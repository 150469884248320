import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Sweetalert from "react-bootstrap-sweetalert";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import PaymentController from "./PaymentController.web";
import Loader from "../../../components/src/Loader.web";

// payment start
const styles = {
  shoppingOuter: {
    padding: '50px 0',
    background: "#f6f6f6",
    "& .MuiGrid-grid-xs-12": {
      padding: "0 10px",
    },
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& .payment .payment-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .payment .payment-list h4": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#212121",
      margin: "0 0 15px"
    },
    "& .payment .payment-list": {
      padding: "20px 20px 25px",
      background: "#fff",
      '@media (max-width:767px)': {
        padding: "15px 15px 20px",
      }
    },
    "& .shop-rightblog-wrapper": {
      '@media (max-width:960px)': {
        margin: "30px 0 0",
      }
    },
    "& .shop-rightblog-wrapper .shop-right-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .shop-right-list": {
      background: "#fff",
      padding: "20px",
    },
    "& .shop-right-list h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 17px",
    },
    "& .product-order-wrapper p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#212121",
      fontWeight: "400",
      margin: " 0 0 16px",
      display: "flex",
      justifyContent: "space-between",
      '@media (max-width:575px)': {
        fontSize: "13px",
      }
    },
    "& .product-order-wrapper p span": {
      display: "inline-block",
      minWidth: "131px",
      color: "#b6b6b6",
      fontWeight: "400",
    },
    "& .product-order-list p:last-child": {
      margin: "0",
    },
    "& .product-order-list .total": {
      padding: "15px 0 0",
      borderTop: "1px solid #e3e3e3",
    },
    "& .product-order-list .total p": {
      fontWeight: "600",
    },
    "& .product-order-list .total p span": {
      fontWeight: "600",
      color: "#212121",
    },
    "& .shop-right-list .black-btn": {
      margin: "25px 0 0",
      width: "100%",
    },
    "& .bank-details .bank-list": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .bank-details .bank-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .bank-details .bank-name": {
      display: "flex",
      alignItems: "flex-start",
    },
    "& .bank-details .bank-name p": {
      margin: " 0 0 10px",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "500",
      color: "#212121",
    },
    "& .bank-details .form-group": {
      margin: "15px 0 0",
    },
    "& .bank-details .bank-name .account-no": {
      margin: " 0 0 0px",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      color: "#212121",
      opacity: "0.5",
    },
    "& .bank-list .bank-logo": {
      margin: "10px 0 0",
      maxWidth: "24px",
    },
    "& .bank-list .MuiFormControlLabel-root": {
      marginRight: "5px",
    },
    "& .payment-method .payment-method-list:not(:last-child)": {
      borderBottom: "1px solid #e3e3e3",
    },
    "& .payment-method .payment-method-list": {
      padding: "20px 0",
      cursor: "pointer",
      '@media (max-width:767px)': {
        padding: "12px 0",
      }
    },
    "& .payment-method .payment-method-wrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .payment-method .payment-method-wrapper .name": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "500",
      textTransform: "uppercase",
      color: "#212121",
    },
    "& .payment-method .payment-method-wrapper .name .img-block": {
      maxWidth: "24px",
      display: "inline-block",
      margin: "0 11px 0 0",
    },
    "& .payment-method .payment-method-wrapper .MuiFormControlLabel-root": {
      marginRight: "5px",
      marginLeft: "5px",
    },
    "& .payment-method .payment-method-wrapper .MuiFormControlLabel-root .PrivateSwitchBase-root-3": {
      padding: "0",
    },
  },
};
// payment end
export class Payment extends PaymentController {

  render() {
    const { classes } = this.props;
    const { priceDetail, selectedPaymentMethod, isOrderPlaced, isPaymentFailed, errorResponse } = this.state;
    return (
      <>
        {/* payment start */}
        {this.state.loading ? (
          <Loader loading={this.state.loading} />
        ) : null}
        <div className={classes.shoppingOuter}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <h2>Payments</h2>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className="payment">
                  <Grid className="payment-list">
                    <h4>Payment Options</h4>
                    <div className="payment-method">
                      <RadioGroup
                        aria-label="payment"
                        name="payment"
                        value={selectedPaymentMethod}
                        onChange={(e: any) =>
                          this.setState({
                            selectedPaymentMethod: e.target.value,
                          })
                        }
                      >
                        <div className="payment-method-list">
                          <div className="payment-method-wrapper">
                            <div className="name">
                              <FormControlLabel
                                value="COD"
                                control={
                                  <Radio
                                    value="cash_on_delivery"
                                    color="default"
                                  />
                                }
                                label="  Cash on delivery"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="payment-method-list">
                          <div className="payment-method-wrapper">
                            <div>
                              <FormControlLabel
                                title={
                                  Number(priceDetail?.total)
                                    ? ""
                                    : "disabled"
                                }
                                disabled={
                                  Number(priceDetail?.total) ? false : true
                                }
                                className="name"
                                value="OnlinePayment"
                                label="Online Payment"
                                control={
                                  <Radio
                                    value="online_payment"
                                    color="default"
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </RadioGroup>
                    </div>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="shop-rightblog-wrapper">
                  <div className="shop-right-list">
                    <div className="product-order-wrapper">
                      <h4>Price Details</h4>
                      <div className="product-order-list">
                        <p>
                          <span>Total MRP</span> ₹
                          {Number(priceDetail?.sub_total) || 0}
                        </p>
                        <p>
                          <span>Discount on MRP</span> -₹
                          {Number(priceDetail?.discount_on_mrp) || 0}
                        </p>
                        {this.state.exchangeFlow == true ?
                          <p>
                            <span>Exchange Product Price</span> -₹{Number(this.state.priceDetail?.exchangeble_item_price)
                              || 0}
                          </p>
                          : <>
                            <p>
                              <span>Loyalty Discount</span> -₹
                              {Number(priceDetail?.loyalty_point_discount) || 0}

                            </p>
                            <p>
                              <span>Coupon Discount</span> -₹
                              {Number(priceDetail?.coupon_discount_amount) || 0}
                            </p>
                          </>}
                        <p>
                          <span>GST</span> ₹
                          {Number(priceDetail?.total_tax) || 0}
                        </p>
                        <p>
                          <span>Delivery Fee</span> ₹
                          {Number(priceDetail?.delivery_charges) || 0}
                        </p>
                        {this.state.exchangeFlow == true ?
                          <div className="total">
                            <p>
                              <span>  {Number(priceDetail?.total) < 0 ? 'Refund' : 'Total'} Amount</span> ₹
                              {Number(priceDetail?.total) < 0 ? Number(priceDetail?.total) * -1 : Number(priceDetail?.total)}
                              {/* {Number(priceDetail?.total) || 0} */}
                            </p>
                            {Number(priceDetail?.total) < 0 ?
                              <p>Refund amount will be credited in your Loyalty/Bank Account</p>
                              :
                              null
                            }
                          </div>

                          :
                          <div className="total">
                            <p>
                              <span>Total Amount</span> ₹
                              {Number(priceDetail?.total) || 0}
                            </p>
                          </div>
                        }

                      </div>


                      {this.state.exchangeFlow ?
                        <Button
                          href=""
                          type="button"
                          variant="contained"
                          className="black-btn"
                          disabled={selectedPaymentMethod ? false : true}
                          onClick={this.PlaceOrderApi}
                        >
                          {selectedPaymentMethod === "cash_on_delivery"
                            ? "Place exchange Order"
                            : "Pay Now"}
                        </Button>



                        :
                        <Button
                          href=""
                          type="button"
                          variant="contained"
                          className="black-btn"
                          disabled={selectedPaymentMethod ? false : true}
                          onClick={this.PlaceOrderApi}
                        >
                          {selectedPaymentMethod === "cash_on_delivery"
                            ? "Place Order"
                            : "Pay Now"}
                        </Button>
                      }
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* payment end */}

        {/* Order Place modal start */}
        {isOrderPlaced ? (
          <Sweetalert
            title="ORDER PLACED!"
            success
            confirmBtnText="Continue Shopping"
            confirmBtnStyle={{
              backgroundColor: "black",
              padding: "10px 15%",
            }}
            onConfirm={() => this.goToHomePage()}
            allowEscape={false}
          />
        ) : null}
        {/* Order Place modal end */}
        {isPaymentFailed ? (
          <Sweetalert
            title="PAYMENT FAILED!"
            error
            confirmBtnText="okay"
            confirmBtnStyle={{
              backgroundColor: "black",
              padding: "10px 15%",
            }}
            onConfirm={() => { this.showRazorPayModel() }}
            allowEscape={false}
          >
            <h6>{errorResponse.code}</h6>
            <h6>Error : {errorResponse.description}</h6>
            <h6>Order ID : {errorResponse.metadata.order_id}</h6>
            <h6>Payment ID : {errorResponse.metadata.payment_id}</h6>
          </Sweetalert>
        ) : null}
      </>
    );
  }
}
export default withStyles(styles)(Payment);
