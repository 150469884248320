import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabContainer from "@material-ui/core/TableContainer";
import {
  Tab,
  Typography,
  Grid,
  Container,
  Link,
  Breadcrumbs,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Loader from "../../../components/src/Loader.web";
import VendorHelpCenterControllers, {
  //  Props
  configJSON,
} from "./VendorHelpCenterController.web";
import { AboutUsStyle } from "./AboutUsStyle.web";

export class VendorHelpCenter extends VendorHelpCenterControllers {
  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <>
        {this.state.loading ? <Loader loading={this.state.loading} /> : null}
        <div className={classes.pageOuter}>
          <div className="heading text-center">
            <Tab disableRipple label="Help Center" />
          </div>

          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} className="block-wrapper">
                <TabContainer>
                  <div id="help">
                    {this.state.helpCenterStep === 1 && (
                      <>
                        {this.state.helpCenter.map((question) => {
                          return (
                            <div
                              key={question.id}
                              className="helpCenter"
                              onClick={() => {
                                this.handleMainQuestion(
                                  question.attributes.types,
                                  2,
                                  question.id
                                );
                              }}
                            >
                              <p>{question.attributes.types}</p>
                              <NavigateNextIcon />
                            </div>
                          );
                        })}
                      </>
                    )}
                    {this.state.helpCenterStep === 2 && (
                      <div className="helpCenterQuestion">
                        <Breadcrumbs
                          separator={<NavigateNextIcon fontSize="small" />}
                          aria-label="breadcrumb"
                        >
                          <Link
                            color="inherit"
                            onClick={() => {
                              this.handleHelpCenterChangeTab(1);
                            }}
                          >
                            Help Center
                          </Link>
                          <Typography color="textPrimary">
                            {this.state.mainQuestion}
                          </Typography>
                        </Breadcrumbs>

                        {this.state.questionAns.map((question) => {
                          return (
                            <div
                              key={question.id}
                              className="helpCenter"
                              onClick={() => {
                                this.handleQuestion(question);
                              }}
                            >
                              <p>{question.attributes.question}</p>
                              <NavigateNextIcon />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {this.state.helpCenterStep === 3 && (
                      <div className="question-ans">
                        <Breadcrumbs
                          separator={<NavigateNextIcon fontSize="small" />}
                          aria-label="breadcrumb"
                        >
                          <Link
                            color="inherit"
                            onClick={() => {
                              this.handleHelpCenterChangeTab(1);
                            }}
                          >
                            Help Center
                          </Link>
                          <Link
                            color="inherit"
                            onClick={() => {
                              this.handleHelpCenterChangeTab(2);
                            }}
                          >
                            {this.state.mainQuestion}
                          </Link>
                          <Typography color="textPrimary">
                            {this.state.question}
                          </Typography>
                        </Breadcrumbs>

                        <div>
                          <p>{this.state.answer}</p>
                        </div>
                      </div>
                    )}
                    <div className="detail-block">
                      <p>In case of any other queries please write to us at</p>
                      <p>
                        <a style={{ color: "blue" }} href="">
                          sellersupport@cealum.in
                        </a>
                      </p>
                    </div>
                    {/* help center end  */}
                  </div>
                </TabContainer>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

export default withStyles(AboutUsStyle)(VendorHelpCenter);
