import React from "react";

const appStore = require("./appStore.png");
const youtubeIcon = require("./youtubeIcon.png");
const caelumWhite = require("./caelumWhite.png");
const facebookIcon = require("./facebookIcon.png");
const google = require("./googlePlay.png");
const greenBoxArrow = require("./greenHands.png");
const greenHands = require("./greenBoxArrow.png");
const greenTruck = require("./greentruck.png");
const instagramIcon = require("./instagramIcon.png");
const whiteBird = require("./whiteBird.png");

import { Container, Grid, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import axios from "axios";
// @ts-ignore
import { baseURL } from "../../framework/src/config";

const lastPart = [
  { img: greenHands, title: "Authentic And Genuine Brands" },
  { img: greenBoxArrow, title: "Easy Returns And Exchanges" },
  { img: greenTruck, title: "Free Shipping Above 699 Across India" },
];

interface Props {
  classes: any;
  callbackShopWithFriend: (is: boolean) => void;
}

const styles = {
  footer: {
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "50px 0",
      marginTop: "20px",
    },
    "& .tiles-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .tiles-wrapper span": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "20px",
      marginTop: "20px",
    },
    "& .footer-wrapper": {
      padding: "50px 20px",
      backgroundColor: "#2d2d2d",
    },
    "& .link-wrapper": {
      padding: "0",
      listStyleType: "none",
      textAlign: "left",
    },
    "& .link-wrapper li a ": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      color: "#fff",
      marginBottom: "10px",
      display: "inline-block",
      textDecoration: "none",
    },
    "& .footer-title": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "16px",
      fontWeight: "600",
      color: "#fff",
      display: "block",
      marginBottom: "30px",
      minHeight: "20px",
      textAlign: "left",
    },
    "& .right-block h4": {
      textTransform: "uppercase",
      color: "#fff",
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      //last block heading
    },
    "& .right-block ul": {
      listStyleType: "none",
      paddingLeft: "0",
      textAlign: "left",
    },
    "& .right-block ul li a": {
      marginRight: "20px",
      display: "inline-block",
      // textAlign:"center"
    },
    "& .right-block .top-block a": {
      display: "inline-block",
      marginRight: "10px",
    },
    "& .right-block .top-block img": {
      maxWidth: "100px",
    },
    "& .blank-link": {
      "@media(max-width:600px)": {
        display: "none",
      },
    },
  },
  footerContent: {
    "& .footer-wrapper": {
      padding: "30px 20px",
      backgroundColor: "white",
    },
    "& .web-right": {
      background: "#2d2d2d",
      padding: "10px 0",
      textAlign: "center",
      color: "white",
      fontWeight: "600",
    },
  },
};

interface S {
  footer_content: string;
}

interface SS {}

export class Footer extends React.Component<Props, S, SS> {
  constructor(props: any) {
    super(props);
    this.state = {
      footer_content: "",
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/Home") {
      this.getFooterContent();
    }
  }

  getFooterContent = () => {
    axios.get(`${baseURL}/bx_block_static_pages/static_pages/footer_home`).then((response) => {
      if (response.status === 200) {
        if (response.data) {
          this.setState({
            ...this.state,
            footer_content: response.data.data.attributes.description,
          });
        }
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.footer} style={{ textAlign: "center" }}>
          <Container maxWidth="md">
            <Grid container spacing={2} className="block-wrapper">
              {lastPart.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={index}
                    className="tiles-wrapper"
                  >
                    <div>
                      <img id="viewAllImg" src={item.img} />
                    </div>
                    <span>{item.title}</span>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
          <div className="footer-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <span className="footer-title">
                  <img src={caelumWhite} />
                </span>
                <ul className="link-wrapper">
                  <li>
                    <Link
                      href={
                        localStorage.getItem("role") === "vendor"
                          ? "/VendorHelpCenter"
                          : "/Aboutus"
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link href="#">Contact Us</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <span className="footer-title">Help</span>
                <ul className="link-wrapper">
                  <li>
                    <Link href="/account/policy?policy=return-exchange-policy">Shipping And Return Policy</Link>
                  </li>
                  <li>
                    <Link href="/account/policy?policy=term-use">Terms &amp; conditions</Link>
                  </li>
                  <li>
                    <Link href="/account/policy?policy=privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <span className="footer-title">Useful links</span>
                <ul className="link-wrapper">
                  <li>
                    <Link href="/Itemlist?category_id=3">Men</Link>
                  </li>
                  <li>
                    <Link href="/Itemlist?category_id=4">Women</Link>
                  </li>
                  <li>
                    <Link href="/Itemlist?category_id=5">Tech &amp; Travel</Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <span className="footer-title blank-link" />
                <ul className="link-wrapper">
                  <li>
                    <Link href="/Itemlist?category_id=6">LifeStyle</Link>
                  </li>
                  <li>
                    <Link href="/BlogsPage">Blogs</Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        this.props.callbackShopWithFriend(true);
                      }}
                    >
                      Shopping With Friends
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} className="right-block">
                <div style={{ textAlign: "left" }}>
                  <h4>Download on mobile</h4>
                  <div className="top-block">
                    <ul>
                      <li>
                        <Link href="www.google.com">
                          <img src={google} />
                        </Link>
                        <Link href="www.google.com">
                          <img src={appStore} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h4 style={{ marginTop: "20px" }}>Keep in Touch</h4>
                  <ul>
                    <li>
                      <Link href="www.google.com">
                        <img src={facebookIcon} />
                      </Link>
                      <Link href="www.google.com">
                        <img src={instagramIcon} />
                      </Link>
                      <Link href="www.google.com">
                        <img src={whiteBird} />
                      </Link>
                      <Link href="www.google.com">
                        <img src={youtubeIcon} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {window.location.pathname === "/Home" && (
          <div className={classes.footerContent}>
            <div className="footer-wrapper">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.footer_content }}
              />
            </div>
            <div className="web-right">
              &copy; 2022 CAELUM. ALL RIGHT RESERVED.
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(Footer);
