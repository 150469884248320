import React, { Component } from "react";
import {
  Box,
  InputLabel,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import AddProductPolicyController from "./AddProductPolicyController.web";
import { Formik } from "formik";

// stepper import
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .btn-wrapper": {
      textAlign: "center",
    },
  },
};
export class AddProductPolicy extends AddProductPolicyController {
  constructor(props: any) {
    super(props);
  }

  warrantyData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  returnableData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  exchangebleData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  codData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  saleData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  steps = ['Basic Info', 'Product Details', 'Pricing', 'Other Info', 'Policy'];
  setActiveStep = (step: any) => {
    this.setState({ activeStep: step });
  }

  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  };

  handleBack = () => {
    this.gotoProductOtherInfoScreen();
    // this.setActiveStep(this.state.activeStep - 1);
  };

  menuProps: any = {
    style: { maxHeight: "300px" },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  render() {
    const { classes } = this.props
    return (
      <>
        {/* Policy start */}
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>

                  {/* step bar start */}
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={this.state.activeStep}>
                      {this.steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        if (index < this.state.activeStep) {
                          stepProps.completed = true;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={this.handleNext}>
                          {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                        </Button> */}
                      </Box>
                    </React.Fragment>
                  </Box>
                  {/* step bar end */}

                  <h2>Policy</h2>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">
                      <Formik
                        initialValues={this.productOtherInfoIntialValues}
                        onSubmit={(values) => {
                          this.addProductPolicyApiCall(values);
                        }}>
                        {(props) => {
                          const { errors, touched, isValid, dirty } = props;
                          props.values.warranty = this.state.warranty;
                          props.values.warrantyPeriodId = this.state.warrantyPeriodId;
                          props.values.isReturnable = this.state.isReturnable;
                          props.values.returnPolicyId = this.state.returnPolicyId
                          props.values.isCODAvailable = this.state.isCODAvailable;
                          props.values.isExchangeable = this.state.isExchangeable;
                          props.values.isSaleAvailable = this.state.isSaleAvailable;
                          return (
                            <form onSubmit={props.handleSubmit}>
                              <h4>Product Policy</h4>
                              <div className="form-group">
                                <InputLabel id="warranty">Warranty</InputLabel>
                                <Select
                                  id="warranty"
                                  name="warranty"
                                  value={props.values.warranty}
                                  error={touched.warranty && errors.warranty ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.warrantyData &&
                                    this.warrantyData.length &&
                                    this.warrantyData.map((warranty: any) =>
                                      <MenuItem value={warranty.value}>{warranty.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.warranty && errors.warranty ? errors.warranty : ''}</span> */}
                              </div>
                              {this.state.warranty &&
                                <div className="form-group">
                                  <InputLabel id="warrantyperiod">
                                    Warranty Period
                                  </InputLabel>
                                  <Select
                                    id="warrantyPeriodId"
                                    name="warrantyPeriodId"
                                    value={props.values.warrantyPeriodId}
                                    error={touched.warrantyPeriodId && errors.warrantyPeriodId ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    MenuProps={this.menuProps}
                                    aria-describedby="my-helper-text"
                                  >
                                    {
                                      this.state.warrantyPeriodData &&
                                      this.state.warrantyPeriodData.length &&
                                      this.state.warrantyPeriodData.map((warranty: any) =>
                                        <MenuItem value={warranty.value}>{warranty.label}</MenuItem>
                                      )
                                    }
                                  </Select>
                                  {/* <span className="error">{touched.warrantyPeriodId && errors.warrantyPeriodId ? errors.warrantyPeriodId : ''}</span> */}
                                </div>
                              }
                              <div className="form-group">
                                <InputLabel id="returnable">
                                  Is It Returnable?
                                </InputLabel>
                                <Select
                                  id="isReturnable"
                                  name="isReturnable"
                                  value={props.values.isReturnable}
                                  error={touched.isReturnable && errors.isReturnable ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.returnableData &&
                                    this.returnableData.length &&
                                    this.returnableData.map((data: any) =>
                                      <MenuItem value={data.value}>{data.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.isReturnable && errors.isReturnable ? errors.isReturnable : ''}</span> */}
                              </div>
                              {this.state.displayReturnPolicyField ?
                                <div className="form-group">
                                  <InputLabel id="warrantyperiod">
                                    Return Policy
                                  </InputLabel>
                                  <Select
                                    id="returnPolicyId"
                                    name="returnPolicyId"
                                    value={props.values.returnPolicyId}
                                    error={touched.returnPolicyId && errors.returnPolicyId ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    MenuProps={this.menuProps}
                                    aria-describedby="my-helper-text"
                                  >
                                    {
                                      this.state.returnPolicyData &&
                                      this.state.returnPolicyData.length &&
                                      this.state.returnPolicyData.map((returnPolicy: any) =>
                                        <MenuItem value={returnPolicy.value}>{returnPolicy.label}</MenuItem>
                                      )
                                    }
                                  </Select>
                                  {/* <span className="error">{touched.warrantyPeriodId && errors.warrantyPeriodId ? errors.warrantyPeriodId : ''}</span> */}
                                </div>
                                : null
                              }
                              <div className="form-group">
                                <InputLabel id="exchangeable">
                                  Is It Exchangeable?
                                </InputLabel>
                                <Select
                                  id="isExchangeable"
                                  name="isExchangeable"
                                  value={props.values.isExchangeable}
                                  error={touched.isExchangeable && errors.isExchangeable ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.exchangebleData &&
                                    this.exchangebleData.length &&
                                    this.exchangebleData.map((data: any) =>
                                      <MenuItem value={data.value}>{data.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.isExchangeable && errors.isExchangeable ? errors.isExchangeable : ''}</span> */}
                              </div>
                              <div className="form-group">
                                <InputLabel id="cod">COD Available?</InputLabel>
                                <Select
                                  id="isCODAvailable"
                                  name="isCODAvailable"
                                  value={props.values.isCODAvailable}
                                  error={touched.isCODAvailable && errors.isCODAvailable ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.codData &&
                                    this.codData.length &&
                                    this.codData.map((data: any) =>
                                      <MenuItem value={data.value}>{data.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.isCODAvailable && errors.isCODAvailable ? errors.isCODAvailable : ''}</span> */}
                              </div>

                              <div className="form-group">
                                <InputLabel id="cod">On Sale Available?</InputLabel>
                                <Select
                                  id="isSaleAvailable"
                                  name="isSaleAvailable"
                                  value={props.values.isSaleAvailable}
                                  error={touched.isSaleAvailable && errors.isSaleAvailable ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.saleData &&
                                    this.saleData.length &&
                                    this.saleData.map((data: any) =>
                                      <MenuItem value={data.value}>{data.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.isCODAvailable && errors.isCODAvailable ? errors.isCODAvailable : ''}</span> */}
                              </div>

                              <div className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                >
                                  {this.state.isEdit ? "Edit" : "Add"}
                                </Button>
                              </div>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Policy start */}
      </>
    );
  }
}

export default withStyles(styles)(AddProductPolicy);
