import React from "react";
import Rating from '@material-ui/lab/Rating';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
    InputLabel,
    Input,
    FormControl,
} from "@material-ui/core";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Loader from "../../../components/src/Loader.web";
import WriteReviewModalController, { Props } from "./WriteReviewModalController.web";

export class WriteReviewModal extends WriteReviewModalController {
    constructor(props: Props) {
        super(props);
        console.log("write review modal open ====>>>>")
    }

    render() {
        const { returnItem } = this.props;
        return (
            <>

                {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}

                {/* Write review */}
                <Dialog
                    aria-labelledby="customized-dialog-duplicate"
                    open={this.state.displayAddRateReviewsModal}
                    className="order-details order-dialog"
                >
                    <DialogTitle id="customized-dialog-title">
                        <span>Rating &amp; Review</span>
                        <CloseIcon
                            className="close-dialog"
                            onClick={(e) => { this.hideReviewModal() }}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: "575px" }}>
                        <Grid item xs={12} className="productlist-wrapper">
                            <Grid className="product-list">
                                <div className="product-list-inner">
                                    <div className="product-img">
                                        <img className="img-block" src={returnItem.imageUrl} />
                                    </div>
                                    <div className="product-detail">
                                        <div className="product-heading">
                                            <h4 className="product-name">{returnItem.product_name}</h4>
                                        </div>
                                        <div className="product-desc">
                                            {returnItem.description}
                                        </div>
                                        <div className="product-desc size-qty">
                                            <span>Size: {returnItem.size}</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className="rating-wrapper">
                                <div className="rating-blog">
                                    <div className="rating">
                                        <p>Rate Product<sup>*</sup></p>
                                        <Rating
                                            name="simple-controlled"
                                            value={this.state.rating}
                                            onChange={(event: any, newValue: any) => { console.log("newvalue:::", newValue, event); this.setState({ rating: newValue }, () => console.log("rating::", this.state.rating)) }}
                                        />
                                    </div>
                                </div>
                                <div className="order-info-blog">
                                    <h6 style={{ fontSize: "16px", fontWeight: 600 }}>Add Product Photos</h6>
                                    <div className="form-group">
                                        <div className="image-outer">
                                            {this.state.reviewsImages &&
                                                this.state.reviewsImages.length > 0 &&
                                                this.state.reviewsImages.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className="image-wrapper">
                                                                <img className="main-img" src={item.url} />
                                                                <div className="cross-icon" style={{ cursor: "pointer" }} onClick={() => this.deleteImage(item.id)}> <CancelRoundedIcon /> </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                        </div>
                                        <div className="upload-file-block">
                                            <AddIcon />
                                            <input type="file" accept="image/*" multiple onChange={(e) => this.uploadMultipleImages(e)} />
                                        </div>
                                        {this.state.hasImgaeError && <span className="error">You can only upload maximum 2 images.</span>}
                                    </div>
                                </div>
                                <div className="order-info-blog">
                                    <h6 style={{ fontSize: "16px", fontWeight: 600 }}>Write Review</h6>
                                    <div className="form-group">
                                        <InputLabel htmlFor="my-input">Write here</InputLabel>
                                        <FormControl>
                                            <Input type="text" id="comment" name="comment" aria-describedby="my-helper-text"
                                                value={this.state.comment}
                                                onChange={(e: any) => {
                                                    this.setState({ comment: e.target.value });
                                                }} />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <Button
                                        variant="contained"
                                        className="outline-btn"
                                        type="button"
                                        onClick={(e) => this.hideReviewModal()}>
                                        Do It Later
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="black-btn"
                                        type="submit"
                                        disabled={this.state.rating > 0 ? false : true}
                                        onClick={(e) => this.addReview()}>
                                        Submit
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                    </DialogContent>
                </Dialog>

            </>
        );
    }
}
export default WriteReviewModal;