Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";

exports.apiPostMethodType = "POST";
exports.apiGetMethodType = "GET";
exports.apiPutMethodType = "PUT";
exports.apiDeleteMethodType = "DELETE";

exports.createGroupAPIEndPoint = "bx_block_groupshopping/group_shoppings";
exports.fetchGroupMemberAPIEndPoint = "bx_block_groupshopping/group_shoppings/";
exports.fetchGroupsAPIEndPoint = "bx_block_groupshopping/group_shoppings";
exports.editGroupNameAPIEndPoint = "bx_block_groupshopping/group_shoppings/";
exports.deleteGroupNameAPIEndPoint = "bx_block_groupshopping/group_shoppings/";
exports.searchMemberAPIEndPoint = "bx_block_groupshopping/search_user";
exports.createInvitationAPIEndPoint = "bx_block_groupshopping/group_invitations";
exports.zoomAdminMeetingAPIEndPoint = "bx_block_zoom_meeting/zoom_meetings";
exports.zoomParticipantMeetingAPIEndPoint = "bx_block_zoom_meeting/participants_meeting";
exports.exitFromGroupAPIEndPoint = "bx_block_groupshopping/remove_member";
exports.acceptDeclineInvitationGroupAPIEndPoint = "bx_block_groupshopping/group_invitations";
exports.getGroupWishListAPIEndPoint = "bx_block_groupshopping/group_shopping_wishlists?group_shopping_id=";
exports.removeWishListAPIEndPoint = "bx_block_groupshopping/remove_to_wishlist";
exports.addToBagOrderAPIEndPoint = "bx_block_order_management/orders";
exports.activeCartAPIEndPoint = "bx_block_shopping_cart/get_active_cart";
exports.chattingAPIEndPoint = "bx_block_groupshopping/group_shoppings";
exports.ShoppingCartAPIEndPoint = "bx_block_shopping_cart/cart_items";
// Customizable Area End
