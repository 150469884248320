Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
var config = require('../../../framework/src/config');
exports.loginAPiEndPoint = "bx_block_login/account_exists";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";  
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
exports.emailType = "email_account";
exports.smsType = "sms_account";
exports.apiAccExist = `bx_block_login/account_exists`;
exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypePost = "POST";
exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint =  `account/accounts`;
exports.verfiyOTPAPiEndPoint =  `account/accounts/email_confirm`;
exports.loginAPiEndPoint =  `bx_block_login/login`;
exports.sendOTPAPiEndPoint =  `account/accounts/send_otp`;
exports.apiVerifyOtpEndPoint = `account_block/account/email_confirm`;
exports.apiVerifyOtpEndPointSMS = `account/accounts/sms_confirmation`;
exports.SetNewPasswordAPIEndPoint = `account_block/set_password`;
// exports.clientID = "48089517582-pfbeav53netvicp8rimhf95majr35bag.apps.googleusercontent.com";
exports.clientID = "962575695421-8543lb6ngn46br9sfobliqe28oboniei.apps.googleusercontent.com";
// Customizable Area End
