import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from "../../../framework/src/StorageProvider";
import { ProductDetail } from "../../Wishlist2/src/ProductDetail.web";
import React, { Component } from "react";
import { OrderModel } from "../../../framework/src/Interfaces/IOrderDetail";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  productDetails(name: any): Function;
  classes: any;
  callbackShopWithFriend: Function;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  //   alfred states start 
  cancelOrder: boolean;
  rateReviews: boolean;
  exchangeItems: boolean;
  token: any;
  priceDetailsData: any;
  reasonOfReturnId: any;
  modeOfRefundForReturn: any;
  returnConfirmation: boolean;
  returnOrderData: any;
  changeAddressDialogBox: boolean;
  addressData: any;
  specificOrderData: any;
  clickedProduct: any;
  showPickupAddress: any;
  pickupAddressId: any;
  productdetails: any;
  additionalComment: any;
  orderId: any;
  displayCancelOrderModal: boolean;
  displayCancelItemOrderModal: boolean;
  dataLoader: boolean;
  openAddReviewModal: boolean,
  selectedProductItemDetail: any;
  reviewId: number;
  accountId: number;
  openDeleteReviewModal: boolean,
  ReturnAndExchangePolicy: boolean;
  order_item_id: any;
  cancelItemResponse: any;
  productName: any;
  exchangeItemId: any;
  popup: boolean;
  activeCartProductIds: any;
  activeCartCatalogueIds: any;
  // cancelReasonList: any;
  // cancelReasonId: any;
  // otherCancelreason: any;
  returnReasonList: any;
  exchangeFlow: boolean;
  cartitemscount: any;
  itemsInCart: boolean;
  cat_id: any

  // alfred state end 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface ProductModel {
  attributes: {
    brand_name: string;
    name: string;
    description: string;
    product_type: any[]; // this is category id
    sub_category_id: any[];
    sub_sub_category_id: any[];
    galleries: any[];
    material_type: string;
    occasion_id: any[];
    about_brand: string;
    country_of_origin: string;
    maximum_retail_price: number;
    sale_price: number;
    average_rating: string;
    care_instruction: string;
    catalogue_variants_attributes: CatalogueVariantModel[];
    is_wishlist: boolean;
    is_it_returnable: boolean;
    bullet_point_1: string;
    bullet_point_2: string;
    bullet_point_3: string;
    bullet_point_4: string;
    bullet_point_5: string;
    review: {
      data: ReviewModal[];
    }
  }
}

export interface CatalogueVariantModel {
  id: number;
  attributes: {
    id: number;
    catalogue_id: number;
    product_name: string;
    sku: string;
    color: string;
    measurement_size_value: string;
    measurement_size_value_id: number;
    maximum_retail_price: number;
    sale_price: number;
    galleries: imageModel[];
  }
}

export interface imageModel {
  id: number;
  url: string;
  is_default: boolean;
}

export interface ReviewModal {
  id: number;
  attributes: {
    order_id: number;
    account_id: number;
    catalogue_id: number;
    rating: number;
    comment: string;
    username: string;
  }
}

export default class MyOrderDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  priceDetailsDataCallId: any;
  ReturnOrderCallId: any;
  addressCallId: any;
  specificOrderDataCallId: any;
  showPickupAddressCallId: any;
  deleteCustomerReviewApiCallId: any;
  cancelItemAPICallId: any;
  // getCancelReasonListAPICallId: any;
  getReturnReasonListAPICallId: any;
  activateCartApiCallId: any;
  addToWishlistApiCallId: any;
  RemoveShoppingBagCallId: any

  // Customizable Area End
  myRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myRef = React.createRef()
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      //   alfred states start
      cancelOrder: false,
      rateReviews: false,
      exchangeItems: false,
      token: "",
      priceDetailsData: [],
      reasonOfReturnId: "",
      modeOfRefundForReturn: "refund_to_bank_account",
      returnConfirmation: false,
      returnOrderData: [],
      changeAddressDialogBox: false,
      addressData: [],
      specificOrderData: [],
      clickedProduct: [],
      showPickupAddress: [],
      pickupAddressId: "",
      productdetails: [],
      additionalComment: "",
      orderId: this.props.navigation.getParam('id'),
      displayCancelOrderModal: false,
      displayCancelItemOrderModal: false,
      dataLoader: true,
      openAddReviewModal: false,
      selectedProductItemDetail: {},
      reviewId: 0,
      accountId: Number(localStorage.getItem('userId')) || 0,
      openDeleteReviewModal: false,
      ReturnAndExchangePolicy: false,
      order_item_id: "",
      cancelItemResponse: [],
      productName: "",
      exchangeItemId: "",
      // cancelReasonList: [],
      // cancelReasonId: '',
      // otherCancelreason: '',
      returnReasonList: [],
      exchangeFlow: false,
      popup: false,
      activeCartProductIds: [],
      activeCartCatalogueIds: [],
      cartitemscount: 0,
      itemsInCart: false,
      cat_id: ""



      // alfred states end 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );


    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start



    // return  Reason with Reason List
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getReturnReasonListAPICallId !== null && apiRequestCallId === this.getReturnReasonListAPICallId) {
      this.getReturnReasonListAPICallId = null;
      // this.setState({ isLoading: false });
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data && (responseJson?.data as any[]).length) {
        // if (responseJson && responseJson.data) {
        // display orders into 
        this.setState({ returnReasonList: responseJson.data })
        this.setState({ reasonOfReturnId: this.state.returnReasonList[0].id })
        console.log(this.state.returnReasonList)
      } else {
        // display no orders found in customer account 
        this.setState({ returnReasonList: [] })
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END return Reason with Reason List




    // Delete customer review
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.deleteCustomerReviewApiCallId !== null && apiRequestCallId === this.deleteCustomerReviewApiCallId) {
      this.deleteCustomerReviewApiCallId = null;
      // this.setState({ isLoading: false });
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data && (responseJson?.data as any[]).length) {
        // redirect to listing page or specific order page
        if (responseJson.data[0].success === 'true') {
          // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.setState({ reviewId: 0, openDeleteReviewModal: false }
            , () => this.specificOrderData()
          )
        }
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END Delete customer review



    // show pickup address start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showPickupAddressCallId !== null &&
      this.showPickupAddressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.showPickupAddressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        // this.setState({ addressData: responseJson.data });
        this.setState({ showPickupAddress: responseJson.data })
        // console.log('//////////////addressData------------------------------------------------------', this.state.addressData);

      }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {

        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // show pick up address end






    // address listing data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addressCallId !== null &&
      this.addressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ addressData: responseJson.data });
        const defaultid: any = this.state.addressData[0].attributes.id
        this.setState({ pickupAddressId: defaultid })
        // console.log('//////////////addressData------------------------------------------------------', this.state.addressData);
        this.showPickupAddress()
      }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {

        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // address listing data end





    // show specific order data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.specificOrderDataCallId !== null &&
      this.specificOrderDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.specificOrderDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        this.setState({ specificOrderData: responseJson.data });
        // console.log('//////////////specific order data------------------------------------------------------', this.state.specificOrderData);
        this.setState({ dataLoader: false })
      } else { this.setState({ specificOrderData: "" }) }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // show specific order data end

    // cancel item start 

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.cancelItemAPICallId !== null &&
      this.cancelItemAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.cancelItemAPICallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ cancelItemResponse: responseJson });
        console.log('//////////////cancelItemResponse------------------------------------------------------', this.state.cancelItemResponse);

      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }


    // cancel item end 





    // Active cart api
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.activateCartApiCallId !== null && apiRequestCallId === this.activateCartApiCallId) {
      this.activateCartApiCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // const { variantId, productId } = this.state;
      if (responseJson && responseJson.data) {

        const productIds: any = []
        const activeCartCatalogueIds: any = []
        const cartItemsCount: any = responseJson.data.attributes.order_items_details.length
        responseJson.data.attributes.order_items_details.map((item: any) => { productIds.push(item.id); activeCartCatalogueIds.push(item.catalogue_id) })
        this.setState({
          //  cartId: responseJson.data.id,
          activeCartProductIds: productIds, activeCartCatalogueIds: activeCartCatalogueIds, cartitemscount: cartItemsCount, itemsInCart: true
        }
          , () => console.log(this.state.activeCartProductIds, this.state.activeCartCatalogueIds, this.state.cartitemscount, "cart info")

          // this.addToBag(productId, variantId)

        )


      }
      else if (responseJson.message) {
        this.setState({ cartitemscount: 0, itemsInCart: false }
          // , () => this.addToBag(productId, variantId)
        )
      }
      console.log(this.state.activeCartProductIds, this.state.activeCartCatalogueIds, this.state.cartitemscount, "cart info")
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END Active cart api

    // return order start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ReturnOrderCallId !== null &&
      this.ReturnOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ReturnOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ returnOrderData: responseJson.data });
        // console.log('////////////// return order------------------------------------------------------', this.state.returnOrderData);
        this.props.navigation.navigate("orders")
      }
      localStorage.removeItem("productdetails")
      localStorage.removeItem("returnreason")
      localStorage.removeItem("productdata")
      localStorage.removeItem("additionalComment")
      // this.props.navigation.navigate("orders")

      // this.setState({ returnOrderData: responseJson.data });
      // console.log('////////////// return order------------------------------------------------------', this.state.returnOrderData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {

        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // return order end 


    // Add To Wishlist
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addToWishlistApiCallId !== null && apiRequestCallId === this.addToWishlistApiCallId) {
      this.addToWishlistApiCallId = null;

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // if (responseJson && responseJson.catalogue && responseJson.wish_catalogue) {
      // add to wishlist changes button to remove wishlist


      // this.setState({ isWishlisted: true });

      // }
      if (responseJson) {
        // add to wishlist changes button to remove wishlist
        // if (localStorage.getItem("exchangeFlow") == "true") {
        this.RemoveShoppingBag();
        // this.setState({ isWishlisted: true });
        // }


      }


      // this.setState({ product: responseJson.data.attributes });

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END Add To Wishlist

    //  remove start shopping bag

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveShoppingBagCallId !== null &&
      this.RemoveShoppingBagCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveShoppingBagCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message == "cart items Removed") {
        this.setState({ popup: false, exchangeItems: true })
        console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove shopping------------------------------------------------------', responseJson.data);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // end  Remove  shopping bag


    // Customizable Area End

  }

  async componentDidMount() {
    this.myRef.current?.scrollIntoView({ behavior: 'auto' })
    try {

      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken,"tokentoken")

      this.setState({ token: localToken });


      this.addressCall()
      this.specificOrderData()
      this.getReturnReasonList();
      this.activeUserCart()


      // this.appliedcoupon()
      // this.loyaltyPoints()

    } catch (e) {
      // error reading value
    }


  }

  activeUserCart() {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activateCartApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateCartApi
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }


  async addToWishist() {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    var httpBody: any = {
      catalogue_ids: this.state.activeCartCatalogueIds
    }


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToWishlistApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToWishlistApi
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }


  // remove from shopping bag  start
  RemoveShoppingBag() {
    // console.log(this.state.ShoppingRemoveBag, "remove product id")

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "ids": this.state.activeCartProductIds

    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.RemoveShoppingBagCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  // remove from shopping bag end 

  getReturnReasonList() {

    // this.setState({ isLoading: true })

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReturnReasonListAPICallId = apiRequest.messageId;


    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.returnReasonApiEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }


  // Customizable Area Start


  // return order start
  returnorder() {
    const productdetail: any = JSON.parse(localStorage.getItem("productdetails") || "{}")
    const productid: any = productdetail?.id
    const returnreason: any = localStorage.getItem("returnreason")
    const additionalcomment: any = localStorage.getItem("additionalComment")
    // console.log(productid,"pr id")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };

    const httpBody = {
      order_item_id: productid,
      service_type: "return",
      // reason_for_return: returnreason,
      reason_to_return_id: returnreason,
      delivery_address_id: this.state.pickupAddressId,
      mode_of_refund: this.state.modeOfRefundForReturn,
      additional_comments: additionalcomment
    };
    // console.log(httpBody)
    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ReturnOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ReturnOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }
  // return order end 


  // listing address start 
  addressCall() {
    // console.log('---------------->>>>>>> address');

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addressCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }
  // listing address end 




  // show pickup address start 
  showPickupAddress() {
    // console.log('---------------->>>>>>> pickupaddress');

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showPickupAddressCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pickupAddressAPiEndPoint.concat(this.state.pickupAddressId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }
  // show pickup address  end 







  // specific order data start 

  specificOrderData() {

    this.setState({ dataLoader: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.specificOrderDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.specificOrderDataAPiEndPoint.concat(this.state.orderId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }


  // specific order data end 

  // delete review  start
  deleteReview = () => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomerReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerReviewApiEndPoint + `/${this.state.reviewId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // delete review end 

  // cancel item api start
  CancelItemApi() {

    // this.setState({ isLoading: true })

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };

    const httpBody = {
      // order_item_id: Number(this.state.order_item_id)
      order_item_id: this.state.order_item_id
    }

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelItemAPICallId = apiRequest.messageId;


    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelItemApiEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  // cancel item api end




  handleChangeReturnPermission() {
    this.setState({ returnConfirmation: !this.state.returnConfirmation }, () => console.log(this.state.returnConfirmation, "confirm"));
  }




  // close address change box 

  handleCloseChangeAddressBox() {
    this.setState({
      changeAddressDialogBox: false
    });
  }
  proceedReturn() {
    this.props.navigation.navigate("returnOrderPickup"); localStorage.setItem("returnreason", this.state.reasonOfReturnId)
    localStorage.setItem("additionalComment", this.state.additionalComment)
  }


  goToCancelOrderScreen = () => {
    this.props.navigation.navigate('cancelOrder', { id: this.state.orderId });
  }
  goToCancelItemScreen = () => {
    this.props.navigation.navigate('cancelItem');
  }


  hideWriteReviewModal = () => {
    console.log("hide hideWriteReviewModal:::");
    this.setState({
      openAddReviewModal: false,
      selectedProductItemDetail: ''
    });
  }
  handleClosePop() {
    this.setState({
      popup: false
    });
  }



  // Customizable Area End

}
