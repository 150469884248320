
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDELETE = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
//size data get


exports.AddWishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/add_to_wishlist";
exports.RemoveWishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/remove_to_wishlist?catalogue_id=";
exports.WishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/wishlisted_catalogue";

exports.PlaceOrderAPiEndPoint =
  "bx_block_order_management/orders";
exports.ShoppingCartAPIEndPoint = "bx_block_shopping_cart/cart_items";
exports.ActiveCartAPIEndPoint =
  "bx_block_shopping_cart/get_active_cart";
exports.removeLoyaltyPointsAPIEndPoint = "bx_block_order_management/orders/remove_loyalty_point?cart_id="
exports.RemoveappliedcouponAPIEndPoint = "bx_block_order_management/orders/remove_coupon_new"