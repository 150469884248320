import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { threadId } from "worker_threads";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  social: string;
  styleNumber: number;
  name: string;
  nameError: string;
  email: string;
  emailError: string;
  phone: any;
  phoneError: string;
  url: string;
  channel: string;
  other: string;
  company: string;
  companyError: string;
  brand: string;
  location: string;
  category: string;
  sku: string;
  emailRegex: any;
  data: any;
  copy: any;
  optionid: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  category: any;
  // Customizable Area End
}

export default class SellerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  emailReg: RegExp;
  apiSellerCallId: any;
  CategoryCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      optionid: "",
      styleNumber: 624,
      other: "",
      email: "",
      channel: "",
      nameError: "",
      emailError: "",
      phoneError: "",
      companyError: "",
      company: "",
      brand: "",
      location: "",
      category: "",
      sku: "",
      social: "",
      data: "",
      copy: "",
      url: "",
      phone: "",
      name: "",
      emailRegex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.emailReg = new RegExp("\\w+");
    // Customizable Area End
  }

  // async receive(from: string, message: Message) {

  //   runEngine.debugLog("Message Received", message);

  // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
  //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  //   this.showAlert(
  //     "Change Value",
  //     "From: " + this.state.txtSavedValue + " To: " + value
  //   );

  //   this.setState({ txtSavedValue: value });
  // }

  // // Customizable Area Start
  // if (getName(MessageEnum.RestAPIRequestMessage) === message.id && this.apiSellerCallId !== null
  // ) {
  //   console.log('----->', MessageEnum.RestAPIRequestMessage);
  //   console.log('--------------->', message.id);
  //   // this.apiSellerCallId = null;

  //   var responseJson = message.getData(
  //     getName(MessageEnum.RestAPIResponceSuccessMessage)
  //   );

  //   var errorReponse = message.getData(
  //     getName(MessageEnum.RestAPIResponceErrorMessage)
  //   );
  //   console.log(responseJson,"vendor data ")
  //   if(responseJson){
  //     this.afterSubmitted()

  //   }
  //   // if(!this.apiSellerCallId){
  //   //   this.afterSubmitted()

  //   // }

  //   console.log(errorReponse,"vendor error data ")
  //   if (responseJson && responseJson.meta) {
  //     runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  //     console.log('response--------------------->>>', responseJson);

  //   } else {
  //     //Check Error Response
  //     this.parseApiErrorResponse(responseJson);
  //   }
  //   this.parseApiCatchErrorResponse(errorReponse);
  // }

  //   if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.CategoryCallId !== null) {

  //     console.log('Show Address------------------->res start');

  //     this.CategoryCallId = null;
  //     console.log("if conditions addressCallId---->", this.CategoryCallId);

  //     var responseJson = message.getData(
  //       getName(MessageEnum.RestAPIResponceSuccessMessage)
  //     );
  //     this.setState({ data: responseJson.data });
  //     console.log(this.state.data, "---------------------------------------------------------------------------------------------------------------")

  //     var errorReponse = message.getData(
  //       getName(MessageEnum.RestAPIResponceErrorMessage)
  //     );
  //     if (responseJson && responseJson.meta && responseJson.meta.token) {
  //       runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  //       //Need To send Login token message to save for future call
  //     } else {
  //       //Check Error Response
  //       this.parseApiErrorResponse(responseJson);
  //     }
  //     this.parseApiCatchErrorResponse(errorReponse);
  //   }
  //   // Customizable Area End

  // }
  //test

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.CategoryCallId) {
          this.setState({ data: responseJson.data });
          // this.setState({copy:this.state.data})
          this.setState({ copy: this.state.data });
        } else if (apiRequestCallId === this.apiSellerCallId) {
          this.afterSubmitted();
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.CategoryCallId) {
        } else if (apiRequestCallId === this.apiSellerCallId) {
        }
      } else if (errorReponse) {
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  moreData() {
    if (this.state.enableField) {
      this.setState({ enableField: false });
      this.setState({ styleNumber: 624 });
    } else {
      this.setState({ enableField: true });
      this.setState({ styleNumber: 994 });
    }
  }

  async componentDidMount() {
    //  api
    this.CategoryCall();
  }

  // public changeState(objectID: string, value: any = null) {
  //   switch (objectID) {
  //     case 'txtInputName':
  //       this.setState({ name: value });
  //       console.log(this.state.name,"onchange")
  //       break;
  //     case 'txtInputEmail':
  //       this.setState({ email: value });
  //       break;
  //     case 'txtInputPhone':
  //       this.setState({ phone: value });
  //       break;
  //     case 'txtInputCompany':
  //       this.setState({ company: value });
  //       break;
  //     case 'txtInputBrand':
  //       this.setState({ brand: value });
  //       break;
  //     case 'txtInputUrl':
  //       this.setState({ url: value });
  //       break;
  //     case 'txtInputLocation':
  //       this.setState({ location: value });
  //       break;
  //     case 'txtInputCategory':
  //       this.setState({ category: value });
  //       break;
  //     case 'txtInputSku':
  //       this.setState({ sku: value });
  //       break;
  //     default:
  //       console.log("changeState::Not Confifured for " + objectID);
  //   }

  // }
  isStringNullOrBlank(str: string | number) {
    return str === null || str.toString().length === 0;
  }
  // isValidEmail(email: string) {
  //   return this.emailReg.test(email);
  // }

  CategoryCall() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CategoryCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCategoryAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  afterSubmitted = () => {
    alert("Your vendor registration form submitted to admin");
    this.setState({ copy: this.state.data }),
      this.setState({ name: "" }),
      this.setState({ phone: "" }),
      this.setState({ email: "" }),
      // this.setState({data: ''}),
      this.setState({ category: "" }),
      this.setState({ brand: "" }),
      this.setState({ url: "" }),
      this.setState({ location: "" }),
      this.setState({ sku: "" });
    this.setState({ company: "" });
    return this.state.copy;
  };
  // getSellerValidation = (data:any) => {
  //   const {name, email, phone, company} = data;
  //   const error:any = {};
  //   if (!name.trim()) {
  //     error.name = '* Field is required';
  //     error.error = true;
  //   } else {
  //     error.error = error.error || false;
  //   }
  //   if (!company.trim()) {
  //     error.company = '* Field is required';
  //     error.error = true;
  //   } else {
  //     error.error = error.error || false;
  //   }
  //   if (!phone.trim()) {
  //     error.phone = '* Field is required';
  //     error.error = true;
  //   } else if(phone.toString().length !== 10){
  //     error.phone = '* Enter valid number';
  //   } else{
  //     error.error = error.error || false;
  //   }
  //   if (!email.trim()) {
  //     error.email = '* Field is required';
  //     error.error = true;
  //   } else if (!this.state.emailRegex.test(email)) {
  //     error.email = '* Enter valid email';
  //     error.error = true;
  //   } else {
  //     error.error = error.error || false;
  //   }
  //   return error;
  // };

  doSeller(): boolean {
    if (
      this.isStringNullOrBlank(this.state.name) &&
      this.isStringNullOrBlank(this.state.phone) &&
      this.isStringNullOrBlank(this.state.company) &&
      this.isStringNullOrBlank(this.state.email)
    ) {
      // alert("fill form")
      // window.scrollTo(0,0)
      this.setState({ nameError: "Please fill name field" });
      this.setState({ emailError: "Please fill email field" });
      this.setState({ phoneError: "Please fill phone number field" });
      this.setState({ companyError: "Please fill company field" });

      return false;
      // if((!this.state.emailRegex.test(this.state.email))) {
      //   this.setState({emailError:"Email is not valid"})
      // return false;
      // }
      // if(this.state.phone.toString().length !== 10)
      // {
      //   this.setState({phoneError:"Phone number is not valid"})
      // return false;
      // }
    } else if (
      !this.state.emailRegex.test(
        this.state.email || this.state.phone.toString().length !== 10
      ) ||
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.company)
    ) {
      if (!this.state.emailRegex.test(this.state.email)) {
        this.setState({ emailError: "Email is not valid" });
        // return false;
      } else {
        this.setState({ emailError: "" });
      }
      if (this.state.phone.toString().length !== 10) {
        this.setState({ phoneError: "Phone number is not valid" });
        // return false;
      } else {
        this.setState({ phoneError: "" });
      }

      if (this.isStringNullOrBlank(this.state.name)) {
        this.setState({ nameError: "Please fill name field" });
      } else {
        this.setState({ nameError: "" });
      }
      if (this.isStringNullOrBlank(this.state.company)) {
        this.setState({ companyError: "Please fill company field" });
      } else {
        this.setState({ companyError: "" });
      }
      return false;
      // }else if (this.state.phone.toString().length !== 10) {
      //   this.setState({phoneError:"Phone number is not valid"})
      //   return false;
    } else {
      this.setState({ nameError: "" });
      this.setState({ emailError: "" });
      this.setState({ phoneError: "" });
      this.setState({ companyError: "" });

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
      };

      const data = {
        name: this.state.name,
        full_phone_number: this.state.phone,
        email: this.state.email,
        company_name: this.state.company,
        category_id: this.state.optionid,
        brand_name: this.state.brand,
        url: this.state.url,
        where_are_you_selling_currently: this.state.location,
        no_of_skus: this.state.sku,
      };

      const httpBody = {
        data: data,
      };

      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiSellerCallId = apiRequest.messageId;
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiSellerAPiEndPoint
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.AffiliateAPiMethod
      );
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;
    }

    // let error:any = this.getSellerValidation({
    //   this.state.name.toString(),
    //   this.state.email.toString()
    //   this.state.phone.toString(),
    //   this.state.company.toString(),
    // });
  }

  // Customizable Area End
}
