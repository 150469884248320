Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httpPatchMethod = "PATCH"

exports.addressPackageNotFoundError = "Please add address and package detail for schedule pickup !"

exports.contentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.ordersByStatusApiEndPoint = "bx_block_order_management/order_status";
exports.searchOrderApiEndPoint = 'bx_block_order_management/search_order';
exports.updateOrderApiEndPoint = 'bx_block_order_management/orders/update_status';
exports.cancelReasonApiEndPoint = "bx_block_order_management/reason_to_cancle";
exports.customerOrderListApiEndPoint = "bx_block_order_management/orders";
exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.packageAPiEndPoint = "bx_block_packaging/packages";
exports.printInvoiceApiEndPoint = 'bx_block_ordersummary/print_invoice';
exports.printShippingLableApiEndPoint = 'bx_block_ship_rocket_shipment/ship_rocket_shipments/generate_label';
exports.printmanifiestApiEndPoint = 'bx_block_ordersummary/print_manifest';
exports.schedulePickupApiEndPoint = 'bx_block_ship_rocket_shipment/ship_rocket_shipments/create_schedule_pickup';
// Customizable Area End