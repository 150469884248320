import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { PoliciesStyle } from "./PoliciesStyle.web";
import PolicyController, {
  //  Props
  configJSON,
} from "./PolicyController.web";

// Policies page start
export class Policy extends PolicyController {
  render() {
    const { classes } = this.props;

    return (
      <>
        {/* Policies page start */}
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="md">
              <Grid container>
                <Grid item xs={12}>
                  <h5 className="heading">
                    {this.state.pageName === "terms_and_conditions"
                      ? "Term Of Use"
                      : this.state.pageName === "privacy_policy"
                      ? "Privacy Policy"
                      : this.state.pageName === "shipping_policy"
                      ? "Shipping Policy"
                      : this.state.pageName === "return_and_exchange_policy"
                      ? "Return & Exchange Policy"
                      : ""}
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.description,
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Policies page end*/}
      </>
    );
  }
}

export default withStyles(PoliciesStyle)(Policy);
