import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import { PoliciesStyle } from "./PoliciesStyle.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PoliciesController, {
  //  Props
  configJSON,
} from "./PoliciesController.web";
import { Link } from "@material-ui/core";

// Policies page start
const helpCenterDataInner = [
  { id: "1", title: "Terms of Use", url: "/account/policy?policy=term-use" },
  {
    id: "2",
    title: "Privacy Policy",
    url: "/account/policy?policy=privacy-policy",
  },
  {
    id: "3",
    title: "Shipping Policy",
    url: "/account/policy?policy=shipping-policy",
  },
  {
    id: "4",
    title: "Return & Exchange Policy",
    url: "/account/policy?policy=return-exchange-policy",
  },
];

export class Policies extends PoliciesController {
  render() {
    const { classes } = this.props;

    return (
      <>
        {/* Policies page start */}
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="md">
              <Grid container>
                <Grid item xs={12}>
                  <h5 className="heading">Policies</h5>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <div id="help">
                        {helpCenterDataInner.map((policy: any) => {
                          return (
                            <Link
                              href={policy.url}
                              key={policy.id}
                              className="helpCenter"
                            >
                              <p>{policy.title}</p>
                              <NavigateNextIcon />
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Policies page end*/}
      </>
    );
  }
}

export default withStyles(PoliciesStyle)(Policies);
