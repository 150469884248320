import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import MenuItems from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Menu, MenuItem } from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
// import { Menu } from "../../../../node_modules/@szhsin/react-menu";

// import DocIc from "../assets/doc-icon.svg";

import AddressController, {
  Props,
  //   configJSON
} from "./AddressController.web";

import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

// Vendor Address Book Start
const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& h5": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "500",
      margin: "0 0 10px",
    },
    "& .address-detail": {
      borderBottom: "1px solid #e3e3e3",
      margin: "0 0 10px",
      padding: "0 0 6px",
    },
    "& .address-detail .user-detail": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#5d5d66",
      margin: "0 0 10px",
      fontWeight: "400",
    },
    "& .address-detail .outline-btn": {
      margin: "0 0 6px",
    },
    "& .address-detail .tab": {
      border: "1px solid #000",
      padding: "2px 10px",
      borderRadius: "3px",
    },

    "& h6": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#121212",
      margin: "0 0 0px",
      fontWeight: "600",
    },
    "& .address-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "25px",
    },
  },
};
// Vendor Address Book End

export class VendorAddressBook extends AddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        {/* Vendor Address Book Start */}
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Address Book</h2>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <h5>Warehouse Address </h5>
                      {
                          this.state.addressData.length ?
                          this.state.addressData.map((items:any) => (
                      <div className="address-detail">
                        <div className="address-title-wrapper">
                          <h6>{items.attributes.name}</h6>
                          <div>
                            <Menu
                              menuButton={
                                <div>
                                  <IconButton>
                                    <MoreHorizIcon/>
                                  </IconButton>
                                </div>
                              }
                              transition
                            >
                              <MenuItem onClick={() => this.editAddress(event, items.id)}>Edit</MenuItem>
                              <MenuItem onClick={() => this.deleteAddressCall(event,items.id)}>Delete</MenuItem>
                              { ! items.attributes.is_default && (<MenuItem onClick = {() => this.makeDefaultAddressCall(event,items.id)}>Make Default</MenuItem>)}
                            </Menu>
                          </div>
                        </div>
                        <p className="user-detail">
                        {items.attributes.address}, {items.attributes.town}, {items.attributes.city}, {items.attributes.state} - {items.attributes.zip_code}
                        </p>
                        { items.attributes.is_default && (<span className="tab"> Default </span> )}                          
                      </div>
                    )) : 
                    <div> No address found </div>
                    }
{/*                       
                      <div className="address-detail">
                        <div className="address-title-wrapper">
                          <h6>Caelum Warehouse</h6>
                          <div>
                            <Menu
                              menuButton={
                                <div>
                                  <IconButton>
                                    <MoreHorizIcon />
                                  </IconButton>
                                </div>
                              }
                            >
                              <MenuItem>Edit</MenuItem>
                              <MenuItem>Delete</MenuItem>
                              <MenuItem>Make Default</MenuItem>
                            </Menu>
                          </div>
                        </div>
                        <p className="user-detail">
                          401-Diamond Heights, M.G. Road, Green city, Ahmedabad
                          - 380854
                        </p>
                        <a href="#" className="default-link">
                          Default
                        </a>
                      </div>
                      <div className="address-detail">
                        <div className="address-title-wrapper">
                          <h6>Caelum Office</h6>
                          <div>
                            <Menu
                              menuButton={
                                <div>
                                  <IconButton>
                                    <MoreHorizIcon />
                                  </IconButton>
                                </div>
                              }
                            >
                              <MenuItem>Edit</MenuItem>
                              <MenuItem>Delete</MenuItem>
                              <MenuItem>Make Default</MenuItem>
                            </Menu>
                          </div>
                        </div>
                        <p className="user-detail">
                          Aashiyana Bungalow, Lakshvillas, Bopal, Ahmedabad -
                          380077
                        </p>
                      </div>
                       */}
                      <div className="btn-wrapper">
                        <Button
                          variant="contained"
                          className="black-btn"
                          type="button"
                          onClick={this.gotoAddAddressScreen}
                        >
                          Add Address
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Vendor Address Book end */}
      </>
    );
  }
}

export default withStyles(styles)(VendorAddressBook);
