Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductQuickview";
exports.labelBodyText = "ProductQuickview Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";

exports.postAPiMethod = "POST";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.putAPiMethod = "PUT";
exports.deleteApiMethodType = "DELETE";

exports.getCategorySubCategorySubSubCategoryApi = "bx_block_categories/categories";
exports.getProductDataApi = "catalogue/catalogues";
exports.addProduct = "catalogue/catalogues";
exports.productEditAPiEndPoint = "catalogue/catalogues";
exports.productPUTAPiEndPoint = "bx_block_catalogue/multiple_update"
exports.productDeleteAPiEndPoint = "catalogue/catalogues";
exports.searchApiEndPoint = 'bx_block_catalogue/catalogues/catalogue_search';

exports.deleteProductVariantApiEndPoint = "bx_block_catalogue/catalogues_variants";
exports.deleteImageApiEndPoint = 'bx_block_catalogue/image_delete';

exports.getDropDown = "bx_block_catalogue/catalogue_values";
exports.categoryList = "bx_block_categories/categories_listing";
exports.subCategoryEndPoint = "bx_block_categories/sub_categories_listing?category_id=";
exports.vendorBrandListApi = "bx_block_catalogue/vendor_brands";
exports.brandListProduct = "catalogue/brands";
exports.SizeApiEndPoint = "bx_block_catalogue/size_varient_listing";
exports.MeasurementSizeApiEndPoint = "bx_block_catalogue/measurement_size_varient_listing";

exports.ShopbyProductAPiEndPoint = "bx_block_catalogue/catalogues/shop_by_product";
exports.getReturnPolicy = "bx_block_order_management/return_policies";
exports.getShopByEditListing = "listing_of_shop_by_edit"
exports.shopbyEditApiEndPoint = "/bx_block_catalogue/listing_of_shop_by_edit";

exports.bulkUploadAPIEndPoint = "bx_block_catalogue/catalogues/upload_bulk_catalogues";
// Customizable Area End