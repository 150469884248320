export const CsvUploadStyleWeb: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .btn-wrapper": {
      textAlign: "center",
    },
    "& .download-doc": {
      border: "1px solid #d3d3d3",
      padding: "20px",
      minHeight: "88px",
      display: "flex",
      alignItems: "center",
      "@media(max-width:480px)": {
        flexWrap: "wrap",
        padding: "15px",
      },
    },
    "& .download-doc .black-btn": {
      minWidth: "120px",
      padding: "5px",
      marginLeft: "auto",
      "@media(max-width:480px)": {
        margin: "15px auto 0",
      },
    },
    "& .download-doc .file-name": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
      marginLeft: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "calc(100% - 180px)",
      textOverflow: "ellipsis",
      "@media(max-width:480px)": {
        maxWidth: "calc(100% - 50px)",
      },
    },
    "& .file-upload": {
      border: "1px solid #d3d3d3",
      padding: "5px 5px 5px 20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "30px",
    },
    "& .file-upload span": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .file-upload .MuiIconButton-root": {
      padding: "8px",
    },
    "& .upload-btn": {
      position: "relative",
      overflow: "hidden",
      minWidth: "120px",
      padding: "5px",
    },
    "& .upload-btn input": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      opacity: "0",
    },
    "& .upload-btn input::-webkit-file-upload-button": {
      cursor: "pointer",
    },
    "& .divide-block": {
      borderBottom: "3px solid #e3e3e3",
      display: "block",
      width: "100%",
      margin: "20px 0 30px",
    },
    "& .file-upload input": {
      opacity: "0",
    },
    "& .file-upload.input": {
      position: "relative",
      padding: "10px",
      cursor: "pointer"
    },
    "& .file-upload.input span": {
      position: "absolute",
      left: "10px",
    },
  },
};
