import React, { useState } from "react";
import Pagination from '@material-ui/lab/Pagination';

const PaginationComponent = ({ ...props }) => {
  const [pageNumber, setPage] = useState(1);

  const onPageChange = (event: any, page: number) => {
    setPage(page);
    props.handlePageChange(page);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Typography>Page: {this.state.pageNumber}</Typography> */}
      <Pagination
        count={props.totalPage} // total number of pages
        page={props?.currentPage ? props.currentPage : pageNumber}        // current page number
        onChange={onPageChange} // on change of page number click
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};
export default PaginationComponent;
