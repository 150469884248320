import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "@szhsin/react-menu";
import moment from "moment";
import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges
} from "react-date-range";
// import { useTheme } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import VendorOrderController, { Props } from "./VendorOrderController.web";
import { championItem, search } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";
import { convertIntoMoneyFormat } from "../../../framework/src/commonFunction";
import { OrderItemModel, OrderModel } from "../../../framework/src/Interfaces/IOrderDetail";
import { CatalogueVariantModel, imageModel, ProductModel } from "../../../framework/src/Interfaces/IProductDetail";
import PaginationComponent from "../../../components/src/shared/pagination";

const styles = {
  pageOuter: {
    backgroundColor: "#f6f6f6",
    padding: "18px 0 35px",

    "& .MuiTabs-flexContainer": {
      display: "block",
      textAlign: "center",
    },
  },
  tabsRoot: {
    borderBottom: "1px solid #c3c3c3",
    marginBottom: "20px",
  },
  tabsIndicator: {
    backgroundColor: "#121212",
    height: "3px",
  },
};

export class VendorOrderManagement extends VendorOrderController {

  constructor(props: Props) {
    super(props);
    this.elementRef = React.createRef();
  }

  renderSearchInput = () => {
    return (
      <div className="heading-left">
        <div className="search-wrapper">
          <div className="form-group">
            <img src={search} className="search-ic" />
            <input
              name="searchInput"
              value={this.state.searchInput}
              onChange={(e) => this.handleSearchInput(e.target.value)}
              placeholder="Search by order, product, order id"
            // inputProps={{ "aria-label": "search" }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderFilterList = () => {
    return (
      <div className="select-border">
        <span className="select-label">Filters:</span>
        <Select
          labelId="product-category"
          id="demo-simple-select"
          name="dayWiseOrder"
          displayEmpty
          value={this.state.dayWiseOrder}
          onChange={(e: any) => {
            console.log("filter value:::", e.target);
            this.setState({ dayWiseOrder: e.target.value }, () => {
              if (this.state.dayWiseOrder === 'custom_choose') {
                this.setState({ isOpenDateRangePicker: true });
              } else {
                this.getFilterDataList(this.state.dayWiseOrder)
              }
            })
          }}
        >
          <MenuItems value="">select</MenuItems>
          {this.state.filterData &&
            this.state.filterData.length &&
            this.state.filterData.map((filter) => {
              return (
                <MenuItems value={filter.value} >{filter.title}</MenuItems>
              )
            })
          }
          <MenuItems value="custom_choose" onClick={() => this.setState({ isOpenDateRangePicker: true, dayWiseOrder: 'custom_choose' })}>Custom</MenuItems>
        </Select >
      </div >
    )
  }

  onSelect = (value: any) => {
    console.log("value:::", value);
    this.setState({ dateRange: value });
  };

  // onStartSelect = (value: any) => {
  //   console.log("startvalue:::",value._d);
  //   const dateRange = moment.range(value, this.state.dateRange.end);
  //   this.setState({ dateRange: dateRange });
  // };

  renderDateRangePicker = () => {
    console.log("range picker:::");
    return (
      <>
        <div>Filter by start date and end date</div>
        {/* <div>Start Date : {this.state.dateRange.start.format("DD-MM-YYYY")}</div> */}
        {/* <div>end Date : {this.state.dateRange.end.format("DD-MM-YYYY")}</div> */}
        <DateRangePicker
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          maxDate={new Date()}
          rangeColors={['#3d91ff', '#3ecf8e', '#fed14c']}
          ranges={this.state.dateRange}
          onChange={(ranges: any) => {
            console.log("ranges:::", ranges);
            this.setState({ dateRange: [ranges.rollup] });
          }}
          staticRanges={[]}
          inputRanges={[]}
        />
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const { tabIndex, isLoading } = this.state;
    const { orderList, selectedOrder } = this.state;
    const status = selectedOrder?.attributes?.status;

    const total = Number(selectedOrder?.attributes?.sub_total);
    const total_tax = Number(selectedOrder?.attributes?.total_tax);

    const statusColor =
      status === ORDER_STATUS_TYPES.CONFIRMED ? 'confirmed' :
        status === ORDER_STATUS_TYPES.DELIVERED ? 'delivered' :
          status === ORDER_STATUS_TYPES.RTO ? 'rto' :
            status === ORDER_STATUS_TYPES.CANCELLED ? 'cancelled' :
              status === ORDER_STATUS_TYPES.REFUND_PROCESSED ? 'refunded' :
                status === ORDER_STATUS_TYPES.EXCHANGE ? 'exchange' : '';

    return (
      <>
        {isLoading ? <Loader loading={isLoading} /> : null}
        <div className={`${classes.pageOuter} order-wrapper`} ref={this.elementRef}>
          <Container maxWidth="md">
            <div className="order-tabs-block">
              <Tabs
                className="order-tabs"
                onChange={this.handleChange}
                value={tabIndex}
                variant="scrollable"
                scrollButtons="on"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
              >
                <Tab
                  disableRipple
                  onClick={() => this.navigateToVendorTab(ORDER_STATUS_TYPES.PLACED, this.VendorOrderTabEnum.RECEIVED)}
                  label="Received" />
                <Tab
                  disableRipple
                  onClick={() => this.navigateToVendorTab(ORDER_STATUS_TYPES.CONFIRMED, this.VendorOrderTabEnum.SHIPPED)}
                  label="Unshipped" />
                <Tab
                  disableRipple
                  onClick={() => this.navigateToVendorTab(ORDER_STATUS_TYPES.READY_TO_SHIP, this.VendorOrderTabEnum.READY_TO_SHIP)}
                  label="Ready to Ship" />
                <Tab
                  disableRipple
                  onClick={() => this.navigateToVendorTab(ORDER_STATUS_TYPES.SHIPPED, this.VendorOrderTabEnum.SENT)}
                  label="Sent" />
                <Tab
                  disableRipple
                  onClick={() => this.navigateToVendorTab(ORDER_STATUS_TYPES.CANCELLED, this.VendorOrderTabEnum.CANCELLED)}
                  label="Cancelled" />
              </Tabs>
            </div>
            {/* Received tab */}
            {tabIndex === 0 && (
              <TabContainer>
                <Grid container className="product-wrapper">
                  <Grid className="inventory-heading" item xs={12}>
                    {this.renderSearchInput()}
                    <div className="btn-wrapper">
                      {this.renderFilterList()}
                    </div>
                  </Grid>
                  <Grid item xs={12} className="productlist-wrapper">
                    {orderList &&
                      orderList.length ?
                      orderList.map((order: OrderModel) => {
                        const orderId = order.id;
                        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                        const orderNumber = order && order.attributes && order.attributes.order_number;
                        const orderDate = order?.attributes?.updated_at;
                        const updatedOrderDate = moment(orderDate).format('DD/MM/YYYY');
                        const orderItems = order?.attributes?.order_items_details;
                        let status = order?.attributes?.status;
                        return (
                          <>
                            <Grid
                              className="product-list pointer"
                              key={orderId}
                              onClick={(e) => {
                                this.setState({
                                  showOrderDetailModal: true,
                                  selectedOrder: order
                                });
                              }}
                            >
                              <div className="orderid-date">
                                <div className="order-id">Order ID: {orderNumber}</div>
                                <span className="order-date">{updatedOrderDate}</span>
                              </div>
                              {orderItems &&
                                orderItems.length &&
                                orderItems.map((item: OrderItemModel, index: number) => {
                                  const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                  const product_name = item?.product_name;
                                  const description = item?.description;
                                  const brand_name = item?.brand;
                                  const size = item?.measurement_size_value;
                                  const price = item?.total_price - (item?.quantity * item?.discount_on_mrp);
                                  const color = item?.color;
                                  return (
                                    < div className="product-list-inner" key={item.id}>
                                      <div className="product-img">
                                        <img className="img-block" src={imageUrl} />
                                      </div>
                                      <div className="product-detail">
                                        <div className="product-heading">
                                          <h4 className="product-name">{product_name}({color})</h4>
                                        </div>
                                        <div className="product-desc">
                                          {brand_name}
                                        </div>
                                        <div className="product-actions">
                                          <div className="product-desc">
                                            <span>Size: {size}</span>
                                            <span className="price">QTY: {item.quantity}</span>
                                            <span className="price">&#8377;{convertIntoMoneyFormat(price)}</span>
                                          </div>
                                          {(orderItems.length - 1) === index &&
                                            (
                                              <div className="btn-wrapper">
                                                <Button
                                                  href=""
                                                  type="submit"
                                                  variant="contained"
                                                  className="outline-btn"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                      cancelOrder: true,
                                                      selectedOrderNumber: orderNumber,
                                                      selectedOrderId: orderId,
                                                      actionType: ORDER_STATUS_TYPES.CANCELLED,
                                                      cancelReasonDescription: '',
                                                      cancelReasonId: null
                                                    });
                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                                <Button
                                                  href=""
                                                  type="submit"
                                                  variant="contained"
                                                  className="black-btn"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                      confirmOrder: true,
                                                      selectedOrderNumber: orderNumber,
                                                      selectedOrderId: orderId,
                                                      actionType: ORDER_STATUS_TYPES.CONFIRMED
                                                    });
                                                  }}
                                                >
                                                  confirm
                                                </Button>
                                              </div>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </Grid>
                          </>
                        )
                      })
                      :
                      <span className="not-found">No Record Found</span>
                    }
                  </Grid>
                </Grid>
              </TabContainer>
            )}

            {/* Unshipped tab */}
            {tabIndex === 1 && (
              <TabContainer>
                <Grid container className="product-wrapper">
                  <Grid className="inventory-heading" item xs={12}>
                    {this.renderSearchInput()}
                    <div className="btn-wrapper">
                      {this.renderFilterList()}
                      {/* will implement in next build */}
                      <Button
                        href=""
                        type="submit"
                        variant="contained"
                        className="black-btn order-btn"
                        disabled={!this.state.selectedOrderId}
                        onClick={(e) => { this.props.navigation.navigate("schedulePickup", { orderId: this.state.selectedOrderId }) }}
                      >
                        Schedule Pickup
                      </Button>
                      <div className="menu-block">
                        <Menu
                          menuButton={
                            <div>
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          }
                        >
                          {/* <MenuItem
                            disabled={!this.state.confirmOrderCheckedId}
                            onClick={() => this.onPrintInvoice()}>
                            Print Invoice
                          </MenuItem> */}
                          <MenuItem
                            disabled={!this.state.selectedOrderId}
                            onClick={(e) => {
                              this.setState({
                                cancelOrder: true,
                                actionType: ORDER_STATUS_TYPES.CANCELLED
                              });
                            }}>
                            Cancel Order
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="productlist-wrapper">
                    {orderList &&
                      orderList.length ?
                      orderList.map((order: OrderModel) => {
                        const orderId = order.id;
                        const shipRocketOrderId= order?.attributes?.ship_rocket_order_id;
                        const printInvoiceOrderId = order?.attributes?.order_id;
                        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                        const orderNumber = order && order.attributes && order.attributes.order_number;
                        const orderDate = order?.attributes?.updated_at;
                        const updatedOrderDate = moment(orderDate).format('DD/MM/YYYY');
                        const orderItems = order?.attributes?.order_items_details;
                        let status = order?.attributes?.status;
                        return (
                          <>
                            <Grid
                              className="product-list pointer"
                              onClick={(e) => {
                                this.setState({
                                  showOrderDetailModal: true,
                                  selectedOrder: order
                                });
                              }}
                            >
                              <div className="orderid-date">
                                <div className="order-id">
                                  <Checkbox
                                    className="order-checkbox"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState((prevState) => ({
                                        confirmOrderCheckedId: prevState.confirmOrderCheckedId === printInvoiceOrderId ? null : printInvoiceOrderId,
                                        selectedOrderNumber: orderNumber,
                                        selectedOrderId: prevState.selectedOrderId === orderId ? null : orderId,
                                        // :::for displaying model as per model one shedule in a go:::
                                        orderSchedule: prevState.confirmOrderCheckedId && prevState.confirmOrderCheckedId !== printInvoiceOrderId ? true : false
                                      }));
                                    }}
                                    checked={this.state.confirmOrderCheckedId === printInvoiceOrderId ? true : false}
                                    inputProps={{
                                      "aria-label": "checkbox with default color",
                                    }}
                                  />
                                  Order ID: {orderNumber}
                                </div>
                                <span className="order-date">
                                  Confirmed on: {updatedOrderDate}
                                </span>
                              </div>
                              {orderItems &&
                                orderItems.length &&
                                orderItems.map((item: OrderItemModel, index: number) => {
                                  const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                  const product_name = item?.product_name;
                                  const description = item?.description;
                                  const brand_name = item?.brand;
                                  const size = item?.measurement_size_value;
                                  const price = item?.total_price - (item?.quantity * item?.discount_on_mrp);
                                  const color = item?.color;
                                  return (
                                    <>
                                      <div className="product-list-inner">
                                        <div className="product-img">
                                          <img className="img-block" src={imageUrl} />
                                        </div>
                                        <div className="product-detail">
                                          <div className="product-heading">
                                            <h4 className="product-name">{product_name}({color})</h4>
                                          </div>
                                          <div className="product-desc">
                                            {brand_name}
                                          </div>
                                          <div className="product-actions">
                                            <div className="product-desc">
                                              <span>Size: {size}</span>
                                              <span className="price">QTY: {item.quantity}</span>
                                              <span className="price">&#8377;{convertIntoMoneyFormat(price)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                              }
                              <div className="status-wrapper">
                                <div className="order-status confirmed">{status}</div>
                              </div>
                            </Grid>
                          </>)
                      }) :
                      <span className="not-found">No Record Found</span>
                    }
                  </Grid>
                </Grid>
              </TabContainer>
            )}

            {/* Ready to Ship tab */}
            {tabIndex === 2 && (
              <TabContainer>
                <Grid container className="product-wrapper">
                  <Grid className="inventory-heading" item xs={12}>
                    {this.renderSearchInput()}
                    <div className="btn-wrapper">
                      {this.renderFilterList()}
                      <div className="menu-block">
                        <Menu
                          menuButton={
                            <div>
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          }
                        >
                          <MenuItem onClick={() => this.onConfirmShipment()}>Confirm Shipment</MenuItem>
                          <MenuItem onClick={() => this.onPrintInvoice()}>Print Invoice</MenuItem>
                          <MenuItem onClick={() => this.onPrintShippingLable()}>Print Shipping Lable</MenuItem>
                          <MenuItem onClick={() => this.onPrintManifest()}>Download Manifest</MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="productlist-wrapper">
                    {orderList &&
                      orderList.length ?
                      orderList.map((order: OrderModel) => {
                        const confirmOrderShipRocketId = order?.id;
                        const orderIdsForInvoice = order.attributes.order_id;
                        const shipmentId = order?.attributes?.shipping_detail?.shipment_id;
                        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                        const orderNumber = order && order.attributes && order.attributes.order_number;
                        const orderDate = order?.attributes?.updated_at;
                        const updatedOrderDate = moment(orderDate).format('DD/MM/YYYY');
                        const orderItems = order?.attributes?.order_items_details;
                        let status = order?.attributes?.status;
                        const isChecked = this.state.readyToShipSelectedOrderIds.includes(orderIdsForInvoice);
                        return (
                          <>
                            <Grid className="product-list pointer"
                              onClick={(e) => {
                                this.setState({
                                  showOrderDetailModal: true,
                                  selectedOrder: order
                                });
                              }}
                            >
                              <div className="orderid-date">
                                <div className="order-id">
                                  <Checkbox
                                    className="order-checkbox"
                                    checked={isChecked}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.handleCheckBox(orderIdsForInvoice, shipmentId, confirmOrderShipRocketId)
                                    }
                                    }
                                    inputProps={{
                                      "aria-label": "checkbox with default color",
                                    }}
                                  />
                                  Order ID: {orderNumber}
                                </div>
                                <span className="order-date">
                                  Scheduled on: {updatedOrderDate}
                                </span>
                              </div>
                              {orderItems &&
                                orderItems.length &&
                                orderItems.map((item: OrderItemModel, index: number) => {
                                  const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                  const product_name = item?.product_name;
                                  const description = item?.description;
                                  const brand_name = item?.brand;
                                  const size = item?.measurement_size_value;
                                  const price = item?.total_price - (item?.quantity * item?.discount_on_mrp);
                                  const color = item?.color;
                                  return (
                                    <>
                                      <div className="product-list-inner">
                                        <div className="product-img">
                                          <img className="img-block" src={imageUrl} />
                                        </div>
                                        <div className="product-detail">
                                          <div className="product-heading">
                                            <h4 className="product-name">{product_name}({color})</h4>
                                          </div>
                                          <div className="product-desc">
                                            {brand_name}
                                          </div>
                                          <div className="product-actions">
                                            <div className="product-desc">
                                              <span>Size: {size}</span>
                                              <span className="price">QTY: {item.quantity}</span>
                                              <span className="price">&#8377;{convertIntoMoneyFormat(price)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>)
                                })
                              }
                              <div className="status-wrapper">
                                <div className="order-status">Scheduled Pickup</div>
                              </div>
                            </Grid>
                          </>)
                      }) :
                      <span className="not-found">No Record Found</span>
                    }
                  </Grid>
                </Grid>
              </TabContainer>
            )}

            {/* Sent tab */}
            {tabIndex === 3 && (
              <TabContainer>
                <Grid container className="product-wrapper">
                  <Grid className="inventory-heading" item xs={12}>
                    {this.renderSearchInput()}
                    <div className="btn-wrapper">
                      {this.renderFilterList()}
                    </div>
                  </Grid>
                  <Grid item xs={12} className="productlist-wrapper">
                    {orderList &&
                      orderList.length ?
                      orderList.map((order: OrderModel) => {
                        const orderId = order.id;
                        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                        const orderNumber = order && order.attributes && order.attributes.order_number;
                        const orderDate = order?.attributes?.updated_at;
                        const updatedOrderDate = moment(orderDate).format('DD/MM/YYYY');
                        const orderItems = order?.attributes?.order_items_details;
                        let status = order?.attributes?.status;
                        return (
                          <>
                            <Grid
                              className="product-list pointer"
                              onClick={(e) => {
                                this.setState({
                                  showOrderDetailModal: true,
                                  selectedOrder: order
                                });
                              }}
                            >
                              <div className="orderid-date">
                                <div className="order-id">Order ID: {orderNumber}</div>
                                <span className="order-date">EDD: {updatedOrderDate}</span>
                              </div>
                              {orderItems &&
                                orderItems.length &&
                                orderItems.map((item: OrderItemModel, index: number) => {
                                  const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                  const product_name = item?.product_name;
                                  const description = item?.description;
                                  const brand_name = item?.brand;
                                  const size = item?.measurement_size_value;
                                  const price = item?.total_price - (item?.quantity * item?.discount_on_mrp);
                                  const color = item?.color;
                                  return (
                                    <>
                                      <div className="product-list-inner">
                                        <div className="product-img">
                                          <img className="img-block" src={imageUrl} />
                                        </div>
                                        <div className="product-detail">
                                          <div className="product-heading">
                                            <h4 className="product-name">{product_name}({color})</h4>
                                          </div>
                                          <div className="product-desc">
                                            {brand_name}
                                          </div>
                                          <div className="product-actions">
                                            <div className="product-desc">
                                              <span>Size: {size}</span>
                                              <span className="price">QTY: {item.quantity}</span>
                                              <span className="price">&#8377;{convertIntoMoneyFormat(price)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>)
                                })
                              }
                              <div className="status-wrapper">
                                <div className={`order-status`}>{status}</div>
                              </div>
                            </Grid>
                          </>)
                      }) :
                      <span className="not-found">No Record Found</span>
                    }
                  </Grid>
                </Grid>
              </TabContainer>
            )}

            {/* Cancelled tab */}
            {tabIndex === 4 && (
              <TabContainer>
                <Grid container className="product-wrapper">
                  <Grid className="inventory-heading" item xs={12}>
                    {this.renderSearchInput()}
                    <div className="btn-wrapper">
                      {this.renderFilterList()}
                    </div>
                  </Grid>
                  <Grid item xs={12} className="productlist-wrapper">
                    {orderList &&
                      orderList.length ?
                      orderList.map((order: OrderModel) => {
                        const orderId = order.id;
                        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                        const orderNumber = order && order.attributes && order.attributes.order_number;
                        const orderDate = order?.attributes?.updated_at;
                        const updatedOrderDate = moment(orderDate).format('DD/MM/YYYY');
                        const orderItems = order?.attributes?.order_items_details;
                        let status = order?.attributes?.status;
                        return (
                          <>
                            <Grid
                              className="product-list pointer"
                              onClick={(e) => {
                                this.setState({
                                  showOrderDetailModal: true,
                                  selectedOrder: order
                                });
                              }}
                            >
                              <div className="orderid-date">
                                <div className="order-id">Order ID: {orderNumber}</div>
                                <span className="order-date">
                                  Cancelled on: {updatedOrderDate}
                                </span>
                              </div>
                              {orderItems &&
                                orderItems.length &&
                                orderItems.map((item: OrderItemModel, index: number) => {
                                  const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                  const product_name = item?.product_name;
                                  const description = item?.description;
                                  const brand_name = item?.brand;
                                  const size = item?.measurement_size_value;
                                  const price = item?.total_price - (item?.quantity * item?.discount_on_mrp);
                                  const color = item?.color;
                                  return (
                                    <>
                                      <div className="product-list-inner">
                                        <div className="product-img">
                                          <img className="img-block" src={imageUrl} />
                                        </div>
                                        <div className="product-detail">
                                          <div className="product-heading">
                                            <h4 className="product-name">{product_name}({color})</h4>
                                          </div>
                                          <div className="product-desc">
                                            {brand_name}
                                          </div>
                                          <div className="product-actions">
                                            <div className="product-desc">
                                              <span>Size: {size}</span>
                                              <span className="price">QTY: {item.quantity}</span>
                                              <span className="price">&#8377;{convertIntoMoneyFormat(price)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="status-wrapper">
                                        <div className="order-status cancelled">{status}</div>
                                      </div> */}
                                    </>)
                                })
                              }
                              <div className="status-wrapper">
                                {/* <div className="order-status cancelled">{status}</div> */}
                                <div className="order-status cancelled">Cancelled</div>
                              </div>
                            </Grid>
                          </>)
                      }) : null}
                  </Grid>
                </Grid>
              </TabContainer>
            )}

            {/* pagination component */}
            <br></br>
            {this.state.totalPage && <PaginationComponent handlePageChange={this.handlePageChange} totalPage={this.state.totalPage} />}

          </Container>
        </div>

        {/* confirm order modal */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.confirmOrder}
          className="cancel-order order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  confirmOrder: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <h2 className="modal-heading">
              Are you sure you want to confirm the order?
            </h2>
            <div className="sub-txt">Order ID: {this.state.selectedOrderNumber}</div>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="outline-btn"
                onClick={(e) => { this.setState({ confirmOrder: false }) }}
              >
                No
              </Button>
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn"
                onClick={(e) => {
                  this.setState({
                    confirmOrder: false,
                  }, () => this.updateOrderStatus());
                }}
              >
                Yes
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* cancel order */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.cancelOrder}
          className="cancel-order order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  cancelOrder: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <h2 className="modal-heading">
              Are you sure you want to cancel the order?
            </h2>
            <div className="sub-txt">Order ID: {this.state.selectedOrderNumber}</div>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="outline-btn"
                onClick={(e) => { this.setState({ cancelOrder: false }) }}
              >
                No
              </Button>
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn"
                onClick={(e) => {
                  this.setState({
                    cancelOrderReason: true,
                    cancelOrder: false,
                  }, () => this.getCancelReasonList());
                }}
              >
                Yes
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* cancel order reason */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.cancelOrderReason}
          className="cancel-order-reason order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Choose Reason to Cancel Order</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  cancelOrderReason: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "460px" }}>
            <div className="sub-txt">
              Are you sure you want to cancel the order ID number {this.state.selectedOrderNumber}?
            </div>
            <div className="form-group">
              <InputLabel id="product-category">Choose Reason</InputLabel>
              <Select
                labelId="product-category"
                id="demo-simple-select"
                displayEmpty
                value={this.state.cancelReasonId || ''}
                onChange={(e: any) => this.setState({ cancelReasonId: e.target.value })}
              >
                {this.state.cancelReasonList &&
                  this.state.cancelReasonList.length &&
                  this.state.cancelReasonList.map((reason) =>
                    <MenuItems value={reason.id}>{reason.attributes.reason}</MenuItems>
                  )
                }
              </Select>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="url">Write Here</InputLabel>
              <FormControl>
                <Input
                  type="text"
                  id="cancelReasonDescription"
                  name="cancelReasonDescription"
                  aria-describedby="my-helper-text"
                  value={this.state.cancelReasonDescription || ''}
                  onChange={(e) => this.setState({ cancelReasonDescription: e.target.value })} />
              </FormControl>
            </div>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn"
                disabled={this.state.cancelReasonId ? false : true}
                onClick={(e) => {
                  this.setState({
                    actionType: ORDER_STATUS_TYPES.CANCELLED,
                    cancelOrderReason: false
                  }, () => this.cancelReasonApi())
                }}
              >
                Cancel Order
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* order schedule */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.orderSchedule}
          className="order-schedule order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  orderSchedule: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <h2 className="modal-heading">
              Only one order can be scheduled in one go.
            </h2>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn full-width"
                onClick={(e) => { this.setState({ orderSchedule: false }); }}
              >
                Okay
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* react date picker schedule */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.isOpenDateRangePicker}
          className="order-schedule order-dialog"
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  isOpenDateRangePicker: false,
                  dayWiseOrder: ''
                });
              }}
            />
          </DialogTitle>
          <DialogContent>
            <h2 className="modal-heading">
              {this.renderDateRangePicker()}
            </h2>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn full-width"
                onClick={(e) => { this.setState({ isOpenDateRangePicker: false }, () => this.getOrderFilterByStartAndEndDate()); }}
              >
                Okay
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* order details */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.showOrderDetailModal}
          className="order-details order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            {/* discuss with siraj sir */}
            <span>Netplay Men Navy Blue Solid Hemley Neck T-shirt</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  showOrderDetailModal: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "575px" }}>
            <Grid item xs={12} className="productlist-wrapper">
              <Grid className="product-list">
                <div className="product-list-inner">
                  <ul className="order-details-list">
                    <li>
                      <div className="order-label">
                        <h3 className="order-heading">Order Details</h3>
                      </div>
                      <div className="order-desc">
                        {/* here status color will be dynamic */}
                        <div className={`order-status ${statusColor}`}>
                          {selectedOrder?.attributes?.status}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="order-label">Order ID</div>
                      <div className="order-desc">{selectedOrder?.attributes?.order_number}</div>
                    </li>
                    <li>
                      <div className="order-label">Order Date</div>
                      <div className="order-desc">{moment(selectedOrder?.attributes?.created_at).format('DD/MM/YYYY')}</div>
                    </li>
                    <li>
                      <div className="order-label">Fulfilled By</div>
                      <div className="order-desc">{selectedOrder?.attributes?.vendor_detail?.company_name}</div>
                    </li>
                    <li>
                      <div className="order-label">Customer Name</div>
                      <div className="order-desc">{selectedOrder?.attributes?.account?.attributes?.name}</div>
                    </li>
                    {status === ORDER_STATUS_TYPES.CANCELLED &&
                      <>
                        <li>
                          <div className="order-label">Cancelled Date</div>
                          <div className="order-desc">{moment(selectedOrder?.attributes?.updated_at).format('DD/MM/YYYY')}</div>
                        </li>
                        <li>
                          <div className="order-label">Cancellation Reason</div>
                          <div className="order-desc">{selectedOrder?.attributes?.reason?.other}</div>
                        </li>
                      </>
                    }
                  </ul>
                </div>
              </Grid>
              <Grid className="product-list">
                {selectedOrder?.attributes?.order_items_details &&
                  selectedOrder?.attributes?.order_items_details.length &&
                  selectedOrder?.attributes?.order_items_details.map((item) => {
                    console.log("order::", item);
                    const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                    const product_name = item?.product_name;
                    const description = item?.description;
                    const brand_name = item?.brand;
                    const size = item?.measurement_size_value;
                    const color = item?.color;
                    const sku = item?.sku;
                    return (
                      <>
                        <div className="product-list-inner">
                          <div className="product-img">
                            <img className="img-block" src={imageUrl} />
                          </div>
                          <div className="product-detail">
                            <div className="product-heading">
                              <h4 className="product-name">{product_name}({brand_name})</h4>
                            </div>
                            <div className="product-desc">
                              {description}
                            </div>
                            <div className="product-desc size-qty">
                              <span>Size: {size}</span>
                              <span className="price">Color: {color}</span>
                              <span>QTY: {item.quantity}</span>
                            </div>
                            <div className="product-desc">SKU ID: {sku}</div>
                          </div>
                        </div>
                      </>
                    )
                  })}
              </Grid>
              <Grid className="product-list">
                <div className="product-list-inner">
                  {this.state.urlTabParam === this.VendorOrderTabEnum.SENT ||
                    this.state.urlTabParam === this.VendorOrderTabEnum.CANCELLED &&
                    <div className={`current-status ${statusColor}`}>
                      {/* this value will be dynamic  */}
                      {selectedOrder?.attributes?.status === ORDER_STATUS_TYPES.SHIPPED ? 'EDD' :
                        selectedOrder?.attributes?.status === ORDER_STATUS_TYPES.DELIVERED ? 'Delivered on' :
                          selectedOrder?.attributes?.status === ORDER_STATUS_TYPES.CANCELLED ? 'Cancelled on' :
                            selectedOrder?.attributes?.status === ORDER_STATUS_TYPES.RTO ? 'Return on' :
                              selectedOrder?.attributes?.status === ORDER_STATUS_TYPES.EXCHANGE ? 'EDD' : ''}: &nbsp;
                      {moment(selectedOrder?.attributes?.updated_at).format('ddd, ll')}
                      {/* Delivered On Sat, 25 Jun 2020 */}
                    </div>
                  }
                  <h3 className="order-heading">Delivery Address</h3>
                  <div className="order-name">{selectedOrder?.attributes?.delivered_at?.name}</div>
                  <p>
                    {`${selectedOrder?.attributes?.delivered_at?.address}, ${selectedOrder?.attributes?.delivered_at?.city}, ${selectedOrder?.attributes?.delivered_at?.state} - ${selectedOrder?.attributes?.delivered_at?.zip_code}`}
                  </p>
                </div>
              </Grid>
              <Grid className="product-list">
                <div className="product-list-inner">
                  <h3 className="order-heading">Price Details</h3>
                  <ul className="order-details-list">
                    <li>
                      <div className="order-label">Total MRP</div>
                      <div className="order-desc">&#8377;{convertIntoMoneyFormat(total - total_tax || 0)}</div>
                    </li>
                    <li>
                      <div className="order-label">Vat Charge</div>
                      <div className="order-desc">&#8377;{convertIntoMoneyFormat(total_tax || 0)}</div>
                    </li>
                    <li className="order-total">
                      <div className="order-label">Total Amount</div>
                      <div className="order-desc">&#8377;{convertIntoMoneyFormat(total || 0)}</div>
                    </li>
                  </ul>
                </div>
              </Grid>
              {/* shipping detail */}
              {selectedOrder?.attributes?.shipping_detail?.shipment_id &&
                selectedOrder?.attributes?.status !== ORDER_STATUS_TYPES.CANCELLED &&
                <Grid className="product-list">
                  <div className="product-list-inner">
                    <h3 className="order-heading">Shipping Details</h3>
                    <ul className="order-details-list">
                      <li>
                        <div className="order-label">Shipping Date</div>
                        <div className="order-desc">{moment(selectedOrder?.attributes?.updated_at).format('DD/MM/YYYY')}</div>
                      </li>
                      <li>
                        <div className="order-label">Shipping Carrier</div>
                        <div className="order-desc">{selectedOrder?.attributes?.shipping_detail?.courier_name}</div>
                      </li>
                      <li>
                        <div className="order-label">AWB No.</div>
                        <div className="order-desc">{selectedOrder?.attributes?.shipping_detail?.awb_code}</div>
                      </li>
                      <li>
                        <div className="order-label">Shipping Fee</div>
                        <div className="order-desc">&#8377;{selectedOrder?.attributes?.shipping_detail.shipping_fee}</div>
                      </li>
                    </ul>
                  </div>
                </Grid>
              }
              {/* package detail */}
              {selectedOrder?.attributes?.package_detail &&
                selectedOrder?.attributes?.status !== ORDER_STATUS_TYPES.CANCELLED &&
                <Grid className="product-list">
                  <div className="product-list-inner">
                    <h3 className="order-heading">Package Details</h3>
                    <ul className="order-details-list">
                      <li>
                        <div className="order-label">Weight</div>
                        <div className="order-desc">{this.state.selectedOrder?.attributes?.package_detail?.package_dimention_weight}</div>
                      </li>
                      <li>
                        <div className="order-label">Length</div>
                        <div className="order-desc">{this.state.selectedOrder?.attributes?.package_detail?.package_length} CM</div>
                      </li>
                      <li>
                        <div className="order-label">Width</div>
                        <div className="order-desc">{this.state.selectedOrder?.attributes?.package_detail?.package_width} CM</div>
                      </li>
                      <li>
                        <div className="order-label">Height</div>
                        <div className="order-desc">{this.state.selectedOrder?.attributes?.package_detail?.package_height} CM</div>
                      </li>
                    </ul>
                  </div>
                </Grid>
              }
            </Grid>
          </DialogContent>
        </Dialog >

        {/* cancel order details
        < Dialog
          aria - labelledby="customized-dialog-duplicate"
        open = {this.state.cancelOrderDetails}
        className = "order-details order-dialog"
      >
        <DialogTitle id="customized-dialog-title">
          <span>Netplay Men Navy Blue Solid Hemley Neck T-shirt</span>
          <CloseIcon
            className="close-dialog"
            onClick={(e) => {
              this.setState({
                cancelOrderDetails: false,
              });
            }}
          />
        </DialogTitle>
        <DialogContent style={{ width: "575px" }}>
          <Grid item xs={12} className="productlist-wrapper">
            <Grid className="product-list">
              <div className="product-list-inner">
                <ul className="order-details-list">
                  <li>
                    <div className="order-label">
                      <h3 className="order-heading">Order Details</h3>
                    </div>
                    <div className="order-desc">
                      <div className="order-status cancelled">Cancelled</div>
                    </div>
                  </li>
                  <li>
                    <div className="order-label">Order ID</div>
                    <div className="order-desc">5878745884</div>
                  </li>
                  <li>
                    <div className="order-label">Order Date</div>
                    <div className="order-desc">18/6/21</div>
                  </li>
                  <li>
                    <div className="order-label">Fulfilled By</div>
                    <div className="order-desc">The Brand Shop</div>
                  </li>
                  <li>
                    <div className="order-label">Customer Name</div>
                    <div className="order-desc">Seema Chauhan</div>
                  </li>
                  <li>
                    <div className="order-label">Cancelled Date</div>
                    <div className="order-desc">22/6/21</div>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid className="product-list">
              <div className="product-list-inner">
                <div className="product-img">
                  <img className="img-block" src={championItem} />
                </div>
                <div className="product-detail">
                  <div className="product-heading">
                    <h4 className="product-name">Netplay</h4>
                  </div>
                  <div className="product-desc">
                    Men Navy Blue Solid Hemley Neck T-shirt
                  </div>
                  <div className="product-desc size-qty">
                    <span>Size: L</span>
                    <span>QTY: 1</span>
                  </div>
                  <div className="product-desc">SKU ID: 1574598785</div>
                </div>
              </div>
            </Grid>
            <Grid className="product-list">
              <div className="product-list-inner">
                <div className="current-status cancelled">
                  Cancelled On Sat, 22 Jun 2020
                </div>
                <h3 className="order-heading">Delivery Address</h3>
                <div className="order-name">Seema Chuhan</div>
                <p>
                  104-Diamond Heights, Nr-Coronation Park,Shanti Swaroop Tyagi
                  Marg, Model Town, Delhi - 110033
                </p>
              </div>
            </Grid>
            <Grid className="product-list">
              <div className="product-list-inner">
                <h3 className="order-heading">Price Details</h3>
                <ul className="order-details-list">
                  <li>
                    <div className="order-label">Total MRP</div>
                    <div className="order-desc">&#8377;1,499</div>
                  </li>
                  <li>
                    <div className="order-label">Vat Charge</div>
                    <div className="order-desc">&#8377;24</div>
                  </li>
                  <li className="order-total">
                    <div className="order-label">Total Amount</div>
                    <div className="order-desc">&#8377;1,523</div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog > */}
      </>
    );
  }
}
export default withStyles(styles)(VendorOrderManagement);
