import React from "react";
// Customizable Area Start
import { Button, Grid, TextField } from "@material-ui/core";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import InviteFriendsController, {
  Props,
  configJSON,
} from "./InviteFriendsController.web";
import { toast } from "react-toastify";
// Customizable Area End

export default class InviteFriends extends InviteFriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderInviteFriends = () => {
    return (
      <Grid xs={12}>
        <div className="user-detail-wrapper">
          <div className="user-detail-inner">
            <div className="settings">
              <h5>
                <b>Invite Friends</b>
              </h5>
              <br />
              <div className="invite-friends">
                <div className="form">
                  <label htmlFor="friends">Enter email addresses</label>
                  <input
                    type="text"
                    placeholder=""
                    value={this.state.emails}
                    onChange={(e: any) => {
                      this.setState({
                        ...this.state,
                        emails: e.target.value,
                      });
                    }}
                  />
                </div>
                {this.state.emailError.length > 0 && (
                  <span className="error">{this.state.emailError}</span>
                )}
                <p className="mt-2">
                  Multiple addresses separated by comma with space
                </p>
                <div className="btn-wrapper">
                  <Button
                    onClick={() => this.inviteFriends()}
                    variant="contained"
                    className="black-btn"
                    type="button"
                  >
                    Invite Friends
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="user-detail-inner">
            <div className="settings">
              <h5>
                <b>Invitation Link</b>
              </h5>
              <br />
              <div className="invite-friends">
                <div className="form">
                  <label htmlFor="friends">Your invite link</label>
                  <input
                    type="text"
                    placeholder=""
                    readOnly
                    value={`${window.location.origin}/Home`}
                  />
                </div>
                <br />
                <div className="btn-wrapper">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/Home`
                      );
                      toast.success("Copied!", {});
                    }}
                    variant="contained"
                    className="black-btn"
                    type="button"
                  >
                    Copy Link
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  render() {
    // Customizable Area Start
    return (
      <>
        <CustomerAccountSideBarWeb
          id={1}
          navigation={this.props.navigation}
          children={this.renderInviteFriends()}
          callbackShopWithFriend={this.props.callbackShopWithFriend}
        />
      </>
    );
  }
  // Customizable Area End
}
