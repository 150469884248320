Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DiscountsOffers";
exports.labelBodyText = "DiscountsOffers Body";
exports.endPointApiGetProductList = "catalogue/catalogues";
exports.methodTypeApiGetProductList = "GET";
exports.getCategorySubCategorySubSubCategoryApi = "bx_block_categories/categories";
exports.endPointApiGetCategoryList = "bx_block_categories/categories_listing";
exports.endPointApiGetSubCategoryList = "bx_block_categories/sub_categories_listing";
exports.methodTypeApiGetCategoryList = "GET";

exports.APIContentType = "application/json";

exports.methodTypeAPIGet = "GET";
exports.methodTypeAPIPost = "POST";
exports.methodTypeAPIPut = "PUT";
exports.methodTypeAPIDelete = "DELETE";

exports.CouponListAPIEndPoint = "bx_block_coupons/coupons?search=";
exports.DeleteCouponAPIEndPoint = "bx_block_coupons/destroy_coupons?coupon_id=";
exports.CreateCouponAPIEndPoint = "bx_block_coupons/coupons";
exports.ChangeCouponStatusAPIEndPoint = 'bx_block_coupons/change_coupon_status';
exports.GetCouponAPIEndPoint = 'bx_block_coupons/coupons/';

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End