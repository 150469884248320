import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import VendorAddAddressAdapter from "../../blocks/adapters/src/VendorAddAddressAdapter";
import VendorAddressListAdapter from "../../blocks/adapters/src/VendorAddressListAdapter";
import InventoryAdapter from "../../blocks/adapters/src/InventoryAdapter";
import ProductBasicDetailAdapter from "../../blocks/adapters/src/ProductBasicDetailAdapter";
import ProductPolicyAdapter from "../../blocks/adapters/src/ProductPolicyAdapter";
import ProductOtherInfoAdapter from "../../blocks/adapters/src/ProductOtherInfoAdapter";
import ProductDetailAdapter from "../../blocks/adapters/src/ProductDetailAdapter";
import ProductProductPriceAdapter from "../../blocks/adapters/src/ProductProductPriceAdapter";
//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const onboardingAdapter = new OnboardingAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const vendorAddAddressAdapter = new VendorAddAddressAdapter();
const vendorAddressListAdapter = new VendorAddressListAdapter();
const inventoryAdapter = new InventoryAdapter();
const productBasicDetailAdapter = new ProductBasicDetailAdapter();
const productDetailAdapter = new ProductDetailAdapter();
const productProductPriceAdapter = new ProductProductPriceAdapter();
const productOtherInfoAdapter = new ProductOtherInfoAdapter();
const productPolicyAdapter = new ProductPolicyAdapter();
//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to CaelumGaurav!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Sorting'}  onPress={() => navigation.navigate("Sorting")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'Ordermanagement'}  onPress={() => navigation.navigate("Ordermanagement")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'Couponcodegenerator'}  onPress={() => navigation.navigate("Couponcodegenerator")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'Invitefriends'}  onPress={() => navigation.navigate("Invitefriends")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'ProductQuickview'}  onPress={() => navigation.navigate("ProductQuickview")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ProductRecommendationEngine'}  onPress={() => navigation.navigate("ProductRecommendationEngine")} />
<CustomTextItem content={'CustomisedOrderStatus'}  onPress={() => navigation.navigate("CustomisedOrderStatus")} />
<CustomTextItem content={'PushNotifications2'}  onPress={() => navigation.navigate("PushNotifications2")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'SplitPayments'}  onPress={() => navigation.navigate("SplitPayments")} />
<CustomTextItem content={'UserGroups'}  onPress={() => navigation.navigate("UserGroups")} />
<CustomTextItem content={'OrderDetailView'}  onPress={() => navigation.navigate("OrderDetailView")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'AudioCall'}  onPress={() => navigation.navigate("AudioCall")} />
<CustomTextItem content={'ShoppingCart'}  onPress={() => navigation.navigate("ShoppingCart")} />
<CustomTextItem content={'OrderSummary'}  onPress={() => navigation.navigate("OrderSummary")} />
<CustomTextItem content={'LoyaltySystem'}  onPress={() => navigation.navigate("LoyaltySystem")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'RolesPermissions'}  onPress={() => navigation.navigate("RolesPermissions")} />
<CustomTextItem content={'AutomaticCheckoutCalculation'}  onPress={() => navigation.navigate("AutomaticCheckoutCalculation")} />
<CustomTextItem content={'Wishlist'}  onPress={() => navigation.navigate("Wishlist")} />
<CustomTextItem content={'AffiliateForm'}  onPress={() => navigation.navigate("AffiliateForm")} />
<CustomTextItem content={'A360PhotoViewer'}  onPress={() => navigation.navigate("A360PhotoViewer")} />
<CustomTextItem content={'DataImportexportcsv2'}  onPress={() => navigation.navigate("DataImportexportcsv2")} />
<CustomTextItem content={'Comments'}  onPress={() => navigation.navigate("Comments")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'DeepLinking'}  onPress={() => navigation.navigate("DeepLinking")} />
<CustomTextItem content={'PaymentAdmin'}  onPress={() => navigation.navigate("PaymentAdmin")} />
<CustomTextItem content={'DiscountsOffers'}  onPress={() => navigation.navigate("DiscountsOffers")} />
<CustomTextItem content={'Payments'}  onPress={() => navigation.navigate("Payments")} />
<CustomTextItem content={'PhoneVerification2'}  onPress={() => navigation.navigate("PhoneVerification2")} />
<CustomTextItem content={'EmailNotifications2'}  onPress={() => navigation.navigate("EmailNotifications2")} />
<CustomTextItem content={'PhotoLibrary'}  onPress={() => navigation.navigate("PhotoLibrary")} />
<CustomTextItem content={'RefundManagement'}  onPress={() => navigation.navigate("RefundManagement")} />
<CustomTextItem content={'GroupChat'}  onPress={() => navigation.navigate("GroupChat")} />
<CustomTextItem content={'Reviews'}  onPress={() => navigation.navigate("Reviews")} />
<CustomTextItem content={'ImageThumbnail'}  onPress={() => navigation.navigate("ImageThumbnail")} />
<CustomTextItem content={'CarouselDisplay0'}  onPress={() => navigation.navigate("CarouselDisplay0")} />
<CustomTextItem content={'AdHocReporting'}  onPress={() => navigation.navigate("AdHocReporting")} />
<CustomTextItem content={'AdminConsole'}  onPress={() => navigation.navigate("AdminConsole")} />
<CustomTextItem content={'AffiliateUrl'}  onPress={() => navigation.navigate("AffiliateUrl")} />
<CustomTextItem content={'BlogPostsManagement'}  onPress={() => navigation.navigate("BlogPostsManagement")} />
<CustomTextItem content={'ShareWishlistWithOtherUser'}  onPress={() => navigation.navigate("ShareWishlistWithOtherUser")} />
<CustomTextItem content={'Location'}  onPress={() => navigation.navigate("Location")} />
<CustomTextItem content={'SplitViewInterface'}  onPress={() => navigation.navigate("SplitViewInterface")} />
<CustomTextItem content={'ScreenSharing'}  onPress={() => navigation.navigate("ScreenSharing")} />
<CustomTextItem content={'ReorderAndReturnOfItem'}  onPress={() => navigation.navigate("ReorderAndReturnOfItem")} />
<CustomTextItem content={'IntegrationWithShopify'}  onPress={() => navigation.navigate("IntegrationWithShopify")} />
<CustomTextItem content={'IntegrationWithCaelum'}  onPress={() => navigation.navigate("IntegrationWithCaelum")} />
<CustomTextItem content={'RealtimeUpdates'}  onPress={() => navigation.navigate("RealtimeUpdates")} />
<CustomTextItem content={'CatalogueCreationForm'}  onPress={() => navigation.navigate("CatalogueCreationForm")} />
<CustomTextItem content={'Emojis'}  onPress={() => navigation.navigate("Emojis")} />

<CustomTextItem content={'PushNotifications'}  onPress={() => navigation.navigate("PushNotifications")} />
<CustomTextItem content={'LoyaltySystem2'}  onPress={() => navigation.navigate("LoyaltySystem2")} />
<CustomTextItem content={'ApiIntegration8'}  onPress={() => navigation.navigate("ApiIntegration8")} />
<CustomTextItem content={'RolesPermissions2'}  onPress={() => navigation.navigate("RolesPermissions2")} />
<CustomTextItem content={'AutomaticCheckoutCalculation2'}  onPress={() => navigation.navigate("AutomaticCheckoutCalculation2")} />
<CustomTextItem content={'Wishlist2'}  onPress={() => navigation.navigate("Wishlist2")} />
<CustomTextItem content={'DataImportexportcsv'}  onPress={() => navigation.navigate("DataImportexportcsv")} />
<CustomTextItem content={'PaymentAdmin2'}  onPress={() => navigation.navigate("PaymentAdmin2")} />
<CustomTextItem content={'PhoneVerification'}  onPress={() => navigation.navigate("PhoneVerification")} />
<CustomTextItem content={'EmailNotifications'}  onPress={() => navigation.navigate("EmailNotifications")} />
<CustomTextItem content={'PhotoLibrary3'}  onPress={() => navigation.navigate("PhotoLibrary3")} />
<CustomTextItem content={'CarouselDisplay'}  onPress={() => navigation.navigate("CarouselDisplay")} />
<CustomTextItem content={'AdminConsole3'}  onPress={() => navigation.navigate("AdminConsole3")} />
<CustomTextItem content={'Location4'}  onPress={() => navigation.navigate("Location4")} />

<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'CatalogueCreationForm3'}  onPress={() => navigation.navigate("CatalogueCreationForm3")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'ItemAvailability'}  onPress={() => navigation.navigate("ItemAvailability")} />
<CustomTextItem content={'ReviewAndApproval'}  onPress={() => navigation.navigate("ReviewAndApproval")} />
<CustomTextItem content={'PeopleManagement2'}  onPress={() => navigation.navigate("PeopleManagement2")} />
<CustomTextItem content={'LiveChatSummary'}  onPress={() => navigation.navigate("LiveChatSummary")} />
<CustomTextItem content={'Trending'}  onPress={() => navigation.navigate("Trending")} />
<CustomTextItem content={'ContentManagement'}  onPress={() => navigation.navigate("ContentManagement")} />
<CustomTextItem content={'ContactsIntegration'}  onPress={() => navigation.navigate("ContactsIntegration")} />
<CustomTextItem content={'AppleIdSignIn2'}  onPress={() => navigation.navigate("AppleIdSignIn2")} />
<CustomTextItem content={'AffiliateForm3'}  onPress={() => navigation.navigate("AffiliateForm3")} />
<CustomTextItem content={'ShareWishlistWithOtherUser3'}  onPress={() => navigation.navigate("ShareWishlistWithOtherUser3")} />
<CustomTextItem content={'ReorderAndReturnOfItem3'}  onPress={() => navigation.navigate("ReorderAndReturnOfItem3")} />
<CustomTextItem content={'IntegrationWithShopify5'}  onPress={() => navigation.navigate("IntegrationWithShopify5")} />
<CustomTextItem content={'IntegrationWithCaelum2'}  onPress={() => navigation.navigate("IntegrationWithCaelum2")} />
<CustomTextItem content={'HamburgerMenu'}  onPress={() => navigation.navigate("HamburgerMenu")} />
{/* <CustomTextItem content={'VendorAddAddress'}  onPress={() => navigation.navigate("VendorAddAddress")} /> */}

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;