import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  file: any;
}

interface SS {
  id: any;
}

export default class CsvUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  CsvUploadCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      file: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CsvUploadCallId !== null &&
      this.CsvUploadCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CsvUploadCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.message) {
        this.setState({
          ...this.state,
          file: null,
        });
        toast.success(responseJson?.message, {
          position: "top-right",
        });
      } else if (responseJson?.error_message) {
        this.setState({
          ...this.state,
          file: null,
        });
        toast.warning(responseJson?.error_message, {position: "top-right"});
      } else {
        this.setState({
          ...this.state,
          file: null,
        });
        toast.success("We are processing your file we will send you an email once successfully imported.", { position: "top-right" });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }

  async componentDidMount() {}

  // Customizable Area Start

  uploadCsvFile = () => {
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    var formData = new FormData();
    formData.append("catalogue_file", this.state.file);

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CsvUploadCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkUploadAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };
  // Customizable Area End
}
