// App.js - WEB
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
// import firebase from 'firebase';
import { connect } from 'react-firebase';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import jwt_decode from 'jwt-decode';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav.web';
import Footer from '../../components/src/Footer.web';
import HomePage from '../../components/src/HomePage.Web';
// import Home from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
// import Wishlist from "../../blocks/Wishlist/src/Wishlist";
import Wishlist from '../../blocks/Wishlist/src/Wishlist.web';
import UserGroups from '../../blocks/UserGroups/src/UserGroups.web';
import DiscountsOffers from '../../blocks/DiscountsOffers/src/DiscountsOffers';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Payments from '../../blocks/Payments/src/Payments';
import Notifications from '../../blocks/Notifications/src/Notifications';
import CatalogueCreationForm from '../../blocks/CatalogueCreationForm/src/CatalogueCreationForm';
import ImageThumbnail from '../../blocks/ImageThumbnail/src/ImageThumbnail';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import ShareWishlistWithOtherUser from '../../blocks/ShareWishlistWithOtherUser/src/ShareWishlistWithOtherUser';
import DataImportexportcsv2 from '../../blocks/DataImportexportcsv2/src/DataImportexportcsv2';
import Comments from '../../blocks/Comments/src/Comments';
import IntegrationWithShopify from '../../blocks/IntegrationWithShopify/src/IntegrationWithShopify';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
import A360PhotoViewer from '../../blocks/360PhotoViewer/src/A360PhotoViewer';
// import PhoneVerification2 from '../../blocks/PhoneVerification2/src/PhoneVerification2';
import LoyaltyPoints from '../../blocks/PhoneVerification/src/LoyaltyPoints.web';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import AudioCall from '../../blocks/AudioCall/src/AudioCall';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
// import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web';
import RealtimeUpdates from '../../blocks/RealtimeUpdates/src/RealtimeUpdates';
import ReorderAndReturnOfItem from '../../blocks/ReorderAndReturnOfItem/src/ReorderAndReturnOfItem';
import AffiliateUrl from '../../blocks/AffiliateUrl/src/AffiliateUrl';
import ProductQuickview from '../../blocks/ProductQuickview/src/ProductQuickview';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import InviteFriends from '../../blocks/invitefriends/src/InviteFriends.web';
import AutomaticCheckoutCalculation from '../../blocks/AutomaticCheckoutCalculation/src/AutomaticCheckoutCalculation';
import LoyaltySystem from '../../blocks/LoyaltySystem/src/LoyaltySystem';
import PushNotifications2 from '../../blocks/PushNotifications2/src/PushNotifications2';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import IntegrationWithCaelum from '../../blocks/IntegrationWithCaelum/src/IntegrationWithCaelum';
import PhotoLibrary from '../../blocks/PhotoLibrary/src/PhotoLibrary';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary.web';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Location from '../../blocks/Location/src/Location';
import ScreenSharing from '../../blocks/ScreenSharing/src/ScreenSharing';
import GroupChat from '../../blocks/GroupChat/src/GroupChat';
import Emojis from '../../blocks/Emojis/src/Emojis';
import DeepLinking from '../../blocks/DeepLinking/src/DeepLinking';
import CarouselDisplay0 from '../../blocks/CarouselDisplay0/src/CarouselDisplay0';
import OrderDetailView from '../../blocks/OrderDetailView/src/OrderDetailView';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
// import AffiliateForm from "../../blocks/AffiliateForm/src/AffiliateForm";
import AffiliateForm from '../../blocks/AffiliateForm/src/AffiliateForm.web';
import AffiliateDashboard from '../../blocks/AffiliateForm/src/AffiliateDashboard.web';
import AffiliateSoldItem from '../../blocks/AffiliateForm/src/AffiliateSoldItem.web';
import AffiliatePreviousShared from '../../blocks/AffiliateForm/src/AffiliatePShared.web';
import AffiliateList from '../../blocks/AffiliateForm/src/AffiliateList.web';
import SellerInquiryForm from '../../blocks/AffiliateForm/src/SellerInquiryForm.web';
import Sorting from '../../blocks/sorting/src/Sorting';
import SplitViewInterface from '../../blocks/SplitViewInterface/src/SplitViewInterface';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import Couponcodegenerator from '../../blocks/couponcodegenerator/src/Couponcodegenerator';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import SplitPayments from '../../blocks/SplitPayments/src/SplitPayments';
import Reviews from '../../blocks/Reviews/src/Reviews';
import CustomerSettings from '../../blocks/Settings2/src/CustomerSettings.web';
import VendorSettings from '../../blocks/Settings2/src/VendorSettings.web';
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics';
// import PhoneVerification from '../../blocks/PhoneVerification/src/PhoneVerification';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import CarouselDisplay from '../../blocks/CarouselDisplay/src/CarouselDisplay';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import Location4 from '../../blocks/Location4/src/Location4';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Wishlist2 from '../../blocks/Wishlist2/src/Wishlist2';
import LoyaltySystem2 from '../../blocks/LoyaltySystem2/src/LoyaltySystem2';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import ApiIntegration8 from '../../blocks/ApiIntegration8/src/ApiIntegration8';
import IntegrationWithCaelum2 from '../../blocks/IntegrationWithCaelum2/src/IntegrationWithCaelum2';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import ReorderAndReturnOfItem3 from '../../blocks/ReorderAndReturnOfItem3/src/ReorderAndReturnOfItem3';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import IntegrationWithShopify5 from '../../blocks/IntegrationWithShopify5/src/IntegrationWithShopify5';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import AffiliateForm3 from '../../blocks/AffiliateForm3/src/AffiliateForm3';
import Trending from '../../blocks/Trending/src/Trending';
import LiveChatSummary from '../../blocks/LiveChatSummary/src/LiveChatSummary';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import CatalogueCreationForm3 from '../../blocks/CatalogueCreationForm3/src/CatalogueCreationForm3';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import ShareWishlistWithOtherUser3 from '../../blocks/ShareWishlistWithOtherUser3/src/ShareWishlistWithOtherUser3';
import HamburgerMenu from '../../blocks/HamburgerMenu/src/HamburgerMenu';
import AppleIdSignIn2 from '../../blocks/AppleIdSignIn2/src/AppleIdSignIn2';
import ContactsIntegration from '../../blocks/ContactsIntegration/src/ContactsIntegration';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
// filter t shirt page
import Filter from '../../blocks/filteritems/src/Filter.web';
// about us page
import Aboutus from '../../blocks/info-page/src/Aboutus.web';
import VendorHelpCenter from '../../blocks/info-page/src/VendorHelpCenter.web';
import CreateChart from '../../blocks/catalogue/src/CreateChart.web';
import SizeCharts from '../../blocks/catalogue/src/SizeCharts.web';
import SizeChartsEmpty from '../../blocks/catalogue/src/SizeChartsEmpty.web';
// import PackagingWeb from '../../blocks/CatalogueCreationForm/src/Packaging.web';
import EditPackageBlock from '../../blocks/CatalogueCreationForm/src/EditPackageBlock.web';

// import AddressWeb from '../../blocks/CatalogueCreationForm/src/Address.web';
import AddressWeb from '../../blocks/ShoppingCart/src/DeliveryAddress.web';
import CustomerAddressBook from '../../blocks/OrderDetailView/src/CustomerAddressBook.web';
//no block decided now
import CustomerMyProfile from '../../blocks/OrderDetailView/src/CustomerMyProfile.web';
import CustomerEditProfile from '../../blocks/OrderDetailView/src/CustomerEditProfile.web';
import VendorAddAddress from '../../blocks/CatalogueCreationForm/src/VendorAddAddress.web';
import VendorAddressBook from '../../blocks/CatalogueCreationForm/src/VendorAddressBook.web';
import VendorProfile from '../../blocks/CatalogueCreationForm/src/VendorProfile.web';
import VendorDashboard from '../../blocks/AdminConsole/src/VendorDashboard.web';
import VendorEditProfile from '../../blocks/CatalogueCreationForm/src/VendorEditProfile.web';
import PackagingWeb from '../../blocks/CatalogueCreationForm/src/Packaging.web';
// Add package page
import AddNewPackage from '../../blocks/CatalogueCreationForm/src/AddNewPackage.web';

// inventory
import Inventory from '../../blocks/ProductQuickview/src/Inventory';
import AddProductBasicInfo from '../../blocks/ProductQuickview/src/AddProductBasicInfo';
import AddProductDetail from '../../blocks/ProductQuickview/src/AddProductDetail';
import AddProductOtherInfo from '../../blocks/ProductQuickview/src/AddProductOtherInfo';
import AddProductPolicy from '../../blocks/ProductQuickview/src/AddProductPolicy';
import AddProductPriceDetail from '../../blocks/ProductQuickview/src/AddProductPriceDetail';
import CsvUpload from '../../blocks/ProductQuickview/src/CsvUpload';
// homepage
import HomeStore from '../../blocks/catalogue/src/HomeStore.web';
// search component
// import Search from '../../blocks/catalogue/src/Search.web';
import BlogsPage from '../../blocks/catalogue/src/Blogs.web';
import BlogPage from '../../blocks/catalogue/src/Blog.web';
import ItemList from '../../blocks/catalogue/src/ItemList.web';
// import ShopByEditList from '../../blocks/catalogue/src/ShopByEditList.web';
// import BannerItemList from '../../blocks/catalogue/src/BannerItemList.web';
import CategoriesList from '../../blocks/catalogue/src/CategoriesList.web';
import Categories from '../../blocks/catalogue/src/Categories.web';
import ShoppingBag from '../../blocks/ShoppingCart/src/Shopping.web';

/**
 * size chart imports
 */
import SizeChartsEmptyWeb from '../../blocks/catalogue/src/SizeChartsEmpty.web';
import EditSizeCharts from '../../blocks/catalogue/src/EditSizeCharts.web';

// product detail web
import ProductDetail from '../../blocks/Wishlist2/src/ProductDetail.web';
import ProductReview from '../../blocks/Wishlist2/src/ProductReview.web';

// Payment page
import Payment from '../../blocks/ShoppingCart/src/Payment.web';

// profile
// import Profile from "../../components/src/Profile"

// Discount Coupons
import Coupons from '../../blocks/DiscountsOffers/src/Coupons.web';
import CreateCoupons from '../../blocks/DiscountsOffers/src/CreateCoupon.web';
import EditCouponWeb from '../../blocks/DiscountsOffers/src/EditCoupon.web';

import CustomerAccountSideBar from '../../components/src/CustomerAccountSideBar.web';
import CustomerOrderList from '../../blocks/OrderDetailView/src/CustomerOrderList.web';
import CancelOrder from '../../blocks/OrderDetailView/src/CancelOrder.web';
import CancelItem from '../../blocks/OrderDetailView/src/CancelItem.web';

// profile
// import Profile from "../../components/src/Profile"
// myorders customer alfred import
import MyOrderDetail from '../../blocks/OrderDetailView/src/MyOrderDetail.web';
import returnOrder from '../../blocks/OrderDetailView/src/ReturnOrder.web';
import returnOrderPickup from '../../blocks/OrderDetailView/src/ReturnOrderPickup.web';

// vendor Order Processing
import VendorOrderManagement from '../../blocks/ordermanagement/src/VendorOrderManagement.web';
import SchedulePickup from '../../blocks/ordermanagement/src/SchedulePickup.web';

// new Size chart imports
import NewCreateChart from '../../blocks/catalogue/src/NewCreateChart.web';
import NewEditSizeCharts from '../../blocks/catalogue/src/NewEditSizeCharts.web';
import NewSizeCharts from '../../blocks/catalogue/src/NewSizeCharts.web';
import { ROLE } from '../../framework/src/Enum';
import { Login_Message } from '../../framework/src/EnumMessage';
// loyalty points
// import loyaltyPoints from "../../blocks/LoyaltySystem/src/LoyaltyPoints.web"

// profile page design
// import MyProfile from '../../blocks/PhoneVerification/src/MyProfile';
// import EditProfile from '../../blocks/PhoneVerification/src/EditProfile';
// import Setting from '../../blocks/PhoneVerification/src/Setting';
// import LoyaltyPoints from "../../blocks/PhoneVerification/src/LoyaltyPoints";
import Policies from '../../blocks/PhoneVerification/src/Policies.web';
import Policy from '../../blocks/PhoneVerification/src/Policy.web';
import firebase, {
  firbaseAnalytic,
  firebaseRequestForTokenMessging
} from './firebase';
import axios from 'axios';
import { baseURL } from '../../framework/src/config';

const routeMap = {
  ShoppingBag: {
    component: ShoppingBag,
    path: '/ShoppingBag',
    roles: [ROLE.CUSTOMER]
  },

  // loyaltyPoints: {
  //   component: loyaltyPoints,
  //   path: '/loyaltyPoints',
  //   roles: [ROLE.CUSTOMER]
  // },

  payment: {
    component: Payment,
    path: '/payment',
    roles: [ROLE.CUSTOMER]
  },
  CategoriesList: {
    component: CategoriesList,
    path: '/CategoriesList'
  },
  Categories: {
    component: Categories,
    path: '/Categories'
  },
  Home: {
    component: HomeStore,
    path: '/Home',
    roles: [ROLE.ALL]
  },
  BlogsPage: {
    component: BlogsPage,
    path: '/BlogsPage',
    roles: [ROLE.ALL]
  },
  Blog: {
    component: BlogPage,
    path: '/BlogPage/:blogId',
    roles: [ROLE.ALL]
  },
  ItemList: {
    component: ItemList,
    path: '/ItemList',
    roles: [ROLE.ALL]
  },
  // ItemList?sub_sub_category_id=62
  // ItemList?sub_category_id=62
  // ItemList?category_id=62
  // ItemList?search=tshirt
  // ItemList?cta_id=6
  AddNewPackage: {
    component: AddNewPackage,
    path: '/AddNewPackage',
    roles: [ROLE.VENDOR]
  },
  EditPackageBlock: {
    component: EditPackageBlock,
    path: '/EditPackage',
    roles: [ROLE.VENDOR]
  },
  PackagingWeb: {
    component: PackagingWeb,
    path: '/packaging',
    roles: [ROLE.VENDOR]
  },
  CreateChart: {
    component: NewCreateChart,
    path: '/chart/add',
    roles: [ROLE.VENDOR]
  },
  SizeCharts: {
    component: NewSizeCharts,
    path: '/charts',
    roles: [ROLE.VENDOR],
    exact: true
  },
  EditSizeCharts: {
    component: NewEditSizeCharts,
    path: '/chart/edit/:id',
    roles: [ROLE.VENDOR]
  },
  SizeChartsEmptyWeb: {
    component: SizeChartsEmptyWeb,
    path: '/charts/empty',
    roles: [ROLE.VENDOR],
    exact: true
  },
  SellerInquiryForm: {
    component: SellerInquiryForm,
    path: '/SellerInquiryForm'
  },
  Aboutus: {
    component: Aboutus,
    path: '/Aboutus',
    roles: [ROLE.ALL]
  },
  VendorHelpCenter: {
    component: VendorHelpCenter,
    path: '/VendorHelpCenter',
    roles: [ROLE.VENDOR]
  },
  Filter: {
    component: Filter,
    path: '/Filter'
  },
  IntegrationWithCaelum2: {
    component: IntegrationWithCaelum2,
    path: '/IntegrationWithCaelum2'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  ReorderAndReturnOfItem3: {
    component: ReorderAndReturnOfItem3,
    path: '/ReorderAndReturnOfItem3'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability'
  },
  IntegrationWithShopify5: {
    component: IntegrationWithShopify5,
    path: '/IntegrationWithShopify5'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  AffiliateForm3: {
    component: AffiliateForm3,
    path: '/AffiliateForm3'
  },
  Trending: {
    component: Trending,
    path: '/Trending'
  },
  LiveChatSummary: {
    component: LiveChatSummary,
    path: '/LiveChatSummary'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  CatalogueCreationForm3: {
    component: CatalogueCreationForm3,
    path: '/CatalogueCreationForm3'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  ShareWishlistWithOtherUser3: {
    component: ShareWishlistWithOtherUser3,
    path: '/ShareWishlistWithOtherUser3'
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: '/HamburgerMenu'
  },
  AppleIdSignIn2: {
    component: AppleIdSignIn2,
    path: '/AppleIdSignIn2'
  },
  ContactsIntegration: {
    component: ContactsIntegration,
    path: '/ContactsIntegration'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },

  // PhoneVerification: {
  //   component: PhoneVerification,
  //   path: '/PhoneVerification'
  // },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: '/AutomaticCheckoutCalculation2'
  },
  Location4: {
    component: Location4,
    path: '/Location4'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Wishlist2: {
    component: Wishlist2,
    path: '/Wishlist2'
  },
  LoyaltySystem2: {
    component: LoyaltySystem2,
    path: '/LoyaltySystem2'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  ApiIntegration8: {
    component: ApiIntegration8,
    path: '/ApiIntegration8'
  },

  Wishlist: {
    component: Wishlist,
    path: '/Wishlist',
    roles: [ROLE.CUSTOMER]
  },
  // UserGroups: {
  //   component: UserGroups,
  //   path: '/UserGroups'
  // },
  DiscountsOffers: {
    component: DiscountsOffers,
    path: '/DiscountsOffers'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Payments: {
    component: Payments,
    path: '/Payments',
    roles: [ROLE.CUSTOMER]
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CatalogueCreationForm: {
    component: CatalogueCreationForm,
    path: '/CatalogueCreationForm'
  },
  ImageThumbnail: {
    component: ImageThumbnail,
    path: '/ImageThumbnail'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  ShareWishlistWithOtherUser: {
    component: ShareWishlistWithOtherUser,
    path: '/ShareWishlistWithOtherUser'
  },
  DataImportexportcsv2: {
    component: DataImportexportcsv2,
    path: '/DataImportexportcsv2'
  },
  Comments: {
    component: Comments,
    path: '/Comments'
  },
  IntegrationWithShopify: {
    component: IntegrationWithShopify,
    path: '/IntegrationWithShopify'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: '/BlogPostsManagement'
  },
  A360PhotoViewer: {
    component: A360PhotoViewer,
    path: '/A360PhotoViewer'
  },
  // PhoneVerification2: {
  //   component: PhoneVerification2,
  //   path: '/PhoneVerification2'
  // },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  AudioCall: {
    component: AudioCall,
    path: '/AudioCall'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  MobileAccountLogin: {
    component: MobileAccountLoginBlock,
    path: '/login'
  },
  RealtimeUpdates: {
    component: RealtimeUpdates,
    path: '/RealtimeUpdates'
  },
  ReorderAndReturnOfItem: {
    component: ReorderAndReturnOfItem,
    path: '/ReorderAndReturnOfItem'
  },
  AffiliateUrl: {
    component: AffiliateUrl,
    path: '/AffiliateUrl'
  },
  ProductQuickview: {
    component: ProductQuickview,
    path: '/ProductQuickview'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  InviteFriends: {
    component: InviteFriends,
    path: '/setting/InviteFriends',
    exact: true,
    roles: [ROLE.CUSTOMER]
  },
  AutomaticCheckoutCalculation: {
    component: AutomaticCheckoutCalculation,
    path: '/AutomaticCheckoutCalculation'
  },
  LoyaltySystem: {
    component: LoyaltySystem,
    path: '/LoyaltySystem'
  },
  PushNotifications2: {
    component: PushNotifications2,
    path: '/PushNotifications2'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  IntegrationWithCaelum: {
    component: IntegrationWithCaelum,
    path: '/IntegrationWithCaelum'
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: '/PhotoLibrary'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  ScreenSharing: {
    component: ScreenSharing,
    path: '/ScreenSharing'
  },
  GroupChat: {
    component: GroupChat,
    path: '/GroupChat'
  },
  Emojis: {
    component: Emojis,
    path: '/Emojis'
  },
  DeepLinking: {
    component: DeepLinking,
    path: '/DeepLinking'
  },
  CarouselDisplay0: {
    component: CarouselDisplay0,
    path: '/CarouselDisplay0'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  AffiliateForm: {
    component: AffiliateForm,
    path: '/AffiliateForm'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  SplitViewInterface: {
    component: SplitViewInterface,
    path: '/SplitViewInterface'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  SplitPayments: {
    component: SplitPayments,
    path: '/SplitPayments'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  CustomerSettings: {
    component: CustomerSettings,
    path: '/account/settings',
    exact: true,
    roles: [ROLE.CUSTOMER]
  },
  VendorSettings: {
    component: VendorSettings,
    path: '/VendorSettings',
    exact: true,
    roles: [ROLE.VENDOR]
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },

  //   Home: {
  // component:Filteritems,
  //     path: '/',
  //     exact: true
  //   }

  HomePage: {
    component: VendorDashboard,
    path: '/HomePage',
    roles: [ROLE.VENDOR]
  },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AddressWeb: {
    component: AddressWeb,
    path: '/deliveryAddress',
    roles: [ROLE.CUSTOMER]
  },
  CustomerAddressBook: {
    component: CustomerAddressBook,
    path: '/account/CustomerAddressBook',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  CustomerMyProfile: {
    component: CustomerMyProfile,
    path: '/account/CustomerMyProfile',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  LoyaltyPoints: {
    component: LoyaltyPoints,
    path: '/account/LoyaltyPoints',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  CustomerEditProfile: {
    component: CustomerEditProfile,
    path: '/account/CustomerMyProfile/CustomerEditProfile',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  Policies: {
    component: Policies,
    path: '/account/policies',
    roles: [ROLE.ALL],
    exact: true
  },
  Policy: {
    component: Policy,
    path: '/account/policy',
    roles: [ROLE.ALL],
    exact: true
  },
  VendorAddAddress: {
    component: VendorAddAddress,
    path: '/vendorAddAddress',
    roles: [ROLE.VENDOR]
  },
  VendorAddressBook: {
    component: VendorAddressBook,
    path: '/vendorAddressBook',
    roles: [ROLE.VENDOR]
  },

  VendorProfile: {
    component: VendorProfile,
    path: '/VendorProfile',
    roles: [ROLE.VENDOR]
  },
  VendorEditProfile: {
    component: VendorEditProfile,
    path: '/VendorEditProfile',
    roles: [ROLE.VENDOR]
  },
  PackagingWeb: {
    component: PackagingWeb,
    path: '/packaging',
    roles: [ROLE.VENDOR]
  },
  // Profile: {
  //   component: Profile,
  //   path: "/Profile"
  // },

  // vendor product management screen
  Inventory: {
    component: Inventory,
    path: '/Inventory',
    roles: [ROLE.VENDOR]
  },
  AddProductBasicInfo: {
    component: AddProductBasicInfo,
    path: '/AddProductBasicInfo',
    roles: [ROLE.VENDOR]
  },
  AddProductDetail: {
    component: AddProductDetail,
    path: '/AddProductDetail',
    roles: [ROLE.VENDOR]
  },
  AddProductOtherInfo: {
    component: AddProductOtherInfo,
    path: '/AddProductOtherInfo',
    roles: [ROLE.VENDOR]
  },
  AddProductPolicy: {
    component: AddProductPolicy,
    path: '/AddProductPolicy',
    roles: [ROLE.VENDOR]
  },
  AddProductPriceDetail: {
    component: AddProductPriceDetail,
    path: '/AddProductPriceDetail',
    roles: [ROLE.VENDOR]
  },
  ProductDetail: {
    component: ProductDetail,
    path: '/ProductDetail/:productId'
    // roles: [ROLE.CUSTOMER]
  },
  ProductReview: {
    component: ProductReview,
    path: '/ProductReview/:productId'
    // roles: [ROLE.CUSTOMER]
  },
  CsvUpload: {
    component: CsvUpload,
    path: '/CsvUpload',
    roles: [ROLE.VENDOR]
  },
  DiscountCoupons: {
    component: Coupons,
    path: '/coupons',
    roles: [ROLE.VENDOR]
  },
  CreateCoupons: {
    component: CreateCoupons,
    path: '/coupon/add',
    roles: [ROLE.VENDOR]
  },
  EditCoupon: {
    component: EditCouponWeb,
    path: '/coupon/edit/:id',
    roles: [ROLE.VENDOR]
  },

  // Customer account routes
  returnOrderPickup: {
    component: returnOrderPickup,
    path: '/account/orders/returnOrderPickup',
    roles: [ROLE.CUSTOMER],
    exact: true
  },

  returnOrder: {
    component: returnOrder,
    path: '/account/orders/returnOrder',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  MyOrderDetail: {
    component: MyOrderDetail,
    path: '/account/orders/OrderDetail/:id',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  cancelOrder: {
    component: CancelOrder,
    path: '/account/orders/cancelOrder/:id',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  cancelItem: {
    component: CancelItem,
    path: '/account/orders/cancelItem',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  orders: {
    component: CustomerOrderList,
    path: '/account/orders',
    roles: [ROLE.CUSTOMER],
    exact: true
  },
  customerAccountSideBar: {
    component: CustomerAccountSideBar,
    path: '/account',
    roles: [ROLE.CUSTOMER],
    exact: true
  },

  // vendor Order management routes
  vendorOrder: {
    component: VendorOrderManagement,
    path: '/vendorOrder/:tab',
    roles: [ROLE.VENDOR]
  },
  schedulePickup: {
    component: SchedulePickup,
    path: '/pickup/:orderId',
    roles: [ROLE.VENDOR]
  },
  AffiliateDashboard: {
    component: AffiliateDashboard,
    path: '/affiliate/dashboard',
    roles: [ROLE.AFFILIATE]
  },
  AffiliateSoldItem: {
    component: AffiliateSoldItem,
    path: '/affiliate/sold-item',
    roles: [ROLE.AFFILIATE]
  },
  AffiliatePreviousShared: {
    component: AffiliatePreviousShared,
    path: '/affiliate/previous-shared-item',
    roles: [ROLE.AFFILIATE]
  },
  AffiliateList: {
    component: AffiliateList,
    path: '/affiliate/affiliate-list',
    roles: [ROLE.AFFILIATE]
  }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyB2dLsioBhDq0vERNMnNwvKKBmYtK4e0fs',
//   authDomain: 'caelumgaurav-924a1.firebaseapp.com',
//   projectId: 'caelumgaurav-924a1',
//   storageBucket: 'caelumgaurav-924a1.appspot.com',
//   messagingSenderId: '962575695421',
//   appId: '1:962575695421:web:3dbb9414d78bdebfef7454',
//   measurementId: 'G-040XCE5XLZ'
// });

const onTokenExpireRedirectToLogin = token => {
  if (jwt_decode(token).exp < Date.now() / 1000) {
    toast.error(Login_Message.SessionExpired, {
      autoClose: 2000,
      toastId: 'ToastSessionExpired'
    });
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
  }
};

const App = () => {
  // const defaultAnalytics = firebaseAPI.analytics();
  // defaultAnalytics.logEvent('APP_Loaded');
  firbaseAnalytic.logEvent('APP_Loaded');
  firebaseRequestForTokenMessging();

  const [cartCount, SetCartCount] = useState(
    Number(localStorage.getItem('cartItemCount')) || 0
  );
  const [ProfileName, SetProfileName] = useState(
    localStorage.getItem('profilename')
  );
  const [isOpenShopWithFriend, setOpenShopWithFriend] = useState(false);

  toast.configure();

  const token = localStorage.getItem('authToken');
  if (token) {
    onTokenExpireRedirectToLogin(token);
  }

  const callbackShopWithFriend = isOpen => {
    localStorage.setItem('isGroupShoppingOpen', isOpen);
    setOpenShopWithFriend(isOpen);
  };

  const handleCartCount = cartItemCount => {
    SetCartCount(cartItemCount);
  };

  const updateProfileName = name => {
    SetProfileName(name);
  };

  useEffect(() => {
    if (token) {
      // get cart item data for display for total item in cart
      axios
        .get(`${baseURL}/bx_block_shopping_cart/cart_items`, {
          headers: {
            token: localStorage.getItem('authToken')
          }
        })
        .then(response => {
          if (response.status === 200) {
            const count = response?.data?.data?.length || 0;
            handleCartCount(count);
          }
        });
    }
  }, []);

  return (
    <View style={{ height: '100vh', width: '100vw' }}>
      <TopNav
        cartItemCount={cartCount}
        callbackShopWithFriend={callbackShopWithFriend}
        profileName={ProfileName}
      />

      {WebRoutesGenerator({ routeMap }, handleCartCount, updateProfileName, callbackShopWithFriend)}

      <ToastContainer />
      <ModalContainer />

      {/* Shop With Friend */}
      {localStorage.getItem('role') !== 'vendor' && (
        <UserGroups
          isOpen={isOpenShopWithFriend}
          callbackShopWithFriend={callbackShopWithFriend}
        />
      )}
      <Footer callbackShopWithFriend={callbackShopWithFriend} />
    </View>
  );
};

export default App;
