import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  coupons: any[];

  search: string;

  selectedCoupons: any[];

  pagination: any;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CouponsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCouponsListAPICallId: any;
  deleteCouponAPICallId: any;
  changeCouponStatusAPICallId: any;
  changeMulCouponStatusAPICallId: any;
  deleteMulCouponAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: true,
      coupons: [],

      search: "",

      selectedCoupons: [],

      pagination: null,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Get All Coupon List
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCouponsListAPICallId !== null &&
      this.getCouponsListAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getCouponsListAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          coupons: responseJson.data,
          loading: false,
          pagination: responseJson.meta,
        });
      } else {
        this.setState({ ...this.state, coupons: [], loading: false });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Delete Coupon
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteCouponAPICallId !== null &&
      this.deleteCouponAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.deleteCouponAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
      });

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Change Coupon Status
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.changeCouponStatusAPICallId !== null &&
      this.changeCouponStatusAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.changeCouponStatusAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        const newCoupon = responseJson.data[0];
        const newCouponList = this.state.coupons.map((coupon) =>
          coupon.id === newCoupon.id ? newCoupon : coupon
        );
        this.setState({
          ...this.state,
          loading: false,
          coupons: newCouponList,
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Change Multiple Coupon Status
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.changeMulCouponStatusAPICallId !== null &&
      this.changeMulCouponStatusAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.changeMulCouponStatusAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        const newCouponList = this.state.coupons.map((coupon: any) =>
          this.state.selectedCoupons.includes(coupon.id)
            ? responseJson.data.find((x: any) => x.id === coupon.id)
            : coupon
        );
        this.setState({
          ...this.state,
          loading: false,
          coupons: newCouponList,
          selectedCoupons: [],
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Delete Multiple Coupon
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteMulCouponAPICallId !== null &&
      this.deleteMulCouponAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.deleteMulCouponAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const newCouponList = this.state.coupons.filter(
        (coupon) => !this.state.selectedCoupons.includes(coupon.id)
      );
      this.setState({
        ...this.state,
        loading: false,
        coupons: newCouponList,
        selectedCoupons: [],
      });

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.getCouponList("");
    }
  }

  async componentDidMount() {
    this.getCouponList("");
  }

  // Get Coupon List API
  getCouponList = (search: string) => {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCouponsListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CouponListAPIEndPoint}${search}&per_page=6&page=${
        this.state.currentPage
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Delete Coupon API
  deleteCoupon = (id: string) => {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCouponAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteCouponAPIEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    const newCoupons = this.state.coupons.filter((coupon) => coupon.id !== id);
    this.setState({
      ...this.state,
      loading: true,
      coupons: newCoupons,
    });

    return true;
  };

  // Delete Coupon API
  deleteMultipleCoupon = () => {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMulCouponAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteCouponAPIEndPoint + this.state.selectedCoupons.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIDelete
    );

    this.setState({
      ...this.state,
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Change Coupon Status API
  changeCouponStatus = (id: string, status: string) => {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeCouponStatusAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.ChangeCouponStatusAPIEndPoint
      }?status=${status}&coupon_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loading: true,
    });

    return true;
  };

  // Change Multiple Coupon Status API
  changeMultipleCouponStatus = (status: string) => {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeMulCouponStatusAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.ChangeCouponStatusAPIEndPoint
      }?status=${status}&coupon_id=${this.state.selectedCoupons.toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loading: true,
    });

    return true;
  };
  // Customizable Area End
}
