Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

// exports.validationApiContentType = "application/json";
// exports.validationApiMethodType = "GET";
// exports.exampleAPiEndPoint = "EXAMPLE_URL";
// exports.exampleAPiMethod = "POST";
// exports.exampleApiContentType = "application/json";
// exports.textInputPlaceHolder = "Enter Text";
// exports.labelTitleText = "IntegrationWithCaelum";
// exports.labelBodyText = "IntegrationWithCaelum Body";

exports.apiMethodTypeGet = "GET";
exports.aboutUsApiContentType = "application/json";

exports.aboutUsAPIEndPoint = "bx_block_static_pages/static_pages/about_us";
exports.affiliateAPIEndPoint = "bx_block_static_pages/static_pages/affiliates";
exports.sellerAPIEndPoint = "bx_block_static_pages/static_pages/seller_registration";
exports.helpCenterAPIEndPoint = "bx_block_settings/help_center?user_type=customer";
exports.vendorHelpCenterAPIEndPoint = "bx_block_settings/help_center?user_type=vendor";
exports.questionAPIEndPoint = "bx_block_settings/question_answer?help_center_id=";

// exports.btnExampleTitle = "CLICK ME";
// Customizable Area End