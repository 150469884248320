import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { number, string } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  styleNumber: number;
  name: string;
  phone: string;
  nameError: string;
  emailError: string;
  phoneError: string;
  url: string;
  type_here: any;
  count: number;
  languageList: any;
  social: string;
  channel: string;
  email: string;
  other: string;
  name1: string;
  url1: string;
  social2: string;
  type: string;
  emailRegex: any;
  inputBox: any;
  qualifiArray: any;
  channelArray: any;
  enableFieldType: boolean;
  channels_attributes: any;
  channelsWithoutId: any;
  channelArrayEmpty: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AffiliateFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailReg: RegExp;
  apiAffiliateCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      channelArrayEmpty: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      // Customizable Area Start
      styleNumber: 624,
      inputBox: [{ value: "" }, { value: "" }],
      channelArray: [
        { id: 1, channel_name: "", url: "", social: "", type_here: "" },
      ],
      qualifiArray: [
        {
          id: 1,
          qualification: "",
          description: "",
          year_from: "",
          year_to: "",
        },
      ],
      channels_attributes: [
        { channel_name: "", url: "", social: "", type_here: "" },
      ],
      channelsWithoutId: [],
      other: "",
      email: "",
      channel: "",
      social: "",
      type_here: "",
      url: "",
      phone: "",
      nameError: "",
      emailError: "",
      phoneError: "",
      name: "",
      type: "",
      url1: "",
      social2: "",
      name1: "",
      languageList: ["facebook", "twitter", "instagram", "linkedIn", "youtube"],
      count: 0,
      enableFieldType: false,
      emailRegex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // async receive(from: string, message: Message) {

  //   runEngine.debugLog("Message Received", message);

  //   if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
  //     let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  //     this.showAlert(
  //       "Change Value",
  //       "From: " + this.state.txtSavedValue + " To: " + value
  //     );

  //     this.setState({ txtSavedValue: value });
  //   }

  //   // Customizable Area Start

  //   if (getName(MessageEnum.RestAPIRequestMessage) === message.id && this.apiAffiliateCallId !== null &&
  //   this.apiAffiliateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
  //     console.log('----->', MessageEnum.RestAPIRequestMessage);
  //     console.log('--------------->',message.id);
  //     // alert("Your affiliate form submitted to admin")

  //     this.apiAffiliateCallId = null;

  //     var responseJson = message.getData(
  //       getName(MessageEnum.RestAPIResponceSuccessMessage)
  //     );

  //     var errorReponse = message.getData(
  //       getName(MessageEnum.RestAPIResponceErrorMessage)
  //     );

  //     if (responseJson && responseJson.meta && responseJson.meta.token) {
  //       runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  //       //Need To send Login token message to save for future call
  //       console.log('response--------------------->>>add address', responseJson);
  //       // alert("Your affiliate form submitted to admin")

  //     } else {
  //       //Check Error Response
  //       this.parseApiErrorResponse(responseJson);
  //     }
  //     this.parseApiCatchErrorResponse(errorReponse);
  //   }
  //   // Customizable Area End

  // }

  //test

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.apiAffiliateCallId) {
          this.afterSubmitted();
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiAffiliateCallId) {
        }
      } else if (errorReponse) {
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  typeChange(value: string) {
    this.setState({ social2: value });
    if (this.state.social2 === value) {
      this.setState({ enableFieldType: true });
    }
  }
  moreData() {
    if (this.state.enableField) {
      this.setState({ count: +1 });
    } else {
      this.setState({ enableField: true });
      this.setState({ styleNumber: 930 });
    }
  }

  removeData(value: any) {
    // this.setState({ enableField: false })
    // this.setState({ styleNumber: 624 })
    // this.setState({count:-1})
    alert(value);
  }
  public changeState(objectID: string, value: any = null) {
    switch (objectID) {
      case "txtInputName":
        this.setState({ name: value });
        break;
      case "txtInputEmail":
        this.setState({ email: value });
        break;
      case "txtInputPhone":
        this.setState({ phone: value });
        break;
      case "txtInputSocial":
        this.setState({ social: value });
        break;
      case "txtInputChannel":
        this.setState({ channel: value });
        break;
      case "txtInputUrl":
        this.setState({ url: value });
        break;
      case "txtInputUrl1":
        this.setState({ url1: value });
        break;
      case "txtInputOthers1":
        this.setState({ other: value });
        break;
      case "txtInputType":
        this.setState({ type: value });
        break;
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  }
  isStringNullOrBlank(str: string | number) {
    return str === null || str.toString().length === 0;
  }

  //test
  // addQualificationInput = (value: any, item: any, isFromTextField: any) => {
  //   console.log("isFromTextField", isFromTextField)
  //   let newMulticityFormData = this.state.qualifiArray;
  //   let selectedIndex = newMulticityFormData.findIndex((itemFilter: any) => itemFilter.id === item.id);
  //   if (isFromTextField === 'qualification')
  //     newMulticityFormData[selectedIndex].qualification = value;
  //   else if (isFromTextField === 'description')
  //     newMulticityFormData[selectedIndex].description = value;
  //   else if (isFromTextField === 'year_from')
  //     newMulticityFormData[selectedIndex].year_from = value;
  //   else if (isFromTextField === 'year_to')
  //     newMulticityFormData[selectedIndex].year_to = value;
  //   this.setState({ qualifiArray: newMulticityFormData }, () => {
  //     console.log("@@@@@ qualifiArray23723673", this.state.qualifiArray)
  //   });

  // }
  //channel data
  // ्data storing
  addChannelInput = (value: any, item: any, isFromTextField: any) => {
    let newChannelFormData = this.state.channelArray;
    let selectedIndex = newChannelFormData.findIndex(
      (itemFilter: any) => itemFilter.id === item.id
    );
    if (isFromTextField === "channel_name")
      newChannelFormData[selectedIndex].channel_name = value;
    else if (isFromTextField === "url")
      newChannelFormData[selectedIndex].url = value;
    else if (isFromTextField === "social")
      newChannelFormData[selectedIndex].social = value;
    else if (isFromTextField === "type_here")
      newChannelFormData[selectedIndex].type_here = value;
    this.setState({ channelArray: newChannelFormData }, () => {});
    // this.state.channelArray.map((val:any) =>  {
    //   let obj = {channel_name: val.channel_name,
    //     url: val.url,
    //     social: val.social,
    //     type_here: val.type_here,
    //   }

    //   let allChannels = this.state.channelsWithoutId
    //   allChannels.push(obj)
    //   console.log(obj,"map")})
    //   console.log(this.state.channelsWithoutId)
  };

  // onAddQualificationView = () => {
  //   let attribute: any = {
  //     id: this.state.qualifiArray.length + 1,
  //     qualification: '',
  //     description: '',
  //     year_from: '',
  //     year_to: '',

  // }

  // let newData = this.state.qualifiArray;
  // newData.push(attribute);
  // this.setState({ qualifiArray: newData }, () => {
  //   console.log('@@@ qualifiArray data  ======', this.state.qualifiArray)
  // })
  //   }

  // add more channel
  onAddChannelView = () => {
    this.setState({ channelArrayEmpty: false });
    let channelAttributes: any = {
      id: this.state.channelArray.length + 1,
      channel_name: "",
      url: "",
      social: "",
      type_here: "",
    };
    let newChannel = this.state.channelArray;
    newChannel.push(channelAttributes);
    this.setState({ channelArray: newChannel }, () => {
      this.setState({ channels_attributes: newChannel });
    });
  };
  // remove channel
  onRemoveChannelView = (index: any) => {
    this.state.channelArray.splice(index, 1);
    this.setState({ channels_attributes: this.state.channelArray });
    // new updates for empty array /
    if (this.state.channelArray.length == 0) {
      this.setState({ channelArrayEmpty: true });
    }
    return this.setState({ channelArray: this.state.channelArray });
  };

  //   send button
  afterSubmitted = () => {
    alert("Your affiliate registration form submitted to admin");
    this.setState({ name: "" }),
      this.setState({ phone: "" }),
      this.setState({ email: "" }),
      this.setState({
        channelArray: [
          { id: 1, channel_name: "", url: "", social: "", type_here: "" },
        ],
      });
    location.reload();
  };
  // dataFlowChannel =(value:any)=>{
  //   this.state.channelArray.map(valueChannel:string)=>{
  //     {
  //     channel_name:any= valueChannel.channel_name,
  //     url: valueChannel.url,
  //    social:valueChannel.social,
  //   type_here: valueChannel.type_here
  //  }
  //  }

  //   let channels_attributes:any={
  //   channel_name: '',
  //   url: '',
  //   social: '',
  //   type_here: '',
  //   }
  //   channels_attributes.channel_name.push(value.channel_name);
  //   channels_attributes.url.push(value.url);
  //   channels_attributes.social.push(value.social);
  //   channels_attributes.type_here.push(value.type_here);
  //   return channels_attributes

  // }

  doAffiliate(): boolean {
    // if (this.state.name == null || this.state.email == null || this.state.phone ==null) {
    //     alert("Please fill required data");
    //     return false;
    //   }

    if (
      this.isStringNullOrBlank(this.state.name) &&
      this.isStringNullOrBlank(this.state.phone) &&
      this.isStringNullOrBlank(this.state.email)
    ) {
      this.setState({ nameError: "Please fill name field" });
      this.setState({ emailError: "Please fill email field" });
      this.setState({ phoneError: "Please fill phone number field" });
      window.scrollTo(0, 0);

      return false;
    } else if (
      !this.state.emailRegex.test(
        this.state.email || this.state.phone.toString().length !== 10
      ) ||
      this.isStringNullOrBlank(this.state.name)
    ) {
      if (!this.state.emailRegex.test(this.state.email)) {
        this.setState({ emailError: "Email is not valid" });
        //  return false;
      } else {
        this.setState({ emailError: "" });
      }
      if (this.state.phone.toString().length !== 10) {
        this.setState({ phoneError: "Phone number is not valid" });
        // return false;
      } else {
        this.setState({ phoneError: "" });
      }

      if (this.isStringNullOrBlank(this.state.name)) {
        this.setState({ nameError: "Please fill name field" });
      } else {
        this.setState({ nameError: "" });
      }
      return false;
      // }else if (this.state.phone.toString().length !== 10) {
      //   this.setState({phoneError:"Phone number is not valid"})
      //   return false;
    } else {
      this.setState({ nameError: "" });
      this.setState({ emailError: "" });
      this.setState({ phoneError: "" });
    }
    const header = {
      "Content-Type": configJSON.AffiliateApiContentType,
    };

    // const channels_attributes = {
    //   channel_name: this.state.channel,
    //   url: this.state.url,
    //   social: this.state.social,
    //   type_here: this.state.type
    // }
    //  const {channel_name,url,social,type_here}={}
    // important .........................................
    this.state.channelArray.map((val: any) => {
      let obj = {
        channel_name: val.channel_name,
        url: val.url,
        social: val.social,
        type_here: val.type_here,
      };

      let allChannels = this.state.channelsWithoutId;
      allChannels.push(obj);
    });

    // final step sending data to api
    const data = {
      name: this.state.name,
      full_phone_number: this.state.phone,
      email: this.state.email,
      channels_attributes: this.state.channelsWithoutId,

      // { channel_name: this.state.channelArray.channel_name,
      //    url: this.state.channelArray.url,
      //   social:this.state.channelArray.social,
      //  type_here: this.state.channelArray.type_here
      // }
    };

    const httpBody = {
      data: data,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiAffiliateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAffiliateAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.AffiliateAPiMethod
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // }else if (this.state.phone.toString().length !== 10) {
  //   this.setState({phoneError:"Phone number is not valid"})
  //   return false;

  // if ((!this.state.emailRegex.test(this.state.email))) {
  //   alert("Email is not valid")
  //   return false;
  // }
  // if (this.state.phone.toString().length !== 10) {
  //   alert("Phone number is not valid")
  //   return false;
  // }

  //  if(!this.state.channelArray){
  //    for(let i:number=0, i<this.state.channelArray.length,i++ ){
  //   this.state.channelArray.[i].channel_name,
  //  this.state.channelArray.[i].url,
  //     this.state.channelArray.[i].social,
  //    this.state.channelArray.[i].type_here,
  //   }
  // }

  // Customizable Area End
}
