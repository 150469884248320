Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AffiliateForm";
exports.labelBodyText = "AffiliateForm Body";

exports.btnExampleTitle = "CLICK ME";

//Affiliate
exports.apiAffiliateAPiEndPoint = "bx_block_settings/affiliate_inquiries";
exports.AffiliateAPiMethod = "POST";
exports.AffiliateApiContentType = "application/json";
//Seller
exports.apiSellerAPiEndPoint= "bx_block_settings/vendor_inquiries";
exports.btnExampleTitle = "CLICK ME";
//categories
exports.apiCategoryAPiEndPoint= "bx_block_categories/categories_listing";



// Customizable Area End