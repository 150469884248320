export const AboutUsStyle: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "20px 200px",
      width: "100%",
      "@media (max-width:990px)": {
        padding: "20px 100px",
      },
      "@media (max-width:767px)": {
        padding: "20px 10px",
      },
    },
    "& .block-title": {
      fontFamily: "'Roboto',sans-serif",
      marginBottom: "20px",
    },
    "& .content": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#666",
      marginBottom: "30px",
    },
    "& .img-block": {
      height: "350px",
      objectFit: "cover",
      width: "100%",
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },
    "& .accordion-wrapper": {
      marginBottom: "40px",
    },
    "& .detail-block": {
      border: "1px solid #e8e8e8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 0 30px",
      marginBottom: "20px",
      marginTop: "10px",
    },
    "& .detail-block p": {
      marginBottom: "0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .btn-wrapper": {
      display: "flex",
      justifyContent: "center",
    },
    "& .black-btn": {
      backgroundColor: "#000",
      color: "#fff",
      borderRadius: "0",
      padding: "10px",
      minWidth: "250px",
      textTransform: "capitalize",
      boxShadow: "none",
    },
    "& .custom-list li p": {
      margin: "0 0 15px",
    },
    "& .custom-list": {
      paddingLeft: "20px",
      listStyle: "square",
    },
    "& .MuiTabs-flexContainer": {
      display: "block",
      textAlign: "center",
    },
    "& #help .helpCenter": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px 0",
      cursor: "pointer",
      borderBottom: "1px solid #dddddd",
    },
    "& #help .helpCenter p": {
      margin: "0",
    },
    "& .about-image": {
      width: "100%",
      height: "350px",
      marginBottom: "20px",
      objectFit: "contain",
    },
    "& .heading span": {
      fontSize: "22px",
      color: "black",
      marginBottom: "15px",
    },
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    marginBottom: "30px",
  },
  tabsIndicator: {
    backgroundColor: "black",
  },
};
