// @ts-ignore
//@ts-nocheck
import React from "react";
import { Container, Grid, Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Pagination from "@material-ui/lab/Pagination";
import CreateCouponController, {
  Props,
  // configJSON
} from "./CreateCouponController.web";
import { CreateCouponStyle } from "./CreateCouponStyle.web";

export class CreateCoupon extends CreateCouponController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    console.log(this.state.errors);

    return (
      // Customizable Area Start
      <div className={classes.pageOuter}>
        <div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <div className="block-wrapper order-block">
                  <div className="block-wrapper-inner">
                    <h2>Create Coupons</h2>
                  </div>
                </div>
              </Grid>

              <div className="create-coupon-form">
                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    this.createCoupon();
                  }}
                >
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Create New Coupon</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="coupon-info">
                        <div>
                          <label htmlFor="coupon-code">Coupon Code *</label>
                          <input
                            type="text"
                            placeholder="Coupon Code"
                            name=""
                            value={this.state.couponCode}
                            id="coupon-code"
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                couponCode: e.target.value,
                              });
                            }}
                          />
                          <span className="error">
                            {this.state.errors["couponCodeError"]}
                          </span>
                        </div>
                        <div>
                          <label htmlFor="desc">Description *</label>
                          <textarea
                            name=""
                            id="desc"
                            placeholder="Description"
                            value={this.state.description}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                          <span className="error">
                            {this.state.errors["descriptionError"]}
                          </span>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="coupon-product">
                        <span className="error">
                          {this.state.errors["productError"]}
                        </span>
                        <br />
                        <label htmlFor="products">Products</label>
                        <div className="select">
                          <input
                            className="input"
                            placeholder="Products"
                            value={this.state.selectedProducts.toString()}
                            onClick={this.toggleProductModal}
                          />
                          <KeyboardArrowDownIcon />
                        </div>

                        <label htmlFor="exclude-products">
                          Exclude Products
                        </label>
                        <div className="select">
                          <input
                            placeholder="Exclude Products"
                            value={this.state.selectedExProducts.toString()}
                            onClick={this.toggleExProductModal}
                            className="input"
                          />
                          <KeyboardArrowDownIcon />
                        </div>

                        <label htmlFor="product-categories">
                          Product Categories
                        </label>
                        <div className="multi-select-menu">
                          <ReactMultiSelectCheckboxes
                            options={this.state.categoryMenuView}
                            isSearchable={false}
                            placeholderButtonLabel="Product Categories"
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            hideSearch={true}
                            onChange={this.handleCategory}
                            value={this.state.selectedCategory}
                          />
                        </div>

                        <label htmlFor="exclude-categories">
                          Exclude Categories
                        </label>
                        <div className="multi-select-menu">
                          <ReactMultiSelectCheckboxes
                            options={this.state.categoryMenuView}
                            isSearchable={false}
                            placeholderButtonLabel="Exclude Categories"
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            hideSearch={true}
                            onChange={this.handleExCategory}
                            value={this.state.selectedExCategory}
                          />
                        </div>

                        <label htmlFor="products-sub-categories">
                          Products Sub categories
                        </label>
                        <div className="multi-select-menu">
                          <ReactMultiSelectCheckboxes
                            options={this.state.subCategoryMenuView}
                            isSearchable={false}
                            placeholderButtonLabel="Products Sub categories"
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            hideSearch={true}
                            onChange={this.handleSubCategory}
                            value={this.state.selectedSubCategory}
                          />
                        </div>

                        <label htmlFor="exclude-sub-categories">
                          Exclude Sub Categories
                        </label>
                        <div className="multi-select-menu">
                          <ReactMultiSelectCheckboxes
                            options={this.state.exSubCategoryMenuView}
                            isSearchable={false}
                            placeholderButtonLabel="Exclude Sub Categories"
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            hideSearch={true}
                            onChange={this.handleExSubCategory}
                            value={this.state.selectedExSubCategory}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Coupon Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="coupon-details">
                        <label htmlFor="types">Types *</label>
                        <select
                          name=""
                          id="types"
                          value={this.state.selectedType}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              selectedType: e.target.value,
                            });
                          }}
                        >
                          <option value="cash_discount">Amount Discount</option>
                          <option value="percentage_discount">
                            Percentage Discount
                          </option>
                        </select>
                        <div>
                          <label htmlFor="amount">
                            {this.state.selectedType === "cash_discount"
                              ? "Amount"
                              : "Percentage"}{" "}
                            *
                          </label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              placeholder={
                                this.state.selectedType === "cash_discount"
                                  ? "INR"
                                  : "%"
                              }
                              name=""
                              id="amount"
                              value={this.state.amount}
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  amount: e.target.value,
                                });
                              }}
                            />
                            <div className="amount-sign">
                              {this.state.selectedType === "cash_discount"
                                ? "₹"
                                : "%"}
                            </div>
                          </div>
                          <span className="error">
                            {this.state.errors["amountError"]}
                          </span>
                        </div>
                        <div>
                          <label htmlFor="eDate">Expiry Date *</label>
                          <input
                            type="date"
                            value={this.state.expiryDate}
                            placeholder="Expiry Date"
                            name=""
                            id="eDate"
                            onChange={(e) => {
                              this.setState({
                                ...this.state,
                                expiryDate: e.target.value,
                              });
                            }}
                          />
                          <span className="error">
                            {this.state.errors["expiryDateError"]}
                          </span>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Usage Restrictions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="usage-restriction">
                        {/* <label htmlFor="min-spend">Minimum Spend</label>
                        <input
                          type="text"
                          placeholder="Minimum Spend"
                          name=""
                          id="min-spend"
                          value={this.state.minimumSpend}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              minimumSpend: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="max-spend">Maximum Spend</label>
                        <input
                          type="text"
                          placeholder="Maximum Spend"
                          name=""
                          id="max-spend"
                          value={this.state.maximumSpend}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              maximumSpend: e.target.value,
                            });
                          }}
                        /> */}
                        {/* <div className="ur-select">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            value={this.state.individualUse}
                            onClick={(e) => {
                              this.setState({
                                ...this.state,
                                individualUse: e.target.checked,
                              });
                            }}
                          />
                          <span>Individual Use Only</span>
                        </div> */}
                        <div className="ur-select">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            value={this.state.excludeSaleItem}
                            onClick={(e) => {
                              this.setState({
                                ...this.state,
                                excludeSaleItem: e.target.checked,
                              });
                            }}
                          />
                          <span>Exclude Sale Items</span>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Usage Limits</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="usage-limit">
                        <label htmlFor="ulpc">Usage Limit Per Coupon</label>
                        <input
                          type="text"
                          placeholder="Usage Limit Per Coupon"
                          name=""
                          id="ulpc"
                          value={this.state.usageLimitPerCoupon}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              usageLimitPerCoupon: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="ulpcr">Usage Limit Per Customer</label>
                        <input
                          type="text"
                          placeholder="Usage Limit Per Customer"
                          name=""
                          id="ulpcr"
                          value={this.state.usageLimitPerCustomer}
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              usageLimitPerCustomer: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <div className="btn-wrapper">
                    <Button
                      variant="contained"
                      className="black-btn"
                      type="submit"
                    >
                      Create Coupon
                    </Button>
                  </div>
                </form>
              </div>
            </Grid>
          </Container>
        </div>

        <Dialog
          open={this.state.isProductModalOpen}
          onClose={this.toggleProductModal}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modal}
        >
          <DialogTitle id="alert-dialog-title">Products</DialogTitle>
          <DialogContent>
            <div className="products">
              <div className="search-product">
                <input
                  type="text"
                  placeholder="Search Product"
                  value={this.state.searchProduct}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      searchProduct: e.target.value,
                    });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      this.getAllProduct();
                    }
                  }}
                />
                <SearchIcon />
              </div>
              {this.state.products && this.state.products.length === 0 && (
                <p>No Record Found</p>
              )}
              {this.state.products &&
                this.state.products.map((product) => {
                  return (
                    <div className="product" key={product.id}>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        checked={this.state.selectedProducts.includes(
                          product.id
                        )}
                        onClick={(e: any) => {
                          this.handleProduct(e.target.checked, product.id);
                        }}
                      />
                      {product.attributes.galleries ? (
                        <img src={product.attributes.galleries[0].url} alt="" />
                      ) : (
                        <img src={`https://tinyurl.com/m48xzr4b`} alt="" />
                      )}
                      <p>
                        <span>{product.attributes.brand_name} </span>{" "}
                        {product.attributes.name}
                      </p>
                    </div>
                  );
                })}
              <Pagination
                onChange={(event: any, value: any) => {
                  this.setState({
                    ...this.state,
                    currentCPage: value,
                  });
                }}
                count={this.state.totalCPage}
                page={this.state.currentCPage}
              />
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>

        <Dialog
          open={this.state.isExProductModalOpen}
          onClose={this.toggleExProductModal}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modal}
        >
          <DialogTitle id="alert-dialog-title">Exclude Products</DialogTitle>
          <DialogContent>
            <div className="products">
              <div className="search-product">
                <input
                  type="text"
                  placeholder="Search Product"
                  value={this.state.searchExProduct}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      searchExProduct: e.target.value,
                    });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      this.getAllExProduct();
                    }
                  }}
                />
                <SearchIcon />
              </div>
              {this.state.eProducts && this.state.eProducts.length === 0 && (
                <p>No Record Found</p>
              )}
              {this.state.eProducts &&
                this.state.eProducts.map((product) => {
                  return (
                    <div className="product" key={product.id}>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        checked={this.state.selectedExProducts.includes(
                          product.id
                        )}
                        onClick={(e: any) => {
                          this.handleExProduct(e.target.checked, product.id);
                        }}
                      />
                      {product.attributes.galleries ? (
                        <img src={product.attributes.galleries[0].url} alt="" />
                      ) : (
                        <img src={`https://tinyurl.com/3wdum8u5`} alt="" />
                      )}
                      <p>
                        <span>{product.attributes.brand_name} </span>{" "}
                        {product.attributes.name}
                      </p>
                    </div>
                  );
                })}
              <Pagination
                onChange={(event: any, value: any) => {
                  this.setState({
                    ...this.state,
                    currentECPage: value,
                  });
                }}
                count={this.state.totalECPage}
                page={this.state.currentECPage}
              />
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(CreateCouponStyle)(CreateCoupon);
