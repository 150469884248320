import React from "react";
import { Link, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CustomerAccountSideBarController, {
  Props,
} from "../../blocks/OrderDetailView/src/CustomerAccountSideBarController.web";

// My Order start
const profileList = [
  { id: "1", category: "My Profile", path: "/" },
  { id: "2", category: "My Orders", path: "/orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  // { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" },
];

const styles = {
  pageOuter: {
    padding: "50px 0",
    "& .order-title-wrapper": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 20px",
      borderBottom: "1px solid #e6e6e6",
    },
    "& .order-title-wrapper .select-border": {
      margin: "0",
    },
    "& h2": {
      fontSize: "18px",
      lineHeight: "22px",
      color: "#121212",
      fontWeight: "700",
      margin: "0",
      textTransform: "uppercase",
      "@media (max-width:767px)": {
        margin: "0 0 10px",
      },
    },
    "& .user-detail-wrapper": {
      padding: "30px 20px",
      background: "#f3f3f3",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-wrapper .user-detail-inner:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .user-detail-inner": {
      background: "#fff",
      padding: "23px 20px",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .product-order-detail": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0 0 20px",
      flexWrap: "wrap",
    },
    "& .order_details": {
      display: "flex",
      alignItems: "center",
      "@media (max-width:575px)": {
        width: "100%",
        margin: " 0 0 15px",
      },
    },
    "& .product-order-detail p": {
      margin: "0",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#212121",
      fontWeight: "400",
    },
    "& .order_details  .order-info": {
      fontWeight: "600",
    },
    "& .order_details  .order-info.shipping": {
      color: "#517ba3",
    },
    "& .order_details  .order-info.delivered": {
      color: "#21c998",
    },
    "& .order_details  .order-info.cancelled": {
      color: "#f94a59",
    },
    "& .order_details  .order-info span": {
      color: "#212121",
    },
    "& .order-info-img": {
      maxWidth: "36px",
      margin: "0 10px 0 0",
    },
    "& .order-id p": {
      color: "#5d5d66",
    },
    "& .order-id p.cancel-btn": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .order-id p.cancel-btn a": {
      color: "#0057ff",
      fontWeight: 600,
    },
    "& .order-id p span": {
      color: "#121212",
      fontWeight: "500",
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
    "& .productlist-wrapper .product-list": {
      border: "0",
      background: "#f6f6f6",
      padding: "15px",
      position: "relative",
      "@media (max-width:767px)": {
        padding: "10px",
      },
      cursor: "pointer",
    },
    "& .productlist-wrapper .product-list .product-img": {
      width: "55px",
      height: "70px",
    },
    "& .productlist-wrapper .product-list .product-detail": {
      paddingLeft: "12px",
    },
    "& .productlist-wrapper .product-name": {
      fontSize: "16px",
      lineHeight: "20px",
      "@media (max-width:767px)": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    "& .productlist-wrapper .product-desc": {
      color: "#5d5d66",
      margin: "0 0 7px",
    },
    "& .productlist-wrapper .product-desc.size-qty": {
      margin: "0 0 0",
    },
    "& .order-cancel": {
      position: "absolute",
      right: "15px",
      bottom: "15px",
    },
    "& .order-cancel a": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#0057ff",
      fontWeight: "600",
      textDecoration: "none",
    },
    "& .rating-blog": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "1px solid #e3e3e3",
      marginTop: "11px",
      padding: "10px 0 0",
      "@media (max-width:767px)": {
        flexWrap: "wrap",
      },
    },
    "& .rating": {
      display: "flex",
      alignItems: "center",
      "@media (max-width:767px)": {
        flexWrap: "wrap",
      },
    },
    "& .rating p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#b6b6b6",
      margin: "0 12px 0 0",
    },
    "& .rating .product-rating .MuiIconButton-root": {
      padding: "0",
      margin: "0 9px 0 0",
    },
    "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label span": {
      display: "none",
    },
    "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label .fill": {
      display: "inline-block",
    },
    "& .rating .product-rating .MuiIconButton-root .fill": {
      display: "none",
    },
    "& .rating .product-rating .MuiIconButton-root .fill svg": {
      color: "#ffc107",
    },
    "& .rating .product-rating .MuiSvgIcon-root": {
      color: "#b6b6b6",
      width: "30px",
      height: "30px",
    },
    "& .write-review a": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ff8030",
      fontWeight: "600",
      textDecoration: "none",
    },
    "& .active-path": {
      fontWeight: "600",
      textDecoration: "none",
    },
    "& .settings": {
      padding: "0 50px",
      textDecoration: "none",
    },
    "& .settings .notification": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .loyalty-point-wrapper": {
      display: "flex",
      flexWrap: "wrap",
      margin: " 0 -15px",
    },
    "& .loyalty-point-wrapper .loyalty-point-list": {
      flex: "0 0 50%",
      maxWidth: "50%",
      padding: "0 15px",
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .loyalty-point-wrapper .loyalty-point-list .user-detail-inner": {
      height: "100%",
      "@media (max-width:767px)": {
        height: "auto",
      },
    },
    "& .loyalty-point-wrapper .loyalty-point-list:last-child .user-detail-inner": {
      "@media (max-width:767px)": {
        margin: "0",
      },
    },
    "& .loyalty-point": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 15px",
    },
    "& .loyalty-point .loyalty-img": {
      display: "inline-block",
      maxWidth: "22px",
      margin: "0 12px 0 0",
    },
    "& .loyalty-point p": {
      fontSize: "14px",
      lineHeight: "16px",
      margin: "0",
      color: "#5d5d66",
    },
    "& .loyalty-point p span": {
      fontWeight: "600",
      color: "#121212",
    },
    "& .loyalty-info p": {
      margin: "0",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#0057ff",
    },
    "& .calculation": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      lineHeight: "16px",
    },
    "& .calculation .points": {
      display: "inline-block",
      minWidth: "150px",
    },
    "& .calculation .price": {
      fontWeight: "600",
    },
  },
};
// My Order End

export class CustomerAccountSideBar extends CustomerAccountSideBarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;


    const profileList = [
      { id: "1", category: "My Profile", path: "/account/CustomerMyProfile" },
      { id: "2", category: "My Orders", path: "/account/orders" },
      { id: "3", category: "Shopping With Friends", key: "ShopWithFriends" },
      {
        id: "4",
        category: "Address Book",
        path: "/account/CustomerAddressBook",
      },
      // { id: "5", category: "Coupons", path: "/account/profile" },
      { id: "6", category: "Policies", path: "/account/policies" },
      { id: "7", category: "Settings", path: "/account/settings" },
      { id: "8", category: "Loyalty Points", path: "/account/LoyaltyPoints" },
      { id: "0", category: "Invite Friends", path: "/setting/InviteFriends" },
    ];

    return (
      <>
        {/* My Order start */}
        <div className={classes.pageOuter}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <div className="order-title-wrapper">
                  <h2>Account</h2>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="profile-list">
                  <ul>
                    {profileList.map((route) => {
                      const urlPath = location.pathname;
                      const active = urlPath.includes(route.path || " ")
                        ? "active-path"
                        : "";
                      return (
                        <>
                          <li>
                            {
                              route?.key === "ShopWithFriends" ?
                                // <Link className={active} to="#" onClick={() =>this.props.callbackShopWithFriend(true)}>
                                <a
                                  onClick={() => { this.props.callbackShopWithFriend(true); }}
                                >
                                  {route.category}
                                </a>
                                // {route.category} {/* shopping with friends dialog */}
                                // </Link>
                                :
                                <Link className={active} to={`${route.path}`}>
                                  {route.category}
                                </Link>
                            }
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                {/* this children will replace component here */}
                {this.props.children}
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* Customer Order Side bar */}
      </>
    );
  }
}
export default withStyles(styles)(CustomerAccountSideBar);
