Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";

exports.apiPostMethodType = "POST";

exports.inviteFriendsAPIEndPoint = "bx_block_invitefriends/invitefriends";
// Customizable Area End
