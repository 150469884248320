Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";

exports.apiGetMethodType = "GET";
exports.apiPutMethodType = "PUT";

exports.SettingAPIEndPoint = "bx_block_notifications/notification_settings";
exports.updateSettingAPIEndPoint = "bx_block_notifications/notification_settings";
// Customizable Area End