import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
  Link,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BackArrow from "@material-ui/icons/ArrowBack";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MenuItems from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Menu } from "../../../../node_modules/@szhsin/react-menu";
import * as Yup from 'yup';

// import DocIc from "../assets/doc-icon.svg";

import AddressController, {
  Props,
  //   configJSON
} from "./AddressController.web";

import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { Formik } from "formik";

// Vendor Add New Address Start
const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: '50px 0',
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px"
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& h5": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "500",
      margin: "0 0 10px"
    },
    "& h5.mrb-25": {
      margin: "0 0 25px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "25px",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& .field-wrapper": {
      margin: "0 0 50px",
    },
    "& .block-wrapper-inner .error": {
      color: "red",
    },
  },
};
// Vendor Add New Address End

const addAddressSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile: Yup.string().matches(/^\d{10}$/, { message: "Please enter valid mobile number" }).required("Mobile No is required"),
  email: Yup.string().email().required("Email is required"),
  address: Yup.string().required("Address is required").min(10),
  town: Yup.string().required("Locality / Town is required"),
  city: Yup.string().required("City / District is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
});


export class VendorAddAddress extends AddressController {
  myRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        {/* Vendor Add New Address Start */}
        <div className={classes.pageOuter} id="addAddress" ref={this.myRef}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  {this.state.mode === 'EDIT' ? <h2>Edit Address</h2> : <h2>Add New Address</h2>}
                </Grid>
                <Grid item xs={12}>
                  <Link style={{ color: "black" }} onClick={this.gotoAddressListScreen}><BackArrow /></Link>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <Formik initialValues={this.intialValues}
                        validationSchema={addAddressSchema}
                        onSubmit={(values) => {
                          console.log("form:::", values);
                          this.state.mode === 'EDIT' ? this.editAddressCall(this.state.id) : this.addNewAddressCall(values)
                        }
                        } >
                        {(props) => {
                          const { errors, touched, isValid, dirty, setFieldValue } = props;
                          if (Object.keys(errors).length > 0) {
                            this.myRef.current?.scrollIntoView({ behavior: 'auto' });
                          }
                          props.values.name = this.state.name;
                          props.values.mobile = this.state.mobile;
                          props.values.email = this.state.email;
                          props.values.address = this.state.address;
                          props.values.town = this.state.town;
                          props.values.city = this.state.city;
                          props.values.state = this.state.state;
                          props.values.pincode = this.state.pincode;
                          return (
                            <form onSubmit={props.handleSubmit}>
                              <h5 className="mrb-25">Contact Details</h5>
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <InputLabel htmlFor="my-input">Name*</InputLabel>
                                  <FormControl>
                                    <Input
                                      autoFocus
                                      type="text"
                                      id="name"
                                      name="name"
                                      value={props.values.name}
                                      error={touched.name && errors.name ? true : false}
                                      onChange={(e) => { this.handleChange(e) }}
                                      aria-describedby="my-helper-text"
                                    />
                                    <span className="error">{touched.name && errors.name ? errors.name : ''}</span>
                                  </FormControl>
                                </div>
                                <div className="form-group">
                                  <InputLabel htmlFor="mobile">Mobile No*</InputLabel>
                                  <FormControl>
                                    <Input
                                      type="text"
                                      name="mobile"
                                      value={props.values.mobile}
                                      error={touched.mobile && errors.mobile ? true : false}
                                      onChange={(e) => { this.handleChange(e) }}
                                      id="mobile"
                                      aria-describedby="my-helper-text"
                                    />
                                    <span className="error">{touched.mobile && errors.mobile ? errors.mobile : ''}</span>
                                  </FormControl>
                                </div>
                                <div className="form-group">
                                  <InputLabel htmlFor="email">Email*</InputLabel>
                                  <FormControl>
                                    <TextField
                                      type="email"
                                      name="email"
                                      value={props.values.email}
                                      error={touched.email && errors.email ? true : false}
                                      onChange={(e) => { this.handleChange(e) }}
                                      id="email"
                                      aria-describedby="my-helper-text"
                                    />
                                    <span className="error">{touched.email && errors.email ? errors.email : ''}</span>
                                  </FormControl>
                                </div>
                              </div>

                              <h5 className="mrb-25">Address</h5>
                              <div className="form-group">
                                <InputLabel htmlFor="address">
                                  Address(House No, Building, Street)*
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={props.values.address}
                                    error={touched.address && errors.address ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.address && errors.address ? errors.address : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="town">Locality / Town*</InputLabel>
                                <FormControl>
                                  <TextField
                                    type="text"
                                    id="town"
                                    name="town"
                                    value={props.values.town}
                                    error={touched.town && errors.town ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.town && errors.town ? errors.town : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="town">Pin Code*</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="pincode"
                                    name="pincode"
                                    value={props.values.pincode}
                                    error={touched.pincode && errors.pincode ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.pincode && errors.pincode ? errors.pincode : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="city">City / District*</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={props.values.city}
                                    error={touched.city && errors.city ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.city && errors.city ? errors.city : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="state">State*</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="state"
                                    name="state"
                                    value={props.values.state}
                                    error={touched.state && errors.state ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.state && errors.state ? errors.state : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <RadioGroup className="row" aria-label="address" name="address">
                                  <h4>Save Address As</h4>
                                  <div>
                                    <FormControlLabel value="home" control={<Radio value="home" onChange={(e) => this.setState({ save_add: e.target.value })} />} checked={this.state.save_add === 'home'} label="home" />
                                    <FormControlLabel value="office" control={<Radio value="office" onChange={(e) => this.setState({ save_add: e.target.value })} />} checked={this.state.save_add === 'office'} label="office" />
                                  </div>
                                </RadioGroup>
                              </div>
                              <div className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                >
                                  {this.state.mode === 'EDIT' ? 'Edit Address' : 'Add Address'}
                                </Button>
                              </div>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Vendor Add New Address end */}
      </>
    );
  }
}

export default withStyles(styles)(VendorAddAddress);
