Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiMethodTypeGet = "GET";

exports.paymentAPIEndPoint =
  "bx_block_order_management/payment_available_balance";
exports.analyticsAPIEndPoint = "bx_block_order_management/analytics";
exports.schedulePickupAPIEndPoint = "bx_block_order_management/search_order?status=confirmed";
// Customizable Area End
