export const CreateCouponStyle = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "60px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      padding: "0",
      marginBottom: "0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      margin: "0 100px",
      padding: "30px 30px",
    },
    "& .block-wrapper-inner": {
      textAlign: "center",
    },
    "& .create-coupon-form": {
      margin: "30px 100px",
      width: "100%",
    },
    "& .create-coupon-form .MuiAccordion-root .MuiAccordionSummary-root": {
      background: "#E1E1E1",
    },
    "& .create-coupon-form .MuiAccordion-root": {
      padding: "10px 0",
      boxShadow: "none",
    },
    "& .create-coupon-form .MuiAccordion-root:before": {
      top: "0",
      height: "0",
    },
    "& .create-coupon-form .amount-sign": {
      position: "absolute",
      top: "30%",
      right: "15px",
      fontWeight: "bold",
    },
    "& .create-coupon-form #amount": {
      paddingRight: "40px",
    },
    "& .create-coupon-form .MuiAccordion-root.Mui-expanded": {
      padding: "0",
    },
    "& .create-coupon-form .MuiAccordionSummary-content p": {
      fontWeight: "bold",
    },
    "& .create-coupon-form .MuiIconButton-root svg": {
      color: "black",
    },
    "& .create-coupon-form .coupon-info": {
      width: "100%",
    },
    "& .create-coupon-form .usage-limit": {
      width: "100%",
    },
    "& .create-coupon-form .usage-restriction": {
      width: "100%",
    },
    "& .create-coupon-form .coupon-details": {
      width: "100%",
    },
    "& .create-coupon-form .coupon-product": {
      width: "100%",
    },
    "& .create-coupon-form .MuiAccordionDetails-root": {
      padding: "16px 16px 8px 16px",
    },
    "& .create-coupon-form .MuiAccordionDetails-root input": {
      border: "none",
      background: "#EDEDED",
      borderRadius: "5px",
      padding: "8px 10px",
      margin: "5px 0",
      fontSize: "16px",
    },
    "& .create-coupon-form .coupon-product .select .input": {
      fontSize: "14px",
      border: "none",
      background: "#EDEDED",
      borderRadius: "5px",
      padding: "8px 10px",
      margin: "5px 0 15px 0",
      cursor: "pointer",
      color: "black",
    },
    "& .create-coupon-form .coupon-product .select": {
      position: "relative",
    },
    "& .create-coupon-form .coupon-product .select svg": {
      position: "absolute",
      right: "8px",
      top: "8px",
      width: "18px",
    },
    "& .create-coupon-form .MuiAccordionDetails-root .ur-select input": {
      width: "15px",
      marginRight: "10px",
      transform: "scale(1.3)",
    },
    "& .create-coupon-form .MuiAccordionDetails-root .ur-select input[type=checkbox]:checked": {
      accentColor: "black",
    },
    "& .create-coupon-form .MuiAccordionDetails-root .ur-select": {
      margin: "10px 1px",
    },
    "& .create-coupon-form .MuiAccordionDetails-root textarea": {
      border: "none",
      background: "#EDEDED",
      borderRadius: "5px",
      padding: "10px 10px",
      margin: "5px 0",
      // resize: "none",
      width: "100%",
      fontSize: "16px",
      outline: "none",
    },
    "& .create-coupon-form .MuiAccordionDetails-root select": {
      border: "none",
      background: "#EDEDED",
      borderRadius: "5px",
      padding: "10px 10px",
      margin: "5px 0 15px 0",
      resize: "none",
      width: "100%",
      fontSize: "16px",
      outline: "none",
    },
    "& .create-coupon-form .error": {
      // marginTop: "10px",
      // marginBottom: "10px",
      color: "red",
      fontSize: "14px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "25px",
      textTransform: "uppercase",
    },
    "& .btn-wrapper .MuiButton-root.black-btn": {
      textTransform: "uppercase",
    },
    "& .coupon-product .multi-select-menu": {
      marginBottom: "15px",
    },
    "& .coupon-product .multi-select-menu>div>div": {
      width: "100%",
      boxShadow: "0 10px 10px #666",
      borderRadius: "5px",
    },
    "& .coupon-product .multi-select-menu>div>div>div>div>div>div": {
      background: "white",
      fontWeight: "500",
    },
    "& .coupon-product .multi-select-menu button": {
      width: "100%",
      boxShadow: "none",
      background: "#EDEDED",
      borderRadius: "5px",
      color: "black",
    },
    "& .coupon-product .multi-select-menu input[type=checkbox]": {
      width: "15px",
      marginRight: "10px",
      transform: "scale(1.3)",
      padding: "0",
      margin: "0",
    },
    "& .coupon-product .multi-select-menu input[type=checkbox]:checked": {
      accentColor: "black",
    },
  },
  modal: {
    "& .products .product": {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
    },
    "& .products .product input[type=checkbox]": {
      width: "15px",
      marginRight: "10px",
      transform: "scale(1.3)",
    },
    "& .products .product input[type=checkbox]:checked": {
      accentColor: "black",
    },
    "& .products .product img": {
      width: "50px",
      height: "50px",
      border: "1px solid #E1E1E1",
      borderRadius: "5px",
      marginRight: "10px",
    },
    "& .products .product p": {
      marginBottom: "0px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .products .product p span": {
      fontWeight: "600",
    },
    "& .products .MuiPagination-root": {
      marginTop: "20px",
    },
    "& .products .MuiPagination-root .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .search-product": {
      backgroundColor: "#fff",
      position: "relative",
      marginBottom: "15px",
    },
    "& .search-product input": {
      border: "1px solid #dfdfdf",
      padding: "5px 10px 5px 40px",
      borderRadius: "5px",
    },
    "& .search-product .MuiSvgIcon-root": {
      position: "absolute",
      top: "6px",
      left: "10px",
      color: "#dfdfdf",
    },
  },
};
