import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { VendorAddAddressModel } from "../../../framework/src/Interfaces/IAddress";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: number;
  mode: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  token: string | null;
  data: any;
  showNewAddressDialog: boolean;
  // Customizable Area Start
  nameError: any;
  mobileError: any;
  addressError: any;
  townError: any;
  pincodeError: any;
  cityError: any;
  stateError: any;
  save_addError: any;
  mode: string
  addressData: any;
  editAddress: Object;
  state: string;
  city: string;
  mobile: any;
  email: string;
  town: string;
  id: number | null;
  name: string;
  address: string;
  pincode: any;
  save_add: string;
  saveAddress: string;
  // addressID: number | null;
  priceDetailsData: any;
  addNewDeliveryAddress: any;
  activeCartId: any;
  deliveryAddressId: any;
  placeOrderLoader: boolean
  isOrderPlaced: boolean
  exchangeFlow: boolean;
  placeExchangeOrderData: any,
  exchangeItemOrderId: any


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class AddressController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  addressCallId: any = null;
  getaddressCallId: any = null;
  DelAddressCallId: any = null;
  // addNewAddressCallId: any = null;
  EditAddressCallId: any = null;
  EidthAddressCallId: any = null;
  DefualtAddressCallId: any = null;
  priceDetailsDataCallId: any;
  addNewDeliveryAddressCallId: any
  addNewAddressCallId: any;
  deliveryAddressSelectedForOrderCallId: any;
  placeOrderCallId: any;
  ActiveCartCallId: any;
  placeOrderApiCallId: any;
  PlaceExchangeOrderCallId: any
  // Customizable Area End

  public intialValues: VendorAddAddressModel = {
    name: '',
    mobile: '',
    email: '',
    address: '',
    town: '',
    city: '',
    state: '',
    pincode: ''
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // const result = msg.getData('id');

    // const result = msg.getData('id');

    // console.log("props.id::::", props);
    this.state = {
      txtSavedValue: 'A',
      token: '',
      data: [],
      showNewAddressDialog: false,
      // Customizable Area Start
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
      mode: '',
      addressData: [],
      editAddress: {},
      state: '',
      city: '',
      mobile: '',
      email: '',
      town: '',
      id: this.props.id || null,
      name: '',
      address: '',
      pincode: '',
      save_add: 'home',
      saveAddress: '',
      priceDetailsData: [],
      addNewDeliveryAddress: [],
      activeCartId: [],
      deliveryAddressId: "",
      placeOrderLoader: false,
      isOrderPlaced: false,
      exchangeFlow: false,
      placeExchangeOrderData: [],
      exchangeItemOrderId: ""
      //addressID: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    const data = msg.getData(
      getName(MessageEnum.VendorAddressDataMessage)
    );
    // console.log("constructor data::", data);
    // this.send(msg);
    // Customizable Area End
  }

  async componentDidMount() {
    // super.componentDidMount();
    // token
    // console.log('----->> token');
    try {
      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken, "tokentoken")

      this.setState({ token: localToken });
      this.activeCartId();
      this.addressCall()
      if (this.state.priceDetailsData) {
        this.shoppingPriceData()
      }
    } catch (e) {
      // error reading value
    }
    const exchangeItem: any = localStorage.getItem("exchangeItemOrderId")
    this.setState({ exchangeItemOrderId: exchangeItem })
    if (localStorage.getItem("exchangeFlow") == "true") {
      this.setState({ exchangeFlow: true })
    } else { this.setState({ exchangeFlow: false }) }
  }

  // temporary
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    //  PLACE ORDER API
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.placeOrderApiCallId !== null &&
      this.placeOrderApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.placeOrderApiCallId = null;
      // this.setState({ loading: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ placeOrderLoader: false })
        if (this.state.exchangeFlow) {
          this.placeExchangeorder()

        } else {
          this.setState({ isOrderPlaced: true })
        }
        //  this.handlePaymentCapture());
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END PLACE ORDER API


    // active cart  id start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ActiveCartCallId !== null &&
      this.ActiveCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ActiveCartCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ activeCartId: responseJson.data })

      }

      // console.log('////////////// activeCartId data------------------------------------------------------', this.state.activeCartId);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // active cart id end 


    // address listing data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addressCallId !== null &&
      this.addressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ addressData: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////addresdata data------------------------------------------------------', this.state.addressData);
        const defaultid: any = this.state.addressData[0].attributes.id
        // console.log(defaultid,"defaultid")
        this.setState({ deliveryAddressId: defaultid }, () => localStorage.setItem("addressId", defaultid))
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // address listing data end


    // add new address response data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addNewDeliveryAddressCallId !== null &&
      this.addNewDeliveryAddressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addNewDeliveryAddressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ addNewDeliveryAddress: responseJson.data });
        this.handleCloseNewAddressDialog()
        this.addressCall()
      }
      // this.setState({ shoppingCartloading: false });
      // console.log('//////////////addNewDeliveryAddress------------------------------------------------------', this.state.addNewDeliveryAddress);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // address listing data end


    // delivery address selected for order data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deliveryAddressSelectedForOrderCallId !== null &&
      this.deliveryAddressSelectedForOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.deliveryAddressSelectedForOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message == "Address linked successfully") {

        if (
          // parseInt(this.state.priceDetailsData.attributes?.total) == 0
          this.state.priceDetailsData.attributes?.total == "0.0" || this.state.priceDetailsData.attributes?.total < 0
        ) {
          this.PlaceOrderApiByLoyalty();



        }
        // this.setState({ placeOrderLoader: true })
        else {
          this.setState({ placeOrderLoader: false })
          this.props.navigation.navigate("payment")
        }

        // this.props.navigation.navigate("payment");
      }

      // console.log('deliveryaddress selected fro order------------------------------------------------------', this.state.deliveryAddressSelectedForOrder);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // delivery address selected for order data end


    // shopping price details data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.priceDetailsDataCallId !== null &&
      this.priceDetailsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.priceDetailsDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // console.log(responseJson,"price details response")
      if (responseJson && responseJson.data) {
        this.setState({ priceDetailsData: responseJson.data });
      }

      // console.log('////////////// Price Details data------------------------------------------------------', this.state.priceDetailsData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //shopping price details data end


    // place exchange order start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PlaceExchangeOrderCallId !== null &&
      this.PlaceExchangeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PlaceExchangeOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ placeExchangeOrderData: responseJson.data });
        this.setState({ isOrderPlaced: true })
        localStorage.removeItem("exchangeFlow")
        localStorage.removeItem("exchangeItemOrderId")
        localStorage.removeItem("addressId")
        console.log('////////////// place Exchange order------------------------------------------------------', this.state.placeExchangeOrderData)
      }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // place exchange order end


    // Customizable Area End

  }












  // exchange item function 
  placeExchangeorder(
    // productid: any, variantId: any
  ) {
    // console.log(Number(productid), variantId)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      "order_item_id": this.state.exchangeItemOrderId, //here take order_item_id which item you have to exchange.
      // reason_for_exchange: "size is too short",
      delivery_address_id: this.state.deliveryAddressId,
      //address id
      // additional_comments: "take additional comment whatever you want",
      service_type: "exchange", //It is mendatory field
      // reason_to_exchange_id: 1,//pass the reasonforexchange id here
      // others: "dummy"

      // "catalogue_id": productid,
      // "catalogue_variant_id": variantId,
      // "type": "exchange", //#use this when you will create the new exchange order.
      // "order_item_id": localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PlaceExchangeOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PlaceExchangeOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }








  // Active Cart id start 
  activeCartId() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.ActiveCartCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ActiveCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }
  // Active Cart Id end  


  // delivery addres for order selected start  ..................................................
  deliveryAddressForOrder() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const data = {
      cart_id: this.state.activeCartId.id,
      address_id: this.state.deliveryAddressId
    };

    const httpBody =
      data

    // console.log("--------------->>>>>>>>>>", httpBody);


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deliveryAddressSelectedForOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deliveryAddressSelectedForOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  // delivery address for order selected end .......................................................



  shoppingPriceData() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.priceDetailsDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shoppingPriceAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  // price details end 


  addressCall() {
    // console.log('---------------->>>>>>> address');

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addressCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }

  handleCloseNewAddressDialog = () => {
    this.setState({
      showNewAddressDialog: false,
      name: "",
      city: "",
      town: "",
      mobile: "",
      pincode: "",
      address: "",
      state: "",
      nameError: "",
      cityError: "",
      townError: "",
      mobileError: "",
      pincodeError: "",
      addressError: "",
      stateError: ""

    });
  };

  addNewAddress() {

    if (!this.state.name || (!this.state.mobile || this.state.mobile.length != 10) || !this.state.town || !this.state.pincode || !this.state.city || !this.state.state || !this.state.save_add || (this.state.address.length < 10 && this.state.address.length > 0) || !this.state.address) {
      if (!this.state.name) {      // alert('Package Name Field is Empty');
        this.setState({ nameError: "Please fill name field" })
      } else { this.setState({ nameError: "" }) }
      if (!this.state.mobile || this.state.mobile.length != 10) {
        console.log(this.state.mobile.length)
        if (!this.state.mobile) {
          // alert('Package Length Field is Empty');
          this.setState({ mobileError: "Please fill number field" })

        }
        else if (this.state.mobile.length != 10) {
          // alert('Package Length Field is Empty');
          this.setState({
            mobileError: "Please enter valid mobile number "
          })

        }
      } else { this.setState({ mobileError: "" }) }

      if (this.state.address.length < 10 && this.state.address.length > 0 || !this.state.address) {

        if (this.state.address.length < 10 && this.state.address.length > 0) {
          this.setState({ addressError: "address is less than 10 characters" })
        }
        else if (!this.state.address) {
          // alert('Package Width Fieldis Empty');
          this.setState({ addressError: "Please fill address field" })
        }
      } else { this.setState({ addressError: "" }) }

      if (!this.state.town) {
        // alert('Package Height Field is Empty');
        this.setState({ townError: "Please fill town field" })
      } else { this.setState({ townError: "" }) }


      if (!this.state.pincode) {
        // alert('Package Height Field is Empty');
        this.setState({ pincodeError: "Please fill pincode field" })
      } else { this.setState({ pincodeError: "" }) }





      if (!this.state.city) {
        // alert('Package Height Field is Empty');
        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.state) {
        // alert('Package Height Field is Empty');
        this.setState({ stateError: "Please fill state field" })
      } else { this.setState({ stateError: "" }) }


      if (!this.state.save_add) {
        // alert('Package Height Field is Empty');
        this.setState({ save_addError: "Please fill save address as field" })
      } else { this.setState({ save_addError: "" }) }

    }

    else {
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: this.state.token,
      };

      const data = {
        name: this.state.name,
        phone_number: this.state.mobile,
        address: this.state.address,
        town: this.state.town,
        zip_code: this.state.pincode,
        city: this.state.city,
        state: this.state.state,
        save_address_as: this.state.save_add,
        // email: "nomail@gmail.com",
        country: "india",
        is_default: true
      };

      const httpBody = data

      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addNewDeliveryAddressCallId = apiRequest.messageId;
      // console.log(this.addNewAddressCallId )
      apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addNewAddressAPiEndPoint);
      apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      this.setState({
        name: "", city: "", town: "", mobile: "", pincode: "", address: "", state: "",

        nameError: "", cityError: "", townError: "", mobileError: "", pincodeError: "", addressError: "", stateError: ""
      })
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;
    }
  }


  isStringNullOrBlank(str: string | number) {
    return str === null || str.toString().length === 0;
  }


  // payment when 0 price 
  PlaceOrderApi = () => {

    // this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpbody = {
      cart_id: this.state.activeCartId,
      payment_method: "prepaid"
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.placeOrderApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }



  placeThisOrder() {
    this.setState({ placeOrderLoader: true })
    this.deliveryAddressForOrder()

  }

  addThisDeliveryAddress() {
    this.addNewAddress();
    // setTimeout(() => this.addressCall(), 2000)
  }

  PlaceOrderApiByLoyalty = () => {

    // this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpbody = {
      cart_id: this.state.activeCartId.id,
      payment_method: "prepaid"
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.placeOrderApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }

  goToHomePage = () => {
    localStorage.setItem('cartItemCount', "0");
    // no item should in cart
    // this.props.handleCartCount(0);
    this.props.navigation.navigate('Home');
  }


}