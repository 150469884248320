import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AffiliateSoldItemController, {
  Props,
  configJSON,
} from "./AffiliateSoldItemController.web";
import { withStyles } from "@material-ui/core/styles";
import { AffiliateSoldItemStyleWeb } from "./AffiliateSoldItemStyle.web";

export class AffiliateSoldItem extends AffiliateSoldItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.pageOuter}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <div className="text-center">
                  <h3>SOLD ITEMS</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <Grid item xs={12} className="vendor-rightcol">
                      <Grid item xs={12} className="productlist-wrapper">
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-img">
                              <img
                                className="img-block"
                                src={`https://tinyurl.com/3wdum8u5`}
                              />
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <div className="product-qty">QTY: 1</div>
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

export default withStyles(AffiliateSoldItemStyleWeb)(AffiliateSoldItem);
