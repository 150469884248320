import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as _ from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  updateId?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  viewChartTable: boolean;
  sizeCharts: any;
  addsizeCharts: any;
  rmsizeCharts: any;
  editsizeCharts: any;
  categories: any;
  subCategories: any;
  productName: any;
  productCategory: any;
  productSubCategory: any;
  productMeasurement: any;
  productCategoryId: any;
  productSubCategoryId: any;
  productMeasurementId: any;
  row_val: any;
  isDelete: boolean;
  sizeChartId: any;
  addColHeader: any;
  updation: any;
  catError: boolean;
  subcatError: boolean;
  meaError: boolean;
  addRow: any;

  // editing size chart start
  chartRows: any;
  cols: any;
  editingSizeChartId: number;
  editingSizeChart: any;
  // editing size chart end

  // size chart list start
  sizeChartToDelete: any; // data of the size chart to be deleted
  selectedCat: string;
  selectedSubCatId: string;
  usedCategories: any;
  usedSubCategories: any;
  totalRows: object[];
  finalRows: object[];
  finalCols: object[];
  loading: boolean;
  formError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}
interface RowObj {
  label_value: string,
  column_name: string,
  id: number,
  row: number
}

export default class SizeChartController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      viewChartTable: false,
      sizeCharts: [],
      addsizeCharts: [],
      rmsizeCharts: [],
      editsizeCharts: [],
      categories: [],
      subCategories: [],
      productName: "hello",
      productCategory: "",
      productSubCategory: "",
      productMeasurement: "",
      productCategoryId: "",
      productSubCategoryId: "",
      productMeasurementId: "",
      row_val: [],
      isDelete: false,
      sizeChartId: "",
      addColHeader: "",
      updation: "",
      catError: false,
      subcatError: false,
      meaError: false,
      addRow: [],

      // editing size chart start
      // size chart rows
      chartRows: [],
      cols: [],
      editingSizeChartId: 0,
      editingSizeChart: [],
      // editing size chart end

      // size chart list start
      sizeChartToDelete: {},
      selectedCat: '',
      selectedSubCatId: '',
      usedCategories: [],
      usedSubCategories: [],
      totalRows: [],
      finalRows: [],
      finalCols: [],
      loading: false,
      formError: false
      // size chart list stop
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSizeChartsCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.data) {
        this.setState({ sizeCharts: responseJson.data },
          () => {
            this.getProductCategoies();

            let usedCategories: any = [];
            let usedSubCategories: any = [];

            this.state.sizeCharts.map((chart: any) => {
              usedCategories.push({
                id: chart.attributes?.categories_id,
                name: chart.attributes?.category_name,
              })
              usedSubCategories.push({
                id: chart.attributes?.sub_categories_id,
                name: chart.attributes?.sub_category_name,
              })
            })

            const variantValues = _.filter(this.state.sizeCharts, (chart: any) => { return chart.attributes?.id == this.props.navigation.getParam('id') })[0];
            if (variantValues === undefined) {
              this.props.navigation.navigate("SizeCharts")
            }

            this.setState(
              {
                editingSizeChart: variantValues,
                usedCategories: usedCategories,
                usedSubCategories: usedSubCategories,
                loading: false,
              },
              () => {
                // declare and initialize empty arrays for cols and rows for size chart
                var cols: any[] = [];
                var rows: any[] = [];

                // pick column names and put into cols array
                this.state.editingSizeChart?.attributes?.variant_size_names.forEach((value: any) => {
                  cols.push(_.pick(value, ['label_name', 'id']));
                });

                var rowTemp = _.groupBy(this.state.editingSizeChart?.attributes?.variant_size_values, (row: any) => {
                  return row.row
                });

                var rowSimplified = [];
                for (const [key, value] of Object.entries(rowTemp)) {
                  var row: any[] = [];
                  value.forEach((field: any) => {
                    var fieldObj: RowObj = {
                      label_value: field.label_value,
                      column_name: cols.filter(col => col.id == field.variant_size_name_id)[0].label_name,
                      id: field.id,
                      row: field.row
                    };
                    row.push(fieldObj);
                  })
                  rowSimplified.push(row);
                }

                this.getProductSubCategoies(this.state?.editingSizeChart?.attributes?.categories_id);
                this.setState({
                  cols: cols,
                  totalRows: rowSimplified,
                  chartRows: rowSimplified,
                  selectedCat: this.state.editingSizeChart?.attributes?.categories_id,
                  selectedSubCatId: this.state.editingSizeChart?.attributes?.sub_categories_id
                })
              });
          }
        )
      } else {
        this.props.navigation.navigate("SizeChartsEmptyWeb")
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProductCategoriesCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.setState({ categories: responseJson.data })
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProductSubCategoriesCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.setState({ subCategories: responseJson.data })
      } else {
        // this.props.navigation.navigate("SizeChartsEmpty")
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.addSizeChartsCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.data && responseJson.error == undefined) {
        // redirect to charts list
        this.setState({ addsizeCharts: responseJson.data }, () => {
          this.props.navigation.navigate("SizeCharts")
        })
      } else {
        console.log("Error");
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.rmSizeChartsCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.setState({ rmsizeCharts: responseJson.data }, () => {
          this.getSizeCharts();
        })
      } else {
        this.getSizeCharts();
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.editSizeChartsCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.props.navigation.navigate("SizeCharts")
      } else {
        console.log("editsizeempty");
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // class properties start
  getProductApiCallId: any;
  getSizeChartsCallId: any;
  addSizeChartsCallId: any;
  rmSizeChartsCallId: any;
  editSizeChartsCallId: any;
  getProductCategoriesCallId: any;
  getProductSubCategoriesCallId: any;
  // class properties end

  /**
   * Function to get all the size charts
   */
  getSizeCharts() {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSizeChartsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSizeChartApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getSizeChartsCallId, requestMessage);
  }

  /**
   * Function to fetch all product categories
   */
  getProductCategoies() {
    const header = {
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getProductCategoriesCallId, requestMessage);
  }

  /**
   * Function to fetch details on a sub-category when parent category's id is passed as argument
   * @param productCategory id of the parent category
   */
  getProductSubCategoies(productCategory: any) {
    const header = {
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductSubCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoriesApiEndPoint + '?category_id=' + productCategory
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getProductSubCategoriesCallId, requestMessage);
  }

  /**
   * Function to add new size chart
   * @param size_chart size chart data object
   */
  addSizeCharts(size_chart: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem('authToken')
    };
    const body = { size_chart };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSizeChartsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSizeChartApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(this.addSizeChartsCallId, requestMessage);
  }

  /**
   * Function to delete the size chart
   * @param size_chart_id id of the size chart to be deleted
   */
  deleteSizeCharts(size_chart_id: any) {
    this.setState({
      isDelete: false
    });
    const header = {
      // 'Access-Control-Allow-Origin': '*',
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rmSizeChartsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSizeChartApiEndPoint + '/' + size_chart_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDel
    );
    runEngine.sendMessage(this.rmSizeChartsCallId, requestMessage);
  }

  /**
   * Function to edit a size chart
   * @param size_chart size chart data object
   * @param size_chart_id id of the size chart
   */
  editSizeCharts(size_chart: any, size_chart_id: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem('authToken')
    };
    const body = { size_chart };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editSizeChartsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSizeChartApiEndPoint + '/' + size_chart_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(this.editSizeChartsCallId, requestMessage);
  }

  // -------------------------- HELPING METHODS --------------------------

  // -------------------------- Create Size Chart start --------------------------

  /**
   * Function to append new column to state/formik form
   */
  addColumn() {
    let columns = this.state.cols;
    columns.push({ label_name: "", variant_size_name_id: "" })
    this.setState({ cols: columns })
  }

  /**
   * Function to remove column and matching values in rows from state/formik form
   * @param index index of the column
   */
  removeColumn(colIndex: number) {
    let cols = this.state.cols;
    let colLabelName = this.state.cols[colIndex].label_name;
    cols.splice(colIndex, 1);
    this.setState({ cols: cols });

    // removing corresponding values from chartRows
    let rows = this.state.chartRows;
    rows.map((row: any) => {
      delete row[colLabelName];
    })
    this.setState({ chartRows: rows })
  }

  /**
   * Function to update the column
   * @param colIndex index of column in cols state variable
   * @param event 
   */
  updateColumns(colIndex: number, event: any) {
    let columns = this.state.cols;
    columns[colIndex].label_name = event?.target.value;
    this.setState({ cols: columns })
  }

  /**
   * Function to add new row
   */
  addRow() {
    let rows = this.state.chartRows;
    rows.push({ label_value: "" });
    this.setState({ chartRows: rows });
  }

  /**
   * Function to remove row
   * @param rowIndex index of row
   */
  removeRow(rowIndex: number) {
    let rows = this.state.chartRows;
    rows.splice(rowIndex, 1);
    this.setState({ chartRows: rows });
  }

  /**
   * Function to update row value
   * @param rowIndex index of row
   */
  updateRow(rowIndex: number, colIndex: number, e: any) {
    console.log('here')
    let rows = this.state.chartRows;
    rows[rowIndex][this.state.cols[colIndex].label_name] = e.target.value;
    this.setState({ chartRows: rows });
  }

  /**
   * Function to delete fields from row by setting new 'delete' property
   * @param row: the row to be deleted
   */

  deleteRow(colIndex: null, row: [{ id: number, label_value: string, label_name: string }], rowIndex: number) {
    var deletedRow: { id: any, label_value: string, label_name: string, destroy?: boolean }[] = [];
    row.map((field: { id: any, label_value: string, label_name: string, destroy?: boolean }) => {
      if (field.id !== '') {
        field['destroy'] = true;
        deletedRow.push(field);
      }
    })
    this.setState({ finalRows: [...this.state.finalRows, ...deletedRow.filter(field => field.destroy)] }, () => {
      console.log('sahib', this.state.finalRows);
      this.removeRow(rowIndex);
    })
  }

  updateField(rowIndex: number, colIndex: number, e: any) {
    let rows = this.state.chartRows;
    rows[rowIndex][colIndex].label_value = e.target.value;
    this.setState({ chartRows: rows });
  }

  addFieldRow() {
    var newRow: object[] = [];
    this.state.cols.forEach((col: { id: number, label_name: string }) => {
      newRow.push({
        label_value: "",
        id: "",
        column_name: col.label_name,
        row: this.state.chartRows.length
      })
    });
    this.setState({ chartRows: [...this.state.chartRows, ...[newRow]] });
    // loop over the columns and add a new object for each column
  }

  addFieldColumn() {
    var chartRows = this.state.chartRows;
    chartRows.forEach((row: [{ row: number, label_value: string, id: string, column_name: string }], rowIndex: number) => {
      row.push({
        label_value: "",
        id: "",
        column_name: "",
        row: row[0].row
      })
    });
    this.setState({ chartRows: chartRows }, () => {
      this.addColumn();
    });
  }

  updateFieldColumn(colIndex: number, e: any) {
    this.updateColumns(colIndex, e);
    var chartRows = this.state.chartRows;
    chartRows.forEach((row: [{ column_name: string }], rowIndex: number) => {
      row[colIndex].column_name = e.target.value;
    });
    this.setState({ chartRows: chartRows })
  }

  removeFieldColumn(colIndex: number) {
    var chartRows = this.state.chartRows;
    var deletedColumns: { destroy: boolean }[] = [];
    var newArr: object[] = [];
    var newCols: object[] = [];
    const cols = this.state.cols;

    console.log('sahib', cols);
    chartRows.forEach((row: [{}]) => {
      newArr.push(row.filter((col, index) => index != colIndex));
    })
    console.log('sahib', chartRows);
    chartRows.map((row: any) => {
      if (row[colIndex].id != '') {
        row[colIndex].destroy = true;
        deletedColumns.push(row[colIndex])
      }
    })

    this.setState({ chartRows: newArr }, () => {
      if (cols[colIndex].variant_size_name_id != '') {
        newCols.push({ ...cols[colIndex], ...{ destroy: true } });
      }
      this.removeColumn(colIndex);
      this.setState({ finalRows: [...this.state.finalRows, ...deletedColumns], finalCols: newCols }, () => {
        console.log('sahib', this.state.finalCols, this.state.finalRows);
      });
    });

  }
  // -------------------------- Create Size Chart end --------------------------


  // Customizable Area End
}
