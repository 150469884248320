import WishlistController, { configJSON } from "./WishlistController.web";
import React, { Component } from 'react'
import { Box, Typography } from "@material-ui/core";
const blueSuit = require("../assets/blueSuit.png")
const wishlistBag = require("../assets/wishlistBag.png")
const cross = require("../assets/cross.png")
const makeupKit = require("../assets/makeupKit.png")
const aeroSummerShorts = require("../assets/aeroSummerShorts.png")
const campus = require("../assets/campus.png")
const versace = require("../assets/versace.png")
const champion = require("../assets/champion.png")
const quiltedSlingBag = require("../assets/quiltedSlingBag.png")
const spykarBeachYellowShirt = require("../assets/spykarBeachYellowShirt.png")
const roadster = require("../assets/roadster.png")
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "../../../web/public/wishlist.css"
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/src/Loader.web"
import { Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {

  withStyles,

} from "@material-ui/core/styles";
import { spawn } from "child_process";

const styles = {
  pageOuter: {
    backgroundColor: "#FFFFFF",
    padding: '50px 0',
    '@media (max-width: 1199px)': {
      padding: '30px 0',
    }

  }
};

interface Props {

}
interface State {

}


export class Wishlist extends WishlistController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>


        <div className={`${classes.pageOuter} wishlist-wrapper`}>
          <Container maxWidth="lg" className="product-container">
            {this.state.isRemoved ? toast.success('Removed From Wishlist', { position: "top-right", autoClose: 1500, onOpen: this.RemovedMessage }) : null}
            {this.state.isAddedToBag ? toast.success('Added To Bag', { position: "top-right", autoClose: 1500, onOpen: this.AddToBagMessage }) : null}
            <div className="product-wrappers">
              <Typography
                variant="h5"
              >
                Wishlist  <span>{this.state.wishlist.length} items</span>
              </Typography>
              <Grid container className="product-items">
                {this.state.wishlist.length == 0 ? <>
                  <div style={{ height: "500px", textAlign: "center", paddingTop: "150px", marginLeft: "2px", width: "100%" }}>
                    {/* <img  width = "150px" height="150px" src={emptyCart}/> */}
                    <h6 >Your Wishlist Is Empty</h6>
                    <Button style={{ border: "1px solid black" }} href="/Home">Shop Now</Button>
                  </div>
                </> : <>
                </>}
                {this.state.wishlist.map((item: any) => {
                  const product = item.attributes?.catalogue.data.attributes
                  const sale_price = product && (product.sale_price ? product.sale_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.sale_price : product.sale_price);
                  const maximum_retail_price = product && (product.maximum_retail_price ? product.maximum_retail_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.maximum_retail_price : product.maximum_retail_price);
                  const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);
                  console.log(sale_price, "sale", maximum_retail_price, "mrp", discount, "%")
                  return (
                    <>

                      <Grid
                        onClick={() => {
                          // window.location.replace(`${window.location.protocol}//${window.location.host}/ProductDetail/${item.attributes.catalogue_id}`)
                          this.props.navigation.navigate("ProductDetail", { productId: item.attributes.catalogue_id })
                        }}
                        key={item.img}
                        className="product-item" item xs={6} sm={4} md={3}
                      >

                        <div className="product-item-info" >
                          <div className="product-item-photo">
                            <span className="product-image-wrapper">

                              <img
                                // style={{ opacity: item.attributes.catalogue.data.attributes.availability ? "none" : "0.6", width: "500px" }}
                                id="viewAllImg"
                                src={item.attributes.catalogue.data.attributes?.galleries?.[0].url} />
                            </span>
                            <div onClick={(e) => {
                              e.stopPropagation();
                              if (this.state.exchangeFlow &&
                                this.state.cartCount == 1
                                //  this.state.cartItemCount == 1
                              ) {

                                this.handleOpenMessagePopup()
                              } else {
                                this.wishlistAddBag(item);
                              }

                              // this.wishlistAddBag(item);
                            }} className="product-cart action-link">
                              <img src={wishlistBag} />

                            </div>
                            <a onClick={(e) => {
                              this.setState({ isDelete: true })
                              e.stopPropagation()
                              this.setState({ wishlistRemoveBag: item })
                              // this.wishlistRemoveBag();

                            }} className="product-cancel action-link">
                              <img src={cross} />
                            </a>
                            {/* {item.attributes.catalogue.data.attributes.availability ? <> </> : <> <span className="stock-info">
                              OUT OF STOCK
                            </span> </>} */}

                          </div>
                          <div className="product-item-details">
                            <div className="product-name" >
                              <a href="#" className="product-link">
                                {item.attributes?.catalogue.data.attributes?.name}
                              </a>
                            </div>
                            <div className="pricing-wrapper">
                              {/* <span className="price">₹{item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes?.sale_price}</span>... */}
                              {/* <span className="price old-price">₹{item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.maximum_retail_price}</span>... */}
                              {/* <span className="offer">({parseInt(((parseFloat(item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.sale_price)/item.attributes.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.maximum_retail_price)*100).toString())}% off)</span> */}
                              {/* <span className="offer">({ parseFloat((((Number(item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.maximum_retail_price) - Number(item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.sale_price)) * 100) / Number(item.attributes?.catalogue.data.attributes?.catalogue_variants_attributes?.[0].attributes.maximum_retail_price)).toString()).toFixed(2)}% off)</span>... */}
                              <span className="price">₹{sale_price}</span>
                              <span className="price old-price">₹{maximum_retail_price}</span>

                              <span className="offer">({parseFloat((((Number(maximum_retail_price) - Number(sale_price)) * 100) / Number(maximum_retail_price)).toString()).toFixed(2)}% off)</span>
                            </div>
                          </div>
                        </div>
                      </Grid>

                    </>
                  );
                })}
              </Grid>
            </div>

            {/* message popup  */}
            {/* <Dialog
              open={this.state.messagePopup}
              onClose={this.handleCloseMessagePopup}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              <DialogTitle id="alert-dialog-title">
                {"Message"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  you cannot add another product while exchange flow is in process
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleClose}>Disagree</Button> */}
            {/* <Button onClick={() => this.handleCloseMessagePopup()} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>  */}



            {/* message popup changed design  */}

            <Dialog
              open={this.state.messagePopup}
              aria-labelledby="simple-dialog-title"
              fullWidth={true} maxWidth={"xs"}
            >
              <Box className="myBox" >
                <Box textAlign="right" style={{
                  paddingRight: "11px",
                  paddingTop: "6px"
                }}>
                  <span className="close-btn" style={{ cursor: "pointer" }}>
                    <CloseIcon
                      onClick={() => this.handleCloseMessagePopup()}
                    ></CloseIcon>
                  </span>
                </Box>
                <Box className="modal-head" style={{ padding: "17px" }} fontWeight="600" p={1}>
                  <Typography style={{ textAlign: "justify" }} variant="h5"> You cannot add another product while exchange flow is in process<b>

                  </b>


                  </Typography>
                </Box>
                <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">

                  <Button className="black-btn"
                    onClick={(e) => {
                      this.handleCloseMessagePopup()

                    }

                    }
                    size="medium"
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </Dialog>














            {/* remove box  */}
            <Dialog
              open={this.state.isDelete}
              aria-labelledby="simple-dialog-title"
              fullWidth={true} maxWidth={"xs"}
            >
              <Box className="myBox">
                <Box textAlign="right" p={2}>
                  <span className="close-btn">
                    <CloseIcon
                      onClick={() => this.handleClose()}
                    ></CloseIcon>
                  </span>
                </Box>
                <Box className="modal-head" textAlign="center" fontWeight="600" p={1}>
                  <Typography variant="h5"> Are you sure, you want to delete? <b>
                    {/* {this.state.sizeChartToDelete.attributes?.product_name} */}
                  </b>
                    {/* size chart? */}

                  </Typography>
                </Box>
                <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">
                  <Button className="black-btn"
                    onClick={() => this.handleClose()}
                    size="medium"
                  >
                    No
                  </Button>
                  <Button className="black-btn"
                    onClick={(e) => {
                      this.setState({ isDelete: false })
                      this.wishlistRemoveBag();

                      e.stopPropagation()
                    }}
                    size="medium"
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Container>
        </div>


      </>
    )
  }
}

export default withStyles(styles)(Wishlist);
