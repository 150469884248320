import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  availableBalance: number;
  nextPayoutDate: string;

  newOrders: number;
  aov: number;
  sales: number;
  inTransit: number;

  sortBy: string;

  orders: any[];

  isOrderModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PaymentAPICallId: any;

  SalesAnalyticsAPICallId: any;
  AOVAnalyticsAPICallId: any;
  NewOrderAnalyticsAPICallId: any;
  InTransitAnalyticsAPICallId: any;

  SchedulePickupAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: true,

      availableBalance: 0,
      nextPayoutDate: "",

      newOrders: 0,
      aov: 0,
      sales: 0,
      inTransit: 0,

      sortBy: "today",

      orders: [],

      isOrderModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Payment
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PaymentAPICallId !== null &&
      this.PaymentAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PaymentAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        availableBalance: responseJson.available_balance,
        nextPayoutDate: responseJson.next_payout_date,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Analytics
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SalesAnalyticsAPICallId !== null &&
      this.SalesAnalyticsAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.SalesAnalyticsAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        sales: responseJson.sales,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AOVAnalyticsAPICallId !== null &&
      this.AOVAnalyticsAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AOVAnalyticsAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        aov: responseJson.aov,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.NewOrderAnalyticsAPICallId !== null &&
      this.NewOrderAnalyticsAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.NewOrderAnalyticsAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        newOrders: responseJson.new_order,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.InTransitAnalyticsAPICallId !== null &&
      this.InTransitAnalyticsAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.InTransitAnalyticsAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        inTransit: responseJson.in_shipped,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SchedulePickupAPICallId !== null &&
      this.SchedulePickupAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.SchedulePickupAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          orders: responseJson.data,
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area
  async componentDidMount() {
    await this.getPaymentData();

    await this.getSalesAnalytics();
    await this.getAOVAnalytics();
    await this.getNewOrdersAnalytics();
    await this.getInTransitAnalytics();

    await this.getSchedulePickupOrder();
  }

  // Payment
  getPaymentData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PaymentAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Analytics
  getSalesAnalytics = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SalesAnalyticsAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsAPIEndPoint}?requested_on=${
        this.state.sortBy
      }&status=sales`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  getAOVAnalytics = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AOVAnalyticsAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsAPIEndPoint}?requested_on=${
        this.state.sortBy
      }&status=aov`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  getNewOrdersAnalytics = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.NewOrderAnalyticsAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsAPIEndPoint}?requested_on=${
        this.state.sortBy
      }&status=new_order`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  getInTransitAnalytics = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.InTransitAnalyticsAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.analyticsAPIEndPoint}?requested_on=${
        this.state.sortBy
      }&status=in_transit`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Schedule Pickup
  getSchedulePickupOrder = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SchedulePickupAPICallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schedulePickupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handle Modal
  handleModal = () => {
    this.setState({
      ...this.state,
      isOrderModalOpen: !this.state.isOrderModalOpen,
    });
  };
  // Customizable Area End
}
