Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderSummary";
exports.labelBodyText = "OrderSummary Body";
exports.recievedApiEndPoint = "/bx_block_order_management/order_status";
exports.unshippedApiEndPoint =
  "bx_block_order_management/unshipped_orders?requested_on=last_week";
exports.btnExampleTitle = "CLICK ME";
exports.cancelOrderApiMethod = "PUT";
exports.dropDownApiMethodType = "PATCH";
exports.reasonCancelApiEndPoint =
  "bx_block_order_management/reason_to_cancle";
exports.reasonToCancelApiEndPoint =
  "bx_block_order_management/reason_to_cancle";
exports.searchApiEndPoint =
  "bx_block_order_management/search_order";
exports.confirmApiEndPoint =
  "bx_block_order_management/orders/update_status";
exports.printInvoiceApiEndPoint =
  "bx_block_ordersummary/print_invoice";
exports.printShippingLableApiEndPoint =
  "bx_block_ship_rocket_shipment/ship_rocket_shipments/generate_label";
exports.printmanifiestApiEndPoint =
  "bx_block_ordersummary/print_manifest";
// Customizable Area End
