import React from "react";
import {
  Container,
  Grid,
  MenuItem,
  IconButton,
  Button,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@szhsin/react-menu";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CouponsController, {
  Props,
  // configJSON
} from "./CouponsController.web";
import Loader from "../../../components/src/Loader.web";
import { CouponsStyle } from "./CouponsStyle.web";
import Pagination from "@material-ui/lab/Pagination";

export class Coupons extends CouponsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <div />
                      <h2>Coupons</h2>
                      <div>
                        <Menu
                          menuButton={
                            <div>
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          }
                        >
                          <MenuItem
                            disabled={this.state.selectedCoupons.length === 0}
                            onClick={() => {
                              this.deleteMultipleCoupon();
                            }}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            disabled={this.state.selectedCoupons.length === 0}
                            onClick={() => {
                              this.changeMultipleCouponStatus("active");
                            }}
                          >
                            Enable
                          </MenuItem>
                          <MenuItem
                            disabled={this.state.selectedCoupons.length === 0}
                            onClick={() => {
                              this.changeMultipleCouponStatus("inactive");
                            }}
                          >
                            Disable
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <div className="search-coupons">
                    <div className="form-group">
                      <input
                        value={this.state.search}
                        type="text"
                        placeholder="Search Coupons"
                        onChange={(e: any) => {
                          this.setState({
                            ...this.state,
                            search: e.target.value,
                          });
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === "Enter") {
                            this.getCouponList(this.state.search);
                          }
                        }}
                      />
                      <SearchIcon />
                    </div>

                    <div className="btn-wrapper">
                      <Link href="/coupon/add">
                        <Button
                          variant="contained"
                          className="black-btn"
                          type="button"
                        >
                          Create Coupon
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Grid>

                <div className="coupons-list">
                  {this.state.coupons.length === 0 && (
                    <div className="coupon">
                      <p className="text-center">
                        There is No Discount Coupon Available..
                      </p>
                    </div>
                  )}
                  {this.state.coupons.map((coupon) => {
                    return (
                      <div
                        className="coupon"
                        key={coupon.id}
                        style={{
                          backgroundColor: `${
                            coupon.attributes.status === "active" ||
                            coupon.attributes.status === null
                              ? "white"
                              : "#efefef"
                          }`,
                        }}
                      >
                        <div className="coupon-check">
                          <input
                            type="checkbox"
                            name="coupon"
                            checked={this.state.selectedCoupons.includes(
                              coupon.id
                            )}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                this.setState({
                                  ...this.state,
                                  selectedCoupons: [
                                    ...this.state.selectedCoupons,
                                    coupon.id,
                                  ],
                                });
                              } else {
                                const newSelectedCoupons = this.state.selectedCoupons.filter(
                                  (id) => id !== coupon.id
                                );
                                this.setState({
                                  ...this.state,
                                  selectedCoupons: newSelectedCoupons,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="coupon-info">
                          <div className="coupon-title">
                            <p>{coupon.attributes.coupon_code}</p>
                            <div>
                              <Menu
                                menuButton={
                                  <div>
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                }
                              >
                                <MenuItem
                                  onClick={() => {
                                    this.props.navigation.navigate(
                                      "EditCoupon",
                                      { id: coupon.id }
                                    );
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    this.deleteCoupon(coupon.id);
                                  }}
                                >
                                  Delete
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    let status =
                                      coupon.attributes.status === "active" ||
                                      coupon.attributes.status === null
                                        ? "inactive"
                                        : "active";
                                    this.changeCouponStatus(coupon.id, status);
                                  }}
                                >
                                  {coupon.attributes.status === "active" ||
                                  coupon.attributes.status === null
                                    ? "Disable"
                                    : "Enable"}
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                          <div className="coupon-desc">
                            <span>{coupon.attributes.discription}</span>
                          </div>
                          <div className="coupon-details">
                            <div className="coupon-detail">
                              <div className="left">
                                <p>
                                  <span>Type: </span>{" "}
                                  {coupon.attributes.coupon_type ===
                                  "cash_discount"
                                    ? "Amount Discount"
                                    : " Percentage Discount"}
                                </p>
                                <p>
                                  <span>Expiry Date: </span>{" "}
                                  {moment(coupon.attributes.expiry_date).format(
                                    "DD/MM/yyyy"
                                  )}
                                </p>
                                {/* <p>
                                  <span>Min. Spend: </span>{" "}
                                  {coupon.attributes.minimum_spend
                                    ? coupon.attributes.minimum_spend
                                    : "-"}
                                </p> */}
                              </div>
                              <div className="right">
                                <p>
                                  <span>Amount: </span>{" "}
                                  {coupon.attributes.amount}
                                </p>
                                <p>
                                  <span>Usage/Limit: </span>{" "}
                                  {coupon.attributes.usage_limit_per_customer
                                    ? coupon.attributes.usage_limit_per_customer
                                    : "-"}
                                  /
                                  {coupon.attributes.usage_limit_per_coupon
                                    ? coupon.attributes.usage_limit_per_coupon
                                    : "-"}
                                </p>
                                {/* <p>
                                  <span>Max. Spend: </span>{" "}
                                  {coupon.attributes.maximum_spend
                                    ? coupon.attributes.maximum_spend
                                    : "-"}
                                </p> */}
                              </div>
                            </div>
                            <p>
                              <span>Products IDs: </span>{" "}
                              {coupon.attributes.catalogue_ids.length === 0
                                ? "-"
                                : coupon.attributes.catalogue_ids.toString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {this.state.pagination && (
                  <Pagination
                    onChange={(event: any, value: any) => {
                      this.setState({
                        ...this.state,
                        currentPage: Number(value),
                      });
                    }}
                    count={this.state.pagination.total_pages}
                    defaultValue={this.state.pagination.current_page}
                    defaultPage={this.state.pagination.current_page}
                    siblingCount={2}
                    page={this.state.pagination.current_page}
                    variant="outlined"
                    shape="rounded"
                  />
                )}
              </Grid>
            </Container>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

export default withStyles(CouponsStyle)(Coupons);
