Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist2";
exports.labelBodyText = "Wishlist2 Body";
exports.apiMethodTypeDELETE = "DELETE";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";

exports.apiContentType = "application/json";
exports.getMethodType = "GET";
exports.postMethodType = "POST";

exports.getProduct_SimilarProduct_FromSameBrand_InterestedIn = "bx_block_catalogue/catalogues/customer_product";
exports.getRecentlyViewProduct = "bx_block_catalogue/catalogues/recent_view_product"
exports.getProductDetailById = "catalogue/catalogues";
exports.addToWishlistApi = "bx_block_wishlist2/wishlist/add_to_wishlist"
exports.removeFromWishlistApi = "bx_block_wishlist2/wishlist/remove_to_wishlist"
exports.activateCartApi = "bx_block_shopping_cart/get_active_cart";
exports.addToCartApi = "bx_block_order_management/orders";
exports.removeFromCartApi = "bx_block_shopping_cart/cart_items/";
exports.cartListApi = "bx_block_shopping_cart/cart_items";
exports.getvariantListBySizeAndColorApi = "bx_block_catalogue/catalogues";
exports.addToGroupWishlistAPIEndPoint = "bx_block_groupshopping/add_to_wishlist";
exports.reviewListAPIEndPoint = "review_list";
exports.RemoveShoppingBagAPIEndPoint = "bx_block_shopping_cart/cart_items";
exports.PlaceExchangeOrderAPiEndPoint = "bx_block_order_management/orders";
// exports.getvariantListByColorApi = "bx_block_catalogue/catalogues/6/get_variants_size_by_color?color=yellow";
// Customizable Area End