import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  callbackShopWithFriend: Function;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emails: string;
  emailError: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InviteFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  InviteFriendsCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      emails: "",
      emailError: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Create Group
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.InviteFriendsCallId !== null &&
      this.InviteFriendsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.InviteFriendsCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.message) {
        toast.success(responseJson.message, {
          position: "top-right",
          autoClose: 5000,
        });
        this.setState({
          ...this.state,
          emails: "",
          emailError: "",
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  validateEmails = () => {
    if (this.state.emails.length === 0) {
      this.setState({
        ...this.state,
        emailError: "Please enter emails",
      });
      return false;
    }
    const emails = this.state.emails.split(", ");
    const isEmail = emails.every(this.validateEmail);

    if (!isEmail) {
      this.setState({
        ...this.state,
        emailError: "Please enter valid emails",
      });
      return false;
    } else {
      this.setState({
        ...this.state,
        emailError: "",
      });
      return true;
    }
  };

  inviteFriends = () => {
    if (!this.validateEmails()) {
      return;
    }

    const emails = this.state.emails.split(", ");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      email: emails,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.InviteFriendsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteFriendsAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };
  // Customizable Area End
}
