import React, { useState,Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from '@material-ui/core/Link';
const  makeupKit = require ("../assets/makeupKit.png")
const  aeroSummerShorts = require ("../assets/aeroSummerShorts.png")
const  campus = require ("../assets/campus.png")
const  versace = require ("../assets/versace.png")
const  champion = require ("../assets/champion.png")
const  quiltedSlingBag = require ("../assets/quiltedSlingBag.png")
const  spykarBeachYellowShirt = require ("../assets/spykarBeachYellowShirt.png")
const  roadster = require ("../assets/roadster.png")
const  blueSuit = require ("../assets/blueSuit.png")
const  wishlistHeart  = require ("../assets/wishlist-heart.png")
const wishlistTrue = require("../assets/wishlistTrue.png");





import Carousel from "react-multi-carousel";
import Loader from "../../../components/src/Loader.web"
// import ImageGallery from 'react-image-gallery';
// import "react-image-gallery/styles/css/image-gallery.css";/

// import Wishlist2Controller, {
//   Props,
//   configJSON
// } from "./Wishlist2Controller";
import { url } from "inspector";
import { CrossIcon } from "react-select/src/components/indicators";
import DashboardControllers, { Props, configJSON } from "./DashboardControllers.web";



const styles:any = {
  pageOuter: {
    textAlign:"center",
    fontFamily: "'Roboto',sans-serif",
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },
    "& .title-block": {
        marginTop:"20px",
        marginBottom:"8px",
      fontWeight: "500",
      color: "#444",
      fontSize: "32px",
      "@media (max-width:480px)": {
        fontSize: "26px",
        margin: "16px 0",
      },
    },
    "& .row-wrap": {
      margin: "0 -8px",
      "@media (max-width:480px)": {
        margin: "0 -4px",
      },
    },
    "& .row-wrap .column": {
      padding: "0 8px",
      height: "100%",
      "@media (max-width:480px)": {
        padding: "0 4px",
      },
    },
    "& .column .inner-col": {
      color: "#444",
      display: "block",
      height: "100%",
    },
    "& .column .img-block": {
      margin: "0 0 10px",
    },
    "& .column .img-block img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .column .col-name": {
      color: "#444",
      fontSize: "24px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      lineHeight: "28px",
      "@media (max-width:480px)": {
        fontSize: "12px",
        lineHeight: "16px",

      },
    },
  },
  heart :{
  
    // position: 'absolute',
    width: '30px',
    height: '30px',
    // top: '11px',
    // right: '11px',
    backgroundColor: '#f4f4f4',
    border: '1px solid #dddddd',
    borderRadius:' 100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding:' 5px',
    zIndex: '2',
    position:"relative",
    bottom:"40px",
    left:"189px"
    // -o-transition: 'all 0.3s ease',
    // -ms-transition: 'all 0.3s ease',
    // -moz-transition: 'all 0.3s ease',
    // -webkit-transition: 'all 0.3s ease',
    // transition: 'all 0.3s ease',
  
},
  shopByPrice: {
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .row-wrap .column": {
      flex: "0 0 50%",
      maxWidth: "50%",
      padding: "10px",
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "5px 0",
      },
    },
    "& .column .inner-col": {
      display: "flex",
    },
    "& .column .inner-col .left-block": {
      maxWidth: "50%",
      flex: "0 0 50%",
      border: "40px solid",
      borderColor: "inherit",
      textAlign: "center",
      padding: "1px",
      "@media (max-width:991px)": {
        border: "20px solid",
      },
    },
    "& .column .img-block": {
      height: "250px",
      marginBottom: "0",
      maxWidth: "50%",
      flex: "0 0 50%",
      "@media (max-width:991px)": {
        height: "170px",
      },
    },
    "& .column .inner-col .left-block .inner-left-block": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      height: "100%",
      border: "5px solid",
      borderColor: "inherit",
    },
    "& .column .inner-col .left-block .discount": {
      display: "block",
      fontSize: "30px",
      lineHeight: "34px",
      textTransform: "uppercase",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "12px",
      "@media (max-width:991px)": {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
    "& .column .inner-col .left-block .shop-now": {
      display: "block",
      fontSize: "16px",
      textTransform: "uppercase",
      color: "#444",
      backgroundColor: "#fff",
      padding: "8px",
      boxShadow: "0 0 1px 1px #eee",
      "@media (max-width:991px)": {
        fontSize: "11px",
        padding: "5px",
      },
    },
    "& .column.green-border .inner-col .left-block": {
      borderColor: "#bdcd8f",
    },
    "& .column.red-border .inner-col .left-block": {
      borderColor: "#fa7167",
    },
    "& .column.yellow-border .inner-col .left-block": {
      borderColor: "#feca39",
    },

    "& .column.green-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#fb7d8b",
    },
    "& .column.red-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#81d478",
    },
    "& .column.yellow-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#d53f41",
    },

  },
  categoriesMenu: {
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .row-wrap .column": {
      flex: "0 0 20%",
      maxWidth: "20%",
      marginBottom: "30px",
      "@media (max-width:1024px)": {
        flex: "0 0 25%",
        maxWidth: "25%",
      },
      "@media (max-width:768px)": {
        flex: "0 0 33.33%",
        maxWidth: "33.33%",
      },
      "@media (max-width:480px)": {
       marginBottom: "15px",
      },

    },
    "& .column .img-block": {
      height: "250px",
      borderRadius: "10px",
      overflow: "hidden",
      "@media (max-width:1024px)": {
        height: "200px",
      },
      "@media (max-width:991px)": {
        height: "170px",
      },
      "@media (max-width:480px)": {
        height: "100px",
      },
    },
  },
  recentlyViewed: {
    "& .row-wrap": {
      "@media (max-width:480px)": {
        margin: "0 -2px",
      },
    },
    "& .row-wrap .column": {
      "@media (max-width:480px)": {
        padding: "0 2px",
      },
    },
    "& .img-block": {
      height:"300px",
      borderRadius: "10px",
      overflow: "hidden",
    },
    "& .img-block img": {
      maxHeight: "300px",
      "@media(max-width:599px)": {
        maxHeight: "250px",
      },
    },
    "& .column .col-name": {
      textAlign: "left",
      marginTop: "10px",
    },
  },
};

const wishlist =
  [
    { img: versace, available: true },
    { img: champion, available: false },
    { img: spykarBeachYellowShirt, available: true },
    { img: quiltedSlingBag, available: false },
    { img: blueSuit, available: true },
    { img: makeupKit, available: true },
    { img: aeroSummerShorts, available: true },
    { img: roadster, available: true },
    { img: campus, available: false },
    { img: versace, available: true },
    { img: blueSuit, available: true },
    { img: spykarBeachYellowShirt, available: true }
  ]

const productResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 959 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 959, min: 599 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 2,
  },
};

const galleryImages = [
  {
    original: blueSuit,
    thumbnail: blueSuit,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: aeroSummerShorts,
    thumbnail: aeroSummerShorts,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: campus,
    thumbnail: campus,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: versace,
    thumbnail: versace,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: champion,
    thumbnail: champion,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: quiltedSlingBag,
    thumbnail: quiltedSlingBag,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: spykarBeachYellowShirt,
    thumbnail: spykarBeachYellowShirt,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: roadster,
    thumbnail: roadster,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
  {
    original: blueSuit,
    thumbnail: blueSuit,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
];

const recentlyViewedResponsive = {

  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2.25,
    slidesToSlide: 1,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2.25,
    slidesToSlide: 1,
  },
};

export class Categories extends DashboardControllers {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {/* product detail page */}
        <div className={classes.pageOuter}>
          <Container maxWidth="lg" className="product-container">
            <h1>Categories</h1>
          </Container>
          {/* categories menu */}
          <div className={classes.categoriesMenu}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <div className="row-wrap">
  {this.state.data.map((val:any)=> <><div onClick = {()=>{localStorage.setItem("pid",val.attributes.id);this.props.navigation.navigate("CategoriesList") }}className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={val.attributes.image} />
                      </div>
                      <div className="col-name">
                        <span>{val.attributes.name}</span>
                      </div>
                    </Link>
                  </div>     </>)}               
  
                </div>
              </div>
            </Container>
          </div>
          {/* categories menu ends */}

          {/* shop by price */}
          <div className={classes.shopByPrice}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <h2 className="title-block">Shop By Price</h2>
                <div className="row-wrap">


                {this.state.loading?<Loader loading = {this.state.loading}/>:
                this.state.data1.slice(0,4).map((val:any)=> <>  
                
                <div className="column green-border">
                    <div className="inner-col">
                      <div className="left-block">
                        <div className="inner-left-block">
                          <span className="discount">Under ₹599</span>
                          <Link className="shop-now">shop now</Link>
                        </div>
                      </div>
                      <div className="img-block">
                      <img src={val.attributes.galleries?.[0].url} />
                      </div>
                    </div>
                  </div> </>)}
                </div>
              </div>
            </Container>
          </div>
          {/* shop by price ends */}

          {/* recently viewed */}
          <div className={classes.recentlyViewed}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <h2 className="title-block">Recently Viewed</h2>
                <div className="row-wrap">
                  <Carousel
                    renderDotsOutside
                    responsive={recentlyViewedResponsive}
                    showDots={false}
                    arrows={false}
                  >
                    {this.state.data2.length == 0? <Loader loading = {this.state.loading}/>:
                    this.state.data2.map((val:any)=> <>
    
                    <div className="column">
                                    <Link className="inner-col">
                                      <div className="img-block">
                                      <img src={val.attributes?.galleries?.[0].url} />
                                      <a  onClick={()=>{this.StatusBlog(val.attributes.is_wishlist,val.attributes.catalogue_id);}}
                         className={classes.heart}> 
                                      <img style ={{width:"30px"}} src={val.attributes.is_wishlist ? wishlistTrue : wishlistHeart}/> 
                                    </a>
                                      </div>
                                      <div className="col-name">
                                        <span>  {val.attributes?.description}</span>
                                      </div>
                                    </Link>
                                  </div>
                    </>)
                    
                    }
     </Carousel>
                </div>
              </div>
            </Container>
          </div>
          {/* recently viewed ends */}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Categories);