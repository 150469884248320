import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as React from "react";
import { useScrollToTop } from "@react-navigation/native";
import { setTimeout } from "timers";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: any;
  data1: any;
  data2: any;
  blogData: any;
  search: any;
  isLoading: boolean;
  Eaddress: any;
  radioBtn: boolean;
  loading: boolean;
  state: string;
  city: string;
  mobile: number | string;
  email: string;
  town: string;
  name: string;
  address: string;
  pincode: string | number;
  save_add: string;
  save_add_Bol: boolean;
  addressID: string | number;
  Checked: number;
  popup: boolean;
  navigate: boolean;
  status: string;
  edith_state: string;
  listCategory: any;
  edith_city: string;
  edith_town: string;
  edith_name: string;
  active: any;
  edith_address: string;
  edith_pincode: string | number;
  token: string | null;
  addressEditData: any;
  is_defulat: boolean;
  visible: boolean;
  home: boolean;
  category: boolean;
  new: boolean;
  offer: boolean;
  languageList: any;
  data3: any;
  brandData: any;
  SearchTitle: any;
  banner: any;
  collection: any;
  key: string;
  allBlog: any;
  fsBlog: any;
  beautyBlog: any;
  luxuryBlog: any;
  detailValue: boolean;
  dataBlogDetail: any;
  categoryId: any;
  tabView: any;
  navCategory: any;
  anchorEl: any;
  open: boolean;
  isActive: boolean;
  subMenuActive: boolean;
  isMenActive: boolean;
  subMenuMenActive: boolean;
  categoryid: any;
  collectiondata: any;
  render: any;
  toggle: any;
  wishlist: any;
  subcategories: any;
  placeOrderData: any;
  catalogueId: any;
  catalogueVariantId: any;
  activeCartId: any;
  productSize: boolean;
  productQty: boolean;
  applyCoupon: boolean;
  payment: boolean;
  ShoppingCartData: any;
  wishlistloading: boolean;
  isShopWithFriendOpen: boolean;
  isNewInActive: boolean;
  isOfferActive: boolean;
  isCategoryActive: number;
  isSubCategoryActive: number;
  homeCenterBanner: any;
  homeBottomBanner: any;
  profileDetail: any;
  profileName: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardControllers extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  CategoriesCallId: any;
  ShopByPricesCallId: any;
  RecentlyViewdsCallId: any;
  DelAddressCallId: any;
  addNewAddressCallId: any;
  CategoryCallId: any;
  EditAddressCallId: any;
  TrendingNowCallId: any;
  BlogNowCallId: any;
  EidthAddressCallId: any;
  DefualtAddressCallId: any;
  BrandCallId: any;
  MainBannerCallId: any;
  SearchCallId: any;
  CollectionNowCallId: any;
  CategoryByIDCallId: any;
  AddToWishCallId: any;
  RemoveToWishCallId: any;
  WishlistCallId: any;
  SubCategoriesCallId: any;
  PlaceOrderCallId: any;
  ActiveCartCallId: any;
  ShoppingCartDataCallId: any;
  RemoveShoppingBagCallId: any;
  HomeTopBannerCallId: any;
  HomeCenterBannerCallId: any;
  HomeBottomBannerCallId: any;
  getProfileId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      data: [],
      data1: [],
      data2: [],
      data3: [],
      dataBlogDetail: [],
      tabView: "",
      detailValue: false,
      blogData: [],
      categoryId: [],
      search: [],
      listCategory: [],
      brandData: [],
      isLoading: false,
      active: 0,
      Eaddress: [],
      radioBtn: false,
      key: "",
      home: true,
      collection: [],
      category: false,
      new: false,
      offer: false,
      state: "",
      city: "",
      mobile: 0,
      email: "",
      loading: false,
      town: "",
      SearchTitle: "",
      name: "",
      address: "",
      pincode: "",
      edith_state: "",
      edith_city: "",
      edith_town: "",
      edith_name: "",
      edith_address: "",
      edith_pincode: "",
      save_add: "home",
      save_add_Bol: true,
      addressID: "",
      Checked: 0,
      popup: false,
      navigate: false,
      status: "",
      token: "",
      addressEditData: {},
      is_defulat: false,
      visible: false,
      banner: [],
      allBlog: true,
      fsBlog: false,
      beautyBlog: false,
      luxuryBlog: false,
      languageList: ["WOMEN", "MEN", "TECH & TRAVEL", "LIFESTYLE"],
      navCategory: true,
      anchorEl: null,
      open: false,
      isActive: false,
      subMenuActive: false,
      isMenActive: false,
      subMenuMenActive: false,
      categoryid: localStorage.getItem("category")
        ? localStorage.getItem("category")
        : "3",
      collectiondata: [],
      render: "",
      toggle: "",
      wishlist: [],
      subcategories: [],
      placeOrderData: [],
      catalogueId: 0,
      catalogueVariantId: 0,
      activeCartId: [],
      productSize: false,
      productQty: false,
      applyCoupon: false,
      payment: false,
      ShoppingCartData: [],
      wishlistloading: false,
      isShopWithFriendOpen: false,
      isCategoryActive: 0,
      isSubCategoryActive: 0,
      isNewInActive: false,
      isOfferActive: false,
      homeCenterBanner: null,
      homeBottomBanner: null,
      profileDetail: [],
      profileName: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start

    // Show Categories
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CategoriesCallId !== null &&
      this.CategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CategoriesCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          data: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //Main Banner By Id
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.MainBannerCallId !== null &&
      this.MainBannerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.MainBannerCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          banner: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // List Category
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CategoryCallId !== null &&
      this.CategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CategoryCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          listCategory: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Wishlist
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.WishlistCallId !== null &&
      this.WishlistCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.WishlistCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          wishlist: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ wishlistloading: false });
    }

    // Place Order
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PlaceOrderCallId !== null &&
      this.PlaceOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PlaceOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          placeOrderData: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // List Category By Id
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CategoryByIDCallId !== null &&
      this.CategoryByIDCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CategoryByIDCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          categoryId: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // profile data 
    // get profile detail start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileId !== null &&
      this.getProfileId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getProfileId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {

        this.setState({ profileDetail: responseJson.data });


        // console.log('//////////////profile detail data------------------------------------------------------', this.state.profileDetail);

        this.setState({
          profileName: this.state.profileDetail.attributes?.name == null ? "" : this.state.profileDetail.attributes?.name,

        })


      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    //   get profile detail end 





    // Active Cart By Id
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ActiveCartCallId !== null &&
      this.ActiveCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ActiveCartCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          activeCartId: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Shopping Cart
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ShoppingCartDataCallId !== null &&
      this.ShoppingCartDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ShoppingCartDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          ShoppingCartData: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Add To Wishlist
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AddToWishCallId !== null &&
      this.AddToWishCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AddToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.RecentlyViewd();
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Remove From Whishlist
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveToWishCallId !== null &&
      this.RemoveToWishCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.RecentlyViewd();
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Remove From Shopping Bag
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveShoppingBagCallId !== null &&
      this.RemoveShoppingBagCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveShoppingBagCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Sub Categories
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SubCategoriesCallId !== null &&
      this.SubCategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.SubCategoriesCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          subcategories: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Shop By Price
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ShopByPricesCallId !== null &&
      this.ShopByPricesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ShopByPricesCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          data1: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Recently View
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RecentlyViewdsCallId !== null &&
      this.RecentlyViewdsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RecentlyViewdsCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          data2: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Trending
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.TrendingNowCallId !== null &&
      this.TrendingNowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.TrendingNowCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          data3: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Blog
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.BlogNowCallId !== null &&
      this.BlogNowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.BlogNowCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          blogData: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Collection
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CollectionNowCallId !== null &&
      this.CollectionNowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CollectionNowCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          collection: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Search
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SearchCallId !== null &&
      this.SearchCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.SearchCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          isLoading: false,
          search: responseJson.data,
        });
      }

      localStorage.setItem("searchlength", this.state.search.length);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Top Brand
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.BrandCallId !== null &&
      this.BrandCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.BrandCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          brandData: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Home Top Banner
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.HomeTopBannerCallId !== null &&
      this.HomeTopBannerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.HomeTopBannerCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          banner: responseJson.data,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Home Center Banner
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.HomeCenterBannerCallId !== null &&
      this.HomeCenterBannerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.HomeCenterBannerCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          homeCenterBanner: responseJson.data[responseJson.data.length - 1],
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Home Bottom Banner
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.HomeBottomBannerCallId !== null &&
      this.HomeBottomBannerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.HomeBottomBannerCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          loading: false,
          homeBottomBanner: responseJson.data[responseJson.data.length - 1],
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  // Customizable Area Start
  async componentDidMount() {
    try {
      const localToken = await localStorage.getItem("authToken");
      this.setState({ ...this.state, token: localToken, loading: true });

      this.homeTopBanner();
      this.homeCenterBanner();
      this.homeBottomBanner();

      this.TrendingNow();

      this.CollectionNow();

      this.topBrandNow();

      this.ShopByPrices();
      
      if (localStorage.getItem("authToken")) {
        this.getProfile()
        this.RecentlyViewd();
        this.Wishlist();
      }

      this.BlogNow();
    } catch (e) {
      // error reading value
    }
  }



  getProfile() {


    const header = {
      "Content-Type": "application/json",
      token: this.state.token

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }







  handleChange(data: any) {
    // this.setState({ ...this.state, loading: true, categoryid: data });
    // localStorage.setItem("category", data);
    // setTimeout(() => {
    //   this.CollectionNow();
    //   this.TrendingNow();
    //   this.topBrandNow();
    //   if (localStorage.getItem("authToken")) {
    //     this.RecentlyViewd();
    //   }
    // }, 1000);
  }

  handleClick = (event: any) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  displaySubMenu = () => {
    this.setState({ ...this.state, isActive: true });
  };

  displayMegaMenu = () => {
    this.setState({ subMenuActive: true });
  };

  displayMenMegaMenu = () => {
    this.setState({ subMenuMenActive: true });
  };

  handleClickCategory = (ID: any) => {
    localStorage.setItem("category", ID);
  };

  // status block for adding wishlist and removing wishlist
  StatusBlog = async (data: any, id: any) => {
    if (data) {
      this.RemoveWishList(id);
      // this.RecentlyViewd();
    } else {
      this.AddWishList(id);
      // this.RecentlyViewd();
    }
  };

  // Home Top Banner
  homeTopBanner() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.HomeTopBannerCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.HomeTopBannerAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Home Center Banner
  homeCenterBanner() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.HomeCenterBannerCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.HomeCenterBannerAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Home Bottom Banner
  homeBottomBanner() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.HomeBottomBannerCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.HomeBottomBannerAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Active Cart By Id
  activeCartId() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ActiveCartCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ActiveCartAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Shopping Cart
  shoppingCartData() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ShoppingCartDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ShoppingCartAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Place Order
  placeorder(catvarid: any, catalogueid: any) {
    const catvariantid = Number(catvarid);

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      quantity: 1,
      catalogue_id: catalogueid,
      catalogue_variant_id: catvariantid ? catvariantid : null,
      cart_id: this.state.activeCartId.id ? this.state.activeCartId.id : null,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PlaceOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PlaceOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Add WishList
  AddWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      "catalogue_ids": [data]
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AddToWishCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWishlistAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Remove Whishlist
  RemoveWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveToWishCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveWishlistAPIEndPoint.concat(data)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Remove From Shopping Bag
  RemoveShoppingBag(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveShoppingBagCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint.concat(data)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDELETE
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Wishlist
  Wishlist() {
    this.setState({ ...this.state, wishlistloading: true });

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.WishlistCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.WishlistAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Category
  showCategories() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CategoriesCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiShowCategoryAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Shop By Price
  ShopByPrices() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ShopByPricesCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiShopByPriceAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Sub Categories
  subcategories() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SubCategoriesCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiSubCategoryAPiEndPoint + "4"
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Recently Viewed
  RecentlyViewd() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RecentlyViewdsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiRecentlyViewdAPiEndPoint
      // + `?product_type=${this.state.categoryid}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Trending
  TrendingNow() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.TrendingNowCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiTreandingAPiEndPoint
      // + `?product_type=${this.state.categoryid}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Blog
  BlogNow() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.BlogNowCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiDiscoverBlogAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Top Brand
  topBrandNow() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.BrandCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiTopAPiEndPoint
      // + `?product_type=${this.state.categoryid}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Collection Now By Id
  CollectionNow() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CollectionNowCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCollectionAPiEndPoint
      // + `?product_type=${this.state.categoryid}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Banner
  MainBanner() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.MainBannerCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiMainBannerEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Category By Id
  CategoryIDCall(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CategoryByIDCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCategoryIDAPiEndPoint.concat(data)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Category
  CategoryCall() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CategoryCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiCategoryAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Serach
  Search() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SearchCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiSerachAPiEndPoint.concat(this.state.key)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  keySearch = () => {
    // this.props.navigation.navigate("PackagingWeb")
    this.setState({ search: [] });
    if (this.state.key) {
      if (this.state.key.length) {
        this.setState({ search: [] });
        this.Search();
      }
    }
  };

  hideMenu = () => this.setState({ visible: false });
  showMenu = () => this.setState({ visible: true });

  // Drawer
  navigateToScreen = (index: any) => () => {
    if (index == 8) {
      this.props.navigation.navigate("AboutUs");
    } else if (index == 1) {
      this.props.navigation.navigate("MyOrderViewScreen");
    }
  };

  InputSearch = (data: any) => {
    if (data.length > 2) {
      let category = this.state.search.filter((coin: any) =>
        coin.includes(data)
      );
      this.setState({ isLoading: true });
      this.setState({ SearchTitle: category });
      this.setState({ isLoading: false });
    } else {
      this.setState({ SearchTitle: "" });
      this.setState({ isLoading: false });
    }
  };

  // Scroll
  ScrollData: any = (props: any) => {
    const ref: any = React.useRef(null);
    useScrollToTop(ref);
    return props;
  };

  // Customizable Area End
}
