import React, { Component } from "react";
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, Select, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddProductBasicInfoController, { Props } from './AddProductBasicInfoController.web';
import * as Yup from 'yup';

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .block-wrapper-inner .error": {
      color: "red",
    },
    "& .btn-wrapper": {
      textAlign: "center",
    },
  },
};

const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const productBasicInfoSchema = Yup.object().shape({
  serialNumber: Yup.string().required("Serial Number is required"),
  categoryId: Yup.string().required("Product Type is required"),
  subCategoryId: Yup.string().required("Sub Category is required"),
  subSubCategoryId: Yup.string().required("Sub Sub Category is required"),
  sku: Yup.string().required("Seller SKU is required"),
  brandId: Yup.string().required("Brand Name is required"),
  productName: Yup.string().required("Product Name/Title is required"),
  country: Yup.string().required("Country of Origin is required"),
  hsn: Yup.number().required("HSN Code is required"),
  manufacture: Yup.string().required("Name Of Manufacturer / Packer / Importer is required"),
  address: Yup.string().required("Address Of Manufacturer / Packer / Importer is required")
});
interface CategoryType {
  value: number;
  label: string;
}
export class AddProductBasicInfo extends AddProductBasicInfoController {
  myRef: React.RefObject<HTMLDivElement>; // error referrance
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef();
    // Customizable Area End
  }

  genderData = [
    { label: 'Male', value: '1' },
    { label: 'Female', value: '2' }
  ];

  pocketData = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
  ];

  compartmentData = [
    { label: 'YES', value: '1' },
    { label: 'NO', value: '2' }
  ];

  steps = ['Basic Info', 'Product Details', 'Pricing', 'Other Info', 'Policy'];

  handleBack = () => {
    this.gotoProductListScreen();
  };

  menuProps: any = {
    style: { maxHeight: "300px" }, 
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  {/* step bar start */}
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={this.state.activeStep}>
                      {this.steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        if (index < this.state.activeStep) {
                          stepProps.completed = true;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={this.handleNext}>
                          {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                        </Button> */}
                      </Box>
                    </React.Fragment>
                  </Box>
                  {/* step bar end */}

                  <h2>{this.state.isEdit ? "EDIT PRODUCT" : "ADD PRODUCT"}</h2>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <Formik
                      initialValues={this.intialValues}
                      validationSchema={productBasicInfoSchema}
                      onSubmit={(values) => {
                        this.addProductBasicInfoApi(values);
                      }}>
                      {(props) => {
                        const { errors, touched, isValid, dirty } = props;
                        if (Object.keys(errors).length) {
                          this.myRef.current?.scrollIntoView({ behavior: "auto" });
                        }
                        props.values.serialNumber = this.state.serialNumber;
                        props.values.categoryId = this.state.categoryId;
                        props.values.subCategoryId = this.state.subCategoryId;
                        props.values.subSubCategoryId = this.state.subSubCategoryId;
                        props.values.sku = this.state.sku;
                        props.values.brandId = this.state.brandId;
                        props.values.productName = this.state.productName;
                        props.values.country = this.state.country;
                        props.values.hsn = this.state.hsn;
                        props.values.manufacture = this.state.manufacture;
                        props.values.address = this.state.address;

                        return (
                          <form onSubmit={props.handleSubmit}>
                            <h4>Basic Information</h4>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Serial Number<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  autoFocus
                                  readOnly
                                  title="Serial Number field is read only"
                                  type="text"
                                  id="serialNumber"
                                  name="serialNumber"
                                  value={props.values.serialNumber}
                                  error={touched.serialNumber && errors.serialNumber ? true : false}
                                  // onChange={(e) => { this.handleChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.serialNumber && errors.serialNumber ? errors.serialNumber : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Product Type<sup>*</sup>
                              </InputLabel>
                              <FormControl style={{ margin: "1px" }}>
                                <Select
                                  id="categoryId"
                                  name="categoryId"
                                  value={props.values.categoryId}
                                  error={touched.categoryId && errors.categoryId ? true : false}
                                  MenuProps={this.menuProps}
                                  onChange={(e) => this.handleCategoryChange(e)}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.categoryData &&
                                    this.state.categoryData.length ?
                                    this.state.categoryData.map((category: CategoryType) =>
                                      <MenuItem value={category.value}>{category.label}</MenuItem>
                                    )
                                    : 
                                    <MenuItem disabled> No Category Found </MenuItem>
                                  }
                                </Select>
                                <span className="error">{touched.categoryId && errors.categoryId ? errors.categoryId : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Sub Category<sup>*</sup>
                              </InputLabel>
                              <FormControl style={{ margin: "1px" }}>
                                <Select
                                  id="subCategoryId"
                                  name="subCategoryId"
                                  value={props.values && props.values.subCategoryId ? props.values.subCategoryId : ""}
                                  error={touched.subCategoryId && errors.subCategoryId ? true : false}
                                  onChange={(e) => { this.handleSubCategoryChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.subCategoryData &&
                                    this.state.subCategoryData.length ?
                                    this.state.subCategoryData.map((subCategory: CategoryType) =>
                                      <MenuItem value={subCategory.value}>{subCategory.label}</MenuItem>
                                    )
                                    :
                                    <MenuItem disabled> No Sub Category Found </MenuItem>
                                  }

                                </Select>
                                <span className="error">{touched.subCategoryId && errors.subCategoryId ? errors.subCategoryId : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Sub Sub Category<sup>*</sup>
                              </InputLabel>
                              <FormControl style={{ margin: "1px" }}>
                                <Select
                                  id="subSubCategoryId"
                                  name="subSubCategoryId"
                                  value={props.values && props.values.subSubCategoryId ? props.values.subSubCategoryId : ""}
                                  error={touched.subSubCategoryId && errors.subSubCategoryId ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.subSubCategoryData &&
                                    this.state.subSubCategoryData.length ?
                                    this.state.subSubCategoryData.map((subSubCategory: CategoryType) =>
                                      <MenuItem value={subSubCategory.value}>{subSubCategory.label}</MenuItem>
                                    )
                                    :
                                    <MenuItem disabled> No Sub Sub Category Found </MenuItem>
                                  }

                                </Select>
                                <span className="error">{touched.subSubCategoryId && errors.subSubCategoryId ? errors.subSubCategoryId : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Seller SKU<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  id="sku"
                                  name="sku"
                                  value={props.values.sku}
                                  error={touched.sku && errors.sku ? true : false}
                                  onChange={(e) => { this.handleSellerSkuIdChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.sku && errors.sku ? errors.sku : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Brand Name<sup>*</sup>
                              </InputLabel>
                              <FormControl style={{ margin: "1px" }}>
                                <Select
                                  id="brandId"
                                  name="brandId"
                                  value={props.values.brandId}
                                  error={touched.brandId && errors.brandId ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.brandListData &&
                                    this.state.brandListData.length &&
                                    this.state.brandListData.map((brand: CategoryType) =>
                                      <MenuItem value={brand.value}>{brand.label}</MenuItem>
                                    )
                                  }

                                </Select>
                                <span className="error">{touched.brandId && errors.brandId ? errors.brandId : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Product Name/Title<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  id="productName"
                                  name="productName"
                                  value={props.values.productName}
                                  error={touched.productName && errors.productName ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.productName && errors.productName ? errors.productName : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Country of Origin<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Select
                                  id="country"
                                  name="country"
                                  value={props.values.country}
                                  error={touched.country && errors.country ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    countryList &&
                                    countryList.length &&
                                    countryList.map((country: any) =>
                                      <MenuItem value={country}>{country}</MenuItem>
                                    )
                                  }
                                </Select>
                                <span className="error">{touched.country && errors.country ? errors.country : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                HSN Code<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  id="hsn"
                                  name="hsn"
                                  value={props.values.hsn}
                                  error={touched.hsn && errors.hsn ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.hsn && errors.hsn ? errors.hsn : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Name Of Manufacturer / Packer / Importer<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  id="manufacture"
                                  name="manufacture"
                                  value={props.values.manufacture}
                                  error={touched.manufacture && errors.manufacture ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.manufacture && errors.manufacture ? errors.manufacture : ''}</span>
                              </FormControl>
                            </div>
                            <div className="form-group">
                              <InputLabel htmlFor="my-input">
                                Address Of Manufacturer / Packer / Importer<sup>*</sup>
                              </InputLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  id="address"
                                  name="address"
                                  value={props.values.address}
                                  error={touched.address && errors.address ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  aria-describedby="my-helper-text"
                                />
                                <span className="error">{touched.address && errors.address ? errors.address : ''}</span>
                              </FormControl>
                            </div>
                            <div className="btn-wrapper">
                              <Button
                                type="submit"
                                variant="contained"
                                className="black-btn"
                              >
                                Continue
                              </Button>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(AddProductBasicInfo);
