import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { VendorAddAddressModel } from "../../../framework/src/Interfaces/IAddress";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    classes: any;
    mode: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    token: string | null;
    data: any;
    showNewAddressDialog: boolean;
    // Customizable Area Start
    mode: string
    addressData: Object[];
    editAddress: Object;
    state: string;
    city: string;
    mobile: string;
    email: string;
    town: string;
    id: number | null;
    name: string;
    address: string;
    pincode: string;
    save_add: string;
    saveAddress: string;
    // addressID: number | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddressController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiPhoneLoginCallId: any;
    addressCallId: any = null;
    getaddressCallId: any = null;
    DelAddressCallId: any = null;
    addNewAddressCallId: any = null;
    EditAddressCallId: any = null;
    EidthAddressCallId: any = null;
    DefualtAddressCallId: any = null;
    // Customizable Area End

    public intialValues: VendorAddAddressModel = {
        name: '',
        mobile: '',
        email: '',
        address: '',
        town: '',
        city: '',
        state: '',
        pincode: ''
    };

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // const result = msg.getData('id');

        // const result = msg.getData('id');

        console.log("props.id::::", props);
        this.state = {
            txtSavedValue: 'A',
            token: '',
            data: [],
            showNewAddressDialog: false,
            // Customizable Area Start
            mode: '',
            addressData: [],
            editAddress: {},
            state: '',
            city: '',
            mobile: '',
            email: '',
            town: '',
            id: this.props.id || null,
            name: '',
            address: '',
            pincode: '',
            save_add: 'home',
            saveAddress: '',
            //addressID: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        const msg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        const data = msg.getData(
            getName(MessageEnum.VendorAddressDataMessage)
        );
        console.log("constructor data::", data);
        // this.send(msg);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // token
        console.log('----->> token');
        try {
            let Token = await localStorage.getItem('authToken');
            this.setState({ token: Token }, () => this.addressCall());

            // this.intialValues = {
            //     name: this.state.name,
            //     email: this.state.email,
            //     mobile: this.state.mobile,
            //     address: this.state.address,
            //     town: this.state.town,
            //     city: this.state.city,
            //     state: this.state.state,
            //     pincode: this.state.pincode
            // };
        } catch (e) {
            // error reading value
        }
    }

    // temporary
    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);
        runEngine.debugLog("this.addressCallId:::", this.addressCallId);


        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            console.log("navigation payload:::")
            const data = message.getData(
                getName(MessageEnum.VendorAddressDataMessage)
            );
            if (data) {
                console.log("data:::", data);
                const { mode, id, isFromEdit, name,
                    mobile,
                    address,
                    town,
                    zip_code,
                    email,
                    city,
                    state,
                    save_add
                } = data;
                this.setState({
                    mode: mode,
                    id: id,
                    name: name,
                    email: email,
                    mobile: mobile,
                    address: address,
                    town: town,
                    city: city,
                    state: state,
                    pincode: zip_code,
                    save_add: save_add
                }, () => console.log("payload mode::", this.state.mode));
                this.componentDidMount();
            }

        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addressCallId !== null) {
            // SHOW ADDRESS
            this.addressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const response: any[] = responseJson.data;
            if (response && response.length) {
                response.sort((a, b) => (Number(a.id) > Number(b.id)) ? -1 : 1);
                this.setState({ addressData: responseJson.data });
            }
            console.log('-------------->', this.state.addressData);


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getaddressCallId !== null) {

            this.getaddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            console.log('-------------->', this.state.addressData);


            this.setState({ editAddress: responseJson.data });
            // this.setState({
            //     showNewAddressDialog: true
            // }); cutomer address

            const edit_address: any = this.state.editAddress;
            this.setState({ mode: 'EDIT' });
            console.log("edit response::::::", responseJson.data);
            this.setState({
                id: edit_address.id,
                name: edit_address.attributes.name,
                email: edit_address.attributes.email,
                mobile: edit_address.attributes.phone_number,
                address: edit_address.attributes.address,
                town: edit_address.attributes.town,
                city: edit_address.attributes.city,
                state: edit_address.attributes.state,
                pincode: edit_address.attributes.zip_code,
                save_add: edit_address.attributes.save_address_as // cutomer address
            }, () => console.log("state value::::::", this.state.save_add));

            this.gotoAddAddressScreen();

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addNewAddressCallId !== null) {


            this.addNewAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('-------------->', this.state.data);


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data) {
                toast.success('Address Created Successfully', { position: "top-right", autoClose: 5000 })
                this.gotoAddressListScreen();
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.EditAddressCallId !== null) {


            this.EditAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('-------------->', this.state.data);


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.data) {
                toast.success('Address Updated Successfully', { position: "top-right", autoClose: 5000 })
                this.gotoAddressListScreen();
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DelAddressCallId !== null) {


            this.DelAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('-------------->', this.state.data);

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.message) {
                toast.success('Address Deleted Successfully', { position: "top-right", autoClose: 5000 })
                this.addressCall();
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DefualtAddressCallId !== null) {

            this.DefualtAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('Default api response-------------->', responseJson);

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data) {
                toast.success('Default Address Change Successfully', { position: "top-right", autoClose: 5000 })
                this.addressCall();
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // Customizable Area End

    }

    // async receive(from: string, message: Message) {
    //     console.log('api call ------------------>>>')
    //     console.log('message ------------------>>>',message)  
    //     console.log('message ------------------>>>',message.id)    

    //     if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
    //         console.log("navigation payload:::")
    //         const data = message.getData(
    //           getName(MessageEnum.VendorAddressDataMessage)
    //         );
    //         if (data) {
    //             console.log("data:::",data);
    //             const {mode, id , isFromEdit,name,
    //                 mobile,
    //                 address,
    //                 town,
    //                 zip_code,
    //                 email,
    //                 city,
    //                 state,
    //                 save_add
    //              } = data;
    //             this.setState({
    //                 mode: mode,
    //                 id: id,
    //                 name: name,
    //                 email: email,
    //                 mobile: mobile,
    //                 address: address,
    //                 town: town,
    //                 city: city,
    //                 state: state,
    //                 pincode: zip_code,
    //                 save_add: save_add                    
    //             } , () => console.log("payload mode::",this.state.mode));
    //             this.componentDidMount();
    //         }

    //       }
    //      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //       const apiRequestCallId = message.getData(
    //         getName(MessageEnum.RestAPIResponceDataMessage)
    //       );

    //       var responseJson = message.getData(
    //         getName(MessageEnum.RestAPIResponceSuccessMessage)
    //       );

    //       var errorReponse = message.getData(
    //         getName(MessageEnum.RestAPIResponceErrorMessage)
    //       );

    //       console.log('response>',responseJson)
    //       console.log('error response>',errorReponse);

    //       if(responseJson && responseJson.errors && responseJson.errors.length && responseJson.errors[0].token) {
    //         this.goToLoginScreen();
    //       }
    //       if (apiRequestCallId && responseJson) {
    //         if (apiRequestCallId === this.addressCallId) {
    //             const response: any[] = responseJson.data;
    //             if(response && response.length){
    //                 response.sort((a,b) => (Number(a.id) > Number(b.id)) ? -1 : 1);
    //                 this.setState({ addressData: responseJson.data }); 
    //             }                 
    //         }
    //         if (apiRequestCallId === this.getaddressCallId) {
    //             this.setState({ editAddress: responseJson.data }); 
    //             // this.setState({
    //             //     showNewAddressDialog: true
    //             // }); cutomer address

    //             const edit_address:any = this.state.editAddress;
    //             this.setState({ mode: 'EDIT' });
    //             console.log("edit response::::::",responseJson.data);
    //             this.setState({
    //                 id: edit_address.id,
    //                 name: edit_address.attributes.name,
    //                 email: edit_address.attributes.email,
    //                 mobile: edit_address.attributes.phone_number,
    //                 address: edit_address.attributes.address,
    //                 town: edit_address.attributes.town,
    //                 city: edit_address.attributes.city,
    //                 state: edit_address.attributes.state,
    //                 pincode: edit_address.attributes.zip_code,
    //                 save_add: edit_address.attributes.save_address_as // cutomer address
    //             },() => console.log("state value::::::",this.state.save_add));

    //             // this.intialValues = {
    //             //     name: edit_address.attributes.name,
    //             //     email: edit_address.attributes.email,
    //             //     mobile: edit_address.attributes.phone_number,
    //             //     address: edit_address.attributes.address,
    //             //     town: edit_address.attributes.town,
    //             //     city: edit_address.attributes.city,
    //             //     state: edit_address.attributes.state,
    //             //     pincode: edit_address.attributes.zip_code,
    //             // }

    //             // console.log("initialvalues:::",this.intialValues);
    //             this.gotoAddAddressScreen();
    //         }
    //         if (apiRequestCallId === this.addNewAddressCallId) {
    //             this.gotoAddressListScreen();
    //             // this.setState({ showNewAddressDialog: false }); customer address model
    //         }
    //         if (apiRequestCallId === this.EditAddressCallId) {
    //             this.gotoAddressListScreen();
    //             // this.setState({ showNewAddressDialog: false }); customer address model
    //         }
    //         if (apiRequestCallId === this.DelAddressCallId) {
    //             console.log("delete response:::",responseJson);
    //             this.addressCall();              
    //         }
    //         if (apiRequestCallId === this.DefualtAddressCallId) {
    //             this.addressCall();
    //         }
    //       }
    //      }
    // }

    gotoAddAddressScreen = () => {
        console.log("go to add address screen:::");
        const msg: Message = new Message(
            getName(MessageEnum.NavigationVendorAddAddressMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.VendorAddressDataMessage), {
            mode: this.state.mode,
            isFromEdit: true,
            id: this.state.id,
            name: this.state.name,
            mobile: this.state.mobile,
            address: this.state.address,
            town: this.state.town,
            zip_code: this.state.pincode,
            email: this.state.email,
            city: this.state.city,
            state: this.state.state,
            save_add: this.state.save_add
        });
        this.send(msg);
    }

    gotoAddressListScreen = () => {
        console.log("go to address list screen:::");
        const msg: Message = new Message(
            getName(MessageEnum.NavigationVendorAddressListMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToLoginScreen = () => {
        console.log("go to login screen:::");
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    addressCall() {
        console.log('---------------->>>>>>> address');

        const header = {
            "Content-Type": "application/json",
            token: this.state.token
            // token: ''
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addressCallId = apiRequest.messageId;
        console.log('---------------->>>>>>> this.addressCallId', this.addressCallId);
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addressAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addressApiMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getAddressByIDCall(addressID: number) {
        console.log('---------------->>>>>>> address');

        const header = {
            "Content-Type": "application/json",
            token: this.state.token
            // token: ''
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getaddressCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addressAPiEndPoint + `/${addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addressApiMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async addNewAddressCall(values: VendorAddAddressModel) {

        const { name, mobile, email, address, town, pincode, city, state } = values;

        const header = {
            "Content-Type": configJSON.addAddressAPiContentType,
            token: this.state.token
        };

        const httpBody = {
            name: name,
            phone_number: mobile,
            email: email,
            address: address,
            town: town,
            zip_code: pincode,
            city: city,
            state: state,
            country: 'India',
            save_address_as: this.state.save_add.toLowerCase(), //for customer address            
        };

        console.log('============>>>>', httpBody);

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addNewAddressCallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addressAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addAddressAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async editAddress(event: any, addressID: number) {
        const result = await this.getAddressByIDCall(addressID);
    }

    async editAddressCall(addressID: number | null) {
        console.log("edit name:::", this.state.name);
        const header = {
            "Content-Type": configJSON.addAddressAPiContentType,
            token: this.state.token
        };

        const httpBody = {
            name: this.state.name,
            phone_number: this.state.mobile,
            address: this.state.address,
            town: this.state.town,
            zip_code: this.state.pincode,
            email: this.state.email,
            save_address_as: this.state.save_add.toLowerCase(),
            city: this.state.city,
            state: this.state.state,
        };

        console.log('============>>>>', httpBody);

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.EditAddressCallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.EditaddressAPiEndPoint + `/${addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.EdithAddressAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    // deleteAddressCall(event: any , addressID:number) {
    //     const header = {
    //         "Content-Type": configJSON.addAddressAPiContentType,
    //         token: this.state.token
    //     };

    //     const apiRequest = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.DelAddressCallId = apiRequest.messageId;


    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.DelAddressAPiEndPoint + `/${addressID}`
    //     );

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.addressAPiMethodType);

    //     runEngine.sendMessage(apiRequest.id, apiRequest);

    //     return true;
    // }

    deleteAddressCall(event: any, addressID: number) {



        const header = {
            "Content-Type": configJSON.DelAddressAPiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DelAddressCallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.DelAddressAPiEndPoint + `/${addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DelAddressAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    makeDefaultAddressCall(event: any, addressID: number) {
        const header = {
            "Content-Type": configJSON.addAddressAPiContentType,
            token: this.state.token
        };

        const httpBody = {
            is_default: true
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DefualtAddressCallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.DefualtAddressAPiEndPoint + `/${addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DefualtAddressAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    handleCloseNewAddressDialog = () => {
        this.setState({
            showNewAddressDialog: false
        });
    };
}