Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.title1 = "Excusive Collection";
exports.title2 = "Best Collection";
exports.title3 = "Pick Your Favorite";
exports.title4 = "Sell products";

exports.subtitle1 = "Caelum app is now easier to use and buy products. Go enjoy your shopping.";
exports.subtitle2 = "Caelum app is now easier to use and buy products. Go enjoy your shopping.";
exports.subtitle3 = "Caelum app is now easier to use and buy products. Go enjoy your shopping.";
exports.subtitle4 = "Caelum app is now easier to use and buy products. Go enjoy your shopping.";
// Customizable Area End