import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getTimeZone } from "react-native-localize";
import AsyncStorage from '@react-native-async-storage/async-storage';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: any;
    // Customizable Area Start
    
    classes:any;
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    id:any;

 
    // Productname: string | null;
    banner: any;
    shopbyedit: any;
    brand: any;
    brandData: any;
    token: string | null;
    SubCategories: any;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
   
    // Customizable Area Start
    // Customizable Area End
}

export default class SubCategoriesController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiPhoneLoginCallId: any;
    ShowBannerCallId: any;
    MainBannerCallId: any;
    SubCategoryCallId: any;
    ShopByEidtCallId: any;
    FeatuerdByBrandCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            // idnav: this.props.navigation.state.params?.idnav,
            id:localStorage.getItem("pid"),
            // Productname: this.props.navigation.state.params?.name,
            SubCategories: [],
            banner: [],
            shopbyedit: [],
            brandData: [],
            brand: [],
            token: '',
            loading: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }




        // Customizable Area Start

        // SHOW Categories 

        // if (getName(MessageEnum.RestAPIResponceMessage) === message.id
        //     && this.ShowBannerCallId !== null
        //     && this.ShowBannerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

        //     this.ShowBannerCallId = null;

        //     var responseJson = message.getData(
        //         getName(MessageEnum.RestAPIResponceSuccessMessage)
        //     );
        //     this.setState({ banner: responseJson });

        //     console.log('@@@@@@@@@@@@@@@@@@@@', this.state.banner);


        //     var errorReponse = message.getData(
        //         getName(MessageEnum.RestAPIResponceErrorMessage)
        //     );
        //     if (responseJson && responseJson.meta && responseJson.meta.token) {
        //         runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //         //Need To send Login token message to save for future call
        //     } else {
        //         //Check Error Response
        //         this.parseApiErrorResponse(responseJson);
        //     }
        //     this.parseApiCatchErrorResponse(errorReponse);
        // }



        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.SubCategoryCallId !== null &&
            this.SubCategoryCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.SubCategoryCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ SubCategories: responseJson.data });
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END SHOW Categories


        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.MainBannerCallId !== null &&
            this.MainBannerCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.MainBannerCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ banner: responseJson.data });
            this.setState({ loading: false });
            console.log('@@@@@@@@@@@@@@@@@@@@ banner------------------------------------------------------', this.state.banner);
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }





        // SHOW Categories 

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id
            && this.FeatuerdByBrandCallId !== null
            && this.FeatuerdByBrandCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {


            this.FeatuerdByBrandCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ brandData: responseJson.data });
            this.setState({ loading: false });
            console.log('@@@@@@@@@@@@@@@@@@@@ FeatuerdByBrandCallId', this.state.brandData);


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }

        // END SHOW Categories

        // SHOW Categories 

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id
            && this.ShopByEidtCallId !== null
            && this.ShopByEidtCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {


            this.ShopByEidtCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ shopbyedit: responseJson.data });
            console.log(this.state.shopbyedit)
            this.setState({ loading: false });
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END SHOW Categories

        // Customizable Area End      


    }

    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnExampleProps = {
        onPress: () => this.doButtonPressed()
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // Customizable Area Start

    async componentDidMount() {
        // token
        console.log('----->> token');
        try {
            const  localToken = await localStorage.getItem("authToken")

       
          this.setState({ token: localToken });
            this.MainBanner();
            this.SubCategory();
            this.ShopByEidt();
            this.FeatuerdByBrand();
        } catch (e) {
            // error reading value
        }
    }


    MainBanner() {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.MainBannerCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiMainBannerEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    SubCategory() {
        console.log("---------------->>>>>>>", this.state.id);
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.SubCategoryCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apisubCategoryEndPoint + `?category_id=${this.state.id}`
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    ShopByEidt() {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ShopByEidtCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ShopByEidtAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    FeatuerdByBrand() {
        this.setState({ loading: true });
       

        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FeatuerdByBrandCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.FeatuerdByBrandAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    // Customizable Area End

}
