import React from "react";

import {
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    View,
    TextInput,
    ScrollView,
    TouchableWithoutFeedback,
    Platform,
    // Customizable Area Start
    FlatList,
    ListRenderItem
    // Customizable Area End
} from "react-native";

import { Checkbox, Box, withStyles, Container, AppBar, Tabs, Tab, Modal, Select, MenuItem, Typography, Button, Dialog } from "@material-ui/core";
import scale, { verticalScale } from "../../../components/src/Scale";
import { COLORS } from "../../../framework/src/Globals"
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VendorHeaderScreen from "../../../components/src/VendorHeader";
import moment from 'moment';
import * as IMG_CONST from "./assets";
import clsx from "clsx";
import * as _ from "lodash";
import { FiberManualRecord } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import OrderSummaryController, {
    Props,
    configJSON
} from "./OrderSummaryController";

interface OrderItem {
    item: any;
    index: any;
}
class OrderSummary extends OrderSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    classes: any = this.props;
    renderReceivedFlatList = () => {
        const { classes } = this.props;
        return (
            <Box className={classes.listBox}>
                {
                    this.state.orderList.length ?
                        <FlatList
                            data={this.state.orderList}
                            renderItem={({ item }) => this.renderReceivedTabs(item)}
                            extraData={this.state}
                        /> :
                        <Typography style={{ paddingBottom: '20px', textAlign: 'center' }} variant="body1">
                            No Record Found
                        </Typography>
                }
            </Box>
        )
    }

    renderUnshippedTabs = () => {
        return (
            <Box className={this.classes.classes.listBox}>
                {
                    this.state.unshippedFlatList.length ?
                        <FlatList
                            data={this.state.unshippedFlatList}
                            renderItem={({ item, index }) => this.renderItem(item, index)}
                        /> :
                        <Typography style={{ paddingBottom: '20px', textAlign: 'center' }} variant="body1">
                            No Record Found
                        </Typography>
                }
            </Box >
        )
    }

    renderItem = (item: any, index: any) => {
        const date = item.attributes.updated_at
        let updatedDate = moment(date).format('DD/MM/YYYY');
        let quantity = item.attributes.order_items?.[0].quantity
        console.log('@@@item', item)
        let items = [];
        if (item.attributes && item.attributes.catalogue_details) {
            items = item.attributes.catalogue_details.map((row: any) => {
                let image = row?.attributes?.galleries?.[0].url
                return (
                    <Box display="flex" mt={2}>
                        <Box flex="1">
                            <img className={this.classes.classes.orderImg} alt="test" src={`${image}`} />
                        </Box>
                        <Box flex="9">
                            <Typography variant="subtitle2">{row.attributes.name}</Typography>
                            <Typography variant="caption">{row.attributes.description}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">QTY: {quantity}</Typography>
                                <FiberManualRecord className={this.classes.classes.separator} />
                                <Typography variant="body1">₹{item?.attributes?.sub_total}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        }

        return (
            <Box className={this.classes.classes.orderBox}>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Checkbox
                            onChange={(e: any) => this.handleOrderChkbox(e)}
                            classes={{ checked: this.classes.classes.checked }}
                        />
                        <Typography variant="body1">Order ID: {item.attributes.order_number}</Typography>
                    </Box>
                    <Typography variant="body1">Confirmed on: {updatedDate}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Box>
                        {items}
                        <Box mt={2} style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '4px', backgroundColor: '#e9faf4' }}>
                            <Typography style={{ textTransform: 'capitalize', color: '#70b7a6' }}>{item.attributes.status}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderReceivedTabs = (item: any) => {
        const { classes } = this.props;
        const date = item.attributes.updated_at
        let updatedDate = moment(date).format('DD/MM/YYYY');
        let quantity = item.attributes?.order_items[0]?.quantity;
        let items = [];
        if (item.attributes && item.attributes.catalogue_details) {
            items = item.attributes.catalogue_details.map((row: any) => {
                let image = row?.attributes?.galleries?.[0].url
                return (
                    <Box display="flex" mt={2}>
                        <Box flex="1">
                            <img className={classes.orderImg} alt="test" src={`${image}`} />
                        </Box>
                        <Box flex="9">
                            <Typography variant="subtitle2">{row.attributes.name}</Typography>
                            <Typography variant="caption">{row.attributes.description}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">QTY: {quantity}</Typography>
                                <FiberManualRecord className={classes.separator} />
                                <Typography variant="body1">₹{item?.attributes?.sub_total}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        }
        return (
            <Box className={classes.orderBox}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">Order ID: {item.attributes.order_number}</Typography>
                    <Typography variant="body1">{updatedDate}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Box>
                        {items}
                    </Box>
                    <Box>
                        <Button
                            type="submit"
                            variant="outlined"
                            className={clsx([classes.orderBtn], classes.orderBtn_White)}
                            onClick={() => this.props.navigation.navigate('CreateChart')}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            className={clsx([classes.orderBtn], classes.orderBtn_Black)}
                            onClick={() => this.props.navigation.navigate('CreateChart')}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderReadyToShipTabs = () => {
        return (
            <Box className={this.classes.classes.listBox}>
                {this.state.readyToShipFlatList?.length ?
                    <FlatList
                        data={this.state.readyToShipFlatList}
                        renderItem={({ item, index }) => this.readyToShipItem(item, index)}
                    /> :
                    <Typography style={{ paddingBottom: '20px', textAlign: 'center' }} variant="body1">
                        No Record Found
                    </Typography>
                }
            </Box>
        )
    }

    readyToShipItem = (item: any, index: any) => {
        const date = item.attributes.updated_at
        let updatedDate = moment(date).format('DD/MM/YYYY');
        let quantity = item.attributes.order_items?.[0].quantity
        let items = [];
        if (item.attributes && item.attributes.catalogue_details) {
            items = item.attributes.catalogue_details.map((row: any) => {
                let image = row?.attributes?.galleries?.[0].url
                return (
                    <View style={styles.qytView}>
                        <View style={styles.imageView}>
                            <Image source={{
                                uri: `${image}`,
                            }} style={styles.orderImageView} />
                        </View>
                        <View style={styles.brandMainView}>
                            <View style={styles.brandTextView}>
                                <Text style={styles.brandText} >{row.attributes.name}</Text>
                                <Text style={styles.descripText}>{" "}{row.attributes.description}</Text>
                            </View>
                            <View style={styles.brandPriceView}>
                                <Text style={styles.quantityyText}>QTY:{quantity}</Text>
                                <Text> {" "} </Text>
                                <View style={styles.pointText}></View>
                                <Text style={styles.priceText}>   ₹{item?.attributes?.sub_total}</Text>
                            </View>
                        </View>
                    </View>

                )
            })
        }
        return (
            <View>
                <View style={styles.unshippedList} >
                    <TouchableOpacity activeOpacity={0.5} onPress={() => this.handleReadyToShipSelection(item)}>
                        <View style={styles.orderIdContainer}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Image style={styles.checkBox} source={item.isSelected ? IMG_CONST.checkBox : IMG_CONST.uncheckBox} />
                                <Text style={styles.orderIdText}>Order ID: {item.attributes.order_number}</Text>
                            </View>
                            <Text style={styles.confirmDateText}>Scheduled on: {updatedDate}</Text>
                        </View>
                        {items}
                        <View style={styles.confirmlView}>
                            <View style={styles.scheduledPickupButoon}>
                                <Text style={styles.scheduledPickUpText}>Scheduled Pickup</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderSentTabs = () => {
        return (
            <Box className={this.classes.classes.listBox}>
                {
                    this.state.sentFlatList.length ?
                        <FlatList
                            data={this.state.sentFlatList}
                            renderItem={({ item, index }) => this.sentItem(item, index)}
                        /> :
                        <Typography style={{ paddingBottom: '20px', textAlign: 'center' }} variant="body1">
                            No Record Found
                        </Typography>
                }
            </Box>
        )
    }

    sentItem = (item: any, index: any) => {
        const date = item.attributes.updated_at
        let updatedDate = moment(date).format('DD/MM/YYYY');
        let quantity = item.attributes.order_items?.[0].quantity
        let items = [];
        if (item.attributes && item.attributes.catalogue_details) {
            items = item.attributes.catalogue_details.map((row: any) => {
                let image = row?.attributes?.galleries?.[0].url
                return (
                    <Box display="flex" mt={2}>
                        <Box flex="1">
                            <img className={this.classes.classes.orderImg} alt="test" src={`${image}`} />
                        </Box>
                        <Box flex="9">
                            <Typography variant="subtitle2">{row.attributes.name}</Typography>
                            <Typography variant="caption">{row.attributes.description}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">QTY: {quantity}</Typography>
                                <FiberManualRecord className={this.classes.classes.separator} />
                                <Typography variant="body1">₹{item?.attributes?.sub_total}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        }
        return (
            <Box className={this.classes.classes.orderBox}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">Order ID: {item.attributes.order_number}</Typography>
                    <Typography variant="body1">{item.attributes.status === 'shipped' ? 'EDD' : item.attributes.status === 'created' ? 'Delivered on' : item.attributes.status === 'rto' ? 'Return on' : item.attributes.status === 'exchange' ? 'EDD' : ''}: {updatedDate}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Box>
                        {items}
                        <Box mt={2} style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '4px', backgroundColor: item.attributes.status === 'shipped' ? '#E8E8E8' : item.attributes.status === 'created' ? '#FFF3EB' : item.attributes.status === 'rto' ? '#EEF1F6' : item.attributes.status === 'exchange' ? '#FBE9FF' : '' }}>
                            <Typography style={{ textTransform: 'capitalize', color: item.attributes.status === 'shipped' ? '#6A6A6A' : item.attributes.status === 'created' ? '#DAA377' : item.attributes.status === 'rto' ? '#5B7995' : item.attributes.status === 'exchange' ? '#BB2FE1' : '' }}>{item.attributes.status}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    toggleTabsButtons = (index: any) => {
        if (index === 0) {
            this.getOnRecievedList();
            this.setState({ received: true, unshipped: false, readyToShip: false, send: false, cancelled: false, startDate: '', endDate: '', selectedId: '', readyToShipSelectedId: '' }, () => this.renderReceivedFlatList());
        } else if (index === 1) {
            this.getOnUnshippedList()
            this.setState({ received: false, unshipped: true, readyToShip: false, send: false, cancelled: false, startDate: '', endDate: '', selectedId: '', readyToShipSelectedId: '' }, () => this.renderUnshippedTabs());
        } else if (index === 2) {
            this.getOnReadyToShipList()
            this.setState({ received: false, unshipped: false, readyToShip: true, send: false, cancelled: false, startDate: '', endDate: '', selectedId: '', readyToShipSelectedId: '' }, () => this.renderReadyToShipTabs());
        } else if (index === 3) {
            this.getOnSendList()
            this.setState({ received: false, unshipped: false, readyToShip: false, send: true, cancelled: false, startDate: '', endDate: '', selectedId: '', readyToShipSelectedId: '' }, () => this.renderSentTabs());
        } else {
            this.getOnCancelledList();
            this.setState({ received: false, unshipped: false, readyToShip: false, send: false, cancelled: true, startDate: '', endDate: '', selectedId: '', readyToShipSelectedId: '' }, () => this.renderCancelledTabs());
        }
    }

    renderCancelledTabs = () => {
        return (
            <Box className={this.classes.classes.listBox}>
                {
                    this.state.cancelledFlatList.length ?
                        <FlatList
                            data={this.state.cancelledFlatList}
                            renderItem={({ item, index }) => this.cancelledItem(item, index)}
                        /> :
                        <Typography style={{ paddingBottom: '20px', textAlign: 'center' }} variant="body1">
                            No Record Found
                        </Typography>
                }
            </Box>
        )
    }

    cancelledItem = (item: any, index: any) => {
        const date = item.attributes.updated_at
        let updatedDate = moment(date).format('DD/MM/YYYY');
        let quantity = item.attributes.order_items?.[0].quantity
        let items = [];
        if (item.attributes && item.attributes.catalogue_details) {
            items = item.attributes.catalogue_details.map((row: any) => {
                let image = row?.attributes?.galleries?.[0].url
                return (
                    <Box display="flex" mt={2}>
                        <Box flex="1">
                            <img className={this.classes.classes.orderImg} alt="test" src={`${image}`} />
                        </Box>
                        <Box flex="9">
                            <Typography variant="subtitle2">{row.attributes.name}</Typography>
                            <Typography variant="caption">{row.attributes.description}</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">QTY: {quantity}</Typography>
                                <FiberManualRecord className={this.classes.classes.separator} />
                                <Typography variant="body1">₹{item?.attributes?.sub_total}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        }

        return (
            <Box onClick={() => this.setState({ openCancelledOrderModal: true, orderToOpen: item }, () => console.log('sahib', this.state.orderToOpen))} className={this.classes.classes.orderBox}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">Order ID: {item.attributes.order_number}</Typography>
                    <Typography variant="body1">Cancelled on: {updatedDate}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Box>
                        {items}
                        <Box mt={2} style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '4px', backgroundColor: item.attributes.status === 'shipped' ? '#E8E8E8' : item.attributes.status === 'created' ? '#FFF3EB' : item.attributes.status === 'rto' ? '#EEF1F6' : item.attributes.status === 'exchange' ? '#FBE9FF' : item.attributes.status === 'cancelled' ? '#FEEDEE' : '' }}>
                            <Typography style={{ textTransform: 'capitalize', color: item.attributes.status === 'shipped' ? '#6A6A6A' : item.attributes.status === 'created' ? '#DAA377' : item.attributes.status === 'rto' ? '#5B7995' : item.attributes.status === 'exchange' ? '#BB2FE1' : item.attributes.status === 'cancelled' ? '#E65A66' : '' }}>{item.attributes.status}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    handleChange = (event: any, newValue: any) => {
        this.setState({ tab: newValue }, () => {
            this.toggleTabsButtons(this.state.tab);
        });
    };

    a11yProps = (index: number) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    renderHeader = () => {
        console.log("this.state.isMenuShow", this.state.isMenuShow)
        return (
            <VendorHeaderScreen
                isMenuOpen={this.state.unshipped || this.state.readyToShip ? true : false}
                isOpenFilterModal={() => this.isOpenModal()}
                isOpenMenuModal={() => this.state.isMenuShow ? this.isOpenMenuModal() : null}
            />
        )
    }

    renderMenuModal = () => {
        return (
            <Modal open={this.state.showMenuModal}>
                <TouchableWithoutFeedback onPress={() => this.setState({ showMenuModal: false, isMenuShow: false })}>
                    <View style={styles.modalMenuMainView}>
                        {this.state.unshipped &&
                            <View style={styles.modalMenuContainer}>
                                <TouchableOpacity onPress={() => this.setState({ showMenuModal: false }, () => console.log('download file'))}>
                                    <Text style={styles.printInvoiceText}  >Print Invoice</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ showMenuModal: false, showCancelUnshippedOrder: true, })}>
                                    <Text style={styles.printInvoiceText}>Cancel Order</Text>
                                </TouchableOpacity>
                            </View>
                        }

                        {this.state.readyToShip &&
                            <View style={styles.modalMenuContainer}>
                                <TouchableOpacity onPress={() => this.onConfirmShipment()}>
                                    <Text style={styles.printInvoiceText}>Confirm Shipment</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ showMenuModal: false }, () => console.log('download file'))}>
                                    <Text style={styles.printInvoiceText}>Print Invoice</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ showMenuModal: false }, () => console.log('download file'))}>
                                    <Text style={styles.printInvoiceText}>Print Shipping Label</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ showMenuModal: false }, () => console.log('download file'))}>
                                    <Text style={styles.printInvoiceText}>Download Menifest</Text>
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        )
    }

    renderFilter = () => {
        let filters: object[] = [];
        this.state.filterData.map((filter: { id: number, title: string, isSelected: boolean, value: string }) => {
            filters.push(<MenuItem value={filter.id}>{filter.title}</MenuItem>);
        })
        return filters;
    }

    handleOrderChkbox = (e: any) => {
        if (e.target.checked) {
            this.setState((state, props) => ({
                selectedOrders: state.selectedOrders + 1
            }));
        } else {
            this.setState((state, props) => ({
                selectedOrders: state.selectedOrders - 1
            }));
        }
    }

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
                {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                <Box className={classes.pageOuter}>
                    <Container maxWidth="md">
                        <AppBar classes={{ positionStatic: classes.positionStatic }} position="static" color="transparent">
                            <Tabs
                                classes={{
                                    flexContainer: classes.flexContainer
                                }}
                                value={this.state.tab}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                centered
                                aria-label="scrollable auto tabs example"
                                TabIndicatorProps={{ style: { height: '3px', background: "#000000" } }}
                            >
                                <Tab label="Received" {...this.a11yProps(0)} />
                                <Tab label="UNSHIPPED" {...this.a11yProps(1)} />
                                <Tab label="READY TO SHIP" {...this.a11yProps(2)} />
                                <Tab label="SENT" {...this.a11yProps(3)} />
                                <Tab label="CANCELLED" {...this.a11yProps(4)} />
                            </Tabs>
                        </AppBar>
                        <Box mt={2} className={classes.header} display={"flex"} justifyContent="space-between">
                            <Box p={1} className={classes.srchOrdrInputBox}>
                                <input className={classes.srchOrdrInputFld} placeholder="Search by order, product, order id" id="searchOrder" />
                            </Box>
                            <Box display="flex">
                                <Box display={this.state.unshipped ? 'flex' : 'none'}>
                                    <Button
                                        disabled={this.state.selectedOrders == 1 ? false : true}
                                        type="submit"
                                        variant="contained"
                                        className={clsx([classes.orderBtn, classes.scheduleBtn, classes.orderBtn_Black])}
                                        onClick={() => this.props.navigation.navigate('CreateChart')}
                                    >
                                        Schedule Pickup
                                    </Button>
                                </Box>
                                <Box className={classes.filter} display="flex" alignItems="center">
                                    <Typography variant="body1" className="select-label">Filters: &nbsp; </Typography>
                                    <Select
                                        labelId="product-category"
                                        value={this.state.selectedFilter}
                                        id="demo-simple-select"
                                        className="selection"
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        disableUnderline
                                        onChange={(e) => console.log(e.target.value)}
                                    >
                                        {this.renderFilter()}
                                    </Select>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {this.state.received ? this.renderReceivedFlatList() : this.state.unshipped ? this.renderUnshippedTabs() : this.state.readyToShip ? this.renderReadyToShipTabs() : this.state.send ? this.renderSentTabs() : this.renderCancelledTabs()}
                        </Box>
                        <Dialog
                            open={this.state.openCancelledOrderModal}
                            aria-labelledby="simple-dialog-title"
                            fullWidth={true} maxWidth={"xs"}
                        >
                            <Box className="myBox" p={2}>
                                <Box mb={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h6">{this.state.orderToOpen.attributes?.catalogue_name}</Typography>
                                    <CloseIcon onClick={() => this.setState({ openCancelledOrderModal: false })}></CloseIcon>
                                </Box>
                                <Box className={classes.orderBox}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1">Order Details</Typography>
                                        <Box
                                            style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '4px',
                                                backgroundColor:
                                                    this.state.orderToOpen.attributes?.status === 'shipped'
                                                        ? '#E8E8E8'
                                                        : this.state.orderToOpen.attributes?.status === 'created'
                                                            ? '#FFF3EB'
                                                            : this.state.orderToOpen.attributes?.status === 'rto'
                                                                ? '#EEF1F6'
                                                                : this.state.orderToOpen.attributes?.status === 'exchange'
                                                                    ? '#FBE9FF'
                                                                    : this.state.orderToOpen.attributes?.status === 'cancelled'
                                                                        ? '#FEEDEE'
                                                                        : ''
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    textTransform: 'capitalize',
                                                    color:
                                                        this.state.orderToOpen.attributes?.status === 'shipped'
                                                            ? '#6A6A6A'
                                                            : this.state.orderToOpen.attributes?.status === 'created'
                                                                ? '#DAA377'
                                                                : this.state.orderToOpen.attributes?.status === 'rto'
                                                                    ? '#5B7995'
                                                                    : this.state.orderToOpen.attributes?.status === 'exchange'
                                                                        ? '#BB2FE1'
                                                                        : this.state.orderToOpen.attributes?.status === 'cancelled'
                                                                            ? '#E65A66'
                                                                            : ''
                                                }}
                                            >
                                                {this.state.orderToOpen.attributes?.status}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box my={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Order ID</Typography>
                                        <Typography variant="caption">{this.state.orderToOpen.attributes?.order_number}</Typography>
                                    </Box>
                                    <Box mb={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Order Date</Typography>
                                        <Typography variant="caption">{moment(this.state.orderToOpen.attributes?.updated_at).format('DD/MM/YYYY')}</Typography>
                                    </Box>
                                    {
                                        this.state.orderToOpen.attributes?.company_name ?
                                            <Box mb={1} display="flex" justifyContent="space-between">
                                                <Typography variant="caption">Fulfilled By</Typography>
                                                <Typography variant="caption">{this.state.orderToOpen.attributes?.company_name}</Typography>
                                            </Box> :
                                            <></>
                                    }
                                    <Box mb={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Customer Name</Typography>
                                        <Typography variant="caption">{_.startCase(_.toLower(this.state.orderToOpen.attributes?.delivered_at.name))}</Typography>
                                    </Box>
                                    <Box mb={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Cancelled Date</Typography>
                                        <Typography variant="caption">{moment(this.state.orderToOpen.attributes?.updated_at).format('DD/MM/YYYY')}</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.orderBox}>
                                    <Box mb={2}>
                                        <Typography style={{ color: this.state.orderToOpen.attributes?.status === 'shipped' ? '#6A6A6A' : this.state.orderToOpen.attributes?.status === 'delivered' ? '#DAA377' : this.state.orderToOpen.attributes?.status === 'rto' ? '#5B7995' : this.state.orderToOpen.attributes?.status === 'exchange' ? '#BB2FE1' : this.state.orderToOpen.attributes?.status === 'cancelled' ? '#E65A66' : '' }}>{this.state.orderToOpen.attributes?.status && this.state.orderToOpen.attributes?.status[0].toUpperCase() + this.state.orderToOpen.attributes?.status.slice(1)} On {moment(this.state.orderToOpen.attributes?.updated_at).format('DD/MM/YYYY')}</Typography>
                                        <Typography variant="body1">Delivery Address</Typography>
                                        <Typography variant="body2">{_.startCase(_.toLower(this.state.orderToOpen.attributes?.delivered_at.name))}</Typography>
                                        <Typography variant="caption">{this.state.orderToOpen.attributes?.delivered_at.address} {this.state.orderToOpen.attributes?.delivered_at.town}</Typography>
                                        <Typography variant="caption">{this.state.orderToOpen.attributes?.delivered_at.city}, {this.state.orderToOpen.attributes?.delivered_at.state} - {this.state.orderToOpen.attributes?.delivered_at.zip_code}</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.orderBox}>
                                    <FlatList
                                        data={this.state.orderToOpen.attributes?.catalogue_details}
                                        renderItem={({ item, index }: OrderItem) => {
                                            let image = item?.attributes?.galleries?.[0].url
                                            return (
                                                <Box display="flex" mt={2}>
                                                    <Box flex="1">
                                                        <img className={this.classes.classes.orderImg} alt="test" src={`${image}`} />
                                                    </Box>
                                                    <Box flex="9">
                                                        <Box my={1}>
                                                            <Typography variant="subtitle2">{item?.attributes.name}</Typography>
                                                            <Typography variant="caption">{item?.attributes.description}</Typography>
                                                        </Box>
                                                        <Box my={1} display="flex" alignItems="center">
                                                            <Typography variant="caption">Size: {item?.attributes.size}</Typography>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Typography variant="caption">QTY: {this.state.orderToOpen.attributes?.order_items[index]?.quantity}</Typography>
                                                        </Box>
                                                        <Box my={1}>
                                                            <Typography variant="caption">SKU ID: {item?.attributes?.sku}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box className={classes.orderBox}>
                                    <Typography variant="body1">Price Details</Typography>
                                    <Box my={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Item Subtotal</Typography>
                                        <Typography variant="caption">₹{this.state.orderToOpen.attributes?.sub_total}</Typography>
                                    </Box>
                                    <Box mb={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Tax</Typography>
                                        <Typography variant="caption">₹{this.state.orderToOpen.attributes?.total_tax}</Typography>
                                    </Box>
                                    <Box mb={1} display="flex" justifyContent="space-between">
                                        <Typography variant="caption">Total Amount</Typography>
                                        <Typography variant="caption">₹{this.state.orderToOpen.attributes?.total}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                    textAlign="center"
                                    p={1}
                                    display="flex"
                                    flexWrap="nowrap"
                                    justifyContent="space-evenly"
                                ></Box>
                            </Box>
                        </Dialog>
                    </Container>
                    {/* Customizable Area Start */}
                    {/* Merge Engine UI Engine Code */}
                    {/* Merge Engine UI Engine Code */}
                    {/* Customizable Area End */}
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    searchInputMainView: {
        backgroundColor: '#ffffff',
    },

    searchView: {
        flexDirection: 'row',
        marginTop: scale(20),
        height: scale(48),
        width: scale(380),
        borderRadius: scale(8),
        alignItems: 'center',
        borderWidth: scale(1),
        borderColor: "#DCDCDC",
        alignSelf: 'center',
    },

    searchImage: {
        marginLeft: scale(10),
    },

    searchInput: {
        height: scale(40),
        width: scale(330),
        marginLeft: scale(10)
    },

    tabBtnMainView: {
        backgroundColor: COLORS.white,
    },

    tabButtonView: {
        marginTop: scale(20),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'center'
    },

    darkLine: {
        height: scale(30),
        borderBottomColor: '#000000',
        borderBottomWidth: scale(1),
        marginHorizontal: scale(15),
    },

    darkBtn: {
        fontSize: scale(18),
        color: "#000000"
    },

    lightBtn: {
        fontSize: scale(16),
        color: "#DCDCDC"
    },

    lightLne: {
        borderBottomColor: '#ffffff',
        marginHorizontal: scale(15),
    },

    receivedList: {
        marginTop: scale(20),
        width: scale(380),
        borderRadius: scale(6),
        alignSelf: 'center',
        backgroundColor: '#ffffff',
        paddingBottom: scale(10),
    },

    orderId: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: scale(10),
        marginTop: scale(10),
    },

    qytView: {
        flexDirection: 'row',
        marginTop: scale(10),
    },

    imageView: {
        height: scale(50),
        width: scale(40),
        marginLeft: scale(10),
        borderColor: COLORS.lightGray,
        borderWidth: 0.5
    },

    orderImageView: {
        width: '100%',
        height: '100%',
        borderWidth: 0.5,
        borderRadius: scale(5),
    },

    brandMainView: {
        marginLeft: scale(10)
    },

    brandTextView: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    brandText: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },

    brandPriceView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: scale(5),
    },

    pointText: {
        height: scale(5),
        width: scale(5),
        borderRadius: scale(2),
        backgroundColor: '#000000',
        marginLeft: scale(8)
    },

    cancelView: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },

    cancelBtn: {
        height: scale(30),
        width: scale(80),
        borderColor: '#000000',
        borderWidth: scale(2),
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: scale(10),
    },

    confirmBtn: {
        height: scale(30),
        width: scale(85),
        backgroundColor: '#000000',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: scale(10),
    },

    confirmText: {
        color: '#ffffff',
    },

    modelView: {
        height: scale(211),
        width: scale(330),
        borderRadius: scale(8),
        alignSelf: 'center',
        backgroundColor: '#fff'
    },

    closeBtn: {
        alignItems: 'flex-end',
        marginRight: scale(16),
    },

    closeImg: {
        width: scale(24),
        height: scale(24),
        marginTop: verticalScale(16),
    },

    OrderHeading: {
        alignSelf: 'center',
        fontSize: scale(18),
        fontWeight: 'bold',
    },

    confirmOrderText: {
        alignSelf: 'center',
        fontSize: scale(18),
        marginTop: scale(20),
    },

    confirmOrderView: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: scale(20),
    },

    noOrderBtn: {
        height: scale(45),
        width: scale(140),
        borderColor: '#000000',
        borderWidth: scale(2),
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: scale(10)
    },

    YesOrderBtn: {
        height: scale(45),
        width: scale(140),
        backgroundColor: '#000000',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: scale(10)
    },

    title: {
        marginBottom: 32,
        fontSize: 16,
        textAlign: "left",
        marginVertical: 8
    },
    body: {
        marginBottom: 32,
        fontSize: 16,
        textAlign: "left",
        marginVertical: 8
    },
    bgPasswordContainer: {
        flexDirection: "row",
        backgroundColor: "#00000000",
        marginBottom: 16,
        borderBottomWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        padding: 10,
        borderWidth: Platform.OS === "web" ? 0 : 1
    },
    bgMobileInput: {
        flex: 1
    },
    showHide: {
        alignSelf: "center"
    },
    imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},

    modalContainer: {
        backgroundColor: "#fff",
        borderTopLeftRadius: scale(16),
        borderTopRightRadius: scale(16),
        width: '100%',
        height: scale(350),
        padding: scale(20)
    },

    modalMainView: {
        flex: 1,
        justifyContent: "flex-end",
        backgroundColor: '#00000089'
    },

    filtersText: {
        fontSize: scale(20),
        fontWeight: 'bold'
    },

    filterDataView: {
        flexDirection: 'row',
        marginTop: verticalScale(15),
        alignItems: 'center'
    },

    imageCheck: {
        height: scale(28),
        width: scale(28),
    },

    filterDataText: {
        fontSize: scale(18),
        marginLeft: scale(14)
    },

    buttonsMainView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: verticalScale(20)
    },

    clearButtonView: {
        height: verticalScale(40),
        width: scale(170),
        borderColor: COLORS.black,
        borderWidth: scale(1.3),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: scale(5)
    },

    clearText: {
        fontSize: scale(18)
    },

    doneButtonView: {
        height: verticalScale(40),
        width: scale(170),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: scale(5),
        backgroundColor: COLORS.black
    },

    doneText: {
        fontSize: scale(18),
        color: COLORS.white
    },

    cancelDateButtonText: {
        fontSize: scale(18),
        color: COLORS.black
    },

    unshippedList: {
        marginTop: scale(20),
        borderRadius: scale(6),
        backgroundColor: '#FFFFFF',
        paddingBottom: scale(10),
        marginHorizontal: scale(17),

    },

    orderIdContainer: {
        flexDirection: 'row',
        marginTop: verticalScale(10),
        marginLeft: scale(10),
        justifyContent: 'space-between'
    },

    orderIdText: {
        marginLeft: scale(5),
        fontSize: scale(12)
    },

    confirmDateText: {
        marginRight: scale(10),
        alignSelf: 'center',
        fontSize: scale(13),
    },

    confirmlView: {
        marginTop: verticalScale(10),
        marginLeft: scale(20)
    },

    confirmButoon: {
        height: scale(30),
        width: scale(85),
        backgroundColor: '#EAF9F4',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: scale(10),
    },

    scheduledPickupButoon: {
        height: scale(30),
        width: scale(130),
        backgroundColor: '#E8E8E8',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: scale(10),
        paddingHorizontal: scale(5)
    },

    shippedButoon: {
        height: scale(30),
        width: scale(80),
        backgroundColor: '#E8E8E8',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: scale(10),
    },

    cancelledButoon: {
        height: scale(30),
        backgroundColor: '#FEEDEE',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: scale(10),
        paddingHorizontal: scale(5)
    },

    returnedButoon: {
        height: scale(30),
        width: scale(80),
        backgroundColor: '#E8E8E8',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: scale(10),
    },

    refundedButoon: {
        height: scale(30),
        width: scale(80),
        backgroundColor: '#F2EEFF',
        borderRadius: scale(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: scale(10),
    },

    buttonsView: {
        flexDirection: 'row',
        marginTop: verticalScale(10)
    },

    confirmButtonText: {
        color: "#68CFB0",
        fontSize: scale(12)
    },

    scheduledPickUpText: {
        color: "#000000",
        fontSize: scale(12)
    },

    shippedText: {
        color: "#000000"
    },

    cancelledText: {
        color: "#E65A66",
        fontSize: scale(12)
    },

    refundedText: {
        color: "#7765C5"
    },

    schedulePickupBtn: {
        width: scale(380),
        height: verticalScale(50),
        alignSelf: 'center',
        borderRadius: scale(5),
        justifyContent: 'center',
        alignItems: 'center',
    },

    btnText: {
        fontSize: scale(20),
        color: COLORS.white
    },

    unshippedBlankView: {
        height: scale(115)
    },

    buttonBackgroundView: {
        backgroundColor: '#fff',
        height: verticalScale(100),
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: "absolute",
        bottom: 0
    },

    modalMenuContainer: {
        backgroundColor: COLORS.white,
        width: '40%',
        padding: scale(10),
        alignSelf: 'flex-end',
        marginTop: verticalScale(70),
        marginRight: scale(10),
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.5,
        elevation: 3,
        shadowRadius: 5,
    },

    modalMenuMainView: {
        flex: 1,
    },

    printInvoiceText: {
        fontSize: scale(12),
        lineHeight: scale(30)
    },

    checkBox: {
        height: scale(20),
        width: scale(20)
    },
    quantityyText: {
        color: COLORS.darkGray
    },
    priceText: {
        color: COLORS.darkGray

    },
    descripText: {
        color: COLORS.darkGray,
        fontSize: scale(12),
        textTransform: 'capitalize'
    },

    fromtxt: {
        fontSize: scale(9.3),
        color: '#5a7184',
        marginTop: verticalScale(21.3),
        alignSelf: 'flex-start'
    },

    modalDateView: {
        flex: 1,
        backgroundColor: '#00000089',
        justifyContent: 'center',
        alignItems: 'center',
    },

    departureList: {
        borderColor: '#c3cad9',
        marginRight: scale(10),
    },

    dateView: {
        width: scale(375),
        borderRadius: scale(8),
        backgroundColor: '#ffffff'
    },

    startDateView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: scale(15)
    },

    endDateView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: scale(15)
    },

    dateText: {
        fontSize: scale(18),
        marginLeft: scale(10),
    },

    dateMainButtonsView: {
        flexDirection: 'row',
        marginBottom: scale(12),
        justifyContent: 'space-around',
    },

    doneDateButtonView: {
        height: verticalScale(40),
        width: scale(160),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: scale(5),
        backgroundColor: COLORS.black
    },

    cancelDateButtonView: {
        height: scale(40),
        width: scale(160),
        borderColor: '#000000',
        borderWidth: scale(2),
        borderRadius: scale(5),
        justifyContent: 'center',
        alignItems: 'center',
    },

    unshippedModalContainerView: {
        justifyContent: 'flex-end',
        flex: 1
    },

    unshippedModalContainer: {
        height: scale(45),
        width: scale(350),
        borderRadius: scale(22),
        alignSelf: 'center',
        backgroundColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0
    },

    alertText: {
        fontSize: scale(15),
        color: COLORS.white
    }
});
const style = {
    pageOuter: {
        backgroundColor: "#F7F7F7",
        padding: "25px 0 70px",
        fontFamily: "'Montserrat', sans-serif",
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center"
    },
    positionStatic: {
        boxShadow: "none"
    },
    srchOrdrInputBox: {
        background: "#f1f1f1",
    },
    srchOrdrInputFld: {
        background: "transparent",
        border: 0
    },
    selection: {
        border: "none",
        width: "100%",
        backgroundColor: "white",
        paddingLeft: "10px"
    },
    filter: {
        minWidth: "180px",
        backgroundColor: "#ffffff",
        border: "2px solid #D7D7D7",
        padding: "5px 20px",
        color: "#7E7E7F",
        marginLeft: "20px",
    },
    header: {
        background: '#fff',
        padding: "20px"
    },
    orderBox: {
        border: '1px solid #ebebeb',
        padding: '15px',
        marginBottom: '20px'
    },
    productImg: {
        marginRight: '15px'
    },
    separator: {
        fontSize: '8px',
        margin: '10px'
    },
    orderImg: {
        width: '75px',
        height: '75px',
        marginRight: '15px'
    },
    listBox: {
        background: '#fff',
        padding: '20px',
        paddingBottom: '0',
        borderTop: '2px solid #e3e3e3'
    },
    orderBtn: {
        border: '1px solid',
        borderRadius: 0,
        boxShadow: 'none',
        "width": "150px",
        "marginLeft": "20px",
        padding: '10px 15px',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    scheduleBtn: {
        width: 'auto'
    },
    orderBtn_White: {
        '&:hover': {
            background: '#000',
            color: '#fff'
        }
    },
    orderBtn_Black: {
        background: '#000',
        color: '#fff',
        '&:hover': {
            background: '#fff',
            color: '#000'
        }
    },
    checked: {
        color: "#000!important"
    }
};

export default withStyles(style)(OrderSummary);
// Customizable Area End
