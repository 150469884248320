import React, { useState,Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Container from "@material-ui/core/Container";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";

// import Wishlist2Controller, { Props, configJSON } from "./Wishlist2Controller";
import { url } from "inspector";
import { CrossIcon } from "react-select/src/components/indicators";
import Link from '@material-ui/core/Link';
const  adidas = require ("../assets/adidas.png");
const  hnm = require ("../assets/hnm.png");
const  makeupKit = require ("../assets/makeupKit.png")
const  aeroSummerShorts = require ("../assets/aeroSummerShorts.png")
const  campus = require ("../assets/campus.png")
const  versace = require ("../assets/versace.png")
const  champion = require ("../assets/champion.png")
const  quiltedSlingBag = require ("../assets/quiltedSlingBag.png")
const  spykarBeachYellowShirt = require ("../assets/spykarBeachYellowShirt.png")
const  roadster = require ("../assets/roadster.png")
const  blueSuit = require ("../assets/blueSuit.png")

import SubCategoriesController, { Props, configJSON } from "./SubCategoriesController.web";
const bannerSliderresponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const styles:any = {
  pageOuter: {
    fontFamily: "'Roboto',sans-serif",
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },
    "& .title-block": {
      textAlign:"center",
      marginTop:"20px",
      marginBottom:"29px",
      fontWeight: "500",
      color: "#444",
      fontSize: "32px",
      "@media (max-width:480px)": {
        fontSize: "26px",
        margin: "16px 0",
      },
    },
    "& .row-wrap": {
      margin: "0 -8px",
      "@media (max-width:480px)": {
        margin: "0 -4px",
      },
    },
    "& .row-wrap .column": {
      padding: "0 8px",
      height: "100%",
      "@media (max-width:480px)": {
        padding: "0 4px",
      },
    },
    "& .column .inner-col": {
      color: "#444",
      display: "block",
      height: "100%",
    },
    "& .column .img-block": {
      margin: "0 0 10px",
    },
    "& .column .img-block img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .column .col-name": {
      color: "#444",
      fontSize: "24px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      lineHeight: "28px",
      "@media (max-width:480px)": {
        fontSize: "12px",
        lineHeight: "16px",

      },
    },
  },
  bannerSlider: {
    "& .row-wrap": {
      position: "relative",
      paddingBottom: "40px",
      "@media (max-width:767px)": {
        padding: "16px",
      },
    },
    "& .slides": {
      maxHeight: "500px",
      minHeight: "400px",
      "@media (max-width:1024px)": {
        minHeight: "300px",
      },
      "@media (max-width:767px)": {
        minHeight: "250px",
      },
    },
    "& .slides img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      "@media (max-width:767px)": {
        minHeight: "250px",
        objectFit: "cover",
      },
    },
    "& .custom-dot": {
      height: "5px",
      border: "none",
      borderRadius: "10px",
      margin: "5px",
      maxWidth: "50px",
      width: "100%",
      "@media (max-width:575px)": {
        maxWidth: "30px",
        height: "4px",
      },
    },
    "& .custom-dot--active": {
      transform: "scale(1)",
      backgroundColor: "#feca39",
    },
    "& .react-multi-carousel-dot-list": {
      bottom: "20%",
    },
  },
  shopProduct: {
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: 'space-between',
      margin: "0",
    },
    "& .row-wrap .block-wrapper": {
      backgroundColor: "#ECECEC",
      padding: "15px 25px",
      flex: "0 0 calc(50% - 8px)",
      maxWidth: "calc(50% - 8px)",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
      "@media (max-width:575px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .block-wrapper img": {
      maxWidth: "100px",
      maxHeight: "100px",
      margin: "0 10px",
      "@media (max-width:480px)": {
        margin: "0 5px",
      },
    },
    "& .block-wrapper span": {
      color: "#444",
      fontSize: "24px",
      fontFamily: "'Roboto',sans-serif",
      lineHeight: "28px",
      "@media (max-width:480px)": {
        fontSize: "16px",
        lineHeight: "20px",

      },
    },
  },
  shopByEdit: {
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 -8px",
    },
    "& .row-wrap .column": {
      flex: "0 0 25%",
      maxWidth: "25%",
      marginBottom: "30px",
      "@media (max-width:991px)": {
        flex: "0 0 33.33%",
        maxWidth: "33.33%",
      },
      "@media (max-width:767px)": {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 8px",
      },
      "@media (max-width:480px)": {
        marginBottom: "15px",
      },
    },
    "& .column .img-block": {
      height: "275px",

      "@media (max-width:480px)": {
        height: "200px",
      },
    },
    "& .column .col-name": {
      textAlign: "center",
      textTransform: "uppercase",
      fontWeight: "700",
    },
  },
  featuredBrand: {
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 -8px",
    },
    "& .row-wrap .column": {
      flex: "0 0 20%",
      maxWidth: "20%",
      marginBottom: "30px",
      padding: "0 8px",
      "@media (max-width:1024px)": {
        flex: "0 0 25%",
        maxWidth: "25%",
      },
      "@media (max-width:767px)": {
        flex: "0 0 33.33%",
        maxWidth: "33.33%",
      },
      "@media (max-width:480px)": {
        marginBottom: "15px",
      },

    },
    "& .column .img-block": {
      borderRadius: "100%",
      overflow: "hidden",
      maxWidth: "170px",
      maxHeight: "170px",
      margin: "0 auto 30px",
      border: "4px solid",
      display: "flex",
      "@media (max-width:991px)": {
        maxWidth: "130px",
        maxHeight: "130px",
        margin: "0 auto 15px",
      },
      "@media (max-width:480px)": {
        maxWidth: "100px",
        maxHeight: "100px",
        margin: "0 auto 15px",
      },
    },
    "& .column .col-name": {
      textTransform: "uppercase",
      fontWeight: "700",
    },
    "& .column.pink-border .img-block": {
      borderColor: "#fb7d8b",
    },
    "& .column.light-green-border .img-block": {
      borderColor: "#81d478",
    },
    "& .column.blue-border .img-block": {
      borderColor: "#7891d4",
    },
    "& .column.yellow-border .img-block": {
      borderColor: "#feca39",
    },
    "& .column.red-border .img-block": {
      borderColor: "#d53f41",
    },
    "& .column.green-border .img-block": {
      borderColor: "#bdcd8f",
    },
  },
};

export class CategoriesList extends SubCategoriesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const CustomDot = ( onClick:any, active:any ) => {
      return (
        <button
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          className={classNames("custom-dot", {
            "custom-dot--active": active,
          })}
        />
      );
    };

    return (
      <>
        {/* product detail page */}
        <div className={classes.pageOuter}>
          {/* banner slider */}
          <div className={classes.bannerSlider}>
            <div className="outer-wrap">
              <div className="row-wrap">
                <Carousel
                  renderDotsOutside
                  responsive={bannerSliderresponsive}
                  infinite={true}
                  customDot={<CustomDot />}
                  autoPlay={true}
                  autoPlaySpeed={2000}
                  showDots={true}
                  arrows={false}
                >
             {this.state.banner.map((val:any)=> <>
              <div className="slides">
                    <img src={val.attributes.image} />
                  </div>
                  </>)}    
                </Carousel>
              </div>
            </div>
          </div>
          {/* banner slider ends */}
          <div className={classes.shopProduct}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <h2 className="title-block">Shop By Products</h2>
                <div className="row-wrap">

                  {this.state.SubCategories.map((val:any) => <>  <div className="column block-wrapper">
                    <img src={val.attributes.image} />
                    <span> {val.attributes.name}</span>
                  </div>   </>)}
              
                </div>
              </div>
            </Container>
          </div>

          {/* shop by edit */}
          <div className={classes.shopByEdit}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <h2 className="title-block">Shop By Edit</h2>
                <div className="row-wrap">
{this.state.shopbyedit.slice(0,12).map((val:any)=> <>

  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={val.attributes.galleries?.[0].url} />
                      </div>
                      <div className="col-name">
                        <span>{val.attributes.name}</span>
                      </div>
                    </Link>
                  </div>
               </>)}

               
             
                </div>
              </div>
            </Container>
          </div>
          {/* shop by edit ends */}
          {/* featured brand */}
          <div className={classes.featuredBrand}>
            <Container maxWidth="lg">
              <div className="outer-wrap">
                <h2 className="title-block">Featured Brands</h2>
                <div className="row-wrap">

            {this.state.brandData.map((val:any)=> <>
              <div className="column pink-border">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={val.attributes.image} />
                      </div>
                      <div className="col-name">
                        <span>{val.attributes.name}</span>
                      </div>
                    </Link>
                  </div>
                </>)}      
             
               
                  
              
               
                </div>
              </div>
            </Container>
          </div>
          {/* featured brands ends */}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(CategoriesList);
