import firebase from "firebase";

export const roundUpRating = (rating: number) => {
    return (Math.round(rating * 100)) / 100;
}

export const convertIntoMoneyFormat = (inputNumber: number) => {
    return Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(inputNumber);
}

export const getAuthToken = () => {
    return localStorage.getItem("authToken")
}

// scroll to top
export const scrollToTopOfElement = (elementReference: any) => {
    elementReference?.current?.scrollIntoView({ behavior: "auto" });
}

// use this function referrence it will provice event name(add to cart) and paramaters
// export const logEventOnFirebase = (event, params) => {
//     const analytics = firebase.analytics();
//     analytics.logEvent(firebase.analytics.EventName.ADD_TO_CART,
//         {
//             currency: "INR",
//             value: 100.10,
//             items: [{
//                 item_name: "road start shoes",
//                 item_brand: "roadstart",
//                 price: 100,
//                 quantity: 3
//             }]
//         }
//     );
// }

// this function is used to log event on firebase
// for ecommerce google analytics
export const logEventOnFirebase = (event: any, params: any) => {
    const analytics = firebase.analytics();
    analytics.logEvent(event, params);
}