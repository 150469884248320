//This an auto generated file for Artboard Name = AddNewPackage And Supports ReactNative Ver. = 0.62
// import { SafeAreaView, Dimensions, PixelRatio, View, Text, FlatList, ScrollView, StyleSheet, Button, TouchableOpacity, CheckBox, Switch, Platform, Image, TextInput, Picker, ActivityIndicator, Alert, ImageBackground } from 'react-native';
// import React from 'react';
// import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
// import { responsiveHeight, responsiveWidth, responsiveFontSize } from 'react-native-responsive-dimensions';
// import { Menu, MenuItem, MenuDivider } from 'react-native-material-menu'
// import EditPackageController,{Props} from './EditPackageController.web';



//         return (

//             <SafeAreaView style={{ "flex": 1 }}>
//                 <ScrollView contentContainerStyle={{
//                     flexGrow: 1,
//                 }} >
//                     <View style={styles.AddNewPackageView}>
//                         <View
//                             style={styles.group_contactdetailsGroup}>
//                             <View
//                                 style={styles.view_bg}>
//                             </View>
//                             <Text
//                                 style={styles.textlabel_textText}>Package Details
//                             </Text>
//                         </View>
//                         <View style={styles.txt_view}>
//                             <TextInput
//                                 style={styles.SUBTITLE1COLORSTYLEText}
//                                 placeholder={'Package Name*'}
//                                 defaultValue={this.state.p_name}
//                                 onChangeText={(text) => { this.setState({ p_name: text }) }}
//                             />
//                         </View>
//                         <View>
//                             <TextInput
//                                 style={styles.SUBTITLE1COLORSTYLEText2}
//                                 placeholder={'Package Length*'}
//                                 defaultValue={this.state.p_length}
//                                 keyboardType='numeric'
//                                 onChangeText={(text) => { this.setState({ p_length: text }) }}
//                             />
//                         </View>
//                         <View>
//                             <TextInput
//                                 style={styles.SUBTITLE1COLORSTYLEText3}
//                                 placeholder={'Package Width*'}
//                                 defaultValue={this.state.p_width}
//                                 keyboardType='numeric'
//                                 onChangeText={(text) => { this.setState({ p_width: text }) }}
//                             />
//                         </View>
//                         <View>
//                             <TextInput
//                                 style={styles.SUBTITLE1COLORSTYLEText4}
//                                 placeholder={'Package Height*'}
//                                 defaultValue={this.state.p_height}
//                                 keyboardType='numeric'
//                                 onChangeText={(text) => { this.setState({ p_height: text }) }}
//                             />
//                         </View>

//                         <TouchableOpacity
//                             onPress={() => this.onEdithPackage()}
//                             style={styles.button_addpackageButton}>
//                             <Text
//                                 style={styles.TEXTCOLORSTYLEText}>Edit Package
//                             </Text>
//                         </TouchableOpacity>
//                     </View>
//                 </ScrollView>
//             </SafeAreaView>

//         )
//     }

// }


import PackagingController, {
    Props,
    configJSON,
  } from "./PackagingController.web";
import React, { Component } from "react";
import { Box, TextField, InputLabel, Input, FormControl } from "@material-ui/core";
// import SellerController, { Props } from "./SellerController.web";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Link from "@material-ui/core/Link";
import BackArrow from "@material-ui/icons/ArrowBack";
import {
  withStyles,
} from "@material-ui/core/styles";

const styles:any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: '50px 0',
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      textTransform: "uppercase",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& p": {
      fontFamily: "'Roboto',sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .send-btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0 0",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
        display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
  },
  unitText: {
    fontFamily: "'Roboto',sans-serif",
    position: "absolute",
    top: "5px",
    right: "0",
    color: "rgba(0, 0, 0, 0.54)"
   
  },
};

export class AddNewPackage extends   PackagingController {
  constructor(props:any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }



  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                <div style ={{display:"flex",width:"760px",justifyContent:"space-between",alignItems:"baseline"}}>   <Link  style ={{color:"black"}} href="/Packaging"><BackArrow/></Link>
                <h2>Add New Package</h2> 
                  </div>
               
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <div className="form-group">
                      <InputLabel required htmlFor="txtInputName">Package Name</InputLabel>
                      <FormControl>
                        {/* <Input id={"txtInputName"}
                         value={this.state.p_name}
                                onChange={(e) => { this.setState({ p_name: e.target.value }) ;console.log(this.state.p_name, "pname") }} /> */}
                                 <Input
                onChange={(e) => { this.setState({ p_name: e.target.value }) ;console.log(this.state.p_name) }}
                id="my-input" aria-describedby="my-helper-text" />
                      </FormControl>
                      <p className="error-msg">
                          {/* {this.state.phoneError} */}
                          {this.state.name_error}
                        </p>
                    </div>
                 
             
               
                    <div className="form-group">
                      <InputLabel  required htmlFor="txtInputBrand"> Length</InputLabel>
                      <FormControl>
                        {/* <Input id={"txtInputBrand"}
                          value={this.state.p_length}
                        //  keyboardType='numeric'
                         onChange={(e) => { this.setState({ p_length: e.target.value});console.log(this.state.p_length, "plength") }}
                  

                          /> */}
                           <Input
                           type = "number"
                    onChange={(e) => { this.setState({p_length: e.target.value });console.log(this.state.p_length) }}
                id="my-input" aria-describedby="my-helper-text" />
                <span className={classes.unitText}>CM</span>
                      </FormControl>
                      <p className="error-msg">
                          {/* {this.state.phoneError} */}
                    {this.state.length_error}
                        </p>
                    </div>
                    <div className="form-group">
                      <InputLabel  required htmlFor="txtInputUrl">Width</InputLabel>
                      <FormControl>
                        {/* <Input id={"txtInputUrl"}
                          value={this.state.p_width}
          
                          //  keyboardType='numeric'
                           onChange={(e) => { this.setState({ p_width: e.target.value});console.log(this.state.p_width, "pwidth") }} /> */}
                            <Input 
                            type = "number"
                    onChange={(e) => { this.setState({ p_width: e.target.value });console.log(this.state.p_width) }}
                id="my-input" aria-describedby="my-helper-text" />
                <span className={classes.unitText}>CM</span>
                      </FormControl>
                      <p className="error-msg">
                          {/* {this.state.phoneError} */}
                          {this.state.width_error}
                        </p>
                    </div>
                    <div className="form-group ">
                      <InputLabel  required htmlFor="standard-adornment-amount" > Height</InputLabel>
                      <FormControl>
                        {/* <Input id={"txtInputLocation"}
                                 value={this.state.p_height}
                                 //  keyboardType='numeric'
                                  onChange={(e) => { this.setState({ p_height: e.target.value});console.log(this.state.p_height, "pheight") }} */}
                                   <Input
                                   type = "number"
                    onChange={(e) => { this.setState({ p_height: e.target.value });console.log(this.state.p_height) }}
                id="my-input" aria-describedby="my-helper-text" />
                <span className={classes.unitText}>CM</span>
                
                        
                        
                      </FormControl>
                      <p className="error-msg">
                          {/* {this.state.phoneError} */}
                          {this.state.height_error}
                        </p>
                    </div>
                 
                 
                    <div className="send-btn-wrapper">
                      <Button  variant="contained" className="black-btn" type="submit"    onClick={() => {this.onAdd()} }>Add Package</Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </div>

      
      </>
    )
  }
}
// styling

const sendButton = {
  outline: "none",
  border: "none",
  marginLeft: "250px",
  backgroundColor: "black",
  width: "150px",
  height: "40px",
  marginTop: "50px",
  color: "white",
};

const sendButtonAnchor = { color: "white", textDecoration: "none" };

export default withStyles(styles)(AddNewPackage);
