import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { } from "./assets";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";
import { OrderModel } from "../../../framework/src/Interfaces/IOrderDetail";
import { Order_Message } from "../../../framework/src/EnumMessage";
import { toastSuccessMessage } from "../../../components/src/toastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string | null;
    isLoading: boolean;
    changePickupAddress: boolean;
    packageDimensions: boolean;
    orderId: number;
    orderResponse: OrderModel;
    addressList: any[];
    packageList: any[];
    addressDetail: any;
    packageDetail: any;
    defaultAdddressId: string;
    defaultPacakgeId: string;
    packageWeight: string;
    hasError: boolean;
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SchedulePickupController extends BlockComponent<
    Props,
    S,
    SS
> {

    getCustomerOrderDetailAPICallId: any;
    getVendorAddressListAPICallId: any;
    getVendorPackageListListApiCallId: any;
    schedulePickupAPICallId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: localStorage.getItem('authToken'),
            isLoading: false,
            changePickupAddress: false,
            packageDimensions: false,
            orderId: this.props.navigation.getParam('orderId'),
            orderResponse: {} as OrderModel,
            addressList: [],
            packageList: [],
            addressDetail: {},
            packageDetail: {},
            defaultAdddressId: '',
            defaultPacakgeId: '',
            packageWeight: '',
            hasError: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        console.log('@@@ API MESSAGE SIGNUP =================', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            console.log('responseJson', responseJson)
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            )

            this.setState({ isLoading: false })
            if (responseJson && !responseJson.errors) {
                console.log('success response', responseJson)
                if (apiRequestCallId === this.getCustomerOrderDetailAPICallId) {
                    this.oderByIdSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getVendorAddressListAPICallId) {
                    this.addressListSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getVendorPackageListListApiCallId) {
                    this.packageListSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.schedulePickupAPICallId) {
                    this.schedulePickupSuccessCallBack(responseJson)
                }
            } else if (responseJson && responseJson.errors) {
                console.log('errors:::', responseJson)
                this.parseApiErrorResponse(responseJson);
                if (apiRequestCallId === this.getCustomerOrderDetailAPICallId) {
                    this.orderByIdFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getVendorAddressListAPICallId) {
                    this.addressListFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getVendorPackageListListApiCallId) {
                    this.packageListFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.schedulePickupAPICallId) {
                    this.schedulePickupFailureCallBack(responseJson)
                }
            } else if (errorReponse) {
                console.log('errorReponse', errorReponse)
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        this.getOrderDetailById();
        this.getVendorAddressList();
        this.getVendorPackageList();
    }

    // api calling strcture
    apiCall = async (data: any) => {
        console.log('@@@@ api data ======', data)
        const { contentType, method, endPoint, body, type } = data
        const header = {
            'Content-Type': contentType,
            token: this.state.token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log('@@@ requestMessage ========', requestMessage)
        return requestMessage.messageId;
    }

    // response handling start
    oderByIdSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson?.data) {
            this.setState({ orderResponse: responseJson.data })
        }
        else {
            this.setState({ orderResponse: {} as OrderModel })
        }
    };
    orderByIdFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
    };

    addressListSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson.data) {
            let addressList: any[] = responseJson.data && responseJson.data.length > 0 ? responseJson.data : [];
            let defaultAddress = null;
            let defaultAdddressId = null;
            if (addressList && addressList.length) {
                // if default address not found take first address as default
                defaultAddress = addressList.find(address => address.attributes.is_default === true) || addressList[0];
                defaultAdddressId = defaultAddress.id;
            }
            this.setState({ addressList: addressList, addressDetail: defaultAddress, defaultAdddressId: defaultAdddressId })
        }
        else {
            this.setState({ addressList: [] })
        }
    };
    addressListFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
    };

    packageListSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson.data) {
            let packageList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : [];
            let isDefaultPacakge = null;
            let defaultPackageId = null;
            if (packageList && packageList.length) {
                // if default address not found take first address as default
                isDefaultPacakge = packageList.find((item: any) => item.attributes.is_default === true) || packageList[0];
                defaultPackageId = isDefaultPacakge.id;
            }
            this.setState({ packageList: packageList, packageDetail: isDefaultPacakge, defaultPacakgeId: defaultPackageId, isLoading: false })
        }
        else {
            this.setState({ packageList: [] })
        }
    };
    packageListFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
    };


    schedulePickupSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on schedule pickup success callBack =================', responseJson)
        if (responseJson?.data?.id) {
            toastSuccessMessage(Order_Message.SchedulePickup);
            // navigate to ready to ship tab
            this.props.navigation.navigate("vendorOrder", { tab: 'ready-to-ship' });
        }
        else {
            // handle failure response
        }
    };
    schedulePickupFailureCallBack = (responseJson: any) => {
        console.log('@@@ schedule pickup failure callback =================', responseJson)
    };

    // response handling end

    // api calling start
    getOrderDetailById = async () => {
        this.setState({ isLoading: true });
        this.getCustomerOrderDetailAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.customerOrderListApiEndPoint + `/${Number(this.state.orderId)}`
        });
    };

    getVendorAddressList = async () => {
        this.getVendorAddressListAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.addressAPiEndPoint,
        });
    };

    getVendorPackageList = async () => {
        this.getVendorPackageListListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.packageAPiEndPoint
        });
    };

    schedulePickupOrder = async () => {
        const { orderId, packageWeight, addressDetail, packageDetail } = this.state;
        if (!addressDetail?.id || !packageDetail?.id) {
            // has error
            this.setState({ hasError: true });
            return;
        }
        this.setState({ isLoading: true, hasError: false })
        const body = {
            order_id: Number(orderId),
            address_id: Number(addressDetail.id),
            package_id: Number(packageDetail.id),
            package_weight: packageWeight
        }
        this.schedulePickupAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.schedulePickupApiEndPoint,
            body
        });
    }
}