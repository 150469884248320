import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import IconButton from "@material-ui/core/IconButton";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

const shopping = require("../assets/shopping.png");
const delivered = require("../assets/delivered.png");
const championItem = require("../assets/champion-item.png");
const cancelled = require("../assets/cancelled.png");
const refund = require("../assets/refund.png");
const DocIc = require("../assets/doc-icon.svg");

import MyOrderDetailController, {
  Props,
  configJSON
} from "./MyOrderDetailController.web";



import {
  Box,
  TextField,
  InputLabel,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "../../../../node_modules/@szhsin/react-menu";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";

// interface Props {
//   classes: any;
// }
// interface State {}

const profileList = [
  { id: "1", category: "My Profile" },
  { id: "2", category: "My Orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" },
];

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      // margin: "0 0 20px",
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio label": {
      color: "#000",
    },
    "& .custom-radio label .MuiIconButton-label": {
      color: "#000",
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
    "& .download-doc": {
      border: "1px solid #d3d3d3",
      padding: "20px",
      minHeight: "88px",
      display: "flex",
      alignItems: "center",
      margin: "30px 0",
      "@media (max-width:767px)": {
        margin: "20px 0 0",
      },
    },
    "& .download-doc .outline-btn": {
      minWidth: "120px",
      padding: "5px",
      marginLeft: "auto",
    },
    "& .download-doc .file-name": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
      marginLeft: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "calc(100% - 180px)",
      textOverflow: "ellipsis",
    },
    "& .product-price span": {
      fontSize: "14px",
      lineHeight: "18px",
      display: "inline-block",
      color: "#a5a5a5",
    },
    "& .product-price span.current-price": {
      fontWeight: "700",
      fontSize: "16px",
      color: "#121212",
      margin: "0 10px 0 0",
    },
    "& .product-price span.off-price": {
      textDecoration: "line-through",
    },
    "& .order-info-blog": {
      borderTop: "1px solid #e3e3e3",
      padding: "25px 0 0 ",
    },
    "& .order-info-blog h4": {
      fontWeight: "600",
      color: "#212121",
      margin: "0 0 15px 0",
    },
    "& .order-info-blog p": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#121212",
      margin: "0 0 15px 0",
    },
    "& .btn-wrapper button": {
      height: "50px",
      minWidth: "150px",
      "@media (max-width:575px)": {
        width: "100%",
      },
    },
    "& .btn-wrapper button.outline-btn": {
      margin: "0 15px 0 0",
      "@media (max-width:575px)": {
        margin: "0px 0 10px",
      },
    },
    "& .order-info-blog .user-detail p": {
      fontSize: "14px",
      lineHeight: "18px",
    },
    "& .order-info-blog .user-detail p b": {
      fontWeight: "600",
    },
    "& .radio-label": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#212121",
    },
    "& .checkbox": {
      margin: "0 0 0 0",
    },
    "& .checkbox .MuiButtonBase-root ": {
      color: "#212121 !important",
    },


    "& .tab": {
      border: "1px solid #000",
      padding: "2px 10px",
      borderRadius: "3px",
    },







  },
};

export class ReturnOrderPickup extends MyOrderDetailController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start

    // Customizable Area End
  }


  renderReturnOrderPickup = () => {
    const { classes } = this.props;
    const productdata: any = JSON.parse(localStorage.getItem("productdata") || "{}")
    const productdetails: any = JSON.parse(localStorage.getItem("productdetails") || "{}")
    const productPolicyDays = productdata.attributes?.return_policy?.days
    const maximum_retail_price: any = productdetails.maximum_retail_price
    const discount_on_mrp: any = Number(productdetails.discount_on_mrp)
    const productSalePrice: any = Number(productdetails.sale_price)
    const total_price: any = Number(productdetails.total_price)

    return (
      <>

        {

          !localStorage.getItem("productdata") && !localStorage.getItem("productdetails") ?
            this.props.navigation.navigate("MyOrderDetail") :
            <div className={classes.pageOuter}>
              <div>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item xs={12}>
                      <h2>Item Details</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="block-wrapper order-block">
                        <div className="block-wrapper-inner">
                          <div className="order-details-wrapper">
                            <Grid item xs={12} className="productlist-wrapper">
                              <Grid className="product-list">
                                <div className="product-list-inner">
                                  <div className="product-img">
                                    <img className="img-block" src={`${productdetails.galleries?.[0].url}`} />
                                  </div>
                                  <div className="product-detail">
                                    <div className="product-heading">
                                      <h4 className="product-name">{productdetails.product_name}</h4>
                                    </div>
                                    <div className="product-desc">
                                      {productdetails.description}
                                    </div>
                                    <div className="product-desc size-qty">
                                      <span>Size:{productdetails.measurement_size_value}</span>
                                    </div>
                                    <div className="product-price">
                                      {/* <span className="current-price">₹{total_price - discount_on_mrp}</span> */}
                                      <span className="current-price">₹{productSalePrice}</span>
                                      <span className="off-price">₹{total_price}</span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <div className="download-doc">
                              <img src={DocIc} alt="" />
                              <span className="file-name">
                                Return & Exchange Policy
                              </span>
                              <Button
                                onClick={() => this.setState({ ReturnAndExchangePolicy: true })}
                                type="button"
                                variant="contained"
                                className="outline-btn"
                              >
                                View Policy
                              </Button>
                            </div>
                            <div className="order-info-blog">
                              <h4>Address for pickup</h4>
                              <div className="user-detail">
                                <p>
                                  <b>{this.state.showPickupAddress.attributes?.name}</b>
                                </p>
                                <p>
                                  {this.state.showPickupAddress.attributes?.address}, {this.state.showPickupAddress.attributes?.city}, {this.state.showPickupAddress.attributes?.state} - {this.state.showPickupAddress.attributes?.zip_code}
                                </p>
                              </div>
                              <div className="form-group">
                                <Button
                                  onClick={() => { this.setState({ changeAddressDialogBox: true }) }}
                                  variant="contained"
                                  className="outline-btn"
                                  type="button"
                                >
                                  Change Address
                                </Button>
                              </div>
                            </div>
                            <div className="order-info-blog">
                              <div className="form-group">
                                <RadioGroup aria-label="return" name="return">
                                  <FormLabel
                                    className="radio-label"
                                  // component="refund"
                                  >
                                    Choose mode of refund
                                  </FormLabel>
                                  <FormControlLabel
                                    checked={this.state.modeOfRefundForReturn == "refund_to_bank_account"}
                                    value="refund_to_bank_account"
                                    control={<Radio />}
                                    label="refund to bank account"
                                    onChange={(e: any) => this.setState({ modeOfRefundForReturn: e.target.value })}
                                  />
                                  <FormControlLabel
                                    checked={this.state.modeOfRefundForReturn == "add_in_loyalty_points"}
                                    value="add_in_loyalty_points"
                                    control={<Radio />}
                                    label="add in loyalty points"

                                    onChange={(e: any) => this.setState({ modeOfRefundForReturn: e.target.value })}
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                            <div className="order-info-blog">
                              <div className="form-group">
                                <FormGroup>
                                  <FormControlLabel
                                    onChange={() => { this.handleChangeReturnPermission() }}
                                    className="checkbox"
                                    control={<Checkbox checked={this.state.returnConfirmation == true} name="checkedB"

                                    />}
                                    label="I confirm that the product is unused."
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>

                          <div className="btn-wrapper">
                            <Button
                              variant="contained"
                              className="outline-btn"
                              type="button"
                            >
                              Cancel
                            </Button>
                            <Button
                              disabled={this.state.returnConfirmation == false}
                              // onClick = {()=>this.returnorder()}
                              onClick={() => {
                                this.returnorder(); console.log(
                                  "mode", this.state.modeOfRefundForReturn, "pickaddressid", this.state.pickupAddressId

                                )
                              }}
                              variant="contained"
                              className="black-btn"
                              type="button"
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
              {/* remove box  */}
              <Dialog
                open={this.state.changeAddressDialogBox}
                aria-labelledby="simple-dialog-title"
                fullWidth={true} maxWidth={"xs"}
              >
                <Box className="myBox">
                  <Box textAlign="right" p={2}>
                    <span className="close-btn">
                      <CloseIcon
                        onClick={() => this.handleCloseChangeAddressBox()}
                      ></CloseIcon>
                    </span>
                  </Box>
                  <Box className="modal-head" textAlign="center" fontWeight="600" p={1}>




                    <RadioGroup aria-label="select" name="address">
                      {/* {this.state.addressData.reverse().slice(0,3).map((address:any)=>  */}
                      {this.state.addressData.map((address: any) =>
                        <>

                          <div className="radio-btn">
                            <FormControlLabel
                              checked={this.state.pickupAddressId == address.id}
                              // value="female"
                              onChange={() => { this.setState({ pickupAddressId: address.id }); console.log(this.state.pickupAddressId) }}
                              // onChange = {()=> {this.setState({deliveryAddressId:address.id},() => console.log(this.state.deliveryAddressId));}}
                              value={address.id}
                              color="#000"
                              control={<Radio />}
                              label={
                                <Typography variant="caption">
                                  <h3>{address.attributes.name}</h3>
                                  <p>
                                    {address.attributes.address}
                                    {/* A-404 Next Car Park, opposite sarthak school,
                            behind Shree sai baba temple, Ghatlodia,
                            Ahmedabad, Gujarat - 380061 */}
                                  </p>
                                  <p>MO:{address.attributes.phone_number}</p>
                                  <span style={{
                                    border: "1px solid #000",
                                    padding: "2px 10px",
                                    borderRadius: "3px",
                                  }}>{address.attributes.save_address_as}</span>
                                </Typography>
                              }
                            />
                          </div>
                          <hr />
                        </>
                      )

                      }
                    </RadioGroup>
                  </Box>
                  <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">

                    <Button style={{ width: "100%" }} className="black-btn"

                      onClick={() => { this.showPickupAddress(); this.handleCloseChangeAddressBox() }}
                      size="medium"
                    >
                      Ok
                    </Button>
                  </Box>
                </Box>
              </Dialog>

            </div>}



        {/* return And Exchange Policy */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.ReturnAndExchangePolicy}
          className="select-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Return And Exchange Policy</span>

            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  ReturnAndExchangePolicy: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            {/* <h4 className="desc-title">Return Policy</h4> */}
            <p className="desc-content">Valid till {productPolicyDays || 10} days after delivered your items</p>
            <span dangerouslySetInnerHTML={{ __html: productdata?.return_policy?.description }} />

          </DialogContent>
        </Dialog>








      </>


    )
  }





  // state = {
  //   cancelOrder: false,
  //   rateReviews: false,
  //   exchangeItems: false,
  // };

  render() {



    return (
      <>
        <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend}>
          {this.renderReturnOrderPickup()}
        </CustomerAccountSideBarWeb>
      </>
    );
  }
}
export default withStyles(styles)(ReturnOrderPickup);
