import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { bagImage, personImage } from "./assets";
import { toast } from "react-toastify";
import { toastSuccessMessage } from "../../../components/src/toastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isOpen: boolean;
  callbackShopWithFriend: (isOpen: boolean) => void;
  // Customizable Area End
}

interface Member {
  name: string;
  role: string;
}

interface Group {
  id: string;
  type: string;
  attributes: GroupAttributes;
}

interface GroupAttributes {
  name: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isMinimize: boolean;

  isFirstPageOpen: boolean;
  isCreateGroupOpen: boolean;
  isCreatedGroupOpen: boolean;
  isCreatedAddPeopleOpen: boolean;
  isChatScreenOpen: boolean;
  isEditGroupOpen: boolean;
  isEditAddPeopleOpen: boolean;
  isWishlistPageOpen: boolean;
  isGroupInfoPageOpen: boolean;
  isExitGroupDialogOpen: boolean;

  groupName: string;
  createdGroupId: string;
  groupMembers: Array<Member>;
  groups: Array<Group>;
  isSettingOpen: boolean;
  chatGroupName: string;
  newChatGroupName: string;
  chatGroupId: string;
  groupCreatorId: string;
  isGroupNameEditable: boolean;

  searchMember: string;
  searchMembersList: any[];
  invitedMembersList: any[];

  wishlistList: any[];
  activeCartId: any;
  catalogId: any;
  catalogVariantId: any;

  message: any;
  messageFile: any;
  messageList: any[];


  messagePopup: boolean,
  cartCount: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserGroupsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  CreateGroupCallId: any;
  GroupMembersCallId: any;
  GroupsCallId: any;
  EditGroupNameCallId: any;
  DeleteGroupCallId: any;
  SearchMemberCallId: any;
  CreateInvitationCallId: any;
  ZoomMeetingCallId: any;
  ExitFromGroupCallId: any;
  RemoveFromGroupCallId: any;
  AcceptDeclineInvitationCallId: any;
  GroupWishlistCallId: any;
  RemoveWishlistCallId: any;
  AddToBagOrderCallId: any;
  ActiveCartCallId: any;
  GetChattingCallId: any;
  PostMessageChattingCallId: any;
  ShoppingCartDataCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isMinimize: false,

      isFirstPageOpen: true,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      isExitGroupDialogOpen: false,

      groups: [],

      groupName: "",
      createdGroupId: "",
      groupMembers: [],
      isSettingOpen: false,
      chatGroupName: "",
      newChatGroupName: "",
      chatGroupId: "",
      groupCreatorId: "",
      isGroupNameEditable: false,

      searchMember: "",
      searchMembersList: [],
      invitedMembersList: [],

      wishlistList: [],
      activeCartId: null,
      catalogId: null,
      catalogVariantId: null,

      message: "",
      messageFile: null,
      messageList: [],


      messagePopup: false,
      cartCount: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // Create Group
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CreateGroupCallId !== null &&
      this.CreateGroupCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CreateGroupCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState(
          {
            ...this.state,
            createdGroupId: responseJson.data.id,
          },
          () => {
            this.openCreatedPage();
            this.fetchGroupMembers(this.state.createdGroupId);
          }
        );
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Fetch Groups
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GroupsCallId !== null &&
      this.GroupsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GroupsCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          groups: responseJson.data,
          messageList: [],
        });
      } else {
        this.setState({
          ...this.state,
          groups: [],
          messageList: [],
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Fetch Group Member
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GroupMembersCallId !== null &&
      this.GroupMembersCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GroupMembersCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        groupMembers: responseJson,
      });

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Edit Group Name
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.EditGroupNameCallId !== null &&
      this.EditGroupNameCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.EditGroupNameCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          chatGroupName: responseJson.data.attributes.name,
          newChatGroupName: responseJson.data.attributes.name,
          isChatScreenOpen: true,
          isEditGroupOpen: false,
          isSettingOpen: false,
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Delete Group
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.DeleteGroupCallId !== null &&
      this.DeleteGroupCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.DeleteGroupCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson?.meta?.message) {
        toastSuccessMessage(responseJson?.meta?.message);
      }

      this.setState(
        {
          ...this.state,
          chatGroupId: "",
          chatGroupName: "",
          newChatGroupName: "",
          isSettingOpen: false,
        },
        () => {
          this.openFirstPage();
          this.fetchGroup();
        }
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Search Member
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SearchMemberCallId !== null &&
      this.SearchMemberCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.SearchMemberCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          searchMembersList: responseJson.data,
        }, () => this.forceUpdate());
      } else {
        this.setState({
          ...this.state,
          searchMembersList: [],
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Create Invitation
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.CreateInvitationCallId !== null &&
      this.CreateInvitationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.CreateInvitationCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson?.meta?.message) {
        toast.success(responseJson?.meta?.message, {
          position: "top-right",
          delay: 1000
        });
      } else if (responseJson.errors) {
        toast.error(responseJson.errors, {
          position: "top-right",
        });
      }

      this.searchMember();

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Handle Zoom Call API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ZoomMeetingCallId !== null &&
      this.ZoomMeetingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ZoomMeetingCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.error) {
        toast.error(responseJson.error, {
          position: "top-right",
        });
      }
      if (responseJson.data) {
        if (this.state.groupCreatorId === localStorage.getItem("userId")) {
          window.open(responseJson.data.attributes.start_url, "_blank");
        } else {
          window.open(responseJson.data.attributes.meeting_link, "_blank");
        }
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Accept or Reject Group Invitation API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AcceptDeclineInvitationCallId !== null &&
      this.AcceptDeclineInvitationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AcceptDeclineInvitationCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.fetchGroup();
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Exit From Group API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ExitFromGroupCallId !== null &&
      this.ExitFromGroupCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AcceptDeclineInvitationCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState(
        {
          ...this.state,
          isExitGroupDialogOpen: false,
        },
        () => {
          this.fetchGroup();
          this.openFirstPage();
        }
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Remove Member from Group API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveFromGroupCallId !== null &&
      this.RemoveFromGroupCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveFromGroupCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson?.message) {
        toastSuccessMessage(responseJson?.message);
      }
      if (responseJson) {
        this.fetchGroupMembers(this.state.chatGroupId);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Fetch Group Wishlist  API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GroupWishlistCallId !== null &&
      this.GroupWishlistCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GroupWishlistCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          wishlistList: responseJson.data,
        });
      } else {
        this.setState({
          ...this.state,
          wishlistList: [],
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Remove Catalog from Group WishList API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveWishlistCallId !== null &&
      this.RemoveWishlistCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveWishlistCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.fetchWishList();
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Add to bag - Group Shopping
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ActiveCartCallId !== null &&
      this.ActiveCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ActiveCartCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {

        this.setState(
          { ...this.state, activeCartId: responseJson.data.id },
          () => {
            this.addToBagOrder();
          }
        );
      } else {
        this.setState({ ...this.state, activeCartId: null }, () => {
          this.addToBagOrder();
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AddToBagOrderCallId !== null &&
      this.AddToBagOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AddToBagOrderCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {

        console.log(location.pathname, "pathname")
        if (location.pathname == "/ShoppingBag") { location.reload() }

        toast.success("Successfully added in your bag", {
          position: "top-right",
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Chatting - GET
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GetChattingCallId !== null &&
      this.GetChattingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GetChattingCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState(
          {
            ...this.state,
            messageList: responseJson.data.reverse(),
          },
          () => this.scrollToBottom()
        );
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Chatting - POST
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PostMessageChattingCallId !== null &&
      this.PostMessageChattingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PostMessageChattingCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState(
        {
          ...this.state,
          message: "",
          messageFile: null,
        },
        () => {
          this.getChatting();
        }
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }


    // cart items count start 
    // shopping cart data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ShoppingCartDataCallId !== null &&
      this.ShoppingCartDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ShoppingCartDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        // this.setState({ ShoppingCartData: responseJson.data }, () => {
        const cartCount: any = responseJson.data.length
        this.setState({ cartCount: cartCount })

      };
      // this.setState({ shoppingCartloading: false });
      // console.log('////////////// ShoppingCartData data------------------------------------------------------', this.state.ShoppingCartData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // shopping cart data end
    // Customizable Area End
  }

  timer: any;
  messageList: any;
  upload: any;
  images = {
    bagImage,
    personImage,
  };

  scrollToBottom = () => {
    const scrollHeight = this.messageList?.scrollHeight;
    if (scrollHeight) {
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
  };

  onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    this.setState({
      ...this.state,
      messageFile: file,
    });
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
      if (localStorage.getItem("authToken")) {
        localStorage.removeItem("groupId");
        this.fetchGroup();
        this.shoppingCartData()
        console.log(location.pathname, "pathname")
      }

      this.timer = setInterval(() => {
        if (this.state.isChatScreenOpen) {
          this.getChatting();
        }
      }, 5000);
    } catch (error) { }


  }

  async componentWillUnmount() {
    clearInterval(this.timer);
  }

  // Chatting - Get
  getChatting = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetChattingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chattingAPIEndPoint}/${this.state.chatGroupId}/get_messages`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Chatting - Post
  postMassage = () => {
    // const body = {
    //   text: this.state.message,
    // };

    let formData = new FormData();
    formData.append('text', this.state.message.toString());
    formData.append('images[]', this.state.messageFile);

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PostMessageChattingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chattingAPIEndPoint}/${this.state.chatGroupId}/send_message`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handle Search Member
  handleSearchMember(name: string) {
    this.setState({
      ...this.state,
      searchMember: name,
    });
  }

  // Search Member API
  searchMember = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };
    
    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id  = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SearchMemberCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchMemberAPIEndPoint + `?search=${this.state.searchMember}&group_id=${group_id}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Edit Group Name API
  handleEditGroupName = () => {
    const body = {
      group: {
        name: this.state.newChatGroupName,
      },
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.EditGroupNameCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editGroupNameAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Delete Group API
  handleDeleteGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteGroupNameAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // cart items count start

  shoppingCartData() {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ShoppingCartDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ShoppingCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;



  }

  // cart items count end

  // Group Listing API
  fetchGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchGroupsAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Fetch Group Member
  fetchGroupMembers(id: string) {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id  = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupMembersCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchGroupMemberAPIEndPoint + group_id
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Exit From Group API
  exitFromGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group_id: Number(this.state.chatGroupId),
      member_id: Number(localStorage.getItem("userId")),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ExitFromGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exitFromGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Remove From Group API
  removeFromGroup = (id: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id  = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const body = {
      group_id: Number(group_id),
      member_id: id,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveFromGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exitFromGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Group - Handle Group Name
  handleChangeGroupName(name: string) {
    this.setState({
      ...this.state,
      groupName: name,
    });
  }

  // Edit Group - Handle Group Name
  handleChangeEditGroupName(name: string) {
    this.setState({
      ...this.state,
      newChatGroupName: name,
    });
  }

  // Create Group API
  handleCreateGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group: {
        name: this.state.groupName,
      },
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Toggle Settings
  handleToggleSetting() {
    this.setState({
      ...this.state,
      isSettingOpen: !this.state.isSettingOpen,
    });
  }

  // Back From Second Page
  backFromSecondPage() {
    this.setState({
      groupName: "",
      createdGroupId: "",
      groupMembers: [],
    });
  }

  backWithoutSave = () => {
    this.setState({
      ...this.state,
      newChatGroupName: this.state.chatGroupName,
      isChatScreenOpen: true,
      isEditGroupOpen: false,
      isSettingOpen: false,
      isWishlistPageOpen: false,
    });
  };

  // Invite User to join Group
  createInvitation = (gId: string, uId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id  = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const body = {
      group_shopping_id: group_id,
      invited_user_id: uId,
    };

    // this.setState({
    //   ...this.state,
    //   invitedMembersList: [...this.state.invitedMembersList, uId],
    // });

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateInvitationCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createInvitationAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Fetch Group Wishlist API
  fetchWishList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupWishlistCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupWishListAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Remove Catalog from Group WishList API
  removeWishlist = (cid: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group_shopping_id: Number(this.state.chatGroupId),
      catalogue_id: Number(cid),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveWishlistCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeWishListAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Accept / Decline to join Group
  acceptDeclineInvitation = (status: string, id: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      status: status,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AcceptDeclineInvitationCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptDeclineInvitationGroupAPIEndPoint
      }/${id}/accept_reject_invite`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Add to Bag API
  addToBagOrder = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const catalogue_variant_id = this.state.catalogVariantId || null;

    // const httpBody = {
    //   quantity: 1,
    //   catalogue_id: Number(this.state.catalogId),
    //   cart_id: this.state.activeCartId ? Number(this.state.activeCartId) : null,
    //   catalogue_variant_id: Number(catalogue_variant_id)
    // };


    const httpBody =
      localStorage.getItem("exchangeFlow") ? {
        quantity: 1,
        catalogue_id: Number(this.state.catalogId),
        // catalogue_variant_id: catvariantid ? catvariantid : null,
        catalogue_variant_id: Number(catalogue_variant_id),
        type: "exchange", //#use this when you will create the new exchange order.
        order_item_id: localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
      } : {
        quantity: 1,
        catalogue_id: Number(this.state.catalogId),
        cart_id: this.state.activeCartId ? Number(this.state.activeCartId) : null,
        catalogue_variant_id: Number(catalogue_variant_id)
      }




    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.AddToBagOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToBagOrderAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  activeCartId = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ActiveCartCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activeCartAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // logo Name
  logoName = (name: string) => {
    let first = name.split(' ').slice(0, 2).join(' ');
    let logoName = first.split(' ').map((word: any) => word[0]).join('');
    return logoName;
  }

  // Random color
  getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Enable Edit Group Name
  enableEditGroupName() {
    this.setState({
      ...this.state,
      isGroupNameEditable: true,
    });
  }

  handleGroup = (group: Group) => {
    this.setState(
      {
        ...this.state,
        chatGroupId: group.id,
        chatGroupName: group.attributes.name,
        newChatGroupName: group.attributes.name,
        groupCreatorId: "" + group.attributes.account_id,
        searchMember: "",
        searchMembersList: [],
        invitedMembersList: [],
        message: "",
        messageFile: null,
      },
      () => {
        this.openChatPage();
        this.getChatting();
        // window.scrollTo(0, );
      }
    );
  };

  // Handle Zoom Call API
  handleZoomCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    let zoomAPIEndPoint: string = "";
    if (this.state.groupCreatorId === localStorage.getItem("userId")) {
      zoomAPIEndPoint = configJSON.zoomAdminMeetingAPIEndPoint;
    } else {
      zoomAPIEndPoint = configJSON.zoomParticipantMeetingAPIEndPoint;
    }

    const body = {
      group_owner_id: this.state.groupCreatorId,
      group_shopping_id: this.state.chatGroupId,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ZoomMeetingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      zoomAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handle Page
  openFirstPage = () => {
    this.setState({
      ...this.state,
      isFirstPageOpen: true,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openCreatePage = () => {
    this.setState({
      ...this.state,
      isCreateGroupOpen: true,
      isFirstPageOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openChatPage = () => {
    this.setState({
      ...this.state,
      // chatGroupId: group.id,
      // chatGroupName: group.attributes.name,
      // newChatGroupName: group.attributes.name,
      // searchMember: "",
      // searchMembersList: [],
      // invitedMembersList: [],
      isGroupNameEditable: false,
      isSettingOpen: false,
      isChatScreenOpen: true,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openCreatedPage = () => {
    this.setState({
      ...this.state,
      isCreateGroupOpen: false,
      isFirstPageOpen: false,
      isCreatedGroupOpen: true,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      searchMember: '',
      searchMembersList: []
    });
  };

  openEditPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: true,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      searchMember: '',
      searchMembersList: []
    });
  };

  openCreatedAddPeoplePage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: true,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openEditAddPeoplePage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isEditAddPeopleOpen: true,
      isGroupInfoPageOpen: false,
    });
  };

  openWishlistPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: true,
      isGroupInfoPageOpen: false,
    });
  };

  openGroupInfoPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: true,
    });
  };

  handleDialog = () => {
    this.setState({
      ...this.state,
      isExitGroupDialogOpen: !this.state.isExitGroupDialogOpen,
    });
  };

  // Remove State
  removeState = () => {
    this.setState(
      {
        ...this.state,
        isMinimize: false,
        groupName: "",
        createdGroupId: "",
        groupMembers: [],
        isSettingOpen: false,
        chatGroupName: "",
        newChatGroupName: "",
        chatGroupId: "",
        groupCreatorId: "",
        isGroupNameEditable: false,

        searchMember: "",
        searchMembersList: [],
        invitedMembersList: [],

        wishlistList: [],
      },
      () => {
        this.openFirstPage();
      }
    );
  };


  handleOpenMessagePopup = () => {
    this.setState({ messagePopup: true })
  };

  handleCloseMessagePopup = () => {
    this.setState({ messagePopup: false })
  };


  // Customizable Area End
}
