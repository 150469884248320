export const TopNavStyle = {
  root: {
    "& *": {
      boxSizing: "border-box",
    },
    "& header": {
      boxShadow: "0 5px 8px -4px #8080809e",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      padding: "0 20px",
      justifyContent: "space-between",
      position: "relative",
      "@media (max-width:991px)": {
        padding: "20px",
      },
    },
    "& .left-header": {
      flex: "180px",
      maxWidth: "180px",
      "@media (max-width:1200px)": {
        flex: "150px",
        maxWidth: "150px",
      },
    },
    "& .left-header .logo": {
      display: "inline-block",
      marginRight: "20px",
      maxWidth: "100%",
    },
    "& .left-header .logo img": {
      maxWidth: "100%",
    },
    "& .right-header": {
      flex: "0 0 calc(100% - 180px)",
      maxWidth: "calc(100% - 180px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "20px",
      // flexWrap: "wrap",
      "@media (max-width:1200px)": {
        flex: "0 0 calc(100% - 150px)",
        maxWidth: "calc(100% - 150px)",
        paddingLeft: "10px",
      },
      "@media (max-width:991px)": {
        position: "fixed",
        width: "350px",
        top: "0",
        right: "-350px",
        bottom: "0",
        height: "100vh",
        padding: "70px 20px 30px",
        backgroundColor: "#fff",
        zIndex: "10000",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        boxShadow: "-2px 2px 5px -2px rgba(153,153,153,0.3)",
        transition: "all 0.5s",
        flexWrap: "nowrap",
        maxWidth: "350px",
        flex: "none",
      },
      "@media (max-width:575px)": {
        maxWidth: "calc(100% - 20px)",
      },
      "@media (max-width:991px) and (orientation:landscape)": {
        padding: "50px 20px 15px",
      },
    },
    "& .right-header .close-ic": {
      display: "none",
      position: "absolute",
      top: "10px",
      right: "15px",
      padding: "5px",

      "@media (max-width:991px)": {
        display: "block",
      },
    },
    "& .right-header .more-link-block": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "10px",
      "@media (max-width:991px)": {
        order: "3",
        width: "100%",
      },
    },
    "& .right-header .more-link-block .link-wrapper": {
      marginRight: "30px",
      cursor: "pointer",
      "@media (max-width:1200px)": {
        marginRight: "0",
        padding: "0 10px",
      },
    },
    "& .right-header .more-link-block .link-wrapper .MuiButton-text": {
      padding: "0px",
    },
    "& .right-header .more-link-block .link-wrapper .MuiBadge-badge": {
      top: "5px",
      right: "5px",
    },
    "& .right-header .more-link-block .link-wrapper .szh-menu li": {
      fontSize: "14px",
      color: "#212121",
    },
    "& .right-header .more-link-block .link-wrapper:last-child": {
      marginRight: "0",
    },
    "& .right-header .more-link-block .menu-ic": {
      height: "18px",
      width: "18px",
      display: "block",
      margin: "0 auto 10px",
    },
    "& .right-header .more-link-block .menu-ic img": {
      maxWidth: "100%",
    },
    "& .right-header .more-link-block span": {
      display: "block",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "14px",
    },
    "& .right-header .header-menu-list": {
      paddingRight: "10px",
      display: "flex",
      "@media (max-width:991px)": {
        order: "2",
        flexDirection: "column",
        width: "100%",
        overflowY: "auto",
        margin: "0 0 20px 0",
        padding: "0",
      },
    },
    "& .header-menu-list > li": {
      display: "flex",
      flexWrap: "wrap",
      "@media (max-width:991px)": {
        margin: "0 0 20px",
      },
    },
    "& .right-header .header-menu-list li > a": {
      "@media (max-width:991px)": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    "& .right-header .header-menu-list li > a > span > svg": {
      width: "15px",
      height: "15px",
      display: "none",
      "@media (max-width:991px)": {
        display: "block",
      },
    },
    "& .right-header .header-menu-list li > a > span": {
      "@media (max-width:991px)": {
        display: "block",
      },
    },
    "& .header-menu-list > li:last-child": {
      "@media (max-width:991px)": {
        margin: "0 0 0px",
      },
    },
    "& .header-menu-list > li:hover > a": {
      backgroundColor: "#000",
      color: "#fff !important",
      "@media (max-width:991px)": {
        backgroundColor: "#fff !important",
        color: "#000 !important",
      },
    },
    "& .header-menu-list > li > .submenu": {
      position: "absolute",
      top: "69px",
      left: "0",
      right: "0",
      background: "#2d2d2d",
      display: " none",
      zIndex: "9",
      "@media (max-width:991px)": {
        position: "fixed",
        width: "350px",
        top: "0",
        left: "auto",
        right: "-350px",
        bottom: "0",
        height: "100vh",
        padding: "20px 20px 30px",
        margin: "50px 0 0",
        backgroundColor: "#fff",
        zIndex: "100",
        boxShadow: "-2px 2px 5px -2px rgba(153,153,153,0.3)",
        transition: "all 0.5s",
        flexWrap: "nowrap",
        // maxWidth: "350px",
        maxWidth: "calc(100% - 20px)"
      },
    },
    "& .header-menu-list > li > .submenu > ul": {
      display: "flex",
      margin: "0",
      padding: "0 20px",
      overflowY: "auto",
      "@media (max-width:991px)": {
        flexWrap: "wrap",
        padding: "0",
        maxHeight: "calc(100vh - 110px)",
      },
    },
    "& .header-menu-list > li > .submenu > ul > li": {
      "@media (max-width:991px)": {
        margin: "0 0 10px",
        width: "100%",
      },
    },
    "& .header-menu-list > li > .submenu > ul > li > a": {
      textTransform: "capitalize",
      padding: "16px 10px",
      color: "#fff",
      background: "#2d2d2d",
      fontSize: "14px",
      fontWeight: "400",
      display: "block",
      textDecoration: "none",
      whiteSpace: "nowrap",
      "@media (max-width:991px)": {
        background: "transparent !important",
        color: "#212121",
        padding: "0",
      },
    },
    "& .header-menu-list > li > .submenu > ul > li:hover > a": {
      background: "#f7f7f7",
      color: "#212121",
    },
    "& .header-menu-list > li:hover > .submenu": {
      display: "block",
    },
    "& .header-menu-list > li > .submenu > ul li:hover > .mega-sub-menu": {
      display: "block",
    },
    "& .header-menu-list > li.active > .submenu": {
      "@media (max-width:991px)": {
        display: "block",
        right: "0",
      },
    },
    "& .header-menu-list > li > .submenu > ul > li.active > .mega-sub-menu": {
      "@media (max-width:991px)": {
        right: "0",
        display: "block",
      },
    },
    "& .header-menu-list .back": {
      display: "none",
      color: "#212121",
      fontSize: "14px",
      textDecoration: "none",
      alignItems: "center",
      margin: "0 0 15px",
      fontWeight: "600",
      "@media (max-width:991px)": {
        display: "flex",
      },
    },
    "& .header-menu-list .back span": {
      display: "flex",
    },
    "& .header-menu-list .back svg": {
      height: "15px",
      width: "15px",
    },
    "& .right-header > .header-menu-list > li > a": {
      fontSize: "15px",
      fontWeight: "500",
      color: "#000000",
      textTransform: "uppercase",
      // color: '#000',
      textDecoration: "none",
      padding: "25px 15px",
      transition: "all 0.3s",
      "@media (max-width:1200px)": {
        fontSize: "14px",
        padding: "25px 10px",
      },
      "@media (max-width:991px)": {
        padding: "0",
      },
    },
    "& .right-header .header-menu-list > li > a:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
    "& .right-header .header-menu-list li > a:last-child": {
      marginRight: "0",
      "@media (max-width:991px)": {
        margin: "0",
      },
    },
    "& .right-header .search-wrapper": {
      padding: "0 10px",
      flex: "0 0 35%",
      maxWidth: "35%",
      "@media (max-width:1200px)": {
        flex: "0 0 30%",
        maxWidth: "30%",
      },
      "@media (max-width:1024px)": {
        flex: "1",
      },
      "@media (max-width:991px)": {
        width: "100%",
        flex: "none",
        maxWidth: "100%",
        order: "1",
        padding: "0",
        margin: "0 0 20px",
      },
    },
    "& .right-header .search-wrapper .form-group": {
      position: "relative",
      margin: "0",
    },
    "& .right-header .search-wrapper .form-group .search-ic": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "11px",
    },
    "& .right-header .search-wrapper .form-group input": {
      height: "36px",
      padding: "5px 5px 5px 35px",
      borderRadius: "5px",
      backgroundColor: "#eeeeee",
      border: "none",
      boxShadow: "none",
      width: "100%",
      maxWidth: "100%",
    },
    "& .right-header .search-wrapper .form-group input:focus": {
      border: "none",
    },
    "& .hamburger-menu": {
      display: "none",
      cursor: "pointer",
      "@media (max-width:991px)": {
        display: "block",
      },
    },
    "& .mega-sub-menu": {
      position: "absolute",
      background: "#fff",
      padding: "20px 0",
      left: "20px",
      right: "20px",
      top: "100%",
      boxShadow:
        "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 46px 46px 8px rgb(0 0 0 / 12%)",
      zIndex: "9",
      display: "none",
      "@media(max-width:1199px)": {
        padding: "20px 0",
      },
      "@media(max-width:991px)": {
        position: "fixed",
        width: "350px",
        top: "0",
        left: "auto",
        right: "-350px",
        bottom: "0",
        height: "100vh",
        padding: "20px 20px 30px",
        margin: "50px 0 0",
        backgroundColor: "#fff",
        zIndex: "100",
        boxShadow: "-2px 2px 5px -2px rgba(153,153,153,0.3)",
        transition: "all 0.5s",
        flexWrap: "nowrap",
        // maxWidth: "350px",
        maxWidth: "calc(100% - 20px)"
      },
    },
    "& .mega-sub-menu .content-wrapper": {
      display: "flex",
      flexWrap: "wrap",
      "@media(max-width:991px)": {
        height: "calc(100vh - 100px)",
        overflowY: "auto",
      },
    },
    "& .mega-sub-menu .block-wrapper": {
      flex: "0 0 24%",
      maxWidth: "24%",
      padding: "0 30px",
      borderRight: "1px solid #ccc",
      "@media(max-width:1366px)": {
        padding: "0 20px",
      },
      "@media(max-width:991px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "20px 0px",
        borderBottom: "1px solid #ccc",
        borderRight: "none",
      },
    },
    "& .mega-sub-menu .block-wrapper:first-child": {
      flex: "0 0 18%",
      maxWidth: "18%",
      "@media(max-width:1200px)": {
        flex: "0 0 22%",
        maxWidth: "22%",
      },
      "@media(max-width:991px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .mega-sub-menu .block-wrapper:last-child": {
      flex: "0 0 34%",
      maxWidth: "34%",
      border: "none",
      padding: "0 20px",
      "@media(max-width:1200px)": {
        flex: "0 0 30%",
        maxWidth: "30%",
      },
      "@media(max-width:991px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "20px 0px",
      },
    },
    "& .mega-sub-menu .block-wrapper img": {
      width: "100%",
      height: "100%",
    },
    "& .mega-sub-menu a": {
      textTransform: "capitalize",
    },
    "& .mega-sub-menu .content-wrapper .heading": {
      color: "#121212",
      fontSize: "14px",
      lineHeight: "16px",
      textDecoration: "underline",
      textTransform: "uppercase",
      margin: "6px 0 27px",
      "@media (max-width:991px)": {
        margin: "0px 0 15px",
      },
    },
    "& .mega-sub-menu ul li a": {
      color: "#212121",
      fontSize: "14px",
      lineHeight: "20px",
      textDecoration: "none",
      display: "inline-block",
      marginBottom: "5px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "@media (max-width:991px)": {
        margin: "0px 0 10px !important",
      },
    },
    "& .mega-sub-menu .bind-link ul li a": {
      width: "200px",
    },
    "& .mega-sub-menu .block-wrapper .img-outer": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    "& .mega-sub-menu .block-wrapper .img-outer li": {
      display: "flex",
      flexDirection: "column",
      marginRight: "40px",
      "@media(max-width:1199px)": {
        marginRight: "20px",
      },
    },
    "& .mega-sub-menu .block-wrapper .img-outer li:nth-child(2n)": {
      "@media(max-width:1199px)": {
        marginRight: "0",
      },
    },
    "& .mega-sub-menu .block-wrapper .img-outer li span": {
      color: "#212121",
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
      marginBottom: "15px",
    },
    "& .mega-sub-menu .block-wrapper .img-outer img": {
      height: "80px",
      width: "80px",
      borderRadius: "100%",
    },
    "& .mega-sub-menu .block-wrapper .big-img-block": {
      maxHeight: "300px",
      objectFit: "cover",
    },
    "& .mega-sub-menu .block-wrapper .category-block img": {
      height: "50px",
      width: "50px",
      borderRadius: "100%",
      marginRight: "10px",
    },
    "& .mega-sub-menu .block-wrapper .category-block li a": {
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
    },
  },
  secondaryheader: {
    zIndex: "100",
    "& *": {
      boxSizing: "border-box",
    },
    "& header": {
      boxShadow:
        "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      display: "flex",
      padding: "20px",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    "& .left-header .logo": {
      display: "inline-block",
      marginRight: "0px",
      maxWidth: "163px",
    },
    "& .nav-list .header-menu-list a": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      color: "#121212",
      padding: "0 20px",
      textDecoration: "none",
    },
    "& .hamburger-menu": {
      display: "block",
      cursor: "pointer",
    },
    "& .nav-list .header-menu-list a:hover": {
      fontWeight: "600",
    },
    "& .nav-list .header-menu-list a.active": {
      fontWeight: "600",
    },
    "& .right-header": {
      position: "fixed",
      width: "350px",
      top: "0",
      right: "-350px",
      bottom: "0",
      height: "100vh",
      padding: "15px 12px 15px",
      backgroundColor: "#fff",
      zIndex: "10000",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      boxShadow: "-2px 2px 5px -2px rgba(153,153,153,0.3)",
      transition: "all 0.5s",
      flexWrap: "nowrap",
      maxWidth: "350px",
      flex: "none",
      overflowY: "auto",
      "@media (max-width:575px)": {
        maxWidth: "calc(100% - 20px)",
      },
    },
    "& .right-header .close-ic": {
      display: "block",
      position: "absolute",
      top: "10px",
      right: "15px",
      padding: "5px",
      cursor: "pointer",
    },
    "& .user-profile": {
      padding: "14px 17px 21px",
      borderBottom: "1px solid #e6e6e6",
    },
    "& .user-profile h4": {
      fontSize: "24px",
      fontWeight: "600",
      color: "#121212",
      margin: "0 0 5px",
    },
    "& .user-profile a": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#121212",
      textDecoration: "none",
    },
    "& .vendor-menu-link ul": {
      padding: "20px 0 9px",
      margin: "0",
      borderBottom: "1px solid #e6e6e6",
    },
    "& .vendor-menu-link ul li": {
      margin: "0 0 10px",
    },
    "& .vendor-menu-link ul li a": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "500",
      color: "#212121",
      textDecoration: "none",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 0px 10px 17px",
    },
    "& .vendor-menu-link ul li a:after": {
      content: " '' ",
      position: "absolute",
      left: "-12px",
      height: "100%",
      width: "3px",
      background: "#212121",
      opacity: "0",
    },
    "& .vendor-menu-link ul li a svg": {
      width: "15px",
      height: "15px",
    },
    "& .vendor-menu-link ul li.active a": {
      fontWeight: "600",
    },
    "& .vendor-menu-link ul li:hover a": {
      fontWeight: "600",
    },
    "& .vendor-menu-link ul li.active a:after": {
      opacity: "1",
    },
    "& .vendor-menu-link ul li:hover a:after": {
      opacity: "1",
    },
    "& .btn-wrapper": {
      padding: "30px 18px",
    },
    "& .btn-wrapper .black-btn": {
      width: "100%",
    },
  },
};
