import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import querystring from "query-string";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageName: string;
  description: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PolicyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  PolicyCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageName: "",
      description: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PolicyCallID !== null &&
      this.PolicyCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PolicyCallID = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          pageName: responseJson.data.attributes.page_detail,
          description: responseJson.data.attributes.description,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const param = querystring.parse(window.location.search);

    let productParam;
    if (param.policy === "term-use") {
      productParam = configJSON.TermUseAPIEndPoint;
    } else if (param.policy === "privacy-policy") {
      productParam = configJSON.PrivacyPolicyAPIEndPoint;
    } else if (param.policy === "shipping-policy") {
      productParam = configJSON.ShippingPolicyAPIEndPoint;
    } else if (param.policy === "return-exchange-policy") {
      productParam = configJSON.ReturnExchangePolicyAPIEndPoint;
    }

    if (productParam.length > 0) {
      this.getPolicyData(productParam);
    }
  }

  getPolicyData = (ApiEndPoint: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PolicyCallID = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      ApiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };
  // Customizable Area End
}
