import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface Menu {
  value: number;
  label: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isProductModalOpen: boolean;
  isExProductModalOpen: boolean;

  couponCode: string;
  description: string;

  selectedType: string;
  amount: string;
  expiryDate: any;

  minimumSpend: any;
  maximumSpend: any;
  // individualUse: boolean;
  excludeSaleItem: boolean;

  usageLimitPerCoupon: string;
  usageLimitPerCustomer: string;

  products: any[];
  eProducts: any[];
  selectedProducts: any[];
  selectedExProducts: any[];

  categories: any;
  categoryMenuView: any[];
  selectedCategory: any[];
  selectedExCategory: any[];

  subCategories: any[];
  subCategoryMenuView: any[];
  selectedSubCategory: any[];

  exSubCategories: any[];
  exSubCategoryMenuView: any[];
  selectedExSubCategory: any[];

  errors: any;

  couponCategory: any[];
  couponExCategory: any[];
  couponSubCategory: any[];
  couponExSubCategory: any[];

  currentCPage: number;
  totalCPage: number;
  currentECPage: number;
  totalECPage: number;

  searchProduct: string;
  searchExProduct: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditCouponController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCouponAPICallId: any;

  getProductListApiCallId: any;
  getExProductListApiCallId: any;
  getCategoryListApiCallId: any;
  getSubCategoryListApiCallId: any;
  getExSubCategoryListApiCallId: any;
  EditCouponAPICallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // txtInputValue: "",
      // txtSavedValue: "A",
      // enableField: false,
      // Customizable Area Start
      loading: true,
      isProductModalOpen: false,
      isExProductModalOpen: false,

      couponCode: "",
      description: "",

      selectedType: "cash_discount",
      expiryDate: "",
      amount: "",

      minimumSpend: "",
      maximumSpend: "",
      // individualUse: false,
      excludeSaleItem: false,

      usageLimitPerCoupon: "",
      usageLimitPerCustomer: "",

      products: [],
      eProducts: [],
      selectedProducts: [],
      selectedExProducts: [],

      categories: [],
      categoryMenuView: [],
      selectedCategory: [],
      selectedExCategory: [],

      subCategories: [],
      subCategoryMenuView: [],
      selectedSubCategory: [],

      exSubCategories: [],
      exSubCategoryMenuView: [],
      selectedExSubCategory: [],

      errors: {},

      couponCategory: [],
      couponExCategory: [],
      couponSubCategory: [],
      couponExSubCategory: [],

      currentCPage: 1,
      currentECPage: 1,
      totalCPage: 1,
      totalECPage: 1,

      searchProduct: "",
      searchExProduct: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    // Get Coupon Detail
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCouponAPICallId !== null &&
      this.getCouponAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getCouponAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        const coupon = responseJson.data;
        this.setState({
          ...this.state,
          loading: false,
          couponCode: coupon.attributes.coupon_code,
          description: coupon.attributes.discription,

          selectedType: coupon.attributes.coupon_type,
          expiryDate: moment(coupon.attributes.expiry_date).format(
            "yyyy-MM-DD"
          ),
          amount:
            coupon.attributes.coupon_type === "cash_discount"
              ? Math.trunc(coupon.attributes.amount).toString()
              : Math.trunc(coupon.attributes.amount.slice(0, -1)).toString(),

          minimumSpend: Math.trunc(coupon.attributes.minimum_spend).toString(),
          maximumSpend: Math.trunc(coupon.attributes.maximum_spend).toString(),
          // individualUse: coupon.attributes.individual_use_only,
          excludeSaleItem: coupon.attributes.exclude_sale_item,

          usageLimitPerCoupon: coupon.attributes.usage_limit_per_coupon,
          usageLimitPerCustomer: coupon.attributes.usage_limit_per_customer,

          selectedProducts: coupon.attributes.catalogue_ids,
          selectedExProducts: coupon.attributes.exclude_catalogue_ids,

          couponCategory: coupon.attributes.categories_id,
          couponExCategory: coupon.attributes.exclude_categories_id,
          couponSubCategory: coupon.attributes.sub_categories,
          couponExSubCategory: coupon.attributes.exclude_sub_categories,
        });
        this.getAllCategory();
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }

      // this.setState({ products: responseJson.data });

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Get All Product
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductListApiCallId !== null &&
      this.getProductListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getProductListApiCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          products: responseJson.data,
          currentCPage: responseJson.meta ? responseJson.meta.current_page : 1,
          totalCPage: responseJson.meta ? responseJson.meta.total_pages : 1,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Get All Ex Product
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getExProductListApiCallId !== null &&
      this.getExProductListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getExProductListApiCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          eProducts: responseJson.data,
          currentECPage: responseJson.meta ? responseJson.meta.current_page : 1,
          totalECPage: responseJson.meta ? responseJson.meta.total_pages : 1,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Get Category
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoryListApiCallId !== null &&
      this.getCategoryListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getCategoryListApiCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({ ...this.state, categories: responseJson.data }, () => {
          if (this.state.couponCategory.length > 0) {
            const presentedCoupon: any[] = [];
            this.state.categories.map((item: any) => {
              let presentCoupon = this.state.couponCategory.find(
                (id: number) => id === item.attributes.id
              );

              if (presentCoupon) {
                presentedCoupon.push({
                  label: item.attributes.name,
                  value: item.attributes.id,
                });
              }
            });
            this.setState(
              {
                // ...this.state,
                selectedCategory: presentedCoupon,
              },
              () => {
                this.getSubCategory();
              }
            );
          }
          if (this.state.couponExCategory.length > 0) {
            const presentedExCoupon: any[] = [];
            this.state.categories.map((item: any) => {
              const presentCoupon = this.state.couponExCategory.find(
                (id: number) => id === item.attributes.id
              );
              if (presentCoupon) {
                presentedExCoupon.push({
                  label: item.attributes.name,
                  value: item.attributes.id,
                });
              }
            });
            this.setState(
              {
                // ...this.state,
                selectedExCategory: presentedExCoupon,
              },
              () => {
                this.getExSubCategory();
              }
            );
          }
          this.updateCategory();
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Get Sub Category
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubCategoryListApiCallId !== null &&
      this.getSubCategoryListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getSubCategoryListApiCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState(
          { ...this.state, subCategories: responseJson.data },
          () => {
            if (this.state.couponSubCategory.length > 0) {
              const presentedCoupon: any[] = [];

              this.state.subCategories.map((item: any) => {
                const presentCoupon = this.state.couponSubCategory.find(
                  (id: number) => id === item.attributes.id
                );
                if (presentCoupon) {
                  presentedCoupon.push({
                    label: item.attributes.name,
                    value: item.attributes.id,
                  });
                }
              });
              this.setState({
                ...this.state,
                selectedSubCategory: presentedCoupon,
              });
            }
            this.updateSubCategory();
          }
        );
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Get Ex Sub Category
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getExSubCategoryListApiCallId !== null &&
      this.getExSubCategoryListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getExSubCategoryListApiCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState(
          { ...this.state, exSubCategories: responseJson.data },
          () => {
            if (this.state.couponExSubCategory.length > 0) {
              const presentedCoupon: any[] = [];

              this.state.exSubCategories.map((item: any) => {
                const presentCoupon = this.state.couponExSubCategory.find(
                  (id: number) => id === item.attributes.id
                );
                if (presentCoupon) {
                  presentedCoupon.push({
                    label: item.attributes.name,
                    value: item.attributes.id,
                  });
                }
              });
              this.setState({
                ...this.state,
                selectedExSubCategory: presentedCoupon,
              });
            }
            this.updateExSubCategory();
          }
        );
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Edit Coupon
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.EditCouponAPICallId !== null &&
      this.EditCouponAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.EditCouponAPICallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        toast.success("Coupon Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
        });
        this.props.navigation.navigate("DiscountCoupons");
      } else {
        toast.error("Please enter different coupon code", {
          position: "top-right",
          autoClose: 5000,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await this.getAllProduct();
    await this.getAllExProduct();
    // await this.getAllCategory();

    const couponId = this.props.navigation.getParam("id");
    await this.getCouponDetails(couponId);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentCPage !== this.state.currentCPage) {
      this.getAllProduct();
    } else if (prevState.currentECPage !== this.state.currentECPage) {
      this.getAllExProduct();
    }
  }

  getCouponDetails = (id: string) => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.APIContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCouponAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.GetCouponAPIEndPoint + id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeAPIGet
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // Get All Product
  getAllProduct = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiGetUserList,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getProductListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiGetProductList}?per_page=6&page=${
          this.state.currentCPage
        }&search=${this.state.searchProduct}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetProductList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // Get All Ex Product
  getAllExProduct = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiGetUserList,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getExProductListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiGetProductList}?per_page=6&page=${
          this.state.currentECPage
        }&search=${this.state.searchExProduct}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetProductList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Toggle Products Modal
  toggleProductModal = () => {
    this.setState({
      ...this.state,
      isProductModalOpen: !this.state.isProductModalOpen,
    });
  };

  toggleExProductModal = () => {
    this.setState({
      ...this.state,
      isExProductModalOpen: !this.state.isExProductModalOpen,
    });
  };

  // Handle Select Product
  handleProduct = (isChecked: boolean, id: any) => {
    if (isChecked) {
      this.setState({
        ...this.state,
        selectedProducts: [...this.state.selectedProducts, Number(id)],
      });
    } else {
      const newProduct = this.state.selectedProducts.filter(
        (sId) => sId !== Number(id)
      );
      this.setState({
        ...this.state,
        selectedProducts: newProduct,
      });
    }
  };

  // Handle Ex Select Product
  handleExProduct = (isChecked: boolean, id: any) => {
    if (isChecked) {
      this.setState({
        ...this.state,
        selectedExProducts: [...this.state.selectedExProducts, Number(id)],
      });
    } else {
      const newProduct = this.state.selectedExProducts.filter(
        (sId) => sId !== Number(id)
      );
      this.setState({
        ...this.state,
        selectedExProducts: newProduct,
      });
    }
  };

  // Get All Category API
  getAllCategory = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiGetUserList,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCategoryListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCategorySubCategorySubSubCategoryApi}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCategoryList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // DropDown Label => Category, Sub Category, Ex Category, Ex Sub Category
  getDropdownButtonLabel = ({
    placeholderButtonLabel,
    value,
  }: {
    placeholderButtonLabel: String;
    value: Array<Menu>;
  }) => {
    if (value === undefined) {
      return `${placeholderButtonLabel}`;
    } else {
      let res = "";
      value.forEach((obj: { value: number; label: String }) => {
        res = res + obj.label + ", ";
      });
      if (value.length > 0) {
        return `${res}`;
      } else {
        return `${placeholderButtonLabel}`;
      }
    }
  };

  // For Category View
  updateCategory = () => {
    this.state.categories.map((item: any) => {
      this.setState((prevState: any) => ({
        categoryMenuView: [
          ...prevState.categoryMenuView,
          { label: item.attributes.name, value: item.attributes.id },
        ],
      }));
    });
  };

  // Handle Change Category
  handleCategory = (options: any) => {
    this.setState(
      {
        ...this.state,
        selectedCategory: options,
        subCategoryMenuView: [],
        selectedSubCategory: [],
      },
      () => {
        if (options.length !== 0) {
          this.getSubCategory();
        }
      }
    );
  };

  // Handle Change Ex Category
  handleExCategory = (options: any) => {
    this.setState(
      {
        ...this.state,
        selectedExCategory: options,
        exSubCategoryMenuView: [],
        selectedExSubCategory: [],
      },
      () => {
        if (options.length !== 0) {
          this.getExSubCategory();
        }
      }
    );
  };

  // Sub Category Id
  getSubCategoryId = () => {
    let data = this.state.selectedCategory.map((item) => {
      return item.value;
    });
    return data.join(",");
  };

  //  Get All Sub Category API
  getSubCategory = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiGetUserList,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubCategoryListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${
          configJSON.getCategorySubCategorySubSubCategoryApi
        }?category_id=${this.getSubCategoryId()}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCategoryList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // For Sub Category View
  updateSubCategory = () => {
    this.state.subCategories.map((item: any) => {
      this.setState((prevState: any) => ({
        subCategoryMenuView: [
          ...prevState.subCategoryMenuView,
          { label: item.attributes.name, value: item.attributes.id },
        ],
      }));
    });
  };

  // Handle Change Sub Category
  handleSubCategory = (options: any) => {
    this.setState({
      ...this.state,
      selectedSubCategory: options,
    });
  };

  // Sub Category Id
  getExSubCategoryId = () => {
    let data = this.state.selectedExCategory.map((item) => {
      return item.value;
    });
    return data.join(",");
  };

  //  Get All Ex Sub Category API
  getExSubCategory = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiGetUserList,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getExSubCategoryListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${
          configJSON.getCategorySubCategorySubSubCategoryApi
        }?category_id=${this.getExSubCategoryId()}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCategoryList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  // For Ex Sub Category View
  updateExSubCategory = () => {
    this.state.exSubCategories.map((item: any) => {
      this.setState((prevState: any) => ({
        exSubCategoryMenuView: [
          ...prevState.exSubCategoryMenuView,
          { label: item.attributes.name, value: item.attributes.id },
        ],
      }));
    });
  };

  // Handle Change Sub Category
  handleExSubCategory = (options: any) => {
    this.setState({
      ...this.state,
      selectedExSubCategory: options,
    });
  };

  isNumeric = (value: string) => {
    if (value.match(/^-?\d+$/)) {
      //valid integer (positive or negative)
      return true;
    } else if (value.match(/^\d+\.\d+$/)) {
      //valid float
      return true;
    } else {
      //not valid number
      return false;
    }
  };

  validationCouponCode = () => {
    let errors: any = {};
    let formIsValid = true;

    if (this.state.couponCode.length <= 0) {
      formIsValid = false;
      errors["couponCodeError"] = "Please Enter Unique Coupon Code";
    }
    if (this.state.description.length <= 0) {
      formIsValid = false;
      errors["descriptionError"] = "Please Enter Coupon Description";
    }
    if (!this.isNumeric(this.state.amount)) {
      formIsValid = false;
      errors["amountError"] = "Please Enter Valid Amount";
    }
    if (
      this.state.selectedType == "percentage_discount" &&
      Number(this.state.amount) > 99
    ) {
      formIsValid = false;
      errors["amountError"] = "Coupon Percentage Should Be less than 99%";
    }
    if (this.state.amount.length <= 0) {
      formIsValid = false;
      errors["amountError"] = "Please Enter Amount";
    }
    if (!moment(this.state.expiryDate).isValid()) {
      formIsValid = false;
      errors["expiryDateError"] = "Please Enter Expiry Date";
    }
    if (
      this.state.selectedProducts.length === 0 &&
      this.state.selectedExProducts.length === 0 &&
      this.state.selectedCategory.length === 0 &&
      this.state.selectedExCategory.length === 0 &&
      this.state.selectedSubCategory.length === 0 &&
      this.state.selectedExSubCategory.length === 0
    ) {
      formIsValid = false;
      errors["productError"] =
        "Please Enter Products or Categories or Sub Categories";
    }
    this.setState({ ...this.state, errors: errors });
    return formIsValid;
  };

  editCoupon = () => {
    if (!this.validationCouponCode()) {
      return;
    }

    const selectedCategoryId = this.state.selectedCategory.map(
      (category: any) => {
        return category.value;
      }
    );
    const selectedCategoryName = this.state.selectedCategory.map(
      (category: any) => {
        return category.label;
      }
    );

    const selectedExCategoryId = this.state.selectedExCategory.map(
      (category: any) => {
        return category.value;
      }
    );
    const selectedExCategoryName = this.state.selectedExCategory.map(
      (category: any) => {
        return category.label;
      }
    );

    const selectedSubCategoryId = this.state.selectedSubCategory.map(
      (category: any) => {
        return category.value;
      }
    );
    const selectedSubCategoryName = this.state.selectedSubCategory.map(
      (category: any) => {
        return category.label;
      }
    );

    const selectedExSubCategoryId = this.state.selectedExSubCategory.map(
      (category: any) => {
        return category.value;
      }
    );
    const selectedExSubCategoryName = this.state.selectedExSubCategory.map(
      (category: any) => {
        return category.label;
      }
    );

    const httpBody = {
      coupon_code: this.state.couponCode,
      discription: this.state.description,

      catalogue_ids: this.state.selectedProducts,
      exclude_catalogue_ids: this.state.selectedExProducts,

      // categories: selectedCategoryName,
      // exclude_categories: selectedExCategoryName,
      categories_id: selectedCategoryId,
      exclude_categories_id: selectedExCategoryId,

      sub_categories: selectedSubCategoryId,
      exclude_sub_categories: selectedExSubCategoryId,
      sub_categories_id: selectedSubCategoryId,
      exclude_sub_categories_id: selectedExSubCategoryId,

      coupon_type: this.state.selectedType,
      amount: this.state.amount,
      expiry_date: this.state.expiryDate,

      // minimum_spend: this.state.minimumSpend,
      // maximum_spend: this.state.maximumSpend,
      // individual_use_only: this.state.individualUse,
      exclude_sale_item: this.state.excludeSaleItem,

      usage_limit_per_coupon: this.state.usageLimitPerCoupon,
      usage_limit_per_customer: this.state.usageLimitPerCustomer,
    };

    const couponId = this.props.navigation.getParam("id");

    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.APIContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EditCouponAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CreateCouponAPIEndPoint + `/${couponId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeAPIPut
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
