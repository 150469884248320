import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Container, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import CsvUploadController, { Props } from "./CsvUploadController.web";
import { CsvUploadStyleWeb } from "./CsvUploadStyle.web";
var DocIc = require("../assets/doc-icon.svg");

export class CsvUpload extends CsvUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  openFileUploadUI = () => {
      const fileInput = document.getElementById('file-button-input');
      fileInput?.click();
  }
  // Customizable Area End

  render() {
    console.log(this.state.file);

    const { classes } = this.props;
    return (
      <>
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Add Multiple Products</h2>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <p>Download CSV files for edit inventory</p>
                    <div className="download-doc">
                      <img src={DocIc} alt="" />
                      <span className="file-name">Sample CSV File</span>
                      <Button
                        type="button"
                        variant="contained"
                        className="black-btn"
                        href={require("../assets/sample_catalogue.csv")}
                        download="sample-bulk-upload"
                      >
                        Download
                      </Button>
                    </div>
                    <span className="divide-block" />
                    <p>
                      Attach CSV file to add multiple products in your product
                      lists
                    </p>
                    {this.state.file ? (
                      <div className="file-upload">
                        <span className="file-name">
                          {this.state.file.name}
                        </span>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              file: null,
                            });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div className="file-upload input">
                        <input
                          type="file"
                          accept=".csv"
                          id="file-button-input"
                          onChange={(e: any) => {
                            console.log( e.target.files[0]);
                            
                            this.setState({
                              ...this.state,
                              file: e.target.files[0],
                            });
                          }}
                        />
                        <span 
                          onClick={this.openFileUploadUI}
                        >
                          Attach CSV File
                        </span>
                      </div>
                    )}
                    <div className="btn-wrapper">
                      <Button
                        disabled={!this.state.file}
                        variant="contained"
                        className="black-btn upload-btn"
                        onClick={() => {
                          this.uploadCsvFile();
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(CsvUploadStyleWeb)(CsvUpload);
