export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ic_bag = require("../assets/ic_bag.png");
export const ic_home = require("../assets/ic_home.png");
export const ic_search = require("../assets/ic_search.png");
export const ic_user = require("../assets/ic_user.png");
export const ic_close = require("../assets/ic_close.png");
export const circleWidth = require("../assets/circle-icon-3.png");
export const circle = require("../assets/icons8-circle-50.png");
export const order_profile_img = require("../assets/group_dp.png")
export const downArrow = require('../assets/downArrow.png');
export const backArrow = require("../assets/backarrow.png")
export const hideEye = require("../assets/icons8-hide-24.png")
export const eye = require("../assets/ic_password_visible.png")
export const checkBox = require("../assets/icons8-checked-checkbox-24.png")
export const uncheckBox = require("../assets/icons8-unchecked-checkbox-48.png")
export const backButton = require("../assets/backButton.png")
