export const USER_TYPES = {
    CUSTOMER: "customer",
    VENDOR: "vendor",
    AFFILIATE: "affiliate"
}

export const ROLE = {
    CUSTOMER: "customer",
    VENDOR: "vendor",
    AFFILIATE: "affiliate",
    ALL: "all"
}

export const ORDER_STATUS_TYPES = {
    PLACED: "placed",
    CONFIRMED: "confirmed",
    READY_TO_SHIP: "ready_to_ship",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
    CANCELLED: "cancelled",
    RETURNED: "returned",
    REFUND_PROCESSED: "refund_processed",
    EXCHANGE_DELIVERED: "exchange_delivered",
    RETURN_PENDING: "return_pending",
    RTO: "rto",
    EXCHANGE: "exchange"
}

export const ORDER_STATUS_COLOR = {
    PLACED: "#5A7994",
    CONFIRMED: "#5A7994",
    READY_TO_SHIP: "#5A7994",
    SHIPPED: "shipped",
    DELIVERED: "#4CC3A0",
    CANCELLED: "#f94a59",
    RETURNED: "returned",
    REFUND_PROCESSED: "refund_processed",
    EXCHANGE_DELIVERED: "exchange_delivered",
    RETURN_PENDING: "return_pending",
    RTO: "rto",
    EXCHANGE: "#BB2FE1"   
}