import firebase from 'firebase';
// import "firebase/messaging";

const config = {
    apiKey: 'AIzaSyB2dLsioBhDq0vERNMnNwvKKBmYtK4e0fs',
    authDomain: 'caelumgaurav-924a1.firebaseapp.com',
    projectId: 'caelumgaurav-924a1',
    storageBucket: 'caelumgaurav-924a1.appspot.com',
    messagingSenderId: '962575695421',
    appId: '1:962575695421:web:3dbb9414d78bdebfef7454',
    measurementId: 'G-040XCE5XLZ'
};

const firebaseAPI = firebase.initializeApp(config);

export const firbaseAnalytic = firebaseAPI.analytics();

const messaging = firebase.messaging();

const key = 'BBbSevpZcka4VBtbwez84xVRH3700R0qVyyoSug_tHfe_MI7VVKQNl0RylKYlF0gSzNazCJCokgBbv9Ac8FP3Ds';

export const firebaseRequestForTokenMessging = () => {
    console.log("messaging:::", messaging);
    return messaging
        .getToken({ vapidKey: key })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        })
};
export default firebase;