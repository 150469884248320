export const Order_Message = {
    Cancelled: "Order Cancelled Successfully",
    Confirm: "Order Confirm Successfully",
    Shipped: "Order Shipped Successfully",
    SchedulePickup: "Schedule Pickup done successfully"
}

export const Login_Message = {
    TokenRequire: "You need to login first",
    SessionExpired: "Session is Expired now"
}