import React, { Component } from "react";
import { Box, TextField, InputLabel, Input, FormControl } from "@material-ui/core";
import SellerController, { Props } from "./SellerController.web";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Link from "@material-ui/core/Link";
import BackArrow from "@material-ui/icons/ArrowBack";
import {
  withStyles,
} from "@material-ui/core/styles";

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: '50px 0',
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      textTransform: "uppercase",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& p": {
      fontFamily: "'Roboto',sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .send-btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0 0",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
        display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
  },
};

export class SellerInquiryForm extends SellerController {
  myRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef()
    // Customizable Area End
  }



  render() {
    const { classes } = this.props;

    return (
      <>
        <div  ref={this.myRef} className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                <div style ={{display:"flex",width:"760px",justifyContent:"space-between",alignItems:"baseline"}}>   <Link  style ={{color:"black"}} href="/Aboutus"><BackArrow/></Link>
                <h2>Seller Inquiry </h2> 
                  </div>
               
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <div className="form-group">
                      <InputLabel required htmlFor="txtInputName">Name</InputLabel>
                      <FormControl>
                        <Input id={"txtInputName"} value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value });  }} />
                      </FormControl>
                      <p className="error-msg">
                        {this.state.nameError}
                      </p>
                    </div>
                    <div className="form-group">
                      <InputLabel required htmlFor="txtInputEmail">Email</InputLabel>
                      <FormControl>
                        <Input id={"txtInputEmail"} value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} />
                      </FormControl>
                      <p className="error-msg">
                        {this.state.emailError}
                      </p>
                    </div>
                    <div className="form-group">
                      <InputLabel required htmlFor="txtInputPhone">Phone Number</InputLabel>
                      <FormControl>
                        <Input type ="number"  id={"txtInputPhone"} value={this.state.phone} onChange={(e) => { this.setState({ phone: e.target.value });  }} />
                      </FormControl>
                      <p className="error-msg">
                        {this.state.phoneError}
                      </p>
                    </div>
                    <div className="form-group">
                      <InputLabel required htmlFor="txtInputCompany" > Company Name </InputLabel>
                      <FormControl>
                        <Input id={"txtInputCompany"} value={this.state.company} onChange={(e) => { this.setState({ company: e.target.value });}} />
                      </FormControl>
                      <p className="error-msg">
                        {this.state.companyError}
                      </p>
                    </div>
                    <div className="form-group">
                      <InputLabel htmlFor="txtInputBrand"> Brand Name</InputLabel>
                      <FormControl>
                        <Input id={"txtInputBrand"} value={this.state.brand} onChange={(e) => { this.setState({ brand: e.target.value });  }} />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <InputLabel htmlFor="txtInputUrl"> URL</InputLabel>
                      <FormControl>
                        <Input id={"txtInputUrl"} value={this.state.url} onChange={(e) => { this.setState({ url: e.target.value });  }} />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <InputLabel htmlFor="standard-adornment-amount" > Where you are selling currently</InputLabel>
                      <FormControl>
                        <Input id={"txtInputLocation"} value={this.state.location} onChange={(e) => { this.setState({ location: e.target.value }); }} />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <InputLabel  htmlFor="standard-adornment-amount"> Category</InputLabel>
                      {/* <FormControl>
                        <TextField 
                         select
                         onChange={(e) => {
                          if(e.target.value == "women"){this.setState({category:e.target.value});this.setState({optionid:"1"})}
                          if(e.target.value == "testing"){this.setState({category:e.target.value});this.setState({optionid:"3"})}
                          if(e.target.value == "hjdhsjkkj"){this.setState({category:e.target.value});this.setState({optionid:"4"})}
                          if(e.target.value == "child"){this.setState({category:e.target.value});this.setState({optionid:"5"})}
                          if(e.target.value == "peter england"){this.setState({category:e.target.value});this.setState({optionid:"8"})}
                          if(e.target.value == "Mens Shirt"){this.setState({category:e.target.value});this.setState({optionid:"9"})}
                          if(e.target.value == "womens shirts"){this.setState({category:e.target.value});this.setState({optionid:"10"})}
                       }}
                          
                         id = {this.state.optionid}
                         value={this.state.category}
                         SelectProps={{
                           native: true,
                         }}
                         variant="standard"
                      
                        >
                          {Array.from(this.state.data).map((option: any) => (
                            <option

                            value={option.attributes.name}
                            key = {option.attributes.name}
                            id = {option.attributes.id}
                            >
                              {option.attributes.name}
                            </option>
                          ))}
                        </TextField>
                      </FormControl> */}
                      {/* new select */}
           
                      <Select  
                      //   onChange={(e:any) => {
                      //     // document.getElementById(this.state.)
                      //     // if(e.target.value == "bags"){this.setState({category:e.target.value});this.setState({optionid:"7"})}
                      //     // if(e.target.value == "woman"){this.setState({category:e.target.value});this.setState({optionid:e.target.id})}
                      //     // if(e.target.value == "men"){this.setState({category:e.target.value});this.setState({optionid:"3"})}
                      //     // if(e.target.value == "Tech & Travel"){this.setState({category:e.target.value});this.setState({optionid:"5"})}
                      //     // if(e.target.value == "LifeStyle"){this.setState({category:e.target.value});this.setState({optionid:"6"})}
                      //     // if(e.target.value == "beg"){this.setState({category:e.target.value});this.setState({optionid:"16"})}
                      //     // if(e.target.value == "sling beg"){this.setState({category:e.target.value});this.setState({optionid:"17"})}
                      //     // this.setState({category:e.target.value});this.setState({optionid:}); console.log(this.state.category,this.state.optionid)
                      //  }}
                          
                         id = {this.state.optionid}
                         value={this.state.category}
                      
                      labelId="product-category" displayEmpty >
                                  { console.log(this.state.data)} 
                      {this.state.data? Array.from(this.state.data).map((option: any) => (            <MenuItem   value={option.attributes.name} onClick = {() => {this.setState({optionid:option.attributes.id}); this.setState({category:option.attributes.name});}}
                            key = {option.attributes.id}
                            id = {option.attributes.id} >{option.attributes.name}</MenuItem>))
                            : <MenuItem>no categories</MenuItem>
                            
                            
                            }
                            
                            
                            
                      </Select>    
                    </div>
                    <div className="form-group">
                      <InputLabel htmlFor="standard-adornment-amount" > No of SKUs</InputLabel>
                      <FormControl>
                        <Input id={"txtInputSku"} value={this.state.sku} onChange={(e) => { this.setState({ sku: e.target.value }); }} />
                      </FormControl>
                    </div>
                    <div className="send-btn-wrapper">
                      <Button variant="contained" className="black-btn" type="button"onClick={() =>{ this.doSeller();this.myRef.current?.scrollIntoView({behavior: 'auto'})}} >send</Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </div>

      
      </>
    )
  }
}
// styling

const sendButton = {
  outline: "none",
  border: "none",
  marginLeft: "250px",
  backgroundColor: "black",
  width: "150px",
  height: "40px",
  marginTop: "50px",
  color: "white",
};

const sendButtonAnchor = { color: "white", textDecoration: "none" };

export default withStyles(styles)(SellerInquiryForm);
