import React from "react";
import firebase from "firebase";
import DashboardControllers, {
  Props,
  configJSON,
} from "../../blocks/catalogue/src/DashboardControllers.web";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import {
  caelumBlack,
  search,
  adidas,
  bagIcon,
  accountIcon,
  heartIcon,
  champion,
  hnm,
  nike,
  noProductImage,
} from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// account imports
import { Menu, MenuItem } from "@szhsin/react-menu";
import "../../../node_modules/@szhsin/react-menu/dist/index.css";
import { withStyles } from "@material-ui/core/styles";
import { TopNavStyle } from "./TopNavWebStyle";

import axios from "axios";
import { baseURL } from "../../framework/src/config";
import { Grid, ListItem } from "@material-ui/core";

class TopNav extends DashboardControllers {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      search: "",
      profileName: "",
      phone: "",
    };
  }

  removeMobileMenu = () => {
    document.querySelector("body").classList.remove("open-menu");
    document.querySelector("html").classList.remove("open-menu");
  };

  componentDidMount() {
    this.removeMobileMenu();
    this.getcategory();
    if (localStorage.getItem("authToken")) {
      this.getProfile();
    }
  }

  getcategory = () => {
    axios
      .get(`${baseURL}/bx_block_categories/categories_listing`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            ...this.state,
            category: response.data.data,
          });
        }
      });
  };

  logout = () => {
    this.removeMobileMenu();
    localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then((result) => {
        this.gotoPage("/login");
      })
      .catch((error) => {});
  };

  gotoPage = (link) => {
    this.removeMobileMenu();
    this.props.history.push(link);
  };

  login = () => {
    this.removeMobileMenu();
    localStorage.clear();
    window.location.pathname === "/login" ? window.location.reload() : null;
    this.props.history.push("/login");
  };

  gotToAboutUsPage = () => {
    this.removeMobileMenu();
    this.props.history.push({
      pathname: "/Aboutus",
      hash: "#state=1",
    });
  };

  displaySubMenu = (id) => {
    this.setState({ ...this.state, isCategoryActive: id });
  };

  displaySubSubMenu = (id) => {
    this.setState({ ...this.state, isSubCategoryActive: id });
  };

  getProfile = () => {
    axios
      .get(`${baseURL}/account_block/account`, {
        headers: {
          token: localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            ...this.state,
            profileName: response.data.data.attributes.name,
            phone: response.data.data.attributes.full_phone_number,
          });
        }
      });
  };

  render() {
    const { classes } = this.props;

    window.addEventListener("resize", (event) => {
      if (window.innerWidth >= 991) {
        this.removeMobileMenu();
        document.querySelector("li").classList.remove("active");
        this.setState({
          ...this.state,
          isCategoryActive: -1,
          isSubCategoryActive: -1,
          isNewInActive: false,
          isOfferActive: false,
        });
      }
    });

    return (
      <>
        {localStorage.getItem("role") === "vendor" ? (
          <div className={classes.secondaryheader}>
            <header>
              <div className="left-header">
                <Link href="/HomePage" className="logo">
                  <img src={caelumBlack} />
                </Link>
              </div>
              <div className="right-header">
                <span
                  className="close-ic"
                  onClick={(e) => {
                    this.removeMobileMenu();
                  }}
                >
                  <CloseIcon />
                </span>

                <div className="user-profile">
                  <h4>{this.state.profileName}</h4>
                  <Link href="/VendorProfile">Edit Profile</Link>
                </div>
                <div className="vendor-menu-link">
                  <ul>
                    <li className="active">
                      <Link href="#">
                        Reports Download
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        Payment
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/VendorAddressBook">
                        Address Book
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/Packaging">
                        Packaging
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        Messages
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/coupons">
                        Discounts & Coupons
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/VendorHelpCenter">
                        Help
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/VendorSettings">
                        Settings
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="#">
                        Seller Support
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="btn-wrapper">
                  <Button
                    type="button"
                    variant="contained"
                    className="black-btn"
                    onClick={() => this.logout()}
                  >
                    Sign Out
                  </Button>
                </div>
              </div>
              <div className="nav-list">
                <div className="header-menu-list">
                  <Link href="/HomePage">Dashboard</Link>
                  <Link href="/vendorOrder/received">Orders</Link>
                  <Link href="/Inventory">Inventory</Link>
                  {/* {navList.map((items) => (
                    <Link href="#">{items.category}</Link>
                  ))} */}
                </div>
              </div>
              <div
                className="hamburger-menu"
                onClick={(e) => {
                  document.querySelector("body").classList.add("open-menu");
                  document.querySelector("html").classList.add("open-menu");
                }}
              >
                <MenuIcon />
              </div>
            </header>
          </div>
        ) : (
          <div className={classes.root}>
            <header>
              <div className="left-header">
                <Link href="/Home" className="logo">
                  <img src={caelumBlack} />
                </Link>
              </div>
              <div className="right-header">
                <span
                  className="close-ic"
                  onClick={(e) => {
                    this.removeMobileMenu();
                    this.setState({
                      ...this.state,
                      isCategoryActive: -1,
                      isSubCategoryActive: -1,
                      isNewInActive: false,
                      isOfferActive: false,
                    });
                  }}
                >
                  <CloseIcon />
                </span>
                <div className="header-menu-list">
                  {this.state.category.map((catagory) => {
                    return (
                      <li
                        className={
                          this.state.isCategoryActive == catagory.attributes.id
                            ? "active"
                            : ""
                        }
                        key={catagory.id}
                      >
                        <Link
                          onClick={() => {
                            if (window.innerWidth <= 991) {
                              this.displaySubMenu(catagory.attributes.id);
                            }
                          }}
                        >
                          {catagory.attributes.name}
                          <span>
                            <ArrowForwardIosIcon />
                          </span>
                        </Link>
                        <div className="submenu">
                          <Link
                            className="back"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                isCategoryActive: -1,
                              });
                            }}
                          >
                            <span>
                              <ArrowBackIosIcon />
                            </span>
                            Back
                          </Link>
                          <ul>
                            <li
                              className={
                                this.state.isNewInActive ? "active" : ""
                              }
                            >
                              <Link
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    isNewInActive: true,
                                  });
                                }}
                              >
                                New In
                              </Link>
                              <div className="mega-sub-menu">
                                <Link
                                  href="#"
                                  className="back"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      isNewInActive: false,
                                    });
                                  }}
                                >
                                  <span>
                                    <ArrowBackIosIcon />
                                  </span>
                                  Back
                                </Link>
                                <div className="content-wrapper">
                                  <div className="block-wrapper bind-link">
                                    <h3 className="heading">New Products</h3>
                                    <ul>
                                      {catagory.attributes.new_product.data
                                        .slice(0, 8)
                                        .map((product) => {
                                          return (
                                            <li key={product.id}>
                                              <Link
                                                href={`/ProductDetail/${
                                                  product.id
                                                }`}
                                              >
                                                {product.attributes.name}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="block-wrapper">
                                    <h3 className="heading">Shop By Edits</h3>
                                    <ul className="category-block">
                                      {catagory.attributes.shop_by_edit.data
                                        .slice(0, 4)
                                        .map((edit) => {
                                          return (
                                            <li key={edit.id}>
                                              <Link
                                                href={`/Itemlist?shop_by_edit_id=${
                                                  edit.id
                                                }`}
                                              >
                                                {edit.attributes.filename ? (
                                                  <img
                                                    src={
                                                      edit.attributes.filename
                                                    }
                                                  />
                                                ) : (
                                                  <img src={noProductImage} />
                                                )}
                                                <span>
                                                  {edit.attributes.name}
                                                </span>
                                              </Link>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="block-wrapper">
                                    <h3 className="heading">SHOP by brands</h3>
                                    <ul className="img-outer">
                                      {catagory.attributes.shop_by_brand.data
                                        .slice(0, 4)
                                        .map((brand) => {
                                          return (
                                            <li key={brand.id}>
                                              <Link
                                                href={`/Itemlist?search=${
                                                  brand.attributes.name
                                                }`}
                                              >
                                                {brand.attributes.image ? (
                                                  <img
                                                    src={brand.attributes.image}
                                                  />
                                                ) : (
                                                  <img src={noProductImage} />
                                                )}
                                              </Link>
                                              <span>
                                                {brand.attributes.name}
                                              </span>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="block-wrapper">
                                    {catagory.attributes.image ? (
                                      <img
                                        className="big-img-block"
                                        src={catagory.attributes.image}
                                      />
                                    ) : (
                                      <img
                                        className="big-img-block"
                                        src={noProductImage}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={
                                this.state.isOfferActive ? "active" : ""
                              }
                            >
                              <Link
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    isOfferActive: true,
                                  });
                                }}
                              >
                                Offers
                              </Link>
                              <div className="mega-sub-menu">
                                <Link
                                  href="#"
                                  className="back"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      isOfferActive: false,
                                    });
                                  }}
                                >
                                  <span>
                                    <ArrowBackIosIcon />
                                  </span>
                                  Back
                                </Link>
                                <div className="content-wrapper">
                                  {/* <div className="block-wrapper"> */}
                                  {/* <h3 className="heading">View All</h3> */}
                                  {catagory?.attributes?.offers?.data?.length ?
                                      (               
                                        <Grid container>
                                        { 
                                          catagory?.attributes?.offers?.data?.map((offer, index) => (
                                            <Grid item xs={12} sm={4} md={3} key={index}>
                                              <ListItem>
                                                <Link
                                                  href={`/Itemlist?offer_id=${offer?.id}`}
                                                  style={{textDecoration: "none", color: "#212121"}}
                                                >
                                                  {offer?.attributes?.name}
                                                </Link>
                                              </ListItem>
                                            </Grid>
                                          ))
                                      }
                                      </Grid>
                                      )
                                      : <span>No Offers</span>
                                    }
                                    {/* <h3 className="heading">View All</h3>
                                    <ul>
                                      <li>
                                        <Link>Maangtika</Link>
                                      </li>
                                      <li>
                                        <Link>Nose Rings</Link>
                                      </li>
                                      <li>
                                        <Link>Brooches</Link>
                                      </li>
                                      <li>
                                        <Link>Mask Chains</Link>
                                      </li>
                                      <li>
                                        <Link>Charms</Link>
                                      </li>
                                      <li>
                                        <Link>Gifting</Link>
                                      </li>
                                      <li>
                                        <Link>Pendants</Link>
                                      </li>
                                    </ul>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                            </li>
                            {catagory.attributes.sub_categories.data.map(
                              (subCat) => {
                                return (
                                  <li
                                    key={subCat.id}
                                    className={
                                      this.state.isSubCategoryActive ==
                                      subCat.attributes.id
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      // href="#"
                                      onClick={() =>
                                        this.displaySubSubMenu(
                                          subCat.attributes.id
                                        )
                                      }
                                    >
                                      {subCat.attributes.name}
                                    </Link>
                                    <div className="mega-sub-menu">
                                      <Link
                                        href="#"
                                        className="back"
                                        onClick={() => {
                                          this.setState({
                                            ...this.state,
                                            isSubCategoryActive: -1,
                                          });
                                        }}
                                      >
                                        <span>
                                          <ArrowBackIosIcon />
                                        </span>
                                        Back
                                      </Link>
                                      <div className="content-wrapper bind-link">
                                        {subCat.attributes.sub_sub_categories
                                          .data.length < 8 ? (
                                          <div
                                            className="block-wrapper"
                                            style={{ border: "none" }}
                                          >
                                            <h3 className="heading">
                                              Shop All
                                            </h3>
                                            <ul>
                                              {subCat.attributes.sub_sub_categories.data.map(
                                                (subSubCat) => {
                                                  return (
                                                    <li key={subSubCat.id}>
                                                      <Link
                                                        href={`/Itemlist?sub_sub_category_id=${
                                                          subSubCat.id
                                                        }`}
                                                      >
                                                        {
                                                          subSubCat.attributes
                                                            .name
                                                        }
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        ) : subCat.attributes.sub_sub_categories
                                            .data.length > 8 &&
                                          subCat.attributes.sub_sub_categories
                                            .data.length < 16 ? (
                                          <>
                                            <div className="block-wrapper">
                                              <h3 className="heading">
                                                Shop All
                                              </h3>
                                              <ul>
                                                {subCat.attributes.sub_sub_categories.data
                                                  .slice(0, 8)
                                                  .map((subSubCat) => {
                                                    return (
                                                      <li key={subSubCat.id}>
                                                        <Link
                                                          onClick={() => {
                                                            window.open(
                                                              `${
                                                                window.location
                                                                  .protocol
                                                              }//${
                                                                window.location
                                                                  .host
                                                              }/Itemlist?sub_sub_category_id=${
                                                                subSubCat.id
                                                              }`,
                                                              "_self"
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            subSubCat.attributes
                                                              .name
                                                          }
                                                        </Link>
                                                      </li>
                                                    );
                                                  })}
                                              </ul>
                                            </div>
                                            <div className="block-wrapper">
                                              <ul>
                                                {subCat.attributes.sub_sub_categories.data
                                                  .slice(8)
                                                  .map((subSubCat) => {
                                                    return (
                                                      <li key={subSubCat.id}>
                                                        <Link
                                                          onClick={() => {
                                                            window.open(
                                                              `${
                                                                window.location
                                                                  .protocol
                                                              }//${
                                                                window.location
                                                                  .host
                                                              }/Itemlist?sub_sub_category_id=${
                                                                subSubCat.id
                                                              }`,
                                                              "_self"
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            subSubCat.attributes
                                                              .name
                                                          }
                                                        </Link>
                                                      </li>
                                                    );
                                                  })}
                                              </ul>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="block-wrapper">
                                              <h3 className="heading">
                                                Shop All
                                              </h3>
                                              <ul>
                                                {subCat.attributes.sub_sub_categories.data
                                                  .slice(
                                                    0,
                                                    subCat.attributes
                                                      .sub_sub_categories.data
                                                      .length / 2
                                                  )
                                                  .map((subSubCat) => {
                                                    return (
                                                      <li key={subSubCat.id}>
                                                        <Link
                                                          onClick={() => {
                                                            window.open(
                                                              `${
                                                                window.location
                                                                  .protocol
                                                              }//${
                                                                window.location
                                                                  .host
                                                              }/Itemlist?sub_sub_category_id=${
                                                                subSubCat.id
                                                              }`,
                                                              "_self"
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            subSubCat.attributes
                                                              .name
                                                          }
                                                        </Link>
                                                      </li>
                                                    );
                                                  })}
                                              </ul>
                                            </div>
                                            <div className="block-wrapper">
                                              <ul>
                                                {subCat.attributes.sub_sub_categories.data
                                                  .slice(
                                                    subCat.attributes
                                                      .sub_sub_categories.data
                                                      .length / 2
                                                  )
                                                  .map((subSubCat) => {
                                                    return (
                                                      <li key={subSubCat.id}>
                                                        <Link
                                                          onClick={() => {
                                                            window.open(
                                                              `${
                                                                window.location
                                                                  .protocol
                                                              }//${
                                                                window.location
                                                                  .host
                                                              }/Itemlist?sub_sub_category_id=${
                                                                subSubCat.id
                                                              }`,
                                                              "_self"
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            subSubCat.attributes
                                                              .name
                                                          }
                                                        </Link>
                                                      </li>
                                                    );
                                                  })}
                                              </ul>
                                            </div>
                                          </>
                                        )}
                                        <div className="block-wrapper" />
                                        <div className="block-wrapper">
                                          <h3 className="heading">
                                            Shop By Edits
                                          </h3>
                                          <ul className="category-block">
                                            {subCat.attributes.shop_by_edit.data
                                              .slice(0, 4)
                                              .map((edit) => {
                                                return (
                                                  <li key={edit.id}>
                                                    <Link
                                                      href={`/Itemlist?shop_by_edit_id=${
                                                        edit.id
                                                      }`}
                                                    >
                                                      {edit.attributes
                                                        .filename ? (
                                                        <img
                                                          src={
                                                            edit.attributes
                                                              .filename
                                                          }
                                                        />
                                                      ) : (
                                                        <img
                                                          src={noProductImage}
                                                        />
                                                      )}
                                                      <span>
                                                        {edit.attributes.name}
                                                      </span>
                                                    </Link>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </div>
                                        <div className="block-wrapper">
                                          {subCat?.attributes?.image ? (
                                            <img
                                              className="big-img-block"
                                              src={subCat?.attributes?.image}
                                            />
                                          ) : (
                                            <img
                                              className="big-img-block"
                                              src={noProductImage}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </li>
                    );
                  })}
                </div>
                <div className="search-wrapper">
                  <div className="form-group">
                    <img src={search} className="search-ic" />
                    <input
                      placeholder="Search Products, Brands & more..."
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          search: e.target.value,
                        });
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          window.open(
                            `${window.location.protocol}//${
                              window.location.host
                            }/Itemlist?search=${this.state.search}`,
                            "_self"
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="more-link-block">
                  <div className="link-wrapper">
                    <Menu
                      menuButton={
                        <Button>
                          <em className="menu-ic">
                            <img id="viewAllImg" src={accountIcon} />
                          </em>
                          <span>Account</span>
                        </Button>
                      }
                    >
                      <MenuItem>
                        {localStorage.getItem("IsLogin") === "true" ? (
                          <div>
                            <h6 className="my-0">{this.state.profileName}</h6>
                            <p className="my-0">{this.state.phone}</p>
                          </div>
                        ) : (
                          <div>
                            <h6 className="m-0">Welcome</h6>
                            <p>To access account</p>
                            <Button
                              variant="outlined"
                              onClick={() => this.gotoPage("/login")}
                            >
                              Login / Sign Up
                            </Button>
                          </div>
                        )}
                      </MenuItem>
                      <hr className="my-2" />
                      <MenuItem
                        onClick={() =>
                          this.gotoPage("/account/CustomerMyProfile")
                        }
                      >
                        {" "}
                        My profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.gotoPage("/account/orders")}
                      >
                        Orders
                      </MenuItem>
                      {localStorage.getItem("role") === "affiliate" && (
                        <MenuItem
                          onClick={() =>
                            this.gotoPage("/affiliate/affiliate-list")
                          }
                        >
                          To Affiliate List
                        </MenuItem>
                      )}
                      {localStorage.getItem("role") === "affiliate" && (
                        <MenuItem
                          onClick={() => this.gotoPage("/affiliate/dashboard")}
                        >
                          Affiliate Dashboard
                        </MenuItem>
                      )}
                      {localStorage.getItem("role") === "affiliate" && (
                        <MenuItem
                          onClick={() =>
                            this.gotoPage("/affiliate/previous-shared-item")
                          }
                        >
                          Previous Shared
                        </MenuItem>
                      )}
                      { localStorage.getItem('authToken') &&
                        <MenuItem
                          onClick={() => {
                            this.props.callbackShopWithFriend(true);
                          }}
                        >
                          Shopping With Friends
                        </MenuItem>
                      }
                      <MenuItem
                        onClick={() => this.gotoPage("/account/settings")}
                      >
                        Setting
                      </MenuItem>
                      <MenuItem>Contact Us</MenuItem>
                      <MenuItem onClick={() => this.gotToAboutUsPage()}>
                        Help
                      </MenuItem>
                      {localStorage.getItem("IsLogin") === "true" ? (
                        <MenuItem onClick={this.logout}>Logout</MenuItem>
                      ) : (
                        <MenuItem onClick={this.login}>Login</MenuItem>
                      )}
                    </Menu>
                  </div>
                  <div className="link-wrapper">
                    <Button onClick={() => this.gotoPage("/wishlist")}>
                      {" "}
                      <em className="menu-ic">
                        <img id="viewAllImg" src={heartIcon} />
                      </em>
                      <span>Wishlist</span>{" "}
                    </Button>
                  </div>
                  <div className="link-wrapper">
                    <Button onClick={() => this.gotoPage("/ShoppingBag")}>
                      {Number(this.props.cartItemCount) >= 0 ? (
                        <Badge
                          badgeContent={this.props.cartItemCount}
                          color="secondary"
                        >
                          <em className="menu-ic">
                            <img id="viewAllImg" src={bagIcon} />
                          </em>
                        </Badge>
                      ) : (
                        <em className="menu-ic">
                          <img id="viewAllImg" src={bagIcon} />
                        </em>
                      )}
                      <span>Bag</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="hamburger-menu"
                onClick={(e) => {
                  const body = document.querySelector("body");
                  const htmls = document.querySelector("html");
                  body.classList.add("open-menu");
                  htmls.classList.add("open-menu");
                }}
              >
                <MenuIcon />
              </div>
            </header>
          </div>
        )}
      </>
    );
  }
}
export default withStyles(TopNavStyle)(withRouter(TopNav));
