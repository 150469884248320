import { toast } from "react-toastify";
import { defaultToasterTime } from "../../framework/src/Constants";

export const toastSuccessMessage = (text: string) => {
    toast.success(text, { position: "top-right", autoClose: defaultToasterTime });
}

export const toastWarnMessage = (text: string) => {
    toast.warn(text, { position: "top-right", autoClose: defaultToasterTime });
}

export const toastErrorMessage = (text: string) => {
    toast.error(text, { position: "top-right", autoClose: defaultToasterTime });
}