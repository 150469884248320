import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CustomerDetailResponseModel, PriceDetailResponseModel } from "../../../framework/src/Interfaces/IPayment";
import SweetAlert from "react-bootstrap-sweetalert";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  handleCartCount(number: Number): Function;
}

interface S {
  token: string | null;
  loading: boolean;
  priceDetail: PriceDetailResponseModel | null;
  customerDetail: CustomerDetailResponseModel | null;
  selectedPaymentMethod: string | null;
  razorpay_order_id: string | null;
  razorpay_payment_id: string | null;
  isOrderPlaced: boolean;
  isPaymentFailed: boolean;
  errorResponse: any;
  cartId: number | null;
  exchangeFlow: boolean;
  exchangeItemOrderId: any;
  addressId: any;
  placeExchangeOrderData: any
}

interface SS {

}

export default class PaymentController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getPriceDetailApiCallId: any = null;
  getActiveCartApiCallId: any = null;
  placeOrderApiCallId: any = null;
  fetchCapturePaymentApiCallId: any = null;
  PlaceExchangeOrderCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: localStorage.getItem("authToken"),
      loading: false,
      priceDetail: null,
      customerDetail: null,
      selectedPaymentMethod: null,
      razorpay_order_id: null,
      razorpay_payment_id: null,
      isOrderPlaced: false,
      isPaymentFailed: false,
      errorResponse: {},
      cartId: null,
      exchangeFlow: false,
      exchangeItemOrderId: "",
      addressId: "",
      placeExchangeOrderData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    this.PriceDetailApi();
    this.getActiveCartApi();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    const exchangeItem: any = localStorage.getItem("exchangeItemOrderId")
    this.setState({ exchangeItemOrderId: exchangeItem })
    const addressId: any = localStorage.getItem("addressId")
    this.setState({ addressId: addressId })
    if (localStorage.getItem("exchangeFlow") == "true") {
      this.setState({ exchangeFlow: true })
    } else { this.setState({ exchangeFlow: false }) }
  }

  async receive(from: string, message: Message) {

    console.log("message::::", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    console.log("apiRequestCallId:::::", apiRequestCallId);

    console.log("responseJson:::::", responseJson);

    // GET PRICE DETAIL API
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getPriceDetailApiCallId !== null && apiRequestCallId === this.getPriceDetailApiCallId) {
      console.log("inside responseJson:::::", responseJson);
      this.getPriceDetailApiCallId = null;
      if (responseJson && responseJson.data && responseJson.data.attributes) {
        const priceResponse: PriceDetailResponseModel = responseJson.data.attributes;
        const response = {
          sub_total: priceResponse.sub_total,
          discount_on_mrp: priceResponse.discount_on_mrp,
          total_sale_price: priceResponse.total_sale_price,
          total_tax: priceResponse.total_tax,
          delivery_charges: priceResponse.delivery_charges,
          applied_discount: priceResponse.applied_discount,
          total: priceResponse.total,
          cart_items_count: priceResponse.cart_items_count,
          loyalty_point_discount: priceResponse.loyalty_point_discount,
          coupon_discount_amount: priceResponse.coupon_discount_amount,
          exchangeble_item_price: priceResponse.exchangeble_item_price
        } as PriceDetailResponseModel;
        this.setState({ priceDetail: priceResponse });
        // this.setState((prevState) => {
        //   return { priceDetail: response };
        // }, () => console.log('PriceDetail reponse', this.state.priceDetail));
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END GET PRICE DETAIL API

    // GET ACTIVE CART DETAIL API
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getActiveCartApiCallId !== null && apiRequestCallId === this.getActiveCartApiCallId) {
      this.getActiveCartApiCallId = null;

      if (responseJson && responseJson.data) {
        this.setState({ cartId: Number(responseJson.data.id) });
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END GET ACTIVE CART DETAIL API


    //  PLACE ORDER API
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.placeOrderApiCallId !== null && apiRequestCallId === this.placeOrderApiCallId) {
      this.placeOrderApiCallId = null;
      this.setState({ loading: false });
      if (responseJson.data) {
        this.setState({ razorpay_order_id: responseJson.data.attributes.razorpay_order_id, customerDetail: responseJson.data.attributes.account.attributes }, () => this.handlePaymentCapture());
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END PLACE ORDER API

    // FETCH CAPTURE PAYMENT API
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.fetchCapturePaymentApiCallId !== null && apiRequestCallId === this.fetchCapturePaymentApiCallId) {
      this.fetchCapturePaymentApiCallId = null;
      this.setState({ loading: false })
      if (responseJson && responseJson.data) {
        if (responseJson.data.attributes.status === 'captured') {

          // exchange flow order placed code
          if (this.state.exchangeFlow) {
            this.placeExchangeorder()
          } else { this.setState({ isOrderPlaced: true }); }

        }
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END FETCH CAPTURE PAYMENT API


    // place exchange order start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PlaceExchangeOrderCallId !== null &&
      this.PlaceExchangeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PlaceExchangeOrderCallId = null;
      // var responseJson = message.getData(
      //   getName(MessageEnum.RestAPIResponceSuccessMessage)
      // );
      if (responseJson) {
        this.setState({ placeExchangeOrderData: responseJson.data });
        this.setState({ isOrderPlaced: true });
        localStorage.removeItem("exchangeFlow")
        localStorage.removeItem("exchangeItemOrderId")
        localStorage.removeItem("addressId")
        console.log('////////////// place Exchange order------------------------------------------------------', this.state.placeExchangeOrderData)
      }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // place exchange order end



  }

  handlePaymentCapture = () => {
    if (this.state.razorpay_order_id || this.state.selectedPaymentMethod === 'online_payment') {
      // if online payment display razor pay dashboard then on success place order modal
      this.displayrazorPayDashBoard();
    } else {
      // display order place modal on cash on delivery page

      if (this.state.exchangeFlow) {
        this.placeExchangeorder()
      } else { this.setState({ isOrderPlaced: true }); }
    }
  }



  // exchange item function 
  placeExchangeorder(
    // productid: any, variantId: any
  ) {
    // console.log(Number(productid), variantId)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      order_item_id: Number(this.state.exchangeItemOrderId),
      delivery_address_id: Number(this.state.addressId),
      service_type: "exchange",
      // reason_for_exchange: "size is too short",
      //address id
      // additional_comments: "take additional comment whatever you want",
      // reason_to_exchange_id: 1,//pass the reasonforexchange id here
      // others: "dummy"


    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PlaceExchangeOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PlaceExchangeOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }



  displayrazorPayDashBoard = () => {

    console.log("name :::", this.state.customerDetail?.name)
    console.log("email :::", this.state.customerDetail?.email)
    console.log("razor pay key ============> :::", process.env.REACT_APP_RAZOR_PAY_KEY);
    console.log("api base url ============> :::", process.env.REACT_APP_API_PATH);
    console.log("environent Type ============> :::", process.env.NODE_ENV);

    let liveModeKey = 'rzp_live_aF2ccNy23Ih8PG';
    // let testModeKey = 'rzp_test_EWw42eQCZDrENt';
    let testModeKey = 'rzp_test_S9BTYeyJB8OOOR';   //updated key
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: this.state.priceDetail?.total,
      currency: "INR",
      name: "Caelum",
      description: "Test Transaction",
      image: "https://cdn.razorpay.com/logos/IrSTISPNKoYeoS_medium.png",
      order_id: this.state.razorpay_order_id,
      handler: (res: any) => {
        console.log("authorize response:::", res);
        this.FetchCapturePaymentApi(res.razorpay_payment_id)
      },
      prefill: {
        name: this.state.customerDetail?.name,
        email: this.state.customerDetail?.email,
        contact: this.state.customerDetail?.full_phone_number,
      },
      modal: {
        handleback: false,
        escape: false
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new (window as any).Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      // handle failure scenario
      // display sweet toast failure alert with order_id and payment id
      console.log("payment failed:::", response);

      this.failedPayment(response.error);

      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
  };

  failedPayment = (errorResponse: any) => {
    // set isPaymet faied to true and desripyion and payment id
    console.log("inside failed payment");
    const container = document.getElementsByClassName("razorpay-container");
    console.log("container::", container);
    this.setState({ isPaymentFailed: true, errorResponse: errorResponse });
    // razorpay-container is a css className for razor pay modal
    (document.getElementsByClassName("razorpay-container")[0] as any).style.display = 'none'; // hide razor pay model and display failure modal
  }

  showRazorPayModel = () => {
    (document.getElementsByClassName("razorpay-container")[0] as any).style.display = 'block'; // display razor pay model and hide failure modal
    this.setState({ isPaymentFailed: false, errorResponse: {} });
  }
  PriceDetailApi = () => {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPriceDetailApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shoppingPriceAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  getActiveCartApi() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getActiveCartApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ActiveCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }

  PlaceOrderApi = () => {

    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpbody = {
      cart_id: this.state.cartId,
      payment_method: this.state.selectedPaymentMethod
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.placeOrderApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }

  // for capture payment on backend database
  // note : this transaction will we auto captured on razor pay dashboard
  FetchCapturePaymentApi = (razorpay_payment_id: string) => {

    this.setState({ loading: true })

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.fetchCapturePaymentApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchPaymentAPIEndPoint + `?order_id=${this.state.cartId}&payment_id=${razorpay_payment_id}`
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }

  goToHomePage = () => {
    localStorage.setItem('cartItemCount', "0");
    // no item should in cart
    this.props.handleCartCount(0);
    this.props.navigation.navigate('Home');
  }
}
