Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderDetailView";
exports.labelBodyText = "OrderDetailView Body";
exports.btnExampleTitle = "CLICK ME";
// myorderdetails alfred 
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST"
exports.shoppingPriceAPIEndPoint = "bx_block_shopping_cart/price_detail_of_cart"
exports.ReturnOrderAPiEndPoint = "bx_block_order_management/return_or_exchange"
exports.deliveryAddressSelectedForOrderAPiEndPoint = "order_management/orders/174/add_address_to_order"
exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.specificOrderDataAPiEndPoint = "bx_block_order_management/orders/"
exports.pickupAddressAPiEndPoint = "bx_block_order_management/addresses/"

exports.GetMethodType = "GET";
exports.PostMethodType = "POST";
exports.PutMethodType = "PUT";
exports.PatchMethodType = "PATCH";
exports.DeleteMethodType = "DELETE";

exports.btnExampleTitle = "CLICK ME";
exports.customerOrderListApiEndPoint = "bx_block_order_management/orders";
exports.cancelReasonApiEndPoint = "bx_block_order_management/reason_to_cancle";
exports.returnReasonApiEndPoint = "bx_block_order_management/reason_to_returns"
exports.cancelOrderApiEndPoint = "bx_block_order_management/cancel_order";
exports.customerReviewApiEndPoint = "catalogue/reviews";
// customer address book apis 
exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.addNewAddressAPiEndPoint = "bx_block_order_management/addresses";
exports.deleteAddressAPiEndPoint = "bx_block_order_management/addresses/";
exports.editAddressAPiEndPoint = "bx_block_order_management/addresses/";
exports.getProfileAPiEndPoint = "account_block/account"
exports.editProfileAPiEndPoint = "account_block/account"
exports.emailOtpAPiEndPoint = "account_block/account/send_otp?email="
exports.phoneOtpAPiEndPoint = "account_block/account/send_otp?phone_number="
exports.emailVerifyAPiEndPoint = "account_block/account/email_confirm"
exports.PhoneVerifyAPiEndPoint = "account/accounts/sms_confirmation"
exports.cancelItemApiEndPoint = "cancel_order_item"
exports.activateCartApi = "bx_block_shopping_cart/get_active_cart";
exports.addToWishlistApi = "bx_block_wishlist2/wishlist/add_to_wishlist"
exports.RemoveShoppingBagAPIEndPoint = "bx_block_shopping_cart/cart_items";

// Customizable Area End