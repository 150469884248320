Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
var helper = require('../../../framework/src/Helpers')
exports.mobileClientID =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
// exports.webClientID = "48089517582-pfbeav53netvicp8rimhf95majr35bag.apps.googleusercontent.com";
exports.webClientID = "962575695421-8543lb6ngn46br9sfobliqe28oboniei.apps.googleusercontent.com";
// exports.clientID = helper.getOS() === 'web' ? "48089517582-pfbeav53netvicp8rimhf95majr35bag.apps.googleusercontent.com" 
                                            // : "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.clientID = helper.getOS() === 'web' ? "962575695421-8543lb6ngn46br9sfobliqe28oboniei.apps.googleusercontent.com" 
                                            : "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";

exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";

exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";

exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";

exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
// Customizable Area End