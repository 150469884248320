import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@material-ui/core";
import { shopping, delivered, cancelled, refund } from "./assets";
import OrderListController, { Props } from "./CustomerOrderListController.web";
import Loader from "../../../components/src/Loader.web";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";
import WriteReviewModal from "./WriteReviewModal.web";
import { CatalogueVariantModel, imageModel, ProductModel, ReviewModal } from "../../../framework/src/Interfaces/IProductDetail";
import PaginationComponent from "../../../components/src/shared/pagination";
import { OrderItemModel, OrderModel, Review } from "../../../framework/src/Interfaces/IOrderDetail";

const styles = {
    pageOuter: {
        padding: '50px 0',
        "& .order-title-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 0 20px",
            borderBottom: "1px solid #e6e6e6",
        },
        "& .order-title-wrapper .select-border": {
            margin: "0",
        },
        "& h2": {
            fontSize: "18px",
            lineHeight: "22px",
            color: "#121212",
            fontWeight: "700",
            margin: "0",
            textTransform: "uppercase",
            '@media (max-width:767px)': {
                margin: "0 0 10px",
            }
        },
        "& .user-detail-wrapper": {
            padding: "30px 20px",
            background: "#f3f3f3",
            '@media (max-width:1024px)': {
                padding: "20px",
            },
            '@media (max-width:767px)': {
                padding: "15px",
            }
        },
        "& .user-detail-wrapper .user-detail-inner:not(:last-child)": {
            margin: "0 0 20px",
        },
        "& .user-detail-inner": {
            background: "#fff",
            padding: "23px 20px",
            '@media (max-width:1024px)': {
                padding: "20px",
            },
            '@media (max-width:767px)': {
                padding: "15px",
            }
        },
        "& .product-order-detail": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 0 20px",
            flexWrap: "wrap",
        },
        "& .order_details": {
            display: "flex",
            alignItems: "center",
            '@media (max-width:575px)': {
                width: "100%",
                margin: " 0 0 15px",
            }
        },
        "& .product-order-detail p": {
            margin: "0",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#212121",
            fontWeight: "400",
        },
        "& .order_details  .order-info": {
            fontWeight: "600",
        },
        "& .order_details  .order-info.shipping": {
            color: "#517ba3",
        },
        "& .order_details  .order-info.delivered": {
            color: "#21c998",
        },
        "& .order_details  .order-info.cancelled": {
            color: "#f94a59",
        },
        "& .order_details  .order-info span": {
            color: "#212121",
        },
        "& .order-info-img": {
            maxWidth: "36px",
            margin: "0 10px 0 0",
        },
        "& .order-id p": {
            color: "#5d5d66",
        },
        "& .order-id p span": {
            color: "#121212",
            fontWeight: "500",
        },
        "& .productlist-wrapper": {
            padding: "0",
        },
        "& .productlist-wrapper .product-list": {
            border: "0",
            background: "#f6f6f6",
            padding: "15px",
            position: "relative",
            '@media (max-width:767px)': {
                padding: "10px",
            },
            cursor: "pointer"
        },
        "& .productlist-wrapper .product-list .product-img": {
            width: "55px",
            height: "70px",
        },
        "& .productlist-wrapper .product-list .product-detail": {
            paddingLeft: "12px",
        },
        "& .productlist-wrapper .product-name": {
            fontSize: "16px",
            lineHeight: "20px",
            '@media (max-width:767px)': {
                fontSize: "14px",
                lineHeight: "18px",
            }
        },
        "& .productlist-wrapper .product-desc": {
            color: "#5d5d66",
            margin: "0 0 7px"
        },
        "& .productlist-wrapper .product-desc.size-qty": {
            margin: "0 0 0",
        },
        "& .order-cancel": {
            position: "absolute",
            right: "15px",
            bottom: "15px",
        },
        "& .order-cancel a": {
            fontSize: "14px",
            lineHeight: "16px",
            color: "#0057ff",
            fontWeight: "600",
            textDecoration: "none",
        },
        "& .rating-blog": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #e3e3e3",
            marginTop: "11px",
            padding: "10px 0 0",
            '@media (max-width:767px)': {
                flexWrap: "wrap",
            }
        },
        "& .rating": {
            display: "flex",
            alignItems: "center",
            '@media (max-width:767px)': {
                flexWrap: "wrap",
            }
        },
        "& .rating p": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#b6b6b6",
            margin: "0 12px 0 0",
        },
        "& .rating .product-rating .MuiIconButton-root": {
            padding: "0",
            margin: "0 9px 0 0",
        },
        "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label span": {
            display: "none",
        },
        "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label .fill": {
            display: "inline-block",
        },
        "& .rating .product-rating .MuiIconButton-root .fill": {
            display: "none",
        },
        "& .rating .product-rating .MuiIconButton-root .fill svg": {
            color: "#ffc107",
        },
        "& .rating .product-rating .MuiSvgIcon-root": {
            color: "#b6b6b6",
            width: "30px",
            height: "30px",
        },
        "& .write-review a": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#ff8030",
            fontWeight: "600",
            textDecoration: "none",
        },
        "& .active-path": {
            fontWeight: "600",
            textDecoration: "none"
        },
    },
};
// My Order End

export class CustomerOrderList extends OrderListController {
    constructor(props: Props) {
        super(props);
        this.elementRef = React.createRef();
    }

    getStatusColorByStatus = (order_status: string) => {
        const statusColor =
            order_status === ORDER_STATUS_TYPES.CONFIRMED ? 'confirmed' :
                order_status === ORDER_STATUS_TYPES.DELIVERED ? 'delivered' :
                    order_status === ORDER_STATUS_TYPES.RTO ? 'rto' :
                        order_status === ORDER_STATUS_TYPES.CANCELLED ? 'cancelled' :
                            order_status === ORDER_STATUS_TYPES.REFUND_PROCESSED ? 'refunded' :
                                order_status === ORDER_STATUS_TYPES.EXCHANGE ? 'exchange' : '';
        return statusColor;
    }

    callCustomerOrders = (): void => {
        console.log("function called by props:::")
        this.setState({ openAddReviewModal: false }, () => this.getCustomerOrders(''));
        this.getCustomerOrders('');
    }

    renderOrderList = () => {
        return (
            <>
                <Grid item xs={12} ref={this.elementRef}>
                    <div className="order-title-wrapper">
                        <h2></h2>
                        <div className="select-border">
                            <span className="select-label">Sort by: </span>
                            <Select
                                labelId="product-category"
                                id="demo-simple-select"
                                displayEmpty
                                value={this.state.filterValue}
                                onChange={(e: any) => { this.setState({ filterValue: e?.target?.value, currentPage: 1 }, () => this.getCustomerOrders(this.state.filterValue)) }}
                            >
                                <MenuItems value=''>All Orders</MenuItems>
                                <MenuItems value="open_orders">Open Orders</MenuItems>
                                <MenuItems value="returned">Return Orders</MenuItems>
                                <MenuItems value="cancelled">Cancelled Orders</MenuItems>
                                <MenuItems value="refunded">Refund Orders</MenuItems>
                            </Select>
                        </div>
                    </div>
                </Grid>
                <div className="user-detail-wrapper">
                    {this.state.orders &&
                        this.state.orders.length ?
                        this.state.orders.map((order: OrderModel) => {

                            const orderId = order.id;
                            const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details.length;
                            const orderNumber = order && order.attributes && order.attributes.order_number;
                            const orderDate = order && order.attributes && order.attributes.updated_at;
                            const updatedOrderDate = moment(orderDate).format('ddd, ll');

                            const cancleOrderDate = order && order.attributes && order.attributes.cancelled_at;
                            const formattedCancelOrderDate = moment(cancleOrderDate).format('ddd, ll');

                            const orderItems: OrderItemModel[] = order && order.attributes && order.attributes.order_items_details;
                            let status: string = order && order.attributes && order.attributes.status;
                            return (<>

                                {/* Placed Order */}
                                {status === ORDER_STATUS_TYPES.PLACED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={shopping} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info shipping">Confirmation Pending <span>{noOfItemsInOrder} Item</span></p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>
                                                    Order ID: <span>{orderNumber}</span>
                                                    <p className="cancel-btn">
                                                        <Link
                                                            onClick={(e: any) => {
                                                                e.stopPropagation()
                                                                this.setState({
                                                                    displayCancelOrderModal: true,
                                                                    cancelOrderId: orderNumber,
                                                                    order: order
                                                                });
                                                            }}>

                                                            Cancel Order
                                                        </Link>
                                                        {/* </span></p> */}
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item: OrderItemModel) => {
                                                const product_name = item?.product_name;
                                                const brand_name = item?.brand;
                                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                                const size = item?.measurement_size_value;
                                                const order_status = item?.status;
                                                const statusColor = this.getStatusColorByStatus(order_status);
                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                    <div className="product-desc size-qty">
                                                                        <span>Size: {size}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-cancel">
                                                                <span className={`order-status ${statusColor}`}>{order_status}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/* Order Processing from Vednor */}
                                {status === ORDER_STATUS_TYPES.CONFIRMED || status === ORDER_STATUS_TYPES.READY_TO_SHIP || status === ORDER_STATUS_TYPES.SHIPPED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={shopping} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info shipping">Processing <span>{noOfItemsInOrder} Item</span></p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                                const product_name = item?.product_name;
                                                const brand_name = item?.brand;
                                                const size = item?.measurement_size_value;
                                                const order_status = item?.status;
                                                const statusColor = this.getStatusColorByStatus(order_status);
                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                    <div className="product-desc size-qty">
                                                                        <span>Size: {size}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-cancel">
                                                                <span className={`order-status ${statusColor}`}>{order_status}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {/* will implemet this shipping order after shiprocket integration */}

                                {/* Shipping Order */}
                                {/* {status === ORDER_STATUS_TYPES.READY_TO_SHIP ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={shopping} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info shipping">Shipping <span>{noOfItemsInOrder} Item</span></p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const catalogue_id = Number(item.catalogue_id);
                                                const catalogue_variant_id = Number(item.catalogue_variant_id);
                                                const catalogue: ProductModel = order &&
                                                    order.attributes &&
                                                    order.attributes.catalogue_details &&
                                                    (order.attributes.catalogue_details as any[]).length &&
                                                    (order.attributes.catalogue_details as any[]).find((catalogue: any) =>
                                                        catalogue.attributes.catalogue_variants_attributes.
                                                            some((variant: any) => Number(variant.id) === Number(catalogue_variant_id) &&
                                                                Number(variant.attributes.catalogue_id) === Number(catalogue_id)
                                                            )
                                                    );

                                                const catalogue_variant: CatalogueVariantModel = catalogue &&
                                                    catalogue.attributes &&
                                                    catalogue.attributes.catalogue_variants_attributes &&
                                                    (catalogue.attributes.catalogue_variants_attributes as any[]).length &&
                                                    (catalogue.attributes.catalogue_variants_attributes as any[]).find((variant: any) => Number(variant.id) === Number(catalogue_variant_id));

                                                const image = catalogue_variant?.attributes?.galleries?.find((image: imageModel) => image.is_default === true); // if is_default image found then display that image
                                                const imageUrl = image?.url || catalogue_variant?.attributes?.galleries?.[0]?.url || '';
                                                const product_name = catalogue_variant?.attributes?.product_name;
                                                const description = catalogue?.attributes?.description;
                                                const size = catalogue_variant?.attributes?.measurement_size_value;
                                                const color = catalogue_variant?.attributes?.color;
                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                    <div className="product-desc size-qty">
                                                                        <span>Size: {size}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rating-blog">
                                                                {!customerReview?.id &&
                                                                    <>
                                                                        <div className="rating">
                                                                            <p>Rate Product</p>
                                                                            <Rating name="read-only" value={0} readOnly />
                                                                        </div>
                                                                        <div className="write-review">
                                                                            <Link
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation()
                                                                                    this.setState({
                                                                                        openAddReviewModal: true,
                                                                                        selectedProductItemDetail: orderItemDetail
                                                                                    });
                                                                                }}>Write Review</Link>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {customerReview?.id &&
                                                                    <>
                                                                        <div className="rating">
                                                                            <Rating name="read-only" value={rating} readOnly />
                                                                        </div>
                                                                        <div className="write-review">
                                                                            <Link
                                                                                onClick={(e: any) => {
                                                                                             e.stopPropagation()
                                                                                    this.setState({
                                                                                        openDeleteReviewModal: true,
                                                                                        reviewId: Number(customerReview?.id) || 0
                                                                                    });
                                                                                }}>Delete Review</Link>
                                                                        </div>
                                                                    </>
                                                                }
                                                            <div className="order-cancel">
                                                                <Link
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation()
                                                                        this.setState({
                                                                            displayCancelOrderModal: true,
                                                                            cancelOrderId: orderNumber,
                                                                            order: order
                                                                        });
                                                                    }}>Cancel Order</Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                } */}

                                {/* Delivered Order */}
                                {status === ORDER_STATUS_TYPES.DELIVERED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={delivered} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info delivered">Delivered <span>{noOfItemsInOrder} items</span></p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const catalogue_id = Number(item.catalogue_id);
                                                const catalogue_variant_id = Number(item.catalogue_variant_id);

                                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                                const product_name = item?.product_name;
                                                const brand_name = item?.brand;
                                                const description = item?.description;
                                                const size = item?.measurement_size_value;
                                                const color = item?.color;
                                                const order_status = item?.status;
                                                const statusColor = this.getStatusColorByStatus(order_status);

                                                // need to change
                                                // const reviews = catalogue?.attributes?.review?.data && catalogue.attributes.review.data.length ? catalogue.attributes.review.data : [];
                                                const reviews: Review[] = item.review || [];

                                                let customerReview: Review | undefined;
                                                let rating: number | undefined = 0;
                                                if (reviews && reviews.length) {
                                                    customerReview = reviews.find((review: Review) => review.catalogue_id === catalogue_id &&
                                                        review.account_id === this.state.accountId);
                                                    rating = customerReview ? customerReview.rating : undefined;
                                                }
                                                const orderItemDetail = {
                                                    orderId,
                                                    catalogue_id,
                                                    product_name,
                                                    description,
                                                    imageUrl,
                                                    size,
                                                    color
                                                };
                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <Grid container>
                                                                <Grid item xs={10}>
                                                                    <div className="product-list-inner">
                                                                        <div className="product-img">
                                                                            <img className="img-block" src={imageUrl} />
                                                                        </div>
                                                                        <div className="product-detail">
                                                                            <div className="product-heading">
                                                                                <h4 className="product-name">{product_name}</h4>
                                                                            </div>
                                                                            <div className="product-desc">
                                                                                {brand_name}
                                                                            </div>
                                                                            <div className="product-desc size-qty">
                                                                                <span>Size: {size}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <div className="flex-end">
                                                                        <span className={`order-status ${statusColor}`}>{order_status}</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <div className="rating-blog">
                                                                {!customerReview?.id &&
                                                                    <>
                                                                        <div className="rating">
                                                                            <p>Rate Product</p>
                                                                            <Rating name="read-only" value={0} readOnly />
                                                                        </div>
                                                                        <div className="write-review">
                                                                            <Link
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({
                                                                                        openAddReviewModal: true,
                                                                                        selectedProductItemDetail: orderItemDetail
                                                                                    });
                                                                                }}>Write Review</Link>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {customerReview?.id &&
                                                                    <>
                                                                        <div className="rating">
                                                                            <Rating name="read-only" value={rating} readOnly />
                                                                        </div>
                                                                        <div className="write-review">
                                                                            <Link
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({
                                                                                        openDeleteReviewModal: true,
                                                                                        reviewId: Number(customerReview?.id) || 0
                                                                                    });
                                                                                }}>Delete Review</Link>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/* will implement CONFIRMED of this functionality in next build */}
                                {/* Exchange Delivered Order */}
                                {/* {status === ORDER_STATUS_TYPES.EXCHANGE_DELIVERED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={delivered} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info delivered">Exchange Delivered</p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const catalogue_id = Number(item.catalogue_id);
                                                const catalogue_variant_id = Number(item.catalogue_variant_id);
                                                const catalogue: ProductModel = order &&
                                                    order.attributes &&
                                                    order.attributes.catalogue_details &&
                                                    (order.attributes.catalogue_details as any[]).length &&
                                                    (order.attributes.catalogue_details as any[]).find((catalogue: any) =>
                                                        catalogue.attributes.catalogue_variants_attributes.
                                                            some((variant: any) => Number(variant.id) === Number(catalogue_variant_id) &&
                                                                Number(variant.attributes.catalogue_id) === Number(catalogue_id)
                                                            )
                                                    );

                                                
                                                const catalogue_variant: CatalogueVariantModel = catalogue &&
                                                    catalogue.attributes &&
                                                    catalogue.attributes.catalogue_variants_attributes &&
                                                    (catalogue.attributes.catalogue_variants_attributes as any[]).length &&
                                                    (catalogue.attributes.catalogue_variants_attributes as any[]).find((variant: any) => Number(variant.id) === Number(catalogue_variant_id));

                                                const image = catalogue_variant?.attributes?.galleries?.find((image: imageModel) => image.is_default === true); // if is_default image found then display that image
                                                const imageUrl = image?.url || catalogue_variant?.attributes?.galleries?.[0]?.url || '';
                                                const product_name = catalogue_variant?.attributes?.product_name;
                                                const description = catalogue?.attributes?.description;
                                                const size = catalogue_variant?.attributes?.measurement_size_value;
                                                const color = catalogue_variant?.attributes?.color;
                                                const reviews = catalogue?.attributes?.review?.data && catalogue.attributes.review.data.length ? catalogue.attributes.review.data : [];

                                                let customerReview: ReviewModal | undefined;
                                                let rating: number | undefined = 0;
                                                if (reviews && reviews.length) {
                                                    customerReview = reviews.find(review => review.attributes.order_id === orderId);
                                                    rating = customerReview?.attributes.rating;
                                                }
                                                return (
                                                    <>
                                                        <div className="product-list"   onClick = {()=> this.props.navigation.navigate("MyOrderDetail", { id: orderId  })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rating-blog">
                                                                <div className="rating">
                                                                    <p>Rate Product</p>
                                                                    <Rating name="read-only" value={rating} readOnly />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                } */}
                                {/* Cancelled Order */}
                                {status === ORDER_STATUS_TYPES.CANCELLED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={cancelled} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info cancelled">Cancelled</p>
                                                    <p className="order-date">On {formattedCancelOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const product_name = item?.product_name;
                                                const brand_name = item?.brand;
                                                const size = item?.measurement_size_value;
                                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                                const order_status = item?.status;
                                                const statusColor = this.getStatusColorByStatus(order_status);

                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                    <div className="product-desc size-qty">
                                                                        <span>Size: {size}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-cancel">
                                                                <span className={`order-status ${statusColor}`}>{order_status}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/* Refund Order */}
                                {status === ORDER_STATUS_TYPES.REFUND_PROCESSED ?
                                    <div className="user-detail-inner">
                                        <div className="product-order-detail">
                                            <div className="order_details">
                                                <div className="order-info-img">
                                                    <img src={refund} alt="" />
                                                </div>
                                                <div className="order-info-content">
                                                    <p className="order-info refund">Refund Processed</p>
                                                    <p className="order-date">On {updatedOrderDate}</p>
                                                </div>
                                            </div>
                                            <div className="order-id">
                                                <p>Order ID: <span>{orderNumber}</span></p>
                                            </div>
                                        </div>
                                        <div className="productlist-wrapper">
                                            {orderItems.map((item) => {
                                                const product_name = item?.product_name;
                                                const brand_name = item?.brand;
                                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                                const order_status = item?.status;
                                                const statusColor = this.getStatusColorByStatus(order_status);

                                                return (
                                                    <>
                                                        <div className="product-list" onClick={() => this.props.navigation.navigate("MyOrderDetail", { id: orderId })}>
                                                            <div className="product-list-inner">
                                                                <div className="product-img">
                                                                    <img className="img-block" src={imageUrl} />
                                                                </div>
                                                                <div className="product-detail">
                                                                    <div className="product-heading">
                                                                        <h4 className="product-name">{product_name}</h4>
                                                                    </div>
                                                                    <div className="product-desc">
                                                                        {brand_name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-cancel">
                                                            <span className={`order-status ${statusColor}`}>{order_status}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </>
                            )
                        })
                        :
                        <div style={{ textAlign: "center" }}>
                            No Order Found
                        </div>
                    }

                    {/* pagination */}
                    {this.state.totalPage ?
                        <PaginationComponent
                            handlePageChange={this.handlePageChange}
                            totalPage={this.state.totalPage}
                            currentPage={this.state.currentPage}
                        /> : null
                    }
                </div>
                {this.state.openAddReviewModal &&
                    <WriteReviewModal
                        navigation={this.props.navigation}
                        callBackFunction={this.callCustomerOrders}
                        hideWriteReviewModal={this.hideWriteReviewModal}
                        returnItem={this.state.selectedProductItemDetail}
                    />
                }
            </>
        );
    }
    render() {
        const { classes } = this.props;
        return (
            <>

                {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}

                <CustomerAccountSideBarWeb 
                    id={1} 
                    navigation={this.props.navigation} 
                    children={this.renderOrderList()} 
                    callbackShopWithFriend={this.props.callbackShopWithFriend}
                />

                {/* cancel order */}
                <Dialog
                    aria-labelledby="customized-dialog-duplicate"
                    open={this.state.displayCancelOrderModal}
                    className="cancel-order order-dialog"
                >
                    <DialogTitle id="customized-dialog-title">
                        <CloseIcon
                            className="close-dialog"
                            onClick={(e) => {
                                this.setState({ displayCancelOrderModal: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: "370px" }}>
                        <h2 className="modal-heading">
                            Are you sure you want to cancel the order?
                        </h2>
                        <div className="sub-txt">Order ID: {this.state.cancelOrderId}</div>
                        <div className="btn-wrapper">
                            <Button
                                href=""
                                type="submit"
                                variant="contained"
                                className="outline-btn"
                                onClick={(e) => { this.setState({ displayCancelOrderModal: false }) }}
                            >
                                No
                            </Button>
                            <Button
                                href=""
                                type="submit"
                                variant="contained"
                                className="black-btn"
                                onClick={(e) => {
                                    this.goToCancelOrderScreen();
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* delete review */}
                <Dialog
                    aria-labelledby="customized-dialog-duplicate"
                    open={this.state.openDeleteReviewModal}
                    className="cancel-order order-dialog"
                >
                    <DialogTitle id="customized-dialog-title">
                        <CloseIcon
                            className="close-dialog"
                            onClick={(e) => {
                                this.setState({ openDeleteReviewModal: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: "370px" }}>
                        <h2 className="modal-heading">
                            Are you sure you want to delete the review?
                        </h2>
                        {/* <div className="sub-txt">Order ID: {this.state.cancelOrderId}</div> */}
                        <div className="btn-wrapper">
                            <Button
                                href=""
                                type="submit"
                                variant="contained"
                                className="outline-btn"
                                onClick={(e) => { this.setState({ openDeleteReviewModal: false }) }}
                            >
                                No
                            </Button>
                            <Button
                                href=""
                                type="submit"
                                variant="contained"
                                className="black-btn"
                                onClick={(e) => {
                                    this.deleteReview();
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* Exchange Item */}
                <Dialog
                    aria-labelledby="customized-dialog-duplicate"
                    open={this.state.exchangeItems}
                    className="select-dialog"
                >
                    <DialogTitle id="customized-dialog-title">
                        <span>Exchange Item</span>
                        <CloseIcon
                            className="close-dialog"
                            onClick={(e) => {
                                this.setState({
                                    exchangeItems: false,
                                });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent style={{ width: "370px" }}>
                        <RadioGroup className="custom-radio mrb-25" name="price">
                            <FormControlLabel
                                value="yes"
                                control={<Radio color="default" />}
                                label="Use All Available Points"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio color="default" />}
                                label="Enter Points"
                            />
                        </RadioGroup>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
export default withStyles(styles)(CustomerOrderList);
