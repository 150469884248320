

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import { setTimeout } from "timers";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    handleCartCount(number: Number): Function;
    // Customizable Area End
}
interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    data: any;
    data1: any;
    data2: any;
    blogData: any;
    search: any;
    isLoading: boolean;
    Eaddress: any;
    radioBtn: boolean;
    loading: boolean;
    state: string;
    city: string;
    mobile: number | string;
    email: string;
    town: string;
    name: string;
    address: string;
    pincode: string | number;
    save_add: string;
    save_add_Bol: boolean;
    addressID: string | number;
    Checked: number;
    popup: boolean;
    navigate: boolean;
    status: string;
    edith_state: string;
    listCategory: any;
    edith_city: string;
    edith_town: string;
    edith_name: string;
    active: any;
    edith_address: string;
    edith_pincode: string | number;
    token: string | null;
    addressEditData: any;
    is_defulat: boolean;
    visible: boolean;
    home: boolean;
    category: boolean;
    new: boolean;
    offer: boolean;
    languageList: any;
    data3: any;
    brandData: any;
    SearchTitle: any;
    banner: any;
    collection: any;
    key: string;
    allBlog: any;
    fsBlog: any;
    beautyBlog: any;
    luxuryBlog: any;
    detailValue: boolean;
    dataBlogDetail: any;
    categoryId: any;
    tabView: any;
    navCategory: any;
    anchorEl: any;
    open: boolean;
    isActive: boolean;
    subMenuActive: boolean;
    categoryid: any;
    collectiondata: any;
    render: any;
    toggle: any;
    wishlist: any;
    subcategories: any;
    placeOrderData: any;
    catalogueId: any;
    catalogueVariantId: any;
    activeCartId: any;
    productSize: boolean;
    productQty: boolean;
    applyCoupon: boolean;
    payment: boolean;
    ShoppingCartData: any;
    wishlistCartLoading: boolean;
    isAddedToBag: boolean;
    isRemoved: boolean;
    isDelete: boolean;
    wishlistRemoveBag: any;
    removeLoyaltyPoints: any;
    Removeappliedcoupon: any;
    exchangeFlow: boolean;
    cartItemCount: any;
    messagePopup: boolean;
    cartCount: any
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class WishlistController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start

    AddToWishCallId: any;
    RemoveToWishCallId: any;
    WishlistCallId: any;
    PlaceOrderCallId: any;
    ActiveCartCallId: any;
    ShoppingCartDataCallId: any;
    RemoveShoppingBagCallId: any;
    removeLoyaltyPointsCallId: any;
    RemoveappliedcouponDataCallId: any





    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            data: [],
            data1: [],
            data2: [],
            data3: [],
            dataBlogDetail: [],
            tabView: "",
            detailValue: false,
            blogData: [],
            categoryId: [],
            search: [],
            listCategory: [],
            brandData: [],
            isLoading: false,
            active: 0,
            Eaddress: [],
            radioBtn: false,
            key: "",
            home: true,
            collection: [],
            category: false,
            new: false,
            offer: false,
            state: "",
            city: "",
            mobile: 0,
            email: "",
            loading: false,
            town: "",
            SearchTitle: "",
            name: "",
            address: "",
            pincode: "",
            edith_state: "",
            edith_city: "",
            edith_town: "",
            edith_name: "",
            edith_address: "",
            edith_pincode: "",
            save_add: "home",
            save_add_Bol: true,
            addressID: "",
            Checked: 0,
            popup: false,
            navigate: false,
            status: "",
            token: "",
            addressEditData: {},
            is_defulat: false,
            visible: false,
            banner: [],
            allBlog: true,
            fsBlog: false,
            beautyBlog: false,
            luxuryBlog: false,
            languageList: ["WOMEN", "MEN", "TECH & TRAVEL", "LIFESTYLE"],
            navCategory: true,
            anchorEl: null,
            open: false,
            isActive: false,
            subMenuActive: false,
            categoryid: localStorage.getItem("dataid"),
            collectiondata: [],
            render: "",
            toggle: "",
            wishlist: [],
            subcategories: [],
            placeOrderData: [],
            catalogueId: 0,
            catalogueVariantId: 0,
            activeCartId: [],
            productSize: false,
            productQty: false,
            applyCoupon: false,
            payment: false,
            ShoppingCartData: [],
            wishlistCartLoading: false,
            isAddedToBag: false,
            isRemoved: false,
            isDelete: false,
            wishlistRemoveBag: [],
            removeLoyaltyPoints: [],
            Removeappliedcoupon: [],
            exchangeFlow: false,
            cartItemCount: 0,
            messagePopup: false,
            cartCount: 0


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );
            this.setState({ txtSavedValue: value });
        }
        // Customizable Area Start






        // wishlist start 

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.WishlistCallId !== null &&
            this.WishlistCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.WishlistCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.data) {
                this.setState({ wishlist: responseJson.data });
                this.setState({ wishlistCartLoading: false })

                console.log('////////////// wishlist data data------------------------------------------------------', this.state.wishlist)
            }
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);

        }


        // wishlist end 

        // shopping cart data start 
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.ShoppingCartDataCallId !== null &&
            this.ShoppingCartDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.ShoppingCartDataCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.data) {
                this.setState({ ShoppingCartData: responseJson.data }, () => {
                    const cartCount: any = responseJson.data.length
                    this.setState({ cartCount: cartCount })
                    const cartItemList: any[] = responseJson.data;
                    if (cartItemList && cartItemList.length) {
                        localStorage.setItem('cartItemCount', cartItemList.length.toString());
                        this.props.handleCartCount(cartItemList.length);
                    } else {
                        localStorage.setItem('cartItemCount', "0");
                        this.props.handleCartCount(0);
                        this.setState({ cartCount: 0 })
                    }
                })
            };
            // this.setState({ shoppingCartloading: false });
            console.log('////////////// ShoppingCartData data------------------------------------------------------', this.state.ShoppingCartData);
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }

        // shopping cart data end

        // place order start
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.PlaceOrderCallId !== null &&
            this.PlaceOrderCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.PlaceOrderCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)

            );
            this.setState({ placeOrderData: responseJson.data }, () => {
                this.shoppingCartData();
                this.setState({ isAddedToBag: true }, () => this.activeCartId())
                console.log('////////////// place order------------------------------------------------------', this.state.placeOrderData);
                // this.removeLoyaltyPoints()
            });


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // place order end 





        // active cart  id start
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.ActiveCartCallId !== null &&
            this.ActiveCartCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.ActiveCartCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            if (responseJson && responseJson.data) {
                const cartLength: any = responseJson.data.attributes.order_items_details.length
                this.setState({ activeCartId: responseJson.data })


            }
            else { this.setState({ activeCartId: "" }) }

            console.log('////////////// activeCartId data------------------------------------------------------', this.state.activeCartId);
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            }
            else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }

        // active cart id end 








        //  Wishlist Add start

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.AddToWishCallId !== null &&
            this.AddToWishCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.AddToWishCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            console.log('@@@@@@@@@@@@@@@@@@@@ AddWishAddWishAddWishAddWishAddWishAddWishAddWish------------------------------------------------------', responseJson.data);
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        //  Add WishWish  end



        // wishlist remove start

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.RemoveToWishCallId !== null &&
            this.RemoveToWishCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.RemoveToWishCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.Wishlist()
                console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove------------------------------------------------------', responseJson.data);
            }
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        //wishlist  end  Remove 




        //remove applied coupons data start 
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.RemoveappliedcouponDataCallId !== null &&
            this.RemoveappliedcouponDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.RemoveappliedcouponDataCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.data) {
                this.setState({ Removeappliedcoupon: responseJson.data });
                console.log('////////////// Removeappliedcoupon------------------------------------------------------', this.state.Removeappliedcoupon);
                //   this.activeCartId()
                //   this.shoppingPriceData()
            }
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }

        //remove applied coupons data end









        // remove  loyalty points start

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeLoyaltyPointsCallId !== null &&
            this.removeLoyaltyPointsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.removeLoyaltyPointsCallId = null;
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {

                this.setState({ removeLoyaltyPoints: responseJson });
                console.log('////////////// removeLoyaltyPoints------------------------------------------------------', this.state.removeLoyaltyPoints);
                //   this.activeCartId()
                //   this.shoppingPriceData()
                //   this.loyaltyPoints()

                //   this.setState({ loyaltyPointsError: "", useLoyaltyPoints: "" })

            }
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }

        // remove loyalty points end 


















    }
    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };
    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };
    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;
    btnExampleProps = {
        onPress: () => this.doButtonPressed(),
    };
    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }
    // Customizable Area Start
    async componentDidMount() {


        console.log("component rendered")

        try {
            const localToken = await localStorage.getItem("authToken")
            console.log(localToken, "tokentoken")

            this.setState({ token: localToken });





        } catch (e) {
            // error reading value
        }

        this.Wishlist()
        this.activeCartId()
        this.shoppingCartData()
        const exchangeFlow: any = localStorage.getItem("exchangeFlow")
        this.setState({ exchangeFlow: exchangeFlow })
        console.log(this.state.exchangeFlow, "exchange boolean")

    }
    handleClick = (event: any) => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };
    displaySubMenu = () => {
        this.setState({ isActive: true });
    }
    displayMegaMenu = () => {
        this.setState({ subMenuActive: true });
    }
    handleClickCategory = (ID: any) => {

        const id = ID
        this.setState({ categoryid: id })
        //  localStorage.setItem("dataid",id)



    }
    // status block for adding wishlist and removing wishlist 
    StatusBlog = async (data: any, id: any) => {
        if (data) {
            this.RemoveWishList(id)

        } else {
            this.AddWishList(id)


        }
    }

    // Active Cart id start 
    activeCartId() {

        // console.log("---------------->>>>>>> showCategories");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.ActiveCartCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ActiveCartAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;


    }
    // Active Cart Id end  


    removeLoyaltyPoints() {


        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token
        };


        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeLoyaltyPointsCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeLoyaltyPointsAPIEndPoint.concat(this.state.activeCartId.id)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // apiRequest.addData(
        //   getName(MessageEnum.RestAPIRequestBodyMessage),
        //   JSON.stringify(httpBody)
        // );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;


    }

    // apply loyalty points end 














    // shopping cart data start 
    shoppingCartData() {
        // this.setState({ shoppingCartloading: true });
        // console.log("---------------->>>>>>> showCategories");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.ShoppingCartDataCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ShoppingCartAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;



    }
    // shopping cart data end 


    // place order start
    placeorder(catvarid: any, catalogueid: any) {
        console.log(Number(catvarid), catalogueid)
        const catvariantid = Number(catvarid)

        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token
        };

        // const httpBody = {
        //     quantity: 1,
        //     catalogue_id: catalogueid,
        //     catalogue_variant_id: catvariantid ? catvariantid : null,
        //     cart_id: this.state.activeCartId ? this.state.activeCartId.id : null
        // };
        const httpBody =
            this.state.exchangeFlow ? {
                quantity: 1,
                catalogue_id: catalogueid,
                // catalogue_variant_id: Number(variantId),
                catalogue_variant_id: catvariantid ? catvariantid : null,
                type: "exchange", //#use this when you will create the new exchange order.
                order_item_id: localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
            } : {
                quantity: 1,
                catalogue_id: catalogueid,
                catalogue_variant_id: catvariantid ? catvariantid : null,
                cart_id: this.state.activeCartId ? this.state.activeCartId.id : null
            }
        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.PlaceOrderCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.PlaceOrderAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost);

        runEngine.sendMessage(apiRequest.id, apiRequest);
        console.log(this.state.activeCartId, "cart id here")
        return true;

    }


    // place ordeer end 





    //add WishList
    AddWishList(data: any) {
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        
        const httpBody = {
            "catalogue_ids": [data]
        }

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.AddToWishCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.AddWishlistAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }
    //remove whishlist
    RemoveWishList(data: any) {
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.RemoveToWishCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RemoveWishlistAPIEndPoint.concat(data)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }



    // remove coupon start 
    Removeappliedcoupon() {


        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token
        };

        const httpBody = {
            cart_id: this.state.activeCartId?.id,
            coupon_code: this.state.activeCartId?.attributes?.coupon_code

        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.RemoveappliedcouponDataCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RemoveappliedcouponAPIEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;


    }

    // remove coupon end 


    // getwishlist 
    Wishlist() {
        this.setState({ wishlistCartLoading: true });
        // console.log("---------------->>>>>>> showCategories");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.WishlistCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.WishlistAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    AddToBagMessage = () => {
        this.setState({ isAddedToBag: false });
    }
    RemovedMessage = () => {
        this.setState({ isRemoved: false });
    }




    // wishlistAddBag(item: any) {
    //     this.activeCartId();
    //     this.setState({ isAddedToBag: true })
    //     setTimeout(() => this.placeorder(item.attributes.catalogue.data.attributes.catalogue_variants_attributes[0]?.id, item.attributes.catalogue_id), 1500);
    //     this.RemoveWishList(item.attributes.catalogue_id);
    //     console.log(this.state.activeCartId.id)
    //     setTimeout(() => this.Wishlist(), 2000)


    // }

    wishlistAddBag(item: any) {
        // this.activeCartId();
        // if (this.state.activeCartId) {
        //     this.Removeappliedcoupon()
        //     this.removeLoyaltyPoints();
        // }
        // if (this.Removeappliedcoupon() &&
        //     this.removeLoyaltyPoints()) {
        // if (this.state.activeCartId) {
        //     this.removeLoyaltyPoints();
        // }

        // setTimeout(() => { 
        this.placeorder(item.attributes.catalogue.data.attributes.catalogue_variants_attributes[0]?.id, item.attributes.catalogue_id); this.RemoveWishList(item.attributes.catalogue_id)
        // }
        // }
        //     , 1500);

        // console.log(this.state.activeCartId.id)

    }




    wishlistRemoveBag() {
        if (this.state.wishlistRemoveBag) {
            this.RemoveWishList(this.state.wishlistRemoveBag.attributes.catalogue_id);
            setTimeout(() => this.Wishlist(), 1000)
            this.setState({ isRemoved: true })
        }
    }
    // close delete box 

    handleClose() {
        this.setState({
            isDelete: false
        });
    }

    handleOpenMessagePopup = () => {
        this.setState({ messagePopup: true })
    };

    handleCloseMessagePopup = () => {
        this.setState({ messagePopup: false })
    };































    // Customizable Area End
}


