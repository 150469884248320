export const AffiliateStyleWeb: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontFamily: "'Roboto',sans-serif",
      textTransform: "uppercase",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& p": {
      fontFamily: "'Roboto',sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .close-img": {
      margin: "9px",
      height: "15px",
      width: "15px",
    },
    "& .inner-close-title": {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      margin: "0 0 25px",
    },
    "& .inner-close-title h4": {
      margin: "0",
    },
    "& .inner-title-wrapper": {
      margin: "40px 0 0",
    },
    "& .inner-title-wrapper h4": {
      margin: "0 0 0",
    },
    "& .outline-btn-wrapper": {
      padding: "0 0 25px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
    "& .send-btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      margin: "30px 0 0",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
  },
};
