import React from "react";
import "../../../web/public/wishlist.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classNames from "classnames";
import "./HomeStore.Web.css";
import DashboardControllers, { Props, configJSON } from "./DashboardControllers.web";
import Loader from "../../../components/src/Loader.web"
// trending now imports
import { Link, Container } from "@material-ui/core";
const wishlistTrue = require("../assets/wishlistTrue.png");
const noProductImage = require("../assets/product.png");
// const { TopNav } = require("../../../components/src/TopNav.web");
// @ts-ignore
const wishlistHeart = require("../assets/wishlist-heart.png");
import "../../../../node_modules/@szhsin/react-menu/dist/index.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
  withStyles,
} from "@material-ui/core/styles";
import { HomeStoreStyle } from "./HomeStoreWebStyle";

const bannerSliderresponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const trendingnowResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const collectionResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const topBrandsResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const recentlyViewedResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  }

};


const CustomDot = ({ onClick, active }: { onClick: () => void; active: boolean; }) => {
  return (
    <button
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      className={classNames("custom-dot", {
        "custom-dot--active": active
      })}
    />
  );
};

export class HomeStore extends DashboardControllers {
  constructor(props: any) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {
          this.state.loading &&
          <Loader loading={this.state.loading} />
        }
        
        <div className={classes.pageOuter}>
          {/* banner slider */}
          {
            this.state.banner.length !== 0 &&
            <div className={classes.bannerSlider}>
              <div className="outer-wrap">
                <div className="row-wrap">
                  <Carousel
                    renderDotsOutside
                    responsive={bannerSliderresponsive}
                    infinite={true}
                    customDot={<CustomDot onClick={() => { }} active={false} />}
                    autoPlay={true}
                    autoPlaySpeed={8000}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "desktop"]}
                    swipeable={true}
                    draggable={true}
                  >
                    {this.state.banner.map((val: any) =>
                      <>
                        <div className="slides" key={val.id} >
                          {
                            val.attributes.banner_image ?
                              <img src={val.attributes.banner_image} onClick={()=>{
                                window.open(`${window.location.protocol}//${window.location.host}/Itemlist?cta_id=${val.attributes.cta}`, "_self");
                              }}/> :
                              <img src={noProductImage} />
                          }
                        </div>
                      </>
                    )}
                  </Carousel>
                </div>
              </div>
            </div>
          }

          {/* trending now */}
          {
            this.state.data3.length !== 0 &&
            <div className={classes.trendingNow}>
              <Container maxWidth="lg">
                <div className="outer-wrap">
                  <h2 className="title-block">
                    Trending Now
                  </h2>
                  <div className="row-wrap">
                    <Carousel
                      renderDotsOutside
                      responsive={trendingnowResponsive}
                      showDots={false}>
                      {this.state.data3.map((val: any) =>
                        <>
                          <div className="column" key={val.id} >
                            <Link className="inner-col" href={`/ProductDetail/${val.id}`} >
                              <div className="img-block">
                                {
                                  (val.attributes?.galleries?.[0]?.url) ?
                                    <img src={val.attributes?.galleries?.[0].url} /> :
                                    <img src={noProductImage} />
                                }
                              </div>
                              <div className="col-name">
                                <span>{val.attributes?.name}</span>
                              </div>
                            </Link>
                          </div>
                        </>
                      )}
                    </Carousel>
                  </div>
                </div>
              </Container>
            </div>
          }

          {/* collection */}
          {
            this.state.collection.length !== 0 &&
            <div className={classes.collection}>
              <Container maxWidth="lg">
                <div className="outer-wrap">
                  <h2 className="title-block">
                    Collection
                  </h2>
                  <div className="row-wrap">
                    <Carousel
                      renderDotsOutside
                      responsive={collectionResponsive}
                      showDots={false}
                    >
                      {
                        this.state.collection.map((val: any) => {
                          return <>
                            <div className="column" key={val.id} >
                              <Link className="inner-col" href={`/Itemlist?search=${val.attributes.name}`}>
                                <div className="img-block">
                                  {
                                    (val.attributes?.galleries && val.attributes?.galleries[0]?.url) ?
                                      <img src={val.attributes?.galleries?.[0].url} /> :
                                      <img src={noProductImage} />
                                  }
                                </div>
                                <div className="col-name">
                                  <span>{val.attributes.name}</span>
                                </div>
                              </Link>
                            </div>
                          </>
                        })}
                    </Carousel>
                  </div>
                </div>
              </Container>
              <div className="black-bg"></div>
            </div>
          }

          {/* Top brands */}
          {
            this.state.brandData.length !== 0 &&
            <div className={classes.TopBrands}>
              <Container maxWidth="lg">
                <div className="outer-wrap">
                  <h2 className="title-block">
                    Top Brands
                  </h2>
                  <div className="row-wrap">
                    <Carousel renderDotsOutside responsive={topBrandsResponsive} showDots={false} >
                      {this.state.brandData.map((val: any) => {
                        return <>
                          <div className="column" key={val.id} >
                            <Link className="inner-col" href={`/Itemlist?search=${val.attributes.name}`}>
                              <div className="img-block">
                                {
                                  val.attributes.image ?
                                    <img src={val.attributes.image} className="brand-img" /> :
                                    <img src={noProductImage} />
                                }
                              </div>
                              <div className="col-name">
                                <span>{val.attributes.name}</span>
                              </div>
                            </Link>
                          </div>
                        </>
                      })}
                    </Carousel>
                  </div>
                </div>
              </Container>
            </div>
          }

          {/* shop by price */}
          {
            this.state.data1.length !== 0 &&
            <div className={classes.shopByPrice}>
              <Container maxWidth="lg">
                <div className="outer-wrap">
                  <h2 className="title-block">
                    Shop By Price
                  </h2>
                  <div className="row-wrap">
                    {
                      this.state.data1.map((val: any) => (
                        <>
                          <div className="column green-border" key={val.id} onClick={() => {
                            window.open(`${window.location.protocol}//${window.location.host}/Itemlist?cta_id=${val.attributes.cta}`, "_self");
                          }}>
                            <div className="inner-col">
                              <div className="left-block">
                                <div className="inner-left-block">
                                  <span className="discount">{val.attributes.price}</span>
                                  <Link className="shop-now">shop now</Link>
                                </div>
                              </div>
                              <div className="img-block">
                                {
                                  val.attributes.shop_image ?
                                    <img src={val.attributes.shop_image} /> :
                                    <img src={noProductImage} />
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    }
                  </div>
                </div>
              </Container>
            </div>
          }

          {/* sale banner */}
          {
            this.state.homeCenterBanner &&
            <div className={classes.saleBanner}>
              <Container maxWidth="lg">
                <div className="outer-wrap">
                  <div className="row-wrap">
                    <div className="left-block">
                      {
                        this.state.homeCenterBanner.attributes.banner_image ?
                          <img src={this.state.homeCenterBanner.attributes.banner_image} onClick={()=>{
                            window.open(`${window.location.protocol}//${window.location.host}/Itemlist?cta_id=${this.state.homeCenterBanner.attributes.cta}`, "_self");
                          }}/> :
                          <img src={noProductImage} />
                      }
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          }

          {/* recently viewed */}
          {
            (localStorage.getItem("IsLogin") && this.state.data2.length !== 0) && (
              <div className={classes.trendingNow}>
                <Container maxWidth="lg">
                  <div className="outer-wrap">
                    <h2 className="title-block">
                      Recently Viewed
                    </h2>
                    <div className="row-wrap">
                      <Carousel renderDotsOutside responsive={recentlyViewedResponsive} showDots={false} >
                        {this.state.data2.map((val: any) =>
                          <>
                            <div className="column" key={val.id}>
                              <Link className="inner-col" >
                                <div className="img-block">
                                  {
                                    (val.attributes?.galleries?.[0]?.url) ?
                                      <>
                                        <img
                                          src={val.attributes?.galleries?.[0].url}
                                          onClick={() => {
                                            window.open(`${window.location.protocol}//${window.location.host}/ProductDetail/${val.attributes.catalogue_id}`, "_self");
                                          }}
                                        />
                                        <button
                                          onClick={() => {
                                            this.StatusBlog(val.attributes.is_wishlist, val.attributes.catalogue_id);
                                          }}
                                          className={classes.heart}>
                                          <img style={{ width: "30px" }}
                                            src={val.attributes.is_wishlist ? wishlistTrue : wishlistHeart} />
                                        </button>
                                      </>
                                      : <img src={noProductImage} />
                                  }
                                </div>
                                <div className="col-name">
                                  <span>{val.attributes?.name}</span>
                                </div>
                              </Link>
                            </div>
                          </>
                        )}
                      </Carousel>
                    </div>
                  </div>
                </Container>
              </div>
            )
          }

          {/* Blogs */}
          {
            this.state.blogData.length !== 0 &&
            <div className={classes.blogs}>
              <Container maxWidth="lg">
                <h2 className="title-block">Blogs</h2>
                <Grid container spacing={2}>
                  {this.state.blogData.slice(0, 3).map((val: any) => (
                    <>
                      <Grid item xs={12} sm={4} key={val.id}>
                        <div className="img-block" onClick={() => {
                          window.open(
                            `${window.location.protocol}//${window.location.host
                            }/BlogPage/${val.id}`,
                            "_self"
                          );
                        }}>
                          {
                            val.attributes.image ?
                              <img src={val.attributes.image.url} /> :
                              <img src={noProductImage} />
                          }
                        </div>
                        <div className="col-name">
                          <span>{val.attributes.title}</span>
                        </div>
                      </Grid>
                    </>
                  ))
                  }
                </Grid>
                <div className="btn-wrapper">
                  <Button
                    variant="outlined"
                    onClick={() => { this.props.navigation.navigate("BlogsPage") }}
                    className="outline-btn">
                    View All
                  </Button>
                </div>
              </Container>
            </div>
          }

          {/* offer block */}
          {
            this.state.homeBottomBanner &&
            <div className={classes.offerBlock}>
              <Container maxWidth="lg">
                <div className="img-block">
                  {
                    this.state.homeBottomBanner.attributes.banner_image ?
                      <img src={this.state.homeBottomBanner.attributes.banner_image} onClick={()=>{
                        window.open(`${window.location.protocol}//${window.location.host}/Itemlist?cta_id=${ this.state.homeBottomBanner.attributes.cta}`, "_self");
                      }}/> :
                      <img src={noProductImage} />
                  }
                </div>
              </Container>
            </div>
          }
        </div>
      </>
    );
  }
}
export default withStyles(HomeStoreStyle)(HomeStore);
