import React, { useState } from "react";
import CarouselSlide from "react-multi-carousel";
import ImageGallery from "react-image-gallery";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { arrowLeft, arrowRight, blueSuit, champion, starImg, starImgWhite } from "./assets"
import "react-image-gallery/styles/css/image-gallery.css"

import ProductDetailController from "./ProductDetailController.web";
import { reviewOffset } from "../../../framework/src/Constants";
import moment from "moment";
import { roundUpRating } from "../../../framework/src/commonFunction";
import Loader from "../../../components/src/Loader.web";
import { ReviewModal } from "../../../framework/src/Interfaces/IProductDetail";
import PaginationComponent from "../../../components/src/shared/pagination";

const styles = {
  pageOuter: {
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },

    "& .thumb-slider .carousel .control-prev.control-arrow:before": {
      backgroundImage: `url(${arrowLeft})`,
    },
    "& .thumb-slider .carousel .control-next.control-arrow:before": {
      backgroundImage: `url(${arrowRight})`,
    },
    "& .rating-review-progress": {
      display: "flex",
    },
    "& .rating-review-progress .index-separator": {
      marginLeft: "50px",
      height: "96px",
      borderLeft: "1.2px solid #eaeaec",
    },
    "& .rating-review-progress .progress-section": {
      marginLeft: "50px",
    },
    "& .rating-review-progress .progress-section .progress": {
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      background: "white",
      margin: "2px 0",
    },
    "& .rating-review-progress .progress-section .progress img": {
      marginLeft: "5px",
      marginRight: "5px",
      width: "10px",
      height: "10px",
    },
    "& .rating-review-progress .progress-section .progress span:first-child": {
      fontSize: "14px",
      width: "8px",
    },
    "& .rating-review-progress .progress-section .progress progress": {
      marginRight: "5px",
      color: "black",
      border: "none",
      width: "120px",
      height: "4px",
      background: "#f5f5f6",
    },
  },
};

const productResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 959 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 959, min: 599 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 2,
  },
};

const galleries = [
  {
    original: blueSuit,
    thumbnail: blueSuit,
    thumbnailWidth: 64,
    thumbnailHeight: 78,
    originalWidth: 530,
    originalHeight: 590,
  },
];

export class ProductReview extends ProductDetailController {
  constructor(props: any) {
    super(props);
    this.elementRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.getProductDetailById(this.state.productId);
    this.getReviewListByCatalogueId();
  }

  render() {
    const { classes } = this.props;

    const { product, productId } = this.state;

    const category_name = product?.attributes?.product_type?.length && product?.attributes?.product_type?.[0]?.name || '';
    const sub_category_name = product?.attributes?.sub_category_id?.length && product?.attributes?.sub_category_id?.[0]?.name || '';
    const sub_sub_category_name = product?.attributes?.sub_sub_category_id?.length && product?.attributes?.sub_sub_category_id?.[0]?.name || '';

    const reviews: ReviewModal[] = this.state.reviews;

    let sortedReviewsByRating = [...reviews];
    sortedReviewsByRating = sortedReviewsByRating && sortedReviewsByRating.length ? sortedReviewsByRating.sort((a, b) => b.attributes.rating - a.attributes.rating) : [];

    // sortedReviewsByRating = sortedReviewsByRating.slice(0, this.state.displayReviewCount);

    let imageGalleryList: any[] = [];

    this.state.productGalleries &&
      this.state.productGalleries.length &&
      this.state.productGalleries.map((variant: any) => {
        if (variant.id && variant.url) {
          let imageObject = variant;
          let image = Object.assign({}, galleries[0]);

          image.original = imageObject.url;
          image.thumbnail = imageObject.url;
          imageGalleryList = imageGalleryList.concat(image);
        }
      });
    console.log("imageGalleryList:::", imageGalleryList);
    return (
      <>
        {/* product detail page */}
        {this.state.loading ? <Loader loading={this.state.loading} /> : null}
        <div className={classes.pageOuter}>
          <Container maxWidth="lg" className="product-container">
            <Breadcrumbs aria-label="breadcrumb">
              <Link onClick={(e: any) => {
                e.preventDefault();
                this.props.navigation.navigate('Home')
              }}>
                Home
              </Link>
              {category_name && <Link href="/Home"> {category_name} {/* Men */} </Link>}
              {sub_category_name && <Link href="/Home"> {sub_category_name} {/* T-shirts */} </Link>}
              {sub_sub_category_name && <Link href="/Home"> {sub_sub_category_name} {/* Topwear */} </Link>}
              <Typography>{product?.attributes?.name}</Typography>
            </Breadcrumbs >
            <div className="product-info-wraper">
              <div className="product-media">
                <ImageGallery
                  items={imageGalleryList}
                  thumbnailPosition="left"
                  showPlayButton={false}
                  showFullscreenButton={false}
                  disableSwipe={true}
                  disableThumbnailScroll={false}
                  slideOnThumbnailOver={true}
                />
              </div>
              <div className="product-info-main">
                <h2 className="product-title">{product?.attributes?.brand_name}</h2>
                <div className="product-name">{product?.attributes?.name}</div>
                <div className="rating">
                  <div className="rating-no">{roundUpRating(Number(product?.attributes?.average_rating) || 0)}</div>
                  <span className="rating-star">
                    <img src={starImg} />
                  </span>
                </div>

                {sortedReviewsByRating && sortedReviewsByRating.length &&
                  <>
                    <div className="ratings-wrapper">
                      <h4 className="desc-title">Rating &amp; Reviews</h4>
                      <div className="rating">
                        <div className="rating-no">{roundUpRating(Number(product?.attributes?.average_rating) || 0)}</div>
                        <span className="rating-star">
                          <img src={starImg} />
                        </span>
                      </div >
                      <div className="desc-count">{reviews.length} Verified Buyers</div>
                    </div >
                    <div className="product-reviews">
                      <h4 className="desc-title">Rating &amp; Reviews</h4>
                      <div className="reviews-detailed">
                        {sortedReviewsByRating && sortedReviewsByRating.map((review) => {
                          const reviewDate = moment(review?.attributes?.created_at).format('DD MMM YYYY');
                          return (
                            <div className="review-wrapper">
                              <div className="rating">
                                <div className="rating-no">{review?.attributes?.rating}</div>
                                <span className="rating-star">
                                  <img src={starImgWhite} />
                                </span>
                              </div>
                              <div className="review-content">
                                {review?.attributes?.comment &&
                                  <div className="product-desc">{review?.attributes?.comment}</div> || null
                                }
                                <div className="thumb-container">
                                  {review?.attributes?.images && review?.attributes?.images?.length &&
                                    review.attributes.images.map((image: any) => {
                                      return (
                                        <div className="thumb-image">
                                          <img src={image.url} alt="product-img" />
                                        </div>
                                      )
                                    })
                                    || null
                                  }
                                </div>
                                <div className="reviewer">
                                  <span>{review?.attributes?.username}</span>
                                  <span className="review-date">{reviewDate}</span>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    {/* pagination */}
                    {this.state.totalPage ?
                      <PaginationComponent
                        handlePageChange={this.handlePageChange}
                        totalPage={this.state.totalPage}
                      /> : null
                    }
                    {/* {
                      (reviews && reviews.length >= reviewOffset) && (reviews && reviews.length > this.state.displayReviewCount) ?
                        <button onClick={(e) => this.setState({ displayReviewCount: this.state.displayReviewCount + reviewOffset })} className="view-link">Load More</button> : null
                    } */}
                    {/* <Link onClick={() => this.props.navigation.navigate('ProductReview', { productId: this.state.productId })} href={`/ProductReview/${productId}`} className="view-link">View All {reviews.length} reviews</Link> */}
                  </>
                  || null
                }
              </div>
            </div >
          </Container >
        </div >
      </>
    );
  }
}

export default withStyles(styles)(ProductReview);
