import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const CouponsStyle = {
  pageOuter: {
    [theme.breakpoints.down("xs")]: {
      "& .coupons-list .coupon .coupon-detail": {
        flexDirection: "column !important",
        alignItems: "start !important",
      },
      "& .coupons-list .coupon .coupon-detail .right": {
        textAlign: "left !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .coupons-list": {
        margin: "30px 0px !important",
      },
      "& .btn-wrapper": {
        // margin: "30px 0px 0 0px !important",
      },
      "& .block-wrapper": {
        padding: "30px 0px !important",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .coupons-list": {
        margin: "30px 30px !important",
      },
      "& .btn-wrapper": {
        // margin: "30px 30px 0 30px !important",
      },
      "& .block-wrapper": {
        padding: "30px 30px !important",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.up("md")]: {
      "& .block-wrapper": {
        padding: "30px 30px !important",
      },
      "& .coupons-list": {
        margin: "30px 80px !important",
      },
      "& .btn-wrapper": {
        // margin: "30px 80px 0 80px !important",
      },
    },
    backgroundColor: "#F7F7F7",
    padding: "60px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      padding: "0",
      marginBottom: "0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      margin: "0 80px",
      padding: "30px 30px",
    },
    "& .block-wrapper-inner": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .search-coupons": {
      marginTop: "2px",
      backgroundColor: "#fff",
      padding: "10px",
      position: "relative",
      margin: "30px 80px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .search-coupons .form-group": {
      width: "80%",
      position: "relative",
      marginBottom: "0",
    },
    "& .search-coupons input": {
      fontSize: "14px",
      lineHeight: "18px",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "400",
      height: "40px",
      padding: "5px 5px 5px 35px",
      borderRadius: "0px",
      backgroundColor: "#f1f1f1",
      color: "#121212",
      border: "none",
      boxShadow: "none",
      width: "100%",
      maxWidth: "100%",
      outline: "0",
    },
    "& .search-coupons .MuiSvgIcon-root": {
      top: "10px",
      left: "7px",
      position: "absolute",
      color: "#838383",
    },
    "& .btn-wrapper button": {
      padding: "7px !important",
      float: "right",
    },
    "& .coupons-list": {
      margin: "30px 100px",
      width: "100%",
    },
    "& .coupons-list .coupon": {
      padding: "10px 20px",
      // backgroundColor: "#fff",
      // display: "flex",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "baseline",
      borderRadius: "5px",
      marginBottom: "10px",
    },
    "& .coupons-list .coupon input": {
      width: "15px",
      marginRight: "10px",
      transform: "scale(1.3)",
    },
    "& .coupons-list .coupon input[type=checkbox]:checked": {
      accentColor: "black",
    },
    "& .coupons-list .coupon .coupon-info": {
      width: "100%",
    },
    "& .coupons-list .coupon .coupon-title": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    "& .coupons-list .coupon .coupon-title p": {
      marginBottom: "0",
      fontSize: "20px",
      fontWeight: "600",
    },
    "& .coupons-list .coupon .coupon-desc": {
      color: "#8B8B8B",
    },
    "& .coupons-list .coupon .coupon-detail": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "15px",
    },
    "& .coupons-list .coupon .coupon-details .right": {
      textAlign: "right",
    },
    "& .coupons-list .coupon .coupon-details p": {
      marginBottom: "5px",
      fontSize: "16px",
    },
    "& .coupons-list .coupon .coupon-details p span": {
      fontWeight: "500",
    },
    "& nav": {
      width: "100%",
      margin: "30px 80px !important",
    },
    "& nav ul": {
      justifyContent: "center",
    },
  },
};
