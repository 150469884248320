import React from "react";
import AffiliateListController, {
  Props,
  configJSON,
} from "./AffiliateListController.web";
const cross = require("../assets/cross.png");
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import { AffiliateListStyleWeb } from "./AffiliateListStyle.web";

export class AffiliateList extends AffiliateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.pageOuter}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <div className="text-center">
                  <h3>TO AFFILIATE</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <Grid item xs={12} className="vendor-rightcol">
                      <Grid item xs={12} className="productlist-wrapper">
                        <div className="to-affiliate-top">
                          <p>6 Items</p>
                          <div className="buttons">
                            <Button
                              type="button"
                              variant="contained"
                              className="outline-btn"
                            >
                              Copy Link
                            </Button>{" "}
                            <Button
                              type="button"
                              variant="contained"
                              className="outline-btn"
                            >
                              Share
                            </Button>{" "}
                            <Button
                              type="button"
                              variant="contained"
                              className="black-btn"
                              onClick={() => this.toggleDialog()}
                            >
                              Shorten Link
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="vendor-rightcol">
                      <Grid item xs={12} className="productlist-wrapper">
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={false}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid className="product-list" key={1}>
                          <div className="product-list-inner">
                            <div className="product-select">
                              <input
                                type="checkbox"
                                name="coupon"
                                checked={true}
                                onChange={(e: any) => {}}
                              />
                              <div className="product-img">
                                <img
                                  className="img-block"
                                  src={`https://tinyurl.com/3wdum8u5`}
                                />
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-heading">
                                <h4 className="product-name">Roadster</h4>
                                <img src={cross} alt="" />
                              </div>
                              <div className="product-desc">
                                Black Solid Funky Jeans
                              </div>
                              <div className="date-time">
                                <span className="date">
                                  <b>&#8377;1212</b>
                                </span>
                                <span className="old-price">&#8377;1212</span>
                                <span className="error">(70% OFF)</span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>

          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={this.state.isDialogOpen}
            onClose={() => this.toggleDialog()}
          >
            <DialogTitle id="responsive-dialog-title">
              {"URL Shortener"}
            </DialogTitle>
            <DialogContent>
              <div>
                <label htmlFor="url">Paste your link</label>
                <TextField
                  fullWidth
                  id="url"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <Button
                type="button"
                variant="contained"
                className="black-btn"
                style={{ width: "100%", margin: "30px 0 20px 0" }}
              >
                Shorten Link
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withStyles(AffiliateListStyleWeb)(AffiliateList);
