import React from "react";
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import BlogController from "./BlogController.web";
import Loader from "../../../components/src/Loader.web";

const styles: any = {
  blogs: {
    padding: "20px 0",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      height: "100%",
      width: "100%",
      maxHeight: "267px",
      objectFit: "cover",
      "@media(max-width:599px)": {
        maxHeight: "250px",
      },
    },
    "& .col-name": {
      color: "#444",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "'Roboto',sans-serif",
      marginTop: "10px",
    },
    "& .btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
    },
    "& .outline-btn": {
      minWidth: "200px",
    },
    "& .blog-title h3": {
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "40px",
      letterSpacing: "0px",
      lineHeight: "1.5em",
      textDecoration: "none",
      textTransform: "none",
      margin: "0",
      fontFamily: "Montserrat, sans-serif",
    },
    "& .blog-title hr": {
      width: "200px",
      height: "1px",
      margin: "20px 0 0",
      transition: "height 100ms ease-in-out",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& .main-image img": {
      // width: "400px",
      height: "400px",
      objectFit: "cover",
    },
    "& .ql-align-center": {
      textAlign: "center",
    },
    "& ul": {
      listStyleType: "disc",
      paddingLeft: "2rem",
    },
  },
};

export class Blogs extends BlogController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <div className={classes.blogs}>
          <Container maxWidth="lg">
            <div className="text-center blog-title">
              <h3>{this.state.title}</h3>
              {/* <hr /> */}
            </div>
            <div className="main-image text-center mt-3">
              <img src={this.state.image} alt="blog main image" />
            </div>
            <div className="description mt-5">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}
              />
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(Blogs);
