import React from "react";
import {
  TextField,
  Container,
  Button,
  Grid,
  Select,
  Link,
} from "@material-ui/core";
import MenuItems from "@material-ui/core/MenuItem";
import AffiliateDashboardController, {
  Props,
  configJSON,
} from "./AffiliateDashboardController.web";
import { boxIcon, payoutIcon } from "./assets";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withStyles } from "@material-ui/core/styles";
import { AffiliateDashboardStyleWeb } from "./AffiliateDashboardStyle.web";

export class AffiliateDashboard extends AffiliateDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.pageOuter}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <div className="text-center">
                  <h3>Dashboard</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid className="top-heading" item xs={12}>
                  <div className="heading-left">
                    <h3 className="heading-title">Analytics</h3>
                  </div>
                  <div className="select-border">
                    <span className="select-label">Filter:</span>
                    <Select
                      labelId="product-category"
                      id="demo-simple-select"
                      onChange={(e: any) => {}}
                    >
                      <MenuItems value={"today"}>Today</MenuItems>
                      <MenuItems value={"last_week"}>Last Week</MenuItems>
                      <MenuItems value={"last_month"}>Last Month</MenuItems>
                      <MenuItems value={"last_year"}>Last Year</MenuItems>
                    </Select>
                  </div>
                </Grid>
                <Grid className="card-main" item xs={12} container>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">
                      <div className="card-heading">
                        <span className="card-icon">
                          <VisibilityIcon fontSize="large" />
                        </span>
                        <div className="card-title">View</div>
                      </div>
                      <div className="card-price">123</div>
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">
                      <div className="card-heading">
                        <span className="card-icon">
                          <img src={payoutIcon} />
                        </span>
                        <div className="card-title">Pending Payouts</div>
                      </div>
                      <div className="card-price orange-txt">&#8377;1212</div>
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">
                      <div className="card-heading">
                        <span className="card-icon">
                          <img src={payoutIcon} />
                        </span>
                        <div className="card-title">Total Earning</div>
                      </div>
                      <div className="card-price navyblue-txt">&#8377;1212</div>
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <Link href="/affiliate/sold-item">
                      <div className="card">
                        <div className="card-heading">
                          <span className="card-icon">
                            <img src={boxIcon} />
                          </span>
                          <div className="card-title">Sold Items</div>
                        </div>
                        <div className="card-price lightgreen-txt">123</div>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid className="top-heading" item xs={12}>
                  <div className="heading-left">
                    <h3 className="heading-title">URL Shortener</h3>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="url">Paste your link</label>
                  <div className="input-button">
                    <TextField
                      fullWidth
                      id="url"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <div />
                    <Button
                      type="button"
                      variant="contained"
                      className="black-btn"
                    >
                      Shorten
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

export default withStyles(AffiliateDashboardStyleWeb)(AffiliateDashboard);
