import React, { Component } from 'react'
import { Text, View, SafeAreaView, Image, TouchableOpacity } from 'react-native'
import scale, { verticalScale } from "./Scale";
import {StyleSheet} from 'react-native';
// import styles from './VendorHeaderStyle'

interface Props {
    isOpenFilterModal: any,
    isOpenMenuModal: any
    isMenuOpen: any
    // headerText: any
}

interface S {
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: '#fff'
    },
  
    orderHeaderMainView: {
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      marginTop: verticalScale(5)
    },
  
    invisibleView: {
      height: verticalScale(50), 
      width: scale(100)
    },
    
    orderHeaderView: {
      flexDirection: 'row', 
      justifyContent: 'space-evenly', 
      width: scale(100),
    },
  
    orderText: {
        fontSize: scale(25),
    },
  
    filterIcon: {
        height: scale(25),
        width: scale(25),
        marginRight: scale(10)
    },
  
    menuIcon: {
        height: scale(25),
        width: scale(25),
    },
  
    lineView: {
      backgroundColor: "#E5E5E5", 
      height: verticalScale(1), 
      width: '100%'
    }
  
  });
export default class VendorHeaderScreen extends Component<Props, S> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    onOpenFilterModal = async () => {
        this.props.isOpenFilterModal();
    }

    onOpenMenuModal = async () => {
        this.props.isOpenMenuModal();
    }

    // onHeaderText = async () => {
    //     this.props.headerText()
    // }

    renderOrderHeader = () => {
        console.log("this.props", this.props)
        return (
            <View>
                <View style={styles.orderHeaderMainView}>
                    <View style={styles.invisibleView} />
                    <View>
                        <Text style={styles.orderText}> Orders</Text>
                    </View>
                    <View style={styles.orderHeaderView}>
                        <TouchableOpacity onPress={() => this.onOpenFilterModal()} >
                            <Image style={styles.filterIcon} source={require('./filterIcon.png')} />
                        </TouchableOpacity>
                        {this.props.isMenuOpen &&
                            <TouchableOpacity onPress={() => this.onOpenMenuModal()}>
                                <Image style={styles.menuIcon} source={require('./menuIcon.png')} />
                            </TouchableOpacity>
                        }
                    </View>
                </View>
                <View style={styles.lineView} />
            </View>
        )
    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderOrderHeader()}
            </View>
        )
    }
}
