export const watchGlasses = require('./watchGlasses.png');
export const wearRingsBracelets = require('./wearRingsBracelets.png');
export const makeupKit = require('./makeupKit.png');
export const greenHands = require('./greenHands.png');
export const greenBoxArrow = require('./greenBoxArrow.png');
export const caelumWhite = require('./caelumWhite.png');
export const instagramIcon = require('./instagramIcon.png');
export const facebookIcon = require('./facebookIcon.png');
export const youtubeIcon = require('./youtubeIcon.png');

export const whiteBird = require('./whiteBird.png');

export const appStore = require('./appStore.png');

export const google = require('./googlePlay.png');

export const ManBlueShirt = require('./ManBlueShirt.png');

export const nike = require('./nike.png');

export const champion = require('./champion.png');

export const puma = require('./puma.png');

export const hnm = require('./hnm.png');
export const adidas = require('./adidas.png');

export const girlPic = require('./girlPic.png');

export const blackGlasses = require('./blackGlasses.png');
export const blueNikeShoes = require('./blueNikeShoes.png');

export const caelumBlack = require('./caelumBlack.png');

export const search = require('./searchIcon.png');

export const bagIcon = require('./bagIcon.png');

export const heartIcon = require('./heartIcon.png');

export const accountIcon = require('./accountIcon.png');

export const championItem = require('./champion.png');

export const balanceIcon = require('./balance-icon.png');

export const boxIcon = require('./box-icon.png');

export const payoutIcon = require('./payout-icon.png');

export const salesIcon = require('./sales-icon.png');
export const truckIcon = require('./truck-icon.png');

export const noProductImage = require('./product.png');
