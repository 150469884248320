export const HomeStoreStyle: any = {
  pageOuter: {
    fontFamily: "'Roboto',sans-serif",
    "& .title-block": {
      textAlign: "center",
      fontWeight: "500",
      color: "#444",
      fontSize: "32px",
      marginBottom: "20px",
    },
    "& .row-wrap": {
      margin: "0 -8px",
    },
    "& .row-wrap .column": {
      padding: "0 8px",
      height: "100%",
    },
    "& .column .inner-col": {
      color: "#444",
      display: "block",
      height: "100%",
      textDecoration: "none",
    },
    "& .column .img-block": {
      margin: "0 0 10px",
    },
    "& .column .img-block img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .column .col-name": {
      color: "#444",
      fontSize: "18px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
    },
  },
  trendingNow: {
    padding: "20px 0",
    "& .column .img-block": {
      height: "300px",
    },
  },
  collection: {
    padding: "20px 0",
    position: "relative",
    "& .row-wrap": {
      position: "relative",
      zIndex: "1",
    },
    "& .column .inner-col": {
      position: "relative",
    },
    "& .column .img-block": {
      height: "280px",
    },
    "& .column .col-name": {
      fontSize: "25px",
      position: "absolute",
      bottom: "10px",
      padding: "10px",
      margin: "0 25px",
      left: "0",
      right: "0",
      backgroundColor: "#fff",
      lineHeight: "30px",
      textTransform: "capitalize",
    },
    "& .black-bg": {
      position: "relative",
      height: "280px",
      backgroundColor: "#2d2d2d",
      marginTop: "-150px",
    },
  },
  TopBrands: {
    padding: "20px 0",
    "& .column .inner-col": {
      backgroundColor: "#f6f6f6",
      padding: "30px 25px",
    },
    "& .column .img-block": {
      height: "200px",
      marginBottom: "15px",
      border: "3px solid #a5a5a5",
      borderRadius: "50%",
    },
    "& .column .img-block img": {
      borderRadius: "50%",
    },
    "& .column .img-block .brand-img": {
      borderRadius: "50%",
    },
    "& .column .col-name": {
      fontSize: "20px",
      textTransform: "capitalize",
    },
  },
  shopByPrice: {
    padding: "20px 0",
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .row-wrap .column": {
      flex: "0 0 50%",
      maxWidth: "50%",
      padding: "10px",
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .column .inner-col": {
      display: "flex",
      border: "5px solid",
    },
    "& .column .inner-col .left-block": {
      maxWidth: "170px",
      flex: "0 0 170px",
      border: "5px solid",
      borderColor: "inherit",
      textAlign: "center",
      padding: "1px",
      "@media (max-width:480px)": {
        maxWidth: "130px",
        flex: "0 0 130px",
      },
    },
    "& .column .img-block": {
      height: "250px",
      marginBottom: "0",
      maxWidth: "calc(100% - 170px)",
      flex: "0 0 calc(100% - 170px)",
      "@media (max-width:480px)": {
        height: "198px",
        maxWidth: "calc(100% - 130px)",
        flex: "0 0 calc(100% - 130px)",
      },
    },
    "& .column .inner-col .left-block .inner-left-block": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      height: "100%",
      border: "5px solid",
      borderColor: "inherit",
    },
    "& .column .inner-col .left-block .discount": {
      display: "block",
      fontSize: "30px",
      lineHeight: "34px",
      textTransform: "uppercase",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "12px",
      "@media (max-width:480px)": {
        fontSize: "24px",
        lineHeight: "28px",
      },
    },
    "& .column .inner-col .left-block .shop-now": {
      textDecoration: "none",
      display: "block",
      fontSize: "16px",
      textTransform: "uppercase",
      color: "#444",
      backgroundColor: "#fff",
      padding: "8px",
      boxShadow: "0 0 1px 1px #eee",
      "@media (max-width:480px)": {
        fontSize: "14px",
        padding: "5px",
      },
    },
    "& .column.green-border": {
      cursor: "pointer",
    },
    "& .column.green-border .inner-col": {
      borderColor: "#bdcd8f",
    },
    "& .column.red-border .inner-col": {
      borderColor: "#fa7167",
    },
    "& .column.yellow-border .inner-col": {
      borderColor: "#feca39",
    },
    "& .column.orange-border .inner-col": {
      borderColor: "#f69847",
    },
    "& .column.green-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#fb7d8b",
    },
    "& .column.red-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#81d478",
    },
    "& .column.yellow-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#d53f41",
    },
    "& .column.orange-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#7891d4",
    },
  },
  heart: {
    position: "absolute",
    width: "30px",
    height: "30px",
    backgroundColor: "#f4f4f4",
    border: "1px solid #dddddd",
    borderRadius: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: " 5px",
    zIndex: "2",
    top: "6px",
    right: "14px",
  },
  saleBanner: {
    padding: "20px 0",
    cursor: "pointer",
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
      margin: "0",
    },
    "& .row-wrap .left-block img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    "& .row-wrap .right-block": {
      flex: "0 0 450px",
      maxWidth: "450px",
      height: "350px",
      backgroundColor: "#142551",
      padding: "20px 40px",
      "@media (max-width:1024px)": {
        flex: "0 0 300px",
        maxWidth: "300px",
        height: "300px",
        padding: "20px",
      },
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .row-wrap .right-block .right-inner": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      color: "#fff",
      height: "100%",
    },
    "& .row-wrap .right-block .right-inner .date": {
      textTransform: "uppercase",
    },
    "& .row-wrap .right-block .right-inner .product-name": {
      fontStyle: "italic",
      fontSize: "24px",
      marginBottom: "10px",
      "@media (max-width:1024px)": {
        fontSize: "20px",
      },
    },
    "& .row-wrap .right-block .right-inner .sale-discount": {
      fontSize: "30px",
      marginBottom: "10px",
      "@media (max-width:1024px)": {
        fontSize: "28px",
      },
    },
    "& .row-wrap .right-block .wishlist-now": {
      display: "block",
      color: "#fff",
      textTransform: "uppercase",
    },
  },
  bannerSlider: {
    "& .row-wrap": {
      position: "relative",
      paddingBottom: "40px",
      marginTop: "44px",
      "@media (max-width:767px)": {
        paddingBottom: "30px",
      },
    },
    "& .slides": {
      minHeight: "300px",
      "@media (max-width:1024px)": {
        minHeight: "300px",
      },
      "@media (max-width:767px)": {
        minHeight: "250px",
      },
    },
    "& .slides img": {
      width: "100%",
      height: "320px",
      objectFit: "fill",
      // width:'100%',
      // height:'100%',
      // objectFit:'contain',
      "@media (max-width:767px)": {
        minHeight: "250px",
        // objectFit: "cover",
      },
    },
    "& .custom-dot": {
      height: "5px",
      border: "none",
      borderRadius: "5px",
      margin: "5px",
      maxWidth: "50px",
      width: "100%",
      "@media (max-width:575px)": {
        maxWidth: "30px",
        height: "4px",
      },
    },
    "& .custom-dot--active": {
      transform: "scale(1)",
    },
  },
  blogs: {
    padding: "20px 0",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      height: "100%",
      width: "100%",
      minHeight: "267px",
      maxHeight: "267px",
      objectFit: "fill",
      cursor: "pointer",
      "@media(max-width:599px)": {
        maxHeight: "250px",
      },
    },
    "& .col-name": {
      color: "#444",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "'Roboto',sans-serif",
      marginTop: "10px",
    },
    "& .btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
    },
    "& .outline-btn": {
      minWidth: "200px",
    },
  },
  offerBlock: {
    padding: "20px 0",
    cursor: "pointer",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      width: "100%",
      height: "250px",
      // objectFit: "cover",
    },
    "& h2": {
      color: "#444",
      fontSize: "36px",
      linHeight: "42px",
      fontWeight: "400",
      fontFamily: "'Roboto',sans-serif",
      margin: "0 0 10px 0",
    },
    "& .more-info": {
      paddingBottom: "20px",
      marginBottom: "10px",
      display: "block",
      borderBottom: "1px solid #eee",
    },
    "& .explore-link": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      color: "#666",
    },
    "& .content-block": {
      padding: "50px 20px 20px",
      "@media (max-width:599px)": {
        padding: "0 20px 50px",
      },
    },
    "& .content-block br": {
      "@media (max-width:599px)": {
        display: "none",
      },
    },
  },
};
