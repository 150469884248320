import { IBlock } from '../../../framework/src/IBlock'
import { Message } from '../../../framework/src/Message'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { Alert } from 'react-native'
export const configJSON = require("./config");
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  received: boolean;
  unshipped: boolean;
  readyToShip: boolean;
  send: boolean;
  cancelled: boolean;
  showCancelOrderModal: boolean;
  orderList: any;
  showFilterModal: boolean;
  filterData: any;
  unshippedFlatList: any;
  showMenuModal: boolean;
  cancelOrderID: any;
  dropDownModal: boolean;
  isSelectedItem: any;
  otherCancelreason: any;
  isLoading: boolean;
  selectedId: any;
  readyToShipSelectedId: any;
  readyToShipFlatList: any,
  sentFlatList: any;
  cancelledFlatList: any
  dayWiseOrder: any
  cancelCategoryList: any,
  recevedOrderSelectedItem: any,
  recevedUnShippedOrderSelectedItem: any,
  selectedReasonID: any,
  searchText: any,
  startDate: any,
  endDate: any,
  openDateCalendarModal: any,
  confirmOrder: any,
  noDataFound: boolean,
  printInvoice: string,
  printShipping: string,
  downloadMenifest: string
  token: string | null;
  unshippedDetailData: any;
  unshippedAlert: boolean;
  cancelPropsData: any;
  cancelUnShippedPropsData: any;
  isMenuShow: boolean;
  isDateActice: boolean;
  showConfirmOrderModal: boolean;
  confirmOrderID: any;
  confirmOrderNumber: any;
  showCancelUnshippedOrder: boolean;
  unshippedItem: any;
  shippmentId: any

  // custom state variable for web
  tab: number;
  selectedFilter: number;
  selectedOrders: number;
  openCancelledOrderModal: boolean;
  orderToOpen: any;
  // Customizable Area End
}

interface SS {
  id: any
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };
  // Customizable Area End
  getRecievedAPICallId: any
  putReasonForCancelApiCallId: any
  getUnshippedAPICallId: any
  getReadyToShipAPICallId: any
  getSendAPICallId: any
  getCancelledAPICallId: any
  getSearchAPICallId: any
  getConfirmOrderAPICallId: any
  reasonCancelApiCallID: any
  getprintInvoiceAPICallId: any
  getprintManifiestAPICallId: any
  getprintLabelAPICallId: any
  onConfirmShipmentAPICallId: any
  putReasonForCancelUnShippedApiCallId: any
  reasonCancelOrderApiCallId: any
  onCancelOrderShipmentAPICallId: any
  onCancelOrderIdAPICallId: any

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      received: true,
      unshipped: false,
      readyToShip: false,
      send: false,
      cancelled: false,
      showCancelOrderModal: false,
      showFilterModal: false,
      searchText: '',
      startDate: '',
      endDate: '',
      confirmOrder: '',
      openDateCalendarModal: false,
      noDataFound: false,
      token: '',
      filterData: [
        {
          id: 1,
          title: 'Today',
          isSelected: false,
          value: 'today'
        },
        {
          id: 2,
          title: 'Last Week',
          isSelected: false,
          value: 'last_week'
        },
        {
          id: 3,
          title: 'Last Month',
          isSelected: false,
          value: 'last_month'
        },
        {
          id: 4,
          title: 'Last Year',
          isSelected: false,
          value: 'last_year'
        },
        {
          id: 5,
          title: 'Custom',
          isSelected: false,
        }
      ],
      unshippedFlatList: [],
      showMenuModal: false,
      orderList: [],
      cancelOrderID: '',
      dropDownModal: false,
      isSelectedItem: '',
      otherCancelreason: '',
      isLoading: false,
      selectedId: [],
      readyToShipSelectedId: [],
      readyToShipFlatList: [],
      sentFlatList: [],
      cancelledFlatList: [],
      dayWiseOrder: "",
      cancelCategoryList: [],
      recevedOrderSelectedItem: '',
      recevedUnShippedOrderSelectedItem: '',
      selectedReasonID: '',
      printInvoice: '',
      downloadMenifest: '',
      printShipping: '',
      unshippedDetailData: '',
      cancelPropsData: '',
      cancelUnShippedPropsData: '',
      isMenuShow: false,
      isDateActice: false,
      unshippedAlert: false,
      showConfirmOrderModal: false,
      confirmOrderID: '',
      confirmOrderNumber: '',
      showCancelUnshippedOrder: false,
      unshippedItem: '',
      shippmentId: [],
      tab: 0,
      selectedFilter: 1,
      selectedOrders: 0,
      openCancelledOrderModal: false,
      orderToOpen: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    try {
      let token = await AsyncStorage.getItem('authToken');
      this.setState({ token: token })
      let data = await AsyncStorage.getItem('data');
    } catch (e) {
      // error reading value
    }
    this.getOnRecievedList();
    this.getreasonForCancel();
  }

  async receive(from: string, message: Message) {
    console.log('@@@ API MESSAGE SIGNUP =================', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      console.log('responseJson', responseJson)
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getRecievedAPICallId) {
          this.receivedOrderSuccessCallBack(responseJson)
        } else if (apiRequestCallId === this.getConfirmOrderAPICallId) {
          this.confirmOrderSuccessCallBack(responseJson)
        } else if (apiRequestCallId === this.putReasonForCancelApiCallId) {
          this.reasonForCancelDropDownDataSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getUnshippedAPICallId) {
          this.unshippedOrderSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getReadyToShipAPICallId) {
          this.readyToShipOrderSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getSendAPICallId) {
          this.sendOrderSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getCancelledAPICallId) {
          this.cancelledOrderSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getSearchAPICallId) {
          this.searchOrderSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.reasonCancelApiCallID) {
          this.reasonForCancelSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintInvoiceAPICallId) {
          this.printInvoiceSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintManifiestAPICallId) {
          this.printManifiestSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintLabelAPICallId) {
          this.printLabelSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onConfirmShipmentAPICallId) {
          this.confirmShipmentSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.putReasonForCancelUnShippedApiCallId) {
          this.reasonForCancelUnShippedDropDownDataSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.reasonCancelOrderApiCallId) {
          this.reasonUnShippedSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onCancelOrderShipmentAPICallId) {
          this.cancalOrderUnShippedSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onCancelOrderIdAPICallId) {
          this.cancalOrderIdSuccessCallBack(responseJson)
        }


      } else if (responseJson && responseJson.errors) {
        console.log('responseIfcondition', responseJson)
        if (apiRequestCallId === this.getRecievedAPICallId) {
          this.receivedOrderFailureCallBack(responseJson)
        } else if (apiRequestCallId === this.getConfirmOrderAPICallId) {
          this.confirmOrderFailureCallBack(responseJson)
        } else if (apiRequestCallId === this.putReasonForCancelApiCallId) {
          this.reasonForCancelDropDownDataFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getUnshippedAPICallId) {
          this.unshippedOrderFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getReadyToShipAPICallId) {
          this.readyToShipOrderFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getSendAPICallId) {
          this.sendOrderFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getCancelledAPICallId) {
          this.cancelledOrderFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getSearchAPICallId) {
          this.searchOrderFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.reasonCancelApiCallID) {
          this.reasonForCancelFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintInvoiceAPICallId) {
          this.printInvoiceFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintManifiestAPICallId) {
          this.printManifiestFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.getprintLabelAPICallId) {
          this.printLabelFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onConfirmShipmentAPICallId) {
          this.confirmShipmentFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.putReasonForCancelUnShippedApiCallId) {
          this.reasonForCancelUnShippedDropDownDataFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.reasonCancelOrderApiCallId) {
          this.reasonUnShippedFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onCancelOrderShipmentAPICallId) {
          this.cancelOrderUnShippedFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.onCancelOrderIdAPICallId) {
          this.cancelOrderIdFailureCallBack(responseJson)
        }
      } else if (errorReponse) {
        this.setState({ isLoading: false })
        console.log('errorReponse', errorReponse)
      }
    }
  }

  receivedOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ received Order success callBack =================', responseJson)
    if (responseJson.data) {
      let orderreciveList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
      this.setState({ orderList: orderreciveList, unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
    }
    else {
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: true, isLoading: false })
    }
  };

  receivedOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ received Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })
  };

  // Customizable Area Start

  cancelOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@cancel Order success callBack =================', responseJson)
    this.setState({ showCancelOrderModal: false }, () => {
      this.getOnRecievedList();
      this.onConfirmOrderButton(this.state.cancelOrderID, 'Cancel')

      console.log("@@@@ this.state.recevedOrderSelectedItem =======", this.state.recevedOrderSelectedItem)
      this.props.navigation.replace('CancelOrder', { RECEVIEDITEM: this.state.recevedOrderSelectedItem })

    })
  }

  cancelOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancel Order failure callBack =================', responseJson)
  };

  confirmOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ confirm Order success callBack =================', responseJson)
    this.setState({ showConfirmOrderModal: false }, () => this.props.navigation.navigate('OrderSummary'))
    this.getOnRecievedList();
  }

  confirmOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ confirm Order failure callBack =================', responseJson)
  };

  reasonForCancelDropDownDataSuccessCallBack = (responseJson: any) => {
    console.log('@@@reason for cancel  drop down data success callBack =================', responseJson)
    this.setState({ cancelCategoryList: responseJson && responseJson.data ? responseJson.data : [] })
  };

  reasonForCancelDropDownDataFailureCallBack = (responseJson: any) => {
    console.log('@@@reason for cancel  drop down data failure callBack =================', responseJson)
  };

  reasonForCancelSuccessCallBack = async (responseJson: any) => {
    console.log('@@@reason for cancel  success callBack =================', responseJson)
    this.onCancelOrder()
  }

  reasonForCancelFailureCallBack = async (error: any) => {
    console.log('@@@reason for cancel failure callBack =================', error)
  }

  unshippedOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ unshipped Order success callBack =================', responseJson)
    if (!responseJson.message) {
      let unshippedOrderList = responseJson && responseJson.data.length > 0 ? responseJson.data : []
      this.setState({ orderList: [], unshippedFlatList: unshippedOrderList, cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
    } else {
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: true, isLoading: false })
    }
  };

  unshippedOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ unshipped Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })
  };

  readyToShipOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ readyToShip Order success callBack =================', responseJson)
    if (!responseJson.message) {
      let readyToShipOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: readyToShipOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
    } else {
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: true, isLoading: false })
    }
  };

  readyToShipOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ readyToShip Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })
  };
  sendOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ send Order success callBack =================', responseJson)
    if (!responseJson.message) {
      let sendOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
      this.setState({ orderList: [], unshippedFlatList: [], readyToShipFlatList: [], cancelledFlatList: [], sentFlatList: sendOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
    } else {
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: true, isLoading: false })

    }
  };
  sendOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ send Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })
  };
  cancelledOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ cancelled Order success callBack =================', responseJson)
    if (!responseJson.message) {
      let cancelOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
      this.setState({ orderList: [], unshippedFlatList: [], readyToShipFlatList: [], sentFlatList: [], cancelledFlatList: cancelOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
    } else {
      this.setState({ orderList: [], unshippedFlatList: [], cancelledFlatList: [], sentFlatList: [], readyToShipFlatList: [], noDataFound: true, isLoading: false })
    }
  };
  cancelledOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancelled Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };
  searchOrderSuccessCallBack = (responseJson: any) => {
    console.log('@@@ search Order success callBack =================', responseJson.data)
    if (responseJson.data) {

      let recived = this.state.received && responseJson.data
      console.log("recived 233", recived)
      let unshipped = this.state.unshipped && responseJson.data
      let readyToShip = this.state.readyToShip && responseJson.data
      let send = this.state.send && responseJson.data
      let cancelled = this.state.cancelled && responseJson.data
      this.setState({ orderList: recived, unshippedFlatList: unshipped, readyToShipFlatList: readyToShip, sentFlatList: send, cancelledFlatList: cancelled, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })

    }

  };
  searchOrderFailureCallBack = (responseJson: any) => {
    console.log('@@@ search Order failure callBack =================', responseJson)
    this.setState({ isLoading: false })
  };

  printInvoiceSuccessCallBack = (responseJson: any) => {
    console.log('@@@ print Invoice success callBack =================', responseJson.url)
    this.setState({ printInvoice: responseJson.url })

    console.log('@@@@ printInvoice =======', this.state.printInvoice)
  }
  printInvoiceFailureCallBack = (responseJson: any) => {
    console.log('@@@ print Invoice failure callBack =================', responseJson)
  };

  printLabelSuccessCallBack = (responseJson: any) => {
    console.log('@@@ print Label success callBack =================', responseJson)
    this.setState({ printShipping: responseJson.label_url })
    console.log("this.state.printShipping==========", this.state.printShipping)
  }
  printLabelFailureCallBack = (responseJson: any) => {
    console.log('@@@ print Label failure callBack =================', responseJson)
  };

  printManifiestSuccessCallBack = (responseJson: any) => {
    console.log('@@@ print Manifiest success callBack =================', responseJson)
    this.setState({ downloadMenifest: responseJson.url })
  }
  printManifiestFailureCallBack = (responseJson: any) => {
    console.log('@@@ print Manifiest failure callBack =================', responseJson)
  };

  confirmShipmentSuccessCallBack = (responseJson: any) => {
    console.log('@@@ confirm Shipment success callBack =================', responseJson)
    this.setState({ isLoading: false })
    Alert.alert(
      "Order shipment successfully",
      "",
      [
        { text: "OK", onPress: () => this.props.navigation.navigate('OrderSummary') }
      ]
    );
    this.getOnReadyToShipList()
  }
  confirmShipmentFailureCallBack = (responseJson: any) => {
    console.log('@@@ confirm Shipment failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };

  reasonForCancelUnShippedDropDownDataSuccessCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped success callBack =================', responseJson)
    this.setState({ isLoading: false })
    this.setState({ cancelCategoryList: responseJson && responseJson.data ? responseJson.data : [] })
  }
  reasonForCancelUnShippedDropDownDataFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped  failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };

  reasonUnShippedSuccessCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped success callBack =================', responseJson)
    this.onCancelOrderShipment()
  }
  reasonUnShippedFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped  failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };

  cancalOrderUnShippedSuccessCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped success callBack =================', responseJson)
    this.setState({ isLoading: false })
    Alert.alert(
      "Order cancel successfully",
      "",
      [
        { text: "OK", onPress: () => this.props.navigation.navigate('OrderSummary') }
      ]
    );
    this.getOnUnshippedList()
  }
  cancelOrderUnShippedFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped  failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };

  cancalOrderIdSuccessCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped success callBack =================', responseJson)
    this.setState({ isLoading: false })
    Alert.alert(
      "Order cancel successfully",
      "",
      [
        { text: "OK", onPress: () => this.props.navigation.navigate('OrderSummary') }
      ]
    );
    this.getOnUnshippedList()
  }
  cancelOrderIdFailureCallBack = (responseJson: any) => {
    console.log('@@@ cancel order unshipped  failure callBack =================', responseJson)
    this.setState({ isLoading: false })

  };



  apiCall = async (data: any) => {
    let token = await AsyncStorage.getItem('authToken');
    console.log('@@@@token', token)
    console.log('@@@@ api data ======', data)
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log('@@@ requestMessage ========', requestMessage)
    return requestMessage.messageId;
  }

  isOpenModal = () => {
    this.setState({ showFilterModal: true })
  }

  isOpenMenuModal = async () => {
    this.setState({ showMenuModal: true })
  }

  searchSubOdersFunction = (val: any) => {
    console.log('val@@@@@====================================', val);
    this.setState({ searchText: val }, () => {

      console.log("this.state.searchText", this.state.searchText)
      setTimeout(async () => {
        if (this.state.searchText.length == 0) {
          console.log('@@@ this.state.searchText.length ====', this.state.searchText.length)
          this.state.received ? this.getOnRecievedList() : this.state.unshipped ? this.getOnUnshippedList() : this.state.readyToShip ? this.getOnReadyToShipList() : this.state.send ? this.getOnSendList() : this.state.cancelled ? this.getOnCancelledList() : ''
        } else {
          this.getOnSearchList()
        }
      }, 1000)
    })

  }
  getOnRecievedList = async () => {
    this.setState({ isLoading: true })
    this.getRecievedAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.recievedApiEndPoint}?status=created&requested_on=${this.state.dayWiseOrder}`,
    });
  };

  confirmCancelation = async () => {
    console.log("@@@cancelOrderID==", this.state.cancelOrderID)
    this.props.navigation.replace('CancelOrder', { RECEVIEDITEM: this.state.recevedOrderSelectedItem })
  };

  getreasonForCancel = async () => {
    this.putReasonForCancelApiCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.dropDownApiMethodType,
      endPoint: configJSON.reasonCancelApiEndPoint,
    });
  };

  reasonCancelation = async () => {
    console.log("@@@reason for cancel", this.state.isSelectedItem)
    console.log("@@@other reason for  cancel", this.state.otherCancelreason)
    this.reasonCancelApiCallID = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.dropDownApiMethodType,
      endPoint: `${configJSON.reasonToCancelApiEndPoint}?other=${this.state.otherCancelreason}&table_reason_to_cancles_id=${this.state.selectedReasonID}&order_id=${this.state.cancelPropsData}`,
    });
  }

  onConfirmOrderButton = async (item: any, type: any) => {
    this.setState({ isLoading: true })
    this.getConfirmOrderAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.cancelOrderApiMethod,
      endPoint: `${configJSON.confirmApiEndPoint}?order[order_id]=${type == 'Cancel' ? item : item}&order[status]=${type == 'Cancel' ? 'cancelled' : 'confirmed'}`,
    });
  }

  onSelectCancelReason = async (item: any) => {
    console.log("selected item", item)
    this.setState({ isSelectedItem: item.attributes && item.attributes && item.attributes.reason, dropDownModal: false, selectedReasonID: item.id })
  }

  getOnUnshippedList = async () => {
    this.setState({ isLoading: true })
    this.getUnshippedAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.recievedApiEndPoint}?status=confirmed&requested_on=${this.state.dayWiseOrder}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`,
    });
  }

  getOnReadyToShipList = async () => {
    this.setState({ isLoading: true })
    this.getReadyToShipAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.recievedApiEndPoint}?status=ready_to_ship&requested_on=${this.state.dayWiseOrder}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`,
    });


  }

  getOnSendList = async () => {
    this.setState({ isLoading: true })
    this.getSendAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.recievedApiEndPoint}?status=shipped&requested_on=${this.state.dayWiseOrder}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`,
    });
  }

  getOnCancelledList = async () => {
    this.setState({ isLoading: true })
    this.getCancelledAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.recievedApiEndPoint}?status=cancelled&requested_on=${this.state.dayWiseOrder}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`,
    });
  }

  getOnSearchList = async () => {
    console.log("this.state.searchText@@@@@@@@@@", this.state.searchText)
    this.setState({ isLoading: true })
    let orderStatus = this.state.received ? 'created' : this.state.unshipped ? 'confirmed' : this.state.readyToShip ? 'ready_to_ship' : this.state.send ? 'shipped' : this.state.cancelled ? 'cancelled' : ''
    console.log('@@@ order status ======', orderStatus)
    this.getSearchAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.searchApiEndPoint}?search=${this.state.searchText}&status=${orderStatus}`,
    });
  }

  handleSelection = (items: any) => {
    console.log("@@@selected unshipped flatList items", items, this.state.selectedId);
    let unshippedStream = this.state.unshippedFlatList;
    let compId: any = []
    let newIndex = this.state.unshippedFlatList.findIndex(
      (item: any) => item.id === items.id
    );
    console.log("@@@@findNewArray", newIndex);
    unshippedStream[newIndex].isSelected = !unshippedStream[newIndex].isSelected;
    console.log("@@@unshippedStream", unshippedStream);
    unshippedStream.map((item: any, index: any) => {
      console.log("@@@selected unshipped Item", item);
      this.setState({ unshippedItem: item })
      console.log("@@@unshippedItem unshipped =====", this.state.unshippedItem);
      if (item.isSelected) {
        console.log("@@@selected Choose unshipped Item", item);
        compId.push(item.id)
      }
    });
    console.log("@choose to select unshipped Stream@", unshippedStream, "ppppppp", compId, 'ttthhhhh', this.state.selectedId);
    this.setState({ unshippedFlatList: unshippedStream, selectedId: compId, unshippedDetailData: items, isMenuShow: compId.length > 0 ? true : false }, () => {
      console.log("@@@ data in selectedId state ========== ", this.state.selectedId);
      this.setState({ unshippedAlert: this.state.selectedId.length > 1 ? true : false })

      this.getOnPrintInvoice()
      compId = []
    });
  };

  handleReadyToShipSelection = (items: any) => {
    console.log("@@@selected ready to ship flatList items", items, this.state.readyToShipSelectedId);
    let readyToShipStream = this.state.readyToShipFlatList;
    let readyToShipCompId: any = []
    let shippmentId: any = []
    let readyToShipNewIndex = this.state.readyToShipFlatList.findIndex(
      (item: any) => item.id === items.id

    );
    console.log("@@@@findReadyToShipNewArray", readyToShipNewIndex);
    readyToShipStream[readyToShipNewIndex].isSelected = !readyToShipStream[readyToShipNewIndex].isSelected;
    console.log("@@@readyToShipStream", readyToShipStream);
    readyToShipStream.map((item: any, index: any) => {
      console.log("@@@selected ready to ship Item", item);
      if (item.isSelected) {
        console.log("@@@selected Choose ready to ship Item", item);
        readyToShipCompId.push(item.id)
        shippmentId.push(item.attributes.shipment_id)
      }
    });
    console.log("@choose to select unshipped Stream@", readyToShipStream, "1234567890", readyToShipCompId, 'ttthhhhh1234567890', this.state.readyToShipSelectedId, 'shipmentiddddd', shippmentId);
    this.setState({ readyToShipFlatList: readyToShipStream, readyToShipSelectedId: readyToShipCompId, shippmentId: shippmentId, isMenuShow: readyToShipCompId.length > 0 ? true : false }, () => {
      console.log("@@@selected ready to ship", this.state.readyToShipSelectedId);
      console.log("@@@selected shippmentId", this.state.shippmentId);
      readyToShipCompId = []
      shippmentId = []
      this.getOnPrintInvoice()
      this.getOnPrintLabel()
      this.getOnPrintManifiest()
    });
  };

  checkDate = (date: any) => {
    console.log("@@@@Date", date, this.state.startDate)

    var to = moment(this.state.startDate).format('DD-MM-YYYY');
    let from = moment(date).format('DD-MM-YYYY');

    console.log("convert into number", to, from)


    let d1 = Date.parse(date);
    let d2 = Date.parse(this.state.startDate);
    let isActive
    console.log("d1", d1, 'd2', d2)
    if (d1 < d2) {

      console.log("exist if condition")
      isActive = false
      alert("To date should be greater than Start date")
        ;

    } else {

      console.log("exist else condition")

      isActive = true
    }

    this.setState({ endDate: date, isDateActice: isActive })
  }

  getOnPrintInvoice = async () => {
    console.log('@@@@ this.state.selectedIdddddddsssss invoice', this.state.selectedId, this.state.readyToShipSelectedId)
    const httpBody = {
      id: `${this.state.selectedId}${this.state.readyToShipSelectedId}`
    };
    this.getprintInvoiceAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.printInvoiceApiEndPoint,
      body: httpBody
    });
  }

  getOnPrintLabel = async () => {
    console.log('@@@@ this.state.selectedIdddddddsssss label', this.state.shippmentId)
    const httpBody = {
      shipment_id: this.state.shippmentId
      // 167399108
      // shipment_id: [167394601]
    };
    this.getprintLabelAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.printShippingLableApiEndPoint,
      body: httpBody
    });
  }

  getOnPrintManifiest = async () => {
    console.log('@@@@ this.state.selectedIdddddddsssss manifist', this.state.readyToShipSelectedId)
    const httpBody = {
      id: this.state.readyToShipSelectedId
    };
    this.getprintManifiestAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.printmanifiestApiEndPoint,
      body: httpBody
    });
  }

  onConfirmShipment = async () => {
    this.setState({ isLoading: true, showMenuModal: false })
    this.onConfirmShipmentAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.cancelOrderApiMethod,
      endPoint: `${configJSON.confirmApiEndPoint}?order[status]=shipped&order[order_id]=${this.state.readyToShipSelectedId}`,
    });
  }

  selectreasonCancelation = () => {
    this.setState({ showCancelUnshippedOrder: false }, () => {
      console.log("@@@@@@@@@@ this.state.selectedId =======", this.state.selectedId, this.state.unshippedItem)
      this.props.navigation.navigate('CancelUnshippedOrder', { RECEVIEDITEMID: this.state.selectedId, RECEVIEDITEMDETAIL: this.state.unshippedFlatList })
    })
  }

  getreasonForCancelUnShipped = async () => {
    this.putReasonForCancelUnShippedApiCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.dropDownApiMethodType,
      endPoint: configJSON.reasonCancelApiEndPoint,
    });
  };

  confirmUnshipedCancelation = async () => {
    this.reasonCancelOrderApiCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.dropDownApiMethodType,
      endPoint: `${configJSON.reasonCancelApiEndPoint}?other=${this.state.otherCancelreason}&table_reason_to_cancles_id=${this.state.selectedReasonID}&order_id=${this.state.cancelUnShippedPropsData}`,
    });
  };

  onCancelOrderShipment = async () => {
    this.setState({ isLoading: true })
    this.onCancelOrderShipmentAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.cancelOrderApiMethod,
      endPoint: `${configJSON.confirmApiEndPoint}?order[status]=cancelled&order[order_id]=${this.state.cancelUnShippedPropsData}`,
    });
  }

  onCancelOrder = async () => {
    this.setState({ isLoading: true })
    this.onCancelOrderIdAPICallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.cancelOrderApiMethod,
      endPoint: `${configJSON.confirmApiEndPoint}?order[status]=cancelled&order[order_id]=${this.state.cancelPropsData}`,
    });
  }

}

