import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
// import SizeChartController, { Props } from "./SizeChartController.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { configJSON } from "./SizeChartController.web";
import Loader from "../../../components/src/Loader.web";
import NewSizeChartController, { Props } from "./NewSizeChartController.web";

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    fontFamily: "'Montserrat', sans-serif",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
    },
    "& p": {
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .title-btn-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "25px 0",
    },
    "& .title-btn-wrapper h4": {
      margin: "0 0 0",
    },
    "& .title-btn-wrapper .black-btn": {
      minWidth: "100px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .guide-block": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      padding: "25px 15px",
      textAlign: "center",
      borderRadius: "6px",
    },
    "& .guide-block p": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .add-row": {
      margin: "30px 0 0",
      borderTop: "3px solid #e3e3e3",
    },
    "& .add-inner-row": {
      margin: "0 0 30px",
      borderBottom: "3px solid #e3e3e3",
    },
    "& .add-inner-row .row-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 25px",
    },
    "& .add-inner-row .row-title-wrapper p": {
      margin: "0 0 0",
    },
    "& .add-inner-row .row-title-wrapper img": {
      height: "15px",
      width: "15px",
      cursor: "pointer",
    },
  },
};
interface MyFormValues {
  product_name: string;
  product_cat: string;
  product_sub_cat?: string;
  measurement_unit: string;
  size_chart_column_name: any[];
  size_chart_column_value: any[];
}

// Validations
const FormSchema = Yup.object().shape({
  product_name: Yup.string().required('Required'),
  product_cat: Yup.string().required('Category is required!'),
  product_sub_cat: Yup.string().required('Sub-Category is required!'),
  measurement_unit: Yup.string().required('Measurement unit is required!'),
});
export class NewEditSizeCharts extends NewSizeChartController {

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    // this.setColRowData(''); // for local devlopement test
    if (this.state.editingSizeChartId) {
      this.getSizeChartById();
    }
  }

  /**
   * 
   * @param event data related to onChange event for the category dropdown
   */
  handleCategoryChange(event: any) {
    let val = event.target.value;
    this.setState({
      selectedCat: val,
      selectedSubCatId: '',
      loading: false
    }, () => {
      this.getProductSubCategoies(val);
      this.rendersubcategories();
    });
  }

  handleSubCategoryChange(event: any) {
    let val = event.target.value;
    this.setState({
      selectedSubCatId: val
    });
  }

  /**
   * Function that returns categories
   * @returns React JSX component for categories
   */
  rendercategories() {
    let categories: any = [];
    this.state.categories.forEach((categ: any) => {
      const cate = (
        <MenuItem value={categ?.attributes?.id}>
          <Typography className="size-name">{_.startCase(_.toLower(categ?.attributes?.name))}</Typography>
        </MenuItem>
      );
      categories.push(cate);
    })
    return categories;
  }

  rendersubcategories() {
    let categories: any = [];
    if (this.state.subCategories != null) {
      this.state.subCategories.forEach((scateg: any) => {
        const cate = (
          <MenuItem value={scateg?.id}>
            <Typography className="size-name">{scateg?.attributes?.name}</Typography>
          </MenuItem>
        );
        categories.push(cate);
      })
    }
    return categories;
  }

  render() {
    const initialValues: MyFormValues = {
      product_name: this.state.editingSizeChart?.attributes?.name,
      product_cat: this.state.selectedCat,
      product_sub_cat: this.state.selectedSubCatId,
      measurement_unit: this.state.editingSizeChart?.attributes?.measurement_unit,
      size_chart_column_name: this.state.cols,
      size_chart_column_value: this.state.chartRows,
    };
    const { classes } = this.props;
    const rows: any[] = (this.state.chartRows as any[]).flat();
    const cols: any[] = this.state.cols;
    return (
      <>
        {this.state.loading ? <Loader loading={this.state.loading} /> : null}
        <Box className={classes.pageOuter}>
          <Box>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Edit Size Chart</h2>
                </Grid>
                <Grid item xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // validationSchema={FormSchema}
                    onSubmit={(values, actions) => {
                      // restructure chartRows
                      this.editSizeChart(values, actions);
                    }}
                  >
                    {({ values, touched, errors, handleChange, handleBlur }) => {
                      return (
                        <Form translate="true">
                          <Box className="block-wrapper">
                            <Box className="block-wrapper-inner">
                              <Typography variant="h4">Edit Product Detail</Typography>
                              <Box className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Chart Title / Product Name
                                </InputLabel>
                                <Field name="product_name" />
                                {errors.product_name && touched.product_name ? (
                                  <div style={{ "color": "red" }}>{errors.product_name}</div>
                                ) : null}
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="product-category">
                                  Product Category
                                </InputLabel>
                                <Select
                                  labelId="product-category"
                                  value={String(values.product_cat)}
                                  name="product_cat"
                                  onChange={(e: any) => { handleChange(e); this.handleCategoryChange(e) }}
                                  onBlur={handleBlur}
                                >
                                  {
                                    this.state.categories.map((category: any) => (
                                      <MenuItem value={Number(category?.attributes?.id)}>
                                        {_.startCase(_.toLower(category?.attributes?.name))}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                                {errors.product_cat && touched.product_cat ? (
                                  <div style={{ "color": "red" }}>{errors.product_cat}</div>
                                ) : null}
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="product-subcategory">
                                  Product Subcategory
                                </InputLabel>
                                <Select
                                  labelId="product-subcategory"
                                  name="product_sub_cat"
                                  value={Number(values.product_sub_cat)}
                                  onChange={(e: any) => { handleChange(e); this.handleSubCategoryChange(e) }}
                                  onBlur={handleBlur}
                                >
                                  {
                                    this.rendersubcategories()
                                  }
                                </Select>
                                {(errors.product_sub_cat && touched.product_sub_cat) || this.state.subCategories == [] ? (
                                  <div style={{ "color": "red" }}>{errors.product_sub_cat}</div>
                                ) : null}
                                <Box display={this.state.subcatError ? 'flex' : 'none'}>
                                  <Typography color="error">Required</Typography>
                                </Box>
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="measurement-unit">
                                  Measurement Units
                                </InputLabel>
                                <Select
                                  labelId="measurement-unit"
                                  name="measurement_unit"
                                  value={String(values.measurement_unit)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <MenuItem value="centimeter">Cm</MenuItem>
                                  <MenuItem value="inch">Inches</MenuItem>
                                  <MenuItem value="meter">Meters</MenuItem>
                                </Select>
                                {errors.measurement_unit && touched.measurement_unit ? (
                                  <div style={{ "color": "red" }}>{errors.measurement_unit}</div>
                                ) : null}
                                <Box display={this.state.meaError ? 'flex' : 'none'}>
                                  <Typography color="error">Required</Typography>
                                </Box>
                              </Box>
                              <Box className="add-row">
                                <Box className="add-col" id="b1">
                                  <Box className="title-btn-wrapper">
                                    <h4>Add Attribute</h4>
                                    <Button
                                      variant="outlined"
                                      className="black-btn"
                                      type="button"
                                      onClick={() => this.addFieldColumn()}
                                    >Add Attribute</Button>
                                  </Box>
                                  {values.size_chart_column_name.map((column: any, index: any) => (
                                    <Box key={index} mb={5}>
                                      <Box className="form-group">
                                        <Box display="flex" justifyContent="space-between">
                                          <InputLabel htmlFor="name">Attribute {index + 1} </InputLabel>
                                          <CloseIcon onClick={() => this.removeFieldColumn(index)}></CloseIcon>
                                        </Box>
                                        <FormControl>
                                          <TextField value={column.label_name} onChange={(e: any) => this.updateFieldColumn(index, e)} />
                                        </FormControl>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                              <Box className="add-row">
                                <Box className="title-btn-wrapper">
                                  <h4>Add Row</h4>
                                  <Button
                                    variant="outlined"
                                    className="black-btn"
                                    type="button"
                                    onClick={() => this.addFieldRow()}
                                  >Add</Button>
                                </Box>
                                {/* render rows for the size chart */}
                                {values.size_chart_column_value.map((row, rowIndex) => (
                                  <Box key={rowIndex} mb={3}>
                                    <Box key={rowIndex} className="add-inner-row">
                                      <Box mb={3} display="flex" justifyContent="space-between">
                                        <InputLabel htmlFor="name">Row {rowIndex + 1}</InputLabel>
                                        <CloseIcon onClick={() => this.deleteRow(null, row, rowIndex)}></CloseIcon>
                                      </Box>
                                      {row.map((field: any, fieldIndex: number) => {
                                        return (
                                          <>
                                            {fieldIndex === 1 && <><h6>Attributes</h6><br /></> || null}
                                            <Box key={fieldIndex} mb={3}>
                                              <InputLabel htmlFor="name">{field.column_name}</InputLabel>
                                              <FormControl>
                                                <TextField value={field.label_value} onChange={(e) => this.updateField(rowIndex, fieldIndex, e)} />
                                              </FormControl>
                                            </Box>
                                          </>
                                        )
                                      })}
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                              <Box display={this.state.formError ? 'flex' : 'none'}>
                                <div style={{ "color": "red" }}>
                                  {(cols && cols.length && rows && !rows.length) || (!rows.length) ?
                                    configJSON.minimumOneRowError :
                                    configJSON.formHasError
                                  }
                                </div>
                              </Box>
                              <Box className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                > Update</Button>
                              </Box>
                            </Box>
                          </Box>
                        </Form>
                      )
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(NewEditSizeCharts);
