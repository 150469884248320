import React from "react";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AddIcon from "@material-ui/icons/Add";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PhoneIcon from "@material-ui/icons/Phone";
import EditIcon from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SendIcon from "@material-ui/icons/Send";
import ForumIcon from "@material-ui/icons/Forum";
import ShareIcon from "@material-ui/icons/Share";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { Menu } from "@szhsin/react-menu";
import moment from "moment";
import {
  Avatar,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  Container,
  Link,
  Box,
  Typography
} from "@material-ui/core";

import UserGroupsController, {
  Props,
  configJSON,
} from "./UserGroupsController.web";

import { GroupShoppingStyleWeb } from "./UserGroupsWebStyle";

class UserGroups extends UserGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;

    return (
      // Customizable Area Start
      (this.props.isOpen ||
        localStorage.getItem("isGroupShoppingOpen") === "true") && (
        <>
          <div
            className={classes.shopWithFriends}
            style={{
              minHeight: `${this.state.isMinimize ? "30px" : "290px"}`,
              height: `${this.state.isMinimize ? "30px" : "290px"}`,
            }}
          >
            <div className="top-nav">
              <div className="title">Shopping With Friends</div>
              <div>
                <RemoveIcon
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isMinimize: !this.state.isMinimize,
                    });
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    localStorage.removeItem("groupId");
                    this.props.callbackShopWithFriend(false);
                    this.removeState();
                  }}
                />
              </div>
            </div>
            <div className="chat-friend">
              {this.state.isFirstPageOpen && (
                <div className="first-page">
                  {this.state.groups.length === 0 ? (
                    <div className="no-group">
                      <img src={this.images.bagImage} alt="" />
                      <p>Try Shopping with your Friends!</p>
                      <p>
                        Shop together by starting a group and adding your
                        friends.
                      </p>
                    </div>
                  ) : (
                    <div className="groups">
                      {this.state.groups && 
                      this.state.groups.length &&
                      this.state.groups.map((group: any) => {
                        return group.attributes.status === "pending" ? (
                          <div className="group">
                            <Avatar>
                              {this.logoName(group.attributes.name)}
                            </Avatar>
                            <div className="info">
                              <div className="name-time">
                                <b>{group.attributes.name}</b>
                                <div className="accept-decline">
                                  <Button
                                    onClick={() => {
                                      this.acceptDeclineInvitation(
                                        "accepted",
                                        "" +
                                        group.attributes.group_invitation.id
                                      );
                                    }}
                                  >
                                    Accept
                                  </Button>
                                  <div />
                                  <Button
                                    onClick={() => {
                                      this.acceptDeclineInvitation(
                                        "rejected",
                                        "" +
                                        group.attributes.group_invitation.id
                                      );
                                    }}
                                  >
                                    Decline
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="group"
                            onClick={() => {
                              this.handleGroup(group);
                              localStorage.setItem("groupId", group.id);
                            }}
                          >
                            <Avatar>
                              {this.logoName(group.attributes.name)}
                            </Avatar>
                            <div className="info">
                              <div className="name-time">
                                <b>{group.attributes.name}</b>
                                <span>
                                  {moment(group.attributes.updated_at).calendar(
                                    null,
                                    {
                                      sameDay: "h:mm A",
                                      nextDay: "DD/MM/YY",
                                      nextWeek: "DD/MM/YY",
                                      lastDay: "DD/MM/YY",
                                      lastWeek: "DD/MM/YY",
                                      sameElse: "DD/MM/YY",
                                    }
                                  )}
                                </span>
                              </div>
                              {
                                group?.attributes?.last_chat_message?.data?.length > 0 &&
                                <div className="last-msg-no-read">
                                  <span>
                                    {Number(group.attributes.last_chat_message?.data?.[0]?.attributes?.account_id) !== Number(localStorage.getItem("userId")) ? group.attributes.last_chat_message?.data?.[0]?.attributes?.user?.name : "You"}:{" "}
                                    {group.attributes.last_chat_message.data?.[0]?.attributes?.text ? group.attributes.last_chat_message.data?.[0]?.attributes?.text : "Image"}
                                  </span>
                                  {/* <div className="no-read">2</div> */}
                                </div>
                              }
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!this.state.isMinimize && (
                    <div
                      className="add-icon"
                      onClick={() => {
                        this.openCreatePage();
                      }}
                    >
                      <AddIcon />
                    </div>
                  )}
                </div>
              )}
              {this.state.isCreateGroupOpen && (
                <div className="create-group">
                  <div className="back">
                    <ArrowBackIcon
                      onClick={() => {
                        this.fetchGroup();
                        this.backFromSecondPage();
                        this.openFirstPage();
                      }}
                    />{" "}
                    New Group
                  </div>
                  <div className="create-group-form">
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        if (this.state.groupName.length > 0) {
                          this.handleCreateGroup();
                        }
                      }}
                    >
                      <span>
                        <b>Provide a group name</b>
                      </span>

                      <TextField
                        value={this.state.groupName}
                        onChange={(e) => {
                          this.handleChangeGroupName(e.target.value);
                        }}
                        id="standard-basic"
                        label="Enter Group Name"
                        fullWidth
                      />

                      <Button
                        disabled={this.state.groupName.length === 0}
                        className="black-btn"
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        Create Group
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.isCreatedGroupOpen && (
                <div className="created-group">
                  <div className="back">
                    <ArrowBackIcon
                      onClick={() => {
                        this.openFirstPage();
                        this.fetchGroup();
                      }}
                    />{" "}
                    New Group
                  </div>
                  <div className="create-group-form">
                    <span>
                      <b>Provide a group name</b>
                    </span>
                    <TextField
                      value={this.state.groupName}
                      InputProps={{
                        readOnly: true,
                      }}
                      id="standard-basic"
                      label="Enter Group Name"
                      fullWidth
                    />
                  </div>
                  <hr />
                  <div className="add-link">
                    <div className="add-icon">
                      <GroupAddIcon /> <span>Add People</span>
                    </div>
                    <Button
                      onClick={() => {
                        this.openCreatedAddPeoplePage();
                      }}
                      className="outline-btn"
                      variant="outlined"
                    >
                      Add
                    </Button>
                  </div>
                  <div className="members-div">
                    <span>{this.state.groupMembers.length} Member</span>
                    <div className="members">
                      {this.state.groupMembers && 
                      this.state.groupMembers.length &&
                      this.state.groupMembers.map((member: any, idx: any) => {
                        return (
                          <div className="member" key={idx}>
                            <div className="name-icon">
                              <Avatar alt={member.name}>
                                {this.logoName(member.name)}
                              </Avatar>
                              <span>{member.name}</span>
                            </div>
                            <div className="button">
                              {member.role !== "Creator" && (
                                <>
                                  <DeleteIcon
                                    onClick={() => {
                                      this.removeFromGroup(member.id);
                                    }}
                                  />{" "}
                                </>
                              )}
                              <Button variant="outlined">{member.role}</Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <br />
                  <Button
                    disabled={this.state.groupName.length === 0}
                    className="black-btn"
                    variant="outlined"
                    onClick={() => {
                      this.openFirstPage();
                      this.fetchGroup();
                    }}
                    fullWidth
                  >
                    Create Group
                  </Button>
                </div>
              )}
              {this.state.isCreatedAddPeopleOpen && (
                <div className="search-add-people">
                  <div className="back">
                    <ArrowBackIcon
                      onClick={() => {
                        this.openCreatedPage();
                        this.fetchGroupMembers(this.state.createdGroupId);
                      }}
                    />{" "}
                    Add People
                  </div>
                  <div className="search">
                    <input
                      onChange={(e: any) => {
                        this.handleSearchMember(e.target.value);
                      }}
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          this.searchMember();
                        }
                      }}
                      value={this.state.searchMember}
                      type="text"
                      placeholder="Search by Phone no. or Email"
                    />
                    <SearchIcon />
                  </div>
                  <div className="members">
                    {this.state.searchMembersList.length === 0 &&
                      "No record Found"}
                    {this.state.searchMembersList &&
                    this.state.searchMembersList.length && 
                    this.state.searchMembersList.map((member: any) => {
                      return (
                        <div className="member">
                          <div className="name-icon">
                            <Avatar>
                              {this.logoName(member.attributes.name)}
                            </Avatar>
                            <span>{member.attributes.name}</span>
                          </div>
                          {
                            member.attributes.status === null &&
                            <Button
                              className="outline-btn"
                              variant="outlined"
                              onClick={() => {
                                this.createInvitation(
                                  this.state.createdGroupId,
                                  member.id
                                );
                              }}
                            >
                              Invite
                            </Button>
                          }
                          {
                            member.attributes.status === "pending" &&
                            <Button
                              className="outline-btn"
                              variant="outlined"
                            >
                              Invited
                            </Button>
                          }
                          {/* <Button
                            disabled={this.state.invitedMembersList.includes(
                              member.id
                            )}
                            className="outline-btn"
                            variant="outlined"
                            onClick={() => {
                              this.createInvitation(
                                this.state.createdGroupId,
                                member.id
                              );
                            }}
                          >
                            {this.state.invitedMembersList.includes(member.id)
                              ? "Invited"
                              : "Invite"}
                          </Button> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {this.state.isChatScreenOpen && (
                <div className="chat-screen">
                  <div className="back">
                    <div className="title">
                      <ArrowBackIcon
                        onClick={() => {
                          if (this.state.isSettingOpen) {
                            this.handleToggleSetting();
                          }
                          this.openFirstPage();
                          this.fetchGroup();
                          localStorage.removeItem("groupId");
                        }}
                      />{" "}
                      <span>{this.state.chatGroupName}</span>
                    </div>
                    <div className="back-icon">
                      <Menu
                        menuButton={
                          <div>
                            <ScreenShareIcon />
                          </div>
                        }
                      >
                        <MenuItem
                          onClick={() => {
                            this.handleZoomCall();
                          }}
                        >
                          {this.state.groupCreatorId ===
                            localStorage.getItem("userId")
                            ? "See My Screen"
                            : "See Admin's Screen"}
                        </MenuItem>
                        {this.state.groupCreatorId !==
                          localStorage.getItem("userId") && (
                            <MenuItem onClick={() => { }}>
                              Request Admin to Share
                            </MenuItem>
                          )}
                      </Menu>
                      <ReceiptIcon
                        onClick={() => {
                          this.openWishlistPage();
                          this.fetchWishList();
                        }}
                      />
                      <Menu
                        menuButton={
                          <div>
                            <MoreVertIcon />
                          </div>
                        }
                      >
                        {this.state.groupCreatorId ===
                          localStorage.getItem("userId") ? (
                          <>
                            <MenuItem onClick={() => { }}>
                              Shopping Notify
                            </MenuItem>
                            <MenuItem
                              onClick={() => this.handleToggleSetting()}
                            >
                              Settings
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem
                              onClick={() => {
                                this.openGroupInfoPage();
                                this.fetchGroupMembers(this.state.chatGroupId);
                              }}
                            >
                              Group Info
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleDialog();
                              }}
                            >
                              Exit Group
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                  <div className="chat" ref={(div: any) => { this.messageList = div; }} >
                    {
                      this.state.messageList.length > 0 &&
                      this.state.messageList.map((message: any, index: any) => {
                        return (
                          message.attributes.text ?
                            <div key={index}>
                              {
                                Number(message.attributes.account_id) !== Number(localStorage.getItem("userId")) &&
                                <>
                                  <span className="name">{message.attributes.user.name}</span>
                                </>
                              }
                              <div className={`${Number(message.attributes.account_id) === Number(localStorage.getItem("userId")) ? "my" : "user"} msg-div`}>
                                <div className="msg-chat">
                                  {
                                    message.attributes.text
                                  }
                                </div>
                              </div>
                            </div> :
                            <>
                              {
                                Number(message.attributes.account_id) !== Number(localStorage.getItem("userId")) &&
                                <>
                                  <span className="name">{message.attributes.account_name}</span>
                                </>
                              }
                              <div className={`${Number(message.attributes.account_id) === Number(localStorage.getItem("userId")) ? "my" : "user"} image-div`}>
                                <img src={message.attributes?.images[0]?.url} alt="" />
                              </div>
                            </>
                        )
                      })
                    }
                  </div>
                  <div className="text-chat">
                    <div className="input-box">
                      <div className="input-div">
                        <input type="text"
                          placeholder="Type message here"
                          value={this.state.message}
                          onChange={(e: any) => {
                            this.setState({
                              ...this.state,
                              message: e.target.value,
                            });
                          }}
                        />
                        <div>
                          <input id="myInput"
                            type="file"
                            ref={(ref: any) => this.upload = ref}
                            style={{ display: 'none' }}
                            onChange={this.onChangeFile.bind(this)}
                            accept=".png, .jpg, .jpeg"
                          />
                          <AttachFileIcon onClick={() => { this.upload.click() }} />
                        </div>
                      </div>
                      <SendIcon onClick={() => {
                        if (this.state.message.length > 0 || this.state.messageFile !== null) {
                          this.postMassage();
                        }
                      }} />
                    </div>
                    {
                      this.state.messageFile &&
                      <span>{this.state.messageFile.name}</span>
                    }
                  </div>
                  {this.state.isSettingOpen && (
                    <div className="setting">
                      <b>Settings</b>
                      <MenuItem
                        onClick={() => {
                          // this.changePageState(6);
                          this.openEditPage();
                          this.fetchGroupMembers(this.state.chatGroupId);
                        }}
                      >
                        Edit Group
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleDeleteGroup();
                        }}
                      >
                        Delete Group
                      </MenuItem>
                    </div>
                  )}
                </div>
              )}
              {this.state.isEditGroupOpen && (
                <div className="created-group edit-group">
                  <div className="back">
                    <span>
                      <ArrowBackIcon
                        onClick={() => {
                          this.backWithoutSave();
                        }}
                      />{" "}
                      New Group
                    </span>
                    <PersonAddIcon
                      onClick={() => {
                        this.openEditAddPeoplePage();
                      }}
                    />
                  </div>
                  <div className="create-group-form">
                    <span>
                      <b>Provide a group name</b>
                    </span>
                    <TextField
                      value={this.state.newChatGroupName}
                      onChange={(e) => {
                        this.handleChangeEditGroupName(e.target.value);
                      }}
                      // InputProps={{
                      //   readOnly: !this.state.isGroupNameEditable,
                      // }}
                      id="standard-basic"
                      label="Enter Group Name"
                      fullWidth
                    />
                    <EditIcon
                      onClick={() => {
                        this.enableEditGroupName();
                      }}
                    />
                  </div>
                  <hr />
                  <div className="members-div">
                    <span>{this.state.groupMembers.length} Member</span>
                    <div className="members">
                      {this.state.groupMembers.length === 0 &&
                        "No record Found"}
                      {this.state.groupMembers &&
                      this.state.groupMembers.length &&
                      this.state.groupMembers.map((member: any, idx: any) => {
                        return (
                          <div className="member" key={idx}>
                            <div className="name-icon">
                              <Avatar alt={member.name}>
                                {this.logoName(member.name)}
                              </Avatar>
                              <span>{member.name}</span>
                            </div>
                            <div className="button">
                              {member.role !== "Creator" && (
                                <>
                                  <DeleteIcon
                                    onClick={() => {
                                      this.removeFromGroup(member.id);
                                    }}
                                  />{" "}
                                </>
                              )}
                              <Button variant="outlined">{member.role}</Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <br />
                  <Button
                    onClick={() => {
                      this.handleEditGroupName();
                    }}
                    className="black-btn"
                    variant="outlined"
                    fullWidth
                  >
                    Save
                  </Button>
                </div>
              )}
              {this.state.isEditAddPeopleOpen && (
                <div className="search-add-people">
                  <div className="back">
                    <ArrowBackIcon
                      onClick={() => {
                        this.fetchGroupMembers(this.state.chatGroupId);
                        this.openEditPage();
                      }}
                    />{" "}
                    Add People
                  </div>
                  <div className="search">
                    <input
                      onChange={(e: any) => {
                        this.handleSearchMember(e.target.value);
                      }}
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          this.searchMember();
                        }
                      }}
                      value={this.state.searchMember}
                      type="text"
                      placeholder="Search by Phone no. or Email"
                    />
                    <SearchIcon />
                  </div>
                  <div className="members">
                    {this.state.searchMembersList.length === 0 &&
                      "No record Found"}
                    {this.state.searchMembersList &&
                    this.state.searchMembersList.length &&
                    this.state.searchMembersList.map((member: any) => {
                      return (
                        <div className="member">
                          <div className="name-icon">
                            <Avatar>
                              {this.logoName(member.attributes.name)}
                            </Avatar>
                            <span>{member.attributes.name}</span>
                          </div>
                          {
                            member.attributes.status === null &&
                            <Button
                              className="outline-btn"
                              variant="outlined"
                              onClick={() => {
                                this.createInvitation(
                                  this.state.chatGroupId,
                                  member.id
                                );
                              }}
                            >
                              Invite
                            </Button>
                          }
                          {
                            member.attributes.status === "pending" &&
                            <Button
                              className="outline-btn"
                              variant="outlined"
                            >
                              Invited
                            </Button>
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {this.state.isWishlistPageOpen && (
                <div className="wishlist">
                  <div className="back">
                    <div className="title">
                      <ArrowBackIcon
                        onClick={() => {
                          this.backWithoutSave();
                        }}
                      />{" "}
                      Wishlist - {this.state.chatGroupName}
                    </div>
                    <div className="back-icon">
                      <ShareIcon />
                      <ForumIcon
                        onClick={() => {
                          this.openChatPage();
                        }}
                      />
                    </div>
                  </div>
                  <div className="chat">
                    {this.state.wishlistList.length === 0 && (
                      <div className="wishlist-container">No Record Found</div>
                    )}
                    {this.state.wishlistList && 
                    this.state.wishlistList.length &&
                    this.state.wishlistList.map((catalog: any) => {
                      return (
                        <div className="wishlist-container" key={catalog.id}>
                          {/* <div className="added-by"> */}
                          <div className="name">
                            Added By {catalog.attributes.account.name}
                          </div>
                          {/* </div> */}
                          <div className="wishlist-item">
                            <div className="item-image">
                              <Link
                                href={`/ProductDetail/${catalog.attributes.catalogue?.data?.id
                                  }`}
                              >
                                <img
                                  src={
                                    catalog.attributes.catalogue.data.attributes.galleries?.[0].url
                                  }
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="item-content">
                              <b>{catalog.attributes.catalogue.data.attributes.name}</b>
                              <p>{catalog.attributes.catalogue.data.attributes.description}</p>
                            </div>
                          </div>
                          <div className="wish-cart">
                            <div
                              className="remove-from-wish"
                              onClick={() => {
                                this.removeWishlist(
                                  catalog.attributes.catalogue.data.id
                                );
                              }}
                            >
                              REMOVE FROM WISHLIST
                            </div>
                            <div className="cBorder" />
                            <div
                              className="add-to-cart"
                              onClick={() => {
                                this.setState(
                                  {
                                    ...this.state,
                                    catalogId: catalog.attributes.catalogue.data.id,
                                    catalogVariantId: catalog.attributes.catalogue.data.attributes.catalogue_variants.data?.[0]?.id, //passing first variant
                                  },
                                  () => {

                                    if (localStorage.getItem("exchangeFlow") &&
                                      localStorage.getItem("cartItemCount") == "1"

                                    ) {

                                      this.handleOpenMessagePopup()
                                    } else {
                                      this.activeCartId();
                                    }


                                    // localStorage.getItem("exchangeFlow") ? this.handleOpenMessagePopup() :
                                    //   this.activeCartId();
                                  }
                                );
                              }}
                            >
                              ADD TO CART
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {this.state.isGroupInfoPageOpen && (
                <div className="created-group edit-group">
                  <div className="back">
                    <span>
                      <ArrowBackIcon
                        onClick={() => {
                          this.openChatPage();
                        }}
                      />{" "}
                      {this.state.chatGroupName}
                    </span>
                  </div>
                  <div className="create-group-form">
                    <span>
                      <b>Group Name</b>
                    </span>
                    <TextField
                      value={this.state.chatGroupName}
                      id="standard-basic"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <hr />
                  <div className="members-div">
                    <span>{this.state.groupMembers.length} Member</span>
                    <div className="members">
                      {this.state.groupMembers.length === 0 &&
                        "No record Found"}
                      {this.state.groupMembers && 
                      this.state.groupMembers.length &&
                      this.state.groupMembers.map((member, idx) => {
                        return (
                          <div className="member" key={idx}>
                            <div className="name-icon">
                              <Avatar alt={member.name}>
                                {this.logoName(member.name)}
                              </Avatar>
                              <span>{member.name}</span>
                            </div>
                            <div className="button">
                              {member.role === "Creator" && (
                                <>
                                  <Button variant="outlined">
                                    {member.role}
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Dialog
            open={this.state.isExitGroupDialogOpen}
            onClose={() => this.handleDialog()}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"lg"}
          >
            <Container>
              <DialogContent>
                <h3 className="text-center mt-3">
                  Are you sure you want to <br /> exit the group?
                </h3>
                <br />
                <br />
                <Button
                  onClick={() => this.exitFromGroup()}
                  className="black-btn mb-3"
                  variant="outlined"
                  fullWidth
                >
                  Yes
                </Button>
              </DialogContent>
            </Container>
          </Dialog>


          {/* message popup changed design  */}

          <Dialog
            open={this.state.messagePopup}
            aria-labelledby="simple-dialog-title"
            fullWidth={true} maxWidth={"xs"}
          >
            <Box className="myBox" >
              <Box textAlign="right" style={{
                paddingRight: "11px",
                paddingTop: "6px"
              }}>
                <span className="close-btn" style={{ cursor: "pointer" }}>
                  <CloseIcon
                    onClick={() => this.handleCloseMessagePopup()}
                  ></CloseIcon>
                </span>
              </Box>
              <Box className="modal-head" style={{ padding: "17px" }} fontWeight="600" p={1}>
                <Typography style={{ textAlign: "justify" }} variant="h5"> You cannot add another product while exchange flow is in process<b>

                </b>


                </Typography>
              </Box>
              <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">

                <Button className="black-btn"
                  onClick={(e) => {
                    this.handleCloseMessagePopup()

                  }

                  }
                  size="medium"
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )
      // Customizable Area End
    );
  }
}

export default withStyles(GroupShoppingStyleWeb)(UserGroups);
