import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { CatalogueVariantModel, ProductModel, ReviewModal, ReviewResponse } from "../../../framework/src/Interfaces/IProductDetail";
import { paginationCount } from "../../../framework/src/Constants";
import { scrollToTopOfElement } from "../../../framework/src/commonFunction";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    classes: any;
    handleCartCount(number: Number): Function;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    loading: boolean;
    showSizeChart: boolean;
    token: string | null;
    cartId: number | null;
    isWishlisted: boolean | null;
    cartItemCount: number;
    isSameBasePrice: boolean;
    productId: number;
    variantId: number;
    productGalleries: any[];
    maximum_retail_price: number | null;
    sale_price: number | null
    product: ProductModel;
    prouductVariantName: string;
    similarProductList: any[];
    sameBrandProductList: any[];
    interestedInProductList: any[];
    recentlyViewProductList: any[];
    isVariantAlreadyAddedToCart: boolean;
    cartList: any[];
    sizeList: any[];
    colorList: string[];
    selectedColor: string;
    selectedSizeId: number;
    hasSizeError: boolean;
    hasColorError: boolean;
    catalogue_variants: CatalogueVariantModel[];
    isAddedToBag: boolean;
    displayReviewCount: number;
    currentPage: number;
    totalPage: number;
    reviews: ReviewModal[];
    // popup: boolean;
    exchangeFlow: boolean;
    activeCartId: any;
    exchangeProduct: any;
    activeCartCatalogueIds: any;
    cartItemsCount: any;
    messagePopup: boolean;
    cartLength: any
    // placeExchangeOrderData: any
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProductDetailController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getProductSimilarSameBrandInterestedApiCallId: any = null;
    getRecentlyViewProductsApiCallId: any = null;
    getProductDataApiCallId: any = null;
    addToWishlistApiCallId: any = null;
    removeFromWishlistApiCallId: any = null;
    activateCartApiCallId: any = null;
    getVariantBySizeIdApiCallId: any = null;
    getVariantByColorApiCallId: any = null;
    addToCartApiCallId: any = null;
    getCartListItemApiCallId: any = null;
    AddToGroupWishlistCallId: any = null;
    reviewsByCatalogueIdApiCallId: any = null;
    ActiveCartCallId: any = null;
    RemoveShoppingBagCallId: any;
    // PlaceExchangeOrderCallId: any

    similarProductList = []
    sameBrandProductList = []
    interestedInProductList = [];

    elementRef: any //for scrolling reference
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        console.log("productid::::", this.props.navigation.getParam('productId'));

        // set cart item count to local storage

        this.state = {
            loading: false,
            showSizeChart: false,
            token: localStorage.getItem('authToken'),
            cartId: Number(localStorage.getItem('cartId')),
            isWishlisted: null,
            cartItemCount: 0,
            productId: this.props.navigation.getParam('productId'),
            variantId: 0,
            productGalleries: [],
            isSameBasePrice: false,
            maximum_retail_price: null,
            sale_price: null,
            product: {} as ProductModel,
            prouductVariantName: '',
            similarProductList: [],
            sameBrandProductList: [],
            interestedInProductList: [],
            recentlyViewProductList: [],
            isVariantAlreadyAddedToCart: false,
            cartList: [],
            colorList: [],
            sizeList: [],
            selectedColor: '',
            selectedSizeId: 0,
            hasSizeError: false,
            hasColorError: false,
            catalogue_variants: [],
            isAddedToBag: false,
            displayReviewCount: 3,
            currentPage: 1,
            totalPage: 0,
            reviews: [],
            // popup: false,
            exchangeFlow: false,
            activeCartId: [],
            exchangeProduct: [],
            activeCartCatalogueIds: [],
            cartItemsCount: 0,
            messagePopup: false,
            cartLength: 0

            // placeExchangeOrderData: []
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        try {
            this.getProductDetailById(this.state.productId);
            this.getSimilar_FromSameBrand_InterestedIn_ProductList();
            if (this.state.token) {
                this.getRecentlyViewProductList();
                setTimeout(() => this.getCartList(), 2000);
            }
        } catch (e) {
            // error reading value
        }
        // this.activeUserCart()
        // const exchangeItem: any = localStorage.getItem("exchangeItem")
        // const exItem = JSON.parse(exchangeItem)
        // this.setState({ exchangeProduct: exItem })
        // const itemCount: any = localStorage.getItem('cartItemCount');
        // this.setState({ cartItemCount: itemCount })
        const exchangeFlow: any = localStorage.getItem("exchangeFlow")
        this.setState({ exchangeFlow: exchangeFlow })
        console.log(this.state.exchangeFlow, "exchange boolean")

    }

    // temporary
    async receive(from: string, message: Message) {

        // runEngine.debugLog("Message Recived", message);
        // console.log("in receive");
        // console.log("message::::", message);


        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        // console.log("apiRequestCallId:::::", apiRequestCallId);

        // Get cart list
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getCartListItemApiCallId !== null && apiRequestCallId === this.getCartListItemApiCallId) {
            this.getCartListItemApiCallId = null;

            const { variantId, productId } = this.state;
            if (responseJson && responseJson.data) {
                let cartList = [];

                const cartItemList: any[] = responseJson.data;
                // cart count 
                // const cartLength = cartItemList.length
                // this.setState({ cartLength: cartLength })

                if (cartItemList && cartItemList.length) {
                    cartList = cartItemList.map((cartItem: any) => {
                        const cartListModel: any = {};
                        cartListModel.catalogue_id = cartItem.attributes.catalogue_id;
                        cartListModel.catalogue_variant_id = cartItem.attributes.catalogue_variant_id;
                        return cartListModel;
                    });
                }

                if (variantId && cartList && cartList.length) {
                    const isVariantAddedToCart = cartList.some((cart) => cart.catalogue_id === Number(productId) && cart.catalogue_variant_id === Number(variantId));
                    // console.log("isVariantAddedToCart:::", isVariantAddedToCart);
                    isVariantAddedToCart ? this.setState({ isVariantAlreadyAddedToCart: true }) : null;
                }
                this.setState({ cartList: cartList, cartItemCount: cartList.length },
                    () => {
                        this.props.handleCartCount(this.state.cartItemCount)
                    }
                );
                localStorage.setItem('cartItemCount', this.state.cartItemCount.toString());
            }
            // if (responseJson.message == "Record not found!") {
            //     this.setState({ cartLength: 0 })
            // }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Add To Cart



        // place  Exchange order start
        // if (
        //     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        //     this.PlaceExchangeOrderCallId !== null &&
        //     this.PlaceExchangeOrderCallId ===
        //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        // ) {
        //     this.PlaceExchangeOrderCallId = null;
        //     // var responseJson = message.getData(
        //     //   getName(MessageEnum.RestAPIResponceSuccessMessage)
        //     // );
        //     if (responseJson) {
        //         this.setState({ placeExchangeOrderData: responseJson.data });
        //         console.log('////////////// place Exchange order------------------------------------------------------', this.state.placeExchangeOrderData)
        //     }
        //     else { alert("issue") }

        //     var errorReponse = message.getData(
        //         getName(MessageEnum.RestAPIResponceErrorMessage)
        //     );
        //     if (responseJson && responseJson.meta && responseJson.meta.token) {
        //         runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //         //Need To send Login token message to save for future call
        //     } else {
        //         //Check Error Response
        //         this.parseApiErrorResponse(responseJson);
        //     }
        //     this.parseApiCatchErrorResponse(errorReponse);
        // }

        // place Exchangeorder end 





        // Active cart api
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.activateCartApiCallId !== null && apiRequestCallId === this.activateCartApiCallId) {
            this.activateCartApiCallId = null;

            const { variantId, productId } = this.state;
            if (responseJson && responseJson.data) {


                this.setState({ cartId: responseJson.data.id }, () => this.addToBag(productId, variantId))
            } else if (responseJson.message) {
                this.setState({ cartId: null }, () => this.addToBag(productId, variantId))
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Active cart api









        // Active cart api
        //  if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.activateCartApiCallId !== null && apiRequestCallId === this.activateCartApiCallId) {
        //     this.activateCartApiCallId = null;

        //     const { variantId, productId } = this.state;
        //     if (responseJson && responseJson.data) {
        //         // const productIds: any = []
        //         // const activeCartCatalogueIds: any = []
        //         responseJson.data.attributes.order_items_details.map((item: any) => {
        //             this.setState({ cartItemsCount: item.length })
        //             alert(this.state.cartItemsCount)
        //             console.log(this.state.cartItemsCount, "items count")
        //             console.log(this.state.exchangeFlow, "exchange boolean")
        //             //  productIds.push(item.id);
        //             //  activeCartCatalogueIds.push(item.catalogue_id)
        //         })
        //         // this.setState({ cartId: responseJson.data.id, activeCartId: productIds, activeCartCatalogueIds: activeCartCatalogueIds }, () => this.addToBag(productId, variantId))


        //     } else if (responseJson.message) {
        //         this.setState({ cartItemsCount: 0 })
        //         this.setState({ cartId: null },

        //             //  () => this.addToBag(productId, variantId)
        //         )
        //         console.log(this.state.cartItemsCount, "items count")
        //         console.log(this.state.exchangeFlow, "exchange boolean")
        //     }

        //     let errorReponse = message.getData(
        //         getName(MessageEnum.RestAPIResponceErrorMessage)
        //     );

        //     if (responseJson && responseJson.meta && responseJson.meta.token) {
        //         runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //         //Need To send Login token message to save for future call
        //     } else {
        //         //Check Error Response
        //         this.parseApiErrorResponse(responseJson);
        //     }
        //     this.parseApiCatchErrorResponse(errorReponse);
        // }
        // END Active cart api








        // GET PRODUCT DETAIL BY ID
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getProductDataApiCallId !== null && apiRequestCallId === this.getProductDataApiCallId) {
            this.getProductDataApiCallId = null;
            this.setState({ loading: false });
            if (responseJson && responseJson.data) {
                this.setState({
                    product: responseJson.data,
                    productId: responseJson.data.id,
                    isWishlisted: responseJson.data.attributes.is_wishlist,
                    catalogue_variants: responseJson.data.attributes.catalogue_variants_attributes,
                    productGalleries: responseJson.data.attributes && responseJson.data.attributes.catalogue_variants_attributes && (responseJson.data.attributes.catalogue_variants_attributes as any[]).length ? responseJson.data.attributes.catalogue_variants_attributes[0].attributes.galleries : [],
                    isSameBasePrice: responseJson.data.attributes.sale_price && responseJson.data.attributes.maximum_retail_price ? true : false,
                }, () => {
                    this.setColorAndSizeListing(this.state.catalogue_variants);
                    this.setSalePriceAndMaxRetailPrice('', 0);
                });
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END GET PRODUCT DETAIL BY ID

        // GET RECENTLY VIEW PRODUCTS
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getRecentlyViewProductsApiCallId !== null && apiRequestCallId === this.getRecentlyViewProductsApiCallId) {
            this.getRecentlyViewProductsApiCallId = null;

            let recentlyViewProductList = [];
            if (responseJson.data && (responseJson.data as any[]).length) {
                recentlyViewProductList = responseJson.data.sort((a: any, b: any) => parseFloat(b.id) - parseFloat(a.id));
            }
            this.setState({ recentlyViewProductList: recentlyViewProductList });

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END GET RECENTLY VIEW PRODUCTS

        // Set Similar , Same Brand, Interested In Product List
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getProductSimilarSameBrandInterestedApiCallId !== null && apiRequestCallId === this.getProductSimilarSameBrandInterestedApiCallId) {
            this.getProductSimilarSameBrandInterestedApiCallId = null;

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data && responseJson.data.attributes) {
                const response = responseJson.data.attributes;
                if (response) {
                    this.setState({ similarProductList: response.similar_product.data, sameBrandProductList: response.more_from_x_brands.data, interestedInProductList: response.you_might_be_interested_in.data, });
                }
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Set Similar , Same Brand, Interested In Product List

        // Add To Wishlist
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addToWishlistApiCallId !== null && apiRequestCallId === this.addToWishlistApiCallId) {
            this.addToWishlistApiCallId = null;

            // let responseJson = message.getData(
            //     getName(MessageEnum.RestAPIResponceSuccessMessage)
            // );

            if (responseJson && responseJson.catalogue && responseJson.wish_catalogue) {
                // add to wishlist changes button to remove wishlist


                this.setState({ isWishlisted: true });

            }
            if (responseJson) {
                // add to wishlist changes button to remove wishlist
                if (localStorage.getItem("exchangeFlow") == "true") {
                    this.RemoveShoppingBag();
                    // this.setState({ isWishlisted: true });
                }


            }


            // this.setState({ product: responseJson.data.attributes });

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Add To Wishlist

        // Add To Group Wishlist
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.AddToGroupWishlistCallId !== null && apiRequestCallId === this.AddToGroupWishlistCallId) {
            this.AddToGroupWishlistCallId = null;

            if (responseJson && responseJson.catalogue && responseJson.wish_catalogue) {
                this.setState({ isWishlisted: true }, () => {
                    toast.success('Successfully added in wishlist', { position: "top-right", autoClose: 5000, closeOnClick: true, })
                });
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Add To Group Wishlist

        // Get variant by size
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getVariantBySizeIdApiCallId !== null && apiRequestCallId === this.getVariantBySizeIdApiCallId) {
            this.getVariantBySizeIdApiCallId = null;

            // let responseJson = message.getData(
            //     getName(MessageEnum.RestAPIResponceSuccessMessage)
            // );

            if (responseJson && responseJson.data) {
                this.setState({ catalogue_variants: responseJson.data }, () => this.setColorAndSizeListing(this.state.catalogue_variants, '', this.state.selectedSizeId));
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Get variant by size

        // Get variant by color
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getVariantByColorApiCallId !== null && apiRequestCallId === this.getVariantByColorApiCallId) {
            this.getVariantByColorApiCallId = null;
            if (responseJson && responseJson.data) {
                this.setState({ catalogue_variants: responseJson.data }, () => this.setColorAndSizeListing(this.state.catalogue_variants, this.state.selectedColor));
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Get variant by color

        // Add To Cart
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addToCartApiCallId !== null && apiRequestCallId === this.addToCartApiCallId) {
            this.addToCartApiCallId = null;

            if (responseJson && responseJson.data) {
                // show toster message added to bag successfully
                this.setState({ isAddedToBag: true, cartId: responseJson.data.order.data.id }, () => {
                    toast.success('added to bag', { position: "top-right", autoClose: 5000, closeOnClick: true, })
                    localStorage.setItem("cartId", this.state.cartId?.toString() || '')
                });
                this.getCartList();
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Add To Cart

        //  remove start shopping bag

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.RemoveShoppingBagCallId !== null &&
            this.RemoveShoppingBagCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.RemoveShoppingBagCallId = null;
            // var responseJson = message.getData(
            //   getName(MessageEnum.RestAPIResponceSuccessMessage)
            // );
            if (responseJson.message == "cart items Removed") {
                // this.handleClosePop()
                //   this.shoppingCartData();
                //   this.shoppingPriceData();
                // this.addToCart(this.state.productId)
                console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove shopping------------------------------------------------------', responseJson.data);
            }
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // end  Remove  shopping bag




        // active cart  id start
        // if (
        //     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        //     this.ActiveCartCallId !== null &&
        //     this.ActiveCartCallId ===
        //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        // ) {
        //     this.ActiveCartCallId = null;
        //     // var responseJson = message.getData(
        //     //     getName(MessageEnum.RestAPIResponceSuccessMessage)
        //     // );


        //     if (responseJson) {
        //         this.setState({ activeCartId: responseJson.data })
        //         console.log('////////////// activeCartId data------------------------------------------------------', this.state.activeCartId)
        //     }
        //     else { this.setState({ activeCartId: "" }) }


        //     var errorReponse = message.getData(
        //         getName(MessageEnum.RestAPIResponceErrorMessage)
        //     );
        //     if (responseJson && responseJson.meta && responseJson.meta.token) {
        //         runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //         //Need To send Login token message to save for future call
        //     }
        //     else {
        //         //Check Error Response
        //         this.parseApiErrorResponse(responseJson);
        //     }
        //     this.parseApiCatchErrorResponse(errorReponse);
        // }

        // active cart id end 











        // Get reviews by catalogue id
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.reviewsByCatalogueIdApiCallId !== null && apiRequestCallId === this.reviewsByCatalogueIdApiCallId) {
            this.reviewsByCatalogueIdApiCallId = null;

            const reviewResponse: ReviewResponse | null = responseJson && responseJson.data ? responseJson : null;
            const reviews: ReviewModal[] = responseJson && responseJson.data ? responseJson.data : [];

            if (reviews && reviews.length) {
                this.setState({
                    reviews: reviews,
                    totalPage: reviewResponse?.meta?.total_pages || 0
                });
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Get reviews by catalogue id

        // Customizable Area End
    }

    goToLoginScreen = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    hideAddToBagMessage = () => {
        this.setState({ isAddedToBag: false });
    }

    async getProductDetailById(productID: number) {

        this.setState({ loading: true });
        let header: any = {
            "Content-Type": "application/json",
        };

        if (this.state.token) {
            header = {
                ...header,
                token: this.state.token
            }
        }

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProductDataApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProductDetailById + `/${productID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getSimilar_FromSameBrand_InterestedIn_ProductList() {

        let header: any = {
            "Content-Type": configJSON.apiContentType,
        };

        if (this.state.token) {
            header = {
                ...header,
                token: this.state.token || ''
            }
        }

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProductSimilarSameBrandInterestedApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProduct_SimilarProduct_FromSameBrand_InterestedIn + `?id=${this.state.productId}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getRecentlyViewProductList() {

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRecentlyViewProductsApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRecentlyViewProduct
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async addToWishist(productIDs: number) {
        console.log(productIDs, "product issss")
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        // var httpBody: any = {
        //     catalogue_ids: localStorage.getItem("exchangeFlow") == "true" ? this.state.activeCartCatalogueIds : [productID]
        // }

        const httpBody =
        {
            catalogue_ids: this.state.activeCartCatalogueIds
        }


        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addToWishlistApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToWishlistApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }


    // add to wishlist bulk 
    async addToWishistSingle(productID: number) {
        console.log(productID, "product issss")
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        // var httpBody: any = {
        //     catalogue_ids: localStorage.getItem("exchangeFlow") == "true" ? this.state.activeCartCatalogueIds : [productID]
        // }

        const httpBody =
        {

            catalogue_ids: [productID]
        }



        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addToWishlistApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToWishlistApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }


    // Active Cart id start 
    activeCartId() {

        // console.log("---------------->>>>>>> showCategories");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.token,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.ActiveCartCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ActiveCartAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;


    }


    // place exchange  order start
    // placeExchangeorder(productid: any, variantId: any) {
    //     console.log(Number(productid), variantId)

    //     const header = {
    //         "Content-Type": configJSON.apiContentType,
    //         token: this.state.token
    //     };

    //     const httpBody = {

    //         "catalogue_id": productid,
    //         "catalogue_variant_id": variantId,
    //         "type": "exchange", //#use this when you will create the new exchange order.
    //         "order_item_id": localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
    //     };

    //     const apiRequest = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.PlaceExchangeOrderCallId = apiRequest.messageId;

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.PlaceExchangeOrderAPiEndPoint
    //     );

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );

    //     apiRequest.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.postMethodType);

    //     runEngine.sendMessage(apiRequest.id, apiRequest);

    //     return true;

    // }


    // place ordeer end 



    // Add to Group Wishlist
    addToGroupWishlist = async (catalogId: number) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("authToken"),
        };

        const body = {
            group_shopping_id: localStorage.getItem("groupId"),
            catalogue_id: catalogId
        }

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.AddToGroupWishlistCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToGroupWishlistAPIEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    async removeFromWishist(productID: number) {

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        const httpBody = {
            catalogue_ids: [productID]
        }

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeFromWishlistApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeFromWishlistApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async activeUserCart() {

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.activateCartApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.activateCartApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    setColorAndSizeListing = (catalogue_variants: CatalogueVariantModel[], color?: string, sizeId?: number) => {
        const { product } = this.state;
        // variant list
        // get first color
        let selectedColor = '';
        let uniqueColorList;
        if (color) {
            uniqueColorList = this.state.colorList;
            selectedColor = color;
        } else {
            if (product.attributes.catalogue_variants_attributes && product.attributes.catalogue_variants_attributes.length) {
                const colorList: string[] = product.attributes.catalogue_variants_attributes.map((varint: CatalogueVariantModel) => {
                    return varint.attributes.color;
                });

                uniqueColorList = new Set(colorList);
                let colors = uniqueColorList.values();
                uniqueColorList.has(this.state.selectedColor) ? selectedColor = this.state.selectedColor : selectedColor = colors.next().value;
            }
        }

        let productGalleries: any[] = [];

        let selectedSize: number = 0;
        let sizeList: any[] = [];
        if (sizeId) {
            sizeList = this.state.sizeList;
            selectedSize = sizeId;
        } else {
            catalogue_variants.map((varint: CatalogueVariantModel) => {
                if (selectedColor === varint.attributes.color) {

                    productGalleries = varint.attributes.galleries;

                    const sizeModel = {} as any;
                    sizeModel.id = varint.attributes.measurement_size_value_id;
                    sizeModel.size_name = varint.attributes.measurement_size_value;
                    sizeList.push(sizeModel);
                    return;
                }
            });
        }

        const prouductVariantName =
            this.state.catalogue_variants &&
                this.state.catalogue_variants.length ?
                this.state.catalogue_variants.find((variant) =>
                    variant.attributes.color === selectedColor
                ) : undefined;

        if (sizeId) {
            this.setState({
                // productGalleries: productGalleries,
                selectedColor: selectedColor,
                colorList: Array.from(uniqueColorList || []),
                selectedSizeId: selectedSize,
                sizeList: sizeList,
            })
        } else {
            this.setState({
                // productGalleries: productGalleries,
                selectedColor: selectedColor,
                colorList: Array.from(uniqueColorList || []),
                selectedSizeId: selectedSize,
                sizeList: sizeList,
                prouductVariantName: prouductVariantName?.attributes.product_name || ''
            })
        }
    }

    setSalePriceAndMaxRetailPrice(color: string, size: number) {
        const { product, isSameBasePrice } = this.state;
        if (isSameBasePrice) {
            this.setState({ maximum_retail_price: product.attributes.maximum_retail_price, sale_price: product.attributes.sale_price });
            return;
        } else {
            if (product.attributes.catalogue_variants_attributes && product.attributes.catalogue_variants_attributes.length) {
                if (color && size) {
                    const variant: CatalogueVariantModel | undefined =
                        product.attributes.catalogue_variants_attributes.
                            find((variant: CatalogueVariantModel) =>
                                variant.attributes.color === color &&
                                variant.attributes.measurement_size_value_id === size);
                    if (variant) {
                        this.setState({
                            maximum_retail_price: variant.attributes.maximum_retail_price,
                            sale_price: variant.attributes.sale_price
                        });
                    }
                } else if (color) {
                    const variant: CatalogueVariantModel | undefined = product.attributes.catalogue_variants_attributes.find((variant: CatalogueVariantModel) => variant.attributes.color === color);
                    if (variant) {
                        this.setState({
                            maximum_retail_price: variant.attributes.maximum_retail_price,
                            sale_price: variant.attributes.sale_price
                        });
                    }
                } else {
                    const variant: CatalogueVariantModel | undefined = product.attributes.catalogue_variants_attributes[0];
                    if (variant) {
                        this.setState({
                            maximum_retail_price: variant.attributes.maximum_retail_price,
                            sale_price: variant.attributes.sale_price
                        });
                    }
                }
            }
        }
    }

    async getVarintBySizeId(productId: number, sizeId: number) {
        const { selectedColor, selectedSizeId, cartList } = this.state;

        if (selectedSizeId === sizeId) {
            return;
        }

        this.setState({ isVariantAlreadyAddedToCart: false });

        // console.log("in side size id funtion:::");
        // console.log("selectedColor:::", selectedColor);
        // console.log("sizeId:::", sizeId);
        this.setSalePriceAndMaxRetailPrice(this.state.selectedColor, sizeId);

        if (selectedColor && sizeId) {
            const variantId = this.setVariantId(selectedColor, sizeId);
            this.setState({ variantId: variantId });
            // console.log("get variant id:::", variantId);
            if (variantId && cartList && cartList.length) {
                const isVariantAddedToCart = cartList.some((cart) => cart.catalogue_id === Number(productId) && cart.catalogue_variant_id === Number(variantId));
                // console.log("isVariantAddedToCart:::", isVariantAddedToCart);
                isVariantAddedToCart ? this.setState({ isVariantAlreadyAddedToCart: true }) : null;
            }
        }

        this.setState({ selectedSizeId: sizeId });

        const header = {
            "Content-Type": configJSON.apiContentType,
            // token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVariantBySizeIdApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getvariantListBySizeAndColorApi + `/${productId}/get_variants_color_by_size_id?size_id=${sizeId}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getVariantByColor(productId: number, color: string) {

        if (this.state.selectedColor === color) {
            return;
        }

        this.setSalePriceAndMaxRetailPrice(color, 0);

        const variant = this.state.product.attributes.catalogue_variants_attributes.find((variant: CatalogueVariantModel) => variant.attributes.color === color);
        this.setState({ selectedColor: color, productGalleries: variant?.attributes.galleries || [] });
        this.setState({ isVariantAlreadyAddedToCart: false });


        const header = {
            "Content-Type": configJSON.apiContentType,
            // token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVariantByColorApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getvariantListBySizeAndColorApi + `/${productId}/get_variants_size_by_color?color=${color}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    setVariantId = (selectedColor: string, selectedSize: number) => {
        const { catalogue_variants_attributes } = this.state.product.attributes;

        let variantId: number = 0;
        catalogue_variants_attributes &&
            catalogue_variants_attributes.length &&
            catalogue_variants_attributes.map((variant: CatalogueVariantModel) => {
                if (variant.attributes.color === selectedColor && variant.attributes.measurement_size_value_id === selectedSize) {
                    variantId = variant.id;
                }
            });
        // console.log("set variant id:::", variantId);
        return variantId;
        // this.setState({ variantId: variantId });
    }

    // hit cart add api on condition of exchange flow is going or not 


    async addToCart(productId: number) {
        const { selectedSizeId: selectedSize, selectedColor } = this.state;

        const variantId = this.setVariantId(selectedColor, selectedSize);
        this.setState({ variantId: variantId });

        let hasSizeError = false;
        let hasColorError = false;
        if (!selectedColor || !selectedSize) {
            if (!selectedColor) {
                hasColorError = true;
            }
            if (!selectedSize) {
                hasSizeError = true;
            }
        }
        this.setState({ hasSizeError: hasSizeError, hasColorError: hasColorError });


        if (!hasSizeError && !hasColorError) {
            this.activeUserCart();
        }
    }
    // remove from shopping bag  start
    RemoveShoppingBag() {
        // console.log(this.state.ShoppingRemoveBag, "remove product id")

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
        };
        const httpBody = {
            "ids": this.state.activeCartId

        }
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        // console.log("$$$$$$$$$$$$$", apiRequest);
        this.RemoveShoppingBagCallId = apiRequest.messageId;
        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RemoveShoppingBagAPIEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDELETE
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }
    // remove from shopping bag end 







    async addToBag(productId: number, variantId: number | null) {

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        const httpBody =
            this.state.exchangeFlow ? {
                "quantity": 1,
                "catalogue_id": Number(productId),
                "catalogue_variant_id": Number(variantId),
                "type": "exchange", //#use this when you will create the new exchange order.
                "order_item_id": localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
            } : {
                quantity: 1,
                catalogue_id: Number(productId),
                catalogue_variant_id: Number(variantId),
                cart_id: Number(this.state.cartId) || null
            }


        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addToCartApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToCartApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethodType
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getCartList() {
        const { productId, variantId } = this.state;
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCartListItemApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cartListApi
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    async getReviewListByCatalogueId() {
        const { productId, currentPage } = this.state;
        const header = {
            "Content-Type": configJSON.apiContentType
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewsByCatalogueIdApiCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reviewListAPIEndPoint + `?catalogue_id=${productId}&page=${currentPage}&per_page=${paginationCount}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    handlePageChange = async (currentPage: number) => {
        this.setState({
            currentPage: currentPage
        }, () => {
            this.getReviewListByCatalogueId();
            scrollToTopOfElement(this.elementRef)
        });
    }

    handleOpenMessagePopup = () => {
        this.setState({ messagePopup: true })
    };

    handleCloseMessagePopup = () => {
        this.setState({ messagePopup: false })
    };



    // handleClosePop() {
    //     this.setState({
    //         popup: false
    //     });
    // }


}