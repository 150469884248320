import React, { useState, Component } from "react";
// import {
//   Box,
//   TextField,
//   InputLabel,
//   Input,
//   Divider,
//   FormControl,
// } from "@material-ui/core";
// import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import TabContainer from "@material-ui/core/TableContainer";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Select from "@material-ui/core/Select";
// import MenuItems from "@material-ui/core/MenuItem";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import moment from "moment";
// import {
//   Menu,
//   MenuItem,
//   MenuButton,
//   SubMenu,
// } from "../../../../node_modules/@szhsin/react-menu";

import { withStyles } from "@material-ui/core/styles";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import CustomerAddressBookController, { Props, configJSON } from "./CustomerAddressBookController.web";

// My Profile Start
const styles: any = {
  userouter: {
    // padding: "50px 0",
    "& h2": {
      fontSize: "18px",
      lineHeight: "22px",
      color: "#121212",
      fontWeight: "700",
      borderBottom: "1px solid #e6e6e6",
      margin: "0",
      textTransform: "uppercase",
      padding: "0 0 20px",
    },
    "& .user-detail-wrapper": {
      padding: "30px 20px",
      background: "#f3f3f3",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner": {
      background: "#fff",
      margin: "0 0 20px",
      padding: "29px 20px",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 20px",
    },
    "& .user-personal-detail .user-detail-list:not(:last-child)": {
      margin: "0 0 20px",
    },
    "& .user-personal-detail .user-detail-list p": {
      fontSize: "16px",
      LineHeight: "20px",
      color: "#121212",
      fontWeight: "400",
      margin: "0",
    },
    "& .user-personal-detail .user-detail-list p span": {
      opacity: "0.6",
      minWidth: "175px",
      display: "inline-block",
      "@media (max-width:767px)": {
        display: "block",
        margin: "0 0 3px",
      },
    },
    "& .user-address-wrapper .user-address-list:not(:last-child)": {
      borderBottom: "1px solid #e3e3e3",
      padding: "0 0 25px",
      margin: "0 0 20px",
    },
    "& .user-address-list h6": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 15px",
    },
    "& .user-address-list p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#5d5d66",
      fontWeight: "400",
      margin: "0 0 15px",
      maxWidth: "500px",
      textTransform: "capitalize",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .user-address-list a": {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      color: "#121212",
      textTransform: "uppercase",
      border: "1px solid #212121",
      borderRadius: "4px",
      padding: "4px 7px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .user-address-list a:hover": {
      background: "#212121",
      color: "#fff",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      padding: "15px 0 0",
      "@media (max-width:767px)": {
        padding: "0 0 0",
      },
    },
  },
};
// My Profile End


const profileList = [
  { id: "1", category: "My Profile" },
  { id: "2", category: "My Orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" }
]
export class CustomerMyProfile extends CustomerAddressBookController {
  constructor(props: Props) {
    super(props);
  }

  renderCustomerMyProfile = () => {
    const { classes } = this.props;
    return (<>
      <div ref={this.myRef} className={classes.userouter}>
        {/* <Container maxWidth="lg">
            <Grid container spacing={4}> */}
        {/* <Grid item xs={12}>
                <h2>Account</h2>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="profile-list">
                  <ul>
                    {profileList.map((items) => (
                      <li>
                        <Link href="#">{items.category}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid> */}
        <Grid
        //   item xs={12} md={9}
        >
          <div className="user-detail-wrapper">
            <div className="user-detail-inner">
              <h4>Personal Details</h4>
              <div className="user-personal-detail">
                <div className="user-detail-list">
                  <p>
                    <span>Name</span>{this.state.profileDetail.attributes?.name}
                  </p>
                </div>
                <div className="user-detail-list">
                  <p>
                    <span>Email ID</span>{this.state.profileDetail.attributes?.email}
                  </p>
                </div>
                <div className="user-detail-list">
                  <p>
                    <span>Mobile Number</span>{this.state.profileDetail.attributes?.phone_number}
                  </p>
                </div>
                <div className="user-detail-list">
                  <p>
                    <span>Gender</span>{this.state.profileDetail.attributes?.gender}
                  </p>
                </div>
                <div className="user-detail-list">
                  <p>
                    <span>Date of Birth</span>{moment(this.state.profileDetail.attributes?.date_of_birth).format("DD-MM-YYYY")}

                  </p>
                </div>
              </div>
            </div>
            <div className="user-detail-inner">
              <h4>Address</h4>
              <div className="user-address-wrapper">
                {this.state.addressData.length == 0 ? <><h6>No Address</h6></> : this.state.addressData.map((address: any) => <div className="user-address-list">
                  <h6>{address.attributes.name}</h6>
                  <p>
                    {address.attributes.address}, {address.attributes.city}, {address.attributes.state} - {address.attributes.zip_code}
                  </p>
                  <Link href="#">{address.attributes.save_address_as}</Link>
                </div>

                )}
              </div>
            </div>
            <div className="btn-wrapper">
              <Button
                onClick={() => { this.props.navigation.navigate("CustomerEditProfile") }}
                variant="contained"
                className="black-btn"
                type="button"
              >
                Edit Profile
              </Button>
            </div>
          </div>
        </Grid>
        {/* </Grid>
          </Container> */}

      </div>

    </>)
  }

  render() {
    const { classes } = this.props;

    return (
      <>


        <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend}  >
          {this.renderCustomerMyProfile()}
        </CustomerAccountSideBarWeb>




      </>
    );
  }
}

export default withStyles(styles)(CustomerMyProfile);
