import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ProductBasicDetailModel } from "../../../framework/src/Interfaces/IProductDetail";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string | null;
  userId: string | null;
  step: number;
  activeStep: number;
  skipped: Set<number>;
  id: number | null;
  isEdit: boolean;
  product: any;
  categoryData: any[];
  subCategoryData: any[];
  subSubCategoryData: any[];
  brandListData: any[];
  serialNumber: string;
  categoryId: string;
  subCategoryId: string;
  subSubCategoryId: string;
  sku: string;
  brandId: number | string;
  productName: string;
  country: string;
  hsn: any;
  manufacture: string;
  address: string;
  file: Array<any>;
}

interface SS {}

export default class AddProductBasicInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductDataApiCallId: any;
  basicInfoApiCallId: any;
  categoryListApiCallId: any;
  subCategoryListApiCallId: any;
  subSubCategoryListApiCallId: any;
  getBrandListApiCallId: any;

  categoryData = [{ value: "", label: "" }];
  subCategoryData = [{ value: "", label: "" }];
  subSubCategoryData = [{ value: "", label: "" }];
  brandListData = [{ value: "", label: "" }];

  productType: string | null = null;
  // Customizable Area End

  public intialValues: ProductBasicDetailModel = {
    serialNumber: "",
    // productType: null,
    categoryId: "",
    subCategoryId: "",
    subSubCategoryId: "",
    sku: "",
    brandId: "",
    productName: "",
    country: "",
    hsn: "",
    manufacture: "",
    address: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      token: localStorage.getItem("authToken"),
      userId: localStorage.getItem("userId"), // here it's a vendor id
      step: 1,
      activeStep: 0,
      skipped: new Set<number>(),
      id: Number(localStorage.getItem("id")) || null,
      isEdit: Boolean(localStorage.getItem("isEdit")),
      product: {},
      categoryData: [],
      subCategoryData: [],
      subSubCategoryData: [],
      brandListData: [],
      serialNumber: "",
      // productType: "",
      categoryId: "",
      subCategoryId: "",
      subSubCategoryId: "",
      sku: "",
      brandId: "",
      productName: "",
      country: "",
      hsn: "",
      manufacture: "",
      address: "",
      file: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // if (this.state.id) {
    //  this.getProductData();
    // }
    // this.getCategoryList();
    // this.getSubCategoryList();
    // this.getBrandList();
    // Customizable Area End
  }

  async componentDidMount() {
    //  api
    if (this.state.id) {
      await this.getProductData();
    } else {
      // await this.getSubSubCategoryList();
      await this.getSubCategoryList();
      await this.getCategoryList();
      await this.getBrandList();
    }
  }

  async receive(from: string, message: Message) {
    console.log("api call ------------------>>>");
    console.log("message ------------------>>>", message);
    console.log("message ------------------>>>", message.id);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.VendorAddUpdateProductDataMessage)
      );
      if (data) {
        const { id, isEdit } = data;
        this.setState({
          id: id,
          isEdit: isEdit,
        });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("json response ------------------>>>", responseJson.data);
      //console.log('responseJson ------------------>>>',responseJson)

      if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors.length &&
        responseJson.errors[0].token
      ) {
        this.goToLoginScreen();
      }

      if (apiRequestCallId && (responseJson.data || responseJson.message)) {
        if (apiRequestCallId === this.getProductDataApiCallId) {
          // store product data into product state
          this.setState({ product: responseJson.data });
          const { product } = this.state;
          if (product && product.attributes) {
            const data = product.attributes;
            console.log("category_id:::", data.product_type);
            console.log("sub_category_id:::", data.sub_category_id);
            // set categoryId for subcategory api calling based on category
            (this.productType =
              (data.product_type &&
                (data.product_type as []).length > 0 &&
                data.product_type[0] &&
                data.product_type[0].id) ||
              ""),
              this.setState(
                {
                  serialNumber: data.serial_number || "",
                  // categoryId: data.product_type && (data.product_type as []).length > 0 && data.product_type[0] && data.product_type[0].id || '',
                  // subCategoryId: data.sub_category_id && (data.sub_category_id as []).length > 0 && data.sub_category_id[0] && data.sub_category_id[0].id || '',
                  sku: data.sku || "",
                  // brandId: data.brand_id || "",
                  productName: data.name || "",
                  country: data.country_of_origin || "",
                  hsn: data.hsn_code || "",
                  manufacture: data.name_of_manufacturer || "",
                  address: data.address_of_manufacturer || "",
                },
                () => {
                  this.getSubCategoryList();
                  this.getCategoryList();
                  this.getBrandList();
                }
              );
          }
          console.log("product:::", this.state.product);
        } else if (apiRequestCallId === this.basicInfoApiCallId) {
          this.basicInfoApiCallId = null;
          localStorage.setItem("id", `${responseJson.data.id}`);
          this.gotoProductDetailScreen();
          // store catelogue id and pass to next display next ui part
        } else if (apiRequestCallId === this.categoryListApiCallId) {
          console.log("category list response ::::", responseJson.data);
          this.categoryData = [];

          let data: any = "";
          let categoryId = "";
          if (Object.keys(this.state.product).length) {
            data = this.state.product.attributes;
            categoryId =
              (data.product_type &&
                (data.product_type as []).length > 0 &&
                data.product_type[0] &&
                data.product_type[0].id) ||
              "";
          }
          // const data = this.state.product.attributes;
          // const categoryId = data.product_type && (data.product_type as []).length > 0 && data.product_type[0] && data.product_type[0].id || '';

          responseJson.data &&
            (responseJson.data as any[]).length &&
            responseJson.data.map((d: any) => {
              this.categoryData.push({
                label: d.attributes.name,
                value: d.attributes.id,
              });
              // d.attributes.sub_categories && d.attributes.sub_categories.data.map((d: any) => {
              //   this.subCategoryData.push({ label: d.name, value: d.id })
              // })
            });

          this.setState({
            categoryData: this.categoryData,
            categoryId: categoryId,
          });
        } else if (apiRequestCallId === this.subCategoryListApiCallId) {
          console.log("sub category response::", responseJson.data);
          this.subCategoryData = [];

          let subCategoryIdExist = false;

          let data: any = "";
          let sub_category_id = "";
          if (Object.keys(this.state.product).length) {
            data = this.state.product.attributes;
            sub_category_id =
              (data.sub_category_id &&
                (data.sub_category_id as []).length > 0 &&
                data.sub_category_id[0] &&
                data.sub_category_id[0].id) ||
              "";
          }

          responseJson.data &&
            (responseJson.data as any[]).length &&
            responseJson.data.map((d: any) => {
              if (this.state.isEdit) {
                if (sub_category_id === d.attributes.id) {
                  subCategoryIdExist = true;
                }
              }
              this.subCategoryData.push({
                label: d.attributes.name,
                value: d.attributes.id,
              });
            });
          console.log("list data::", this.subCategoryData);

          console.log("data::", data);
          // const sub_category_id =  data.sub_category_id && (data.sub_category_id as []).length > 0 && data.sub_category_id[0] && data.sub_category_id[0].id || '';

          if (subCategoryIdExist) {
            this.setState(
              {
                subCategoryData: this.subCategoryData,
                subCategoryId: sub_category_id,
              },
              () => this.getSubSubCategoryList()
            );
          } else {
            this.setState({
              subCategoryData: this.subCategoryData,
              subCategoryId: "",
              subSubCategoryData: [],
              subSubCategoryId: "",
            });
            // }, () => this.getSubSubCategoryList());
          }
        } else if (apiRequestCallId === this.subSubCategoryListApiCallId) {
          this.subSubCategoryData = [];

          let subSubCategoryIdExist = false;

          let data: any = "";
          let sub_sub_category_id = "";
          if (Object.keys(this.state.product).length) {
            data = this.state.product.attributes;
            sub_sub_category_id =
              (data.sub_sub_category_id &&
                (data.sub_sub_category_id as []).length > 0 &&
                data.sub_sub_category_id[0] &&
                data.sub_sub_category_id[0].id) ||
              "";
          }

          responseJson.data &&
            (responseJson.data as any[]).length &&
            responseJson.data.map((d: any) => {
              if (sub_sub_category_id === d.attributes.id) {
                subSubCategoryIdExist = true;
              }
              this.subSubCategoryData.push({
                label: d.attributes.name,
                value: d.attributes.id,
              });
            });

          if (subSubCategoryIdExist) {
            this.setState({
              subSubCategoryData: this.subSubCategoryData,
              subSubCategoryId: sub_sub_category_id,
            });
          } else {
            this.setState({
              subSubCategoryData: this.subSubCategoryData,
              subSubCategoryId: "",
            });
          }
        } else if (apiRequestCallId === this.getBrandListApiCallId) {
          this.brandListData = [];

          let data: any = "";
          let brandId = "";
          if (Object.keys(this.state.product).length) {
            data = this.state.product.attributes;
            brandId = data.brand_id || "";
          }

          responseJson.data &&
            (responseJson.data as any[]).length &&
            responseJson.data.map((d: any) => {
              this.brandListData.push({
                label: d.attributes.name,
                value: d.attributes.id,
              });
            });
          this.setState(
            { brandListData: this.brandListData, brandId: brandId },
            () => console.log("brandlist:::", this.state.brandListData)
          );
        }
      }

      // api errors
      if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }

      // api handle catch response
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  handleChange = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleSellerSkuIdChange = (event: any) => {
    this.setState({
      ...this.state,
      sku: event.target.value,
      serialNumber: event.target.value + this.state.userId,
    });
  };

  handleCategoryChange = (event: any) => {
    this.productType = event.target.value;
    this.setState(
      {
        ...this.state,
        categoryId: event.target.value,
      },
      () => this.getSubCategoryList()
    );
  };

  handleSubCategoryChange = (event: any) => {
    this.setState(
      {
        ...this.state,
        subCategoryId: event.target.value,
      },
      () => this.getSubSubCategoryList()
    );
  };

  gotoProductListScreen = () => {
    console.log("back:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationInventoryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  gotoProductDetailScreen = () => {
    console.log("next:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductDetailMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
    //   id: this.state.id,
    //   isEdit: this.state.isEdit
    // });
    this.send(msg);
  };

  goToLoginScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMobilePhoneLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  // Customizable Area Start
  getProductData = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const productId = this.state.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDataApi + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoryList = async () => {
    console.log("category list api is called:::");
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategorySubCategorySubSubCategoryApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubCategoryList = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subCategoryListApiCallId = requestMessage.messageId;
    console.log("category id:::", this.productType);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategorySubCategorySubSubCategoryApi + `?category_id=${this.productType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubSubCategoryList = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subSubCategoryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategorySubCategorySubSubCategoryApi + `?sub_category_id=${this.state.subCategoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrandList = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrandListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorBrandListApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addProductBasicInfoApi = (values: ProductBasicDetailModel) => {
    const {
      serialNumber,
      categoryId,
      subCategoryId,
      subSubCategoryId,
      sku,
      brandId: brandName,
      productName,
      country,
      hsn,
      address,
      manufacture,
    } = values;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.basicInfoApiCallId = requestMessage.messageId;

    const httpBody = {
      serial_number: serialNumber,
      product_type: categoryId,
      sub_category_id: subCategoryId,
      sub_sub_category_id: subSubCategoryId,
      sku: sku,
      brand_id: brandName,
      name: productName, // product name
      country_of_origin: country,
      hsn_code: hsn,
      name_of_manufacturer: manufacture,
      address_of_manufacturer: address,
    };

    console.log("http body ------>>>", httpBody);

    if (this.state.isEdit || this.state.id) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.productEditAPiEndPoint + `/${this.state.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethodType
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addProduct
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiMethod
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadMultipleFiles = (e: any) => {
    //this.setState({file: URL.createObjectURL(e.target.files[0])});
    //   this.fileObj.push(e.target.files)
    const fileArray = [];
    const multiFileObj = [];
    multiFileObj.push(e.target.files);
    for (let i = 0; i < multiFileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(multiFileObj[0][i]));
    }
    this.setState({ ...this.state, file: fileArray });
    console.log("file", this.state.file);
  };

  deleteFile = (e: any) => {
    const s: any[] = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    console.log(s);
  };

  // Product add details end
  // Customizable Area End
}
