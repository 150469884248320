Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";

exports.loyaltyPointsAPIEndPoint = "bx_block_shopping_cart/loyalty_points";
exports.TermUseAPIEndPoint =
  "bx_block_static_pages/static_pages/terms_and_conditions";
exports.PrivacyPolicyAPIEndPoint =
  "bx_block_static_pages/static_pages/privacy_policy";
exports.ShippingPolicyAPIEndPoint =
  "bx_block_static_pages/static_pages/shipping_policy";
exports.ReturnExchangePolicyAPIEndPoint =
  "bx_block_static_pages/static_pages/return_and_exchange_policy";
// Customizable Area End
