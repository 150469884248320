import { toast } from "react-toastify";
import { Login_Message } from "../../framework/src/EnumMessage";

export const redirectToLoginOnTokenExpire = () => {
    toast.error(Login_Message.SessionExpired, { autoClose: 2000, toastId: "ToastSessionExpired" })
    localStorage.clear();
    // if token expire then after redirect to login page
    // then after successful login redirect to that page where token expire
    setTimeout(() => {
        const currentUrl = window.location.href;  // current url on which token expires

        // redirect to login page
        // here returnUrl query key is for redirecting on same url where token expire
        window.location.href = `/login?returnUrl=${window.location.pathname}`;
    }, 2000);
}

// get query string value from URL by key
export const getReturnUrlOnSuccessFullLogin = () => {
    const queryParams = new URL(window.location.href).searchParams;
    return queryParams.get('returnUrl');
}