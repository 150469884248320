import React from "react";
import { Container, withStyles, Switch } from "@material-ui/core";
import VendorSettingsController, {
  Props,
  configJSON,
} from "./VendorSettingsController.web";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "60px 0",
    "& .vendor-settings .notifications": {
      background: "white",
      padding: "20px 0",
    },
    "& .vendor-settings h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      padding: "0",
      marginBottom: "20px",
    },
    "& .vendor-settings .notifications .notification": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
};

class VendorSettings extends VendorSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div className={classes.pageOuter}>
        <Container maxWidth="md" className="vendor-settings">
          <h2>Settings</h2>
          <div className="notifications">
            <Container maxWidth="md">
              <p>
                <b>Notifications</b>
              </p>
              <div className="notification">
                <span>Push Notification</span>
                <AntSwitch
                  checked={this.state.push_notification}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        push_notification: !this.state.push_notification,
                      },
                      () => {
                        this.updateVendorSettings();
                      }
                    );
                  }}
                  name="checkedP"
                />
              </div>
              <hr />
              <div className="notification">
                <span>Email Notification</span>
                <AntSwitch
                  checked={this.state.email_notification}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        email_notification: !this.state.email_notification,
                      },
                      () => {
                        this.updateVendorSettings();
                      }
                    );
                  }}
                  name="checkedE"
                />
              </div>
              <hr />
              <div className="notification">
                <span>Whatsapp Notification</span>
                <AntSwitch
                  checked={this.state.whatsapp_notification}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        whatsapp_notification: !this.state
                          .whatsapp_notification,
                      },
                      () => {
                        this.updateVendorSettings();
                      }
                    );
                  }}
                  name="checkedW"
                />
              </div>
              <hr />
            </Container>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(styles)(VendorSettings);
