import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  callbackShopWithFriend: Function;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  push_notification: boolean;
  email_notification: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetCustomerSettingsCallId: any;
  UpdateCustomerSettingsCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      push_notification: false,
      email_notification: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    // Customizable Area Start
    // Get
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GetCustomerSettingsCallId !== null &&
      this.GetCustomerSettingsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GetCustomerSettingsCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          push_notification: responseJson.data.attributes.push_notification,
          email_notification:
            responseJson.data.attributes.email_notification,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Update
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.UpdateCustomerSettingsCallId !== null &&
      this.UpdateCustomerSettingsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.UpdateCustomerSettingsCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({
          ...this.state,
          push_notification: responseJson.data.attributes.push_notification,
          email_notification:
            responseJson.data.attributes.email_notification,
        });
      }

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
      this.getCustomerSettings();
    } catch (error) {}
  }

  // Get
  getCustomerSettings = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetCustomerSettingsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SettingAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Update
  updateCustomerSettings = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      push_notification: this.state.push_notification,
      email_notification: this.state.email_notification,
      // whatsapp_notification: false,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.UpdateCustomerSettingsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSettingAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Customizable Area End
}
