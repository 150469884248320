import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { } from "./assets";
import { returnItemModal } from "../../../framework/src/Interfaces/IProductDetail";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    callBackFunction: Function;
    hideWriteReviewModal: Function;
    returnItem: returnItemModal;
    // id: number;
    // classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string | null;
    isLoading: boolean;
    displayAddRateReviewsModal: boolean;
    rating: number;
    reviewsImages: any[];
    comment: string;
    accountId: string | number;
    productId: number;
    order_id: number;
    reviewId: number;
    hasImgaeError: boolean;
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WriteReviewModalController extends BlockComponent<
    Props,
    S,
    SS
> {

    addCustomerProductReviewApiCallId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            token: localStorage.getItem('authToken'),
            isLoading: false,
            displayAddRateReviewsModal: true,
            rating: 0,
            reviewsImages: [],
            comment: '',
            accountId: localStorage.getItem('userId') || 0,
            productId: this.props.returnItem.catalogue_id || 0,
            order_id: this.props.returnItem.orderId || 0,
            reviewId: 0,
            hasImgaeError: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        console.log("response json:::", responseJson);

        // Add customer review on product
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.addCustomerProductReviewApiCallId !== null && apiRequestCallId === this.addCustomerProductReviewApiCallId) {
            this.addCustomerProductReviewApiCallId = null;

            this.setState({ isLoading: false });
            // if (responseJson?.data?.id) {
            // redirect to listing page or specific order page
            // this.setState({ displayAddRateReviewsModal: false }, () => this.props.callBackFunction())
            // }


            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson?.data?.id) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                this.setState({ displayAddRateReviewsModal: false }, () => this.props.callBackFunction())
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Add customer review on product

        // Customizable Area End

    }

    async componentDidMount() {
        super.componentDidMount();
    }

    hideReviewModal = () => {
        this.setState({ displayAddRateReviewsModal: false, }, () => { this.props.hideWriteReviewModal() });
    }

    uploadMultipleImages = (e: any) => {
        const images = [...this.state.reviewsImages];

        const previewImages = this.state.reviewsImages;
        const newImages: Blob[] = Array.from(e.target.files);
        const imageLength = this.state.reviewsImages && this.state.reviewsImages.length;
        const totalImages = imageLength + newImages.length;
        if (totalImages > 2) {
            // if user upload more than 2 images then display error;
            this.setState({ hasImgaeError: true });
            return;
        } else {
            this.setState({ hasImgaeError: false });
        }
        for (let i = 0; i < newImages.length; i++) {
            images.push({ id: imageLength + i, url: URL.createObjectURL(newImages[i]), imageBlob: newImages[i] });
        }
        this.setState({ reviewsImages: images });
    }

    deleteImage = (id: string) => {

        let galleries = this.state.reviewsImages.filter(item => item.id !== id);

        const imagesId =
            this.state.reviewsImages &&
            this.state.reviewsImages &&
            this.state.reviewsImages.map((galleries) => { return (galleries.id) });
        if (imagesId.includes(id)) {
            // if images uploaded on api
            this.setState({ reviewsImages: galleries });
        } else {
            this.setState({ reviewsImages: galleries });
        }
    }

    addReview = () => {
        const { rating, accountId, productId, order_id, comment, reviewsImages, hasImgaeError } = this.state;

        if (hasImgaeError) {
            return;
        }
        const header = {
            'Accept': 'application/json',
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addCustomerProductReviewApiCallId = requestMessage.messageId;

        let formdata = new FormData();

        const images =
            reviewsImages &&
            reviewsImages.length &&
            reviewsImages.map((data: any, index) =>
                formdata.append(`images[]`, data.imageBlob as Blob, (data.imageBlob as any).name)
            );
        formdata.append('rating', rating.toString());
        formdata.append('catalogue_id', productId.toString());
        formdata.append('comment', comment);
        formdata.append('account_id', accountId.toString());
        formdata.append('order_id', order_id.toString());

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.customerReviewApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}