import React from "react";
import { Grid, Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const noProductImage = require("../assets/product.png");

import BlogsController from "./BlogsController.web";
import Loader from "../../../components/src/Loader.web";

const styles: any = {
  blogs: {
    padding: "20px 0",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      height: "270px",
      width: "400px",
      maxHeight: "267px",
      objectFit: "fill",
      cursor: "pointer",
      "@media(max-width:599px)": {
        maxHeight: "250px",
      },
    },
    "& .col-name": {
      color: "#444",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "'Roboto',sans-serif",
      marginTop: "10px",
    },
    "& .btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
    },
    "& .outline-btn": {
      minWidth: "200px",
    },
  },
};

export class Blogs extends BlogsController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <div className={classes.blogs}>
          <Container maxWidth="lg">
            <h2 className="title-block">Blogs</h2>
            <Grid container spacing={2}>
              {this.state.blogData.map((blog: any) => {
                return (
                  <Grid item xs={12} sm={4}>
                    <div
                      className="img-block mt-3"
                      onClick={() => {
                        window.open(
                          `${window.location.protocol}//${
                            window.location.host
                          }/BlogPage/${blog.id}`,
                          "_self"
                        );
                      }}
                    >
                      {blog.attributes.image ? (
                        <img src={blog.attributes.image.url} />
                      ) : (
                        <img src={noProductImage} />
                      )}
                    </div>
                    <div className="col-name">
                      <span>{blog.attributes.title}</span>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(Blogs);
