import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getTimeZone } from "react-native-localize";
// import AsyncStorage from '@react-native-async-storage/async-storage';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
classes?:any;
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    p_name: string | null;
    p_length: string | null;
    p_width: string | null;
    p_height: string | null;
    data: any;
    Eaddress: any;
    addressID: string | number | null;
    Checked: number;
    popup: boolean;
    navigate: boolean;
    status: string;
    token: string | null;
    addressEditData: any;
    is_defulat: boolean;
    visible: boolean;
    name_error:string;
    length_error:string;
    width_error:string;
    height_error:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditPackageController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiPhoneLoginCallId: any;
    packageCallId: any;
    DelPackageCallId: any;
    addNewPackageCallId: any;
    EditPackageCallId: any;
    DefaultPackageCallId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            // p_name: this.props.navigation.state.params.name,
            // p_length: this.props.navigation.state.params.length.toString(),
            // p_width: this.props.navigation.state.params.width.toString(),
            // p_height: this.props.navigation.state.params.height.toString(),
            // addressID: this.props.navigation.state.params.id,
            p_name: "",
            p_length: "",
            p_width: "",
            p_height: "",
            addressID: "",
            data: [],
            Eaddress: [],
            Checked: 0,
            popup: false,
            navigate: false,
            status: '',
            token: '',
            addressEditData: {},
            is_defulat: false,
            visible: false,
            name_error:"",
            length_error:"",
            width_error:"",
            height_error:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        // Customizable Area Start

        if (getName(MessageEnum.RestAPIRequestMessage) === message.id && this.EditPackageCallId !== null) {

            this.EditPackageCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.state.p_name && this.state.p_length && this.state.p_width && this.state.p_height) {
                this.props.navigation.navigate('PackagingWeb');
            }

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);


        }

        // Customizable Area End

    }

    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible
    };

    btnExampleProps = {
        onPress: () => this.doButtonPressed()
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // Customizable Area Start

    async componentDidMount() {
        // token
        console.log('----->> token');
        try {
            // let Token = await AsyncStorage.getItem('Token');
            // console.log('Token--------->', Token);
            // this.setState({ token: Token })
            const  localToken = await localStorage.getItem("authToken")
            console.log(localToken,"tokentoken")
                this.setState({token:localToken})
            console.log('token new--------->', this.state.token);
         
            
      const   length =  await localStorage.getItem("lenght")
      const   width =  await localStorage.getItem("width")
      const  height =  await localStorage.getItem("height")
      const   name =  await localStorage.getItem("name")
      const id = await localStorage.getItem("id")
            this.setState({p_length:length})
            this.setState({p_width:width})
            this.setState({p_height:height})
            this.setState({p_name:name})
            this.setState({addressID:id})
            console.log(this.state.p_length,"lengthghjkl")
            console.log(this.state.addressID,"addressid")
        //      localStorage.removeItem("lenght")
        //    localStorage.removeItem("width")
        //     localStorage.gremoveItem("height")
        //      localStorage.removeItem("name")
        // localStorage.removeItem("id")
         
        } catch (e) {
            // error reading value
        }

        console.log('======>>>>>>>>>', this.state.p_length);


    }


    onEdithPackage() {
        if(!this.state.p_name ||!this.state.p_length || !this.state.p_width || !this.state.p_height ) {
            if (!this.state.p_name) {      // alert('Package Name Field is Empty');
                    this.setState({name_error:"Package Name Field is Empty"})
                }else{ this.setState({name_error:""}) }
                 if (!this.state.p_length) {
                    // alert('Package Length Field is Empty');
                    this.setState({length_error:"Package Length Field is Empty"})
                }else{ this.setState({length_error:""}) }
                 if (!this.state.p_width) {
                    // alert('Package Width Fieldis Empty');
                    this.setState({width_error:"Package Width Field is Empty"}) 
                } else{ this.setState({width_error:""}) }
                 if (!this.state.p_height) {
                    // alert('Package Height Field is Empty');
                    this.setState({height_error:"Package Height Field is Empty"})
                }else{this.setState({height_error:""})}  }
else {



    const header = {
        "Content-Type": configJSON.EditpackageApiContentType,
        token: this.state.token
    };

    const httpBody = {
        package_name: this.state.p_name,
        package_length: this.state.p_length,
        package_width: this.state.p_width,
        package_height: this.state.p_height
    };

    console.log("===========>resss", httpBody)

    const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EditPackageCallId = apiRequest.messageId;

    apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.EditpackageAPiEndPoint + `/${this.state.addressID}`
    );

    apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.EditpackageApiMethodType);

    runEngine.sendMessage(apiRequest.id, apiRequest);
this.props.navigation.navigate("PackagingWeb")

    return true;



}








//         const header = {
//             "Content-Type": configJSON.EditpackageApiContentType,
//             token: this.state.token
//         };

//         const httpBody = {
//             package_name: this.state.p_name,
//             package_length: this.state.p_length,
//             package_width: this.state.p_width,
//             package_height: this.state.p_height
//         };

//         console.log("===========>resss", httpBody)

//         const apiRequest = new Message(
//             getName(MessageEnum.RestAPIRequestMessage)
//         );

//         this.EditPackageCallId = apiRequest.messageId;

//         apiRequest.addData(
//             getName(MessageEnum.RestAPIResponceEndPointMessage),
//             configJSON.EditpackageAPiEndPoint + `/${this.state.addressID}`
//         );

//         apiRequest.addData(
//             getName(MessageEnum.RestAPIRequestHeaderMessage),
//             JSON.stringify(header)
//         );

//         apiRequest.addData(
//             getName(MessageEnum.RestAPIRequestBodyMessage),
//             JSON.stringify(httpBody)
//         );

//         apiRequest.addData(
//             getName(MessageEnum.RestAPIRequestMethodMessage),
//             configJSON.EditpackageApiMethodType);

//         runEngine.sendMessage(apiRequest.id, apiRequest);
// this.props.navigation.navigate("PackagingWeb")

//         return true;
    
    }


    // Customizable Area End

}
