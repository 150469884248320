import moment from "moment";
import { saveAs } from "file-saver";
import fileDownload from 'js-file-download'
// @ts-ignore
// const moment = extendMoment(originalMoment);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { } from "./assets";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";
import { paginationCount } from "../../../framework/src/Constants";
import { toast } from "react-toastify";
import { Order_Message } from "../../../framework/src/EnumMessage";
import { DateRange, OrderModel } from "../../../framework/src/Interfaces/IOrderDetail";
import { scrollToTopOfElement } from "../../../framework/src/commonFunction";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string | null;
    isLoading: boolean;
    urlTabParam: string;
    tabIndex: number;
    tabName: string;
    cancelOrder: boolean;
    cancelOrderReason: boolean;
    confirmOrder: boolean;
    orderSchedule: boolean;
    showOrderDetailModal: boolean;
    orderList: [];
    searchInput: string;
    dayWiseOrder: any;
    startDate: Date | null;
    endDate: Date | null;
    noDataFound: boolean;
    filterData: any[];
    selectedOrderNumber: string;
    selectedOrderId: number | null;
    status: string;
    actionType: string;

    // unshipped tab state
    cancelReasonId: number | null;
    cancelReasonList: any[];
    confirmOrderCheckedId: number | null;
    cancelReasonDescription: string;
    isOpenDateRangePicker: boolean;
    dateRange: DateRange | any;

    // ready to ship tab
    readyToShipSelectedOrderIds: number[];
    shipmentIds: number[];
    confirmOrderShipRocketIds: number[];
    selectedOrder: OrderModel;

    totalPage: number;
    currentPage: number;
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class VendorOrderController extends BlockComponent<
    Props,
    S,
    SS
> {

    elementRef: React.RefObject<HTMLDivElement>;

    getvendorOrderListApiCallId: any;

    getReceivedOrderListApiCallId: any;
    getUnshippedOrderListApiCallId: any;
    getReadyToShipOrderListApiCallId: any;
    getShippedOrderListApiCallId: any;
    getCancelledOrderListApiCallId: any;
    getSearchOrderListApiCallId: any;

    getFilterByStartAndEndDateApiCallId: any;
    getCancelReasonListAPICallId: any;
    cancelReasonAPICallId: any;
    onCancelOrderIdAPICallId: any;
    updateOrderStatusListApiCallId: any;

    onConfirmShipmentAPICallId: any;
    printInvoiceAPICallId: any;
    printShippingLableAPICallId: any;
    printManifestAPICall: any;

    VendorOrderTabEnum = {
        RECEIVED: 'received',
        SHIPPED: 'shipped',
        READY_TO_SHIP: 'ready-to-ship',
        SENT: 'sent',
        CANCELLED: 'cancelled'
    }

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: localStorage.getItem('authToken'),
            isLoading: false,
            urlTabParam: this.props.navigation.getParam("tab") || this.VendorOrderTabEnum.RECEIVED,
            dayWiseOrder: '',
            tabIndex: 0,
            tabName: ORDER_STATUS_TYPES.PLACED,
            actionType: '',
            confirmOrder: false,
            cancelOrder: false,
            cancelOrderReason: false,
            orderSchedule: false,
            showOrderDetailModal: false,
            searchInput: '',
            startDate: null,
            endDate: null,
            orderList: [],
            noDataFound: false,
            filterData: [
                {
                    title: 'Today',
                    value: 'today'
                },
                {
                    title: 'Last Week',
                    value: 'last_week'
                },
                {
                    title: 'Last Month',
                    value: 'last_month'
                },
                {
                    title: 'Last Year',
                    value: 'last_year'
                }
            ],
            selectedOrderNumber: '',
            selectedOrderId: null,
            status: '',
            cancelReasonId: null,
            cancelReasonList: [],
            confirmOrderCheckedId: null,
            cancelReasonDescription: '',
            isOpenDateRangePicker: false,
            // dateRange: moment.range(moment().clone().subtract(7, "days"), moment().clone())
            dateRange: [{
                startDate: new Date(),
                endDate: new Date(),
                key: "rollup"
            }],
            readyToShipSelectedOrderIds: [],
            shipmentIds: [],
            confirmOrderShipRocketIds: [],
            selectedOrder: {} as OrderModel,
            totalPage: 0,
            currentPage: 1
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        console.log('@@@ API MESSAGE SIGNUP =================', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            console.log('responseJson', responseJson)
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            )

            if (responseJson && !responseJson.errors) {
                console.log('success response', responseJson)
                if (apiRequestCallId === this.getvendorOrderListApiCallId) {
                    this.vendorOrderListSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getReceivedOrderListApiCallId) {
                    this.receivedOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getUnshippedOrderListApiCallId) {
                    this.unshippedOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getReadyToShipOrderListApiCallId) {
                    this.readyToShipOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getShippedOrderListApiCallId) {
                    this.sendOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getCancelledOrderListApiCallId) {
                    this.cancelledOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getSearchOrderListApiCallId) {
                    this.searchOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.updateOrderStatusListApiCallId) {
                    this.updateOrderSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getCancelReasonListAPICallId) {
                    this.getCancelReasonListSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.getFilterByStartAndEndDateApiCallId) {
                    this.vendorOrderListSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.cancelReasonAPICallId) {
                    this.cancelReasonSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.onCancelOrderIdAPICallId) {
                    this.onCancelReasonSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.onConfirmShipmentAPICallId) {
                    this.onConfirmShipmentSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.printInvoiceAPICallId) {
                    this.onPrintInvoiceSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.printShippingLableAPICallId) {
                    this.onPrintShippingLableSuccessCallBack(responseJson)
                } else if (apiRequestCallId === this.printManifestAPICall) {
                    this.onPrintManifestSuccessCallBack(responseJson)
                }
            } else if (responseJson && responseJson.errors) {
                console.log('errors:::', responseJson)

                // handle error respose
                this.parseApiErrorResponse(responseJson);
                if (apiRequestCallId === this.getvendorOrderListApiCallId) {
                    this.vendorOrderListFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getReceivedOrderListApiCallId) {
                    this.receivedOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getUnshippedOrderListApiCallId) {
                    this.unshippedOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getReadyToShipOrderListApiCallId) {
                    this.readyToShipOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getShippedOrderListApiCallId) {
                    this.sendOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getCancelledOrderListApiCallId) {
                    this.cancelledOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getSearchOrderListApiCallId) {
                    this.searchOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.updateOrderStatusListApiCallId) {
                    this.updateOrderFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getCancelReasonListAPICallId) {
                    this.getCancelReasonListFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.getFilterByStartAndEndDateApiCallId) {
                    this.vendorOrderListFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.cancelReasonAPICallId) {
                    this.cancelReasonFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.onCancelOrderIdAPICallId) {
                    this.onCancelReasonFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.onConfirmShipmentAPICallId) {
                    this.onConfirmShipmentFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.printInvoiceAPICallId) {
                    this.onFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.printShippingLableAPICallId) {
                    this.onFailureCallBack(responseJson)
                } else if (apiRequestCallId === this.printManifestAPICall) {
                    this.onFailureCallBack(responseJson)
                }
            } else if (errorReponse) {
                this.setState({ isLoading: false })
                console.log('errorReponse', errorReponse)
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }


    async componentDidMount() {
        super.componentDidMount();
        this.getVendorOrders(this.state.urlTabParam);
    }

    navigateToVendorTab = (order_status: string, tab: string) => {
        const isfromSameTab = this.state.tabName === order_status ? true : false;
        this.setState((prevState, props) => ({
            tabName: order_status,
            urlTabParam: tab,
            // on tab changes make search filter to empty
            searchInput: prevState.tabName === order_status ? prevState.searchInput : '',
            dayWiseOrder: prevState.tabName === order_status ? prevState.dayWiseOrder : '',
            dateRange: prevState.tabName === order_status ? prevState.dateRange : [{
                startDate: new Date(),
                endDate: new Date(),
                key: "rollup"
            }],
            currentPage: 1, // on tab change page start from intial count,
            readyToShipSelectedOrderIds: prevState.tabName === order_status ? prevState.readyToShipSelectedOrderIds : [],
            shipmentIds: prevState.tabName === order_status ? prevState.shipmentIds : [],
            confirmOrderShipRocketIds: prevState.tabName === order_status ? prevState.confirmOrderShipRocketIds : []
        }),
            () => {
                // change url based on vendor tab
                this.props.navigation.navigate("vendorOrder", { tab: tab });
                this.getVendorOrderListByStatusAndFilter(order_status, isfromSameTab);
            }
        )
    }

    getVendorOrders = (tabName: string) => {
        let tabIndex = 0, status = ORDER_STATUS_TYPES.PLACED;
        if (tabName === this.VendorOrderTabEnum.RECEIVED) {
            tabIndex = 0;
            status = ORDER_STATUS_TYPES.PLACED;
        } else if (tabName === this.VendorOrderTabEnum.SHIPPED) {
            tabIndex = 1;
            status = ORDER_STATUS_TYPES.CONFIRMED;
        } else if (tabName === this.VendorOrderTabEnum.READY_TO_SHIP) {
            tabIndex = 2;
            status = ORDER_STATUS_TYPES.READY_TO_SHIP;
        } else if (tabName === this.VendorOrderTabEnum.SENT) {
            tabIndex = 3;
            status = ORDER_STATUS_TYPES.SHIPPED;
        } else if (tabName === this.VendorOrderTabEnum.CANCELLED) {
            tabIndex = 4;
            status = ORDER_STATUS_TYPES.CANCELLED;
        }

        this.setState({ tabIndex: tabIndex, tabName: status, urlTabParam: tabName });
        this.getVendorOrderListByStatusAndFilter(status);
    }

    handleChange = (event: any, value: any) => {
        this.setState({ tabIndex: value });
    };

    handleSearchInput = async (value: string) => {
        this.setState({ searchInput: value }, () => this.getSearchOrderList());
    }

    // api calling strcture
    apiCall = async (data: any) => {
        console.log('@@@@ api data ======', data)
        const { contentType, method, endPoint, body, type } = data
        const header = {
            'Content-Type': contentType,
            token: this.state.token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        console.log('@@@ requestMessage ========', requestMessage)
        return requestMessage.messageId;
    }

    // response handling start
    vendorOrderListSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson.data) {
            let orderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({
                orderList: orderList,
                totalPage: responseJson?.meta?.total_pages,
                noDataFound: responseJson.data.length > 0 ? false : true,
                isLoading: false
            })
        }
        else {
            this.setState({ orderList: [], totalPage: 0, noDataFound: true, isLoading: false })
        }
    };
    vendorOrderListFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    receivedOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson.data) {
            let orderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: orderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        }
        else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    receivedOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    unshippedOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ unshipped Order success callBack =================', responseJson)
        if (!responseJson.message) {
            let unshippedOrderList = responseJson && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: unshippedOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        } else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    unshippedOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ unshipped Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    readyToShipOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ readyToShip Order success callBack =================', responseJson)
        if (!responseJson.message) {
            let readyToShipOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: readyToShipOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        } else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    readyToShipOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ readyToShip Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    sendOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ send Order success callBack =================', responseJson)
        if (!responseJson.message) {
            let sendOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: sendOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        } else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    sendOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ send Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    cancelledOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ cancelled Order success callBack =================', responseJson)
        if (!responseJson.message) {
            // toast.success(Order_Message.Cancelled, { position: "top-right", autoClose: 5000 })
            let cancelOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: cancelOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        } else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    cancelledOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ cancelled Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })

    };

    searchOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ search Order success callBack =================', responseJson)
        if (responseJson.data) {
            let searchedOrderList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ orderList: searchedOrderList, noDataFound: responseJson.data.length > 0 ? false : true, isLoading: false })
        }
        else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    searchOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ search Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    updateOrderSuccessCallBack = (responseJson: any) => {
        console.log('@@@ update status Order success callBack =================', responseJson)
        if (responseJson.data) {
            // display toaster of cancelled or confirm Order
            const type = this.state.tabName === ORDER_STATUS_TYPES.PLACED ? 'Confirm' :
                this.state.tabName === ORDER_STATUS_TYPES.CONFIRMED ? 'SchedulePickup' :
                    this.state.tabName === ORDER_STATUS_TYPES.READY_TO_SHIP ? 'Shipped' :
                        this.state.tabName === ORDER_STATUS_TYPES.SHIPPED ? 'Shipped' :
                            this.state.tabName === ORDER_STATUS_TYPES.CANCELLED ? 'Cancelled' : '';
            toast.success(`Order ${type} Successfully`, { position: "top-right", autoClose: 5000 })
            // call receivedOrderList api again
            this.getVendorOrderListByStatusAndFilter(this.state.tabName);
        }
        else {
            this.setState({ orderList: [], noDataFound: true, isLoading: false })
        }
    };
    updateOrderFailureCallBack = (responseJson: any) => {
        console.log('@@@ update status Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    getCancelReasonListSuccessCallBack = (responseJson: any) => {
        console.log('@@@ received Order success callBack =================', responseJson)
        if (responseJson.data) {
            let reasonList = responseJson.data && responseJson.data.length > 0 ? responseJson.data : []
            this.setState({ cancelReasonList: reasonList, isLoading: false })
        }
        else {
            this.setState({ cancelReasonList: [], isLoading: false })
        }
    };
    getCancelReasonListFailureCallBack = (responseJson: any) => {
        console.log('@@@ received Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    cancelReasonSuccessCallBack = (responseJson: any) => {
        console.log('@@@ cancel Order success callBack =================', responseJson)
        if (responseJson?.data?.id) {
            this.onCancelOrder();
            // final cancel order call            
            // this.setState({ cancelReasonList: reasonList, isLoading: false })
        }
        else {
            this.setState({ isLoading: false })
        }
    };
    cancelReasonFailureCallBack = (responseJson: any) => {
        console.log('@@@ cancel Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    onCancelReasonSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on cancel Order success callBack =================', responseJson)
        if (responseJson?.data) {
            toast.success(Order_Message.Cancelled, { position: "top-right", autoClose: 5000 })
            this.getSearchOrderList();
            this.setState({ isLoading: false, selectedOrderId : null, confirmOrderCheckedId: null })
        }
        else {
            this.setState({ isLoading: false })
        }
    };
    onCancelReasonFailureCallBack = (responseJson: any) => {
        console.log('@@@ on cancel Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    onConfirmShipmentSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on confirm Order success callBack =================', responseJson)
        if (responseJson && responseJson.data) {
            toast.success(Order_Message.Shipped, { position: "top-right", autoClose: 5000 })
            // toast message orders is confirmed successfully
            this.setState({ isLoading: false }, () => this.getSearchOrderList());
        }
        else {
            this.setState({ isLoading: false })
        }
    };
    onConfirmShipmentFailureCallBack = (responseJson: any) => {
        console.log('@@@ on confirm Order failure callBack =================', responseJson)
        this.setState({ isLoading: false })
    };

    onPrintInvoiceSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on confirm Order success callBack =================', responseJson)
        if (responseJson?.url) {
            // download print from url
            // this.setState({ isLoading: false }, () => {
            //     const urls = responseJson?.url;
            //     for (let i = 0; i < urls.length; i++) {
            //         saveAs(
            //             baseUrl + urls[i],
            //             "invoice.pdf"
            //         );
            //     }
            // });
            this.setState({ isLoading: false }, () => {
                const urls = responseJson?.url;
                for (let i = 0; i < urls.length; i++) {
                    var link = document.createElement('a');
                    link.href = urls[i];
                    link.download = 'file.pdf';
                    link.target = '_blank';
                    link.click();
                }
            });
        }
        else {
            this.setState({ isLoading: false })
        }
    };

    onPrintShippingLableSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on print shiping label success callBack =================', responseJson)
        if (responseJson?.label_url) {
            // download print from url
            this.setState({ isLoading: false }, () => {
                window.open(responseJson?.label_url, '_blank')
            });
        }
        else {
            this.setState({ isLoading: false })
        }
    };

    onPrintManifestSuccessCallBack = (responseJson: any) => {
        console.log('@@@ on print shiping label success callBack =================', responseJson)
        if (responseJson?.url) {
            // download print from url
            this.setState({ isLoading: false }, () => {
                const urls = responseJson?.url;
                for (let i = 0; i < urls.length; i++) {
                    var link = document.createElement('a');
                    link.onclick = () => { window.open(urls[i]); }
                    link.target = '_blank'
                    link.dispatchEvent(new MouseEvent('click'));
                }
            });
        }
        else {
            this.setState({ isLoading: false })
        }
    };

    onFailureCallBack = (responseJson: any) => {
        console.log('@@@ on  =================', responseJson)
        this.setState({ isLoading: false })
    };

    // response handling end

    // api calling start
    getSearchOrderList = async () => {
        const orderStatus = this.state.tabName;
        this.setState({ isLoading: true, orderList: [] });
        this.getvendorOrderListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.searchOrderApiEndPoint}?search=${this.state.searchInput}&status=${orderStatus}&requested_on=${this.state.dayWiseOrder}&page=${this.state.currentPage}&per_page=${paginationCount}`,
        });
    };

    getOrderFilterByStartAndEndDate = async () => {
        const orderStatus = this.state.tabName;
        const startDate = moment(this.state.dateRange[0].startDate).format('DD/MM/YYYY');
        const endDate = moment(this.state.dateRange[0].endDate).format('DD/MM/YYYY');
        this.setState({ isLoading: true, orderList: [] });
        this.getvendorOrderListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.searchOrderApiEndPoint}?search=${this.state.searchInput}&status=${orderStatus}&requested_on=${this.state.dayWiseOrder}&from_date=${startDate}&to_date=${endDate}&page=${this.state.currentPage}&per_page=${paginationCount}`,
        });
    };

    getFilterDataList = async (value: string) => {
        this.setState({ isLoading: true, orderList: [] });
        const orderStatus = this.state.tabName;
        const isFromSameTab = true;
        if (orderStatus === ORDER_STATUS_TYPES.PLACED) {
            this.getVendorOrderListByStatusAndFilter(ORDER_STATUS_TYPES.PLACED, isFromSameTab);
        } else if (orderStatus === ORDER_STATUS_TYPES.CONFIRMED) {
            this.getVendorOrderListByStatusAndFilter(ORDER_STATUS_TYPES.CONFIRMED, isFromSameTab);
        } else if (orderStatus === ORDER_STATUS_TYPES.READY_TO_SHIP) {
            this.getVendorOrderListByStatusAndFilter(ORDER_STATUS_TYPES.READY_TO_SHIP, isFromSameTab);
        } else if (orderStatus === ORDER_STATUS_TYPES.SHIPPED) {
            this.getVendorOrderListByStatusAndFilter(ORDER_STATUS_TYPES.SHIPPED, isFromSameTab);
        } else if (orderStatus === ORDER_STATUS_TYPES.CANCELLED) {
            this.getVendorOrderListByStatusAndFilter(ORDER_STATUS_TYPES.CANCELLED, isFromSameTab);
        }
    };

    getVendorOrderListByStatusAndFilter = async (status: string, isFromSameTab?: boolean) => {
        // ordersByStatusApiEndPoint
        this.setState((prevState) => ({
            isLoading: true,
            tabName: status,
            dayWiseOrder: isFromSameTab ? prevState.dayWiseOrder : '',
            searchInput: isFromSameTab ? prevState.searchInput : '',
            confirmOrderCheckedId: isFromSameTab ? prevState.confirmOrderCheckedId : null,
            readyToShipSelectedOrderIds: isFromSameTab ? prevState.readyToShipSelectedOrderIds : [],
            orderList: []
        }));

        this.getvendorOrderListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.searchOrderApiEndPoint}?status=${status}&requested_on=${this.state.dayWiseOrder}&search=${this.state.searchInput}&page=${this.state.currentPage}&per_page=${paginationCount}`
        });
    };

    getVendorOrderByPageNumber = async () => {
        this.getvendorOrderListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.searchOrderApiEndPoint}?status=${this.state.tabName}&requested_on=${this.state.dayWiseOrder}&search=${this.state.searchInput}&page=${this.state.currentPage}&per_page=${paginationCount}`,
        });
    }

    handlePageChange = async (currentPage: number) => {
        this.setState({
            currentPage: currentPage
        }, () => {
            this.getVendorOrderByPageNumber()
            scrollToTopOfElement(this.elementRef);
        })
    }

    updateOrderStatus = async () => {
        const { actionType, selectedOrderId, } = this.state;

        this.setState({ isLoading: true });
        this.updateOrderStatusListApiCallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpPutMethod,
            endPoint: `${configJSON.updateOrderApiEndPoint}?order[order_id]=${selectedOrderId}&order[status]=${actionType}`,
        });
    };

    getCancelReasonList = async () => {

        this.setState({ isLoading: true });
        this.getCancelReasonListAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpPatchMethod,
            endPoint: configJSON.cancelReasonApiEndPoint
        });
    }

    cancelReasonApi = async () => {

        this.setState({ isLoading: true })
        this.cancelReasonAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpPatchMethod,
            endPoint: configJSON.cancelReasonApiEndPoint + `?other=${this.state.cancelReasonDescription}&table_reason_to_cancles_id=${Number(this.state.cancelReasonId)}&ship_rocket_order_id=${Number(this.state.selectedOrderId)}`
        });
    }

    onCancelOrder = async () => {
        this.setState({ isLoading: true })
        this.onCancelOrderIdAPICallId = await this.apiCall({
            contentType: configJSON.contentType,
            method: configJSON.httpPutMethod,
            endPoint: `${configJSON.updateOrderApiEndPoint}?order[status]=${ORDER_STATUS_TYPES.CANCELLED}&order[order_id]=${this.state.selectedOrderId}`,
        });
    }

    handleCheckBox = async (orderIdsForInvoice: number, shipementId: number, confirmOrderShipRocketId: number) => {
        console.log("orderId:::", orderIdsForInvoice);
        const isOrderIdPresent = this.state.readyToShipSelectedOrderIds.find((selectedOrderId) => selectedOrderId === orderIdsForInvoice);
        console.log("isOrderIdPresent:::", isOrderIdPresent);

        let orderIds: number[] = [];  // order id for print invoice and and print manifest
        let shipmentIds: number[] = [];  // shippement number for print shipping label 
        let confirmOrderShipRocketIds: number[] = []; // ship rocket id for confirm shipement api
        if (isOrderIdPresent) {
            // isOrderIdPresent is present then remove from array
            orderIds = this.state.readyToShipSelectedOrderIds.filter(selectedOrderId => selectedOrderId !== orderIdsForInvoice);
            shipmentIds = this.state.shipmentIds.filter(shippmentId => shippmentId !== shipementId);
            confirmOrderShipRocketIds = this.state.confirmOrderShipRocketIds.filter(shiprocketId => shiprocketId !== confirmOrderShipRocketId);
        } else {
            // isOrderIdPresent is not present then add to array
            orderIds = [...this.state.readyToShipSelectedOrderIds, orderIdsForInvoice];
            shipmentIds = [...this.state.shipmentIds, shipementId];
            confirmOrderShipRocketIds = [...this.state.confirmOrderShipRocketIds, confirmOrderShipRocketId];
        }
        console.log("orderIds:::", orderIds);
        this.setState({ readyToShipSelectedOrderIds: orderIds, shipmentIds: shipmentIds, confirmOrderShipRocketIds: confirmOrderShipRocketIds })
    }

    onConfirmShipment = async () => {
        this.setState({ isLoading: true })
        if (this.state.confirmOrderShipRocketIds && this.state.confirmOrderShipRocketIds.length) {
            this.onConfirmShipmentAPICallId = await this.apiCall({
                contentType: configJSON.contentType,
                method: configJSON.httpPutMethod,
                endPoint: `${configJSON.updateOrderApiEndPoint}?order[status]=shipped&order[order_id]=${this.state.confirmOrderShipRocketIds}`,
            });
        }
    }

    onPrintInvoice = async () => {
        console.log("print invoice")
        console.log("this.state.selectedOrderId", this.state.confirmOrderCheckedId)
        if (this.state.confirmOrderCheckedId) {
            // for single selection in Schedule pickup invoice
            this.setState({ isLoading: true })
            const httpBody = {
                id: `${this.state.confirmOrderCheckedId}`
            };
            this.printInvoiceAPICallId = await this.apiCall({
                contentType: configJSON.contentType,
                method: configJSON.httpPostMethod,
                endPoint: `${configJSON.printInvoiceApiEndPoint}`,
                body: httpBody
            });
        } else if (this.state.readyToShipSelectedOrderIds && this.state.readyToShipSelectedOrderIds.length) {
            // for mutipe selection in ready to ship invoice
            this.setState({ isLoading: true })
            const httpBody = {
                id: this.state.readyToShipSelectedOrderIds
            };
            this.printInvoiceAPICallId = await this.apiCall({
                contentType: configJSON.contentType,
                method: configJSON.httpPostMethod,
                endPoint: `${configJSON.printInvoiceApiEndPoint}`,
                body: httpBody
            });
        } else {
            return;
        }
    }

    onPrintShippingLable = async () => {
        if (this.state.shipmentIds && this.state.shipmentIds.length) {
            this.setState({ isLoading: true });
            const httpBody = {
                // shipment_id: this.state.shipmentIds
                // 167399108
                shipment_id: this.state.shipmentIds
            };
            this.printShippingLableAPICallId = await this.apiCall({
                contentType: configJSON.contentType,
                method: configJSON.httpPostMethod,
                endPoint: `${configJSON.printShippingLableApiEndPoint}`,
                body: httpBody
            });
        } else {
            return;
        }
    }

    onPrintManifest = async () => {
        if (this.state.readyToShipSelectedOrderIds && this.state.readyToShipSelectedOrderIds.length) {
            this.setState({ isLoading: true });
            const httpBody = {
                id: this.state.readyToShipSelectedOrderIds
            };
            this.printManifestAPICall = await this.apiCall({
                contentType: configJSON.contentType,
                method: configJSON.httpPostMethod,
                endPoint: configJSON.printmanifiestApiEndPoint,
                body: httpBody
            });
        } else {
            return;
        }
    }
}
