import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { array } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string | null;
  languageList: any;
  data: string;
  visibleSide: boolean;
  columnArray: any;
  rowArray: any;
  title: string;
  proCategory: string;
  proSubCategory: string;
  measurement: string;
  rowAdd: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      visibleSide: false,
      languageList: [
        "Facebook", "Twitter", "Instagram"
      ],
      data: '',
      columnArray: [{ id: 1, value: '', row:[{id: 1, value: ''}] }],
      rowArray: [1],
      title: '',
      proCategory: '',
      proSubCategory: '',
      measurement: '',
      rowAdd: [1]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    //console.log('----->> token');
    try {
      let Token = await AsyncStorage.getItem('Token');
      //console.log('Token--------->', Token);
      this.setState({ token: Token })
    } catch (e) {
      // error reading value
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  hideMenu = () => this.setState({ visibleSide: false });

  showMenu = () => this.setState({ visibleSide: true });

 


  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------


  addColumnInput = (e: any, index: any) => {  // column Input

    let obj = this.state.columnArray[index]
    obj.value = e.nativeEvent.text

  }

  onAddColumnView = () => {   // colum button method
  
    var cArray = this.state.columnArray
    cArray.push( {  id: cArray.length + 1, value: '', row:[{id: 1, value: ''}]} )

    this.setState({ columnArray: cArray }, () => {
      //console.log('columnArray  -->>', this.state.columnArray)
    })

    var rArray = this.state.rowArray
    rArray.push(rArray.length + 1)

    this.setState({ rowArray: rArray }, () => {
      //console.log('rowArray  -->>', this.state.rowArray)
    })

  }


  onRowEndInput = (e: any, baseIndex: any, index:any) => {  // Row Input
  
   // this.state.columnArray[index].row.push({ id: (baseIndex + 1), value: e.nativeEvent.text })

   this.state.columnArray[index].row[baseIndex] = { id: (baseIndex + 1), value: e.nativeEvent.text }

    this.state.columnArray.map((item:any) => {
    });
  }

  onAddAllRowView = () => { // Row button method

    var array = this.state.rowAdd
    array.push( this.state.rowAdd.length + 1)

    this.setState({ rowAdd: array }, () => {
     // console.log('rowAdd arr', this.state.rowAdd)
    })
  }

 // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------



  //   async componentDidMount() {
  //     // token
  //     console.log('----->> token');
  //     try {
  //         let Token = await AsyncStorage.getItem('Token');
  //         console.log('Token--------->', Token);
  //         this.setState({ token: Token })
  //         console.log('token new--------->', this.state.token);
  //     } catch (e) {
  //         // error reading value
  //     }
  //     // setTimeout(() => {
  //     //     this.packageCall();
  //     // }, 1000)
  //     // const willFocusSubscription = this.props.navigation.addListener(
  //     //     'willFocus',
  //     //     () => {
  //     //         setTimeout(() => {
  //     //             this.packageCall();
  //     //         }, 1100)
  //     //     }
  //     // );
  // }
  // Customizable Area End
}
