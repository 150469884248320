import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import querystring from "query-string";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface helpCenter {
  attributes: types;
  id: string;
  type: string;
}

interface types {
  types: string;
}

interface questionAns {
  attributes: Ans;
  id: string;
  type: string;
}

interface Ans {
  answer: string;
  help_center_id: number;
  question: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean;
  value: number;
  about_us: string;
  affiliates: string;
  seller_registration: string;
  helpCenter: Array<helpCenter>;

  helpCenterStep: number;
  mainQuestion: string;
  questionAns: Array<questionAns>;

  question: string;
  answer: string;
  about_img: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorHelpCenterControllers extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  AboutUsDataCallId: any;
  HelpCenterDataCallId: any;
  QuestionDataCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      value: 0,
      about_us: "",
      affiliates: "",
      seller_registration: "",
      helpCenter: [],
      loading: true,
      helpCenterStep: 1,
      mainQuestion: "",
      questionAns: [],
      question: "",
      answer: "",
      about_img: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );
    //   this.setState({ txtSavedValue: value });
    // }

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.AboutUsDataCallId !== null &&
      this.AboutUsDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AboutUsDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        about_us: responseJson.data[0].attributes.about_us,
        affiliates: responseJson.data[0].attributes.affiliates,
        seller_registration:
          responseJson.data[0].attributes.seller_registration,
        about_img: responseJson.data[0].attributes.image,
      });
    }

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.HelpCenterDataCallId !== null &&
      this.HelpCenterDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.HelpCenterDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        helpCenter: responseJson.data ? responseJson.data : [],
        mainQuestion: "",
      });
    }

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.QuestionDataCallId !== null &&
      this.QuestionDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.QuestionDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        ...this.state,
        loading: false,
        questionAns: responseJson.data ? responseJson.data : [],
      });
    }

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    this.getAboutData();
    this.getHelpCenterData();
    try {
    } catch (e) {}
  }

  // About API Call
  getAboutData = () => {
    const header = {
      "Content-Type": configJSON.aboutUsApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AboutUsDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutUSAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Help Center API Call
  getHelpCenterData = () => {
    const header = {
      "Content-Type": configJSON.aboutUsApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.HelpCenterDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorHelpCenterAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Question API Call
  questionData = (id: string) => {
    const apiEndPoint = configJSON.questionAPIEndPoint + id;

    const header = {
      "Content-Type": configJSON.aboutUsApiContentType,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.QuestionDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Change Help Center Tab
  handleHelpCenterChangeTab = (value: any) => {
    this.setState({
      ...this.state,
      helpCenterStep: value,
    });
  };

  // Main Question Call Api
  handleMainQuestion = (question: string, value: number, id: string) => {
    this.setState({
      ...this.state,
      helpCenterStep: value,
      mainQuestion: question,
      loading: true,
    });

    this.questionData(id);
  };

  // Question Tab
  handleQuestion = (question: questionAns) => {
    this.setState({
      ...this.state,
      helpCenterStep: 3,
      question: question.attributes.question,
      answer: question.attributes.answer,
    });
  };

  // Customizable Area End
}
