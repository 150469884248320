import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { Menu } from "../../../../node_modules/@szhsin/react-menu";
import { withStyles } from "@material-ui/core/styles";
import AddProductOtherInfoController from './AddProductOtherInfoController.web'
import { Formik } from "formik";
import * as Yup from 'yup'

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px"
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .btn-wrapper": {
      textAlign: "center",
    },
    "& .block-wrapper-inner .error": {
      color: "red",
    },
  },
};

const productOtherInfoSchema = Yup.object().shape({
  occasionId: Yup.string().required("Occasion is required"),
  // shopByEditId: Yup.string().required("Shop By Edit 1 is required")
});

export class AddProductOtherInfo extends AddProductOtherInfoController {
  myRef: React.RefObject<HTMLDivElement>; // error referrance
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  steps = ['Basic Info', 'Product Details', 'Pricing', 'Other Info', 'Policy'];
  setActiveStep = (step: any) => {
    this.setState({ activeStep: step });
  }

  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  };

  handleBack = () => {
    this.gotoProductPriceDetailScreen();
  };

  menuProps: any = {
    style: { maxHeight: "300px" },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {/* Other Info start */}
        <div className={classes.pageOuter} ref={this.myRef}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  {/* step bar start */}
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={this.state.activeStep}>
                      {this.steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        if (index < this.state.activeStep) {
                          stepProps.completed = true;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={this.handleNext}>
                          {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                        </Button> */}
                      </Box>
                    </React.Fragment>
                  </Box>
                  {/* step bar end */}

                  <h2>Other Info</h2>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">
                      <Formik
                        initialValues={this.productOtherInfoIntialValues}
                        validationSchema={productOtherInfoSchema}
                        onSubmit={(values) => {
                          this.addProductOtherInfoApiCall(values);
                        }}>
                        {(props) => {
                          const { errors, touched, isValid, dirty } = props;
                          if (Object.keys(errors).length) {
                            this.myRef.current?.scrollIntoView({ behavior: 'auto' });
                          }
                          props.values.bullet_point1 = this.state.bullet_point1;
                          props.values.bullet_point2 = this.state.bullet_point2;
                          props.values.bullet_point3 = this.state.bullet_point3;
                          props.values.bullet_point4 = this.state.bullet_point4;
                          props.values.bullet_point5 = this.state.bullet_point5;
                          props.values.occasionId = this.state.occasionId;
                          props.values.occasion2Id = this.state.occasion2Id;
                          props.values.shopByEditId = this.state.shopByEditId;
                          props.values.shopByEdit2Id = this.state.shopByEdit2Id;
                          props.values.other_attribute1 = this.state.other_attribute1;
                          props.values.other_attribute2 = this.state.other_attribute2;
                          props.values.other_attribute3 = this.state.other_attribute3;
                          props.values.care_instruction = this.state.care_instruction;

                          return (
                            <form onSubmit={props.handleSubmit}>
                              <h4>Additional Detail</h4>
                              <div className="form-group">
                                <InputLabel htmlFor="point1">Bullet Point 1</InputLabel>
                                <FormControl>
                                  <Input
                                    autoFocus
                                    type="text"
                                    id="bullet_point1"
                                    name="bullet_point1"
                                    value={props.values.bullet_point1}
                                    error={touched.bullet_point1 && errors.bullet_point1 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.bullet_point1 && errors.bullet_point1 ? errors.bullet_point1 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="point2">Bullet Point 2</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="bullet_point2"
                                    name="bullet_point2"
                                    value={props.values.bullet_point2}
                                    error={touched.bullet_point2 && errors.bullet_point2 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.bullet_point2 && errors.bullet_point2 ? errors.bullet_point2 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="point3">Bullet Point 3</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="bullet_point3"
                                    name="bullet_point3"
                                    value={props.values.bullet_point3}
                                    error={touched.bullet_point3 && errors.bullet_point3 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.bullet_point3 && errors.bullet_point3 ? errors.bullet_point3 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="point4">Bullet Point 4</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="bullet_point4"
                                    name="bullet_point4"
                                    value={props.values.bullet_point4}
                                    error={touched.bullet_point4 && errors.bullet_point4 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.bullet_point4 && errors.bullet_point4 ? errors.bullet_point4 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="point5">Bullet Point 5</InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="bullet_point5"
                                    name="bullet_point5"
                                    value={props.values.bullet_point5}
                                    error={touched.bullet_point5 && errors.bullet_point5 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.bullet_point5 && errors.bullet_point5 ? errors.bullet_point5 : ''}</span> */}
                                </FormControl>
                              </div>

                              <div className="form-group">
                                <InputLabel id="occasion">Occasion*</InputLabel>
                                <Select
                                  id="occasionId"
                                  name="occasionId"
                                  value={props.values.occasionId}
                                  error={touched.occasionId && errors.occasionId ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.occasionData &&
                                    this.state.occasionData.length &&
                                    this.state.occasionData.map((occasion: any) => {
                                      if (Number(occasion.value) !== Number(props.values.occasion2Id)) {
                                        return (
                                          <MenuItem value={occasion.value}>{occasion.label}</MenuItem>
                                        )
                                      }
                                    }
                                    )
                                  }
                                </Select>
                                <span className="error">{touched.occasionId && errors.occasionId ? errors.occasionId : ''}</span>
                              </div>
                              <div className="form-group">
                                <InputLabel id="occasion1">Occasion 2</InputLabel>
                                <Select
                                  id="occasion2Id"
                                  name="occasion2Id"
                                  value={props.values.occasion2Id}
                                  error={touched.occasion2Id && errors.occasion2Id ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  <MenuItem value="">Select</MenuItem>
                                  {
                                    this.state.occasionData &&
                                    this.state.occasionData.length &&
                                    this.state.occasionData.map((occasion2: any) => {
                                      if (Number(occasion2.value) !== Number(props.values.occasionId)) {
                                        return (
                                          <MenuItem value={occasion2.value}>{occasion2.label}</MenuItem>
                                        )
                                      }
                                    }
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.occasion2Id && errors.occasion2Id ? errors.occasion2Id : ''}</span> */}
                              </div>
                              <div className="form-group">
                                <InputLabel id="shopedit1">Shop By Edit 1</InputLabel>
                                <Select
                                  id="shopByEditId"
                                  name="shopByEditId"
                                  value={props.values.shopByEditId}
                                  error={touched.shopByEditId && errors.shopByEditId ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                  displayEmpty
                                >
                                  <MenuItem value="">Select</MenuItem>
                                  {
                                    this.state.shopByEditData &&
                                    this.state.shopByEditData.length &&
                                    this.state.shopByEditData.map((shopByEditId: any) => {
                                      if (Number(shopByEditId.value) !== Number(props.values.shopByEdit2Id)) {
                                        return (
                                          <MenuItem value={shopByEditId.value}>{shopByEditId.label}</MenuItem>
                                        )
                                      }
                                    }
                                    )
                                  }
                                </Select>
                                <span className="error">{touched.shopByEditId && errors.shopByEditId ? errors.shopByEditId : ''}</span>
                              </div>
                              <div className="form-group">
                                <InputLabel id="shopedit2">Shop By Edit 2</InputLabel>
                                <Select
                                  id="shopByEdit2Id"
                                  name="shopByEdit2Id"
                                  value={props.values.shopByEdit2Id}
                                  error={touched.shopByEdit2Id && errors.shopByEdit2Id ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                  displayEmpty
                                >
                                  <MenuItem value="">Select</MenuItem>
                                  {
                                    this.state.shopByEditData &&
                                    this.state.shopByEditData.length &&
                                    this.state.shopByEditData.map((shopedit2: any) => {
                                      if (Number(shopedit2.value) !== Number(props.values.shopByEditId)) {
                                        return (
                                          <MenuItem value={shopedit2.value}>{shopedit2.label}</MenuItem>
                                        )
                                      }
                                    }
                                    )
                                  }
                                </Select>
                                {/* <span className="error">{touched.shopByEdit2Id && errors.shopByEdit2Id ? errors.shopByEdit2Id : ''}</span> */}
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="other-attribute1">
                                  Other Attribute 1
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="other_attribute1"
                                    name="other_attribute1"
                                    value={props.values.other_attribute1}
                                    error={touched.other_attribute1 && errors.other_attribute1 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.other_attribute1 && errors.other_attribute1 ? errors.other_attribute1 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="other-attribute2">
                                  Other Attribute 2
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="other_attribute2"
                                    name="other_attribute2"
                                    value={props.values.other_attribute2}
                                    error={touched.other_attribute2 && errors.other_attribute2 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.other_attribute2 && errors.other_attribute2 ? errors.other_attribute2 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="other-attribute3">
                                  Other Attribute 3
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="other_attribute3"
                                    name="other_attribute3"
                                    value={props.values.other_attribute3}
                                    error={touched.other_attribute3 && errors.other_attribute3 ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.other_attribute3 && errors.other_attribute3 ? errors.other_attribute3 : ''}</span> */}
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="care-instruction">
                                  Care Instructions
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="care_instruction"
                                    name="care_instruction"
                                    value={props.values.care_instruction}
                                    error={touched.care_instruction && errors.care_instruction ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  {/* <span className="error">{touched.care_instruction && errors.care_instruction ? errors.care_instruction : ''}</span> */}
                                </FormControl>
                              </div>

                              <div className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                >
                                  Continue
                                </Button>
                              </div>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* Other Info end */}
      </>
    );
  }
}

export default withStyles(styles)(AddProductOtherInfo);
