import React from "react";
import Grid from "@material-ui/core/Grid";
import LoyaltyPointsController, {
  Props,
  configJSON,
} from "./LoyaltyPointsController.web";
import { loyalty } from "./assets";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";

class LoyaltyPoints extends LoyaltyPointsController {
  constructor(props: Props) {
    super(props);
  }

  renderLoyaltyPoints = () => {
    return (
      <Grid xs={12}>
        <div className="user-detail-wrapper">
          <div className="loyalty-point-wrapper">
            <div className="loyalty-point-list">
              <div className="user-detail-inner">
                <h5>
                  <b>Loyalty Points</b>
                </h5>
                <div className="loyalty-point mt-3">
                  <span className="loyalty-img">
                    <img src={loyalty} alt="" />
                  </span>
                  <p>
                    Available Poins: <span>{this.state.point}</span>
                  </p>
                </div>
                <div className="loyalty-info">
                  <p>
                    By use of loyalty points, ₹{this.state.rupee} will be
                    deducted from order total MRP
                  </p>
                </div>
              </div>
            </div>
            <div className="loyalty-point-list">
              <div className="user-detail-inner">
                <h5>
                  <b>Points &amp; Rupees Calculation</b>
                </h5>
                <div className="calculation mt-3">
                  <span className="points">100 Points</span>
                  <span className="price">₹5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  render() {
    return (
      <>
        <CustomerAccountSideBarWeb
          id={1}
          navigation={this.props.navigation}
          children={this.renderLoyaltyPoints()}
          callbackShopWithFriend={this.props.callbackShopWithFriend}
        />
      </>
    );
  }
}

export default LoyaltyPoints;
