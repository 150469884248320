import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ProductOtherInfoModel } from "../../../framework/src/Interfaces/IProductDetail";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string | null;
  id: number | null;
  isEdit: boolean;
  product: any;
  activeStep: number;
  occasionData: any[];
  occasion2Data: any[];
  shopByEditData: any[];
  shopByEdit2Data: any[];
  bullet_point1: string;
  bullet_point2: string;
  bullet_point3: string;
  bullet_point4: string;
  bullet_point5: string;
  occasionId: number | null;
  occasion2Id: number | null;
  shopByEditId: number | null;
  shopByEdit2Id: number | null;
  other_attribute1: string;
  other_attribute2: string;
  other_attribute3: string;
  care_instruction: string;
}

interface SS { }

export default class AddProductOtherInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductDataApiCallId: any;
  occationApiCallId: any;
  occation2ApiCallId: any;
  shopByEditApiCallId: any;
  shopByEdit2ApiCallId: any;
  addProductOtherInfoApi: any;
  occasionData = [{ value: "", label: "" }];
  occasion2Data = [{ value: "", label: "" }];
  shopByEditData = [{ value: "", label: "" }];
  shopByEdit2Data = [{ value: "", label: "" }];
  // Customizable Area End

  public productOtherInfoIntialValues: ProductOtherInfoModel = {
    bullet_point1: "",
    bullet_point2: "",
    bullet_point3: "",
    bullet_point4: "",
    bullet_point5: "",
    occasionId: null,
    occasion2Id: null,
    shopByEditId: null,
    shopByEdit2Id: null,
    other_attribute1: "",
    other_attribute2: "",
    other_attribute3: "",
    care_instruction: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      token: localStorage.getItem("authToken"),
      id: Number(localStorage.getItem("id")) || null,
      isEdit: Boolean(localStorage.getItem("isEdit")),
      product: {},
      activeStep: 3,
      occasionData: [{ value: "", lable: "" }],
      occasion2Data: [{ value: "", lable: "" }],
      shopByEditData: [{ value: "", lable: "" }],
      shopByEdit2Data: [{ value: "", lable: "" }],
      bullet_point1: "",
      bullet_point2: "",
      bullet_point3: "",
      bullet_point4: "",
      bullet_point5: "",
      occasionId: null,
      occasion2Id: null,
      shopByEditId: null,
      shopByEdit2Id: null,
      other_attribute1: "",
      other_attribute2: "",
      other_attribute3: "",
      care_instruction: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await this.getDropDownList();
    if (this.state.isEdit) {
      this.getProductData();
    }
  }

  async receive(from: string, message: Message) {
    console.log("api call ------------------>>>");
    console.log("message ------------------>>>", message);
    console.log("message ------------------>>>", message.id);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.VendorAddUpdateProductDataMessage)
      );
      if (data) {
        const { id, isEdit } = data;
        this.setState({ id: id, isEdit: isEdit });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("json response ------------------>>>", responseJson.data);

      if (apiRequestCallId && responseJson.data) {
        if (apiRequestCallId === this.getProductDataApiCallId) {
          // store product data into product state
          this.setState({ product: responseJson.data });
          const { product } = this.state;
          if (product && product.attributes) {
            const data = product.attributes;
            // set catgory sub category sub sub category api
            const category_id = data.product_type && data.product_type.length ? data.product_type[0].id : 0;
            const sub_category_id = data.sub_category_id && data.sub_category_id.length ? data.sub_category_id[0].id : 0;

            this.setState({
              bullet_point1: data.bullet_point_1,
              bullet_point2: data.bullet_point_2,
              bullet_point3: data.bullet_point_3,
              bullet_point4: data.bullet_point_4,
              bullet_point5: data.bullet_point_5,
              occasionId:
                (data.occasion_id &&
                  data.occasion_id[0] &&
                  data.occasion_id[0].id) ||
                "",
              occasion2Id:
                (data.occasion_2_id &&
                  data.occasion_2_id[0] &&
                  data.occasion_2_id[0].id) ||
                "",
              shopByEditId:
                (data.shop_by_edit_id &&
                  data.shop_by_edit_id[0] &&
                  data.shop_by_edit_id[0].id) ||
                "",
              shopByEdit2Id:
                (data.shop_by_edit_2_id &&
                  data.shop_by_edit_2_id[0] &&
                  data.shop_by_edit_2_id[0].id) ||
                "",
              other_attribute1: data.other_attribute_1,
              other_attribute2: data.other_attribute_2,
              other_attribute3: data.other_attribute_3,
              care_instruction: data.care_instruction,
            },
              () => this.getShopByEditList(category_id, sub_category_id)
            );
          }
        } else if (apiRequestCallId === this.occationApiCallId) {
          this.occationApiCallId = null;
          this.occasionData = [];

          responseJson.data.map((d: any) => {
            this.occasionData.push({ label: d.attributes.name, value: d.id });
          });
          this.setState({ occasionData: this.occasionData }, () =>
            console.log("occasionData:::", this.state.occasionData)
          );
        } else if (apiRequestCallId === this.shopByEditApiCallId) {
          this.shopByEditApiCallId = null;
          this.shopByEditData = [];

          responseJson.data.map((d: any) => {
            this.shopByEditData.push({ label: d.attributes.name, value: d.id });
          });
          this.setState({ shopByEditData: this.shopByEditData });
        } else if (apiRequestCallId === this.addProductOtherInfoApi) {
          // go to next page :::
          this.gotoProductPolicyScreen();
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }

      // api errors
      if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }

      // api handle catch response
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  handleChange = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  gotoProductPolicyScreen = () => {
    console.log("next:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
    //   id: this.state.id,
    //   isEdit: this.state.isEdit
    // });
    this.send(msg);
  };

  gotoProductPriceDetailScreen = () => {
    console.log("back:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductPriceDetailMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
      id: this.state.id,
      isEdit: this.state.isEdit,
    });
    this.send(msg);
  };

  getDropDownList = async () => {
    await this.getOccasionList();
    // await this.getOccasion2List();
    // await this.getShopByEditList();
    // await this.getShopByEdit2List();
  };

  getProductData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const productId = this.state.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDataApi + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getOccasionList() {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.occationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropDown + "?value_type=occasion"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // async getOccasion2List() {

  //   const header = {
  //     "Content-Type": configJSON.apiContentType,
  //     "token": this.state.token
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.occation2ApiCallId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.getDropDown + "?value_type=occasion_2"
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.getApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }

  async getShopByEditList(category_id: number, sub_category_id: number) {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shopByEditApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shopbyEditApiEndPoint + `?category_id=${category_id}&sub_category_id=${sub_category_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // async getShopByEdit2List() {

  //   const header = {
  //     "Content-Type": configJSON.apiContentType,
  //     "token": this.state.token
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.shopByEdit2ApiCallId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.getDropDown + "?value_type=shop_by_edit_2"
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.getApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }

  addProductOtherInfoApiCall = (values: ProductOtherInfoModel) => {
    const {
      bullet_point1,
      bullet_point2,
      bullet_point3,
      bullet_point4,
      bullet_point5,
      occasionId,
      occasion2Id,
      shopByEditId,
      shopByEdit2Id,
      other_attribute1,
      other_attribute2,
      other_attribute3,
      care_instruction,
    } = values;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addProductOtherInfoApi = requestMessage.messageId;

    let productID = this.state.id;
    // let productID = this.state.productId;

    const httpBody = {
      bullet_point_1: bullet_point1,
      bullet_point_2: bullet_point2,
      bullet_point_3: bullet_point3,
      bullet_point_4: bullet_point4,
      bullet_point_5: bullet_point5,
      occasion_id: occasionId,
      occasion_2_id: Number(occasion2Id),
      shop_by_edit_id: shopByEditId,
      shop_by_edit_2_id: shopByEdit2Id,
      other_attribute_1: other_attribute1,
      other_attribute_2: other_attribute2,
      other_attribute_3: other_attribute3,
      care_instruction: care_instruction,
    };

    console.log("http body ------>>>", httpBody);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productEditAPiEndPoint + `/${productID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
