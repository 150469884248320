import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as _ from "lodash";
import { sizeAttributeModal, sizeAttributeResponse, SizeChartResponse, sizeResponse } from "../../../framework/src/Interfaces/ISizeChart";
import { toastErrorMessage } from "../../../components/src/toastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  updateId?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  viewChartTable: boolean;
  sizeCharts: any[];
  addsizeCharts: any;
  rmsizeCharts: any;
  editsizeCharts: any;
  categories: any;
  subCategories: any;
  productName: any;
  productCategory: any;
  productSubCategory: any;
  productMeasurement: any;
  productCategoryId: any;
  productSubCategoryId: any;
  productMeasurementId: any;
  row_val: any;
  isDelete: boolean;
  sizeChartId: any;
  addColHeader: any;
  updation: any;
  catError: boolean;
  subcatError: boolean;
  meaError: boolean;
  addRow: any;

  // editing size chart start
  chartRows: any;
  cols: any;
  editingSizeChartId: number;
  editingSizeChart: any;
  // editing size chart end

  // size chart list start
  sizeChartToDelete: any; // data of the size chart to be deleted
  selectedCat: string;
  selectedSubCatId: string;
  usedCategories: any;
  usedSubCategories: any;
  totalRows: object[];
  finalRows: object[];
  finalCols: object[];
  loading: boolean;
  formError: boolean;

  // new size charts changes states
  deletedSizes: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
interface RowObj {
  label_value: string,
  column_name: string,
  id: number,
  row: number
}

export default class NewSizeChartController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: localStorage.getItem('authToken') || '',
      viewChartTable: false,
      sizeCharts: [],
      addsizeCharts: [],
      rmsizeCharts: [],
      editsizeCharts: [],
      categories: [],
      subCategories: [],
      productName: "hello",
      productCategory: "",
      productSubCategory: "",
      productMeasurement: "",
      productCategoryId: "",
      productSubCategoryId: "",
      productMeasurementId: "",
      row_val: [],
      isDelete: false,
      sizeChartId: "",
      addColHeader: "",
      updation: "",
      catError: false,
      subcatError: false,
      meaError: false,
      addRow: [],

      // editing size chart start
      // size chart rows
      chartRows: [],
      cols: [],
      // editingSizeChartId: 0,
      editingSizeChart: [],
      // editing size chart end

      // size chart list start
      sizeChartToDelete: {},
      selectedCat: '',
      selectedSubCatId: '',
      usedCategories: [],
      usedSubCategories: [],
      totalRows: [],
      finalRows: [],
      finalCols: [],
      loading: false,
      formError: false,

      // new define state
      editingSizeChartId: this.props.navigation.getParam('id'),
      deletedSizes: []
      // size chart list stop
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSizeChartsCallId) {
      this.setState({ loading: false });
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.data) {
        this.setState({ sizeCharts: responseJson.data },
          () => {
            console.log("get size charts response:::");
          }
        )
      } else {
        this.setState({ sizeCharts: [] });
        // if size listing filter by category and sub category then do not redirect on empty size chart page
        if (!this.state.productCategoryId && !this.state.productSubCategoryId) {
          this.props.navigation.navigate("SizeChartsEmptyWeb")
        }
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getSizeChartByIdCallId) {
      this.setState({ loading: false });
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.getProductCategoies();
      if (responseJson !== undefined && responseJson.data) {
        this.setState({ editingSizeChart: responseJson.data },
          () => {
            console.log("get size charts response by id:::", this.state.editingSizeChart);
            this.getProductCategoies();

            let usedCategories: any = [];
            let usedSubCategories: any = [];

            // this.state.editingSizeChart.map((chart: any) => {
            usedCategories.push({
              id: this.state.editingSizeChart?.attributes?.categories_id,
              // name: chart.attributes?.category_name,
            })
            usedSubCategories.push({
              id: this.state.editingSizeChart?.attributes?.sub_categories_id,
              // name: chart.attributes?.sub_category_name,
            })
            // })

            this.setState(
              {
                editingSizeChart: responseJson.data,
                usedCategories: usedCategories,
                usedSubCategories: usedSubCategories,
                loading: false,
              },
              () => {
                this.setColRowData(responseJson?.data?.attributes); // this function will set repsonse in col - row as per ui
                this.getProductSubCategoies(this.state?.editingSizeChart?.attributes?.categories_id);
                this.setState({
                  selectedCat: this.state.editingSizeChart?.attributes?.categories_id,
                  selectedSubCatId: this.state.editingSizeChart?.attributes?.sub_categories_id
                })
              });
          }
        )
      } else {
        this.props.navigation.navigate("SizeChartsEmptyWeb")
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProductCategoriesCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.setState({ categories: responseJson.data })
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getProductSubCategoriesCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        this.setState({ subCategories: responseJson.data })
      } else {
        // this.props.navigation.navigate("SizeChartsEmpty")
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.addSizeChartsCallId) {
      this.setState({ loading: false });
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.data && responseJson.error == undefined) {
        // redirect to charts list
        this.setState({ addsizeCharts: responseJson.data }, () => {
          this.props.navigation.navigate("SizeCharts")
        })
      } else {
        console.log("Error");
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.rmSizeChartsCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        if (responseJson.data && responseJson.data.length) {
          if (responseJson.data[0].errors &&
            responseJson.data[0].errors.length
          ) {
            // size chart is not deleted show toast error message
            const error_message = responseJson.data[0].errors[0].measurement_chart;
            toastErrorMessage(error_message);
          }
        }
        this.setState({ rmsizeCharts: responseJson.data }, () => {
          this.getSizeCharts();
        })
      } else {
        this.getSizeCharts();
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.editSizeChartsCallId) {
      this.setState({ loading: false });
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined) {
        if (responseJson !== undefined) {
          if (responseJson.data) {
            if (
              responseJson.data.length &&
              responseJson.data[0].errors &&
              responseJson.data[0].errors.length
            ) {
              // size chart is not deleted show toast error message
              const error_message = responseJson.data[0].errors[0].measurement_chart;
              toastErrorMessage(error_message);
            } else {
              this.props.navigation.navigate("SizeCharts")
            }
          }
        }
        else {
          this.props.navigation.navigate("SizeCharts")
        }
      } else {
        console.log("editsizeempty");
      }
    }

    if (errorReponse) {
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // class properties start
  getProductApiCallId: any;
  getSizeChartsCallId: any;
  getSizeChartByIdCallId: any;
  addSizeChartsCallId: any;
  rmSizeChartsCallId: any;
  editSizeChartsCallId: any;
  getProductCategoriesCallId: any;
  getProductSubCategoriesCallId: any;
  // class properties end

  /**
   * Function to get all the size charts
   */
  getSizeCharts() {
    const { productCategoryId, productSubCategoryId } = this.state;
    // this.setState({ loading: true });
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSizeChartsCallId = requestMessage.messageId;

    if (productCategoryId && productSubCategoryId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.newSizeChartApiEndPoint}?category_id=${productCategoryId}&sub_category_id=${productSubCategoryId}`
      );
    } else if (productCategoryId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.newSizeChartApiEndPoint}?category_id=${productCategoryId}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.newSizeChartApiEndPoint
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getSizeChartsCallId, requestMessage);
  }

  /**
   * Function to fetch all product categories
   */
  getProductCategoies() {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategorySubCategorySubSubCategoryApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getProductCategoriesCallId, requestMessage);
  }

  /**
   * Function to fetch details on a sub-category when parent category's id is passed as argument
   * @param productCategory id of the parent category
   */
  getProductSubCategoies(productCategory: any) {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductSubCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategorySubCategorySubSubCategoryApi + `?category_id=${productCategory}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(this.getProductSubCategoriesCallId, requestMessage);
  }

  /**
   * Function to add new size chart
   * @param size_chart size chart data object
   */
  addSizeCharts(size_chart: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const body = { size_chart };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSizeChartsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSizeChartApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(this.addSizeChartsCallId, requestMessage);
  }

  /**
   * Function to delete the size chart
   * @param size_chart_id id of the size chart to be deleted
   */
  deleteSizeCharts(size_chart_id: any) {
    this.setState({
      isDelete: false
    });
    const header = {
      // 'Access-Control-Allow-Origin': '*',
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rmSizeChartsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newSizeChartApiEndPoint + '/' + size_chart_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDel
    );
    runEngine.sendMessage(this.rmSizeChartsCallId, requestMessage);
  }

  /**
   * Function to edit a size chart
   * @param size_chart size chart data object
   * @param size_chart_id id of the size chart
   */
  editSizeCharts(size_chart: any, size_chart_id: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const body = { size_chart };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editSizeChartsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSizeChartApiEndPoint + '/' + size_chart_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(this.editSizeChartsCallId, requestMessage);
  }

  // -------------------------- HELPING METHODS --------------------------

  // -------------------------- Create Size Chart start --------------------------

  /**
   * Function to append new column to state/formik form
   */
  addColumn() {
    let columns = this.state.cols;
    columns.push({ label_name: "", variant_size_name_id: "" })
    this.setState({ cols: columns })
  }

  /**
   * Function to remove column and matching values in rows from state/formik form
   * @param index index of the column
   */
  removeColumn(colIndex: number) {
    let cols = this.state.cols;
    let colLabelName = this.state.cols[colIndex].label_name;
    cols.splice(colIndex, 1);
    this.setState({ cols: cols });

    // removing corresponding values from chartRows
    let rows = this.state.chartRows;
    rows.map((row: any) => {
      delete row[colLabelName];
    })
    this.setState({ chartRows: rows })
  }

  /**
   * Function to update the column
   * @param colIndex index of column in cols state variable
   * @param event 
   */
  updateColumns(colIndex: number, event: any) {
    let columns = this.state.cols;
    columns[colIndex].label_name = event?.target.value;
    this.setState({ cols: columns })
  }

  /**
   * Function to add new row
   */
  addRow() {
    let rows = this.state.chartRows;
    rows.push({ label_value: "", defaultSizeRow: '' });
    this.setState({ chartRows: rows });
  }

  /**
   * Function to remove row
   * @param rowIndex index of row
   */
  removeRow(rowIndex: number) {
    let rows = this.state.chartRows;
    rows.splice(rowIndex, 1);
    this.setState({ chartRows: rows });
  }

  /**
   * Function to update row value
   * @param rowIndex index of row
   */
  updateRow(rowIndex: number, colIndex: number, e: any) {
    if (colIndex === -1) {
      // i will pass column index as -1 for default row (size name value)
      let rows = this.state.chartRows;
      rows[rowIndex].defaultSizeRow = e.target.value;
      this.setState({ chartRows: rows });
      return;
    }
    console.log('here')
    let rows = this.state.chartRows;
    rows[rowIndex][this.state.cols[colIndex].label_name] = e.target.value;
    this.setState({ chartRows: rows });
  }

  /**
   * Function to delete fields from row by setting new 'delete' property
   * @param row: the row to be deleted
   */

  deleteRow(colIndex: null, row: [{ id: number, label_value: string, label_name: string }], rowIndex: number) {
    console.log('deleted row', row);
    let deletedSize = [...this.state.deletedSizes];
    var deletedRow: { id: any, label_value: string, label_name: string, destroy?: boolean }[] = [];
    row.map((field: { id: any, label_value: string, label_name: string, destroy?: boolean, isDefaultValue?: boolean }) => {
      if (field.isDefaultValue && field.id) {
        // deleteSizeIds state for resprent whole row is deleted of existing size id
        const data = {
          name: field.label_value,
          size_id: field.id,
          destroy: "true"
          // "alias": "s",
        }
        deletedSize = [...this.state.deletedSizes, data];
      }
      if (field.id !== '') {
        field['destroy'] = true;
        deletedRow.push(field);
      }
    })
    this.setState({
      finalRows: [...this.state.finalRows,
      ...deletedRow.filter(field => field.destroy)],
      deletedSizes: deletedSize
    }, () => {
      console.log('sahib', this.state.finalRows);
      this.removeRow(rowIndex);
    })
  }

  updateField(rowIndex: number, colIndex: number, e: any) {
    let rows = this.state.chartRows;
    rows[rowIndex][colIndex].label_value = e.target.value;
    this.setState({ chartRows: rows });
  }

  addFieldRow() {
    var newRow: object[] = [];
    // this is for default row added in ui
    newRow.push({
      label_value: "",
      id: "",
      column_name: 'Size Name',
      isDefaultValue: true,
      row: this.state.chartRows.length
    });

    this.state.cols.forEach((col: { id: number, label_name: string }) => {
      newRow.push({
        label_value: "",
        id: "",
        column_name: col.label_name,
        row: this.state.chartRows.length
      })
    });
    this.setState({ chartRows: [...this.state.chartRows, ...[newRow]] });
    // loop over the columns and add a new object for each column
  }

  addFieldColumn() {
    var chartRows = this.state.chartRows;
    chartRows.forEach((row: [{ row: number, label_value: string, id: string, column_name: string }], rowIndex: number) => {
      row.push({
        label_value: "",
        id: "",
        column_name: "",
        row: row[0].row
      })
    });
    this.setState({ chartRows: chartRows }, () => {
      this.addColumn();
    });
  }

  updateFieldColumn(colIndex: number, e: any) {
    this.updateColumns(colIndex, e);
    var chartRows = this.state.chartRows;
    chartRows.forEach((row: [{ column_name: string }], rowIndex: number) => {
      // colIndex + 1 (index) ===> first default row in each rows object is static(column is static)
      row[colIndex + 1].column_name = e.target.value;
    });
    this.setState({ chartRows: chartRows })
  }

  removeFieldColumn(colIndex: number) {
    var chartRows = this.state.chartRows;
    var deletedColumns: { destroy: boolean }[] = [];
    var newArr: object[] = [];
    var newCols: object[] = [];
    const cols = this.state.cols;

    const deletRows = [];

    // column index start from 1 (default column at 0 index is always present so index != colIndex + 1 );
    console.log('sahib', cols);
    chartRows.forEach((row: [{}]) => {
      newArr.push(row.filter((col, index) => index != colIndex + 1));
    })
    console.log('sahib', chartRows);
    chartRows.map((row: any) => {
      if (row[colIndex + 1].id != '') {
        console.log('deleted rows', row[colIndex + 1]);
        row[colIndex + 1].destroy = true;
        deletedColumns.push(row[colIndex + 1])
      }
    })

    this.setState({ chartRows: newArr }, () => {
      if (cols[colIndex].variant_size_name_id != '') {
        newCols.push({ ...cols[colIndex], ...{ destroy: true } });
      }
      this.removeColumn(colIndex);
      this.setState({ finalRows: [...this.state.finalRows, ...deletedColumns], finalCols: newCols }, () => {
        console.log('sahib', this.state.finalCols, this.state.finalRows);
      });
    });

  }
  // -------------------------- Create Size Chart end --------------------------


  // new logic for size chart
  newAddSizeChart = (values: any, actions: any) => {
    // here i have to add validation for default size name value
    console.log("value:::", values);
    console.table("coloumn::", values.size_chart_column_name)
    console.table("rows::", this.state.chartRows)

    // return;
    let cols: object[] = [];
    this.state.cols.map((col: any) => {
      cols.push({ label_value: _.toString(_.map(this.state.chartRows, col.label_name)) });
    });
    // renaming properties product_cat with categories_id and product_sub_cat with sub_categories_id
    let sizeChart: { product_name: string, measurement_unit: string, categories_id: number, sub_categories_id: number, size_chart_column_value: any, size_chart_column_name: any } = {
      product_name: values.product_name,
      categories_id: Number(values.product_cat),
      sub_categories_id: Number(values.product_sub_cat),
      measurement_unit: values.measurement_unit,
      size_chart_column_name: values.size_chart_column_name,
      size_chart_column_value: cols
    };

    const chartRows: any[] = this.state.chartRows;
    // calling the addSizeCharts method of parent controller class
    if (!chartRows.length) {
      this.setState({ formError: true });
    } else if (
      sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value == 'undefined').length ||
      sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value == '').length ||
      sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value.indexOf('undefined') > -1).length ||
      sizeChart.size_chart_column_name.filter((x: { label_name: string, id: string }) => x.label_name == '').length ||
      sizeChart.size_chart_column_name.filter((x: { label_name: string, id: string }) => x.label_name == 'undefined').length ||
      sizeChart.size_chart_column_name.length != sizeChart.size_chart_column_value.length ||
      chartRows.filter(row => row.defaultSizeRow == '').length ||
      chartRows.filter(row => row.defaultSizeRow == undefined).length
    ) {
      console.table("has error::", this.state.chartRows)
      this.setState({ formError: true });
    } else {
      // size attribute body logic
      this.setState({ formError: false });

      let sizeData: any = [];
      // loop on rows for
      for (let index = 0; index < this.state.chartRows.length; index++) {
        let size: {
          name: string, size_attributes: any[]
        } = {
          name: '',
          size_attributes: []
        };
        const element = this.state.chartRows[index];
        size.name = element.defaultSizeRow;

        // store other object properites inside size_attributes key
        if (Object.keys(element).length) {
          size.size_attributes = [];

          let size_attributes = [];
          for (const [key, value] of Object.entries(element)) {
            console.log("key ::", key, "value:::", value);
            // do not push defaultSizeRow inside attribute it's parent key
            if (key !== 'defaultSizeRow') {
              if (value) {
                let sizemodal: { attribute_name: string, attribute_value: any } = {
                  attribute_name: key,
                  attribute_value: value
                };
                size_attributes.push(sizemodal);
              }
            }
          }
          size.size_attributes = size_attributes
        }
        sizeData.push(size);
      }
      console.log("size:::", sizeData)

      // construct body for api structure:
      let httpBody: { name: string, measurement_unit: string, categories_id: number, sub_categories_id: number, size: any[] } = {
        name: values.product_name,
        categories_id: Number(values.product_cat),
        sub_categories_id: Number(values.product_sub_cat),
        measurement_unit: values.measurement_unit,
        size: sizeData || []
      };
      console.log("http body request ===>>>", httpBody);
      this.newAddSizeChartAPI(httpBody);
      actions.setSubmitting(false);
    }
  }

  newAddSizeChartAPI = async (httpBody: any) => {
    this.setState({ loading: true });
    this.addSizeChartsCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.newSizeChartApiEndPoint,
      body: httpBody
    });
  };

  // new logic for size chart
  editSizeChart = (values: any, actions: any) => {
    // here i have to add validation for default size name value
    console.log("value:::", values);

    const rows: any[] = (this.state.chartRows as any[]).flat();
    const cols: any[] = this.state.cols;

    if (!rows || !rows.length) {
      this.setState({ formError: true });
      return;
    }

    if (cols && cols.length && rows && !rows.length) {
      // column present and row not present then return error
      this.setState({ formError: true });
      return;
    }

    if (
      (cols && cols.length && rows && rows.length) &&
      (
        rows.filter((x: { label_value: string }) => x.label_value == 'undefined').length ||
        rows.filter((x: { label_value: string }) => x.label_value == '').length ||
        rows.filter((x: { label_value: string }) => x.label_value.indexOf('undefined') > -1).length ||
        cols.filter((x: { label_name: string, id: string }) => x.label_name == '').length ||
        cols.filter((x: { label_name: string, id: string }) => x.label_name == 'undefined').length ||
        cols.length + 1 != this.state.chartRows[0].length // column(attribute)+ 1 length should equal to first row object length
      )
    ) {
      this.setState({ formError: true });
      return;
    } else if (rows && rows.length &&
      (
        rows.filter((x: { label_value: string }) => x.label_value == 'undefined').length ||
        rows.filter((x: { label_value: string }) => x.label_value == '').length ||
        rows.filter((x: { label_value: string }) => x.label_value.indexOf('undefined') > -1).length
      )
    ) {
      this.setState({ formError: true });
      return;
    }
    else {
      this.setState({ formError: false });
      // size attribute body logic
      let sizeData: any[] = [];
      // loop on rows for
      for (let index = 0; index < this.state.chartRows.length; index++) {
        let size_attributes: any[] = [];
        let size: { size_id?: number, name: string, size_attributes: any[] } = {
          name: '',
          size_attributes: []
        };
        this.state.chartRows[index].forEach((element: any, rowIndex: number) => {

          if (element.isDefaultValue) {
            size = {
              ...size,
              name: element.label_value
            }
            if (element.id) {
              size = {
                ...size,
                size_id: element.id
              }
            }
          } else {
            let sizeAttribute: sizeAttributeModal = {
              attribute_name: element.column_name,
              attribute_value: element.label_value
            };
            if (element.id) {
              sizeAttribute = {
                ...sizeAttribute,
                size_attribute_id: element.id,
              }
            }
            size_attributes.push(sizeAttribute);
            size = {
              ...size,
              size_attributes: size_attributes
            }
          }
        })
        sizeData.push(size);
      }
      // added deleted row as detroy true
      sizeData = [...sizeData, ...this.state.deletedSizes];

      // added deleted rows as detroy true
      // finalRows contains all deleted rows(delete row object , delete specific attribute like length)
      if (sizeData && sizeData.length) {
        this.state.finalRows &&
          this.state.finalRows.length ?
          this.state.finalRows.forEach((row: any) => {
            // this is already deleted not to added
            const condition = row.isDefaultValue ? row.id : row.size_id;
            const result = this.state.deletedSizes &&
              this.state.deletedSizes.length ?
              this.state.deletedSizes.find((data) => data.size_id === condition) : null;
            console.log("result:::", result);
            // if result is not null(size already added in size data with destroy: "true") then that size added in sizeData variable
            if (!result) {
              const sizeIndex = sizeData &&
                sizeData.length ?
                sizeData.findIndex((size) => size.size_id === row.size_id) : -1;
              if (sizeIndex !== -1) {
                const deletedRow = {
                  size_attribute_id: row.id,
                  attribute_name: row.column_name,
                  attribute_value: row.label_value,
                  destroy: "true"
                }
                sizeData[sizeIndex].size_attributes.push(deletedRow);
              }
            }
          }) : null;
      }


      console.log("final size:::", sizeData)

      // construct body for api structure:
      let httpBody: { name: string, measurement_unit: string, categories_id: number, sub_categories_id: number, size: any[] } = {
        name: values.product_name,
        categories_id: Number(values.product_cat),
        sub_categories_id: Number(values.product_sub_cat),
        measurement_unit: values.measurement_unit,
        size: sizeData || []
      };

      console.log("http body request ===>>>", httpBody)
      this.editSizeChartAPI(httpBody);
      actions.setSubmitting(false);
    }
  }

  editSizeChartAPI = async (httpBody: any) => {
    this.setState({ loading: true });
    console.log("edit size chart api ===>>>", httpBody)
    this.editSizeChartsCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.newSizeChartApiEndPoint + `/${this.state.editingSizeChartId}`,
      body: httpBody
    });
  };

  getSizeChartById = async () => {
    console.log("get size chart by id api")
    this.setState({ loading: true });
    this.getSizeChartByIdCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.newSizeChartApiEndPoint}/${this.state.editingSizeChartId}`,
    });
  };

  // api calling strcture
  apiCall = async (data: any) => {
    console.log('@@@@ api data ======', data)
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log('@@@ requestMessage ========', requestMessage)
    return requestMessage.messageId;
  }



  // Edit Size chart logic start
  setColRowData = (responseData: SizeChartResponse) => {
    console.log("response json:::", responseData);
    const apiResponse: SizeChartResponse = JSON.parse(JSON.stringify(responseData));
    const sizeData: sizeResponse[] = apiResponse?.sizes?.data;

    let columnData: sizeAttributeResponse[] = [];
    if (sizeData && sizeData.length) {
      columnData = sizeData[0].attributes?.size_attributes?.data;  // attribute name is same for all so get first row

      let cols: any[] = [];
      let rows: any[] = [];

      cols = columnData &&
        columnData.length ?
        columnData.map((column: any) => {
          const object = {
            label_name: column.attributes.attribute_name,
            isEdit: true
          }
          return object;
        }) : [];

      // rows of object
      sizeData.forEach((size: sizeResponse) => {
        let defaultSize: any = {};
        // default size name column value
        defaultSize.id = size?.attributes?.id;
        defaultSize.column_name = 'Size Name';
        defaultSize.label_value = size?.attributes?.name;
        defaultSize.isDefaultValue = true;
        const size_attributes: sizeAttributeResponse[] = size?.attributes?.size_attributes?.data;

        let sizeNameValue = [];
        sizeNameValue.push(defaultSize);
        // connected with default values
        if (size_attributes && size_attributes.length) {
          size_attributes.forEach((size_attribute: sizeAttributeResponse) => {
            const attribute: any = {};
            attribute.id = size_attribute?.attributes?.id;
            attribute.column_name = size_attribute?.attributes?.attribute_name;
            attribute.label_value = size_attribute?.attributes?.attribute_value;
            attribute.size_id = size_attribute?.attributes?.size_id;
            sizeNameValue.push(attribute);
          });
        }
        rows.push(sizeNameValue);
      });
      console.log("cols::", cols);
      console.log("rows::", rows);
      this.setState({
        chartRows: rows,
        cols: cols,
      })
    }
  }
  // end 

  // Customizable Area End
}
