import React, { Component } from 'react'
import {Divider} from "@material-ui/core";
// import topPicture from  "../assets/topPicture.png"
import { Box, Typography } from "@material-ui/core";
// import blueSuit from "../assets/blueSuit.png"
// import wishlistBag  from "../assets/wishlistBag.png"
// import cross  from "../assets/cross.png"
// import wishlistIcon  from "../assets/wishlistIcon.png"
// import makeupKit from "../assets/makeupKit.png"
// import aeroSummerShorts from "../assets/aeroSummerShorts.png"
// import campus from "../assets/campus.png"
// import versace from "../assets/versace.png"
// import champion from "../assets/champion.png"
// import quiltedSlingBag from "../assets/quiltedSlingBag.png"
// import spykarBeachYellowShirt from "../assets/spykarBeachYellowShirt.png"
// import roadster from "../assets/roadster.png"



const blueSuit = require("../assets/blueSuit.png");
const wishlistBag = require("../assets/wishlistBag.png");
const cross = require("../assets/cross.png");
const makeupKit = require("../assets/makeupKit.png");
const aeroSummerShorts = require("../assets/aeroSummerShorts.png");
const campus = require("../assets/campus.png");
const champion = require("../assets/champion.png");
const quiltedSlingBag = require("../assets/quiltedSlingBag.png");
const spykarBeachYellowShirt = require("../assets/spykarBeachYellowShirt.png");
const roadster = require("../assets/roadster.png");
const versace = require("../assets/versace.png");
const topPicture = require("../assets/topPicture.png");
const wishlistIcon = require("../assets/wishlistIcon.png");


// filter component imports 
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// breadCrumb import 
// import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';



interface Props {
    
}
interface State {
    
}


const Tshirt = 
[
{img: versace,available:true},
{img: champion,available:false},
{img: spykarBeachYellowShirt,available:true},
{img: quiltedSlingBag,available:false},
{img: blueSuit,available:true},
{img: makeupKit,available:true},
{img:aeroSummerShorts,available:true},
{img:roadster,available:true},
{img: campus,available:false},
{img: versace,available:true},
{img: blueSuit,available:true},
{img:spykarBeachYellowShirt,available:true}

]

export default class Filter extends Component<Props, State> {
    state = {}

    render() {
        function handleClick(event:any) {
            event.preventDefault();
            console.info('You clicked a breadcrumb.');
          }
        return (
            <>
            {/* top image start */}
              <div >
            <img style = {{width:"100%",opacity:"0.9",height:"100%"}}  src = {topPicture} />
            {/* breadcrumbs navigation start */}
            <div style ={{position:"relative", bottom:"316px",left:"20px",color:"white"}} role="presentation" >
      <Breadcrumbs aria-label="breadcrumb" style ={{color:"white"}}>
        <Link underline="hover" color="inherit" href="HomePage">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="Filter"
        >
          Men
        </Link>
        <Typography>T-shirts</Typography>
      </Breadcrumbs>
    </div>

               {/* breadcrumbs navigation end */}

             {/* page heading start */}
             <div style ={{position:"relative",bottom:"200px",left:"50px"}}> 
             <Typography style ={{color:"white",fontSize:"40px",fontWeight:"bolder"}}>Men T-shirts</ Typography>
             
             </div>
             {/* page  heading end  */}
               </div>
                     {/* top image end */}




                     {/* filter bar start  */}
                     <div style ={{height:"60px"}}>
                         <h4 style = {{marginLeft:"20px"}}>Filter</h4>
                     </div>
                     {/* filter bar end   */}
<Divider/>

{/* filter and items start  */}
<div style ={{display:"flex"}}>
    {/* filter component start */}
<div style ={{width:"431px",height:"1000px"}}>

<div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <input name = "checkbox" type = "checkbox"/>   <label htmlFor="checkbox">T-Shirt</label>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Brand</Typography>
        </AccordionSummary>
        <AccordionDetails>
         
        <input name = "checkbox" type = "checkbox"/>   <label htmlFor="checkbox">Nike</label>
        </AccordionDetails>
      </Accordion>
   
    </div>



</div>
{/* filter component end  */}

{/* vertical divider start  */}
<Divider orientation= "vertical"/>
<div  style = {{width:"1300px",height:"600px",margin:"41px auto"}}>
{/* vertical divider end  */}

{/* copy  */}
<Box
            id="viewAllBox"
            // update 
            style={{ display: "grid"  ,gridTemplateColumns: "200px 200px 200px 200px", 
             gridRowGap: "30px",justifyContent:"space-evenly", gridColumnGap: "24px"}}
          >
            {Tshirt.map((item) => {
              return (
<>
  <div
          
          key={item.img}
          style={{
            borderRadius: "0px",
            boxShadow: "none",
            margin:"auto auto",
            // update 
            
          }}
        >
          <div style ={{height:"250px"}} >
            <img
           style ={{width:"200px"}}
              id="viewAllImg"
              src={item.img}
            />

<div style ={{position:"relative", left:"150px", width:"30px",height:"30px",backgroundColor:"white",borderRadius:"15px",bottom: "245px"}}> <img  
            
            style ={{margin:"5px "}}
              src={ wishlistIcon }
            /> </div>


          </div>
          {/* text start */}
          <div  style ={{marginTop:"5px",fontWeight:"bold"}}> 
          <Typography
            variant="body2"
            style={{
             
              fontFamily: "'Raleway',sans-serif",
              width:"285px",
         
           
            }}
          >
         FIDO DIDO
          </Typography>
        
          <Typography
            variant="body2"
            style={{
              color: "grey",
              fontFamily: "'Raleway',sans-serif",
              width:"285px",
            
            //   textOverflow:"ellipsis"
           
            }}
          >
          Men Black Printed Polo
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: "black",
              fontFamily: "'Raleway',sans-serif",
     
           fontSize:"10px"
            }}
          >
          RS1,200  <span style ={{color:"grey",marginLeft:"3px",textDecoration:"line-through"}}>rs1000</span>  <span style ={{color:"red",marginLeft:"3px"}}>(70% Off)</span>
          </Typography>
          </div>
          {/* text end   */}
        </div>
        
        
        
        
              
                </>
              );
            })}
          </Box>


</div>
</div>
{/* filter and items end  */}
            </>
        )
    }
}
