import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { } from "./assets";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";
import { OrderModel } from "../../../framework/src/Interfaces/IOrderDetail";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    classes: any;
    callbackShopWithFriend: Function;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string | null;
    isLoading: boolean;
    cancelOrderId: string;
    order: OrderModel;
    cancelReasonList: any[];
    cancelReasonId: string;
    otherCancelreason: string;
    isOrderCancelledSuccesfully: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CancelOrderController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getCustomerOrderDetailAPICallId: any;
    getCancelReasonListAPICallId: any;
    cancelReasonAPICallId: any;
    cancelOrderAPICallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.log("props cancel controller:::", props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            token: localStorage.getItem('authToken'),
            isLoading: false,
            cancelOrderId: this.props.navigation.getParam('id'),
            order: {} as OrderModel,
            cancelReasonList: [],
            cancelReasonId: '',
            otherCancelreason: '',
            isOrderCancelledSuccesfully: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();

        this.getCustomerOrderById();
        this.getCancelReasonList();
    }

    // temporary
    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        console.log("message::::", message);


        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        console.log("apiRequestCallId:::::", apiRequestCallId);

        // Get Order Detail by id
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getCustomerOrderDetailAPICallId !== null && apiRequestCallId === this.getCustomerOrderDetailAPICallId) {
            this.getCustomerOrderDetailAPICallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.data) {
                // display orders into 

                if (responseJson?.data?.attributes?.status === ORDER_STATUS_TYPES.PLACED) {
                    this.setState({ order: responseJson.data })
                } else {
                    this.setState({ order: {} as OrderModel })
                }
            } else {
                // display no orders found in customer account 
                this.setState({ order: {} as OrderModel })
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Get Order Detail by id

        // Cancel Reason with Reason List
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getCancelReasonListAPICallId !== null && apiRequestCallId === this.getCancelReasonListAPICallId) {
            this.getCancelReasonListAPICallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.data && (responseJson?.data as any[]).length) {
                // display orders into 
                this.setState({ cancelReasonList: responseJson.data })
            } else {
                // display no orders found in customer account 
                this.setState({ cancelReasonList: [] })
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Cancel Reason with Reason List

        // Cancel Reason
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.cancelReasonAPICallId !== null && apiRequestCallId === this.cancelReasonAPICallId) {
            this.cancelReasonAPICallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.data?.id) {
                // Call Cancel Order api
                this.CancelOrderApi();
                // this.setState({ orders: responseJson.data })
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Cancel Reason

        // Cancel Order
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.cancelOrderAPICallId !== null && apiRequestCallId === this.cancelOrderAPICallId) {
            this.cancelOrderAPICallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.message) {
                // Order is cancel 
                // show in taost alert
                this.setState({ isOrderCancelledSuccesfully: true }, () => this.goToCustomerOrdersScreen());
            } else {
                // display no orders found in customer account 
                this.setState({ order: {} as OrderModel })
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Cancel Order
        // Customizable Area End

    }

    getCustomerOrderById() {

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomerOrderDetailAPICallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.customerOrderListApiEndPoint + `/${Number(this.state.cancelOrderId)}`
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    getCancelReasonList() {

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCancelReasonListAPICallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cancelReasonApiEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PatchMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    CancelReasonApi() {

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cancelReasonAPICallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cancelReasonApiEndPoint + `?other=${this.state.otherCancelreason}&table_reason_to_cancles_id=${Number(this.state.cancelReasonId)}&order_id=${Number(this.state.order.id)}`
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PatchMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    CancelOrderApi() {

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const httpBody = {
            order_id: Number(this.state.order.id)
        }

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cancelOrderAPICallId = apiRequest.messageId;


        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cancelOrderApiEndPoint
        );
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutMethodType
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    hideCancelledToastMessage = () => {
        this.setState({ isOrderCancelledSuccesfully: false });
    }

    goToCustomerOrdersScreen = () => {
        setTimeout(
            () => {
                this.props.navigation.navigate('orders')
            }, 2000);
        console.log("go to Cancel Order screen:::");
    }
}