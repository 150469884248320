export const AffiliatePSharedStyleWeb: any = {
  pageOuter: {
    backgroundColor: "#ffffff",
    padding: "41px 0 85px",
    "& .container-full": {
      padding: "0 55px",
      "@media (max-width:1366px)": {
        padding: "0 30px",
      },
      "@media (max-width:600px)": {
        padding: "0 16px",
      },
    },
    "& .top-heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "12px",
      minHeight: "40px",
      "@media (max-width:991px)": {
        minHeight: "unset",
      },
    },
    "& .top-heading .heading-left": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "@media (max-width:767px)": {
        width: "100%",
        marginBottom: "10px",
      },
    },
    "& .top-heading .select-border": {
      marginRight: "0",
      minWidth: "230px",
    },
    "& .heading-title": {
      fontSize: "20px",
      lineHeight: "24px",
      color: "#121212",
      fontWeight: "600",
      margin: "0",
      "@media (max-width: 1199px)": {
        fontSize: "18px",
        lineHeight: "22px",
      },
    },
    "& .card-main": {
      margin: "0 -8px",
      marginBottom: "55px",
      width: "auto",
      maxWidth: "unset",
      "@media (max-width: 1199px)": {
        marginBottom: "25px",
      },
    },
    "& .card-item": {
      padding: "0 8px",
      marginBottom: "19px",
      "@media (max-width: 767px)": {
        marginBottom: "12px",
      },
    },
    "& .card-item .card": {
      padding: "28px 20px 31px 27px",
      background: "#f6f6f6",
      borderRadius: "5px",
      "@media (max-width: 1280px)": {
        padding: "20px 15px",
      },
      "@media (max-width: 767px)": {
        padding: "15px 12px",
      },
    },
    "& .card-item .card-heading": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "46px",
      textDecoration: "none",
      "@media (max-width: 767px)": {
        marginBottom: "30px",
      },
    },
    "& .card-item .card-heading:hover": {
      textDecoration: "none",
    },
    "& .card-item .card-icon": {
      marginRight: "15px",
      "@media (max-width: 1280px)": {
        marginRight: "10px",
      },
    },
    "& .card-item .card-icon img": {
      height: "30px",
      "@media (max-width: 1199px)": {
        height: "25px",
      },
    },
    "& .card-item .card-title": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "500",
      flex: "1",
      textDecoration: "none",
      "@media (max-width: 1199px)": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    "& .MuiLink-root": {
      textDecoration: "none !important",
    },
    "& .card-item .card-price": {
      fontSize: "36px",
      lineHeight: "40px",
      color: "#ff406c",
      fontWeight: "600",
      "@media (max-width: 1199px)": {
        fontSize: "30px",
        lineHeight: "34px",
      },
      "@media (max-width: 767px)": {
        fontSize: "26px",
        lineHeight: "30px",
      },
    },
    "& .vendor-grid": {
      margin: "0 -30px",
      width: "auto",
      "@media (max-width:1366px)": {
        margin: "0 -15px",
      },
    },
    "& .vendor-grid .vendor-leftcol": {
      padding: "0 30px",
      borderRight: "1px solid #f4f4f4",
      maxWidth: "52%",
      flexBasis: "52%",
      "@media (max-width:1366px)": {
        padding: "0 15px",
      },
      "@media (max-width:991px)": {
        maxWidth: "100%",
        flexBasis: "100%",
        borderRight: "0",
      },
    },
    "& .vendor-grid .vendor-leftcol .top-heading": {
      marginBottom: "24px",
      "@media (max-width:767px)": {
        marginBottom: "0",
      },
    },
    "& .card-item .lightgreen-txt": {
      color: "#21c998",
    },
    "& .card-item .orange-txt": {
      color: "#ff8030",
    },
    "& .card-item .navyblue-txt": {
      color: "#517ba3",
    },
    "& .card-item .purple-txt": {
      color: "#cf20ff",
    },
    "& .input-button": {
      display: "flex",
      alignItems: "flex-end",
      width: "100%",
    },
    "& .MuiTextField-root": {
      marginRight: "10px",
    },
    "& .old-price": {
      fontSize: "12px",
      lineHeight: "20px",
      textDecoration: "line-through",
      color: "#a5a5a5",
      fontWeight: "normal",
      margin: "0 5px",
    },
  },
};
