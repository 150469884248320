// import React, { Component } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import classNames from "classnames";
// import "./HomePage.Web.css";
// // trending now imports

// import { Box, Link } from '../../../node_modules/@material-ui/core';
// import { makeStyles } from "@material-ui/core";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import { Card, Container, Typography, AppBar } from "@material-ui/core";
// import {ManBlueShirt,adidas,appStore,blackGlasses,youtubeIcon,blueNikeShoes,caelumWhite,champion,facebookIcon,girlPic,google,greenBoxArrow,greenHands,hnm,instagramIcon,nike,puma,whiteBird,makeupKit, wearRingsBracelets , watchGlasses} from "./assets";
// import {
//   Menu,
//   MenuItem,
//   MenuButton,
//   SubMenu
// } from "../../../node_modules/@szhsin/react-menu";
// import "../../../node_modules/@szhsin/react-menu/dist/index.css";
// import Divider from '../../../node_modules/@material-ui/core/Divider';
// import Avatar from  "../../../node_modules/@material-ui/core/Avatar"
// import TopNav from "./TopNav";

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };



// const links = [
//   {name:"newin"},
//   {name:"offers"},
//   {name:"earrings"},
//   {name:"jewellery sets"},
//   {name:"necklaces"},
//   {name:"bracelets & bangles"},
//   {name:"rings"},
//   {name:"hair accessories"},
//   {name:"ankles"},
//   {name:"Maangtika"},
//   {name:"Nose Rings"},
//   {name:"Brooches"},
//   {name:"Mask Chains"},
//   {name:"Charms"},
  
//         ]

// const trendingNow = [
//   { img: blueNikeShoes, name: "Footwear" },
//   { img: blackGlasses, name: "Travel" },
//   { img: makeupKit, name: "Beauty" },
//   { img: girlPic, name: "Bag" },
//   { img: ManBlueShirt, name: "Denim" },
// ];

// const collections = [
//   { img: blueNikeShoes,name:"Belts" },
//   { img: blackGlasses,name:"Bags" },
//   { img: makeupKit ,name:"Socks"},
//   { img: girlPic,name:"Wallets" },
// ];
// const brands = [
//   { img: champion },
//   { img: adidas },
//   { img: hnm },
//   { img: puma },
//   { img: nike },
// ];

// const blogs = [
//   { img: watchGlasses },
//   { img: wearRingsBracelets },
//   { img: makeupKit },
// ];

// const lastPart = [
//   { img: greenHands },
//   { img: greenBoxArrow },
//   { img: greenHands },
// ];
// const shopByPrice = [
//   {
//     color: "5px solid green",
//     image:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWDgyerc34I3Qug4658NabZOLulx8cF1wfjw&usqp=CAU",
//   },
//   {
//     color: "5px solid pink",
//     image:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWDgyerc34I3Qug4658NabZOLulx8cF1wfjw&usqp=CAU",
//   },
//   {
//     color: "5px solid yellow",
//     image:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWDgyerc34I3Qug4658NabZOLulx8cF1wfjw&usqp=CAU",
//   },
//   {
//     color: "5px solid orange",
//     image:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWDgyerc34I3Qug4658NabZOLulx8cF1wfjw&usqp=CAU",
//   },
// ];

// export interface Props {  
//   props?: any;
//   // Customizable Area Start
//   // Customizable Area End
// }

// interface S {
// }
// interface SS {

// }

// export default class CarousalBig extends Component<Props, S, SS> {
//   // state = {};

//   constructor(props: Props) {
//     super(props);
//     console.log("Home props:",props);   
//   }

//   render() {
//     const CustomDot = ( onClick: any, active: any ) => {
//       return (
//         <button
//           style={{ width: "50px",height:"5px", marginLeft: "7px",border:"none" ,borderRadius:"5px"}}
//           onClick={e => {
//             onClick();
//             e.preventDefault();
//           }}
//           className={classNames("custom-dot", {
//             "custom-dot--active": active
//           })}
//         />
//       );
//     };



  

//     return (
//       <>
//      <TopNav navigation="" id= "" props={this.props}></TopNav>
//       <div>

//  {/* black menu data start  */}
//  <Box css ={{ flexGrow: 1 }}>
//         <AppBar position="static"   style ={{backgroundColor:"#121518",height:"50px"}}>
        

//        <Box style={{justifyContent:"space-evenly",fontSize:"5px", display: 'flex', alignItems: 'center', textAlign: 'center',marginTop:"10px"}}>
      
         
     
//       { links.map(item => ( <Menu align = "center" menuStyles ={{marginTop:"18px ",width:"1520px",height:"300px",}} menuButton={ <a>  <Typography style ={{fontSize:"15px"}}  >{item.name}</Typography></a> }>
//         <div style ={{display:"flex"}}>
       
       
//         <div style={{fontSize:"15px",width:"400px",height:"100%"}} >
//             <h3 style ={{textDecorationLine:"underline",textDecorationColor:"grey"}} >NEW PRODUCTS</h3>
//             <ul style={{ listStyleType: "none" ,marginLeft:"97px"}}>
//               <li style ={{textAlign:"left"}}>
//                 <a style={{ textDecoration: "none",color:"grey"}} href="#">
//                  Ankles
//                 </a>
//               </li>
//               <li style ={{textAlign:"left"}}>
//                 <a style={{ textDecoration: "none",color:"grey"}} href="#">
//                 Maangtika
//                 </a>
//               </li>
//               <li style ={{textAlign:"left"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                   nose rings
//                 </a>
//               </li>
//               <li style ={{textAlign:"left"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                   Chains
//                 </a>
//               </li>
//               <li style ={{textAlign:"left"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                   Brooches
//                 </a>
//               </li>
//             </ul>
//           </div> 
// <div>
// {/* first  divider  */}
// <Divider orientation= "vertical"/>
// </div> 

//           <div style={{fontSize:"15px",width:"400px",height:"100%"}} >
//           <h3  style ={{marginRight:"179px",textDecorationLine:"underline",textDecorationColor:"grey"}} >SHOP BY EDITS</h3>
//             <ul style={{ listStyleType: "none" ,height:"207px",marginRight:"20px",display:"flex",flexDirection:"column",justifyContent:"space-evenly"}}>
//             <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar/>
//                 </a> <span style = {{marginLeft:"10px"}}>Summer Wear</span>
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar/>
//                 </a> <span  style = {{marginLeft:"10px"}}>Beach Wear</span>
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar/>
//                 </a> <span  style = {{marginLeft:"10px"}}>Summer Glasses</span>
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar/>
//                 </a> <span  style = {{marginLeft:"10px"}}>Shorts</span>
               
//               </li>
             
//             </ul>
//           </div>


// <div>
// {/*second  divider  */}
// <Divider orientation= "vertical"/>
// </div> 

//           <div style={{width:"400px",height:"100%",fontSize:"15px"}} >
//           <h3  style ={{marginRight:"179px",textDecorationLine:"underline",textDecorationColor:"grey"}}>SHOP BY BRANDS</h3>
//             <ul style={{alignContent:"space-between", listStyleType: "none" ,justifyContent:"space-between",marginRight:"20px" ,display:"flex",flexWrap:"wrap"}}>
//             <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar src = {hnm} style = {{width:"110px",height:"90px"}}/>  <span >H&M</span>
//                 </a>
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar src = {adidas} style = {{width:"110px",height:"90px"}}/> <span >Adidas</span>
//                 </a>
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar src = {puma} style = {{width:"110px",height:"90px"}}/><span  >Puma</span>
//                 </a> 
               
//               </li>
//               <li style ={{display:"flex",alignItems:"center"}}>
//                 <a style={{ textDecoration: "none",color:"grey" }} href="#">
//                <Avatar  src = {nike} style = {{width:"110px",height:"100px"}}/><span >Nike</span>
//                 </a> 
               
//               </li>
             
//             </ul>
//           </div>

//           <div>
// {/*third  divider  */}
// <Divider orientation= "vertical"/></div>

// <div style={{width:"400px",height:"100%"}} >
//   <img  style = {{width:"280px",height:"280px",margin:"auto auto"}}  src = { watchGlasses}/>
//            </div>

       
//         </div>
//        </Menu> ))}   
//         </Box>
    
          
//           </AppBar>
          
//         </Box>
//          {/* black menu data end  */}







  
//         {/* carousal start   */}
//         <div
//           style={{
//             paddingBottom: "30px",
//             position: "relative",
//             width:"100%"
//           }}
//         >
//           <Carousel
//             renderDotsOutside
//             responsive={responsive}
//             infinite={true}
//             customDot={<CustomDot />}
//             // autoPlay={this.props.deviceType !== "mobile" ? true : false}
//             autoPlay={true}
//             autoPlaySpeed={2000}
//             showDots={true}
//             removeArrowOnDeviceType={["tablet", "desktop"]}
//           >
//             <div style={carousalStyleImageConatiner}>
//               {" "}
//               <img
//                 style={{ width: "100%" }}
//                 src="https://m.media-amazon.com/images/I/71ePd6+9HBL._SX3000_.jpg"
//               />
//             </div>
//             <div style={carousalStyleImageConatiner}>
//               {" "}
//               <img
//                 style={{ width: "100%" }}
//                 src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg"
//               />
//             </div>
//             <div style={carousalStyleImageConatiner}>
//               <img
//                 style={{ width: "100%" }}
//                 src="https://m.media-amazon.com/images/I/71ePd6+9HBL._SX3000_.jpg"
//               />
//             </div>
//             <div style={carousalStyleImageConatiner}>
//               {" "}
//               <img
//                 style={{ width: "100%" }}
//                 src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg"
//               />
//             </div>
//           </Carousel>
//         </div>
//         {/* carousal end 

//         {/* trending now start  */}
//         <div style ={{width:"100%"}}>
//           <Typography
//             variant="h4"
//             style={{
//               color: "black",
//               fontFamily: "'Raleway',sans-serif",
//               textAlign:"center",
//               marginBottom: "10px",
//               marginTop: "10px"}}
//           >
//             Trending Now
//           </Typography>
//           <Box
//             id="viewAllBox"
//             style={{ display: "flex", justifyContent: "center" }}
//           >
//             {trendingNow.map((item) => {
//               return (
//                 <div
//                   key={item.img}
//                   style={{
//                     borderRadius: "0px",
//                     boxShadow: "none",
//                     marginLeft: "20px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       position: "relative",
//                       height: "300px",
//                       width: "250px",
//                     }}
//                   >
//                     <img
//                       style={{ height: "290px", width: "250px" }}
//                       id="viewAllImg"
//                       src={item.img}
//                     />
//                   </div>
//                   <Typography
//                     variant="h6"
//                     style={{
//                       color: "black",
//                       fontFamily: "'Raleway',sans-serif",
//                       marginLeft: "70px",
//                     }}
//                   >
//                     {item.name}
//                   </Typography>
//                 </div>
//               );
//             })}
//           </Box>
//         </div>

//         {/* trending now end   */}

//         {/* collections start  */}
//         <div style ={{paddingTop:"200px"}}>
// <div style ={{height:"300px",backgroundColor:"Black"}}>
// <div style ={{position:"relative",bottom:"200px"}}>
//     <Typography variant="h4" style={{ color: "black", fontFamily: "'Raleway',sans-serif", textAlign:"center",marginBottom:"10px",marginTop:"10px" }}>
//                                                             Collections
//                                                            </Typography>
//          <Box id = "viewAllBox" style = {{display:"flex",  justifyContent:"space-around",margin:"20px 80px"}} >
                                        
//                                         {collections.map((item) => {
//                                                 return (
                                            
                                          
//                                         <Card  key={item.img} style ={{borderRadius:"0px",boxShadow:"none"}}>
                                           
//                                                          <div style={{position: "relative",height:"300px",width:"300px"}}>
//                                                             <img style ={{height:"300px",width:"300px"}} id = "viewAllImg" src={item.img} />
//                                                             <button style ={{position:"relative",bottom:"40px",left:"50px",fontSize:"30px",width:"200px",height:"40px",border:"none"}}> {item.name}</button>
                                                          
//                                                          </div>
                                                           
                                                           
//                                                     </Card>
                                              
//                                                 )
//                                             })}
//                                             </Box>
//                                         </div>
//                                             </div>
//                                             </div>                        
//         {/* collection end  */}

//         {/* brands start  */}
//         <div style ={{margin:"10px auto"}}>
//         <Typography variant="h4" style={{ color: "black", fontFamily: "'Raleway',sans-serif", textAlign:"center",marginBottom:"10px",marginTop:"10px"}}>
//                                                            Top Brands
//                                                            </Typography>
//            <Box id = "viewAllBox" style = {{display:"flex",  justifyContent:"flex-start",marginLeft:"38px"}} >
                                        
//                                         {brands.map((item) => {
//                                                 return (
                                            
                                          
//                                         <Card  key={item.img} style ={{borderRadius:"0px",boxShadow:"none",marginLeft:"60px",backgroundColor:"#e8e4e3"}}>
                                           
//                                                          <div style={{position: "relative"}}>
//                                                             <img id = "viewAllImg" src={item.img} style ={{width:"180px",height:"180px",margin:"20px 20px 20px 20px"}}/>
                                                          
                                                          
//                                                          </div>
//                                                             <Typography variant="h6" style={{ color: "black", fontFamily: "'Raleway',sans-serif", textAlign:"center",}}>
//                                                             Upto 50% Off 
//                                                            </Typography>
                                                           
//                                                     </Card>
                                              
//                                                 )
//                                             })}
//                                             </Box>
//                                             </div>
//         {/* brands end  */}

//         {/* shop by price starts  */}
//         <div style ={{ margin: '41px auto',width:"auto"}}>
// <Typography  variant="h4" style={{ color: "black", fontFamily: "'Raleway',sans-serif", textAlign:"center",marginBottom:"10px" }}>
//                                                             Shop By Price
//                                                            </Typography>
// <div  style ={{display:"flex",flexWrap:"wrap"}}>


//    {shopByPrice.map(items => (
//     <div
//           style={{
//             height: "262px",
//             width: "40%",
//             border: items.color,
//             display: "flex",
//             marginLeft:"100px",
//             marginTop:"30px"
//           }}
//         >
//           <div
//             style={{
//               height: "250px",
//               width: "250px",
//               border: items.color,
//               margin: "1px 1px 1px 1px",
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGMSLFppLq5TnzAL7LlqbWXaykFcxW50FcZw&usqp=CAU"
//             />
//              <button style ={{position:"relative",bottom:"45px",left:"38px",height:"28px",width:"100px"}}>SHOP NOW</button>
//           </div>
//           <div
//             style={{
//               height: "100",
//               width: "100%",
//               borderLeft: items.color,
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfaDvfL2dvP5RjwNJqiEgdi4McFW2c_JTS0Q&usqp=CAU"
//             />
//           </div>
//         </div>
//    ))}     
        

// </div>

// </div>
// {/* shop by price end  */}

//         {/* Card start */}
//         <div
//           style={{
//             height: "260px",
//             width: "1307px",
           
//             display: "flex",
//             margin: '50px auto'
//           }}
//         >
//           <div
//             style={{
//               height: "250px",
//               width: "900px",
             
//               margin: "1px 1px 1px 1px",
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Laptops/Jupiter/Category/D29756085_IN_OP_Jup21-graphics_P1_1500x300_22.jpg"
//             />
        
//           </div>
//           <div
//             style={{
//               height: "250px",
//               width: "400px",
       
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://m.media-amazon.com/images/G/31/img19/AMS/Houseads/Laptops-Sept2019._CB436595915_.jpg"
//             />
//              <a style ={{fontWeight:"bold",textDecoration:"none",color:"Black",position:"relative",bottom:"25px",left:"50px"}}>+ Wishlist</a>
//           </div>
//         </div> 

//          {/* card end  */}

//         {/* blogs start  */}
//         <Typography variant="h4" style={{ color: "black", fontFamily: "'Raleway',sans-serif", textAlign:"center", }}>
//                                                             Blogs
//                                                            </Typography>
//            <Box id = "viewAllBox" style = {{display:"flex",justifyContent:"space-between", margin: "20px 130px 10px 50px"}} >
                                        
//                                         {blogs.map((item) => {
//                                                 return (
                                            
                                          
//                                         <Card  key={item.img} style ={{borderRadius:"0px",boxShadow:"none",marginLeft:"50px"}}>
                                           
//                                                          <div style={{position: "relative",height:"350px",width:"350px"}}>
//                                                             <img style ={{height:"340px",width:"350px"}} id = "viewAllImg" src={item.img} />
                                                          
                                                          
//                                                          </div>
//                                                             <Typography variant="h6" style={{ color: "black", fontFamily: "'Raleway',sans-serif" }}>
//                                                             Lorem ipsum dolor
//                                                            </Typography>
                                                           
//                                                     </Card>
                                              
//                                                 )
//                                             })}
//                                             </Box>

//         {/* blogs end  */}

//         {/* Card start */}
//         <div
//           style={{
//             height: "260px",
//             width: "1307px",
           
//             display: "flex",
//             margin: '50px auto'
//           }}
//         >
//           <div
//             style={{
//               height: "250px",
//               width: "900px",
             
//               margin: "1px 1px 1px 1px",
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Laptops/Jupiter/Category/D29756085_IN_OP_Jup21-graphics_P1_1500x300_22.jpg"
//             />
           
//           </div>
//           <div
//             style={{
//               height: "250px",
//               width: "400px",
       
//             }}
//           >
//             <img
//               style={{ width: "100%", height: "100%" }}
//               src="https://m.media-amazon.com/images/G/31/img19/AMS/Houseads/Laptops-Sept2019._CB436595915_.jpg"
//             />
//              <a style ={{fontWeight:"bold",textDecoration:"none",color:"Black",position:"relative",bottom:"25px",left:"50px"}}>+ Explore</a>
//           </div>
//         </div>

//         {/* card end  */}

//         {/* last part  start*/}
//         <Box id = "viewAllBox" style = {{display:"flex",justifyContent:"space-evenly",marginTop:"25px"}} >
                                        
//                                         {lastPart.map((item) => {
//                                                 return (
                                            
                                          
//                                         <Card  key={item.img} style ={{borderRadius:"0px",boxShadow:"none",marginLeft:"50px"}}>
                                           
//                                                          <div>
//                                                             <img  id = "viewAllImg" src={item.img} />
                                                          
                                                          
//                                                          </div>
//                                                             <Typography variant="h6" style={{ color: "black", fontFamily: "'Raleway',sans-serif"}}>
//                                                           Authentic
//                                                            </Typography>
                                                           
//                                                     </Card>
                                              
//                                                 )
//                                             })}
//                                             </Box>

//         {/* last part end  */}

//         {/* footer start  */}
//         <div style ={{marginTop:"30px"}}>

//         {/* top part start  */}

//         <div
//           style={{
//             height: "200px",
//             backgroundColor: "#121518",
//             display: "flex",
//             justifyContent: "space-evenly",
//           }}
//         >
//           <div style={{ paddingTop: "50px" }}>
//             <img src={caelumWhite} />
//             <ul style={{ paddingLeft: "0px", listStyleType: "none" }}>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   About Us
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Contact Us
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <div style={{ paddingTop: "20px" }}>
//             <h4 style={{ color: "white" }}>help </h4>
//             <ul style={{ listStyleType: "none" ,paddingLeft:"0px" }}>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Hipping And Return Policy
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Terms & conditions
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Privacy Policy
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <div style={{ paddingTop: "20px" }}>
//             <h4 style={{ color: "white" }}>Useful Links </h4>
//             <ul style={{ listStyleType: "none",paddingLeft:"0px" }}>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Men
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Women
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Tech&Travel
//                 </a>
//               </li>
//             </ul  >
//           </div>

//           <div style={{ paddingTop: "20px", marginTop: "50px" }}>
           
//             <ul style={{ listStyleType: "none" ,paddingLeft:"0px"}}>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   LifeStyle
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Blogs
//                 </a>
//               </li>
//               <li>
//                 <a style={{ textDecoration: "none", color: "white" }} href="#">
//                   Shopping With Friends
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <div style={{ paddingTop: "20px", height: "50px" }}>
//             <div>
//               <h4 style={{ color: "white", marginBottom: "10px" }}>
//                 Download on mobile
//               </h4>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
               
//                 <img style={{ width: "100px" }} src={google} />
//               </a>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
               
//                 <img style={{ width: "100px" }} src={appStore} />
//               </a>
//             </div>

//             <div>
//               <h4 style={{ color: "white", marginBottom: "0px" }}>
//                 Keep In Touch
//               </h4>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
               
//                 <img src={facebookIcon} />
//               </a>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
               
//                 <img src={instagramIcon} />
//               </a>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
              
//                 <img src={whiteBird} />
//               </a>
//               <a style={{ textDecoration: "none" }} href="www.google.com">
               
//                 <img src={youtubeIcon} />
//               </a>
//             </div>
//           </div>
//         </div>

//         {/* top part end  */}

//         {/* middlePart */}

//       <div  style ={{
//     margin: "20px 100px"
// }}>   Nostrud tempor laborum laboris non nostrud et adipisicing esse irure enim ex culpa non. Commodo sit veniam fugiat fugiat minim sunt incididunt irure. Commodo est veniam anim tempor.<br/>
//         Amet consequat do dolore exercitation do ad laboris consequat dolor ipsum laboris. Incididunt aliqua anim culpa non id. Ullamco eu proident et voluptate sunt esse cillum reprehenderit aliqua Lorem consequat ex.<br/>

// Sunt in fugiat minim adipisicing sunt elit sint amet dolor dolor do. Mollit irure consectetur consectetur magna eu exercitation velit fugiat cillum cupidatat nulla cupidatat proident. Proident sit quis laboris quis culpa proident. Sit sunt adipisicing tempor voluptate occaecat. Officia in officia quis ullamco Lorem fugiat veniam. Consectetur minim nulla sint proident aliquip qui amet nulla enim consectetur irure esse. Occaecat nulla occaecat est duis ipsum dolore fugiat mollit laborum duis dolor.<br/>

// Ex excepteur irure qui aliqua voluptate. Non irure consectetur mollit dolor eiusmod adipisicing ea pariatur Lorem culpa ipsum fugiat duis. Esse commodo adipisicing laboris exercitation ea nisi dolor quis esse commodo aliqua.<br/>



// Sunt in fugiat minim adipisicing sunt elit sint amet dolor dolor do. Mollit irure consectetur consectetur magna eu exercitation velit fugiat cillum cupidatat nulla cupidatat proident. Proident sit quis laboris quis culpa proident. Sit sunt adipisicing tempor voluptate occaecat. Officia in officia quis ullamco Lorem fugiat veniam. Consectetur minim nulla sint proident aliquip qui amet nulla enim consectetur irure esse. Occaecat nulla occaecat est duis ipsum dolore fugiat mollit laborum duis dolor.<br/>



// Sunt in fugiat minim adipisicing sunt elit sint amet dolor dolor do. Mollit irure consectetur consectetur magna eu exercitation velit fugiat cillum cupidatat nulla cupidatat proident. Proident sit quis laboris quis culpa proident. Sit sunt adipisicing tempor voluptate occaecat. Officia in officia quis ullamco Lorem fugiat veniam. Consectetur minim nulla sint proident aliquip qui amet nulla enim consectetur irure esse. Occaecat nulla occaecat est duis ipsum dolore fugiat mollit laborum duis dolor.<br/>


// Ex excepteur irure qui aliqua voluptate. Non irure consectetur mollit dolor eiusmod adipisicing ea pariatur Lorem culpa ipsum fugiat duis. Esse commodo adipisicing laboris exercitation ea nisi dolor quis esse commodo aliqua.<br/> 
 
// </div>
//         {/* middle part end  */}

//         {/* bottom part  start */}

//         <AppBar
//           position="static"
//           style={{
//             background: "#121518",
//             height: "50px",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Typography variant="subtitle2">
//             © 2021 CEALUM ALL RIGHTS RESERVED
//           </Typography>
//         </AppBar>
//         {/* bottom part end  */}
        
//         {/* footer end   */}
//         </div>





//         </div>
//       </>
//     );
//   }
// }

// // styling
// const carousalStyleImageConatiner = { height: "250px" };


import React, { Component, SetStateAction } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classNames from "classnames";
import "./HomePage.Web.css";
// trending now imports

import { Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import YouTubeIcon from "@material-ui/icons/YouTube";
import {bagIcon, boxIcon, ManBlueShirt,balanceIcon,adidas,blackGlasses,caelumBlack,championItem,payoutIcon,salesIcon,search,truckIcon,youtubeIcon,heartIcon,appStore,blueNikeShoes,caelumWhite,champion,facebookIcon,girlPic,google,greenBoxArrow,greenHands,hnm,instagramIcon,nike,puma,whiteBird,makeupKit, wearRingsBracelets , watchGlasses} from "./assets";
// import blueNikeShoes from "../../components/assets/blueNikeShoes.png";
// import blackGlasses from "../../components/assets/blackGlasses.png";
// import makeupKit from "../../components/assets/makeupKit.png"
// import girlPic from "../../components/assets/girlPic.png";
// import adidas from "../../components/assets/adidas.png";
// import hnm from "../../components/assets/hnm.png";
// import puma from "../../components/assets/puma.png";
// import champion from "../../components/assets/champion.png";
// import nike from "../../components/assets/nike.png";
// import ManBlueShirt from "../../components/assets/ManBlueShirt.png";

// footer imports
// import google from "../../components/assets/googlePlay.png";
// import appStore from "../../components/assets/appStore.png";
// import whiteBird from "../../components/assets/whiteBird.png";
// import youtubeIcon from "../../components/assets/youtubeIcon.png";
// import facebookIcon from "../../components/assets/facebookIcon.png";
// import instagramIcon from "../../components/assets/instagramIcon.png";
// import caelumWhite from "../../components/assets/caelumWhite.png";
// import { Toolbar, AppBar } from "@material-ui/core";
import { Card, Container, Typography } from "@material-ui/core";
// blogs import
// import watchGlasses from "../../components/assets/watchGlasses.png";
// import wearRingsBracelets from "../../components/assets/wearRingsBracelets.png";
// import makeupKit from "../../components/assets/makeupKit.png";

// import greenHands from "../../components/assets/greenHands.png";
// import greenBoxArrow from "../../components/assets/greenBoxArrow.png";
import Select from '@material-ui/core/Select';
import MenuItems from '@material-ui/core/MenuItem';
// import championItem from "../assets/champion-item.png";
// import bagIcon  from "../assets/bag-icon.svg";
// import balanceIcon  from "../assets/balance-icon.svg";
// import boxIcon  from "../assets/box-icon.svg"
// import payoutIcon  from "../assets/payout-icon.svg";
// import salesIcon  from "../assets/sales-icon.svg";
// import truckIcon  from "../assets/truck-icon.svg";

// carousal imports

// account imports
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "../../../node_modules/@szhsin/react-menu";
import "../../../node_modules/@szhsin/react-menu/dist/index.css";
import Divider from "../../../node_modules/@material-ui/core/Divider";
// import watchGlasses from "../../components/assets/watchGlasses.png";
import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const bannerSliderresponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const trendingnowResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
const collectionResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
const topBrandsResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
const styles: any = {
  pageOuter: {
    fontFamily: "'Roboto',sans-serif",
    "& .title-block": {
      textAlign: "center",
      fontWeight: "500",
      color: "#444",
      fontSize: "32px",
    },
    "& .row-wrap": {
      margin: "0 -8px",
    },
    "& .row-wrap .column": {
      padding: "0 8px",
      height: "100%",
    },
    "& .column .inner-col": {
      color: "#444",
      display: "block",
      height: "100%",
    },
    "& .column .img-block": {
      margin: "0 0 10px",
    },
    "& .column .img-block img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& .column .col-name": {
      color: "#444",
      fontSize: "24px",
      textAlign: "center",
    },
  },
  trendingNow: {
    padding: "20px 0",
    "& .column .img-block": {
      height: "364px",
    },
  },
  collection: {
    padding: "20px 0",
    position: "relative",
    "& .row-wrap": {
      position: "relative",
      zIndex: "1",
    },
    "& .column .inner-col": {
      position: "relative",
    },
    "& .column .img-block": {
      height: "280px",
    },
    "& .column .col-name": {
      fontSize: "28px",
      position: "absolute",
      bottom: "10px",
      padding: "10px",
      margin: "0 32px",
      left: "0",
      right: "0",
      backgroundColor: "#fff",
    },
    "& .black-bg": {
      position: "relative",
      height: "280px",
      backgroundColor: "#2d2d2d",
      marginTop: "-150px",
    },
  },
  TopBrands: {
    padding: "20px 0",
    "& .column .inner-col": {
      backgroundColor: "#f6f6f6",
      padding: "30px 25px",
    },
    "& .column .img-block": {
      height: "200px",
      marginBottom: "20px",
    },
    "& .column .img-block img": {
      objectFit: "contain",
    },
  },
  shopByPrice: {
    padding: "20px 0",
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .row-wrap .column": {
      flex: "0 0 50%",
      maxWidth: "50%",
      padding: "10px",
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .column .inner-col": {
      display: "flex",
      border: "5px solid",
    },
    "& .column .inner-col .left-block": {
      maxWidth: "170px",
      flex: "0 0 170px",
      border: "5px solid",
      borderColor: "inherit",
      textAlign: "center",
      padding: "1px",
      "@media (max-width:480px)": {
        maxWidth: "130px",
        flex: "0 0 130px",
      },
    },
    "& .column .img-block": {
      height: "250px",
      marginBottom: "0",
      maxWidth: "calc(100% - 170px)",
      flex: "0 0 calc(100% - 170px)",
      "@media (max-width:480px)": {
        height: "198px",
        maxWidth: "calc(100% - 130px)",
        flex: "0 0 calc(100% - 130px)",
      },
    },
    "& .column .inner-col .left-block .inner-left-block": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      height: "100%",
      border: "5px solid",
      borderColor: "inherit",
    },
    "& .column .inner-col .left-block .discount": {
      display: "block",
      fontSize: "30px",
      lineHeight: "34px",
      textTransform: "uppercase",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "12px",
      "@media (max-width:480px)": {
        fontSize: "24px",
        lineHeight: "28px",
      },
    },
    "& .column .inner-col .left-block .shop-now": {
      display: "block",
      fontSize: "16px",
      textTransform: "uppercase",
      color: "#444",
      backgroundColor: "#fff",
      padding: "8px",
      boxShadow: "0 0 1px 1px #eee",
      "@media (max-width:480px)": {
        fontSize: "14px",
        padding: "5px",
      },
    },
    "& .column.green-border .inner-col": {
      borderColor: "#bdcd8f",
    },
    "& .column.red-border .inner-col": {
      borderColor: "#fa7167",
    },
    "& .column.yellow-border .inner-col": {
      borderColor: "#feca39",
    },
    "& .column.orange-border .inner-col": {
      borderColor: "#f69847",
    },
    "& .column.green-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#fb7d8b",
    },
    "& .column.red-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#81d478",
    },
    "& .column.yellow-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#d53f41",
    },
    "& .column.orange-border .inner-col .left-block .inner-left-block": {
      backgroundColor: "#7891d4",
    },
  },
  saleBanner: {
    padding: "20px 0",
    "& .row-wrap": {
      display: "flex",
      flexWrap: "wrap",
      margin: "0",
    },
    "& .row-wrap .left-block": {
      flex: "0 0 calc(100% - 450px)",
      maxWidth: "calc(100% - 450px)",
      "@media (max-width:1024px)": {
        flex: "0 0 calc(100% - 300px)",
        maxWidth: "calc(100% - 300px)",
      },
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
        height: "300px",
      },
    },
    "& .row-wrap .left-block img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    "& .row-wrap .right-block": {
      flex: "0 0 450px",
      maxWidth: "450px",
      height: "350px",
      backgroundColor: "#142551",
      padding: "20px 40px",
      "@media (max-width:1024px)": {
        flex: "0 0 300px",
        maxWidth: "300px",
        height: "300px",
        padding: "20px",
      },
      "@media (max-width:767px)": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    "& .row-wrap .right-block .right-inner": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      color: "#fff",
      height: "100%",
    },
    "& .row-wrap .right-block .right-inner .date": {
      textTransform: "uppercase",
    },
    "& .row-wrap .right-block .right-inner .product-name": {
      fontStyle: "italic",
      fontSize: "24px",
      marginBottom: "10px",
      "@media (max-width:1024px)": {
        fontSize: "20px",
      },
    },
    "& .row-wrap .right-block .right-inner .sale-discount": {
      fontSize: "30px",
      marginBottom: "10px",
      "@media (max-width:1024px)": {
        fontSize: "28px",
      },
    },
    "& .row-wrap .right-block .wishlist-now": {
      display: "block",
      color: "#fff",
      textTransform: "uppercase",
    },
  },
  bannerSlider: {
    "& .row-wrap": {
      position: "relative",
      paddingBottom: "40px",
      "@media (max-width:767px)": {
        paddingBottom: "30px",
      },
    },
    "& .slides": {
      minHeight: "400px",
      "@media (max-width:1024px)": {
        minHeight: "300px",
      },
      "@media (max-width:767px)": {
        minHeight: "250px",
      },
    },
    "& .slides img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      "@media (max-width:767px)": {
        minHeight: "250px",
        objectFit: "cover",
      },
    },
    "& .custom-dot": {
      height: "5px",
      border: "none",
      borderRadius: "5px",
      margin: "5px",
      maxWidth: "50px",
      width: "100%",
      "@media (max-width:575px)": {
        maxWidth: "30px",
        height: "4px",
      },
    },
    "& .custom-dot--active": {
      transform: "scale(1)",
    },
  },
  blogs: {
    padding: "20px 0",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      height: "100%",
      width: "100%",
      maxHeight: "300px",
      objectFit: "cover",
      "@media(max-width:599px)": {
        maxHeight: "250px",
      },
    },
    "& .col-name": {
      color: "#444",
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "'Roboto',sans-serif",
      marginTop: "10px",
    },
    "& .btn-wrapper": {
      display: "flex",
      justifyContent: "center",
      marginTop: "40px",
    },
    "& .outline-btn": {
      minWidth: "200px",
    },
  },
  offerBlock: {
    padding: "20px 0",
    fontFamily: "'Roboto',sans-serif",
    "& .img-block img": {
      width: "100%",
      height: "250px",
      objectFit: "cover",
    },
    "& h2": {
      color: "#444",
      fontSize: "36px",
      linHeight: "42px",
      fontWeight: "400",
      fontFamily: "'Roboto',sans-serif",
      margin: "0 0 10px 0",
    },
    "& .more-info": {
      paddingBottom: "20px",
      marginBottom: "10px",
      display: "block",
      borderBottom: "1px solid #eee",
    },
    "& .explore-link": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      color: "#666",
    },
    "& .content-block": {
      padding: "50px 20px 20px",
      "@media (max-width:599px)": {
        padding: "0 20px 50px",
      },
    },
    "& .content-block br": {
      "@media (max-width:599px)": {
        display: "none",
      },
    },
  },
  VendorPageOuter: {
    backgroundColor: "#ffffff",
    padding: '41px 0 85px', 
    "& .container-full": {
      padding: "0 55px",
      "@media (max-width:1366px)" :{
        padding: "0 30px",
      },
      "@media (max-width:600px)" :{
        padding: "0 16px",
      },
    },  
    "& .top-heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "12px",
      minHeight: "40px",
      "@media (max-width:991px)" :{
        minHeight: "unset",
      },
    },    
    "& .top-heading .heading-left": {
      display: "flex",
      alignItems: "center",        
      flexWrap: "wrap",
      "@media (max-width:767px)": {
        width: "100%",
        marginBottom: "10px",
      },
    }, 
    "& .top-heading .select-border": {
      marginRight: "0",
      minWidth: "230px",
    },
    "& .heading-title": {
      fontSize: "20px",
      lineHeight: "24px",
      color: "#121212",
      fontWeight: "600",
      margin: "0",
      "@media (max-width: 1199px)": {
        fontSize: "18px",
        lineHeight: "22px",
      },
    },
    "& .card-main": {
      margin: "0 -8px",
      marginBottom: "55px",
      width: "auto",
      maxWidth: "unset",
      "@media (max-width: 1199px)": {
        marginBottom: "25px",
      },
    },
    "& .card-item": {
      padding: "0 8px",
      marginBottom: "19px",
      "@media (max-width: 767px)": {
        marginBottom: "12px",
      }, 
    },
    "& .card-item .card": {
      padding: "28px 20px 31px 27px",
      background: "#f6f6f6",  
      borderRadius: "5px",  
      "@media (max-width: 1280px)": {
        padding: "20px 15px",
      }, 
      "@media (max-width: 767px)": {
        padding: "15px 12px",
      }, 
    },
    "& .card-item .card-heading": {
      display: "flex",
      alignItems: "center",        
      flexWrap: "wrap",
      marginBottom: "46px",
      "@media (max-width: 767px)": {
        marginBottom: "30px",
      }, 
    },
    "& .card-item .card-icon": {
      marginRight: "15px",
      "@media (max-width: 1280px)": {
        marginRight: "10px",
      }, 
    },
    "& .card-item .card-icon img": {
      height: "30px",
      "@media (max-width: 1199px)": {
        height: "25px",
      },
    },
    "& .card-item .card-title": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "500",
      flex: "1",
      "@media (max-width: 1199px)": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    "& .card-item .card-price": {
      fontSize: "36px",
      lineHeight: "40px",
      color: "#ff406c",
      fontWeight: "600",
      "@media (max-width: 1199px)": {
        fontSize: "30px",
        lineHeight: "34px",
      },
      "@media (max-width: 767px)": {
        fontSize: "26px",
        lineHeight: "30px",
      },
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
    "& .productlist-wrapper .product-name": {
      fontSize: "16px",
      lineHeight: "20px",
      "@media (max-width: 1199px)": {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    "& .productlist-wrapper .product-list .product-detail": {      
      paddingLeft: "10px",
    },
    "& .productlist-wrapper .product-list .product-img": {
      width: "90px",
      height: "90px",
      borderRadius: "5px",
      overflow: "hidden",
      "@media (max-width: 767px)": {
        width: "60px",
        height: "60px",
      },
    },
    "& .productlist-wrapper .product-heading": {     
      marginBottom: "14px",
      "@media (max-width: 767px)": {
       flexWrap: "wrap",
      },
    },
    "& .productlist-wrapper .product-qty": {
      "@media (max-width: 767px)": {
        width: "100%",
        marginTop: "10px",
       },
    },
    "& .productlist-wrapper .product-list": {
      padding: "10px",
      marginBottom: "18px",
      borderRadius: "5px",
    },
    "& .productlist-wrapper .product-desc": {
      marginBottom: "18px",
    },
    "& .vendor-grid": {
      margin: "0 -30px",
      width: "auto",
      "@media (max-width:1366px)" :{
        margin: "0 -15px",
      },
    },
    "& .vendor-grid .vendor-leftcol": {
      padding: "0 30px",
      borderRight: "1px solid #f4f4f4",
      maxWidth: "52%",
      flexBasis: "52%",
      "@media (max-width:1366px)" :{
        padding: "0 15px",
      },
      "@media (max-width:991px)" :{
        maxWidth: "100%",
        flexBasis: "100%",
        borderRight: "0",
      },
    },
    "& .vendor-grid .vendor-rightcol": {
      padding: "0 30px",      
      borderRight: "0",
      maxWidth: "48%",
      flexBasis: "48%",
      "@media (max-width:1366px)" :{
        padding: "0 15px",
      },
      "@media (max-width:991px)" :{
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "& .vendor-grid .vendor-leftcol .top-heading": {
      marginBottom: "24px",
      "@media (max-width:767px)" :{
        marginBottom: "0",
      },
    },
    "& .card-item .lightgreen-txt": {
      color: "#21c998",
    },
    "& .card-item .orange-txt": {
      color: "#ff8030",
    },
    "& .card-item .navyblue-txt": {
      color: "#517ba3",
    },
    "& .card-item .purple-txt": {
      color: "#cf20ff",
    },
  },
};

export interface Props {  
  classes: any;
  props?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
}
interface SS {

}

export class CarousalBig extends Component<Props,S,SS> {
  state = {};

    constructor(props: Props) {
      super(props);
   
    }

  render() {
    const { classes } = this.props;
    const CustomDot = ( onClick: any, active: any ) => {
      return (
        <button
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          className={classNames("custom-dot", {
            "custom-dot--active": active,
          })}
        />
      );
    };

    return (
      <>
   {( localStorage.getItem('role') !== 'vendor') &&  (   <div className={classes.pageOuter}>
        {/* banner slider */}
        <div className={classes.bannerSlider}>
          <div className="outer-wrap">
            <div className="row-wrap">
              <Carousel
                renderDotsOutside
                responsive={bannerSliderresponsive}
                infinite={true}
                customDot={<CustomDot />}
                autoPlay={true}
                autoPlaySpeed={2000}
                showDots={true}
                removeArrowOnDeviceType={["tablet", "desktop"]}
              >
                <div className="slides">
                  <img src="https://m.media-amazon.com/images/I/71ePd6+9HBL._SX3000_.jpg" />
                </div>
                <div className="slides">
                  <img src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg" />
                </div>
                <div className="slides">
                  <img src="https://m.media-amazon.com/images/I/71ePd6+9HBL._SX3000_.jpg" />
                </div>
                <div className="slides">
                  <img src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        {/* banner slider ends */}

        {/* trending now */}
        <div className={classes.trendingNow}>
          <Container maxWidth="lg">
            <div className="outer-wrap">
              <h2 className="title-block">Trending Now</h2>
              <div className="row-wrap">
                <Carousel
                  renderDotsOutside
                  responsive={trendingnowResponsive}
                  showDots={false}
                >
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={blueNikeShoes} />
                      </div>
                      <div className="col-name">
                        <span>Footwear</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={blackGlasses} />
                      </div>
                      <div className="col-name">
                        <span>Travel</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={makeupKit} />
                      </div>
                      <div className="col-name">
                        <span>Beauty</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={girlPic} />
                      </div>
                      <div className="col-name">
                        <span>Bag</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={ManBlueShirt} />
                      </div>
                      <div className="col-name">
                        <span>Denim</span>
                      </div>
                    </Link>
                  </div>
                </Carousel>
              </div>
            </div>
          </Container>
        </div>
        {/* trending now ends */}

        {/* collection */}
        <div className={classes.collection}>
          <Container maxWidth="lg">
            <div className="outer-wrap">
              <h2 className="title-block">Collection</h2>
              <div className="row-wrap">
                <Carousel
                  renderDotsOutside
                  responsive={collectionResponsive}
                  showDots={false}
                >
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={blueNikeShoes} />
                      </div>
                      <div className="col-name">
                        <span>Belts</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={blackGlasses} />
                      </div>
                      <div className="col-name">
                        <span>Bags</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={makeupKit} />
                      </div>
                      <div className="col-name">
                        <span>Socks</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={girlPic} />
                      </div>
                      <div className="col-name">
                        <span>Wallets</span>
                      </div>
                    </Link>
                  </div>
                </Carousel>
              </div>
            </div>
          </Container>
          <div className="black-bg" />
        </div>
        {/* collection ends */}

        {/* top brand now */}
        <div className={classes.TopBrands}>
          <Container maxWidth="lg">
            <div className="outer-wrap">
              <h2 className="title-block">Top Brands</h2>
              <div className="row-wrap">
                <Carousel
                  renderDotsOutside
                  responsive={topBrandsResponsive}
                  showDots={false}
                >
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={champion} />
                      </div>
                      <div className="col-name">
                        <span>Up To 50% Off</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={adidas} />
                      </div>
                      <div className="col-name">
                        <span>30-60% Off</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={hnm} />
                      </div>
                      <div className="col-name">
                        <span>Min.50% Off</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={puma} />
                      </div>
                      <div className="col-name">
                        <span>30-50% Off</span>
                      </div>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="inner-col">
                      <div className="img-block">
                        <img src={nike} />
                      </div>
                      <div className="col-name">
                        <span>Min.30% Off</span>
                      </div>
                    </Link>
                  </div>
                </Carousel>
              </div>
            </div>
          </Container>
        </div>
        {/* Top brands ends */}

        {/* shop by price */}
        <div className={classes.shopByPrice}>
          <Container maxWidth="lg">
            <div className="outer-wrap">
              <h2 className="title-block">Shop By Price</h2>
              <div className="row-wrap">
                <div className="column green-border">
                  <div className="inner-col">
                    <div className="left-block">
                      <div className="inner-left-block">
                        <span className="discount">Under ₹ 599</span>
                        <Link className="shop-now">shop now</Link>
                      </div>
                    </div>
                    <div className="img-block">
                      <img src={blueNikeShoes} />
                    </div>
                  </div>
                </div>
                <div className="column red-border">
                  <div className="inner-col">
                    <div className="left-block">
                      <div className="inner-left-block">
                        <span className="discount">Under ₹ 199</span>
                        <Link className="shop-now">shop now</Link>
                      </div>
                    </div>
                    <div className="img-block">
                      <img src={blackGlasses} />
                    </div>
                  </div>
                </div>
                <div className="column yellow-border">
                  <div className="inner-col">
                    <div className="left-block">
                      <div className="inner-left-block">
                        <span className="discount">Under ₹ 459</span>
                        <Link className="shop-now">shop now</Link>
                      </div>
                    </div>
                    <div className="img-block">
                      <img src={girlPic} />
                    </div>
                  </div>
                </div>
                <div className="column orange-border">
                  <div className="inner-col">
                    <div className="left-block">
                      <div className="inner-left-block">
                        <span className="discount">50-80 off*</span>
                        <Link className="shop-now">shop now</Link>
                      </div>
                    </div>
                    <div className="img-block">
                      <img src={makeupKit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* shop by price ends */}

        {/* sale banner */}
        <div className={classes.saleBanner}>
          <Container maxWidth="lg">
            <div className="outer-wrap">
              <div className="row-wrap">
                <div className="left-block">
                  <img src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg" />
                </div>
                <div className="right-block">
                  <div className="right-inner">
                    <div className="upper-block">
                      <div className="logo" />
                      <div className="date">
                        7<sup>TH</sup> - 12 <sup>TH</sup> Aug
                      </div>
                    </div>
                    <div className="lower-block">
                      <div className="product-name">
                        Men's innerwear & Loungewear
                      </div>
                      <div className="sale-discount">Up To 70% Off</div>
                      <Link className="wishlist-now">+ Wishlist now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* sale banner ends */}

        {/* Blogs */}
        <div className={classes.blogs}>
          <Container maxWidth="lg">
            <h2 className="title-block">Blogs</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="img-block">
                  <img src={blueNikeShoes} />
                </div>
                <div className="col-name">
                  <span>Belts</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="img-block">
                  <img src={blackGlasses} />
                </div>
                <div className="col-name">
                  <span>Bags</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="img-block">
                  <img src={makeupKit} />
                </div>
                <div className="col-name">
                  <span>Socks</span>
                </div>
              </Grid>
            </Grid>
            <div className="btn-wrapper">
              <Button variant="outlined" className="outline-btn">
                View All
              </Button>
            </div>
          </Container>
        </div>
        {/* Blogs ends */}

        {/* offer block */}
        <div className={classes.offerBlock}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8}>
                <div className="img-block">
                  <img src="https://m.media-amazon.com/images/I/71A8S3g4PiL._SX3000_.jpg" />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className="content-block">
                <h2>
                  Flats &amp; <br />
                  Heels
                </h2>
                <span className="more-info">Min. 40% off</span>
                <Link href="#" className="explore-link">
                  + Explore
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* offer block ends */}
      </div>
    )}
    {/* for vendor dashboard */}
{localStorage.getItem('role') === 'vendor' && (
  <div className={classes.VendorPageOuter}>          
          <Container className="container-full" maxWidth={false}>
            <Grid container className="vendor-grid">
              <Grid item className="vendor-leftcol">
                <Grid className="top-heading" item xs={12}>
                  <div className="heading-left">
                    <h3 className="heading-title">Analytics</h3>
                  </div>
                  <div className="select-border">
                    <span className="select-label">Sort By:</span>
                    <Select
                      labelId="product-category"
                      id="demo-simple-select"
                      displayEmpty
                    >
                      <MenuItems>Today</MenuItems>
                      <MenuItems value="2">Product 2</MenuItems>
                      <MenuItems value="3">Product 3</MenuItems>
                    </Select>
                  </div> 
                </Grid>
                <Grid className="card-main" item xs={12} container>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ bagIcon }
                          />
                        </span>
                        <div className="card-title">New Orders</div>
                      </div>
                      <div className="card-price">512</div>                              
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ boxIcon }
                          />
                        </span>
                        <div className="card-title">AOV (Average Order Value)</div>
                      </div>
                      <div className="card-price lightgreen-txt">&#8377;219</div>                              
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ salesIcon }
                          />
                        </span>
                        <div className="card-title">Sales</div>
                      </div>
                      <div className="card-price orange-txt">&#8377;25.8k</div>                              
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ truckIcon }
                          />
                        </span>
                        <div className="card-title">In Transit</div>
                      </div>
                      <div className="card-price navyblue-txt">15</div>                              
                    </div>
                  </Grid>
                </Grid>
                <Grid className="top-heading" item xs={12}>
                  <div className="heading-left">
                    <h3 className="heading-title">Payment</h3>
                  </div>                   
                </Grid>
                <Grid className="card-main" item xs={12} container>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ balanceIcon }
                          />
                        </span>
                        <div className="card-title">Available Balance</div>
                      </div>
                      <div className="card-price purple-txt">512</div>                              
                    </div>
                  </Grid>
                  <Grid item className="card-item" xs={12} sm={6}>
                    <div className="card">                      
                      <div className="card-heading">
                        <span className="card-icon">
                          <img              
                            src={ payoutIcon }
                          />
                        </span>
                        <div className="card-title">Next Payout Date</div>
                      </div>
                      <div className="card-price">&#8377;219</div>                              
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className="vendor-rightcol">
                <Grid className="top-heading" item xs={12}>
                  <div className="heading-left">
                    <h3 className="heading-title">Schedule Pickup</h3>
                  </div>
                  <a href="#" className="view-link">View All</a>
                </Grid>
                <Grid item xs={12} className="productlist-wrapper">
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">Spyker Black Jeans</h4>
                          <div className="product-qty">QTY: 1</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">AERO Summer Shorts</h4>
                          <div className="product-qty">QTY: 2</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">Brecy Women Festival Flare Dress</h4>
                          <div className="product-qty">QTY: 1</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">Spyker Men Beach Yellow Shirt</h4>
                          <div className="product-qty">QTY: 1</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">TITO Women Leather Black Bag</h4>
                          <div className="product-qty">QTY: 1</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                  <Grid className="product-list">
                    <div className="product-list-inner">
                      <div className="product-img">
                        <img className="img-block" src={championItem} />
                      </div>
                      <div className="product-detail">
                        <div className="product-heading">
                          <h4 className="product-name">Champion Men Yellow Hoodie</h4>
                          <div className="product-qty">QTY: 1</div>
                        </div>  
                        <div className="product-desc">2054874527</div> 
                        <div className="date-time">                 
                          <span className="date">8/6/2021, </span>          
                          <span className="time">10:30 AM</span>
                        </div>  
                      </div>   
                    </div>                                                
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
  )}
    </> 
    );
  }
}
export default withStyles(styles)(CarousalBig);
// styling
const carousalStyleImageConatiner = { height: "250px" };
const links = [
  { name: "newin" },
  { name: "offers" },
  { name: "earrings" },
  { name: "jewellery sets" },
  { name: "necklaces" },
  { name: "bracelets & bangles" },
  { name: "rings" },
  { name: "hair accessories" },
  { name: "ankles" },
  { name: "Maangtika" },
  { name: "Nose Rings" },
  { name: "Brooches" },
  { name: "Mask Chains" },
  { name: "Charms" },
];
