import React from "react";
import { Grid, withStyles, Switch } from "@material-ui/core";
import CustomerSettingsController, {
  Props,
  configJSON,
} from "./CustomerSettingsController.web";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

class CustomerSettings extends CustomerSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCustomerSetting = () => {
    return (
      <Grid xs={12}>
        <div className="user-detail-wrapper">
          <div className="user-detail-inner">
            <div className="settings">
              <h5>
                <b>Settings</b>
              </h5>
              <p>
                <b>Notifications</b>
              </p>
              <div className="notification">
                <span>Push Notification</span>
                <AntSwitch
                  checked={this.state.push_notification}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        push_notification: !this.state.push_notification,
                      },
                      () => {
                        this.updateCustomerSettings();
                      }
                    );
                  }}
                  name="checkedP"
                />
              </div>
              <hr />
              <div className="notification">
                <span>Email Notification</span>
                <AntSwitch
                  checked={this.state.email_notification}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        email_notification: !this.state.email_notification,
                      },
                      () => {
                        this.updateCustomerSettings();
                      }
                    );
                  }}
                  name="checkedE"
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <CustomerAccountSideBarWeb
          id={1}
          navigation={this.props.navigation}
          children={this.renderCustomerSetting()}
          callbackShopWithFriend={this.props.callbackShopWithFriend}
        />
      </>
      // Customizable Area End
    );
  }
}

export default CustomerSettings;
