export const PoliciesStyle: any = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "20px 200px",
      width: "100%",
      "@media (max-width:990px)": {
        padding: "20px 100px",
      },
      "@media (max-width:767px)": {
        padding: "20px 10px",
      },
    },
    "& .block-title": {
      fontFamily: "'Roboto',sans-serif",
      marginBottom: "20px",
    },
    "& .content": {
      fontFamily: "'Roboto',sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#666",
      marginBottom: "30px",
    },
    "& .detail-block": {
      border: "1px solid #e8e8e8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 0 30px",
      marginBottom: "20px",
      marginTop: "10px",
    },
    "& .detail-block p": {
      marginBottom: "0",
      fontFamily: "'Roboto',sans-serif",
    },
    "& .custom-list li p": {
      margin: "0 0 15px",
    },
    "& .heading": {
      fontWeight: "600",
      textAlign: "center",
      marginBottom: "22px",
      fontSize: "22px",
    },
    "& #help .helpCenter": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "15px 0",
      cursor: "pointer",
      borderBottom: "1px solid #dddddd",
      textDecoration: "none",
      color: "black",
    },
    "& #help .helpCenter p": {
      margin: "0",
    },
  },
};
