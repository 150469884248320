import React from "react";
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Select,
  withStyles,
} from "@material-ui/core";
import MenuItems from "@material-ui/core/MenuItem";
import VendorDashboardController, {
  Props,
  configJSON,
} from "./VendorDashboardController.web";
import { VendorDashboardStyle } from "./VendorDashboardStyle.web";
import {
  bagIcon,
  boxIcon,
  salesIcon,
  truckIcon,
  payoutIcon,
  balanceIcon,
} from "./assets";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";

export class VendorDashboard extends VendorDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;

    return (
      // Customizable Area Start
      <div className={classes.VendorPageOuter}>
        <Loader loading={this.state.loading} />
        <Container className="container-full" maxWidth={false}>
          <Grid container className="vendor-grid">
            <Grid item className="vendor-leftcol">
              <Grid className="top-heading" item xs={12}>
                <div className="heading-left">
                  <h3 className="heading-title">Analytics</h3>
                </div>
                <div className="select-border">
                  <span className="select-label">Sort By:</span>
                  <Select
                    labelId="product-category"
                    id="demo-simple-select"
                    value={this.state.sortBy}
                    onChange={(e: any) => {
                      this.setState(
                        {
                          ...this.state,
                          loading: true,
                          sortBy: e.target.value,
                        },
                        () => {
                          this.getSalesAnalytics();
                          this.getAOVAnalytics();
                          this.getNewOrdersAnalytics();
                          this.getInTransitAnalytics();
                        }
                      );
                    }}
                  >
                    <MenuItems value={"today"}>Today</MenuItems>
                    <MenuItems value={"last_week"}>Last Week</MenuItems>
                    <MenuItems value={"last_month"}>Last Month</MenuItems>
                    <MenuItems value={"last_year"}>Last Year</MenuItems>
                  </Select>
                </div>
              </Grid>
              <Grid className="card-main" item xs={12} container>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={bagIcon} />
                      </span>
                      <div className="card-title">New Orders</div>
                    </div>
                    <div className="card-price">{this.state.newOrders}</div>
                  </div>
                </Grid>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={boxIcon} />
                      </span>
                      <div className="card-title">
                        AOV (Average Order Value)
                      </div>
                    </div>
                    <div className="card-price lightgreen-txt">
                      &#8377;{this.state.aov}
                    </div>
                  </div>
                </Grid>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={salesIcon} />
                      </span>
                      <div className="card-title">Sales</div>
                    </div>
                    <div className="card-price orange-txt">
                      &#8377;{this.state.sales}
                    </div>
                  </div>
                </Grid>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={truckIcon} />
                      </span>
                      <div className="card-title">In Transit</div>
                    </div>
                    <div className="card-price navyblue-txt">
                      {this.state.inTransit}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid className="top-heading" item xs={12}>
                <div className="heading-left">
                  <h3 className="heading-title">Payment</h3>
                </div>
              </Grid>
              <Grid className="card-main" item xs={12} container>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={balanceIcon} />
                      </span>
                      <div className="card-title">Available Balance</div>
                    </div>
                    <div className="card-price purple-txt">
                      &#8377;{this.state.availableBalance}
                    </div>
                  </div>
                </Grid>
                <Grid item className="card-item" xs={12} sm={6}>
                  <div className="card">
                    <div className="card-heading">
                      <span className="card-icon">
                        <img src={payoutIcon} />
                      </span>
                      <div className="card-title">Next Payout Date</div>
                    </div>
                    <div className="card-price">
                      {this.state.nextPayoutDate}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className="vendor-rightcol">
              <Grid className="top-heading" item xs={12}>
                <div className="heading-left">
                  <h3 className="heading-title">Schedule Pickup</h3>
                </div>
                <a href="/vendorOrder/ready-to-ship" className="view-link">
                  View All
                </a>
              </Grid>
              <Grid item xs={12} className="productlist-wrapper">
                {this.state.orders.length === 0 && (
                  <div>No Order Available</div>
                )}
                {this.state.orders.slice(0, 6).map((order: any) => {
                  return (
                    <div>
                      {order.attributes.order_items_details.map(
                        (orderItem: any) => {
                          return (
                            <Grid className="product-list" key={order.id}>
                              <div className="product-list-inner">
                                <div className="product-img">
                                  {orderItem.galleries &&
                                  orderItem.galleries.length > 0 &&
                                  orderItem.galleries[0].url ? (
                                    <img
                                      className="img-block"
                                      src={orderItem.galleries[0].url}
                                    />
                                  ) : (
                                    <img
                                      className="img-block"
                                      src={`https://tinyurl.com/3wdum8u5`}
                                    />
                                  )}
                                </div>
                                <div className="product-detail">
                                  <div className="product-heading">
                                    <h4 className="product-name">
                                      {orderItem.product_name}{" "}
                                      {orderItem.color &&
                                        `(${orderItem.color})`}
                                    </h4>
                                    <div className="product-qty">
                                      QTY: {orderItem.quantity}
                                    </div>
                                  </div>
                                  <div className="product-desc">
                                    {order.attributes.order_number}
                                  </div>
                                  <div className="date-time">
                                    <span className="date">
                                      {moment(orderItem.updated_at).format(
                                        "DD/MM/yyyy, hh:mm A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      )}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          onClose={this.handleModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.isOrderModalOpen}
        >
          <DialogTitle id="customized-dialog-title">Modal title</DialogTitle>
          <DialogContent>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </DialogContent>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

export default withStyles(VendorDashboardStyle)(VendorDashboard);
