import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Loader from "../../../components/src/Loader.web"

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import React, { Component } from "react";
import { VendorAddAddressModel } from "../../../framework/src/Interfaces/IAddress";
export const configJSON = require("./config");
import moment from "moment";
export interface Props {
    navigation: any;
    id: number;
    mode: string;
    // Customizable Area Start
    classes: any;
    callbackShopWithFriend: Function;
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    token: string | null;
    data: any;
    showNewAddressDialog: boolean;
    showEditAddressDialog:boolean
    // Customizable Area Start
    nameError:any;
    mobileError:any;
     addressError:any;
    townError:any;
     pincodeError:any;
      cityError:any;
     stateError:any;
     save_addError:any;
     otpPhoneError:any
  otpEmailError:any
    mode: string
    addressData:any;
    state: string;
    city: string;
    mobile: any;
    email: string;
    town: string;
    id: number | null;
    name: string;
    address: string;
    pincode: any;
    save_add: string;
    saveAddress: string;
    // addressID: number | null;
    priceDetailsData:any;
    addNewDeliveryAddress:any;
    activeCartId:any;
    deliveryAddressId:any;
    placeOrderLoader:boolean,
    deleteAddressId:any,
    addressId:any,
    PhoneVerify: boolean,
    MailVerify:boolean
    editName:any
    editPhoneNumber: any
    editAddress: any
    editTown: any
    editZipCode: any
    editCity: any
    editState: any
    editSaveAddressAs: any;
    editAddressResponse:any;
    profileDetail:any;
    editProfileResponse:any;
    editProfileName:any
    editProfileemail:any
    editProfilePhoneNumber:any
    editProfileDateOfBirth:any
    editProfileGender:any
    isDelete:any
    editProfileNameError:any
    editProfileEmailError:any;
    editProfilePhoneError:any
    editProfileDateOfBirthError:any;
    verifyEmail:any;
    verifyPhone:any;
    emailOtpData:any;
    phoneOtpData:any;
    otpPinEmail:any;
    otpPinPhone:any;
    emailVerifyToken:any;
    PhoneVerifyToken:any;
    verifyEmailData:any;
    verifyPhoneData:any;
    otpTimerEmail:any;
    otpTimerPhone:any;
    resendActivateEmail:boolean;
    resendActivatePhone:boolean;
    addressBookLoader:boolean

    // profilename :any
    // profileemail : any
    // profilephone_number : any
    // profiledate_of_birth : any
    // profilegender :  any
    
   
     
    
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomerAddressController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiPhoneLoginCallId: any;
    addressCallId: any = null;
    getaddressCallId: any = null;
    DelAddressCallId: any = null;
    // addNewAddressCallId: any = null;
    EditAddressCallId: any = null;
    EidthAddressCallId: any = null;
    DefualtAddressCallId: any = null;
    priceDetailsDataCallId :any;
    addNewDeliveryAddressCallId:any
    addNewAddressCallId :any;
    editAddressCallId:any;
    deliveryAddressSelectedForOrderCallId:any;
    placeOrderCallId:any;
    ActiveCartCallId:any;
    getProfileId:any;
    editProfileCallId:any;
    emailOtpCallId:any;
    phoneOtpCallId :any;
    emailVerifyCallId :any
    PhoneVerifyCallId :any;
    myRef: React.RefObject<HTMLDivElement>;
 
    // Customizable Area End

    public intialValues: VendorAddAddressModel = {
        name: '',
        mobile: '',
        email: '',
        address: '',
        town: '',
        city: '',
        state: '',
        pincode: ''
    };

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.myRef = React.createRef()
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // const result = msg.getData('id');

        // const result = msg.getData('id');

        console.log("props.id::::", props);
        this.state = {
            txtSavedValue: 'A',
            token: '',
            data: [],
            showNewAddressDialog: false,
            showEditAddressDialog: false,
            // Customizable Area Start
            nameError:"",
            mobileError:"",
             addressError:"",
            townError:"",
             pincodeError:"",
              cityError:"",
             stateError:"",
             save_addError:"",
             otpPhoneError:"",
             otpEmailError:"",
            mode: '',
            addressData:[],
            // editAddress: {},
            state: '',
            city: '',
            mobile: '',
            email: '',
            town: '',
            id: this.props.id || null,
            name: '',
            address: '',
            pincode: '',
            save_add: 'home',
            saveAddress: '',
            priceDetailsData:[],
            addNewDeliveryAddress:[],
            activeCartId:[],
            deleteAddressId:"",
            deliveryAddressId:"",
            placeOrderLoader:false,
            addressId:"",
            PhoneVerify: false,
            MailVerify:false,
            editName:"",
            editPhoneNumber: "",
            editAddress: "",
            editTown: "",
            editZipCode: "",
            editCity: "",
            editState: "",
            editSaveAddressAs: "",
            editAddressResponse:[],
            profileDetail:[],
            editProfileResponse:[],
            editProfileName:"",
            editProfileemail:"",
            editProfilePhoneNumber:localStorage.getItem("phone"),
            editProfileDateOfBirth:"",
            editProfileGender:"",
            isDelete:false,
            editProfileNameError:"",
            editProfileEmailError:"",
            editProfilePhoneError:"",
            editProfileDateOfBirthError:"",
             verifyEmail:false,
            verifyPhone:false,
            emailOtpData:"",
            phoneOtpData:[],
            otpPinEmail:"",
            otpPinPhone:"",
            emailVerifyToken:"",
            PhoneVerifyToken:"",
            verifyEmailData:"",
            verifyPhoneData:"",
            otpTimerEmail:"",
            otpTimerPhone:"",
            resendActivateEmail:false,
            resendActivatePhone:false,
            addressBookLoader:false
        
       
            //addressID: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        const msg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        const data = msg.getData(
            getName(MessageEnum.VendorAddressDataMessage)
        );
        console.log("constructor data::", data);
        // this.send(msg);
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.myRef.current?.scrollIntoView({behavior: 'auto'})
        // token
        console.log('----->> token');
        try {
            const localToken = await localStorage.getItem("authToken")
            console.log(localToken, "tokentoken")

            this.setState({ token: localToken });
            this.addressCall() 
           this.getProfile()
      
          

        } catch (e) {
            // error reading value
        }
    }

    // temporary
    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            console.log("navigation payload:::")
            const data = message.getData(
                getName(MessageEnum.VendorAddressDataMessage)
            );
       

        }

        // Customizable Area Start

        // address listing data start 
if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.addressCallId !== null &&
    this.addressCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.addressCallId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   

    if (responseJson && responseJson.data) {
        this.setState({addressData: responseJson.data});
        this.setState({addressBookLoader:false})
        // this.setState({ shoppingCartloading: false });
        console.log('//////////////addresdata data------------------------------------------------------', this.state.addressData);
        // const defaultid:any = this.state.addressData?.[0].attributes?.id
        // console.log(defaultid,"defaultid")
        // this.setState({deliveryAddressId:defaultid})
    }

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  // address listing data end

        // verify email data start 
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.emailVerifyCallId !== null &&
            this.emailVerifyCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            this.emailVerifyCallId= null;
            var responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
        console.log(responseJson && responseJson.data)
            if (responseJson  && responseJson.data ) {
                this.setState({verifyEmailData: responseJson},()=>{this.setState({MailVerify:false})
                this.getProfile()});
             
                console.log('/////////////verify email data------------------------------------------------------', this.state.verifyEmailData);
               
            }
        
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
              // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
              //Need To send Login token message to save for future call
            } 
            else {
              //Check Error Response
              // this.parseApiErrorResponse(responseJson);
              const otpErr:any =  responseJson.errors[0].pin
              this.setState({otpEmailError:otpErr})
            }
            this.parseApiCatchErrorResponse(errorReponse);
          }
          
          // verify email data end




        // verify phone data start 
        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.PhoneVerifyCallId !== null &&
          this.PhoneVerifyCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          this.PhoneVerifyCallId= null;
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
         
      console.log(responseJson)
  
          if (responseJson && responseJson.data ) {
              this.setState({verifyEmailData: responseJson},()=>{this.setState({PhoneVerify:false})
              this.getProfile()});
              console.log('/////////////verify email data------------------------------------------------------', this.state.verifyEmailData);
             
          }
      
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            //Need To send Login token message to save for future call
          } 
          else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            const otpErr:any =  responseJson.errors[0].pin
            this.setState({otpPhoneError:otpErr})
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        
        // verify phone data end









// get profile detail start 
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getProfileId !== null &&
    this.getProfileId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.getProfileId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   

    if (responseJson && responseJson.data) {
      
        this.setState({profileDetail: responseJson.data},
        ()=>{    localStorage.setItem("phone",this.state.profileDetail.attributes?.phone_number);     this.setState(
{
    editProfileName:this.state.profileDetail.attributes?.name,
    editProfileemail:this.state.profileDetail.attributes?.email,
    // editProfilePhoneNumber:this.state.profileDetail.attributes?.phone_number,
    editProfileDateOfBirth:moment(this.state.profileDetail.attributes?.date_of_birth).format("DD-MM-YYYY"),
    editProfileGender:this.state.profileDetail.attributes?.gender,
verifyPhone:this.state.profileDetail.attributes?.phone_verified,
verifyEmail:this.state.profileDetail.attributes?.email_verified

}

  )} 
          );
         console.log('//////////////profile detail data------------------------------------------------------', this.state.profileDetail);

    }

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
//   get profile detail end 


       // add new address response data start 
       if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.addNewDeliveryAddressCallId !== null &&
        this.addNewDeliveryAddressCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        this.addNewDeliveryAddressCallId= null;
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (responseJson  && responseJson.data) {
        this.setState({addNewDeliveryAddress: responseJson.data});
        // this.setState({ shoppingCartloading: false });
        console.log('//////////////addNewDeliveryAddress------------------------------------------------------', this.state.addNewDeliveryAddress);
        }
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          //Need To send Login token message to save for future call
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      // add new address response data end 
    




// email verifictaion  start 

// email opt send start 
if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.emailOtpCallId !== null &&
    this.emailOtpCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.emailOtpCallId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson){
    this.setState({emailOtpData: responseJson});
    this.setState({emailVerifyToken:this.state.emailOtpData.token})
    console.log('//////////////emailOtpData------------------------------------------------------', this.state.emailOtpData);
    }
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
       
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

// email opt end


// email verifictaion  start 






// phone  verifictaion  start 

// phone opt send start 
if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.phoneOtpCallId !== null &&
    this.phoneOtpCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.phoneOtpCallId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      this.setState({phoneOtpData: responseJson});
      this.setState({PhoneVerifyToken:this.state.phoneOtpData.token})
      // this.setState({ shoppingCartloading: false });
      console.log('//////////////phoneOtpData------------------------------------------------------', this.state.phoneOtpData);
      }
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

// phone opt end

// phone verifictaion  start 










// edit profile start 

if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.editProfileCallId !== null &&
    this.editProfileCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.editProfileCallId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson  && responseJson.data) {
    this.setState({editProfileResponse: responseJson.data});
    // this.setState({ shoppingCartloading: false });
    console.log('//////////////editProfileResponse------------------------------------------------------', this.state.editProfileResponse);
    // this.getProfile()
this.props.navigation.navigate("CustomerMyProfile")
localStorage.removeItem("phone");   

    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJson);
      if (responseJson.account_error[0] == "Email has already been taken") {
        this.setState({ editProfileEmailError: responseJson.account_error[0] })
      }
      if (responseJson.account_error[0] == "Full phone number has already been taken") {
        this.setState({ editProfilePhoneError: responseJson.account_error[0] })
      }


      if (responseJson.account_error[0] == "Full phone number has already been taken" && responseJson.account_error[1] == "Email has already been taken") {
        this.setState({ editProfilePhoneError: responseJson.account_error[0], editProfileEmailError: responseJson.account_error[1] })
      }


    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

// edit profile end 












// edit address start 

  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.editAddressCallId !== null &&
    this.editAddressCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    this.editAddressCallId= null;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson  && responseJson.data) {
    this.setState({editAddressResponse: responseJson.data});
    // this.setState({ shoppingCartloading: false });
    console.log('//////////////editAddressResponse------------------------------------------------------', this.state.editAddressResponse);

    this.addressCall();
    this.handleCloseEditAddressDialog()
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

// edit address end 



        // DELETE ADDRESS
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DelAddressCallId !== null) {


            this.DelAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('-------------->', this.state.data);

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.message == "Address deleted successfully") {
              this.setState({
                isDelete: false})
                this.addressCall()
            }


            if (responseJson && responseJson.meta && responseJson.meta.token) {
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END DELETE ADDRESS

        // DEFAULT ADDRESS
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DefualtAddressCallId !== null) {

            this.DefualtAddressCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            // this.setState({ data: responseJson.data });
            console.log('Default api response-------------->', responseJson);

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data) {
                this.addressCall();
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END DEFAULT ADDRESS

        // Customizable Area End

    }

    getProfile() {
 

        const header = {
            "Content-Type": "application/json",
            token: this.state.token
          
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProfileId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProfileAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }


// deleteAddress starat 
deleteAddress(){

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.DelAddressCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAddressAPiEndPoint.concat(this.state.addressId)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
   
   
  }

//   deleteAddress end 


    addressCall() {
        console.log('---------------->>>>>>> address');
        this.setState({addressBookLoader:true})
        const header = {
            "Content-Type": "application/json",
            token: this.state.token
            // token: ''
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addressCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addressAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }




    handleCloseNewAddressDialog = () => {
        this.setState({
            showNewAddressDialog: false,
            nameError: "",
            mobileError: "",
            addressError: "",
            townError: "",
            pincodeError: "",
            cityError: "",
            stateError: "",
            save_addError: "",
        });
    };

    handleCloseEditAddressDialog = () => {
        this.setState({
            showEditAddressDialog: false,
            state: '',
            city: '',
            mobile: '',
            email: '',
            town: '',
            name: '',
            address: '',
            pincode: '',
            nameError: "",
            mobileError: "",
            addressError: "",
            townError: "",
            pincodeError: "",
            cityError: "",
            stateError: "",
            save_addError: "",
        });
    };
    addNewAddress(){
 
        if(!this.state.name ||!this.state.mobile || !this.state.town ||!this.state.pincode || !this.state.city ||!this.state.state || !this.state.save_add || (this.state.address.length < 10 && this.state.address.length > 0) || !this.state.address) {
            if (!this.state.name) {      // alert('Package Name Field is Empty');
                this.setState({nameError:"Please fill name field"})
                }else{ this.setState({nameError:""}) }
                 if (!this.state.mobile) {
                    // alert('Package Length Field is Empty');
                    this.setState({mobileError:"Please fill number field"})
                }
                else if (this.state.mobile.length != 10) {
                  this.setState({ mobileError: "Please enter valid number" })
                }
                else{ this.setState({mobileError:""}) }
                //  if (!this.state.address) {
                //     // alert('Package Width Fieldis Empty');
                //     this.setState({addressError:"Please fill address field"})
                // } else{ this.setState({addressError:""}) }
                if(this.state.address.length < 10 && this.state.address.length > 0 || !this.state.address ){

                    if(this.state.address.length < 10 && this.state.address.length > 0){
                    this.setState({addressError:"address is less than 10 characters"})}
                    else if (!this.state.address) {
                        // alert('Package Width Fieldis Empty');
                        this.setState({addressError:"Please fill address field"})
                }else{ this.setState({addressError:""}) }}
                
                 if (!this.state.town) {
                    // alert('Package Height Field is Empty');
                    this.setState({townError:"Please fill town field"})
                }else{this.setState({townError:""})  }


                if (!this.state.pincode) {
                    // alert('Package Height Field is Empty');
                    this.setState({pincodeError:"Please fill pincode field"})
                }else{this.setState({pincodeError:""})}  


                if (!this.state.city) {
                    // alert('Package Height Field is Empty');
                    this.setState({cityError:"Please fill city field"})
                }else{this.setState({cityError:""})}  

                
                if (!this.state.city) {
                    // alert('Package Height Field is Empty');
                    this.setState({cityError:"Please fill city field"})
                }else{this.setState({cityError:""})}
            
            
                if (!this.state.state) {
                    // alert('Package Height Field is Empty');
                    this.setState({stateError:"Please fill state field"})
                }else{this.setState({stateError:""})}
            
            
                if (!this.state.save_add) {
                    // alert('Package Height Field is Empty');
                    this.setState({save_addError:"Please fill save address as field"})
                }else{this.setState({save_addError:""})}
            
            }

            else{
                this.handleCloseNewAddressDialog()
            
        
        
              const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token,
              };
          
              const data = {
                name: this.state.name,
                phone_number: this.state.mobile,
                address: this.state.address,
                town: this.state.town,
                zip_code: this.state.pincode,
                city: this.state.city,
                state: this.state.state,
                save_address_as: this.state.save_add,
                // email: "nomail@gmail.com",
                country:"india",
                is_default:true
              };
          
              const httpBody = 
                 data
            
              console.log("--------------->>>>>>>>>>", httpBody);
          
          
              const apiRequest = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
          
              this.addNewAddressCallId = apiRequest.messageId;
              console.log(this.addNewAddressCallId )
              apiRequest.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addNewAddressAPiEndPoint
              );
          
              apiRequest.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
          
              apiRequest.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
              );
          
              apiRequest.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypePost
              );
              this.setState({name:"",city:"",town:"",mobile:"",pincode:"",address:"",state:"",
            
              nameError:"",cityError:"",townError:"",mobileError:"",pincodeError:"",addressError:"",stateError:""})
              runEngine.sendMessage(apiRequest.id, apiRequest);
              return true;
        
            
              }
            
       
          }
          // ValidateEmail(mail:any) {
          //   // return /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(mail)   
          //   return /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/.test(mail)
        
          // }

          ValidateEmail = (text: string): boolean => {
            // let reg = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/;
            // let reg = /^w+([.]?)w+@\w+.\w{2,3})$/;
            let reg =   /^\w+([.-]?\w+)([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/
            if (reg.test(text) === false) {
              return false;
            }
            else {
              return true;
            }
         
          }


// edit address start
EditProfile(){
 

    if( !this.ValidateEmail(this.state.editProfileemail) ||   !this.state.editProfileName ||!this.state.editProfileemail || !this.state.editProfilePhoneNumber || this.state.editProfilePhoneNumber.length != 10  || !this.state.editProfileDateOfBirth ||!this.state.editProfileGender ) {
       
             if (!this.state.editProfileName) {
              
                this.setState({editProfileNameError:"Please enter your name"})
            }else{ this.setState({editProfileNameError:""}) }
         
            if (!this.state.editProfileemail ) {
              
                this.setState({editProfileEmailError:"Please enter your email"})
            }else if (!this.ValidateEmail(this.state.editProfileemail))
            { this.setState({editProfileEmailError:"Please enter valid email"})}
            else{ this.setState({editProfileEmailError:""}) }
         
          
            if (!this.state.editProfilePhoneNumber ) {
              
                this.setState({editProfilePhoneError:"Please enter your phone number"})
            
              }else if(this.state.editProfilePhoneNumber.length != 10 ){
              console.log(this.state.editProfilePhoneNumber.length ,"length")
              this.setState({editProfilePhoneError:"must be 10 digits"}) 
             
            }else{ this.setState({editProfilePhoneError:""})}
    
        
            if (!this.state.editProfileDateOfBirth ) {
              
                this.setState({editProfileDateOfBirthError:"Please enter your date of birth"})
            }else{ this.setState({editProfileDateOfBirthError:""}) }
         
        }

        else{
          this.setState({editProfilePhoneError:""})
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token,
          };
      
          const data = {

                name : this.state.editProfileName,
                email : this.state.editProfileemail,
                phone_number : this.state.editProfilePhoneNumber,
                date_of_birth : this.state.editProfileDateOfBirth,
                gender : this.state.editProfileGender                 
             }

      
          const httpBody = 
             data
        
          console.log("--------------->>>>>>>>>>", httpBody);
      
      
          const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.editProfileCallId = apiRequest.messageId;
    
          apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.editProfileAPiEndPoint
          
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PatchMethodType
          );
       
          runEngine.sendMessage(apiRequest.id, apiRequest);
          return true;
    
         
    
       
          }
        
        }
      


// edit address start
EditAddress(){
 
    if(!this.state.editName ||(!this.state.editPhoneNumber || this.state.editPhoneNumber.length != 10) || !this.state.editTown ||!this.state.editZipCode || !this.state.editCity ||!this.state.editState || !this.state.save_add || (this.state.editAddress.length < 10 && this.state.editAddress.length > 0) || !this.state.editAddress) {
        if (!this.state.editName) {      // alert('Package Name Field is Empty');
            this.setState({nameError:"Please fill name field"})
            }else{ this.setState({nameError:""}) }
             if (!this.state.editPhoneNumber) {
                // alert('Package Length Field is Empty');
                this.setState({mobileError:"Please fill number field"})
            }else if (this.state.editPhoneNumber.length != 10)
            {this.setState({ mobileError: "Please enter valid number" })}
            else{ this.setState({mobileError:""}) }
            //  if (!this.state.address) {
            //     // alert('Package Width Fieldis Empty');
            //     this.setState({addressError:"Please fill address field"})
            // } else{ this.setState({addressError:""}) }
            if(this.state.editAddress.length < 10 && this.state.editAddress.length > 0 || !this.state.editAddress ){

                if(this.state.editAddress.length < 10 && this.state.editAddress.length > 0){
                this.setState({addressError:"address is less than 10 characters"})}
                else if (!this.state.editAddress) {
                    // alert('Package Width Fieldis Empty');
                    this.setState({addressError:"Please fill address field"})
            }else{ this.setState({addressError:""}) }}
            
             if (!this.state.editTown) {
                // alert('Package Height Field is Empty');
                this.setState({townError:"Please fill town field"})
            }else{this.setState({townError:""})  }


            if (!this.state.editZipCode) {
                // alert('Package Height Field is Empty');
                this.setState({pincodeError:"Please fill pincode field"})
            }else{this.setState({pincodeError:""})}  


            if (!this.state.editCity) {
                // alert('Package Height Field is Empty');
                this.setState({cityError:"Please fill city field"})
            }else{this.setState({cityError:""})}  

            
            if (!this.state.editCity) {
                // alert('Package Height Field is Empty');
                this.setState({cityError:"Please fill city field"})
            }else{this.setState({cityError:""})}
        
        
            if (!this.state.editState) {
                // alert('Package Height Field is Empty');
                this.setState({stateError:"Please fill state field"})
            }else{this.setState({stateError:""})}
        
        
            if (!this.state.save_add) {
                // alert('Package Height Field is Empty');
                this.setState({save_addError:"Please fill save address as field"})
            }else{this.setState({save_addError:""})}
        
        }

        else{
            this.handleCloseNewAddressDialog()
        
    
    
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token,
          };
      
          const data = {

          
            name: this.state.editName,
            phone_number: this.state.editPhoneNumber,
            address: this.state.editAddress,
            town: this.state.editTown,
            zip_code: this.state.editZipCode,
            city: this.state.editCity,
            state: this.state.editState,
            save_address_as: this.state.editSaveAddressAs,
            // email: "nomail@gmail.com",
            country:"india",
            is_default:true
          };
      
          const httpBody = 
             data
        
          console.log("--------------->>>>>>>>>>", httpBody);
      
      
          const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.editAddressCallId = apiRequest.messageId;
          console.log(this.editAddressCallId )
          apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.editAddressAPiEndPoint.concat(this.state.addressId)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PatchMethodType
          );
        //   this.setState({name:"",city:"",town:"",mobile:"",pincode:"",address:"",state:"",
        
        //   nameError:"",cityError:"",townError:"",mobileError:"",pincodeError:"",addressError:"",stateError:""})
          runEngine.sendMessage(apiRequest.id, apiRequest);
          return true;
    
         
  
    
          }
        
   
      }
    
// edit  address end 


sendOtpEmail(){
 
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token,
          };
      
     
      
          const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.emailOtpCallId = apiRequest.messageId;
       
          apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.emailOtpAPiEndPoint.concat(this.state.editProfileemail)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
          );
        
          runEngine.sendMessage(apiRequest.id, apiRequest);
          return true;
    
        
      }




      sendOtpPhone(){
 
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.state.token,
        };
    
   
    
        const apiRequest = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.phoneOtpCallId = apiRequest.messageId;
     
        apiRequest.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.phoneOtpAPiEndPoint.concat(this.state.editProfilePhoneNumber)
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GetMethodType
        );
      
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
  
      
    }

    verifyEmail(){
console.log("tokenverify",this.state.emailVerifyToken,"otp",this.state.otpPinEmail)

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.state.emailVerifyToken,
        };
    
        const data = {
            pin:this.state.otpPinEmail
        };
    
        const httpBody = 
           data
        
        console.log("--------------->>>>>>>>>>", httpBody);
    

        const apiRequest = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.emailVerifyCallId = apiRequest.messageId;

        apiRequest.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.emailVerifyAPiEndPoint
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.PostMethodType
        );
        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
  }

  verifyPhone(){
    console.log("tokenverify",this.state.PhoneVerifyToken,"otp",this.state.otpPinPhone)
    
            const header = {
              "Content-Type": configJSON.validationApiContentType,
              token: this.state.PhoneVerifyToken,
            };
        
            const data = {
                pin:this.state.otpPinPhone
            };
        
            const httpBody = 
               data
            
            console.log("--------------->>>>>>>>>>", httpBody);
        
    
            const apiRequest = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            
            this.PhoneVerifyCallId = apiRequest.messageId;
    
            apiRequest.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.PhoneVerifyAPiEndPoint
            );
        
            apiRequest.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
        
            apiRequest.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
        
            apiRequest.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.PostMethodType
            );
            runEngine.sendMessage(apiRequest.id, apiRequest);
            return true;
      }
    
    
          isStringNullOrBlank(str: string | number) {
            return str === null || str.toString().length === 0;
          }
          


       

        
     addThisDeliveryAddress(){
  
     this.addNewAddress();
     setTimeout(()=>this.addressCall(),2000)
                        
                      
                    }

          
    handleClose() {
                        this.setState({
                          isDelete: false
                        });
                      }
                

              




otpTimerEmail(){

 var timeleft = 30;
this.setState({otpTimerEmail:timeleft})

 var downloadTimer = setInterval(()=>{
 var updatedtime =  this.state.otpTimerEmail-1;
this.setState({otpTimerEmail:updatedtime})
  if(this.state.otpTimerEmail <= 0){
      clearInterval(downloadTimer);
      this.setState({resendActivateEmail:true})}
  },1000);


}
otpTimerPhone(){

  var timeleft = 30;
 this.setState({otpTimerPhone:timeleft})
 
  var downloadTimer = setInterval(()=>{
  var updatedtime =  this.state.otpTimerPhone-1;
 this.setState({otpTimerPhone:updatedtime})
   if(this.state.otpTimerPhone <= 0){
   this.setState({resendActivatePhone:true})
       clearInterval(downloadTimer);}
       
   },1000);
 
 
 }



}
