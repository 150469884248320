import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Loader from "../../../components/src/Loader.web"

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import React, { Component } from "react";
import { AnySoaRecord } from "dns";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: number;
  mode: string;
  // Customizable Area Start
  classes: any;
  updateProfileName(name: any): Function
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  token: string | null;
  data: any;
  showNewAddressDialog: boolean;
  showEditAddressDialog: boolean
  showAddNewBrandDialog: boolean
  showEditBrandDialog: boolean
  // Customizable Area Start
  // error display start 
  nameError: any;
  mobileError: any;
  addressError: any;
  townError: any;
  pincodeError: any;
  cityError: any;
  stateError: any;
  save_addError: any;
  brandNameError: any;
  brandDescriptionError: any;
  brandImageError: any
  editBrandNameError: any;
  editBrandDescriptionError: any;
  editBrandImageError: any

  editProfileBrandNameError: any;
  editProfileAccountHolderNameError: any;
  editProfileAccountNumberError: any;
  editProfileBankNameError: any;
  editProfileIfscCodeError: any;
  editProfileRegisteredBusinessNameError: any;
  editProfileGstinError: any;
  editProfileTanError: any;
  editProfilePanError: any;
  editSignatureImageError: any
  otpPhoneError: any;
  otpEmailError: any;
  // error display end 
  mode: string
  addressData: any;
  state: string;
  city: string;
  mobile: any;
  email: string;
  town: string;
  id: number | null;
  name: string;
  address: string;
  pincode: any;
  save_add: string;
  saveAddress: string;
  // addressID: number | null;
  priceDetailsData: any;
  addNewDeliveryAddress: any;
  activeCartId: any;
  deliveryAddressId: any;
  placeOrderLoader: boolean,
  deleteAddressId: any,
  addressId: any,
  PhoneVerify: boolean,
  MailVerify: boolean
  editName: any
  editPhoneNumber: any
  editAddress: any
  editTown: any
  editZipCode: any
  editCity: any
  editState: any
  editSaveAddressAs: any;
  editAddressResponse: any;
  profileDetail: any;
  editProfileResponse: any;
  editProfileName: any
  editProfileemail: any
  editProfilePhoneNumber: any
  editProfileDateOfBirth: any
  editProfileGender: any
  isDelete: any;
  isDeleteBrand: any;
  editProfileNameError: any
  ; editProfileEmailError: any;
  editProfilePhoneError: any
  editProfileDateOfBirthError: any;
  verifyEmail: any;
  verifyPhone: any;
  emailOtpData: any;
  phoneOtpData: any;
  otpPinEmail: any;
  otpPinPhone: any;
  emailVerifyToken: any;
  PhoneVerifyToken: any;
  verifyEmailData: any;
  verifyPhoneData: any;
  otpTimerEmail: any;
  otpTimerPhone: any;
  resendActivateEmail: boolean;
  resendActivatePhone: boolean;
  addressBookLoader: boolean;
  brandData: any;
  BrandId: any;
  addNewBrand: any;
  brandName: any;
  brandDescription: any;
  brandImage: any;
  EditbrandName: any;
  EditbrandDescription: any;
  EditbrandImage: any;
  signatureImage: any;
  displayBrandNames: any
  // profilename :any
  // profileemail : any
  // profilephone_number : any
  // profiledate_of_birth : any
  // profilegender :  any
  // edit vendor profile 
  editProfileBrandName: any;
  editProfileAccountHolderName: any;
  editProfileAccountNumber: any;
  editProfileBankName: any;
  editProfileIfscCode: any;
  editProfileRegisteredBusinessName: any;
  editProfileGstin: any;
  editProfileTan: any;
  editProfilePan: any;
  EditBrandData: any;
  imageUrl: any;
  editBrandImageUrl: any;
  editSignatureImageUrl: any;
  getProfileLoader: any;
  phone_verification: boolean;
  alertAddBrand: boolean;
  alertEditBrand: boolean;



  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface VendorAddAddressModel {
  name: string;
  mobile: string;
  email: string;
  address: string;
  town: string;
  city: string;
  state: string;
  pincode: string;

}

export default class CustomerAddressController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  addressCallId: any = null;
  getaddressCallId: any = null;
  DelAddressCallId: any = null;
  // addNewAddressCallId: any = null;
  EditAddressCallId: any = null;
  EidthAddressCallId: any = null;
  DefualtAddressCallId: any = null;
  priceDetailsDataCallId: any;
  addNewDeliveryAddressCallId: any
  addNewAddressCallId: any;
  editAddressCallId: any;
  deliveryAddressSelectedForOrderCallId: any;
  placeOrderCallId: any;
  ActiveCartCallId: any;
  getProfileId: any;
  editProfileCallId: any;
  emailOtpCallId: any;
  phoneOtpCallId: any;
  emailVerifyCallId: any
  PhoneVerifyCallId: any;
  brandDataId: any;
  DelBrandCallId: any;
  addNewBrandCallId: any;
  EditBrandCallId: any
  myRef: React.RefObject<HTMLDivElement>;
  myEditProfileRef: React.RefObject<HTMLDivElement>;
  myProfileRef: React.RefObject<HTMLDivElement>;


  // Customizable Area End

  public intialValues: VendorAddAddressModel = {
    name: '',
    mobile: '',
    email: '',
    address: '',
    town: '',
    city: '',
    state: '',
    pincode: ''
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.myRef = React.createRef()
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // const result = msg.getData('id');

    // const result = msg.getData('id');

    // console.log("props.id::::", props);
    this.state = {
      txtSavedValue: 'A',
      token: '',
      data: [],
      showNewAddressDialog: false,
      showEditAddressDialog: false,
      showAddNewBrandDialog: false,
      showEditBrandDialog: false,
      // Customizable Area Start
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
      brandNameError: "",
      brandDescriptionError: "",
      brandImageError: "",
      editBrandNameError: "",
      editBrandDescriptionError: "",
      editBrandImageError: "",
      otpPhoneError: "",
      otpEmailError: "",
      mode: '',
      addressData: [],
      // editAddress: {},
      state: '',
      city: '',
      mobile: '',
      email: '',
      town: '',
      id: this.props.id || null,
      name: '',
      address: '',
      pincode: '',
      save_add: 'home',
      saveAddress: '',
      priceDetailsData: [],
      addNewDeliveryAddress: [],
      activeCartId: [],
      deleteAddressId: "",
      deliveryAddressId: "",
      placeOrderLoader: false,
      addressId: "",
      PhoneVerify: false,
      MailVerify: false,
      editName: "",
      editPhoneNumber: "",
      editAddress: "",
      editTown: "",
      editZipCode: "",
      editCity: "",
      editState: "",
      editSaveAddressAs: "",
      editAddressResponse: [],
      profileDetail: [],
      editProfileResponse: [],
      editProfileName: "",
      editProfileemail: "",
      editProfilePhoneNumber: "",
      editProfileDateOfBirth: "",
      editProfileGender: "",
      isDelete: false,
      isDeleteBrand: false,
      editProfileNameError: "",
      editProfileEmailError: "",
      editProfilePhoneError: "",
      editProfileDateOfBirthError: "",
      verifyEmail: false,
      verifyPhone: false,
      emailOtpData: "",
      phoneOtpData: [],
      otpPinEmail: "",
      otpPinPhone: "",
      emailVerifyToken: "",
      PhoneVerifyToken: "",
      verifyEmailData: "",
      verifyPhoneData: "",
      otpTimerEmail: "",
      otpTimerPhone: "",
      resendActivateEmail: false,
      resendActivatePhone: false,
      addressBookLoader: false,
      brandData: [],
      // edit vendor profile 
      EditbrandName: "",
      EditbrandDescription: "",
      EditbrandImage: "",
      editProfileBrandName: "",
      editProfileAccountHolderName: "",
      editProfileAccountNumber: "",
      editProfileBankName: "",
      editProfileIfscCode: "",
      editProfileRegisteredBusinessName: "",
      editProfileGstin: "",
      editProfileTan: "",
      editProfilePan: "",
      BrandId: "",
      addNewBrand: [],
      EditBrandData: [],
      brandName: "",
      brandDescription: "",
      brandImage: "",
      signatureImage: "",
      displayBrandNames: [],
      imageUrl: "",
      editBrandImageUrl: "",
      editSignatureImageUrl: "",
      // error display start 
      editProfileBrandNameError: "",
      editProfileAccountHolderNameError: "",
      editProfileAccountNumberError: "",
      editProfileBankNameError: "",
      editProfileIfscCodeError: "",
      editProfileRegisteredBusinessNameError: "",
      editProfileGstinError: "",
      editProfileTanError: "",
      editProfilePanError: "",
      editSignatureImageError: "",
      // error display end 
      getProfileLoader: false,
      phone_verification: true,
      alertAddBrand: false,
      alertEditBrand: false

      //addressID: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    const data = msg.getData(
      getName(MessageEnum.VendorAddressDataMessage)
    );
    // console.log("constructor data::", data);
    // this.send(msg);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.myRef.current?.scrollIntoView({ behavior: 'auto' })
    // this.myProfileRef.current?.scrollIntoView({ behavior: 'auto' })
    // this.myEditProfileRef.current?.scrollIntoView({ behavior: 'auto' })
    // token
    // console.log('----->> token');
    try {
      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken, "tokentoken")

      this.setState({ token: localToken });
      this.addressCall()
      this.getProfile()
      this.getBrandData()



    } catch (e) {
      // error reading value
    }
  }

  // temporary
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      // console.log("navigation payload:::")
      const data = message.getData(
        getName(MessageEnum.VendorAddressDataMessage)
      );


    }

    // Customizable Area Start






    // address listing data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addressCallId !== null &&
      this.addressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {
        this.setState({ addressData: responseJson.data });
        this.setState({ addressBookLoader: false })
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////addresdata data------------------------------------------------------', this.state.addressData);
        // const defaultid:any = this.state.addressData?.[0].attributes?.id
        // console.log(defaultid,"defaultid")
        // this.setState({deliveryAddressId:defaultid})
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // address listing data end




    // verify email data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.emailVerifyCallId !== null &&
      this.emailVerifyCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.emailVerifyCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // console.log(responseJson && responseJson.data)
      if (responseJson && responseJson.data) {
        this.setState({ verifyEmailData: responseJson }, () => {
          this.setState({ MailVerify: false })
          this.getProfile()
        });

        // console.log('/////////////verify email data------------------------------------------------------', this.state.verifyEmailData);

      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        const otpErr: any = responseJson.errors[0].pin
        this.setState({ otpEmailError: otpErr })
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // verify email data end




    // verify phone data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PhoneVerifyCallId !== null &&
      this.PhoneVerifyCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PhoneVerifyCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // console.log(responseJson)

      if (responseJson && responseJson.data) {
        this.setState({ verifyEmailData: responseJson }, () => {
          this.setState({ PhoneVerify: false })
          this.getProfile()
        });
        // console.log('/////////////verify email data------------------------------------------------------', this.state.verifyEmailData);

      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        const otpErr: any = responseJson.errors[0].pin
        this.setState({ otpPhoneError: otpErr })
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // verify phone data end



    // get profile detail start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileId !== null &&
      this.getProfileId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getProfileId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {

        this.setState({ profileDetail: responseJson.data },


        );
        { console.log(this.props, "vendor") }
        this.setState({ getProfileLoader: false })
        // console.log('//////////////profile detail data------------------------------------------------------', this.state.profileDetail);
        localStorage.setItem("phone", this.state.profileDetail.attributes?.phone_number);
        localStorage.setItem("profilename", this.state.profileDetail.attributes?.name);
        this.props.updateProfileName(this.state.profileDetail.attributes?.name)

        this.setState({
          editProfileName: this.state.profileDetail.attributes?.name == null ? "" : this.state.profileDetail.attributes?.name,
          editProfileemail: this.state.profileDetail.attributes?.email == null ? "" : this.state.profileDetail.attributes?.email,
          editProfilePhoneNumber: this.state.profileDetail.attributes?.phone_number == null ? "" : this.state.profileDetail.attributes?.phone_number,
          editProfileBrandName: this.state.profileDetail.attributes?.name == null ? "" : this.state.profileDetail.attributes?.name,
          // editProfile
          editProfileAccountHolderName: this.state.profileDetail.attributes?.bank_details?.account_holder_name == null ? "" : this.state.profileDetail.attributes?.bank_details?.account_holder_name,
          editProfileAccountNumber: this.state.profileDetail.attributes?.bank_details?.account_number == null ? "" : this.state.profileDetail.attributes?.bank_details?.account_number,
          editProfileBankName: this.state.profileDetail.attributes?.bank_details?.bank_name == null ? "" : this.state.profileDetail.attributes?.bank_details?.bank_name,
          editProfileIfscCode: this.state.profileDetail.attributes?.bank_details?.ifsc_code == null ? "" : this.state.profileDetail.attributes?.bank_details?.ifsc_code,
          editProfileRegisteredBusinessName: this.state.profileDetail.attributes?.business_details?.data.attributes?.registered_business_name == null ? "" : this.state.profileDetail.attributes?.business_details?.data.attributes?.registered_business_name,
          editProfileGstin: this.state.profileDetail.attributes?.business_details?.data.attributes?.gstin == null ? "" : this.state.profileDetail.attributes?.business_details?.data.attributes?.gstin,
          editProfileTan: this.state.profileDetail.attributes?.business_details?.data.attributes?.tan == null ? "" : this.state.profileDetail.attributes?.business_details?.data.attributes?.tan,
          editProfilePan: this.state.profileDetail.attributes?.business_details?.data.attributes?.pan == null ? "" : this.state.profileDetail.attributes?.business_details?.data.attributes?.pan,
          editSignatureImageUrl: this.state.profileDetail.attributes?.business_details?.data.attributes?.signature_image.url == null ? "" : `https://caelumgaurav-65468-ruby.b65468.dev.eastus.az.svc.builder.cafe${this.state.profileDetail.attributes?.business_details?.data.attributes?.signature_image.url}`,
          // editProfileSignature:,
          verifyPhone: this.state.profileDetail.attributes?.phone_verified,
          verifyEmail: this.state.profileDetail.attributes?.email_verified
        })


      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    //   get profile detail end 



    // get brands detail start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.brandDataId !== null &&
      this.brandDataId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.brandDataId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {

        this.setState({ brandData: responseJson.data });
        // this.state.brandData.map((item: any) => {
        //   this.state.displayBrandNames.push(item.attributes?.name)

        // })
        // this.state.displayBrandNames.join(',')
        // this.setState({})
        let displayBrandNames: string[] = []
        this.state.brandData.map((item: any) => {
          displayBrandNames.push(item.attributes.name)
        })
        this.setState({
          displayBrandNames: displayBrandNames.join(", ")
        })
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    //   get brand detail end 






    // add new address response data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addNewDeliveryAddressCallId !== null &&
      this.addNewDeliveryAddressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addNewDeliveryAddressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ addNewDeliveryAddress: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////addNewDeliveryAddress------------------------------------------------------', this.state.addNewDeliveryAddress);
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // add new address response data end 



    // add new brand response data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addNewBrandCallId !== null &&
      this.addNewBrandCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.addNewBrandCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data && responseJson.data.type == "brand") {
        this.setState({ addNewBrand: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        this.handleCloseAddNewBrandDialog()
        this.getBrandData()
        // this.getBrandData()
        console.log('//////////////addNewBrand------------------------------------------------------', this.state.addNewBrand);
      }
      if (responseJson && responseJson.data && responseJson.data.type == "error") {
        // alert(responseJson.data.attributes.errors.name)
        this.setState({ addNewBrand: responseJson.data.attributes.errors.name })
        this.setState({ alertAddBrand: true })
        // this.setState({ shoppingCartloading: false });
        // this.handleCloseAddNewBrandDialog()
        // this.getBrandData()
        // this.getBrandData()
        console.log('//////////////addNewBrand------------------------------------------------------', this.state.addNewBrand);
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // add new brand response data end 






    // edit brand response data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.EditBrandCallId !== null &&
      this.EditBrandCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.EditBrandCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data && responseJson.data.type == "brand") {
        this.setState({ EditBrandData: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        this.handleCloseEditBrandDialog()
        this.getBrandData()
        // console.log('//////////////Edit Brand------------------------------------------------------', this.state.EditBrandData);
      }

      if (responseJson && responseJson.data && responseJson.data.type == "error") {
        this.setState({ EditBrandData: responseJson.data.attributes.errors.name })
        this.setState({ alertEditBrand: true })


      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // edit brandresponse data end 




    // email verifictaion  start 

    // email opt send start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.emailOtpCallId !== null &&
      this.emailOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.emailOtpCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ emailOtpData: responseJson });
        this.setState({ emailVerifyToken: this.state.emailOtpData.token })
        // console.log('//////////////emailOtpData------------------------------------------------------', this.state.emailOtpData);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // email opt end


    // email verifictaion  start 






    // phone  verifictaion  start 

    // phone opt send start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.phoneOtpCallId !== null &&
      this.phoneOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.phoneOtpCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ phoneOtpData: responseJson });
        this.setState({ PhoneVerifyToken: this.state.phoneOtpData.token })
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////phoneOtpData------------------------------------------------------', this.state.phoneOtpData);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // phone opt end

    // phone verifictaion  start 

    // edit profile start 

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.editProfileCallId !== null &&
      this.editProfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.editProfileCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ editProfileResponse: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////editProfileResponse------------------------------------------------------', this.state.editProfileResponse);
        // this.getProfile()
        this.props.navigation.navigate("VendorProfile")
        localStorage.removeItem("phone");


      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        // console.log(responseJson.message)
        if (responseJson.account_error[0] == "Email has already been taken") {
          this.setState({ editProfileEmailError: responseJson.account_error[0] })
        }
        if (responseJson.account_error[0] == "Full phone number has already been taken") {
          this.setState({ editProfilePhoneError: responseJson.account_error[0] })
        }


        if (responseJson.account_error[0] == "Full phone number has already been taken" && responseJson.account_error[1] == "Email has already been taken") {
          this.setState({ editProfilePhoneError: responseJson.account_error[0], editProfileEmailError: responseJson.account_error[1] })
        }





      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // edit profile end 


    // edit address start 

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.editAddressCallId !== null &&
      this.editAddressCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.editAddressCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ editAddressResponse: responseJson.data });
        // this.setState({ shoppingCartloading: false });
        // console.log('//////////////editAddressResponse------------------------------------------------------', this.state.editAddressResponse);

        this.addressCall();
        this.handleCloseEditAddressDialog()
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // edit address end 



    // DELETE ADDRESS
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DelAddressCallId !== null) {


      this.DelAddressCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // this.setState({ data: responseJson.data });
      // console.log('-------------->', this.state.data);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.message == "Address deleted successfully") {
        this.setState({
          isDelete: false
        })
        this.addressCall()
      }


      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END DELETE ADDRESS




    // DELETE brand
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DelBrandCallId !== null) {


      this.DelBrandCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // this.setState({ data: responseJson.data });
      // console.log('-------------->', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        // console.log(responseJson)
        this.handleCloseBrand()
        this.getBrandData()


      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END DELETE brand



    // DEFAULT ADDRESS
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.DefualtAddressCallId !== null) {

      this.DefualtAddressCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // this.setState({ data: responseJson.data });
      // console.log('Default api response-------------->', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        this.addressCall();
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // END DEFAULT ADDRESS

    // Customizable Area End

  }

  getProfile() {
    this.setState({ getProfileLoader: true })

    const header = {
      "Content-Type": "application/json",
      token: this.state.token

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }


  getBrandData() {


    const header = {
      "Content-Type": "application/json",
      token: this.state.token

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.brandDataId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandDataAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }



  // deleteAddress starat 
  deleteAddress() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.DelAddressCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAddressAPiEndPoint.concat(this.state.addressId)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  //   deleteAddress end 


  // delete brand 
  deleteBrand() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.DelBrandCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBrandAPiEndPoint.concat(this.state.BrandId)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }
  //   deleteAddress end 



  addressCall() {
    // console.log('---------------->>>>>>> address');
    this.setState({ addressBookLoader: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addressCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }




  handleCloseNewAddressDialog = () => {
    this.setState({
      showNewAddressDialog: false,
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",

    });
  };

  handleCloseEditAddressDialog = () => {
    this.setState({
      showEditAddressDialog: false,
      state: '',
      city: '',
      mobile: '',
      email: '',
      town: '',
      name: '',
      address: '',
      pincode: '',
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
    });
  };
  handleCloseAddNewBrandDialog = () => {
    this.setState({
      showAddNewBrandDialog: false,
      brandNameError: "",
      brandDescriptionError: "",
      brandImageError: " ",
      imageUrl: "",
      brandName: "",
      brandDescription: "",
      alertAddBrand: false


    });
  };

  handleCloseEditBrandDialog = () => {
    this.setState({
      showEditBrandDialog: false,
      editBrandNameError: "",
      editBrandDescriptionError: "",
      editBrandImageError: "",
      alertEditBrand: false
    });
  };


  addNewAddress() {

    if (!this.state.name || !this.state.mobile || !this.state.town || !this.state.pincode || !this.state.city || !this.state.state || !this.state.save_add || (this.state.address.length < 10 && this.state.address.length > 0) || !this.state.address) {
      if (!this.state.name) {      // alert('Package Name Field is Empty');
        this.setState({ nameError: "Please fill name field" })
      } else { this.setState({ nameError: "" }) }
      if (!this.state.mobile) {
        // alert('Package Length Field is Empty');
        this.setState({ mobileError: "Please fill number field" })
      } else if (this.state.mobile.length != 10) {
        this.setState({ mobileError: "Please enter valid number" })
      }
      else { this.setState({ mobileError: "" }) }



      //  if (!this.state.address) {
      //     // alert('Package Width Fieldis Empty');
      //     this.setState({addressError:"Please fill address field"})
      // } else{ this.setState({addressError:""}) }
      if (this.state.address.length < 10 && this.state.address.length > 0 || !this.state.address) {

        if (this.state.address.length < 10 && this.state.address.length > 0) {
          this.setState({ addressError: "address is less than 10 characters" })
        }
        else if (!this.state.address) {
          // alert('Package Width Fieldis Empty');
          this.setState({ addressError: "Please fill address field" })
        } else { this.setState({ addressError: "" }) }
      }

      if (!this.state.town) {
        // alert('Package Height Field is Empty');
        this.setState({ townError: "Please fill town field" })
      } else { this.setState({ townError: "" }) }


      if (!this.state.pincode) {
        // alert('Package Height Field is Empty');
        this.setState({ pincodeError: "Please fill pincode field" })
      } else { this.setState({ pincodeError: "" }) }


      if (!this.state.city) {
        // alert('Package Height Field is Empty');
        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.city) {
        // alert('Package Height Field is Empty');
        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.state) {
        // alert('Package Height Field is Empty');
        this.setState({ stateError: "Please fill state field" })
      } else { this.setState({ stateError: "" }) }


      if (!this.state.save_add) {
        // alert('Package Height Field is Empty');
        this.setState({ save_addError: "Please fill save address as field" })
      } else { this.setState({ save_addError: "" }) }

    }

    else {
      this.handleCloseNewAddressDialog()



      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const data = {
        name: this.state.name,
        phone_number: this.state.mobile,
        address: this.state.address,
        town: this.state.town,
        zip_code: this.state.pincode,
        city: this.state.city,
        state: this.state.state,
        save_address_as: this.state.save_add,
        // email: "nomail@gmail.com",
        country: "india",
        is_default: true
      };

      const httpBody =
        data

      console.log("--------------->>>>>>>>>>", httpBody);


      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addNewAddressCallId = apiRequest.messageId;
      // console.log(this.addNewAddressCallId)
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addNewAddressAPiEndPoint
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      this.setState({
        name: "", city: "", town: "", mobile: "", pincode: "", address: "", state: "",

        nameError: "", cityError: "", townError: "", mobileError: "", pincodeError: "", addressError: "", stateError: ""
      })
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;

    }


  }



  ValidateEmail = (text: string): boolean => {
    // let reg = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/;
    // let reg = /^w+([.]?)w+@\w+.\w{2,3})$/;
    let reg = /^\w+([.-]?\w+)([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/
    if (reg.test(text) === false) {
      return false;
    }
    else {
      return true;
    }

  }



  checkEditProfileValidations = () => {
    console.log(this.ValidateEmail(this.state.editProfileemail), "validate email")

    if (this.isStringNullOrBlank(this.state.editProfileName)) {
      // this.setState({ companyNameErrorMessage: 'Enter Company Name' })
      this.setState({ editProfileNameError: 'Enter Company Name' })
    } else { this.setState({ editProfileNameError: '' }) }

    if (this.isStringNullOrBlank(this.state.editProfileemail)) {
      // this.setState({ emailErrorMessage: 'Enter Email' })
      this.setState({ editProfileEmailError: 'Enter email' })
    }
    else if (!this.ValidateEmail(this.state.editProfileemail)) {
      this.setState({ editProfileEmailError: 'Enter valid email' })
    }
    else { this.setState({ editProfileEmailError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfilePhoneNumber)) {
      // this.setState({ phoneNumberErrorMessage: 'Enter Mobile Number' })
      this.setState({ editProfilePhoneError: " Enter Mobile Number" })
    }
    else if (this.state.editProfilePhoneNumber.toString().length !== 10) {
      this.setState({ editProfilePhoneError: "Enter valid number" })
      // const str = this.state.editProfilePhoneNumber.length
      // alert(JSON.stringify(this.state.editProfilePhoneNumber))
      // alert(this.state.editProfilePhoneNumber.toString().length)
    } else { this.setState({ editProfilePhoneError: " " }) }
    // else if (!this.stringContainsNumber(this.state.editProfilePhoneNumber) || this.state.editProfilePhoneNumber.length < 10) {
    //     // this.setState({ phoneNumberErrorMessage: 'Enter Valid Mobile Number' })
    //     alert('Enter Valid Mobile Number')
    // }
    if (this.isStringNullOrBlank(this.state.editProfileAccountHolderName)) {

      this.setState({ editProfileAccountHolderNameError: 'Enter Account Holder Name' })
    } else { this.setState({ editProfileAccountHolderNameError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfileAccountNumber)) {

      this.setState({ editProfileAccountNumberError: 'Enter Account Number' })
    } else { this.setState({ editProfileAccountNumberError: '' }) }


    if (this.isStringNullOrBlank(this.state.editSignatureImageUrl)) {

      this.setState({ editSignatureImageError: 'Upload Signature Image' })
    } else { this.setState({ editSignatureImageError: '' }) }



    //  else if (!this.stringContainsNumber(JSON.stringify(this.state.editProfileAccountNumber)) || this.state.editProfileAccountNumber.length < 10) {
    //   // this.setState({ accountNumberErrorMessage: 'Enter Valid Account Number' })
    //   alert('Enter Valid Account Number')
    // }
    if (this.isStringNullOrBlank(this.state.editProfileBankName)) {
      this.setState({ editProfileBankNameError: 'Enter Bank Name' })
    } else { this.setState({ editProfileBankNameError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfileIfscCode)) {
      this.setState({ editProfileIfscCodeError: 'Enter ifsc Code' })
    } else { this.setState({ editProfileIfscCodeError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfileRegisteredBusinessName)) {


      this.setState({ editProfileRegisteredBusinessNameError: 'Enter Registered Business Name' })
    } else { this.setState({ editProfileRegisteredBusinessNameError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfileGstin)) {

      this.setState({ editProfileGstinError: 'Enter GST Number' })
    } else { this.setState({ editProfileGstinError: '' }) }




    if (this.isStringNullOrBlank(this.state.editProfileTan)) {
      this.setState({ editProfileTanError: 'Enter TAN Number' })
    } else { this.setState({ editProfileTanError: '' }) }


    if (this.isStringNullOrBlank(this.state.editProfilePan)) {
      this.setState({ editProfilePanError: 'Enter PAN Number' })
    } else { this.setState({ editProfilePanError: '' }) }



    if (!this.isStringNullOrBlank(this.state.editProfileName) &&
      (!this.isStringNullOrBlank(this.state.editProfileemail) && this.ValidateEmail(this.state.editProfileemail)) &&
      !this.isStringNullOrBlank(this.state.editProfileAccountHolderName) &&
      !this.isStringNullOrBlank(JSON.stringify(this.state.editProfilePhoneNumber))
      && this.stringContainsNumber(JSON.stringify(this.state.editProfilePhoneNumber)
      )
      &&
      !this.isStringNullOrBlank(this.state.editProfileBankName) &&
      !this.isStringNullOrBlank(this.state.editProfileIfscCode) &&
      !this.isStringNullOrBlank(this.state.editProfileRegisteredBusinessName) &&
      !this.isStringNullOrBlank(this.state.editProfileGstin) &&
      !this.isStringNullOrBlank(this.state.editProfileTan) &&
      !this.isStringNullOrBlank(this.state.editProfilePan)
      &&
      (this.state.editProfilePhoneNumber.length === 10 || this.state.editProfilePhoneNumber.length == undefined)
      &&
      !this.isStringNullOrBlank(this.state.editSignatureImageUrl)
    ) {
      this.EditProfile()
    }

  }



  // edit address start
  EditProfile() {
    // console.log(typeof this.state.signatureImage)
    const header = {
      'Accept': 'application/json',
      token: this.state.token,
    };



    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProfileCallId = apiRequest.messageId;


    // console.log("signature", this.state.signatureImage)
    var formdata = new FormData()
    formdata.append("name", this.state.editProfileName);
    formdata.append("email", this.state.editProfileemail);
    formdata.append("phone_number", this.state.editProfilePhoneNumber);
    formdata.append("account_holder_name", this.state.editProfileAccountHolderName);
    formdata.append("account_number", this.state.editProfileAccountNumber);
    formdata.append("bank_name", this.state.editProfileBankName);
    formdata.append("ifsc_code", this.state.editProfileIfscCode);
    formdata.append("registered_business_name", this.state.editProfileRegisteredBusinessName);
    formdata.append("gstin", this.state.editProfileGstin);
    formdata.append("tan", this.state.editProfileTan);
    formdata.append("pan", this.state.editProfilePan);
    if (typeof this.state.signatureImage == "object") {
      formdata.append("signature_image", this.state.signatureImage, this.state.signatureImage.name);
    }

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editProfileAPiEndPoint

    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PatchMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }



  // edit address start
  EditAddress() {


    if (!this.state.editName || (!this.state.editPhoneNumber || this.state.editPhoneNumber.length != 10) || !this.state.editTown || !this.state.editZipCode || !this.state.editCity || !this.state.editState || !this.state.save_add || (this.state.editAddress.length < 10 && this.state.editAddress.length > 0) || !this.state.editAddress) {
      if (!this.state.editName) {      // alert('Package Name Field is Empty');
        this.setState({ nameError: "Please fill name field" })
      } else { this.setState({ nameError: "" }) }

      if (!this.state.editPhoneNumber) {
        // alert('Package Length Field is Empty');
        this.setState({ mobileError: "Please fill number field" })
      }
      else if (this.state.editPhoneNumber.length != 10) {
        this.setState({ mobileError: "Please enter valid number" })
      } else { this.setState({ mobileError: "" }) }

      if (this.state.editAddress.length < 10 && this.state.editAddress.length > 0 || !this.state.editAddress) {

        if (this.state.editAddress.length < 10 && this.state.editAddress.length > 0) {
          this.setState({ addressError: "address is less than 10 characters" })
        }
        else if (!this.state.editAddress) {
          // alert('Package Width Fieldis Empty');
          this.setState({ addressError: "Please fill address field" })
        } else { this.setState({ addressError: "" }) }
      }

      if (!this.state.editTown) {
        // alert('Package Height Field is Empty');
        this.setState({ townError: "Please fill town field" })
      } else { this.setState({ townError: "" }) }


      if (!this.state.editZipCode) {
        // alert('Package Height Field is Empty');
        this.setState({ pincodeError: "Please fill pincode field" })
      } else { this.setState({ pincodeError: "" }) }


      if (!this.state.editCity) {
        // alert('Package Height Field is Empty');
        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.editCity) {
        // alert('Package Height Field is Empty');
        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.editState) {
        // alert('Package Height Field is Empty');
        this.setState({ stateError: "Please fill state field" })
      } else { this.setState({ stateError: "" }) }


      if (!this.state.save_add) {
        // alert('Package Height Field is Empty');
        this.setState({ save_addError: "Please fill save address as field" })
      } else { this.setState({ save_addError: "" }) }

    }

    else {
      this.handleCloseNewAddressDialog()



      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const data = {
        name: this.state.editName,
        phone_number: this.state.editPhoneNumber,
        address: this.state.editAddress,
        town: this.state.editTown,
        zip_code: this.state.editZipCode,
        city: this.state.editCity,
        state: this.state.editState,
        save_address_as: this.state.editSaveAddressAs,
        // email: "nomail@gmail.com",
        country: "india",
        is_default: true
      };

      const httpBody =
        data

      // console.log("--------------->>>>>>>>>>", httpBody);


      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editAddressCallId = apiRequest.messageId;
      // console.log(this.editAddressCallId)
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editAddressAPiEndPoint.concat(this.state.addressId)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PatchMethodType
      );

      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;
    }

  }

  // edit  address end 


  stringContainsNumber(string: string) {
    return /\d/.test(string);
  }


  // add new brand start 
  addNewBrand() {
    if (!this.state.brandName || !this.state.brandImage || !this.state.brandDescription || !this.state.imageUrl) {
      if (!this.state.brandName) { this.setState({ brandNameError: "Brand name is required" }) } else { this.setState({ brandNameError: "" }) }
      if (!this.state.brandImage && !this.state.imageUrl) { this.setState({ brandImageError: "Brand image is required" }) } else { this.setState({ brandImageError: "" }) }
      if (!this.state.brandDescription) { this.setState({ brandDescriptionError: "Brand description is required" }) } else { this.setState({ brandDescriptionError: "" }) }
    }
    else {

      const header = {
        // "Content-Type":"multipart/form-data",
        'Accept': 'application/json',
        token: this.state.token,
      };

      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addNewBrandCallId = apiRequest.messageId;


      var formdata = new FormData();
      formdata.append("name", this.state.brandName);
      formdata.append("image", this.state.brandImage, this.state.brandImage.name);
      formdata.append("description", this.state.brandDescription);

      // console.log(JSON.stringify(formdata), "formdata")
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addNewBrandAPiEndPoint
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), formdata
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );


      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;


    }
  }
  // add new brand end



  // Edit brand start 
  EditBrand() {
    if (!this.state.EditbrandName || !this.state.EditbrandImage || !this.state.EditbrandDescription || !this.state.editBrandImageUrl) {
      if (!this.state.EditbrandName) { this.setState({ editBrandNameError: "Brand name is required" }) } else { this.setState({ editBrandNameError: "" }) }
      if (!this.state.EditbrandImage && !this.state.editBrandImageUrl) { this.setState({ editBrandImageError: "Brand image is required" }) } else { this.setState({ editBrandImageError: "" }) }
      if (!this.state.EditbrandDescription) { this.setState({ editBrandDescriptionError: "Brand description is required" }) } else { this.setState({ editBrandDescriptionError: "" }) }
    }
    else {
      const header = {
        // "Content-Type":"multipart/form-data",
        'Accept': 'application/json',
        token: this.state.token,
      };

      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.EditBrandCallId = apiRequest.messageId;


      var formdata = new FormData();
      formdata.append("name", this.state.EditbrandName);
      formdata.append("image", this.state.EditbrandImage, this.state.EditbrandImage.name);
      formdata.append("description", this.state.EditbrandDescription);

      // console.log(JSON.stringify(formdata), "formdata")
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateBrandAPiEndPoint.concat(this.state.BrandId)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), formdata
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PatchMethodType
      );
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;
    }
  }
  // Edit brand end


  sendOtpEmail() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };



    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailOtpCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOtpAPiEndPoint.concat(this.state.editProfileemail)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }




  sendOtpPhone() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };



    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.phoneOtpCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneOtpAPiEndPoint.concat(this.state.editProfilePhoneNumber)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  verifyEmail() {
    // console.log("tokenverify", this.state.emailVerifyToken, "otp", this.state.otpPinEmail)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.emailVerifyToken,
    };

    const data = {
      pin: this.state.otpPinEmail
    };

    const httpBody =
      data

    // console.log("--------------->>>>>>>>>>", httpBody);


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailVerifyCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailVerifyAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  verifyPhone() {
    // console.log("tokenverify", this.state.PhoneVerifyToken, "otp", this.state.otpPinPhone)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.PhoneVerifyToken,
    };

    const data = {
      pin: this.state.otpPinPhone
    };

    const httpBody =
      data

    // console.log("--------------->>>>>>>>>>", httpBody);


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PhoneVerifyCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PhoneVerifyAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  uploadImage(e: any) {
    // console.log(e.target.files, "image")
    const url = URL.createObjectURL(e.target.files[0])
    this.setState({ brandImage: e.target.files[0] })
    this.setState({ imageUrl: url })
  }
  EdituploadImage(e: any) {
    // console.log(e.target.files, "image")
    const url = URL.createObjectURL(e.target.files[0])
    this.setState({ EditbrandImage: e.target.files[0] })
    this.setState({ editBrandImageUrl: url })
  }
  signatureImage(e: any) {
    // console.log(e.target.files, "image")
    const url = URL.createObjectURL(e.target.files[0])
    this.setState({ signatureImage: e.target.files[0] })
    this.setState({ editSignatureImageUrl: url })
  }


  deleteBrandImage = () => {

    this.setState({ imageUrl: "" })
  }
  deleteSignatureImage = () => {

    this.setState({ editSignatureImageUrl: "" })
  }

  deleteEditBrandImage = () => {

    this.setState({ editBrandImageUrl: "" })
  }





  isStringNullOrBlank(str: string | number) {
    return str === null || str.toString().length === 0;
  }




  addThisDeliveryAddress() {

    this.addNewAddress();
    setTimeout(() => this.addressCall(), 2000)


  }

  handleClose() {
    this.setState({
      isDelete: false
    });
  }



  handleCloseBrand() {
    this.setState({
      isDeleteBrand: false
    });
  }




  otpTimerEmail() {

    var timeleft = 30;
    this.setState({ otpTimerEmail: timeleft })

    var downloadTimer = setInterval(() => {
      var updatedtime = this.state.otpTimerEmail - 1;
      this.setState({ otpTimerEmail: updatedtime })
      if (this.state.otpTimerEmail <= 0) {
        clearInterval(downloadTimer);
        this.setState({ resendActivateEmail: true })
      }
    }, 1000);


  }
  otpTimerPhone() {

    var timeleft = 30;
    this.setState({ otpTimerPhone: timeleft })

    var downloadTimer = setInterval(() => {
      var updatedtime = this.state.otpTimerPhone - 1;
      this.setState({ otpTimerPhone: updatedtime })
      if (this.state.otpTimerPhone <= 0) {
        this.setState({ resendActivatePhone: true })
        clearInterval(downloadTimer);
      }

    }, 1000);


  }



}
